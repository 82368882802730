import React from 'react';
import styled from 'styled-components';
import { themeColors } from 'themes';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  viewBox: '0 0 21 21',
  fill: 'none'
}))``;

export const IcoChevronDown = ({
  width,
  color,
  srLabel
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg
      {...{ width, color }}
      className="icon--pencil icon"
      focusable="true"
      aria-label={srLabel || `edit`}
    >
      <path
        d="M15 1L8 8L1 1"
        stroke={color ? `${color}` : themeColors.darkGrey}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
