import React from 'react';
import { DimensionsContainer } from 'components';
import { BarChartProps } from '../';
import { generateStackedBarChart } from '../helpers/generateStackedBarChart';
import { DivBarChartBars } from './DivBarChartBars';
import { DivBarChartAxis } from './DivBarChartAxis';
import { DivBarChartGrid } from './DivBarChartGrid';
import { ChartContainer } from 'common/ui';

const Provided = ({
  domains,
  format,
  colors,
  width,
  height,
  groupKey,
  categoryKey,
  valueKey,
  chartData,
  margins,
  data,
  chartKeys,
  hideAxisLeft,
  hideAxisBottom,
  hideAxisTop,
  hideAxisRight,
  Bar,
  Tooltip,
  barSpacing,
  categories,
  handleClick,
  leftTickFormatType
}: BarChartProps): JSX.Element => {
  if (!chartData || data) {
    console.log('chartData is needed to render DivBarChart');
    return <></>;
  }

  // set some fallbacks
  width = Number(width || 0);
  height = Number(height || 0);

  if (width === 0 || height === 0) return <></>;

  // check if there is an axis bottom or left and add margins if there are
  const marginLeft = margins?.left || (hideAxisLeft ? 0 : 40);
  const marginTop = margins?.top || hideAxisTop ? 0 : 10;
  const marginBottom = margins?.bottom || hideAxisBottom ? 0 : 20;
  const marginRight = margins?.right || hideAxisRight ? 0 : 20;

  categoryKey = categoryKey || chartKeys?.category || 'category';
  groupKey = groupKey || chartKeys?.group || 'group';
  valueKey = valueKey || chartKeys?.value || 'value';

  // calculate chart dimensions
  const chartWidth = Number(width) - marginLeft - marginRight;
  const chartHeight = Number(height) - marginTop - marginBottom;

  const [bars, axisL, axisB, gridLines] = generateStackedBarChart(chartData, {
    width: chartWidth,
    height: chartHeight,
    groupKey,
    categoryKey,
    valueKey,
    colors,
    domainX: domains?.x,
    domainY: domains?.y,
    barSpacing,
    leftTickFormatType,
    categories
  });

  return (
    <ChartContainer
      className="div-bar-chart"
      {...{
        //width,
        //height,
        chartHeight,
        chartWidth,
        marginLeft,
        marginBottom,
        marginTop,
        marginRight
      }}
    >
      {axisB.length > 0 && (
        <div className="axis axis--bottom">
          <DivBarChartAxis axisItems={axisB} {...{ formatter: format?.bottomTicks }} />
        </div>
      )}

      {axisL.length > 1 && (
        <div className="axis axis--left">
          <DivBarChartAxis axisItems={axisL} {...{ marginLeft, formatter: format?.leftTicks }} />
        </div>
      )}

      {gridLines && (
        <div className="grid-area">
          <DivBarChartGrid {...{ gridLines }} />
        </div>
      )}

      {bars && (
        <div className="chart-area chart-area--bars">
          <DivBarChartBars
            {...{ bars, width: chartWidth, height: chartHeight, Bar, Tooltip, handleClick }}
          />
        </div>
      )}
    </ChartContainer>
  );
};

export const DivBarChart = ({ className, ...rest }: BarChartProps): JSX.Element => {
  // taking out width width and height to pass to dims component and send rest to charts
  const { width, height, ...props } = rest;

  return (
    <DimensionsContainer
      {...{
        className: className ? `div-bar-chart dimensions-container--${className}` : `div-bar-chart`,
        dims: { width: width as number, height: height as number }
      }}
      Component={(dims) => <Provided {...props} {...dims} />}
    />
  );
};
