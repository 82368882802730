import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import theme from 'themes';

const Input = styled.input`
  width: 4.5rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid ${theme.colors.lightGrey3};
  height: 2.35rem;
  min-width: 60px;
  margin-left: 1rem;
  padding: 0 1rem;
  ::-webkit-inner-spin-button {
    opacity: 1;
    -webkit-appearance: inner-spin-button !important;
    width: 30px;
    position: absolute;
    top: 0.1rem;
    bottom: 0.1rem;
    right: 0.1rem;
    background: ${theme.colors.darkGrey};
  }
`;
const NumberInput = (props: InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  return <Input {...props} />;
};

export default NumberInput;
