export default {
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  SET_ITEM_QUANTITY: 'SET_ITEM_QUANTITY',
  UPDATE_CART_ITEM: 'UPDATE_CART_ITEM',
  VERIFY_CART: 'VERIFY_CART',
  EMPTY_CART: 'EMPTY_CART',
  SET_CART_USER_ID: 'SET_CART_USER_ID',
  DELETE_CART_USER_ID: 'DELETE_CART_USER_ID',
  DOWNLOAD_CART_CSV: 'DOWNLOAD_CART_CSV',
  SET_SF_CART: 'SET_SF_CART'
};
