import React from 'react';

export const X = ({
  color,
  width = 14,
  height = 14
}: {
  color?: string;
  width?: number;
  height?: number;
}): JSX.Element => {
  const className = 'icon icon--x';
  color = color || '#0076CC';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ className }}
    >
      <path
        d="M1 13L13 1M1 1L13 13"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default X;
