import { useUpdateUserRoleMutation } from 'api';
import DropdownOption from 'common/components/DropdownOption';
import EllypsisMenu, { OptionType } from 'components/EllypsisMenu';
import { Ellipsys } from 'icons/Ellipsys';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { UserGroup } from 'types/user-management';
import { DropDownItem } from 'pages/ProteinMachine/MachineConfig/Common/Alerts/FormElementsTypes';

type Props = {
  data: UserGroup[];
  id?: string;
  isGroupPage?: boolean;
  role: 'admin' | 'member';
  adminStatus?: { isAdmin: boolean; isAllAdmin: boolean };
};
const UserRole: React.FC<Props> = ({ data = [], id, isGroupPage, role, adminStatus }) => {
  const urlParam: { groupId?: string } = useParams();
  const getUserRole = () => {
    const group = data.find((group) => group.id === urlParam.groupId);
    return group?.isAdmin ? 'admin' : 'member';
  };
  const userRole = isGroupPage ? getUserRole() : role;

  const [updateUserRole] = useUpdateUserRoleMutation();

  const options = useMemo(() => {
    const opt = data.map((item) => ({
      label: item.name,
      value: item.id,
      subMenu: [
        { label: 'Admin', value: 'admin', isActive: item.isAdmin },
        { label: 'Member', value: 'member', isActive: !item.isAdmin }
      ]
    }));
    return opt;
  }, [data]);

  const handleChangeRole = (option?: OptionType, parentOption?: OptionType) => {
    const params = {
      group_id: isGroupPage ? urlParam.groupId : parentOption?.value,
      user_id: id,
      is_admin: option?.value === 'admin'
    };
    updateUserRole([params]);
  };
  const handleSelectChangeRole = (option?: DropDownItem | null) => {
    if (data && data.length > 0) {
      const params = {
        group_id: isGroupPage ? urlParam.groupId : data[0].id,
        user_id: id,
        is_admin: option?.value === 'admin'
      };
      updateUserRole([params]);
    }
  };

  return (
    <>
      {isGroupPage ? (
        <DropdownOption
          options={[
            { label: 'Member', value: 'member' },
            { label: 'Admin', value: 'admin' }
          ]}
          handleOptionSelect={(option) => handleChangeRole(option)}
          selectedOpt={userRole === 'admin' ? 'Admin' : 'Member'}
          containerPosition="absolute"
        />
      ) : data.length > 1 ? (
        <EllypsisMenu
          icon={<Ellipsys />}
          options={options}
          handleOptionSelect={handleChangeRole}
          adminStatus={adminStatus}
        />
      ) : (
        <Select
          isSearchable={false}
          options={[
            { label: 'Member', value: 'member' },
            { label: 'Admin', value: 'admin' }
          ]}
          onChange={(option) => handleSelectChangeRole(option)}
          menuPlacement="auto"
          menuPosition="fixed"
          value={
            adminStatus?.isAdmin
              ? { label: 'Admin', value: 'admin' }
              : { label: 'Member', value: 'member' }
          }
          components={{
            IndicatorSeparator: () => null
          }}
          styles={{
            control: (base) => ({
              ...base,
              border: 'none',
              boxShadow: 'none',
              width: '8rem',
              marginLeft: '-0.8rem'
            })
          }}
        />
      )}
    </>
  );
};

export default UserRole;
