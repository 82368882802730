import { BaseType, Id, WithId } from 'types';

export type {
  CreateNewWidgetItemProps,
  TableRowProps,
  WidgetRowCrudProps,
  WidgetTableCustomExpandIcon,
  WidgetTableDataItem,
  WidgetTableDropdownItem,
  WidgetTableFormProps,
  WidgetTableProps,
  WidgetTableTitleProps,
  WidgetOverviewDataItem
} from './widget-table';

export type Value = {
  key: string;
  content?: string | JSX.Element;
  weight?: number;
  height?: number;
  color?: string;
  bgColor?: string;
  clickable?: boolean;
  isCustomRowHeight?: boolean;
  customRowHeight?: string;
};

export type KpiRow = {
  value?: Value;
  label?: string;
};

export type ConnectionStatus = 'good' | 'medium' | 'bad' | 'error' | 'never-connected' | null;
export type AccountInfoConnectionStatus = {
  watchdog: string;
  lastKnownConnected: string;
  gateway: string;
};
export enum MachineHealthKpiKey {
  WeightMachineHour = 'weightMachineHour',
  FailedCycleMachineHour = 'failedCycleMachineHour',
  CyclecountMachineHour = 'cyclecountMachineHour',
  WaitTimeMachineHour = 'waitTimeMachineHour',
  MachineState = 'machineState',
  MachineCondition = 'machineCondition',
  CyclecountPluShift = 'cyclecountPluShift'
}

export type MachineHealthKpiUnit =
  | null
  | 'lbs / cycle'
  | 'Total in last hour'
  | 'min. / cycle'
  | 'in shift';

export type MachineHealthKpiUnits = Record<MachineHealthKpiKey, MachineHealthKpiUnit>;

export interface MachineHealthKpi {
  value: number;
  unit: string;
  status?: 'at-risk' | 'warning';
}

export type MachineHealthStatus = 'running' | 'stopped-alarm';

export interface MachineHealthItem extends BaseType {
  id: number;
  component: string;
  status: MachineHealthStatus;
  data: { [kpi: string]: MachineHealthKpiDetail[] };
  actionNeeded?: string;
  state: SubComponentDataState;
}

export interface MachineHealthItemWithKey extends MachineHealthItem {
  key: string;
}

export type MachineHealthKpiValue = string | number;

export enum MachineHealthKpiStatus {
  Good = 'good',
  Warning = 'warning',
  Bad = 'bad',
  Running = 'running',
  NA = 'n/a',
  Disabled = 'disabled'
}

export interface MachineHealthKpiDetail extends BaseType {
  kpi: string;
  state?: MachineHealthKpiValue;
  values: {
    actual: MachineHealthKpiValue;
    target: null | MachineHealthKpiValue;
  };
  threshold: {
    status: MachineHealthKpiStatus;
    value: {
      actual: MachineHealthKpiValue;
      target: null | MachineHealthKpiValue;
    };
  };
}

export interface MachineHealthKpiUom extends BaseType {
  kpiUom: MachineHealthKpiUnits;
  machineKpis: NewMachineHealthKpi[];
}

export interface NewMachineHealthKpi extends BaseType, WithId {
  machineDesc: string;
  plantId: Id;
  data: { [kpi: string]: MachineHealthKpiDetail[] };
  pluData: { [plu: string]: PluData };
  subComponentData: { [key: string]: SubComponentData };
}

export interface PluData extends BaseType {
  [kpi: string]: MachineHealthKpiDetail[];
}

export interface SubComponentDataState {
  displayName: string;
  value: string;
}

export interface SubComponentData extends BaseType {
  action?: string;
  data: { [kpi: string]: MachineHealthKpiDetail[] };
  name: string;
  state: SubComponentDataState;
  status: MachineHealthStatus;
}

export interface MachineHealthProductionData extends BaseType {
  id: Id;
  machine: string;
  oee: string;
  successfulCyclesActual: MachineHealthKpiValue;
  successfulCyclesTarget: MachineHealthKpiValue;
  successfulCyclesStatus: MachineHealthKpiStatus;
  weightActual: MachineHealthKpiValue;
  weightTarget: MachineHealthKpiValue;
  weightStatus: MachineHealthKpiStatus;
  waitTime: MachineHealthKpiValue;
  waitTimeStatus: MachineHealthKpiStatus;
  failCycles: MachineHealthKpiValue;
  failCyclesStatus: MachineHealthKpiStatus;
  state: MachineHealthKpiValue;
  stateStatus: MachineHealthKpiStatus;
  condition: MachineHealthKpiValue;
  conditionStatus: MachineHealthKpiStatus;
}

/**
 *  Aseptic
 */
export type AsepticMachineHealthKpiItem = {
  type: string;
  id: string;
  unit: string;
  small_size_threshold: number;
  large_size_threshold: number;
  distance_edge_threshold: number;
  distance_between_threshold: number;
  modeNumber: string;
  modeDescr: string;
  recipeDescr: string;
  startTime: string;
  endTime: string;
  value: {
    timestamp: string;
    value: number;
    endTimestamp: string;
  };
  values: {
    timestamp: string;
    value: number;
    endTimestamp: string;
  }[];
};

export type AsepticMachineModesItem = {
  modeNumber: string;
  modeDescr: string;
  recipeDescr: string;
  startTime: string;
  endTime: string;
};

export type AsepticMachineModesGraphItem = {
  id: number;
  label: string;
  parentProperty: string;
  data: AsepticMachineModesBarItem[];
};

export type AsepticMachineModesBarItem = {
  stateCode: string;
  stateName: string;
  startTimestamp: string;
  endTimestamp: string;
};

export type AsepticMachineHealthKpi = AsepticMachineHealthKpiItem[];

export interface AsepticMachineHealthKpiQueryParams extends BaseType {
  machineId: string;
  kpiDataInterval?: string;
  widgetType: string;
  limit: number;
  includeHistoricData: boolean;
  utc_start_datetime?: string;
  utc_end_datetime?: string;
}

export enum MachineType {
  Aseptic = 'aseptic',
  DSI = 'dsi',
  Avure = 'avure'
}

export enum WidgetType {
  ALERTS_WIDGET = 'alerts_widget',
  BOTTLES_THROUGHPUT = 'bottles_throughput',
  CHANGEOVER = 'changeover',
  CLEANING_LAST_SESSION = 'cleaning_last_session',
  CURRENT_ISSUES = 'current_issues',
  CYCLE = 'cycle',
  DURATION_CHART = 'duration_chart',
  FOIL = 'foil',
  KEY_INDICATORS_HISTORY = 'key_indicators_history',
  KPI_CARD = 'kpi_card',
  LINE_VIEW_MACHINE = 'line_view_machine',
  MACHINE_IMAGE = 'machine_image',
  MACHINE_INFO = 'machine_info',
  LANE_ALARMS = 'lane_alarms',
  MACHINE_OVERVIEW_WIDGET = 'machine_overview_widget',
  MACHINE_OVERVIEW_FLYOUT_WIDGET = 'machine_overview_flyout_widget',
  MACHINE_OVERVIEW_FLYOUT_ZONE_WIDGET = 'machine_overview_flyout_zone_widget',
  MACHINE_OVERVIEW_ZONE_WIDGET = 'machine_overview_zone_widget',
  machineUtilization = 'machine_utilization',
  MATRIX_WIDGET = 'matrix_widget',
  MATRIX_WIDGET_GROUP = 'matrix_widget_group',
  MENU = 'menu',
  OEE = 'oee',
  PAGE = 'page',
  PRODUCTION_METRICS = 'production_metrics',
  RATIO_CHART = 'ratio_chart',
  STATE = 'state',
  STRUCTURE = 'structure',
  SUBMENU = 'submenu',
  TAG_GROUP = 'tag_group',
  TAG_GROUP_BUTTON_ROW = 'tag_group_button_row',
  TAG_BUTTON_ROW = 'tag_button_row',
  THROUGHPUT = 'throughput',
  TORQUE_CHART = 'torque_chart',
  UNKNOWN = 'unknown',
  WIDGET_BUTTON_ROW = 'widget_button_row'
}

// export enum AsepticMachineHealthType {
//   Foil = 'Foil',
//   MachineInfo = 'MachineInfo',
//   LaneAlarms = 'LaneAlarms',
//   MachineUtilization = 'MachineUtilization',
//   Throughput = 'Throughput',
//   OEE = 'OEE'
// }

export enum AsepticMachineHealthInterval {
  Last24Hours = 'LAST_DAY',
  Last8Hours = 'LAST_8_HOURS',
  Last7Days = 'LAST_7_DAYS',
  Last3Days = 'LAST_3_DAYS'
}

/**
 * DSI
 */

export type DSIMachineType = 'Nuggets 9065' | 'Fillets 8293';

export type MachineHealthKpiItem = {
  type: string;
  id: string;
  unit: string;
  value: {
    timestamp: string;
    value: number;
    endTimestamp: string;
    target?: number;
    status?: string;
  };
  values: {
    timestamp: string;
    value: number;
    endTimestamp: string;
    target?: number;
    status?: string;
    appName?: string[];
    psuName?: string;
  }[];
};

export interface MachineHealthKpiQueryParams extends BaseType {
  machineId: string;
  kpiDataInterval: string;
  widgetType: string;
  limit: number;
  includeHistoricData: boolean;
  skipValue?: number;
  totalValuesDesired?: number;
}

export interface MachineHealthProductTypeKpiQueryParams extends BaseType {
  startDatetime: string;
  endDatetime: string;
}

export interface FavoriteMachinesParams {
  id: string;
  description: string;
  name: string;
}

export interface ContractData {
  machineId: string;
  contractName: string;
  recordType: string;
  salesforceMachine_id: string;
  startDate: Date;
  endDate: Date;
}
