import React, { ReactElement } from 'react';
import styled from 'styled-components';
// Components
import { ContextAwareCart, SavedProductList, SidebarNav } from 'components';
import jbtOfficialLogo from '../../img/OmniBlu Official Logo.svg';
import jbtLogo from '../../img/JBT_logo.svg';
import TextBadge from '../BadgeWithText/TextBadge';
import breakpoint from 'constants/breakpoints';
import DropdownSelect from '../DropdownMenu/DropdownSelect';
import { useUser } from 'selectors';
import { JBTRoutes } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { FleetNavigationProvider } from 'providers';
import { Role, SidenavItemProps } from 'types';

interface HeaderProps {
  items: SidenavItemProps[];
  role?: Role.Admin | Role.User;
}

// Commenting out the functionality related to search and notification.The comments will be removed once required

// const Searchbox = styled.div`
//   width: 60%;
//   height: 40px;
//   @media ${breakpoint.device.lg} {
//     width: 50%;
//   }
//   @media ${breakpoint.device.md} {
//     width: 60%;
//   }
//   @media ${breakpoint.device.sm} {
//     width: 100%;
//   }
// `;

const OuterHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 60px;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 990;
  padding-left: 15px;
`;
const InnerLogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;
const ImgContainer = styled.div`
  padding-top: 3px;
  width: 60%;
  cursor: pointer;
`;
const Divider = styled.div`
  width: 2px;
  height: 28px;
  background-color: #e5e9ed;
`;
const IconContainer = styled.div`
  display: flex;
  gap: 25px;
  justify-content: flex-end;
  padding-right: 15px;
  align-items: center;
`;
const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  @media ${breakpoint.device.md} {
    width: 26%;
  }
`;
const IconInnerContainer = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  @media (max-width: 540px) {
    display: none;
  }
`;

const SidebarNavMobile = styled.div`
  @media (min-width: 541px) {
    display: none;
  }
`;

// const SearchContainer = styled.div`
//   width: 34%;
//   display: flex;
//   align-items: center;
//   @media ${breakpoint.device.xs} {
//     width: 32%;
//   }

//   @media ${breakpoint.device.sm} {
//     width: 32%;
//   }

//   @media ${breakpoint.device.md} {
//     width: 30%;
//   }
// `;

const Header = ({ role, items }: HeaderProps): ReactElement => {
  const headerColor = process.env.REACT_APP_HEADER_COLOR;
  // const searchColor = process.env.REACT_APP_SEARCH_COLOR;
  // const headerSearch = true;
  // const handleSearchChange = () => {
  //   console.log('search');
  // };

  const user = useUser();
  const history = useHistory();
  return (
    <OuterHeaderContainer style={{ backgroundColor: headerColor }}>
      <InnerContainer>
        <SidebarNavMobile>
          <FleetNavigationProvider>
            <SidebarNav items={items} role={role} mobileView={true} />
          </FleetNavigationProvider>
        </SidebarNavMobile>
        <InnerLogoContainer>
          <img src={jbtLogo} alt="jbtLogo" />
          <Divider></Divider>
          <ImgContainer
            onClick={() => {
              history.push(JBTRoutes.dashboard);
            }}
          >
            <img src={jbtOfficialLogo} alt="jbtLogo" />
          </ImgContainer>
        </InnerLogoContainer>
      </InnerContainer>
      {/* <SearchContainer>
        <Searchbox>
          <SearchInput
            headerSearch={headerSearch}
            searchColor={searchColor}
            placeholder={'Search'}
            onChange={handleSearchChange}
            borderRadius="0.5rem"
          />
        </Searchbox>
      </SearchContainer> */}

      <IconContainer>
        <IconInnerContainer>
          <DropdownSelect />
          <SavedProductList />
        </IconInnerContainer>
        <ContextAwareCart />

        {/* <Notification /> */}
        <TextBadge name={`${user?.firstName} ${user?.lastName}`} />
      </IconContainer>
    </OuterHeaderContainer>
  );
};

export default Header;
