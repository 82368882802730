import React, { ReactNode, createContext, useContext } from 'react';

import { useGetAccountInfoQuery } from 'api';
import { useFleetRouter } from '../hooks/useFleetRouter';
import { DataLoader } from 'common/components/DataLoader/DataLoader';

export interface UseAccountInfoPropsConnectionStatus {
  watchdog?: string;
  lastKnownConnected?: string;
  gateway?: string;
}

export interface UseAccountInfoProps {
  isLoading?: boolean;
  hasMessage?: ReactNode;
  hasError?: ReactNode;
  [key: string]: unknown;
}

const AccountInfoContext = createContext<UseAccountInfoProps>({
  isLoading: true
});

export const useAccountInfo = (): UseAccountInfoProps => useContext(AccountInfoContext);

interface Props extends UseAccountInfoProps {
  children?: ReactNode | ReactNode[];
  businessUnit?: string;
}

export const AccountInfoProvider = ({ children }: Props): JSX.Element => {
  const [{ id }] = useFleetRouter();

  const { data, isLoading, error } = useGetAccountInfoQuery({ machineId: id as string });
  const hasError = !error ? undefined : 'error getting data from useGetAcccountInfoQuery';
  const hasMessage = !data && !isLoading && !hasError ? 'no account info' : undefined;

  return (
    <DataLoader {...{ isLoading, hasError, hasMessage }} className="account-info-provider-loader">
      <AccountInfoContext.Provider value={{ ...data }}>
        {data && children}
      </AccountInfoContext.Provider>
    </DataLoader>
  );
};

export const demoData2 = {
  id: 'dce5e9f4-bf5c-4b85-b506-e6183f2cd225',
  salesforceId: '02i1R000008CQixQAG',
  plantId: '00136000016vHABAA2',
  orgId: '00136000016vHABAA2',
  warrantyExpired: null,
  nickname: null,
  order: null,
  description: 'FNDS-Aseptic-Linear Filler-N.00312.01',
  serialNumber: 'N.00312.01',
  tags: [],
  procare: false,
  companyName: 'STEUBEN FOODS INC',
  siteName: 'ELMA',
  lineName: 'Line',
  connectionStatus: {
    watchdog: 'never-connected',
    lastKnownConnected: null,
    gateway: null
  },
  currProdState: 'Production Stopped',
  bottleneck: false,
  machineTypeCd: 'LF',
  workspaceId: 'ec895d04-4ad8-4ebb-9cc5-6c46f19f7a2d',
  reportId: '40ffb35a-e95a-4f72-9771-77995635cdfd',
  powerBiList: [
    {
      machineId: 'b99026cc-c57f-457c-a377-6e17ecfdf680',
      workspaceId: 'ec895d04-4ad8-4ebb-9cc5-6c46f19f7a2d',
      reportId: 'f2698d23-bf96-4b9f-8cba-a703527bd2fe',
      machineDescription: 'Weekly Reports'
    },
    {
      machineId: 'b99026cc-c57f-457c-a377-6e17ecfdf680',
      workspaceId: '1fa8ff31-5759-4fef-b225-7a45270323ef',
      reportId: '6972b205-1ab7-4ef7-93e5-f053353a568d',
      machineDescription: 'Daily Dose'
    },
    {
      machineId: 'dce5e9f4-bf5c-4b85-b506-e6183f2cd225',
      workspaceId: 'ec895d04-4ad8-4ebb-9cc5-6c46f19f7a2d',
      reportId: '40ffb35a-e95a-4f72-9771-77995635cdfd',
      machineDescription: 'FNDS-Aseptic-Linear Filler-N.00312.01'
    }
  ]
};
