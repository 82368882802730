import { StyledUiContainerProps } from 'components';
import styled, { css } from 'styled-components';
import theme from 'common/theme';

const squaresLoaderDuration = 1200;

export const SquaresLoader = css`
  display: grid;
  gap: 0.2em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 3em;
  height: 3em;

  .square {
    border-radius: 3px;
    animation-name: 'in-out';
    animation-iteration-count: infinite;
    animation-direction: forwards;
    opacity: 0;

    animation-duration: ${squaresLoaderDuration}ms;

    &.grid-1 {
      grid-row: 1;
      grid-column: 1;
    }

    &.grid-2 {
      grid-row: 1;
      grid-column: 2;
      animation-delay: ${squaresLoaderDuration * 0.25}ms;
    }

    &.grid-3 {
      grid-row: 2;
      grid-column: 1;
      animation-delay: ${squaresLoaderDuration * 0.75}ms;
    }

    &.grid-4 {
      grid-row: 2;
      grid-column: 2;
      animation-delay: ${squaresLoaderDuration * 0.5}ms;
    }

    &.blue {
      background-color: blue;
    }

    &.purple {
      background-color: purple;
    }
  }

  @keyframes in-out {
    0 {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const DataLoaderContainer = styled.div<StyledUiContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  flex-direction: column;
  gap: 1.5em;

  .loader-message {
    font-weight: 500;
    color: ${theme.colors.label};
    &::first-letter {
      text-transform: capitalize;
    }
  }

  .icon {
    max-height: 85px;
    width: auto;
    height: 100%;
  }

  grid-row: ${({ gridRows }) => gridRows};
  grid-column: ${({ gridCols }) => gridCols};

  &.type--dots,
  &.type--dashes {
    opacity: 0.3;
  }

  &.type--squares {
    .squares-loader {
      ${SquaresLoader};
    }
  }
`;
