// 3rd party libs
import React, { ReactNode, useState } from 'react';

// Components
import { WidgetHeader, WidgetMain, Types } from './';
import { UiWidget as Ui, useDateRange } from '../';
import { Modal } from 'components';
import { DateRangePicker } from '../DateRange/DateRangePicker/DateRangePicker';
import { timeDisplayFormatter } from './timeDisplayFormatter';
import styled from 'styled-components';
// Hooks
import { useContainerSize } from 'hooks';

// Types
import { DateRangeProps } from '../DateRange/DateRangePicker/DateRangePicker.types';
import { ModalSize } from 'types';
import DropdownMenu from 'components/AccumulatedValues/DropdownMenu';
import WidgetCustomDropdown from './WidgetCustomDropdown';
import { useTranslation } from 'react-i18next';

interface dropDownItemProps {
  title?: string;
  onClick?: () => void;
  icon?: JSX.Element;
}

interface Props extends Ui.UiProps, Types.Props {
  children?: ReactNode | ReactNode[];
  subtractDaysCount?: number;
  linksToPathTooltipContent?: string;
  titleIndicator?: ReactNode | ReactNode[];
  showIconHelper?: boolean;
  headerBackgroundColor?: string;
  ga?: string;
  dateRange?: DateRangeProps;
  hasGoBackDateLimit?: number;
  hasMenu?: boolean;
  setIsEditAdminPopupOpen?: (data: boolean) => void;
  noPad?: boolean;
  hasCustomizer?: boolean;
  customDropdownItems?: dropDownItemProps[];
  hasCustomMenu?: boolean;
  itemCount?: number;
  machineId?: string;
}

const ViewingTime = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  position: sticky;
  bottom: 0;
  background: #fff;
`;

const Calendar = ({
  setShowCalendar,
  showCalendar,
  hasGoBackDateLimit,
  headline
}: {
  headline?: string;
  setShowCalendar: (x: boolean) => void;
  dayRanges?: number[];
  showCalendar?: boolean;
  subtractDaysCount?: number;
  hasGoBackDateLimit?: number;
}): JSX.Element => {
  const { dateRange, setDateRange } = useDateRange();

  return (
    <Modal
      allowContentScroll
      title={headline}
      visible={showCalendar ? true : false}
      onClose={() => setShowCalendar(false)}
      size={ModalSize.SM}
      maxWidth="502px"
      contentBorderRadius="0"
    >
      <DateRangePicker
        handleSubmit={(range: DateRangeProps) => {
          setDateRange({
            startTime: range.startTime,
            endTime: range.endTime
          });
          setShowCalendar(false);
        }}
        handleCancel={() => setShowCalendar(false)}
        dateRange={{
          startTime: dateRange.startTime || new Date(),
          endTime: dateRange.endTime || new Date()
        }}
        hasGoBackDateLimit={hasGoBackDateLimit}
      />
    </Modal>
  );
};

export const DashboardWidget = ({
  children,
  dayRanges,
  hasDatePicker,
  hasMenu,
  hasFlyOut,
  hasStatusIcon,
  hasError,
  hasGoBackDateLimit,
  headerBackgroundColor,
  isAdminWidget,
  isCentered,
  isLoading,
  Load,
  linksToPath,
  linksToPathTooltipContent,
  Main,
  onAdminButtonClickCallback,
  dateRange,
  showDateRange,
  SubTitle,
  showIconHelper,
  refs,
  timeZone,
  title,
  titleIndicator,
  ga,
  noPad,
  hasCustomizer,
  setIsEditAdminPopupOpen,
  hasCustomMenu,
  customDropdownItems,
  itemCount,
  machineId
}: Props): JSX.Element => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [showMenuOptions, setShowMenuOptions] = useState<boolean>(false);
  const [showCustomMenuOptions, setShowCustomMenuOptions] = useState<boolean>(false);
  const { t } = useTranslation(['mh']);
  // make ref to man section, load it and send to main component
  const { width, height, containerRef: refMain } = useContainerSize();

  const handleToggleCalendar = () => (hasDatePicker || hasMenu) && setShowCalendar(!showCalendar);
  const handleToggleMenu = () => hasMenu && setShowMenuOptions(!showMenuOptions);
  const handleToggleCustomMenu = () =>
    hasCustomMenu && setShowCustomMenuOptions(!showCustomMenuOptions);

  // check to see if a <header> wrapper is needed and styles the rest accordingly
  const hasHeader =
    hasDatePicker ||
    hasMenu ||
    linksToPath ||
    showDateRange ||
    hasFlyOut ||
    hasStatusIcon ||
    isAdminWidget ||
    showIconHelper ||
    hasCustomMenu ||
    hasError ||
    titleIndicator
      ? true
      : false;
  // does the content need to be centered?  typcially used for error or loading
  isCentered = isCentered || isLoading || hasError ? true : false; // send to Ui.Main

  const uiContainer = {
    // setting up styles to pass to Ui.Container
    hasHeader: hasHeader || title ? true : false,
    ref: refs?.refHeader, // send any incoming ref
    isLoading, // set ariaBusy if loading
    ga // grid-area
  };

  const uiHeader = {
    // setting up styles to pass to Ui.Header
    hasRight:
      hasDatePicker ||
      linksToPath ||
      isAdminWidget ||
      hasDatePicker ||
      showIconHelper ||
      hasCustomMenu ||
      hasFlyOut ||
      hasMenu
        ? true
        : false,
    hasLeft: hasStatusIcon ? true : false,
    ref: refs?.refHeader, // send any incoming ref,
    headerBackgroundColor: headerBackgroundColor
  };

  const widgetHeader = {
    isLoading,
    hasError,
    timeZone,
    title,
    titleIndicator,
    SubTitle,
    hasDatePicker,
    hasMenu,
    hasFlyOut,
    linksToPath,
    linksToPathTooltipContent,
    dateRange,
    showDateRange,
    showIconHelper,
    handleToggleCalendar,
    handleToggleMenu,
    hasCustomMenu,
    handleToggleCustomMenu,
    onAdminButtonClickCallback,
    containerRef: refs?.refHeader,
    isAdminWidget,
    setIsEditAdminPopupOpen,
    hasCustomizer,
    itemCount,
    isMenuActive: showMenuOptions || showCustomMenuOptions
  };

  // set headline if widget has a popup
  const calendarPopupHeadline = title && `Selecting date range for ${title}`;

  SubTitle = showDateRange
    ? `${timeDisplayFormatter({ ...dateRange, useForDisplay: true })}${
        timeZone ? ` (${timeZone})` : ''
      } `
    : SubTitle;

  return (
    // get the divs/refs loaded to dom before return children
    <Ui.Container {...uiContainer} gridStyle>
      {!hasHeader ? (
        title && (
          // only load h3 tag if there's only a title, otherwise wrap in a </header> tag
          <Ui.Title>
            {title}
            {SubTitle && !hasError && !isLoading && <Ui.SubTitle>{SubTitle}</Ui.SubTitle>}
          </Ui.Title>
        )
      ) : (
        // needs the <header> wrapper
        <Ui.Header {...uiHeader}>
          <WidgetHeader {...widgetHeader} />
        </Ui.Header>
      )}

      <Ui.Main {...{ isCentered, ref: refMain, hasError }} hasHeader noPad={noPad}>
        <WidgetMain {...{ Main, isLoading, Load, width, height, hasError }}>{children}</WidgetMain>

        {SubTitle && !hasError && !isLoading && (
          <ViewingTime>
            <Ui.SubTitle iconType="history-clock">
              <span className="date-label">{t('Viewing')}:</span> {SubTitle}
            </Ui.SubTitle>
          </ViewingTime>
        )}
      </Ui.Main>

      <Calendar
        {...{
          setShowCalendar,
          showCalendar,
          dayRanges,
          headline: calendarPopupHeadline,
          hasGoBackDateLimit
        }}
      />

      <WidgetCustomDropdown
        customDropdownItems={customDropdownItems}
        showMenu={showCustomMenuOptions}
        setShowMenuOptions={setShowCustomMenuOptions}
      />

      {hasMenu ? (
        <DropdownMenu
          machineId={machineId}
          showMenu={showMenuOptions}
          setShowMenuOptions={setShowMenuOptions}
          setIsEditAdminPopupOpen={setIsEditAdminPopupOpen}
          handleToggleCalendar={handleToggleCalendar}
        />
      ) : (
        ''
      )}
    </Ui.Container>
  );
};
