import React from 'react';
import styled from 'styled-components';
const Marker = styled.span<{ size?: string }>`
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
`;
const RequiredMark = ({ color, size }: { color?: string; size?: string }): JSX.Element => {
  return (
    <Marker size={size} color={color}>
      *
    </Marker>
  );
};

export default RequiredMark;
