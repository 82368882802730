// 3rd party
import React, { ReactElement } from 'react';
import styled from 'styled-components';

// Components
import { Loader } from 'components';
import { useCart } from 'selectors';
import { toast } from 'react-toastify';

// Hooks
import { useTranslation } from 'react-i18next';

// Styling
const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.75rem 0.625rem 2.5rem 2.375rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.25rem;
  overflow: hidden;
`;

const FileContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
`;

const Iframe = styled.div`
  width: 106%;
  height: 122%;
  position: absolute;
  overflow: hidden;
  transform: translate(-4rem, -3.5rem);
`;

// Props
interface Props {
  orderId?: string;
  orderUrl?: string;
}

const CartOrderReview = ({ orderUrl }: Props): ReactElement => {
  const cart = useCart();
  const { t } = useTranslation(['fpns']);
  if (cart && cart.items.length > 0) {
    toast.warn(t('warning_order_quote'), {
      autoClose: 30000,
      toastId: 'warning-order-quote',
      hideProgressBar: false,
      style: { width: '500px' }
    });
  }
  return orderUrl ? (
    <Container>
      <FileContainer>
        <Iframe>
          <iframe
            frameBorder={'0'}
            src={`${orderUrl}#view=fitH&toolbar=0`}
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowTransparency
          />
        </Iframe>
      </FileContainer>
    </Container>
  ) : (
    <Loader />
  );
};

export default CartOrderReview;
