import { useState, useEffect, useRef, RefObject } from 'react';
export interface UseContainerSizeReturnProps {
  width: number;
  height: number;
  triggerResize?: () => void;
  divRef?: RefObject<HTMLDivElement>;
}

const defaultTime = 250;

const debounce = (
  func: (x: Record<string, unknown>) => void,
  wait: number
): ((x: Record<string, unknown>) => void) => {
  let timeout: NodeJS.Timeout | null = null;
  return (x: Record<string, unknown>) => {
    const later = () => {
      timeout && clearTimeout(timeout);
      func(x);
    };
    timeout && clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const useContainerSize = (
  ref: RefObject<HTMLElement>
): { width: number; height: number } => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const observer = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    // make sure ResizeObserver is supported
    if (typeof ResizeObserver === 'undefined') return;
    // don't debounce on the first load, by setting the timer to 5ms
    let timer = size.width === 0 || size.height === 0 ? 5 : defaultTime;
    // debounce the setSize function
    const debouncedSetSize = debounce(({ width, height }) => {
      if (width === size.width || height === size.height) timer = 0;
      return setSize({ width: Number(width), height: Number(height) });
    }, timer);

    observer.current = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      const { contentRect } = entries[0];
      // Call the debounced setSize function
      return debouncedSetSize({ width: contentRect.width, height: contentRect.height });
    });

    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [ref]);

  return size;
};

export default useContainerSize;
