import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

// Components
import { DashboardWidget, PermissionWrapper, useDateRange } from 'components';

// Api
import {
  useCreateMachineConfigurationDataMutation,
  useGetMachineCleaningMetricsQuery,
  useGetMachineConfiguratorDataQuery,
  useGetMachineProductionMetricsQuery,
  useGetMachineWidgetDataQuery
} from 'api';

// Types
import {
  MachineHealthSubTabs,
  MachineHealthTabs,
  ProteinMachineRouteQueryParams,
  WidgetType
} from 'types/protein';

// Utils
import { KpiContainer } from 'components/machine-health/MachineKpi';
import RowRenderer from 'components/machine-health/RowRenderer';
import { useContainerSize } from 'hooks';
import { isNumber } from 'lodash';
import { formatDuration } from 'helpers';
import { AdminCardEditButton, DataRenderer } from 'components/machine-health';
import { useLanguage } from 'providers';
import { KpiRow, WidgetTableDropdownItem } from 'types/machine-health';
import { PermissionScopeName } from 'types/user-management';
import { Role, UserScopes } from 'types';
import { calculateAccumulatedValue } from 'components/machine-health/MachineKpi/utils';

const renderer = (metric?: number) => {
  if (!isNumber(metric)) return null;
  return `${formatDuration(metric, 'hours:mins')}`;
};

const AccumulatedValues = (): JSX.Element => {
  const { machineId } = useParams<ProteinMachineRouteQueryParams>();
  const { t } = useTranslation(['mh']);
  const { dateRange } = useDateRange();
  const { languageId } = useLanguage();
  const startDatetime = dateRange.startTime.toISOString();
  const endDatetime = dateRange.endTime.toISOString();
  const { data: machineProductionTime } = useGetMachineProductionMetricsQuery({
    machineId,
    startDatetime,
    endDatetime
  });

  const { data: machineCleaningTime } = useGetMachineCleaningMetricsQuery({
    machineId,
    startDatetime,
    endDatetime
  });

  const [createMachineConfigurationData] = useCreateMachineConfigurationDataMutation();
  const {
    data: overviewWidgetData,
    isFetching: overviewWidgetDataIsFetching,
    error: overviewWidgetDataError
  } = useGetMachineConfiguratorDataQuery({
    machineId,
    labels: [MachineHealthTabs.Overview],
    languageId: languageId,
    startDatetime,
    endDatetime
  });

  const createWidgetConfiguration = async (parent_id: string) => {
    await createMachineConfigurationData({
      machineId,
      configuration: {
        id: uuidv4(),
        name: 'Accumulated Values',
        names: {
          en: 'Accumulated Values'
        },
        label: MachineHealthSubTabs.AccumulatedValues,
        widgetClass: 'widget',
        widgetType: WidgetType.KpiCard,
        editable: false,
        deletable: false,
        active: true,
        toggleActive: true,
        reorder: false,
        parent_id,
        tags: []
      },
      languageId: languageId
    })
      .unwrap()
      .catch((error) => {
        console.error(error?.data?.detail);
      });
  };

  const { data } = useGetMachineConfiguratorDataQuery({
    machineId,
    labels: [MachineHealthSubTabs.AccumulatedValues],
    languageId: languageId,
    startDatetime,
    endDatetime
  });

  useEffect(() => {
    if (overviewWidgetData && overviewWidgetData.length > 0) {
      const targetItem = overviewWidgetData[0].members?.find(
        (item) => item.label === MachineHealthSubTabs.AccumulatedValues
      );
      if (!targetItem) {
        // POST call to add widget data
        createWidgetConfiguration(overviewWidgetData[0].id);
      }
    }
  }, [overviewWidgetData]);

  const { height, containerRef: kpisContainerRef } = useContainerSize();
  const [isEditAdminPopupOpen, setIsEditAdminPopupOpen] = useState<boolean>(false);
  const handleIsEditAdminPopupOpen = () => {
    setIsEditAdminPopupOpen(!isEditAdminPopupOpen);
  };

  const {
    data: widgetData,
    isFetching: isFetchingWidgetData,
    error: errorWidgetData
  } = useGetMachineWidgetDataQuery({
    machineId,
    widgetId: (data && data[0]?.id) || '',
    includeTagValues: true,
    languageId: languageId,
    pollingInterval: 5000,
    startDatetime,
    endDatetime
  });

  const accumulatedWidgetTags = [] as KpiRow[];
  accumulatedWidgetTags[0] = {
    label: t('production_time') as string,
    value: {
      key: 'production_time',
      content:
        (renderer(machineProductionTime?.productionTime) as string) ||
        (t('not_available', { ns: 'common' }) as string)
    }
  };

  accumulatedWidgetTags[1] = {
    label: t('cleaning_time') as string,
    value: {
      key: 'cleaning_time',
      content:
        (renderer(machineCleaningTime?.cleaningTime) as string) ||
        (t('not_available', { ns: 'common' }) as string)
    }
  };

  widgetData?.tags?.forEach(function (item: WidgetTableDropdownItem) {
    accumulatedWidgetTags.push({
      label: (item.name as string) || (item.id as string),
      value: {
        key: item.id as string,
        content:
          Array.isArray(item.values) && item.values.length > 0
            ? calculateAccumulatedValue(item, item?.meta?.dataType || '', item?.unit || '')
            : (t('not_available', { ns: 'common' }) as string)
      }
    });
  });

  const widgetSettings = {
    title: t('consumption_values'),
    isLoading: isFetchingWidgetData || overviewWidgetDataIsFetching,
    hasError: (errorWidgetData || overviewWidgetDataError) && 'Failed to load Accumulated Values',
    hasMenu: true,
    isEditAdminPopupOpen: isEditAdminPopupOpen,
    setIsEditAdminPopupOpen: setIsEditAdminPopupOpen,
    dateRange,
    showDateRange: true,
    noPad: true,
    machineId: machineId
  };

  return (
    <DashboardWidget {...widgetSettings}>
      {data?.[0]?.id && (
        <PermissionWrapper
          page={PermissionScopeName.FLEET}
          scope={UserScopes.Write}
          role={Role.Admin}
        >
          <AdminCardEditButton
            hideInactive
            machineId={machineId}
            widgetId={data[0].id}
            isEditAdminPopupOpen={isEditAdminPopupOpen}
            setIsEditAdminPopupOpen={handleIsEditAdminPopupOpen}
          />
        </PermissionWrapper>
      )}
      <KpiContainer ref={kpisContainerRef}>
        <DataRenderer
          isLoading={isFetchingWidgetData || overviewWidgetDataIsFetching}
          error={
            (errorWidgetData || overviewWidgetDataError) &&
            (t('failed_to_load_data', { ns: 'common' }) as string)
          }
        >
          <RowRenderer
            isCustomRowHeight={true}
            customRowHeight="2.5rem"
            rows={accumulatedWidgetTags}
            containerHeight={height}
          />
        </DataRenderer>
      </KpiContainer>
    </DashboardWidget>
  );
};
export default AccumulatedValues;
