import React from 'react';

export const AddUserIcon = (): JSX.Element => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="
    http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6126_22105)">
      <path
        d="M5.83325 4.66667C5.83325 5.37391 6.1142 6.05219 6.6143 6.55229C7.1144 7.05238 7.79267 7.33333 8.49992 7.33333C9.20716 7.33333 9.88544 7.05238 10.3855 6.55229C10.8856 6.05219 11.1666 5.37391 11.1666 4.66667C11.1666 3.95942 10.8856 3.28115 10.3855 2.78105C9.88544 2.28095 9.20716 2 8.49992 2C7.79267 2 7.1144 2.28095 6.6143 2.78105C6.1142 3.28115 5.83325 3.95942 5.83325 4.66667Z"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1667 12.6666H15.1667"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1667 10.6666V14.6666"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 14V12.6667C4.5 11.9594 4.78095 11.2811 5.28105 10.781C5.78115 10.281 6.45942 10 7.16667 10H9.83333"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6126_22105">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
