import React, { useRef, useEffect, useState, ReactElement } from 'react';
import OverflowMenu from './OverflowMenu';
import './intersection-observer-wrapper.css';

interface Props {
  children: ReactElement | ReactElement[];
}

const IntersectionObserverWrapper = ({ children }: Props): JSX.Element => {
  const navRef = useRef(null);
  const [visibilityMap, setVisibilityMap] = useState({});
  const handleIntersection = (entries) => {
    const updatedEntries = {};
    entries.forEach((entry) => {
      const targetid = entry.target.dataset.targetid;
      if (entry.isIntersecting) {
        updatedEntries[targetid] = true;
      } else {
        updatedEntries[targetid] = false;
      }
    });

    setVisibilityMap((prev) => ({
      ...prev,
      ...updatedEntries
    }));
  };
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: 1
    });

    // addting observers to child elements of the container div with ref as navRef.
    // adding observers only if the child elemeent has data-targetid
    Array.from(navRef.current.children).forEach((item) => {
      if (item.dataset.targetid) {
        observer.observe(item);
      }
    });
    return () => observer.disconnect();
  }, []);
  return (
    <div className="toolbar-wrapper" ref={navRef}>
      {React.Children.map(children, (child) => {
        const isVisible = !!visibilityMap[child?.props['data-targetid']];
        const childClassName = isVisible ? 'visible' : 'invisible';
        if (child) {
          return React.cloneElement(child, {
            className: `${child.props.className ? child.props.className : ''} ${childClassName}`
          });
        }
      })}
      <OverflowMenu visibilityMap={visibilityMap}>{children}</OverflowMenu>
    </div>
  );
};

export default IntersectionObserverWrapper;
