import { styledTheme } from 'components';
import styled from 'styled-components';
import iconCheckMark from 'img/IconCheckMark.svg';

export const DateRangesSelectDropdownContainer = styled.div`
  position: relative;
  font-size: 0.8em;

  z-index: 1;

  &:hover {
    .date-ranges-select-dropdown__dropdown-area {
      display: flex;
    }
    .date-ranges-select-dropdown__view-box {
      border-color: ${styledTheme.color.secondary};
    }
  }

  .date-ranges-select-dropdown__view-box {
    padding: 0 0.5em 0.3em 0.5em;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.8em;
    align-items: center;
    cursor: pointer;
    color: rgba(102, 102, 102, 1);
    text-transform: capitalize;
    border-bottom: solid 2px transparent;
    .icon-chevron {
      width: 0.7em;
      path {
        fill: ${styledTheme.color.main};
      }
    }
  }

  .date-ranges-select-dropdown__dropdown-area {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    min-width: 100%;
    width: max-content;
    flex-direction: column;
    background-color: white;
    z-index: 1;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
    border-radius: 5px;

    &:focus {
      display: none;
    }

    button {
      display: grid;
      grid-template-columns: auto 1fr;
      background-color: transparent;
      padding: 0.5em 0.8em;
      border: 0;
      text-align: left;
      cursor: pointer;
      font-family: inherit;
      justify-content: center;
      gap: 0.5em;
      text-transform: capitalize;

      &:before {
        content: '';
        display: block;
        width: 1.2em;
        height: 1.2em;
        border-radius: 50%;
        margin-right: 0.5em;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.is-selected:before {
        background-image: url(${iconCheckMark});
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
`;
