// 3rd party libs
import React, { ReactElement, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from 'themes';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import styled from 'styled-components';
import { isEmpty, lowerCase, sortBy } from 'lodash';

// Components
import { Column, Row, Typography } from 'components';

import { DropdownWithCheckboxes } from 'pages/ProteinMachine/MachineConfig/Common/Alerts/DropdownWithCheckboxes';

// Types
import {
  GroupOrganization,
  GroupPlant,
  GroupScope,
  LinesById,
  ScopeCount
} from 'types/user-management';

// Utils
import {
  getGroupOrgOptions,
  getGroupPlantOption,
  getGroupPlantOptions,
  getGroupPlantsByOrg,
  getMachinesByGroupOrgOptions,
  getMachinesByGroupPlantOptions,
  getMachinesByOrgOptions,
  getMachinesByPlantOptions,
  getNewPlants,
  getOrgById,
  getPlantById,
  getScopeCounts,
  getSelectOptions
} from 'pages/UserManagementTwo/components/Group/AddEditGroup/Scope/ScopeUtils';
import { Organization, Plant } from 'types';
import {
  ClearIndicator,
  Option,
  DropdownIndicator,
  ClearValues
} from 'pages/ProteinMachine/MachineConfig/Common/Alerts/DropdownWithCheckboxes';
import { MandatoryIndicator } from '..';

interface Props {
  onScopeDetailChange: (scope: GroupScope) => void;
  allOrganizations: Organization[];
  allPlants: Plant[];
  allLines: LinesById;
  internalAccessGroup: boolean;
  setCounts: (counts: ScopeCount) => void;
  setScopeState: React.Dispatch<React.SetStateAction<GroupScope>>;
  scopeState: GroupScope;
}

const OrganizationContainer = styled.fieldset`
  margin-bottom: 1rem;
  border: 0.063rem solid ${theme.colors.lightGrey4};
  border-radius: 0.313rem;
`;
const OrganizationNameText = styled.legend`
  font-weight: bold;
  padding: 0px 4px;
`;
const PlantsContainer = styled.div`
  position: relative;
`;

const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1.25rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;
const SelectHeaderText = styled.div`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.3rem;
`;
const OrganizationFooter = styled.div`
  position: relative;
  margin-top: -1rem;
`;
const TrashBox = styled.div`
  display: flex;
  cursor: pointer;
  width: 4rem;
  float: right;
  margin-top: 0.27rem;
`;
const IconText = styled.div`
  margin-left: 0.625rem;
`;
const OrganizationFooterButtonBox = styled.div`
  width: 4rem;
  height: 1.5rem;
`;
const OrganizationButton = styled.div`
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  button:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
`;
const ItemLabel = styled.div`
  display: flex;
  flex-gap: 0;
`;
const CustomerSelect = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;
const ScopeDetail = ({
  allOrganizations,
  allPlants,
  allLines,
  onScopeDetailChange,
  internalAccessGroup,
  setCounts,
  setScopeState,
  scopeState
}: Props): ReactElement => {
  const activeSelection = useRef({ value: '', plantIndex: 0 });
  const updateOrganizations = (items: { label: string; value: string }[]) => {
    const orgItems: GroupOrganization[] = [];
    let isAllOrg = false;
    items.forEach((item) => {
      if (item.value === 'All') isAllOrg = true;
      const groupOrg = scopeState.organizations.find((org) => org.organizationId === item.value);
      const orgItem = {
        organizationId: item.value,
        value: item.value,
        label: item.label,
        allPlants: groupOrg?.allPlants ?? false,
        allMachines: groupOrg?.allMachines ?? false,
        plants: groupOrg?.plants ?? [],
        machines: [],
        selectedPlants: []
      };
      orgItems.push(orgItem);
    });

    const newScopeState = {
      ...scopeState,
      organizations: isAllOrg ? [] : orgItems,
      allOrganizations: isAllOrg
    };
    setScopeState(newScopeState);
    onScopeDetailChange(newScopeState);
    setCounts(getScopeCounts(allOrganizations ?? [], allPlants ?? [], newScopeState));
  };

  const updatePlants = (organizationId: string, newPlants: GroupPlant[], isAllPlants: boolean) => {
    const organizations = scopeState.organizations.map((organization) => {
      if (organization.organizationId === organizationId) {
        return {
          ...organization,
          allPlants: isAllPlants,
          allMachines: false,
          plants: isAllPlants ? [] : newPlants
        };
      }
      return organization;
    });
    setScopeState((scopeState) => ({
      ...scopeState,
      organizations: organizations
    }));
    setCounts(
      getScopeCounts(allOrganizations ?? [], allPlants ?? [], {
        ...scopeState,
        organizations: organizations
      })
    );
    onScopeDetailChange({
      ...scopeState,
      organizations: organizations
    });
  };

  const updateMachines = (
    organizationId: string,
    plantId: string,
    newMachines: string[],
    isAllMachines: boolean,
    isAllPlants: boolean
  ) => {
    const organizations = scopeState.organizations.map((organization) => {
      if (organization.organizationId === organizationId) {
        return {
          ...organization,
          machines: isAllPlants && !isAllMachines ? newMachines : [],
          allMachines: isAllPlants && isAllMachines,
          plants: organization?.plants?.map((plant) => {
            if (plant.plantId === plantId) {
              return {
                ...plant,
                allMachines: isAllMachines,
                machines: isAllMachines || isAllPlants ? [] : newMachines
              };
            }
            return plant;
          })
        };
      }
      return organization;
    });
    setScopeState((scopeState) => ({
      ...scopeState,
      organizations: organizations
    }));
    setCounts(
      getScopeCounts(allOrganizations ?? [], allPlants ?? [], {
        ...scopeState,
        organizations: organizations
      })
    );
    onScopeDetailChange({
      ...scopeState,
      organizations: organizations
    });
  };

  // const onGoBackToGroup = useCallback(() => {
  //   const counts = (allOrganizations ?? [], allPlants ?? [], scopeState);
  //   setCounts(counts);getScopeCounts
  //   onScopeDetail(false);
  //   onScopeDetailChange(scopeState);
  // }, [scopeState]);

  const renderPlant = (
    organization: GroupOrganization,
    plantOptions: { label: string; value: string }[],
    orgIndex: number
  ) => {
    const plantItem = getPlantById(allPlants ?? [], plantOptions[0] && plantOptions[0]?.value);
    const groupPlant = scopeState?.organizations?.[orgIndex]?.plants?.[0];
    let machineOptions: { value: string; label: string }[];
    if (organization.allMachines || organization.allPlants) {
      machineOptions = getMachinesByOrgOptions(allPlants, allLines, organization.organizationId);
    } else {
      machineOptions = getMachinesByPlantOptions(allPlants, allLines, plantItem);
    }

    let machineOptionsValue: { label: string; value: string }[];
    if (organization.allMachines || groupPlant?.allMachines) {
      machineOptionsValue = [{ value: 'All', label: 'All Machines' }];
    } else if (organization?.allPlants) {
      machineOptionsValue = getMachinesByGroupOrgOptions(allPlants, allLines, organization);
    } else {
      machineOptionsValue = getMachinesByGroupPlantOptions(allPlants, allLines, groupPlant ?? {});
    }

    const plantOptionsValue =
      scopeState?.organizations?.[orgIndex]?.plants &&
      !isEmpty(scopeState.organizations[orgIndex].plants) &&
      scopeState.organizations[orgIndex].plants[0]
        ? getGroupPlantOption(allPlants, scopeState.organizations[orgIndex].plants[0])
        : null;
    return (
      <SelectBoxWrapper>
        <SelectBox>
          <SelectHeaderText>Site</SelectHeaderText>
          <Select
            options={[
              { value: 'All', label: 'All Sites' },
              ...sortBy(plantOptions, [
                (p) => {
                  return lowerCase(p.label);
                }
              ])
            ]}
            value={
              organization.allPlants ? { value: 'All', label: 'All Sites' } : plantOptionsValue
            }
            placeholder="Select Site"
            hideSelectedOptions={false}
            onChange={(option) => {
              const newPlants = getNewPlants(
                { value: option?.value ?? '', label: option?.label ?? '' },
                organization.plants,
                0
              );
              updatePlants(organization.organizationId, newPlants, option?.value === 'All');
            }}
            styles={{
              singleValue: (styles) => ({
                ...styles,
                border: '0.063rem solid  #0076CC',
                width: 'fit-content',
                borderRadius: '1.25rem',
                padding: '0.188rem;',
                fontSize: '12px'
              })
            }}
          />
        </SelectBox>
        <SelectBox>
          <SelectHeaderText>Machines</SelectHeaderText>
          <Select
            placeholder="Select Machines"
            className={'dropdown_checkboxes'}
            value={sortBy(machineOptionsValue, [(p) => lowerCase(p.label)])}
            options={[
              { value: 'All', label: 'All Machines' },
              ...sortBy(machineOptions, [
                (p) => {
                  return lowerCase(p.label);
                }
              ])
            ]}
            onChange={(machineOptions) => {
              const machineItems = Array.from(machineOptions).map(
                (machineOption) => machineOption.value
              );
              updateMachines(
                organization.organizationId,
                plantItem?.id ?? '',
                machineItems,
                machineOptions.some((machineOption) => machineOption.value === 'All'),
                organization.allPlants
              );
            }}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option,
              ClearIndicator,
              DropdownIndicator
            }}
            styles={{
              clearIndicator: ClearValues,
              multiValue: (styles) => {
                return {
                  ...styles,
                  backgroundColor: 'white',
                  borderRadius: '1.25rem',
                  border: '0.063rem solid  #0076CC',
                  padding: '0.25rem 0.5rem 0.25rem 0.5rem;'
                };
              },
              multiValueLabel: (styles) => ({
                ...styles,
                fontWeight: '400'
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                ':hover': {
                  cursor: 'pointer'
                }
              })
            }}
          />
        </SelectBox>
        <OrganizationFooter>
          <TrashBox
            onClick={() => {
              updatePlants(organization.organizationId, [], false);
            }}
          >
            <FontAwesomeIcon color={theme.colors.negativeRed} icon={faTrashCan} size="sm" />
            <IconText>Clear</IconText>
          </TrashBox>
        </OrganizationFooter>
      </SelectBoxWrapper>
    );
  };

  const renderPlants = (
    organization: GroupOrganization,
    plantOptions: { label: string; value: string }[],
    orgIndex: number
  ) => {
    return organization.plants?.map((plant, plantIndex) => {
      const plantItem = getPlantById(allPlants ?? [], plant.plantId);
      const groupPlant = scopeState?.organizations?.[orgIndex]?.plants?.[plantIndex];
      let machineOptions: { value: string; label: string }[];
      if (organization.allMachines || organization.allPlants) {
        machineOptions = getMachinesByOrgOptions(allPlants, allLines, organization.organizationId);
      } else {
        machineOptions = getMachinesByPlantOptions(allPlants, allLines, plantItem);
      }
      const machineOptionsValue = getMachinesByGroupPlantOptions(
        allPlants,
        allLines,
        groupPlant ?? {}
      );

      let plantOptionsValue =
        scopeState?.organizations?.[orgIndex]?.plants &&
        !isEmpty(scopeState.organizations[orgIndex].plants) &&
        scopeState.organizations[orgIndex].plants[plantIndex]
          ? getGroupPlantOption(allPlants, scopeState.organizations[orgIndex].plants[plantIndex])
          : null;
      activeSelection.current = {
        value: plantOptionsValue?.value as string,
        plantIndex: plantIndex
      };
      const filteredOptions: { label: string; value: string }[] = [];
      if (organization.selectedPlants) {
        organization.selectedPlants.forEach((selection, index) => {
          if (selection.plantIndex === activeSelection.current.plantIndex) {
            organization.selectedPlants.splice(index, 1);
          }
        });
        organization.selectedPlants.push(activeSelection.current);

        plantOptions.forEach((opt) => {
          const selected = organization.selectedPlants.find((p) => p.value === opt.value);
          if (!selected) {
            filteredOptions.push(opt);
          }
        });
      }

      plantOptionsValue =
        plantOptionsValue?.label === '' && plantOptionsValue.value === ''
          ? null
          : plantOptionsValue;

      return (
        <SelectBoxWrapper key={plantIndex}>
          <SelectBox>
            <SelectHeaderText>Site</SelectHeaderText>
            <Select
              options={[{ value: 'All', label: 'All Sites' }, ...filteredOptions]}
              value={
                organization?.allPlants ? { value: 'All', label: 'All Sites' } : plantOptionsValue
              }
              placeholder="Select Site"
              hideSelectedOptions={false}
              onChange={(option) => {
                const newPlants = getNewPlants(
                  { value: option?.value ?? '', label: option?.label ?? '' },
                  organization.plants,
                  plantIndex
                );
                updatePlants(organization.organizationId, newPlants, option?.value === 'All');
              }}
              styles={{
                singleValue: (styles) => ({
                  ...styles,
                  border: '0.063rem solid  #0076CC',
                  width: 'fit-content',
                  borderRadius: '1.25rem',
                  padding: '0.25rem 0.5rem 0.25rem 0.5rem'
                })
              }}
            />
          </SelectBox>
          <SelectBox>
            <SelectHeaderText>Machines</SelectHeaderText>
            <Select
              placeholder="Select Machines"
              className={'dropdown_checkboxes'}
              value={
                groupPlant?.allMachines
                  ? [{ value: 'All', label: 'All Machines' }]
                  : sortBy(machineOptionsValue, [(p) => lowerCase(p.label)])
              }
              options={[
                { value: 'All', label: 'All Machines' },
                ...sortBy(machineOptions, [
                  (p) => {
                    return lowerCase(p.label);
                  }
                ])
              ]}
              onChange={(machineOptions) => {
                const machineItems = Array.from(machineOptions).map(
                  (machineOption) => machineOption.value
                );
                updateMachines(
                  organization.organizationId,
                  plantItem?.id ?? '',
                  machineItems,
                  machineOptions.some((machineOption) => machineOption.value === 'All'),
                  organization.allPlants
                );
              }}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
                ClearIndicator,
                DropdownIndicator
              }}
              styles={{
                clearIndicator: ClearValues,
                multiValue: (styles) => {
                  return {
                    ...styles,
                    backgroundColor: 'white',
                    borderRadius: '1.25rem',
                    border: '0.063rem solid  #0076CC',
                    padding: '0.25rem 0.5rem 0.25rem 0.5rem;'
                  };
                },
                multiValueLabel: (styles) => ({
                  ...styles,
                  fontWeight: '400'
                }),
                multiValueRemove: (styles) => ({
                  ...styles,
                  ':hover': {
                    cursor: 'pointer'
                  }
                })
              }}
            />
          </SelectBox>
          <OrganizationFooter>
            <TrashBox
              onClick={() => {
                if (organization.selectedPlants) {
                  organization.selectedPlants.forEach((selection, index) => {
                    if (selection.plantIndex === plantIndex) {
                      organization.selectedPlants.splice(index, 1);
                    }
                  });
                }
                updatePlants(
                  organization.organizationId,
                  [
                    ...organization.plants.slice(0, plantIndex),
                    ...organization.plants.slice(plantIndex + 1)
                  ],
                  organization.allPlants
                );
              }}
            >
              <FontAwesomeIcon color={theme.colors.negativeRed} icon={faTrashCan} size="sm" />
              <IconText>Clear</IconText>
            </TrashBox>
          </OrganizationFooter>
        </SelectBoxWrapper>
      );
    });
  };

  const renderOrganizations = () => {
    return scopeState.organizations?.map((organization: GroupOrganization, orgIndex) => {
      const plantOptions = getGroupPlantOptions(
        allPlants,
        getGroupPlantsByOrg(allPlants ?? [], organization)
      );
      return (
        <OrganizationContainer key={orgIndex}>
          <OrganizationNameText>
            {!isEmpty(organization.label)
              ? organization.label
              : getOrgById(allOrganizations, organization.organizationId)?.name}
          </OrganizationNameText>
          <PlantsContainer>
            <>
              {organization.allPlants || isEmpty(organization.plants)
                ? renderPlant(organization, plantOptions, orgIndex)
                : renderPlants(organization, plantOptions, orgIndex)}
            </>
          </PlantsContainer>
          <OrganizationFooter>
            <OrganizationFooterButtonBox>
              <OrganizationButton>
                <button
                  onClick={() => {
                    updatePlants(
                      organization.organizationId,
                      [
                        ...organization.plants,
                        {
                          plantId: '',
                          value: '',
                          label: '',
                          allMachines: false,
                          machines: []
                        }
                      ],
                      organization.allPlants
                    );
                  }}
                  // If the limit of org plants is reached , do not allow adding new one
                  disabled={
                    organization.allPlants ||
                    isEmpty(organization.plants) ||
                    plantOptions.length === organization.plants.length
                  }
                >
                  <Typography size="1rem" weight="semi-bold">
                    <FontAwesomeIcon icon={faPlus} size="1x" color={theme.colors.mediumBlue} /> Add
                  </Typography>
                </button>
              </OrganizationButton>
            </OrganizationFooterButtonBox>
          </OrganizationFooter>
        </OrganizationContainer>
      );
    });
  };
  return (
    <>
      <ItemLabel>
        <Typography size="0.875rem" color={theme.colors.text.lightBlack} weight="semi-bold">
          Customer
        </Typography>
        <MandatoryIndicator>*</MandatoryIndicator>
      </ItemLabel>
      <CustomerSelect>
        <DropdownWithCheckboxes
          placeholder="Select Organizations"
          value={
            scopeState.allOrganizations
              ? [{ value: 'All', label: 'All organizations' }]
              : getGroupOrgOptions(allOrganizations, scopeState.organizations)
          }
          options={
            internalAccessGroup
              ? [
                  { value: 'All', label: 'All organizations' },
                  ...getSelectOptions(allOrganizations ?? [])
                ]
              : getSelectOptions(allOrganizations ?? [])
          }
          handleMultiSelect={(items) => {
            updateOrganizations(items);
          }}
        />
      </CustomerSelect>

      {scopeState.allOrganizations ? (
        <Row>
          <Column>
            <Select
              isMulti
              options={[]}
              placeholder="All Sites"
              isDisabled={scopeState.allOrganizations}
            />
          </Column>
          <Column>
            <Select
              isMulti
              options={[]}
              placeholder="All Machines"
              isDisabled={scopeState.allOrganizations}
            />
          </Column>
        </Row>
      ) : (
        <>{renderOrganizations()}</>
      )}
    </>
  );
};
export default ScopeDetail;
