import React from 'react';
import type { FC, SVGProps } from 'react';

export const IcoPlus: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M6.00033 0.666016V11.3327M11.3337 5.99935L0.666992 5.99935"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
