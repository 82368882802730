import React from 'react';

export const AddPlusIcon = (): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="
    http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99984 2.66699V13.3337M13.3332 8.00033L2.6665 8.00032"
      stroke="#0076CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
