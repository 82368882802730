import { StyledUiContainerProps } from 'components';
import styled from 'styled-components';

export const FleetPageContainer = styled.main<StyledUiContainerProps>`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 100vh;

  > header,
  > section {
    padding: 1.5em 2em;
  }

  .tabs-nav {
    font-size: clamp(0.75rem, 1.8vw, 0.875rem);
  }

  .content-padding {
    padding: 1.5em 2em;
  }

  .diagnostics-mode-bar {
    font-size: 0.9em;
    position: fixed;
    right: 0;
    bottom: 0;
    width: max-content;
    height: max-content;
    padding: 1em;
    background: white;
    border-radius: 5px 0 0 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;

    button {
      background-color: transparent;
      color: pink;
      border: none;
    }
  }

  .machine-info-provider__error {
    color: red;
    text-transform: captialize;
  }
`;

export const FleetPageViewContainer = styled.section<StyledUiContainerProps>``;
