export const hasConfig: Record<string, boolean> = {
  protein: true,
  dsi: true
};

export const hasWatchdog: Record<string, boolean> = {
  protein: true,
  dsi: true
};

export const buID: Record<string, string> = {
  1: 'pna',
  2: 'avure',
  3: 'pemea',
  4: 'aseptic',
  5: 'proseal',
  6: 'dsi'
};
