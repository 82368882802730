import React from 'react';
import useClickOutside from 'hooks/useClickOutside';
import { FC, Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { PopupWithButtonContainer } from './PopupWithButton.elements';
import { IcoClose } from 'icons/IcoClose';

export const PopupWithButton = ({
  Button,
  Popup,
  type,
  title,
  Footer,
  isOpen,
  handle,
  children,
  Header,
  HeaderComponent
}: {
  Header?: FC<Record<string, unknown>>;
  Button?: FC<Record<string, unknown>>;
  Popup: FC<Record<string, unknown>>;
  type?: 'flyout' | 'modal' | 'full-screen';
  title?: string;
  Footer?: JSX.Element;
  // if you want to externally show the popup (example, if you want to use routing instead of states)
  isOpen?: boolean;
  // if you want to externally control the popup (example, if you want to use routing instead of states)
  handle?: (type: 'open' | 'close') => void;
  // this is a component that will show before the close button, it is being used as a filter for flyout or other
  // functioning icons or button/components
  HeaderComponent?: FC<Record<string, unknown>>;
  children?: ReactNode | ReactNode[];
}): JSX.Element => {
  // ref is used for useClickoutside
  const ref = useRef(null);
  // this state hook is here for internal states,
  // you you're using an outside state, just pass the isOpen and handle props
  const [open, setOpen] = useState(false);

  if (!Popup) return <>error: missing popup</>;

  // start the grid layout and build from there
  // we start with the close button/title row
  let gridRows = 'auto';
  // add header row if needded
  if (Header) gridRows += ' auto';
  // add main content row no matter what
  gridRows += ' 1fr';
  // add footer row if needed
  if (Footer) gridRows += ' auto';

  //default is flyout for now
  type = type || 'flyout';

  isOpen = isOpen || open;

  let className = 'popup-container';
  if (type) className += ` is-${type}`;
  if (isOpen) className += ' is-open';
  if (Header && Footer) className += ' has-header-footer';
  else {
    if (Header) className += ' has-header';
    if (Footer) className += ' has-footer';
  }

  useEffect(() => {
    if (isOpen) document.body.classList.add('popup-open');
    else document.body.classList.remove('popup-open');
  }, [isOpen, open]);

  const handleClose = () => {
    document.body.classList.remove('popup-open');
    return handle ? handle?.('close') : setOpen(false);
  };

  useClickOutside(ref, () => handleClose());

  return (
    <Fragment>
      {children}
      {Button && <Button onClick={() => (handle ? handle?.('open') : setOpen(true))} />}
      {isOpen && (
        <PopupWithButtonContainer {...{ className, gridRows }}>
          <div className="popup-wrapper" ref={ref}>
            <div className="popup-title-bar">
              <h2 className="popup-title-bar__header">{title}</h2>
              {HeaderComponent && <HeaderComponent />}
              <button type="button" onClick={() => (handle ? handle?.('close') : setOpen(false))}>
                <IcoClose />
              </button>
            </div>
            {Header && (
              <div className="popup-header">
                <Header />
              </div>
            )}
            <div className="popup-main">{isOpen && <Popup {...{ handleClose }} />}</div>
            {Footer && <div className="popup-footer">{Footer}</div>}
          </div>
        </PopupWithButtonContainer>
      )}
    </Fragment>
  );
};
