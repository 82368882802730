export const media = {
  xs: 320,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1200
};

export const mediaMin = {
  xs: '319px',
  sm: '479px',
  md: '767px',
  lg: '1023px',
  xl: '1199px'
};

export const mediaMax = {
  xs: '321px',
  sm: '481px',
  md: '769px',
  lg: '1025px',
  xl: '1201px'
};
