import { StyledUiContainerProps } from 'components';
import styled from 'styled-components';

interface TooltipWrapperContainerProps extends StyledUiContainerProps {
  type?: 'light' | 'dark';
}

export const TooltipWrapperContainer = styled.div<TooltipWrapperContainerProps>`
  position: absolute;
  width: 0px;
  height: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: ${({ left, top }) => (!left || !top ? 'none' : 'flex')};
  left: ${({ left }) => Math.round(left as number)}px;
  top: ${({ top }) => Math.round(top as number)}px;

  .content-wrapper {
    padding: 0.5em;
    background-color: white;
    border-radius: 0.5em;

    position: absolute;
    bottom: 100%;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.35);
    margin-bottom: 15px;

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 50%;
      bottom: -5px;
      margin-left: -5px;
      background-color: inherit;
      transform: rotate(45deg);
    }

    .split-col {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 0.5em;
      min-width: max-content;
    }

    .border-bottom {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      padding-bottom: 0.5em;
    }

    .label {
      opacity: 0.9;
    }
    .item {
      font-weight: 500;
    }
  }
`;
