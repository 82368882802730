import React from 'react';

export const CalendarIcon = ({ color }: { color?: string }): JSX.Element => {
  color = color || '#0076CC';
  return (
    <svg width="16" height="16" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.55556 3.66667V1M8.44444 3.66667V1M2.94444 6.33333H9.05556M1.72222 13H10.2778C10.9528 13 11.5 12.403 11.5 11.6667V3.66667C11.5 2.93029 10.9528 2.33333 10.2778 2.33333H1.72222C1.04721 2.33333 0.5 2.93029 0.5 3.66667V11.6667C0.5 12.403 1.04721 13 1.72222 13Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
