import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoFormDownload = ({
  width,
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5700_196015)">
        <path
          d="M11.115 20H8.5C7.96957 20 7.46086 19.7893 7.08579 19.4142C6.71071 19.0391 6.5 18.5304 6.5 18V6C6.5 5.46957 6.71071 4.96086 7.08579 4.58579C7.46086 4.21071 7.96957 4 8.5 4H16.5C17.0304 4 17.5391 4.21071 17.9142 4.58579C18.2893 4.96086 18.5 5.46957 18.5 6V8.54546"
          stroke={color || '#828485'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 8H13.5"
          stroke={color || '#828485'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 12H11.5"
          stroke={color || '#828485'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 17.9092L18.5 20.9092L21.5 17.9092"
          stroke={color || '#828485'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5 20.9092V11.9092"
          stroke={color || '#828485'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5700_196015">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </Svg>
  );
};
