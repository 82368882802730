import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Typography } from 'components';
import theme from 'themes';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
  label: string;
  color: string;
  onChange?: (id: string, value: boolean) => void;
  onClick?: () => void;
  active?: boolean;
  legendShape?: string;
  children?: React.ReactNode;
}

const Container = styled.label<{ clickable: boolean; active: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  ${({ clickable }) => clickable && 'cursor: pointer'};
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

const CheckBox = styled.span<{ color: string; legendShape?: string }>`
  display: inline-block;
  flex-shrink: 0;
  width: 0.75rem;
  height: 0.75rem;
  border-radius ${({ legendShape }) => (legendShape === 'circle' ? '50%' : '15.875rem')};
  background-color: ${({ color }) => color};
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: center;
  margin-right: 0.3rem;
`;

/**
 * This renders as a checkboox when onChange is provided.
 * Otherwise, just acts as a simple label.
 */
const GraphLegend = ({
  id,
  label,
  color,
  active,
  onChange,
  onClick,
  legendShape
}: Props): JSX.Element => {
  const { t } = useTranslation(['mh']);

  return (
    <Container clickable={!!onChange || !!onClick} active={!!active} onClick={onClick}>
      {onChange && (
        <input
          type="checkbox"
          checked={active}
          onChange={(evt) => {
            onChange && onChange(id, evt.target.checked);
          }}
        ></input>
      )}
      <CheckBox color={color} legendShape={legendShape}>
        {active && onChange && <FontAwesomeIcon icon={faCheck} color="white" />}
      </CheckBox>
      <Typography mb={0} as="span" size="0.75rem" color={`${theme.colors.text.lightBlack}`}>
        {`${t(label as string)}`}
      </Typography>
    </Container>
  );
};

export default GraphLegend;
