import React, { useMemo } from 'react';
import { BarChartProps } from '../BarChart.types';
import { GridLine } from 'common/ui';

export interface DivBarChartAxisProps extends BarChartProps {
  gridLines?: {
    x?: number[];
    y?: number[];
  };
  valKey?: 'x' | 'y';
}

export const DivBarChartGrid = ({ gridLines }: DivBarChartAxisProps): JSX.Element => {
  const gridX = gridLines?.x;
  const gridY = gridLines?.y;

  const Grid = useMemo(
    () => (
      <>
        {gridX &&
          gridX.map((x, i) => (
            <GridLine key={i} {...{ x }} className="grid-line grid-line--x grid-line--vert" />
          ))}
        {gridY &&
          gridY.map((y, i) => (
            <GridLine key={i} {...{ y }} className="grid-line grid-line--y grid-line--horz" />
          ))}
      </>
    ),
    [gridLines]
  );

  return <>{Grid}</>;
};
