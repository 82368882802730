import colors from './light.theme';

export const ticks = {
  labelColor: colors.tickLabel,
  lineColor: colors.tickLine,
  fontSize: '.8em',
  space: `5em`
};

export const gridLines = {
  lineColor: colors.gridLine,
  hoverColor: colors.gridLineHover
};

export default { ticks, gridLines };
