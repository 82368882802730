import React from 'react';
import { styledTheme } from 'components';

// this icon is the blue outline hand in the stopped position
export const IcoHand = ({ color }: { color?: string }): JSX.Element => {
  color = color || styledTheme.color.main;
  return (
    <svg
      className="icon icon--hand"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M5.33203 8.66797V3.66797C5.33203 3.40275 5.43739 3.1484 5.62492 2.96086C5.81246 2.77333 6.06681 2.66797 6.33203 2.66797C6.59725 2.66797 6.8516 2.77333 7.03914 2.96086C7.22667 3.1484 7.33203 3.40275 7.33203 3.66797V8.0013"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33203 3.66536V2.33203C7.33203 2.20071 7.3579 2.07067 7.40815 1.94935C7.45841 1.82802 7.53207 1.71778 7.62492 1.62492C7.71778 1.53207 7.82802 1.45841 7.94935 1.40815C8.07067 1.3579 8.20071 1.33203 8.33203 1.33203C8.46335 1.33203 8.59339 1.3579 8.71471 1.40815C8.83604 1.45841 8.94628 1.53207 9.03914 1.62492C9.132 1.71778 9.20566 1.82802 9.25591 1.94935C9.30617 2.07067 9.33203 2.20071 9.33203 2.33203V7.9987"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33203 3.66797C9.33203 3.40275 9.43739 3.1484 9.62492 2.96086C9.81246 2.77333 10.0668 2.66797 10.332 2.66797C10.5972 2.66797 10.8516 2.77333 11.0391 2.96086C11.2267 3.1484 11.332 3.40275 11.332 3.66797V8.0013"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3335 5C11.3335 4.73478 11.4388 4.48043 11.6263 4.29289C11.8139 4.10536 12.0682 4 12.3335 4C12.5987 4 12.853 4.10536 13.0406 4.29289C13.2281 4.48043 13.3335 4.73478 13.3335 5V10.6667C13.3335 11.7275 12.912 12.7449 12.1619 13.4951C11.4117 14.2452 10.3943 14.6667 9.33346 14.6667H8.00012H8.13879C7.47635 14.6668 6.82425 14.5024 6.24104 14.1882C5.65784 13.874 5.1618 13.4199 4.79746 12.8667C4.75373 12.8001 4.71017 12.7334 4.66679 12.6667C4.45879 12.3473 3.72879 11.0747 2.47612 8.848C2.34842 8.62102 2.31431 8.35318 2.38104 8.10143C2.44778 7.84969 2.61009 7.63392 2.83346 7.5C3.07137 7.35724 3.35017 7.29808 3.62556 7.3319C3.90095 7.36572 4.15715 7.49058 4.35346 7.68667L5.33346 8.66667"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
