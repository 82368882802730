import styled from 'styled-components';

export const PageHeaderContainer = styled.header`
  padding-bottom: 1.25rem;
  padding-left: 3.25rem;
  padding-right: 3.25rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
  background: linear-gradient(0deg, rgba(241, 243, 244, 0.2) 0%, rgba(241, 243, 244, 0) 100%);
`;
