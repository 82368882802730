import React from 'react';
import { styledTheme } from 'components';

export interface Props {
  color?: string;
}

export const IconReset = ({ color }: Props): JSX.Element => {
  color = color || styledTheme.color.secondary;

  return (
    <svg
      width="13"
      height="13"
      className="icon icon--reset"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15C8.80208 15 9.57031 14.8776 10.3047 14.6328C11.0443 14.3828 11.7161 14.0339 12.3203 13.5859C12.9297 13.1328 13.4583 12.5911 13.9062 11.9609C14.3594 11.3255 14.7031 10.6224 14.9375 9.85156L15.8906 10.1484C15.6302 11.0182 15.2422 11.8125 14.7266 12.5312C14.2109 13.2448 13.6042 13.8594 12.9062 14.375C12.2135 14.8906 11.4479 15.2917 10.6094 15.5781C9.77604 15.8594 8.90625 16 8 16C7.28646 16 6.59115 15.9115 5.91406 15.7344C5.23698 15.5573 4.59635 15.3021 3.99219 14.9688C3.39323 14.6302 2.83854 14.2214 2.32812 13.7422C1.81771 13.2578 1.375 12.7109 1 12.1016V14H0V10H4V11H1.53125C1.83854 11.6094 2.21875 12.1615 2.67188 12.6562C3.13021 13.1458 3.64062 13.5651 4.20312 13.9141C4.76562 14.2578 5.36719 14.526 6.00781 14.7188C6.65365 14.9062 7.31771 15 8 15ZM16 2V6H12V5H14.4688C14.1615 4.39062 13.7786 3.84115 13.3203 3.35156C12.8672 2.85677 12.3594 2.4375 11.7969 2.09375C11.2344 1.74479 10.6302 1.47656 9.98438 1.28906C9.34375 1.09635 8.68229 1 8 1C7.19792 1 6.42708 1.125 5.6875 1.375C4.95312 1.61979 4.28125 1.96875 3.67188 2.42188C3.06771 2.86979 2.53906 3.41146 2.08594 4.04688C1.63802 4.67708 1.29688 5.3776 1.0625 6.14844L0.109375 5.85156C0.369792 4.98698 0.757812 4.19531 1.27344 3.47656C1.78906 2.75781 2.39323 2.14062 3.08594 1.625C3.78385 1.10938 4.54948 0.710938 5.38281 0.429688C6.22135 0.143229 7.09375 0 8 0C8.71354 0 9.40885 0.0885417 10.0859 0.265625C10.763 0.442708 11.401 0.700521 12 1.03906C12.6042 1.3724 13.1615 1.78125 13.6719 2.26562C14.1823 2.74479 14.625 3.28906 15 3.89844V2H16Z"
        fill={`${color}`}
      />
    </svg>
  );
};
