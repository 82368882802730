import { useDeleteUserV2Mutation } from 'api';
import { ToastMsg } from 'common/components/Toast/Toast';
import { Button, Modal, Typography } from 'components';
import { IcoCross } from 'icons/IcoCross';
import {
  StyledButtonRow,
  StyledContentContainer,
  StyledModalHeaderContainer,
  StyledTitle
} from 'pages/MachineManagement/MachineManagementPopup/MachineImageUploaderStyledComponents';
import React from 'react';
import theme from 'themes';
import { ModalSize } from 'types';
import { UserGroup, UserTypes } from 'types/user-management';

type Props = {
  showConfirmation: boolean;
  closeModal: () => void;
  data: UserTypes[];
  groupId?: string;
  handleDeleteActionOnImport?: () => void;
  isImportMode?: boolean;
  houseKeepOnDelete?: () => void;
};
const DeleteUser = ({
  showConfirmation,
  closeModal,
  data,
  groupId,
  handleDeleteActionOnImport,
  isImportMode,
  houseKeepOnDelete
}: Props): JSX.Element => {
  const [deleteUser] = useDeleteUserV2Mutation();

  function getSpecificKey(data: UserTypes[] | UserGroup[]) {
    return data.map((item) => ({ id: item.id }));
  }

  const handleDeleteAction = () => {
    const params = data.map((item) => ({
      id: item.id,
      groups: groupId ? [{ id: groupId }] : getSpecificKey(item.groups || [])
    }));

    deleteUser(params)
      .unwrap()
      .then(() => {
        ToastMsg({
          heading: 'Success',
          message: 'Multiple user(s) have been successfully deleted.',
          type: 'success',
          position: 'top-center'
        });
        houseKeepOnDelete?.();
      });
  };
  return (
    <Modal
      title={
        <StyledModalHeaderContainer>
          {IcoCross()}
          <StyledTitle>Delete Member</StyledTitle>
        </StyledModalHeaderContainer>
      }
      allowContentScroll
      onClose={closeModal}
      showCloseButton={false}
      size={ModalSize.XSMALL}
      visible={showConfirmation}
    >
      <StyledContentContainer>
        <Typography weight="medium">
          Are you sure you want to delete {data.length} selected{' '}
          {data.length > 1 ? 'users' : 'user'}? This action cannot be undone.
        </Typography>
        <StyledButtonRow>
          <Button
            onClick={closeModal}
            variant="link"
            borderRadius="3rem"
            borderColor={theme.colors.text.error}
            color={theme.colors.text.error}
          >
            Cancel
          </Button>
          <Button
            onClick={isImportMode ? handleDeleteActionOnImport : handleDeleteAction}
            borderColor={theme.colors.text.error}
            variant="constant"
            bgColor={theme.colors.text.error}
            borderRadius="3rem"
            color={theme.colors.white}
          >
            Yes
          </Button>
        </StyledButtonRow>
      </StyledContentContainer>
    </Modal>
  );
};

export default DeleteUser;
