import styled from 'styled-components';
import theme from 'themes';

export const IssueContainer = styled.div`
  display: flex;
  margin: 1rem;
  flex-direction: column;
  gap: 0.25rem;
`;

export const IssuesItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-top: 0.5rem;
  border-bottom: 1px solid ${theme.colors.lightGrey7};
  &:first-of-type {
    padding-top: 0;
  }
  width: calc(100% - 0.625rem);
  padding-bottom: 0.2rem;
`;

export const IssuesHeader = styled.div`
  display: flex;
  gap: 0.563rem;
  align-items: center;
  justify-content: space-between;

  .issue-header-left-side {
    display: flex;
    gap: 0.563rem;
  }
`;

export const IssuesBody = styled.div`
  display: flex;
`;

export const IssuesFooter = styled.div`
  display: flex;
  justify-content: end;
`;

export const IssuesText = styled.p`
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${theme.colors.text.lightBlack};
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${theme.colors.primaryBlue5};
  cursor: pointer;
`;
