import React from 'react';
import { Theme, ToastOptions, ToastPosition, toast } from 'react-toastify';
import warningIcon from 'img/warning-icon.png';
import successIcon from 'img/success-icon.png';
import InfoIcon from 'img/info-icon.svg';
import './toast.css';
import styled from 'styled-components';
import { colors, themeColors } from 'themes';
import { IcoClose } from 'icons/IcoClose';

interface ToastProps extends ToastOptions {
  message: string;
  heading?: string;
  position?: ToastPosition;
  theme?: Theme;
}
type ToastComponentProps = {
  heading?: string;
  message: string;
};
const ToastMessageContainer = styled.div`
  color: ${colors.text.lightBlack};
  & h3 {
    padding: 0rem 0rem !important;
    margin: 0rem;
    font-size: 1rem;
    font-weight: 600;
  }
  & p {
    padding: 0.5rem 0rem;
    margin: 0rem;
  }
`;

const CloseIconContainer = styled.div`
  padding: 6px;
`;
const ToastMessageComp = ({ heading, message }: ToastComponentProps) => {
  return (
    <ToastMessageContainer>
      {heading && <h3>{heading}</h3>}
      <p>{message}</p>
    </ToastMessageContainer>
  );
};
export const ToastMsg = ({
  message,
  heading,
  position,
  theme,
  ...rest
}: ToastProps): string | number => {
  const { type = 'success' } = rest;
  const icon =
    type === 'warning'
      ? warningIcon
      : type === 'success'
      ? successIcon
      : type === 'info'
      ? InfoIcon
      : '';

  return toast(<ToastMessageComp message={message} heading={heading} />, {
    position: position || 'top-center',
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    theme: theme || 'light',
    className: heading ? 'toast-heading' : '',
    closeButton: (
      <CloseIconContainer>
        {IcoClose(
          type === 'warning'
            ? colors.icons.toast.warning
            : type === 'success'
            ? themeColors.green2
            : type === 'info'
            ? themeColors.primaryBlue5
            : colors.text.lightBlack
        )}
      </CloseIconContainer>
    ),
    ...(icon && { icon: <img src={icon} /> }),
    ...rest
  });
};
