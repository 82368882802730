import React, { FC, ReactNode } from 'react';
import { ActiveIssuesRow } from './ActiveIssuesTable.elements';
import { IcoAlertBell } from 'icons/IcoAlertBell';
export interface ActiveIssuesTableDataItemProps {
  alertIcon?: ReactNode;
  strong?: string;
  text?: ReactNode;
  timestamp?: string;
  actionText?: string;
  action?: () => void;
  id?: string;
  CustomRow?: FC<Record<string, unknown>>;
  [key: string]: unknown;
}

interface ActiveIssuesProps {
  data?: Record<string, unknown>[];
  tableData?: ActiveIssuesTableDataItemProps[];
  mapKeys?: Record<keyof ActiveIssuesTableDataItemProps, string>;
}

export const ActiveIssues = ({ data, tableData, mapKeys }: ActiveIssuesProps): JSX.Element => {
  const Row = ({
    alertIcon,
    timestamp,
    strong,
    text,
    action,
    actionText,
    CustomRow,
    ...rest
  }: ActiveIssuesTableDataItemProps) => {
    let curTimestamp = timestamp;

    if (mapKeys) {
      curTimestamp = (rest?.[mapKeys?.timestamp as string] as string) || curTimestamp;
    }

    const Icon = alertIcon ? (
      <img className="ico-alert-bell" src={alertIcon as string} alt="alert" />
    ) : (
      <IcoAlertBell />
    );

    return (
      <ActiveIssuesRow className="active-issues__row">
        <div className="active-issues__row active-issues__row--1">
          {Icon} {curTimestamp}
        </div>
        {CustomRow ? (
          <CustomRow />
        ) : (
          <div className="active-issues__row active-issues__row--2">
            <div className="active-issues__row active-issues__row--text">
              {strong && <strong>{strong} </strong>} {text && text}
            </div>
          </div>
        )}
        {action && actionText && (
          <div className="active-issues__row active-issues__row--3">
            <p onClick={action}>{actionText}</p>
          </div>
        )}
      </ActiveIssuesRow>
    );
  };

  const loopData = data || tableData;

  return (
    <>
      {loopData?.map((item: ActiveIssuesTableDataItemProps, i) => (
        <Row key={`row${i}`} {...item} />
      ))}
    </>
  );
};
