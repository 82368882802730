import React from 'react';
import { LoaderContainer, LoaderElement } from './index.elements';
import theme from 'themes';

type LoaderProps = {
  padding?: string;
  size?: string;
  color?: string; // color props should be in hexadecimal with length of six.
  thickness?: string;
};

const CustomLoader = ({
  padding,
  size = '5rem',
  color = theme.colors.darkBlue3,
  thickness
}: LoaderProps): JSX.Element => {
  return (
    <LoaderContainer padding={padding}>
      <LoaderElement thickness={thickness} size={size} color={color} />
    </LoaderContainer>
  );
};

export default CustomLoader;
