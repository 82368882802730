import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';
import theme from 'common/theme';

export const LargeTextTileWidgetContainer = styled.div<StyledUiContainerProps>`
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;

  color: ${theme.colors.gray800};

  .large-text-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
    text-align: center;
    flex-grow: 1;
  }

  .large-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 50%;
    gap: 0.2em;
  }

  .large-text-title {
    color: ${theme.colors.label};
    font-weight: 500;
  }

  .large-text-label {
    font-size: 0.8em;
    font-weight: 500;
    text-transform: capitalize;

    color: ${theme.colors.label};
  }

  .large-text-value {
    font-size: 1.9em;
    font-weight: 800;

    .large-text-unit {
      font-size: 0.5em;
      color: ${theme.colors.label};
    }
  }
`;
