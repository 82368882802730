import React, { ReactNode } from 'react';
import { TimeStampDisplayContainer } from './TimeStampDisplay.elements';
import { TooltipWrapper, useDateRange } from 'components';
import { IcoDateHistory } from 'icons/IcoDateHistory';
import { differenceInHours } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useWidgetDateRange } from '../providers/useWidgetDateRange';
import { convertToZonedTimestamp } from 'common/helpers/dateAndTimeHelpersV2';

export interface TimeStampDisplayProps {
  //  if you leave out children, the current date range will be used based on the dateRangeProvider
  children?: ReactNode;
  displayTimestampTooltip?: boolean;
  label?: ReactNode;
}

export const TimeStampDisplayV1 = ({ children }: TimeStampDisplayProps): JSX.Element => {
  const { dateRange, timeZone } = useDateRange();
  const { label } = useWidgetDateRange();

  const { t } = useTranslation(['mh']);

  if (children) {
    const stamp = typeof children === 'string' ? t(children.toLowerCase()) : children;
    return (
      <TimeStampDisplayContainer className="widget-ui__footer time-stamp-footer">
        <IcoDateHistory /> {t('Viewing')}: <strong>{stamp}</strong>
      </TimeStampDisplayContainer>
    );
  }

  let timeDisplay = '24 Hours';
  const start = dateRange.startTime;
  const end = dateRange.endTime;

  const hoursDifference = differenceInHours(end, start);
  const formattedStartDate = convertToZonedTimestamp(start, timeZone, 'MMMM d');
  const formattedEndDate = convertToZonedTimestamp(end, timeZone, 'MMMM d');

  if (hoursDifference <= 24) {
    timeDisplay = `Last ${hoursDifference} hours`;
  } else {
    timeDisplay = `${formattedStartDate} - ${formattedEndDate}`;
  }

  if (typeof timeDisplay === 'string')
    timeDisplay = t(label || timeDisplay.toLowerCase()) as string;

  return (
    <TimeStampDisplayContainer>
      <IcoDateHistory /> {t('Viewing')}: <strong>{timeDisplay}</strong>
    </TimeStampDisplayContainer>
  );
};

export const TimeStampDisplay = ({
  children,
  displayTimestampTooltip,
  label
}: TimeStampDisplayProps): JSX.Element => {
  const v2Props = useWidgetDateRange();

  if (!v2Props) return <TimeStampDisplayV1>{children}</TimeStampDisplayV1>;

  const { utcDateRange, timeZone, startTime, endTime, ...rest } = v2Props;

  const { t } = useTranslation(['mh']);

  children = label || rest?.label || children;

  if (children || label) {
    children = children || label;

    const stamp = typeof children === 'string' ? t(children.toLowerCase()) : children;

    if (displayTimestampTooltip) {
      return (
        <TimeStampDisplayContainer className="widget-ui__footer time-stamp-footer">
          <TooltipWrapper
            Tooltip={
              <div>
                last updated: {convertToZonedTimestamp(utcDateRange.endTime, timeZone, 'h:mm:ss a')}
              </div>
            }
          >
            <IcoDateHistory />
            {!label && `${t('Viewing')}: `}
            <strong>{stamp}</strong>
          </TooltipWrapper>
        </TimeStampDisplayContainer>
      );
    }

    return (
      <TimeStampDisplayContainer className="widget-ui__footer time-stamp-footer">
        <IcoDateHistory /> {t('Viewing')}: <strong>{stamp}</strong>
      </TimeStampDisplayContainer>
    );
  }

  let timeDisplay = '24 Hours';
  const start = new Date(startTime);
  const end = new Date(endTime);

  const hoursDifference = differenceInHours(end, start);
  const formattedStartDate = convertToZonedTimestamp(start, timeZone, 'MMMM d');
  const formattedEndDate = convertToZonedTimestamp(end, timeZone, 'MMMM d');

  if (hoursDifference <= 24) {
    timeDisplay = `Last ${hoursDifference} hours`;
  } else {
    timeDisplay = `${formattedStartDate} - ${formattedEndDate}`;
  }

  if (typeof timeDisplay === 'string')
    timeDisplay = t(label || timeDisplay.toLowerCase()) as string;

  return (
    <TimeStampDisplayContainer>
      <IcoDateHistory /> {t('Viewing')}: <strong>{timeDisplay}</strong>
    </TimeStampDisplayContainer>
  );
};
