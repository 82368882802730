import React, { ReactNode } from 'react';
import { TooltipV2Container } from './Tooltip.elements';
import { IcoTriangle } from 'icons/IcoTriangle';
import { useTranslation } from 'react-i18next';

export const Tooltip = ({
  children,
  x,
  y,
  left,
  top,
  bottom,
  right,
  title
}: {
  children: ReactNode;
  x?: number;
  y?: number;
  left?: string;
  top?: string;
  bottom?: string;
  right?: string;
  title?: ReactNode;
  [key: string]: unknown;
}): JSX.Element => {
  const { t } = useTranslation(['mh']);

  if (!children && !title) return <></>;

  let TitleToUse = title;

  if (typeof TitleToUse === 'string')
    TitleToUse = <div className="tooltip__title">{t(TitleToUse)}</div>;

  left = left || (x ? `${x}px` : undefined);
  top = bottom ? undefined : top || (y ? `${y}px` : undefined);
  bottom = top ? undefined : bottom;

  return (
    <TooltipV2Container className="tooltip-wrapper" {...{ left, top, bottom, right }}>
      <div className="tooltip">
        <div className="tooltip__inner">
          {TitleToUse}
          {children}
        </div>
        <IcoTriangle fill="white" />
      </div>
    </TooltipV2Container>
  );
};
