import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

interface Props extends StyledUiContainerProps {
  image?: string;
}

const spacer = '3em';

export const MachineOverviewWidgetContainer = styled.div<Props>`
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  display: grid;
  gap: ${spacer};
  padding: 0.5em ${spacer};
  align-items: center;
  justify-content: center;
  min-height: 250px;

  .machine-overview-widget__image {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(${({ image }) => image}) center center no-repeat contain;

    img {
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .machine-overview-widget__tiles {
    display: flex;
    flex-direction: column;
    min-height: 250px;
    justify-content: space-between;
    height: 100%;
    padding-left: 4em;
    margin: 0 auto;
  }

  .machine-overview-widget__tile {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-items: center;
    &:last-of-type {
      border-bottom: none;
    }
  }

  .widget-tile__inner {
    display: grid;
    position: relative;
    grid-template-columns: 5em 1fr;
    flex-grow: 0;

    .widget-tile__cell--icon {
      position: absolute;
      width: 2.9em;
      height: 2.9em;
      left: -1em;
      transform: translateX(-100%);
      top: -5px;
    }
  }
`;

export const WigetTileCellContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;

  &.widget-tile__cell--icon {
    width: 3em;
    height: 3em;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 50%;
      height: 50%;
      path {
        stroke: white;
      }
    }

    span {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      background-color: ${({ color }) => color};
    }
  }

  &.widget-tile__cell--title {
    font-weight: 600;
    font-size: 0.925em;
    text-transform: capitalize;
    box-sizing: content-box;
  }

  &.widget-tile__cell--value {
    padding-left: 1.5em;

    .widget-tile__value {
      font-size: 1.3em;
      line-height: 1;
      align-items: left;
      text-transform: capitalize;
      font-weight: 600;

      color: ${({ color }) => color};

      span {
        color: rgba(0, 0, 0, 0.7);
      }
    }

    .widget-tile__sub-value {
      padding-top: 0.25em;
      text-transform: capitalize;
      font-size: 0.9em;
      color: #666666;
    }

    .widget-tile__label {
      padding-bottom: 0.25em;
      font-size: 0.8em;
      color: #666666;
      text-transform: capitalize;
    }
  }

  &.is-last {
    border-bottom: none;
  }
`;
