// 3rd party libs
import React, { ReactElement, useMemo } from 'react';
import theme from 'themes';
import styled from 'styled-components';
import { AddPlusIcon } from 'icons/AddPlusIcon';
import ScopeDetail from 'pages/UserManagementTwo/components/Group/AddEditGroup/Scope/ScopeDetail';
// Components
import { KPICard, Typography } from 'components';
import { Cell, Row as CardRow, ScopeDetailRow } from 'components/KPICard/CardComponents';
import { GroupItem, GroupScope, LinesById, ScopeCount } from 'types/user-management';
import { BusinessUnit, Line, Organization, Plant } from 'types';
import { sortBy } from 'lodash';
import { DropdownWithCheckboxes } from 'pages/ProteinMachine/MachineConfig/Common/Alerts/DropdownWithCheckboxes';
import { getBUSelectOptions, getSelectedBUs, getTreeBulletPoint } from './ScopeUtils';
import TreeView from 'components/SimpleReactTree/TreeView';
interface Props {
  setGroupInfoState?: React.Dispatch<React.SetStateAction<GroupItem>>;
  organizations?: Organization[];
  plants?: Plant[];
  lines?: Line[];
  groupInfoState?: GroupItem;
  setIsChanged: (isChanged: boolean) => void;
  setCounts: React.Dispatch<React.SetStateAction<ScopeCount>>;
  counts: { organizations: string; plants: string; machines: string };
  isVisibleScopeDetail?: boolean;
  setIsVisibleScopeDetail?: React.Dispatch<React.SetStateAction<boolean>>;
  setScopeState: React.Dispatch<React.SetStateAction<GroupScope>>;
  scopeState: GroupScope;
  businessUnits?: BusinessUnit[];
  selectedBUs?: BusinessUnit[];
  setSelectedBUs?: (BUs: BusinessUnit[]) => void;
  buSelectionVisible?: boolean;
  setBuSelectionVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  businessUnitsCount?: { locations: number; lines: number; machines: number };
  setBusinessUnitsCount?: React.Dispatch<
    React.SetStateAction<{ locations: number; lines: number; machines: number }>
  >;
}
interface BusinessTree {
  id: string | number;
  name: string;
  children?: BusinessTree[];
}
const StyledHeader = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  position: relative;
  width: 100%;
  margin-right: 0 !important;
`;

export const ActionItem = styled.div`
  diplay: flex;
  cursor: pointer;
`;
const BusinessUnitSelect = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;
const BusinessUnitTreeWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  border: 0.063rem solid ${({ theme }) => theme.colors.lightGrey3};
  border-radius: 0.63rem;
  padding-top: 1rem;
  padding-left: 1rem;
  height: auto;
  max-height: 25.875rem;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
`;
const NodeLabel = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 0.575rem;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.25rem;
  text-wrap: pretty;
`;
const getBusinessTree = (BUs: BusinessUnit[] | undefined): BusinessTree[] => {
  if (!BUs) return [];
  const tree: BusinessTree[] = [];

  BUs.forEach((bu) => {
    let td: BusinessTree = { name: '', id: 0 };
    const ods: BusinessTree[] = [];
    if (bu.organizations) {
      for (const org of bu.organizations) {
        let od: BusinessTree = { name: '', id: 0 };
        od = { name: org.name, id: org.id, children: [...org.plants] };
        ods.push(od);
      }
    }
    td = { name: bu.displayName as string, id: bu.id, children: ods };
    tree.push(td);
  });
  return tree;
};
const Scope = ({
  counts,
  plants,
  organizations,
  lines,
  groupInfoState,
  setGroupInfoState,
  setIsChanged,
  setCounts,
  isVisibleScopeDetail,
  setIsVisibleScopeDetail,
  setScopeState,
  scopeState,
  businessUnits,
  businessUnitsCount,
  setBusinessUnitsCount,
  selectedBUs,
  setSelectedBUs,
  buSelectionVisible,
  setBuSelectionVisible
}: Props): ReactElement => {
  const sortedOrgs = sortBy(organizations, [
    (o) => {
      return o.name;
    }
  ]);
  const sortedPlants = sortBy(plants, [
    (p) => {
      return p.name;
    }
  ]);
  const allLines = useMemo(
    () =>
      lines?.reduce((acc, line) => {
        acc[line.id] = line;
        return acc;
      }, {} as LinesById),
    [lines]
  );
  const treeData = useMemo(() => {
    if (groupInfoState?.isInternal) {
      return getBusinessTree(selectedBUs);
    }
  }, [selectedBUs, groupInfoState?.isInternal]);
  const updateBusinessUnitCount = (items: { label: string; value: string }[]) => {
    const counts = { locations: 0, lines: 0, machines: 0 };
    const selected: BusinessUnit[] = [];
    items.forEach((item) => {
      const unit = businessUnits?.find((bu) => bu.id.toString() === item.value);
      if (unit) {
        counts.locations += unit?.organizationCount as number;
        counts.lines += unit?.plantCount as number;
        counts.machines += unit?.machineCount as number;
        selected.push(unit);
      }
    });
    setBusinessUnitsCount && setBusinessUnitsCount(counts);
    setSelectedBUs && setSelectedBUs([...selected]);
  };

  return (
    <>
      <KPICard
        mb="1rem"
        hideHeaderBackgroundColor={true}
        hideBorder={true}
        component={
          <StyledHeader>
            <ActionItem
              onClick={() => {
                if (groupInfoState?.isInternal) {
                  setBuSelectionVisible && setBuSelectionVisible(true);
                  setIsVisibleScopeDetail && setIsVisibleScopeDetail(false);
                } else {
                  setIsVisibleScopeDetail && setIsVisibleScopeDetail(true);
                  setBuSelectionVisible && setBuSelectionVisible(false);
                }
              }}
            >
              <AddPlusIcon /> &nbsp; Add
            </ActionItem>
          </StyledHeader>
        }
      >
        {!groupInfoState?.isInternal ? (
          <CardRow>
            <Cell>
              <Typography size="2.25rem" color={theme.colors.darkGrey} weight="bold">
                {counts.organizations}
              </Typography>
              <Typography size="0.8125rem" color={theme.colors.mediumGrey3} weight="normal">
                Customers
              </Typography>
            </Cell>
            <Cell>
              <Typography size="2.25rem" color={theme.colors.darkGrey} weight="bold">
                {counts.plants}
              </Typography>
              <Typography size="0.8125rem" color={theme.colors.mediumGrey3} weight="normal">
                Sites
              </Typography>
            </Cell>
            <Cell>
              <Typography size="2.25rem" color={theme.colors.darkGrey} weight="bold">
                {counts.machines}
              </Typography>
              <Typography size="0.8125rem" color={theme.colors.mediumGrey3} weight="normal">
                Machines
              </Typography>
            </Cell>
          </CardRow>
        ) : (
          <CardRow>
            <Cell>
              <Typography size="2.25rem" color={theme.colors.darkGrey} weight="bold">
                {businessUnitsCount && businessUnitsCount.locations}
              </Typography>
              <Typography size="0.8125rem" color={theme.colors.mediumGrey3} weight="normal">
                Customers
              </Typography>
            </Cell>
            <Cell>
              <Typography size="2.25rem" color={theme.colors.darkGrey} weight="bold">
                {businessUnitsCount && businessUnitsCount.lines}
              </Typography>
              <Typography size="0.8125rem" color={theme.colors.mediumGrey3} weight="normal">
                Sites
              </Typography>
            </Cell>
            <Cell>
              <Typography size="2.25rem" color={theme.colors.darkGrey} weight="bold">
                {businessUnitsCount && businessUnitsCount.machines}
              </Typography>
              <Typography size="0.8125rem" color={theme.colors.mediumGrey3} weight="normal">
                Machines
              </Typography>
            </Cell>
          </CardRow>
        )}
      </KPICard>
      <div>
        {isVisibleScopeDetail && groupInfoState && !groupInfoState?.isInternal && (
          <ScopeDetailRow>
            <ScopeDetail
              allOrganizations={sortedOrgs ?? []}
              allPlants={sortedPlants ?? []}
              allLines={allLines ?? {}}
              internalAccessGroup={groupInfoState.isInternal as boolean}
              onScopeDetailChange={(scope: GroupScope) => {
                setGroupInfoState &&
                  setGroupInfoState({
                    ...groupInfoState,
                    ...scope
                  });
                setIsChanged(true);
              }}
              setCounts={setCounts}
              setScopeState={setScopeState}
              scopeState={scopeState}
            />
          </ScopeDetailRow>
        )}
        {buSelectionVisible && groupInfoState && groupInfoState?.isInternal && (
          <BusinessUnitSelect>
            <DropdownWithCheckboxes
              placeholder="Select Business Units"
              value={getSelectedBUs(businessUnits as BusinessUnit[], selectedBUs as BusinessUnit[])}
              options={getBUSelectOptions(businessUnits ?? [])}
              handleMultiSelect={(items) => {
                updateBusinessUnitCount(items);
              }}
            />
            {treeData && treeData.length > 0 && (
              <BusinessUnitTreeWrapper>
                <TreeView
                  data={treeData}
                  renderNode={({ name }: { name: string }) => <NodeLabel>{name}</NodeLabel>}
                  lineColor={'#C8C8C8'}
                  expandButtonColor={'#000000'}
                  nodeIcon={getTreeBulletPoint()}
                />
              </BusinessUnitTreeWrapper>
            )}
          </BusinessUnitSelect>
        )}
      </div>
    </>
  );
};

export default Scope;
