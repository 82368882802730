import React from 'react';

export const IcoCross = (color = '#B62C10'): JSX.Element => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.4" stroke={color} strokeWidth="1.2" />
      <path d="M4.68359 14.613L15 6" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
      <path d="M5 6L15 15" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  );
};
