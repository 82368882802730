// 3rd party libs
import React, { useEffect, useRef, useState } from 'react';
import { MachineStatusIndicator } from 'components/MachineStatus/MachineStatusIndicator';
import { useMachineInfo } from '../../providers';
import { useGetAccountInfoQuery } from 'api';
import { convertUTCTimestamptoZonedTimestamp } from 'common/helpers/dateAndTimeHelpersV2';
import moment from 'moment';
import { NUM_OF_TIMESTAMPS_TO_CHECK } from 'pages/DSI/useMachineStatusProvider';
import { useRouter } from 'common/hooks/useRouter';
import { fleetPageRoute } from '../../settings/fleetPageRoutes';

export const MachineViewWatchdogStatusIcon = (): JSX.Element => {
  const { id } = useRouter(fleetPageRoute);

  const { timeZone, businessUnit } = useMachineInfo();

  const { data, isLoading } = useGetAccountInfoQuery(
    { machineId: id },
    {
      pollingInterval: 30000
    }
  );

  const connectionStatus = data?.connectionStatus;
  const [isDisconnected, setIsDisconnected] = useState<boolean>(false);

  // This block is to determine machine status, we save last 5 timestamps and if they are the same, machine is considered offline
  const lastConnectedDates = useRef<string[]>([]);

  useEffect(() => {
    if (!lastConnectedDates) return;

    // we will be comparing timestamps in UTC timezone
    const tz = 'UTC';

    //Check if it was more than 15 min ago
    const nowTimestamp = moment().subtract(15, 'minutes').tz(tz).valueOf();

    const apiTimestamp = connectionStatus && Date.parse(connectionStatus?.lastKnownConnected);

    // if we don't have any timestamp from api, or watchdog timestamp is way in the past then we set disconnected to true
    if (!apiTimestamp || apiTimestamp < nowTimestamp) {
      setIsDisconnected(true);
      return;
    }

    if (lastConnectedDates?.current?.length === NUM_OF_TIMESTAMPS_TO_CHECK) {
      lastConnectedDates?.current?.shift();
    }
    connectionStatus?.lastKnownConnected &&
      lastConnectedDates?.current?.push(connectionStatus.lastKnownConnected);

    //compare dates only when array has all 5 last values
    if (lastConnectedDates?.current?.length === NUM_OF_TIMESTAMPS_TO_CHECK) {
      const isSameTimestamp = new Set(lastConnectedDates?.current);
      if (isSameTimestamp.size === 1) {
        setIsDisconnected(true);
      } else {
        setIsDisconnected(false);
      }
    } else {
      setIsDisconnected(false);
    }
  }, [connectionStatus, id]);

  const formattedDate = data?.connectionStatus?.lastKnownConnected
    ? convertUTCTimestamptoZonedTimestamp(
        data.connectionStatus.lastKnownConnected as string,
        timeZone as string,
        'P, p'
      )
    : undefined;

  return (
    <MachineStatusIndicator
      {...{
        machineStatus: data?.connectionStatus?.watchdog as string,
        formattedDate,
        isLoading,
        isDisconnected,
        businessUnit: businessUnit as string
      }}
    />
  );
};
