import React, { useState, useEffect, useRef } from 'react';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import English from '../../img/English.svg';
import German from '../../img/German1.svg';
import Italian from '../../img/ItalianFlag.svg';
import France from '../../img/France.svg';
import Polish from '../../img/Polish.svg';
import Spanish from '../../img/Spanish.svg';
import Portuguese from '../../img/Portuguese.svg';
import theme from 'themes';

interface Option {
  value: string;
  label: string;
  flag: string;
}

type DefaultLangType = Option | null | undefined;

const DropdownContainer = styled.div`
  display: flex;
  gap: 15px;
`;
const InnerDropdownContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const PopupContainer = styled.ul`
  position: absolute;
  top: 100%;
  background-color: white;
  min-width: 100px;
  padding: 10px;
  border: 1px;
  list-style: none;
  margin: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  @media (max-width: 540px) {
    right: 101%;
    top: 55%;
    border-radius: 0.625rem;
  }
}
`;
const PopupInnerContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const DropdownMenuMobile = styled.div`
  display: flex;
  gap: 15px;
  @media (min-width: 541px) {
    display: none;
  }
`;

const DropdownMenuLargeScreen = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: 540px) {
    display: none;
  }
`;

const options = [
  { value: 'en', label: 'English : English', flag: English },
  { value: 'it', label: 'Italian : Italia', flag: Italian },
  { value: 'de', label: 'German : Deutsch', flag: German },
  { value: 'fr', label: 'France : Français', flag: France },
  { value: 'pl', label: 'Polish : Pologne', flag: Polish },
  { value: 'es', label: 'Spanish : Español', flag: Spanish },
  { value: 'pt', label: 'Portuguese : Português', flag: Portuguese }
];

const DropdownSelect: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation(['common']);

  const defaultLang = options.find((element: Option) => {
    if (i18n.language === element.value) {
      return element;
    } else return null;
  });

  // State for managing the selected option
  const [selectedOption, setSelectedOption] = useState<DefaultLangType>(defaultLang);
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for the dropdown container

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle option selection
  const handleOptionSelect = (option: Option): void => {
    const language = typeof option.value === 'string' ? option.value : '';
    setSelectedOption(option);
    setIsOpen(false);
    i18n.changeLanguage(language);
  };

  // Effect to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownMenuLargeScreen>
        <div>
          <InnerDropdownContainer>
            {selectedOption ? (
              <>
                <span style={{ color: '#E5E9ED' }}>{selectedOption.value.toUpperCase()}</span>
                <img src={selectedOption.flag} />
              </>
            ) : (
              <>
                {options[0].label === 'English : English' && (
                  <>
                    <span style={{ color: '#E5E9ED' }}>{options[0].value.toUpperCase()}</span>
                    <img src={options[0].flag} />
                  </>
                )}
              </>
            )}
          </InnerDropdownContainer>
        </div>
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{ color: '#E5E9ED', cursor: 'pointer' }}
          onClick={toggleDropdown}
        />
      </DropdownMenuLargeScreen>
      <DropdownMenuMobile>
        <div>
          <InnerDropdownContainer>
            {selectedOption ? (
              <>
                <img src={selectedOption.flag} />
                <span>{selectedOption.label.split(':')[0]}</span>
              </>
            ) : (
              <>
                {options[0].label === 'English : English' && (
                  <>
                    <img src={options[0].flag} />
                    <span>{options[0].label.split(':')[0]}</span>
                  </>
                )}
              </>
            )}
          </InnerDropdownContainer>
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          style={{ color: `${theme.colors.primaryBlue5}`, cursor: 'pointer', fontSize: '13px' }}
          onClick={toggleDropdown}
        />
      </DropdownMenuMobile>
      {isOpen && (
        <PopupContainer>
          {options.map((option) => (
            <li
              key={option.value}
              style={{
                padding: 5,
                cursor: 'pointer'
              }}
              onClick={() => handleOptionSelect(option)}
            >
              <PopupInnerContainer>
                <img src={option.flag} />
                <span style={{ paddingLeft: 8 }}> {option.label}</span>
              </PopupInnerContainer>
            </li>
          ))}
        </PopupContainer>
      )}
    </DropdownContainer>
  );
};

export default DropdownSelect;
