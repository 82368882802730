import React, { ReactNode } from 'react';
import { LargeTextTileWidgetContainer } from './LargeTextTileWidget.elements';
import { WidgetUi, WidgetUiProps } from 'common/ui';
import { useTranslation } from 'react-i18next';

export interface LargeTextTileWidgetValueProps {
  title?: ReactNode;
  value?: ReactNode;
  label?: ReactNode;
  unit?: ReactNode;
  color?: string;
}

export interface LargeTextTileWidgetProps extends WidgetUiProps {
  values?: LargeTextTileWidgetValueProps[];
  value?: LargeTextTileWidgetValueProps;
}

export const LargeTextTileWidget = ({
  value,
  values,
  ...widgetSettings
}: LargeTextTileWidgetProps): JSX.Element => {
  if (!value && !values) return <></>;

  values = (values || [value]) as Record<string, unknown>[];

  const { t } = useTranslation(['mh']);

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <LargeTextTileWidgetContainer className="widget-ui__main">
          {values?.map(({ value, title, label, unit }, index) => (
            <div className="large-text-container" key={index}>
              {title && <div className="large-text-title">{t(String(title))}</div>}
              {
                <div className="large-text-value">
                  {value || '-'}
                  {unit && <span className="large-text-unit">{unit}</span>}
                </div>
              }
              {label && <div className="large-text-label">{t(String(label))}</div>}
            </div>
          ))}
        </LargeTextTileWidgetContainer>
      }
    />
  );
};
