import React, { ReactElement, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

// Components
import { BaseSelect, Button, Checkbox, Indicator, Input, Typography } from 'components';
import { ButtonContainer, DayPickerContainer } from '..';

// Types
import { ChangeEvent } from 'types';
import {
  MaintenanceEvent,
  MaintenanceEventStatus,
  MaintenanceFrequencyType,
  MaintenanceTaskType
} from 'types/maintenance';
import { markTaskCompleteOrIncompleteByType, pmFormatDate, standardFormatDate } from 'helpers';
import { useGetMachinesCurrentRunMetricQuery, useUpdateMaintenanceEventsMutation } from 'api';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { AnalyticsCategories, MaintenanceAnalyticEventActions } from 'constants/analytics';
import { generateAnalyticsEvent } from 'helpers/analytics';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ToastMsg } from 'common/components/Toast/Toast';
import RequiredMark from 'components/RequiredMarker';
import { isAlphaNumeric, isValidEmail } from 'helpers';

interface MaintenanceEventCloseOutSurveyProps {
  maintenanceEvent: MaintenanceEvent;
  surveyCompletedAsPlanned: boolean;
  onSubmitClick: (event?: MaintenanceEvent) => void;
  machineDescription: string | undefined;
}

type propNames =
  | 'owner'
  | 'completion'
  | 'completionCycleCount'
  | 'duration'
  | 'comments'
  | 'followUpNeeded';

const cycleCountOptions = [
  '5 min',
  '10 min',
  '15 min',
  '30 min',
  '45 min',
  '60 min',
  '90 min',
  '90+ min'
];

// Styling
const Container = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
  position: relative;
`;

const InputGroup = styled.div`
  margin-top: 2rem;
  margin-bottom: 0.75rem;
`;

const Label = styled.label`
  display: block;
  color: #5d6a86;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1rem;
  margin-bottom: 0.25rem;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.lightGrey6};
  box-shadow: ${({ theme }) => theme.colors.borders.border02.shadow};
  border-radius: 0.375rem;
`;

const FollowUpContainer = styled.div`
  margin-bottom: 1rem;
  margin-left: 1rem;
`;
const ValidatorText = styled.div`
  color: red;
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;
function isCloseOutSurveyValid(event: MaintenanceEvent): boolean {
  // events with day-based frequency type only need a completion date, cycle based events need both
  // a completion date and cycle count

  //TODO: How to handle for SIP and Run Hours?
  if (event.frequencyType === MaintenanceFrequencyType.Cycles && !event.completionCycleCount) {
    return false;
  }
  if (event.owner === 'SYSTEM') event = { ...event, owner: '' }; //BE job required to remove system as owner...pending
  return Boolean(
    event.completion && event.owner.length > 0 && event.duration && event.completionCycleCount > 0
  );
}

const MaintenanceEventCloseOutSurvey = ({
  maintenanceEvent,
  surveyCompletedAsPlanned,
  onSubmitClick,
  machineDescription
}: MaintenanceEventCloseOutSurveyProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation(['fpns']);
  const [updateMaintenanceEvent] = useUpdateMaintenanceEventsMutation();
  const indicatorColor = surveyCompletedAsPlanned
    ? theme.colors.onTrackGreen
    : theme.colors.negativeRed;
  const indicatorText = surveyCompletedAsPlanned
    ? t('completed_as_planned')
    : t('not_completed_as_planned');
  const [localMaintenanceEvent, setLocalMaintenanceEvent] =
    useStateWithCallbackLazy<MaintenanceEvent>(maintenanceEvent);
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);
  const [closedRunMetric, setClosedRunMetric] = useState<string>();
  const [isValidAssigned, setIsValidAssigned] = useState<boolean>(true);
  const { data: machineCurrentRunMetric, isFetching } = useGetMachinesCurrentRunMetricQuery(
    maintenanceEvent.machineId
      ? {
          machineUuids: [maintenanceEvent.machineId]
        }
      : skipToken
  );

  useEffect(() => {
    setClosedRunMetric(
      machineCurrentRunMetric?.length == 1 &&
        machineCurrentRunMetric[0].runMetricValue &&
        machineCurrentRunMetric[0].runMetricLabel
        ? `${
            machineCurrentRunMetric[0].runMetricValue
          } ${machineCurrentRunMetric[0].runMetricLabel.replaceAll('_', ' ')}`
        : ''
    );
  }, [machineCurrentRunMetric]);

  const copyPMInfo = (
    baseEvent: MaintenanceEvent,
    propName: string,
    value: string | number | Date | boolean | MaintenanceEventStatus
  ) => {
    const updatedEvent = { ...baseEvent, [propName]: value } as MaintenanceEvent;
    setLocalMaintenanceEvent(updatedEvent, noop);
  };

  const handleChange = (value: string | Date | number | boolean, propName: propNames) => {
    copyPMInfo(localMaintenanceEvent, propName, value);
  };

  const handleDayChange = (selectedDay: Date) => {
    copyPMInfo(localMaintenanceEvent, 'completion', standardFormatDate(selectedDay));
  };
  const isValidAssingedTo = (value: string): boolean => {
    return (isAlphaNumeric(value) || isValidEmail(value)) && value.length <= 75;
  };
  useEffect(() => {
    // has to be done this way to make sure that state changes were consumed by React before submission
    if (isCloseOutSurveyValid(localMaintenanceEvent) && shouldSubmit) {
      const sendEvent: MaintenanceEvent = {
        ...localMaintenanceEvent,
        ['status']: MaintenanceEventStatus.Completed
      };
      updateMaintenanceEvent([sendEvent]).then(() => {
        ToastMsg({
          message: t('maintenance_completed', { machine_name: machineDescription }),
          heading: t('complete') as string,
          type: 'success'
        });
        generateAnalyticsEvent({
          category: AnalyticsCategories.MAINTENANCE,
          action: MaintenanceAnalyticEventActions.MAINTENANCE_EVENT_COMPLETED,
          label: maintenanceEvent?.id
        });

        onSubmitClick(sendEvent);
      });
      setShouldSubmit(false);
    }
  });

  return (
    <Container>
      <Typography as="h3" mb={'.5rem'} size="1.125rem" weight="bold">
        {machineDescription}
      </Typography>
      <Indicator style={{ marginLeft: 0 }} color={indicatorColor}>
        {indicatorText}
      </Indicator>
      <Typography as="h4" mb={'.5rem'} size="1rem" weight="semi-bold">
        {t('assigned_to')} <RequiredMark color={theme.colors.text.error} size={'1.3rem'} />
      </Typography>
      <Input
        type="string"
        id="assignedTo"
        variant="white-dark"
        onChange={(event: ChangeEvent) => {
          if (isValidAssingedTo(event.target.value)) {
            handleChange(event.target.value.trimStart() as string, 'owner');
            setIsValidAssigned(true);
          } else {
            handleChange('', 'owner');
            setIsValidAssigned(false);
          }
        }}
        placeholder="Enter Alphanumeric values or a valid email"
      />{' '}
      {!isValidAssigned && (
        <ValidatorText>Enter Alphanumeric values or a valid email</ValidatorText>
      )}
      <br /> <br />
      <Typography mb={'.5rem'} weight="semi-bold" size="1rem">
        {t('completion_date')} <RequiredMark color={theme.colors.text.error} size={'1.3rem'} />
      </Typography>
      <DayPickerContainer>
        <DayPickerInput
          value={localMaintenanceEvent.completion as Date}
          formatDate={pmFormatDate}
          placeholder={t('select_completion_date') as string}
          onDayChange={handleDayChange}
          dayPickerProps={{
            disabledDays: {
              after: new Date()
            }
          }}
          inputProps={{
            title: t('select_completion_date') as string,
            readonly: 'readonly'
          }}
        />
      </DayPickerContainer>
      <br />
      <Typography mb={'.5rem'} weight="semi-bold" size="1rem">
        {t('completion_cycle_count')}{' '}
        <RequiredMark color={theme.colors.text.error} size={'1.3rem'} />
      </Typography>
      <Input
        type="number"
        min="1"
        id="completionCycleCount"
        variant="white-dark"
        value={localMaintenanceEvent.cycleCount}
        onChange={(event: ChangeEvent) => {
          handleChange(Number.parseInt(event.target.value), 'completionCycleCount');
        }}
      />{' '}
      <br />
      <br />
      <Typography mb={'.5rem'} weight="semi-bold" size="1rem">
        {t('duration')} <RequiredMark color={theme.colors.text.error} size={'1.3rem'} />
      </Typography>
      <BaseSelect
        variant={'white'}
        value={localMaintenanceEvent.duration as string}
        handleChange={(event): void => {
          handleChange(event.target.value, 'duration');
        }}
        options={cycleCountOptions}
        placeholder={t('select_dot', { ns: 'common' }) as string}
        id="duration"
      />
      <InputGroup>
        <Label>
          <Typography mb={'.5rem'} weight="semi-bold" size="1rem">
            {surveyCompletedAsPlanned ? t('leave_comments_optional') : t('leave_comments')}
          </Typography>
        </Label>
        <StyledTextarea
          rows={8}
          value={localMaintenanceEvent.comments}
          placeholder={!surveyCompletedAsPlanned ? (t('what_went_wrong') as string) : ''}
          onChange={(event) => {
            handleChange(event.target.value as string, 'comments');
          }}
        />
      </InputGroup>
      <FollowUpContainer>
        <Checkbox
          key={`checkbox-survey-${localMaintenanceEvent.id}`}
          checked={
            localMaintenanceEvent.followUpNeeded === undefined
              ? false
              : localMaintenanceEvent.followUpNeeded
          }
          label={t('create_follow_up_service') as string}
          labelWeight={500}
          onChange={() => {
            handleChange(!localMaintenanceEvent.followUpNeeded, 'followUpNeeded');
          }}
        />
      </FollowUpContainer>
      <ButtonContainer>
        <Button
          borderRadius="3rem"
          variant="hover-blue"
          bgColor={theme.colors.primaryBlue5}
          color={theme.colors.lightGrey3}
          onClick={() => {
            setLocalMaintenanceEvent(
              {
                ...markTaskCompleteOrIncompleteByType(
                  MaintenanceTaskType.Complete,
                  localMaintenanceEvent
                ),
                closedRunMetric,
                completedAsPlanned: surveyCompletedAsPlanned
              },
              () => {
                // ensure all state updates are finished before submission
                setShouldSubmit(true);
              }
            );
          }}
          disabled={!isCloseOutSurveyValid(localMaintenanceEvent) && !isFetching}
        >
          {t('submit_and_mark_complete')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default MaintenanceEventCloseOutSurvey;
