import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, Typography } from 'components';
import theme from 'themes';
import { CTAsStretch, Table } from '.';
import { MaintenanceEvent, MaintenanceTaskType } from 'types/maintenance';
import { useUpdateMaintenanceEventsMutation } from 'api';
import { formatDate, markTaskCompleteOrIncompleteByType } from 'helpers';
import { IcoUser } from 'icons/IcoUser';
import { CardFooter } from '../MaintenanceEventDetailCard';
import { IcoTrash } from 'icons/IcoTrash';
import { BtnText, DownArrow } from './CloseOutSurveyCard';
import { IcoChevronDown } from 'icons/IcoChevronDown';
import { ToastMsg } from 'common/components/Toast/Toast';
import CustomLoader from 'components/CustomLoader';
import { LoaderContainer } from 'components/CustomLoader/index.elements';

interface AssignAndScheduleTaskCardProps {
  maintenanceEvent: MaintenanceEvent;
  onClick: CallableFunction;
  machineDescription?: string | undefined;
}

const AssignAndScheduleTaskCard = ({
  maintenanceEvent,
  onClick,
  machineDescription
}: AssignAndScheduleTaskCardProps): JSX.Element => {
  const [updateMaintenanceEvent, { isLoading }] = useUpdateMaintenanceEventsMutation();
  const handleClearAssignment = () => {
    updateMaintenanceEvent([
      {
        ...markTaskCompleteOrIncompleteByType(
          MaintenanceTaskType.AssignAndSchedule,
          maintenanceEvent,
          false
        ),
        owner: 'SYSTEM',
        scheduled: undefined
      }
    ]).then(() => {
      ToastMsg({
        message: t('maintenance_unassigned', { item: machineDescription }),
        heading: t('unassigned') as string,
        theme: 'colored',
        type: 'info'
      });
    });
  }
  const { t } = useTranslation(['fpns']);
  return (
    <Card borderColor={theme.colors.tableborder}>
      <Card.Header bgColor={theme.colors.white} padding="0rem 1rem" icon={<IcoUser width="25" />}>
        <Typography variant="h3" mb={0} size="0.8125rem" weight="medium">
          {t('assign_and_schedule')}
        </Typography>
        <DownArrow>
          <IcoChevronDown width="24" color={theme.colors.primaryBlue5} />
        </DownArrow>
      </Card.Header>
      <Card.Body pl={'3rem'} pt={0}>
        <Table col={2.4}>
          <tbody>
            <tr>
              <td>
                <Typography as="span" size=".95rem" weight="semi-bold">
                  {t('estimated_labor')}:
                </Typography>
              </td>
              <td>
                <Typography as="span" size=".95rem" weight="semi-bold">
                  {maintenanceEvent?.estimatedCompletionTime
                    ? maintenanceEvent.estimatedCompletionTime
                    : t('no_time_estimate_available')}
                </Typography>
              </td>
            </tr>

            {maintenanceEvent.scheduled && maintenanceEvent.owner && (
              <>
                <tr>
                  <td>
                    <Typography as="span" size="0.95rem" weight="semi-bold">
                      Assigned to:&nbsp;
                    </Typography>
                  </td>
                  <td>
                    <Typography as="span" size=".95rem" weight="semi-bold">
                      {maintenanceEvent?.owner && maintenanceEvent?.owner !== 'SYSTEM'
                        ? maintenanceEvent.owner
                        : t('not_yet_assigned')}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography as="span" size="0.95rem" weight="semi-bold">
                      Scheduled for:&nbsp;
                    </Typography>
                  </td>
                  <td>
                    <Typography as="span" size=".95rem" weight="semi-bold">
                      {maintenanceEvent?.scheduled
                        ? formatDate(maintenanceEvent.scheduled, 'short')
                        : t('not_yet_scheduled')}
                    </Typography>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <CardFooter>
        <CTAsStretch>
          <Button
            variant="text"
            size="small"
            width="auto"
            onClick={ handleClearAssignment }
            disabled={!(maintenanceEvent.owner && maintenanceEvent.scheduled)}
          >
            <BtnText>
            {
                isLoading ? <LoaderContainer padding='.7rem .5rem'><CustomLoader size='1.25rem'/></LoaderContainer>
                :
              <span style={{ paddingTop: '.7rem' }}>
                <IcoTrash
                  color={
                    maintenanceEvent.owner && maintenanceEvent.scheduled
                      ? theme.colors.darkGrey
                      : theme.colors.lightGrey9
                  }
                  width="25"
                />
              </span>
              }
              <Typography
                mb={0}
                size={'1rem'}
                weight="semi-bold"
                color={
                  maintenanceEvent.owner && maintenanceEvent.scheduled
                    ? theme.colors.darkGrey
                    : theme.colors.lightGrey9
                }
              >
                {t('clear_assignment')}
              </Typography>

            </BtnText>
          </Button>
          <Button
            color={theme.colors.primaryBlue5}
            variant="text"
            size="small"
            onClick={() => onClick()}
            width="auto"
          >
            <BtnText>
              <span>
                <IcoUser />
              </span>
              <Typography weight="semi-bold" mb={0} size={'1rem'} color={theme.colors.primaryBlue5}>
                {t('assign_and_schedule')}
              </Typography>
            </BtnText>
          </Button>
        </CTAsStretch>
      </CardFooter>
    </Card>
  );
};

export default AssignAndScheduleTaskCard;
