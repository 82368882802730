import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// 3rd party
import styled from 'styled-components';

// Helpers
import { toISO8601 } from 'helpers';

// Components
import { DashboardWidget } from 'components';

// Types
import { AlarmLocation } from 'types/machine-health/alarms';
import {
  AlertAcknowledgeStatus,
  AlertConfigAlertLocation,
  AlertLocation,
  AlertStatus
} from 'types/machine-health/alerts';

// Api
import {
  useGetAlertsByMachineIdQuery,
  useGetMachineAlarmsQuery,
  useGetMachineDataScienceAlertsQuery,
  useGetThresholdAlertsQuery
} from 'api';
import { FrameIcon } from 'icons/FrameIcon';
import { ActiveIssues } from '../IssuesTable';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { usePermissions } from 'hooks';
interface IssuesPastSessionCardProps {
  machineId: string;
  startTimestamp: string;
  endTimestamp?: string;
  ThresholdAlertLocation?: string;
}

const TableContainer = styled.div`
  max-height: 19.563rem;
  .rc-table {
    border-radius: 0 !important;
    border-width: 0;
    overflow: hidden;
    tbody {
      height: 100%;
    }
  }
`;
const NoDataText = styled.div`
  font-weight: 500;
  color: rgb(102, 102, 102);
  font-size: 1rem;
  left: 40%;
  position: absolute;
  top: 50%;
`;
const MIN_SCROLL_HEIGHT = 145;

const IssuesPastSessionCard = ({
  machineId,
  startTimestamp,
  endTimestamp,
  ThresholdAlertLocation
}: IssuesPastSessionCardProps): ReactElement => {
  const startDatetime = startTimestamp && toISO8601(new Date(startTimestamp));
  const endDatetime = endTimestamp && toISO8601(new Date(endTimestamp));
  const { scopes } = usePermissions();

  const userHaveAlertsPermission = useMemo(() => {
    const alertScope = scopes.find((scope) => {
      return scope.name === 'Alert Management';
    });
    return alertScope?.read || false;
  }, [scopes]);

  const [filterList, setFilterList] = useState({
    filterAlert: true,
    filterAlarm: true,
    filterMachineAlert: true
  });
  const {
    data: alarmsData,
    isLoading: alarmsLoading,
    error: alarmsError
  } = useGetMachineAlarmsQuery({
    machineId,
    location_id: AlarmLocation.CLE,
    startDatetime,
    endDatetime
  });

  const {
    data: alertsData,
    isLoading: alertsLoading,
    error: alertsError
  } = useGetMachineDataScienceAlertsQuery({
    machineId,
    startDatetime,
    endDatetime,
    location: AlertLocation.Cleaning
  });

  const {
    data: machineAlerts,
    error: machineAlertsError,
    isLoading: machineAlertsLoading
  } = useGetAlertsByMachineIdQuery({
    machineId,
    active: true,
    startDatetime,
    endDatetime,
    locations: [AlertConfigAlertLocation.Cleaning]
  });

  const ApiEndPoints = useMemo(() => {
    return {
      apiStatus: AlertStatus.ACTIVE,
      secondApiStatus: AlertStatus.INACTIVE,
      acknowledgeStatus: undefined,
      secondAcknowledgeStatus: AlertAcknowledgeStatus.NOT_ACKNOWLEDGED,
      needsAcknowledge: undefined,
      secondNeedsAcknowledge: 'true'
    };
  }, []);

  const {
    data: machineThresholdAlerts,
    error: machineThresholdAlertsError,
    isLoading: machineThresholdAlertsLoading
  } = useGetThresholdAlertsQuery(
    userHaveAlertsPermission &&
      ThresholdAlertLocation?.length !== 0 &&
      process.env.REACT_APP_ENABLE_ALERTS_TAB === 'true'
      ? {
          machineId,
          widgetId: ThresholdAlertLocation,
          startDate: startDatetime,
          endDate: endDatetime,
          status: ApiEndPoints.apiStatus,
          acknowledgmentStatus: ApiEndPoints.acknowledgeStatus,
          needsAcknowledgment: ApiEndPoints.needsAcknowledge
        }
      : skipToken
  );

  const {
    data: secondaryApi,
    error: secondaryApiError,
    isLoading: secondaryApiLoading
  } = useGetThresholdAlertsQuery(
    userHaveAlertsPermission &&
      ThresholdAlertLocation?.length !== 0 &&
      process.env.REACT_APP_ENABLE_ALERTS_TAB === 'true'
      ? {
          machineId: machineId,
          widgetId: ThresholdAlertLocation,
          startDate: startDatetime,
          endDate: endDatetime,
          status: ApiEndPoints.secondApiStatus,
          acknowledgmentStatus: ApiEndPoints.secondAcknowledgeStatus,
          needsAcknowledgment: ApiEndPoints.secondNeedsAcknowledge
        }
      : skipToken
  );

  const sortedByActiveAndStartTime = useMemo(() => {
    if (machineThresholdAlerts && secondaryApi) {
      const mergedData = [...machineThresholdAlerts, ...secondaryApi];
      return mergedData;
    }
  }, [machineThresholdAlerts, secondaryApi]);

  const thresholAlertLoading =
    secondaryApiLoading || machineThresholdAlertsLoading || ThresholdAlertLocation?.length === 0;

  const isLoading = alarmsLoading || alertsLoading || machineAlertsLoading || thresholAlertLoading;

  const hasError =
    alarmsError &&
    alertsError &&
    machineAlertsError &&
    secondaryApiError &&
    machineThresholdAlertsError;
  const { t } = useTranslation(['mh']);

  const widgetSettings = {
    title: t('Issues Selected Session'),
    isLoading: isLoading ? true : false,
    hasError: hasError && t('Failed to load Active Issues'),
    hasCustomMenu: true,
    noPad: true,
    customDropdownItems: [
      {
        title: t('See Alarms'),
        onClick: () =>
          setFilterList({
            filterAlert: false,
            filterAlarm: true,
            filterMachineAlert: false
          }),
        icon: <FrameIcon />
      },
      {
        title: t('See Alerts'),
        onClick: () =>
          setFilterList({
            filterAlert: true,
            filterAlarm: false,
            filterMachineAlert: false
          }),
        icon: <FrameIcon />
      },
      {
        title: t('see_all'),
        onClick: () =>
          setFilterList({
            filterAlert: true,
            filterAlarm: true,
            filterMachineAlert: true
          }),
        icon: <FrameIcon />
      }
    ],
    itemCount:
      (filterList.filterAlarm && alarmsData?.length ? alarmsData?.length : 0) +
      (filterList.filterAlert && alertsData?.length ? alertsData?.length : 0) +
      (filterList.filterAlert && sortedByActiveAndStartTime?.length
        ? sortedByActiveAndStartTime?.length
        : 0) +
      (filterList.filterMachineAlert && machineAlerts?.length ? machineAlerts?.length : 0)
  };

  return (
    <DashboardWidget {...widgetSettings}>
      <TableContainer className="widget-ui-main no-padding">
        {widgetSettings?.itemCount === 0 ? (
          <NoDataText>No Active Issues</NoDataText>
        ) : (
          <>
            <ActiveIssues
              scrollHeight={MIN_SCROLL_HEIGHT}
              alarmsData={isLoading || !filterList.filterAlarm ? [] : alarmsData}
              alertsData={isLoading || !filterList.filterAlert ? [] : alertsData}
              machineAlerts={isLoading || !filterList.filterMachineAlert ? [] : machineAlerts}
              alertsTriggers={
                isLoading || !filterList.filterAlert ? [] : sortedByActiveAndStartTime
              }
              isLoading={isLoading}
            />
          </>
        )}
      </TableContainer>
    </DashboardWidget>
  );
};

export default IssuesPastSessionCard;
