import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const MachineStatesContainer = styled.div`
  margin-top: 1rem;
`;

export const EditButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const IconButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;
