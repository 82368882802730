// 3rd party
import React, { ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// Types
import { ChangeEvent, ModalSize, Plant } from 'types';
import { CartOrderDetails } from 'types/parts/cart';

// Styling
const Root = styled.div`
  padding: 0.75rem 1.5rem 1.25rem 2.375rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6.25rem;
  overflow: auto;
`;

// Components
import { Checkbox, Modal, Input, Typography, Button } from 'components';
import { useGetPlantsQuery } from 'api';
import PlantAutofillTable from './PlantAutofillTable';

// Hook
import { useUser } from 'selectors';
import theme from 'themes';
import { IcoUploadFile } from 'icons/IcoUploadFile';
import ChipContainer from './Chip';
import { isAlphaNumeric } from 'helpers';
import { ToastMsg } from 'common/components/Toast/Toast';

// Constants
import breakpoint from 'constants/breakpoints';

const Container = styled.div`
  width: 50%;
  margin-top: 2rem;
  min-width: 13.5rem;
  padding-right: 1.875rem;

  &:nth-child(2n) {
    padding-right: 0;
    padding-left: 1.875rem;
    @media (max-width: ${breakpoint.size.md}px) {
      padding-left: 0;
    }
  }

  @media (max-width: ${breakpoint.size.md}px) {
    width: 100%;
    padding-right: 0;
  }
`;

const InputRowBase = styled.div`
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const InputRow = styled(InputRowBase)`
  & > div {
    flex-grow: 1;
    margin-right: 0.8125rem;
    display: flex;
    align-items: center;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

const InputRowAddressButtons = styled(InputRowBase)`
  display: flex;
  justify-content: flex-end;
`;

interface InputContainerProps {
  justify?: 'flex-start' | 'center' | 'flex-end';
}
const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};

  input {
    border-radius: 0.375rem;
  }
  input:focus {
    outline: none;
  }
  input[type='checkbox'] {
    border-radius: 0.375rem;
  }
  .purchase_order-input {
    margin-right: 20px;
  }
  .purchase_order-input,
  .purchange_order-label {
    flex: 1;
  }
`;

const BillToRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AddressButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: ${breakpoint.size.sm}px) {
    flex-direction: column;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLable = styled.label`
  width: 15rem;
  display: flex;
  align-items: center;
  color: ${theme.colors.primaryBlue5};
  font-weight: 500;
  gap: 0.5rem;
  line-height: normal;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
`;

// Props
interface Props {
  orderDetails: CartOrderDetails;
  handleUpdate: (updatedDetails: CartOrderDetails) => void;
}

const CartFulfillmentDetails = ({ orderDetails, handleUpdate }: Props): ReactElement => {
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  const { data: plants, isFetching: plantsLoading } = useGetPlantsQuery();
  const user = useUser();
  const { t } = useTranslation(['fpns']);

  const [fileList, setFileList] = useState<File[] | undefined>();

  // If the user data flows through after initialization
  useEffect(() => {
    if (user && orderDetails.creator === '') {
      handleUpdate({ ...orderDetails, creator: user.email });
    }
  }, [user]);

  // Input change handling functions
  const handleDetailsUpdate = (details: CartOrderDetails) => {
    handleUpdate(details);
  };

  const handleShipSameAsBilling = (details: CartOrderDetails) => {
    handleDetailsUpdate({
      ...details,
      shippingAddress: details.shipMatchBilling
        ? {
            streetAddress: details.billingAddress.streetAddress,
            suiteNumber: details.billingAddress.suiteNumber,
            city: details.billingAddress.city,
            state: details.billingAddress.state,
            postalCode: details.billingAddress.postalCode,
            country: details.billingAddress.country
          }
        : {
            streetAddress: '',
            suiteNumber: '',
            city: '',
            state: '',
            postalCode: '',
            country: ''
          }
    });
  };
  const handleNameChange = (name: string, propName: 'firstName' | 'lastName') => {
    const copyBillee = { ...orderDetails.billee, [propName]: name.trimStart() };
    const copyDetails = { ...orderDetails, billee: { ...copyBillee } };
    handleDetailsUpdate(copyDetails);
  };

  const autofillAddress = (plant: Plant | undefined): void => {
    if (plant) {
      handleDetailsUpdate({
        ...orderDetails,
        billingAddress: {
          streetAddress: plant.addressLine1,
          suiteNumber: plant.addressLine2 ? plant.addressLine2 : '',
          city: plant.city,
          state: plant.state,
          postalCode: plant.zipCode,
          country: plant.countryName
        }
      });
    } else {
      handleDetailsUpdate({
        ...orderDetails,
        billingAddress: {
          streetAddress: '',
          suiteNumber: '',
          city: '',
          state: '',
          postalCode: '',
          country: ''
        },
        shipMatchBilling: false,
        shippingAddress: {
          streetAddress: '',
          suiteNumber: '',
          city: '',
          state: '',
          postalCode: '',
          country: ''
        }
      });
    }
    if (plant) {
      toast.success(t('autofilled_address', { item: plant.name }));
    } else {
      toast.success(t('cleared_address'));
    }
  };

  const handleAddressChange = (
    val: string,
    billOrShip: 'billingAddress' | 'shippingAddress',
    propName: 'streetAddress' | 'suiteNumber' | 'city' | 'state' | 'postalCode' | 'country'
  ) => {
    const copyAddr = { ...orderDetails[billOrShip], [propName]: val.trimStart() };
    const copyDetails = { ...orderDetails, [billOrShip]: { ...copyAddr } };
    handleDetailsUpdate(copyDetails);
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleFileUpload = (event: any) => {
    const allFiles = fileList
      ? ([...fileList, ...event.target?.files] as File[])
      : [...event.target?.files];
    const file = allFiles.filter((file) => {
      if (file.size > 10000000) {
        ToastMsg({
          heading: 'Error',
          message: 'File should be less than 10MB',
          type: 'error'
        });
      }
      return file.size <= 10000000;
    });
    setFileList(file);
    handleDetailsUpdate({ ...orderDetails, files: file });
  };

  const handleFileRemove = (file?: File) => {
    const remainingFile = fileList?.filter((f) => f.name !== file?.name);
    setFileList(remainingFile);
    handleDetailsUpdate({ ...orderDetails, files: remainingFile });
  };

  return (
    <Root>
      <Container>
        <BillToRow>
          <Typography mb={0} variant="subtitle">
            {t('bill_to')}
          </Typography>
        </BillToRow>

        <InputRow>
          <InputContainer>
            <Input
              variant="white"
              placeholder={t('first_name', { ns: 'common' })}
              width="50%"
              value={orderDetails?.billee?.firstName}
              onChange={(event: ChangeEvent) => {
                const firstName = event.target.value as string;
                handleNameChange(firstName, 'firstName');
              }}
            />
          </InputContainer>
          <InputContainer>
            <Input
              variant="white"
              placeholder={t('last_name', { ns: 'common' })}
              value={orderDetails?.billee?.lastName}
              onChange={(event: ChangeEvent) => {
                const lastName = event.target.value as string;
                handleNameChange(lastName, 'lastName');
              }}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer>
            <Input
              variant="white"
              placeholder={t('street_address', { ns: 'common' })}
              value={orderDetails?.billingAddress?.streetAddress ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'billingAddress', 'streetAddress');
              }}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer>
            <Input
              variant="white"
              placeholder={t('app_suite_unit', { ns: 'common' })}
              value={orderDetails?.billingAddress?.suiteNumber ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'billingAddress', 'suiteNumber');
              }}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer>
            <Input
              variant="white"
              placeholder={t('city', { ns: 'common' })}
              value={orderDetails?.billingAddress?.city ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'billingAddress', 'city');
              }}
            />
          </InputContainer>
          <InputContainer>
            <Input
              variant="white"
              placeholder={t('state', { ns: 'common' })}
              value={orderDetails?.billingAddress?.state ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'billingAddress', 'state');
              }}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer>
            <Input
              variant="white"
              placeholder={t('postal_code', { ns: 'common' })}
              value={orderDetails?.billingAddress?.postalCode ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'billingAddress', 'postalCode');
              }}
            />
          </InputContainer>
          <InputContainer>
            <Input
              variant="white"
              placeholder={t('country', { ns: 'common' })}
              value={orderDetails?.billingAddress?.country ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'billingAddress', 'country');
              }}
            />
          </InputContainer>
        </InputRow>
        <InputRowAddressButtons>
          <AddressButtons>
            <Button variant="outline-blue" size="small" onClick={() => autofillAddress(undefined)}>
              {t('clear_address')}
            </Button>
            <Button
              variant="filled-primary"
              size="small"
              disabled={!plants || plantsLoading}
              onClick={() => setShowAddressModal(!showAddressModal)}
            >
              {t('use_saved_address')}
            </Button>
          </AddressButtons>
        </InputRowAddressButtons>
      </Container>
      <Container>
        <Typography mb={0} variant="subtitle">
          {t('ship_to')}
        </Typography>
        <InputRow>
          <Checkbox
            width={20}
            height={20}
            id={'same_as_billing_address'}
            label={t('same_as_billing_address') as string}
            checked={orderDetails?.shipMatchBilling}
            onChange={(event: ChangeEvent) => {
              const val = event.target.checked;
              handleShipSameAsBilling({ ...orderDetails, shipMatchBilling: val });
            }}
          />
        </InputRow>
        <InputRow>
          <InputContainer>
            <Input
              variant={orderDetails?.shipMatchBilling ? 'disabled' : 'white'}
              placeholder={t('street_address', { ns: 'common' })}
              value={orderDetails?.shippingAddress?.streetAddress ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'shippingAddress', 'streetAddress');
              }}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer>
            <Input
              variant={orderDetails?.shipMatchBilling ? 'disabled' : 'white'}
              placeholder={t('app_suite_unit', { ns: 'common' })}
              value={orderDetails?.shippingAddress?.suiteNumber ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'shippingAddress', 'suiteNumber');
              }}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer>
            <Input
              variant={orderDetails?.shipMatchBilling ? 'disabled' : 'white'}
              placeholder={t('city', { ns: 'common' })}
              value={orderDetails?.shippingAddress?.city ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'shippingAddress', 'city');
              }}
            />
          </InputContainer>
          <InputContainer>
            <Input
              variant={orderDetails?.shipMatchBilling ? 'disabled' : 'white'}
              placeholder={t('state', { ns: 'common' })}
              value={orderDetails?.shippingAddress?.state ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'shippingAddress', 'state');
              }}
            />
          </InputContainer>
        </InputRow>

        <InputRow>
          <InputContainer>
            <Input
              variant={orderDetails?.shipMatchBilling ? 'disabled' : 'white'}
              placeholder={t('postal_code', { ns: 'common' })}
              value={orderDetails?.shippingAddress?.postalCode ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'shippingAddress', 'postalCode');
              }}
            />
          </InputContainer>
          <InputContainer>
            <Input
              variant={orderDetails?.shipMatchBilling ? 'disabled' : 'white'}
              placeholder={t('country', { ns: 'common' })}
              value={orderDetails?.shippingAddress?.country ?? ''}
              onChange={(event: ChangeEvent) => {
                const val = event.target.value as string;
                handleAddressChange(val, 'shippingAddress', 'country');
              }}
            />
          </InputContainer>
        </InputRow>
      </Container>
      <Container>
        <Typography mb={0} color={theme.colors.black} variant="subtitle">
          {t('shipment_method')}
        </Typography>
        <InputContainer>
          <Checkbox
            width={20}
            height={20}
            id={'allow_partial_shipments'}
            label={t('allow_partial_shipments') as string}
            checked={orderDetails?.allowPartialShipments}
            onChange={(event: ChangeEvent) => {
              const val = event.target.checked;
              handleDetailsUpdate({ ...orderDetails, allowPartialShipments: val });
            }}
            margin="0.5rem 0 2rem"
          />
        </InputContainer>
        <Typography mb={0} color={theme.colors.black} variant="subtitle">
          {t('created_by')}
        </Typography>
        <InputContainer>
          <Input
            variant="white"
            placeholder="creator@comp.com"
            value={orderDetails?.creator}
            onChange={(event: ChangeEvent) => {
              const creator = event.target.value as string;
              handleDetailsUpdate({ ...orderDetails, creator: creator.trimStart() });
            }}
          />
        </InputContainer>
      </Container>
      <Container>
        <Typography mb={0} color={theme.colors.black} variant="subtitle">
          {t('purchase_order_info')} ({t('required', { ns: 'common' })})
        </Typography>
        <InputContainer>
          <Input
            className="purchase_order-input"
            variant="white"
            placeholder={t('add_po_number')}
            value={orderDetails?.customerPoNumber}
            onChange={(event: ChangeEvent) => {
              const customerPo = event.target.value as string;
              if (event.target.value.length <= 20 && isAlphaNumeric(customerPo))
                handleDetailsUpdate({ ...orderDetails, customerPoNumber: customerPo.trimStart() });
            }}
          />
          <FileInputLable className="purchange_order-label" htmlFor="order_pdf">
            <IcoUploadFile />
            Upload PDF{' '}
            <Typography mb={0} color={theme.colors.text.lightBlack}>
              <i>(Size limit 10mb)</i>
            </Typography>
          </FileInputLable>
          <FileInput
            accept="application/pdf"
            multiple
            onChange={handleFileUpload}
            type="file"
            id="order_pdf"
          />
        </InputContainer>
        <ChipContainer files={fileList} handleRemove={handleFileRemove} />
      </Container>
      <Modal
        visible={showAddressModal}
        size={ModalSize.SMALL}
        title={t('saved_addresses') as string}
        onClose={() => setShowAddressModal(false)}
        allowContentScroll={true}
      >
        <PlantAutofillTable
          plants={plants}
          autoFillFunction={autofillAddress}
          showModalFunction={setShowAddressModal}
        />
      </Modal>
    </Root>
  );
};

export default CartFulfillmentDetails;
