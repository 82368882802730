import React from 'react';
import { ChartLegendContainer, ItemContainer } from './InlineChartLegend.elements';
import { useTranslation } from 'react-i18next';
import { ChartLegendProps } from '../ChartLegends.types';

export const InlineChartLegend = ({
  items,
  handleClick,
  className,
  isVertical,
  checkIfSelected,
  isLoading,
  format,
  groupKey,
  labelKey
}: ChartLegendProps): JSX.Element => {
  checkIfSelected = checkIfSelected ? checkIfSelected : () => true;

  labelKey = labelKey || 'label';

  const { t } = useTranslation(['mh']);

  if (isLoading || !items || items.length === 0) return <></>;

  // establesh base class name
  className = className ? `chart-legend ${className}` : `chart-legend`;
  // add class if vertical
  if (isVertical) className += ` is-vertical`;
  if (handleClick) className += ` has-buttons`;

  // the legend item component
  const Item = (props: Record<string, unknown>) => {
    // get label and color from props
    const color = props?.color as string;
    let val = props?.value as string;
    const group = groupKey && (props?.[groupKey] as string);
    const label = (props?.[labelKey] as string) || group;

    // return early if missing label
    if (!label) return <>error: missing label</>;

    let translatedLabel: string | undefined = undefined;
    if (typeof label === 'string') translatedLabel = t(label.toLowerCase() as string) as string;

    // establesh base class name
    let itemClassName = `chart-legend__item`;
    // check if this is selected, only if we have a function to check

    if (checkIfSelected?.(props)) itemClassName += ` is-selected`;
    else itemClassName += ` is-not-selected`;

    if (val === `0%`) val = `0-1%`;
    const lableValue = format?.legendItem?.(props) || (
      <>
        {translatedLabel}
        {val && <span className="value">({val})</span>}
      </>
    );

    //items?.sort((a, b) => Number(a.value) < Number(b.value) ? 1 : -1)

    return (
      <ItemContainer
        {...{
          color,
          className: itemClassName,
          onClick: () => handleClick?.(label)
        }}
      >
        <span className={val ? `label has-value` : `label`}>{lableValue}</span>
      </ItemContainer>
    );
  };

  return (
    <ChartLegendContainer {...{ className }}>
      {items.map((item, i) => (
        <Item {...item} key={i} />
      ))}
    </ChartLegendContainer>
  );
};
