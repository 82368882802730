import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

export const BarChartWidgetContainer = styled.div<StyledUiContainerProps>`
  padding: 1em 1em 2em 1.3em;
  min-height: 250px;
  position: relative;

  .grid-line--x {
    display: none;
  }
`;
