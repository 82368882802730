import React from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { Typography } from 'components';

const ICONS = {
  file: (
    <>
      <path
        d="M8.666 1.333H3.999a1.333 1.333 0 0 0-1.333 1.333v10.667a1.333 1.333 0 0 0 1.333 1.333h8a1.333 1.333 0 0 0 1.334-1.333V6L8.666 1.333Z"
        stroke="#303E47"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.666 1.333V6h4.667"
        stroke="#303E47"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  cart: (
    <path
      d="M6 14.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333ZM13.333 14.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333ZM.666.667h2.667l1.786 8.927a1.333 1.333 0 0 0 1.334 1.073h6.48a1.333 1.333 0 0 0 1.333-1.073L15.333 4H3.999"
      stroke="#0076CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  assigned: (
    <path
      d="M10.666 14v-1.333A2.667 2.667 0 0 0 7.999 10H3.333a2.667 2.667 0 0 0-2.667 2.667V14M5.667 7.333a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333ZM11.334 7.333l1.333 1.334L15.334 6"
      stroke="#303E47"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  unassigned: (
    <path
      d="M13.333 14v-1.333A2.667 2.667 0 0 0 10.666 10H5.333a2.667 2.667 0 0 0-2.667 2.667V14M8 7.333A2.667 2.667 0 1 0 8 2a2.667 2.667 0 0 0 0 5.333Z"
      stroke="#303E47"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  service: (
    <path
      d="M9.8 4.2a.667.667 0 0 0 0 .933L10.868 6.2a.667.667 0 0 0 .934 0l2.513-2.513A4 4 0 0 1 9.021 8.98l-4.607 4.607a1.414 1.414 0 0 1-2-2L7.021 6.98a4 4 0 0 1 5.293-5.293L9.808 4.193 9.8 4.2Z"
      stroke="#303E47"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  person: (
    <>
      <path
        d="M13.3333 14V12.6667C13.3333 11.9594 13.0524 11.2811 12.5523 10.781C12.0522 10.281 11.3739 10 10.6667 10H5.33333C4.62609 10 3.94781 10.281 3.44772 10.781C2.94762 11.2811 2.66667 11.9594 2.66667 12.6667V14"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7.33333C9.47276 7.33333 10.6667 6.13943 10.6667 4.66667C10.6667 3.19391 9.47276 2 8 2C6.52724 2 5.33333 3.19391 5.33333 4.66667C5.33333 6.13943 6.52724 7.33333 8 7.33333Z"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  wrench: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_757_3299)">
        <path
          d="M4.66673 6.66624H6.66673V4.66624L4.3334 2.33291C5.07982 1.97643 5.9184 1.86011 6.73366 1.99999C7.54893 2.13987 8.30078 2.52906 8.88568 3.11396C9.47059 3.69886 9.85977 4.45071 9.99965 5.26598C10.1395 6.08124 10.0232 6.91982 9.66673 7.66624L13.6667 11.6662C13.9319 11.9315 14.0809 12.2912 14.0809 12.6662C14.0809 13.0413 13.9319 13.401 13.6667 13.6662C13.4015 13.9315 13.0418 14.0805 12.6667 14.0805C12.2917 14.0805 11.9319 13.9315 11.6667 13.6662L7.66673 9.66624C6.92031 10.0227 6.08173 10.139 5.26647 9.99916C4.4512 9.85928 3.69935 9.4701 3.11445 8.8852C2.52954 8.30029 2.14036 7.54844 2.00048 6.73318C1.8606 5.91791 1.97691 5.07933 2.3334 4.33291L4.66673 6.66624Z"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_757_3299">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  plus: (
    <path
      d="M11.6667 1H2.33333C1.59695 1 1 1.59695 1 2.33333V11.6667C1 12.403 1.59695 13 2.33333 13H11.6667C12.403 13 13 12.403 13 11.6667V2.33333C13 1.59695 12.403 1 11.6667 1Z"
      stroke="#0076CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  alert: (
    <path
      d="M6.86 2.573 1.215 12a1.333 1.333 0 0 0 1.14 2h11.293a1.332 1.332 0 0 0 1.14-2L9.14 2.573a1.333 1.333 0 0 0-2.28 0v0ZM8 6v2.667M8 11.333h.007"
      stroke="#303E47"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  create: (
    <path
      d="M12.667 2H3.333C2.597 2 2 2.597 2 3.333v9.334C2 13.403 2.597 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.333V3.333C14 2.597 13.403 2 12.667 2ZM8 5.333v5.333M5.334 8h5.333"
      stroke="#0076CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  warn: (
    <>
      <path
        d="M2 8C2 8.78793 2.15519 9.56815 2.45672 10.2961C2.75825 11.0241 3.20021 11.6855 3.75736 12.2426C4.31451 12.7998 4.97595 13.2417 5.7039 13.5433C6.43185 13.8448 7.21207 14 8 14C8.78793 14 9.56815 13.8448 10.2961 13.5433C11.0241 13.2417 11.6855 12.7998 12.2426 12.2426C12.7998 11.6855 13.2417 11.0241 13.5433 10.2961C13.8448 9.56815 14 8.78793 14 8C14 7.21207 13.8448 6.43185 13.5433 5.7039C13.2417 4.97595 12.7998 4.31451 12.2426 3.75736C11.6855 3.20021 11.0241 2.75825 10.2961 2.45672C9.56815 2.15519 8.78793 2 8 2C7.21207 2 6.43185 2.15519 5.7039 2.45672C4.97595 2.75825 4.31451 3.20021 3.75736 3.75736C3.20021 4.31451 2.75825 4.97595 2.45672 5.7039C2.15519 6.43185 2 7.21207 2 8Z"
        stroke="#303E47"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.80005 3.7998L12.2 12.1998"
        stroke="#303E47"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  check: (
    <path
      d="M5 7L6.33333 8.33333L9 5.66667M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
      stroke="#0076CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  )
};

export type IconType = keyof typeof ICONS;

interface Props {
  icon: IconType;
  text?: string;
  subText?: React.ReactNode;
  showArrow?: boolean;
  onClick?: () => void;
}

const Root = styled.div<{ onClick?: () => void }>`
  display: flex;
  ${({ onClick }) => onClick && 'cursor: pointer;'}
`;

const IconContainer = styled.div`
  width: 16px;
  margin-right: 0.5rem;
  padding-top: 0.1rem;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`;

/**
 * Dumb component for displaying a cell with icon, some text, and optional chevron
 */
const IconCell = ({ icon, text, subText, showArrow, onClick }: Props): JSX.Element => {
  const theme = useTheme();
  const color = text === 'None Required' ? theme.colors.darkGrey : theme.colors.primaryBlue5;
  return (
    <Root onClick={onClick}>
      <IconContainer>
        <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
          {ICONS[icon]}
        </svg>
      </IconContainer>
      <ContentContainer>
        {text && (
          <Typography color={color} mb={0}>
            {text}
          </Typography>
        )}
        {subText && subText}
      </ContentContainer>
      {showArrow && (
        <ArrowContainer>
          <FontAwesomeIcon icon={faChevronRight} color={theme.colors.darkGrey} />
        </ArrowContainer>
      )}
    </Root>
  );
};

export default IconCell;
