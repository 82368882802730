import { Button, Typography } from 'components';
import React from 'react';
import theme from 'themes';
import socketImage from 'img/socketImage.png';
import { Container, ImageContainer, ItemCenter, MainText, SubWarnText } from './ErrorPage.elements';

const ErrorPage = (): JSX.Element => {
  const handleBack = () => {
    history.back();
    setTimeout(() => location.reload(), 10);
  };
  return (
    <Container>
      <Typography font="Roboto" size={'64px'}>
        <MainText>Mmm, something went wrong!</MainText>
      </Typography>
      <Typography font="Roboto" mb={'2rem'} size={'32px'}>
        <SubWarnText>
          Something went wrong while loading the page, it might be some technical error please
          reload or go back.
        </SubWarnText>
      </Typography>
      <ItemCenter>
        <Button
          width="8rem"
          bgColor={theme.colors.primaryBlue5}
          color={theme.colors.lightGrey3}
          borderRadius="3rem"
          variant="hover-blue"
          onClick={handleBack}
        >
          Go Back
        </Button>
      </ItemCenter>
      <ImageContainer src={socketImage}></ImageContainer>
    </Container>
  );
};

export default ErrorPage;
