import React from 'react';

export const SettingIcon = ({ color }: { color?: string }): JSX.Element => {
  color = color || '#0076CC';
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.88309 1.87821C6.16735 0.707264 7.83265 0.707265 8.11692 1.87821C8.30055 2.63462 9.16716 2.99358 9.83188 2.58857C10.8609 1.96159 12.0384 3.13914 11.4114 4.16812C11.0064 4.83284 11.3654 5.69945 12.1218 5.88308C13.2927 6.16735 13.2927 7.83265 12.1218 8.11692C11.3654 8.30055 11.0064 9.16716 11.4114 9.83188C12.0384 10.8609 10.8609 12.0384 9.83188 11.4114C9.16717 11.0064 8.30055 11.3654 8.11692 12.1218C7.83265 13.2927 6.16735 13.2927 5.88309 12.1218C5.69945 11.3654 4.83284 11.0064 4.16812 11.4114C3.13914 12.0384 1.96159 10.8609 2.58857 9.83188C2.99358 9.16716 2.63462 8.30055 1.87821 8.11692C0.707265 7.83265 0.707264 6.16735 1.87821 5.88308C2.63462 5.69945 2.99358 4.83284 2.58857 4.16812C1.96159 3.13914 3.13914 1.96159 4.16812 2.58857C4.83284 2.99358 5.69945 2.63462 5.88309 1.87821Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 7C9 8.10457 8.10457 9 7 9C5.89543 9 5 8.10457 5 7C5 5.89543 5.89543 5 7 5C8.10457 5 9 5.89543 9 7Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
