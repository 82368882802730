import { useSelector } from 'react-redux';
import { tanStackTableFilterOption, tanStackTableMultiFilterOption } from 'reducers/tanStackTable';
// Reducers
import { RootState } from 'reducers';
const useTanStackFilterOptions = ():
  | null
  | tanStackTableFilterOption[]
  | tanStackTableMultiFilterOption[] =>
  useSelector<RootState, null | tanStackTableFilterOption[] | tanStackTableMultiFilterOption[]>(
    (state) => state?.tanStackTableFilterOptions ?? null
  );
export default useTanStackFilterOptions;
