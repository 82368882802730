import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || '18px',
  height: width ? 'auto' : '20px',
  fill: 'none'
}))``;

export const IcoUser = ({ width, color }: { width?: string; color?: string }): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.31055 6.45381C5.31055 7.64563 5.784 8.78865 6.62675 9.63139C7.46949 10.4741 8.6125 10.9476 9.80433 10.9476C10.9962 10.9476 12.1392 10.4741 12.9819 9.63139C13.8247 8.78865 14.2981 7.64563 14.2981 6.45381C14.2981 5.26198 13.8247 4.11897 12.9819 3.27622C12.1392 2.43347 10.9962 1.96002 9.80433 1.96002C8.6125 1.96002 7.46949 2.43347 6.62675 3.27622C5.784 4.11897 5.31055 5.26198 5.31055 6.45381Z"
        stroke={color || '#0076CC'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.06348 22.1821V19.9352C3.06348 18.7434 3.53693 17.6004 4.37967 16.7576C5.22242 15.9149 6.36543 15.4414 7.55726 15.4414H10.3659"
        stroke={color || '#0076CC'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 17.4445V19.0001L18.1667 20.1667M20.5 19.0001C20.5 20.9331 18.933 22.5001 17 22.5001C15.067 22.5001 13.5 20.9331 13.5 19.0001C13.5 17.0671 15.067 15.5001 17 15.5001C18.933 15.5001 20.5 17.0671 20.5 19.0001Z"
        stroke={color || '#0076CC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
