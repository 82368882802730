import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FollowUpDetail, Typography } from 'components';
import React from 'react';
import styled from 'styled-components';
import theme from 'themes';
import { Machine } from 'types';
import { MaintenanceEvent } from 'types/maintenance';
interface ServiceProps {
  title: React.ReactNode;
  followUpPM: MaintenanceEvent | null;
  maintenanceEvent?: MaintenanceEvent;
  machines?: Machine[];
  machineId?: string;
  handleClose: () => void;
  submitClickHandler: () => void;
  showCreateServiceModal?: boolean;
}

const Container = styled.div`
  padding: 1rem;
`;
const SubHeader = styled.div`
  margin: 0.8rem 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey9};
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CreateServiceForm = ({
  title,
  followUpPM,
  handleClose,
  submitClickHandler,
  showCreateServiceModal,
  machineId,
  machines
}: ServiceProps): JSX.Element => {
  return (
    <Container>
      <Header>
        {title}
        <FontAwesomeIcon
          onClick={handleClose}
          cursor={'pointer'}
          size={'lg'}
          icon={faTimes}
          color={theme.colors.darkGrey}
        />
      </Header>
      {!followUpPM && (
        <SubHeader>
          <Typography size={'1rem'} weight="semi-bold" mb={0}>
            Create a new service for Service Tech team
          </Typography>
        </SubHeader>
      )}
      <FollowUpDetail
        maintenanceEvent={followUpPM as MaintenanceEvent}
        onClose={handleClose}
        onSubmitClick={submitClickHandler}
        machines={showCreateServiceModal ? machines : []}
        machineId={machineId}
        newService={showCreateServiceModal ? true : false}
      />
    </Container>
  );
};

export default CreateServiceForm;
