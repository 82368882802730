import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  > div {
    position: relative;
  }
`;

export const SubContainer = styled.div``;

export const ImageSubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47%;
  img {
    max-height: 17rem;
    max-width: 100%;
    padding: 0rem;
  }
  @media (max-width: 2200px) {
    img {
      min-width: 100%;
    }
  }
`;

export const ActiveRecipeContainer = styled.div`
  margin: 0.5rem 0;
  position: absolute;
  top: 0;
  left: -1.1rem;
`;

export const NewKpiContainer = styled.div`
  height: 15.1875rem;
  overflow-y: auto;
  padding-right: 1.2rem;
  & > div {
    padding: 0rem 1rem;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 1.3rem;
  justify-content: space-between;
  padding: 0rem 2.2rem 0 1rem;
`;
