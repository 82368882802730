// 3rd party libs
import { useGetContractsDetailsQuery } from 'api';
import { DataRenderer } from 'components/machine-health';
import { formatDate } from 'helpers';
import { useTimeZone } from 'providers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { themeColors, colors } from 'themes';
import { ProteinMachineRouteQueryParams } from 'types/protein';

type Data = { [key in string]: string | string[] };
type Props = {
  data: Data;
};

const SubTitleSidebar = styled.div`
  display: block;
`;
const CardData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.625rem;
  width: 100%;
  margin: 0px auto;
  padding: 0 0rem 0.9rem;
  .cstm_list_wrp {
    display: flex;
    align-items: center;
    .svg_code_ttl {
      img {
        width: 0.938rem;
      }
      display: flex;
      gap: 0.375rem;
      min-width: 11.25rem;
      span {
        font-weight: 600;
        color: ${themeColors.lightGrey8};
        font-size: 0.875rem;
      }
    }
    .ttl_values {
      color: ${colors.text.lightBlack};
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.026rem;
    }
  }
  .line_gap {
    background: ${themeColors.lightGrey3};
    height: 0.063rem;
  }
`;
const ContractData = styled.div`
  margin:  0.625rem 0rem 1.5625rem;
  padding: 0.625rem 0.438rem;
  border: 1px solid ${themeColors.tableborder};
  border-radius: 0.625rem;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      tr {
        th {
          border-bottom: 0.063rem solid ${themeColors.tableborder};
          border-left: border-left: 0px !important;;
          padding: 0.688rem 0.375rem;
          text-align: left;
          font-size: 0.875rem;
          color: ${themeColors.lightGrey8};
          font-weight: 600;
          line-height: 1.026rem;
          &:first-child {
            border-left: 0rem;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 0.063rem solid ${themeColors.tableborder};
          padding: 0.75rem 0.188rem 1.375rem 0.875rem;
          text-align: left;
          font-family: Roboto;
          font-style: normal;
          font-size: 0.75rem;
          color: ${colors.text.lightBlack};
          font-weight: 400;
          line-height: 1rem;
          position: relative;                 
        }
        .customcss {         
            border-bottom: 0.063rem solid ${themeColors.tableborder};
            padding: 0.75rem 0.188rem 1.375rem 0.875rem;
            text-align: left;
            color:  ${themeColors.primaryBlue5};
            line-height: 1rem;
            position: relative;
            &:before {
              content: '';
              width: 0.188rem;
              height: 0.188rem;
              background:  ${themeColors.primaryBlue5};;
              border-radius: 50%;
              position: absolute;
              left: 0.25rem;
              top: 1.063rem;                        
          }
        }
        &:last-child {
          td {
            border-bottom: 0rem;
            padding-top: 0rem;
            padding-bottom: 0rem;          
          }
        }
      }
    }
  }
`;

const Summary = ({ data }: Props): JSX.Element => {
  const { timeZone } = useTimeZone();
  const { t } = useTranslation(['mh', 'common']);
  const { machineId } = useParams<ProteinMachineRouteQueryParams>();
  const { data: contractData, isLoading, error } = useGetContractsDetailsQuery({ machineId });
  return (
    <>
      <SubTitleSidebar>
        <CardData>
          <div className="cstm_list_wrp">
            <div className="svg_code_ttl">
              <img src="../../../../assets/imgs/icons/customer_name_icon.svg" alt="" />
              <span>Customer Name:</span>
            </div>
            <div className="ttl_values">{data['Customer Name']}</div>
          </div>
          <div className="line_gap"></div>
          <div className="cstm_list_wrp">
            <div className="svg_code_ttl">
              <img src="../../../../assets/imgs/icons/serial_number_icon.svg" alt="" />
              <span>Serial Number:</span>
            </div>
            <div className="ttl_values">{Math.floor(Number(data['Salesforce Serial Number']))}</div>
          </div>
          <div className="line_gap"></div>
          <div className="cstm_list_wrp">
            <div className="svg_code_ttl">
              <img src="../../../../assets/imgs/icons/equipment_type_icon.svg" alt="" />
              <span>Equipment Type:</span>
            </div>
            <div className="ttl_values">{data['Equipment Type']}</div>
          </div>
          <div className="line_gap"></div>
        </CardData>
        <ContractData>
          <table>
            <thead>
              <tr>
                <th>Contract Type</th>
                <th>Contract Name</th>
                <th>Contract End Date</th>
              </tr>
            </thead>
            <DataRenderer
              isLoading={isLoading}
              error={error && (t('failed_to_load_data', { ns: 'common' }) as string)}
            >
              <tbody>
                {contractData?.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td className="customcss">{data.recordType}</td>
                      <td>{data.contractName}</td>
                      <td>{formatDate(data.endDate, 'short', timeZone)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </DataRenderer>
          </table>
        </ContractData>
      </SubTitleSidebar>
    </>
  );
};
export default Summary;
