import React from 'react';

export const DeleteIcon = (): JSX.Element => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6667 3.66667L10.0885 11.7617C10.0387 12.4594 9.45811 13 8.75859 13H3.24157C2.54205 13 1.96147 12.4594 1.91163 11.7617L1.33341 3.66667M4.66675 6.33333V10.3333M7.33341 6.33333V10.3333M8.00008 3.66667V1.66667C8.00008 1.29848 7.7016 1 7.33341 1H4.66675C4.29856 1 4.00008 1.29848 4.00008 1.66667V3.66667M0.666748 3.66667H11.3334"
        stroke="#B62C10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
