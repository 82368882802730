import { styledTheme } from 'components';
import React from 'react';

export interface Props {
  color?: string;
}

export const IcoExternal = ({ color }: Props): JSX.Element => {
  color = color || styledTheme.color.main;

  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.83331 2.50001H2.49998C1.85565 2.50001 1.33331 3.02234 1.33331 3.66668V9.50001C1.33331 10.1443 1.85565 10.6667 2.49998 10.6667H8.33331C8.97764 10.6667 9.49998 10.1443 9.49998 9.50001V7.16668M7.16665 1.33334H10.6666M10.6666 1.33334V4.83334M10.6666 1.33334L4.83331 7.16668"
        stroke={color}
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
