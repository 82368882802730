import React from 'react';
import { differenceInMinutes } from 'date-fns';
import { MachineStateStatusIcon } from './MachineState';
import { MachineStateContainer } from './Status.elements';
import { StateProtein, mappedStateDSI, mappedStateProtein } from './utils/BUsMachineStatusMapping';
import { BusinessUnit } from 'types/dsi';
import { useTranslation } from 'react-i18next';

interface MachineStateProps {
  machineState?: string;
  isLoading?: boolean;
  businessUnit?: string;
  isDisconnected?: boolean;
  lastConnected?: string;
}

export const MachineState = ({
  machineState,
  isLoading,
  businessUnit,
  isDisconnected,
  lastConnected
}: MachineStateProps): JSX.Element => {
  if (isLoading) return <></>;

  const baseClass = 'state';

  const lastConnectedTime = lastConnected
    ? differenceInMinutes(new Date(), new Date(lastConnected))
    : null;

  if (!businessUnit) return <></>;

  //if true then change status to disconnected
  if (isDisconnected) machineState = 'Disconnected';

  // This logic depend on businessUnit because business units have different backend support
  // when it comes to machine statuses
  const { t } = useTranslation(['mh']);
  const mappedProteinState: StateProtein = mappedStateProtein(t);

  let mappedState;
  if (businessUnit === BusinessUnit.DSI) {
    mappedState = machineState && mappedStateDSI[machineState];
  } else if (businessUnit === BusinessUnit.PROTEIN) {
    mappedState = machineState && mappedProteinState[machineState];
  } else {
    //right now we have machine status for Protein and DSI only
    return <></>;
  }

  const state =
    machineState && mappedState && lastConnectedTime !== null && lastConnectedTime < 6
      ? mappedState
      : 'Unknown';

  return (
    <MachineStateContainer>
      Machine State:
      <span className={baseClass + ` ${state.toLowerCase()}`}>{state}</span>
      <MachineStateStatusIcon state={state} />
    </MachineStateContainer>
  );
};
