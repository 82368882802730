import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width, height }) => ({
  width: width || `100%`,
  height: height || `auto`,
  fill: 'none'
}))``;

export const IcoExclamationCircleHollow = ({
  width = '15',
  height = '14',
  color = '#B62C10'
}: {
  width?: string;
  height?: string;
  color?: string;
}): JSX.Element => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Download">
        <path
          d="M7.25 4.66667V7M7.25 9.33333H7.25583M12.5 7C12.5 9.8995 10.1495 12.25 7.25 12.25C4.35051 12.25 2 9.8995 2 7C2 4.10051 4.35051 1.75 7.25 1.75C10.1495 1.75 12.5 4.10051 12.5 7Z"
          stroke={color}
          strokeWidth="1.05"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  );
};
