import React from 'react';
import { UiWidget as Ui } from '../';

interface itemProp {
  title?: string;
  onClick?: () => void;
  icon?: JSX.Element;
}

interface dropdownProps {
  showMenu?: boolean;
  setShowMenuOptions?: (data: boolean) => void;
  setIsEditAdminPopupOpen?: (data: boolean) => void;
  handleToggleCalendar?: () => void;
  dateRange?: string;
  customDropdownItems?: itemProp[];
}

const WidgetCustomDropdown = ({
  showMenu,
  setShowMenuOptions,
  customDropdownItems
}: dropdownProps): JSX.Element => {
  return (
    <>
      {showMenu && (
        <div>
          <Ui.MenuContainer>
            {customDropdownItems &&
              customDropdownItems.map((data) => {
                return (
                  <Ui.MenuItem
                    onClick={() => {
                      data.onClick && data.onClick(),
                        setShowMenuOptions && setShowMenuOptions(!showMenu);
                    }}
                  >
                    <Ui.MenuItemIcon>{data.icon}</Ui.MenuItemIcon>
                    <Ui.MenuItemText>{data.title}</Ui.MenuItemText>
                  </Ui.MenuItem>
                );
              })}
          </Ui.MenuContainer>
        </div>
      )}
    </>
  );
};

export default WidgetCustomDropdown;
