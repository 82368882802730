import { Button } from 'components';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
`;

export const ActionsButton = styled(Button)`
  border: none;
  box-shadow: none;
  border-radius: 0.5rem;
  padding: 0.25rem 0.375rem;

  svg {
    margin-left: 0;
  }
`;

export const MasterTagListTableWrapper = styled.div`
  width: 100%;

  tr td {
    &:first-child {
      width: 25%;
      word-break: break-all;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 8%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5) {
      width: 25%;
    }
    &:nth-child(6) {
      width: 20%;
    }

    &:nth-child(7) {
      width: 10%;
    }
    &:nth-child(8) {
      width: 10%;
    }

    &:nth-child(9) {
      width: 10%;
      span {
        margin-left: 1rem;
      }
    }
  }

  table {
    th {
      & > div,
      .select-none {
        margin: 0;
      }
    }
  }

  td {
    text-align: left;
  }

  tr td span {
    margin: 0;
  }

  td,
  th {
    padding: 0.6em 1em 0.6em 0;
  }

  thead th {
    padding: 0.5rem 0.625rem;
  }

  .master-tag-list {
    div:nth-child(2) {
      max-height: calc(100vh - 21rem) !important;
    }
  }
`;
