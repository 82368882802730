import React, { useMemo, ReactNode } from 'react';
import { BarChartProps, BarChartBarProps } from '../BarChart.types';
import { TickMark } from 'common/ui';

export interface DivBarChartAxisProps extends BarChartProps {
  axisItems?: BarChartBarProps[];
  marginLeft?: number;
  formatter?: (tick?: string | number) => ReactNode;
}

export const DivBarChartAxis = ({
  axisItems,
  marginLeft,
  formatter
}: DivBarChartAxisProps): JSX.Element => {
  const Axis = useMemo(() => {
    if (axisItems)
      return axisItems.map((item, i) => {
        if (!marginLeft) {
          return (
            <TickMark
              key={i}
              {...{
                className: 'tick tick--bottom',
                x: item?.x as number,
                y: item?.y as number,
                width: item?.width as number
              }}
            >
              <div className="tick__inner">
                {formatter ? formatter(item.label) : item.label || ''}
              </div>
            </TickMark>
          );
        }
        return (
          <TickMark
            key={i}
            {...{
              className: 'tick tick--left',
              x: item?.x as number,
              y: item?.y as number,
              width: marginLeft as number
            }}
          >
            <div className="tick__inner">
              {formatter ? formatter(`${item.label}`) : item.label || ''}
            </div>
          </TickMark>
        );
      });
    return <></>;
  }, [axisItems]);

  return <>{Axis}</>;
};
