import React from 'react';
import styled from 'styled-components';
import { colors } from 'themes';

const AsteriskWrapper = styled.sup`
  color: ${colors.text.error};
  font-size: 16px;
  top: 0;
`;

const Asterisk = (): JSX.Element => {
  return <AsteriskWrapper>*</AsteriskWrapper>;
};

export default Asterisk;
