// 3rd party libs
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Components
import { DashboardWidget, WidgetUi, useDateRange } from 'components';
import { IssuesTable } from 'components/machine-health';

// hooks
// import useMachineActiveIssues from 'hooks/useMachineActiveIssues';

// Providers
import { useTimeZone } from 'providers/timeZoneProvider';

// API
import {
  useGetAlertsByMachineIdQuery,
  useGetMachineAlarmsQuery,
  useGetMachineDataScienceAlertsQuery,
  useGetThresholdAlertsQuery
} from 'api';

// Types
import { ProteinMachineRouteQueryParams } from 'types/protein';
import { Alarm } from 'types/machine-health/alarms';
import { Alert, AlertAcknowledgeStatus, AlertStatus } from 'types/machine-health/alerts';
import { MachineType } from 'types/machine-health';
import { toISO8601 } from 'helpers';
import { ActiveIssues } from '../IssuesTable';
import { FrameIcon } from 'icons/FrameIcon';
import { RightArrowIcon } from 'icons/RightArrowIcon';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { usePermissions } from 'hooks';

const MIN_SCROLL_HEIGHT = 175;

type Props = {
  machineType?: MachineType.Aseptic | MachineType.DSI | undefined;
  linksToPath?: string;
  onClick?: () => void;
  scrollHeight?: number;
  height?: string;
  title?: string;
  tooltipContent?: string;
  alarmsDataIn?: Alarm[];
  alertsDataIn?: Alert[];
  hideArrow?: boolean;
  ga?: string;
  location?: string;
};

const StyledIssuesTable = styled.div`
  max-height: 19.563rem;
  .rc-table {
    border-radius: 0 !important;
    border-width: 0;
    overflow: hidden;
    tbody {
      height: 100%;
    }
  }
`;

const NoDataText = styled.div`
  font-weight: 500;
  color: rgb(102, 102, 102);
  font-size: 1rem;
  left: 47%;
  position: absolute;
  top: 50%;
`;
interface MachineAlertsOnlyWidgetProps {
  machineId: string;
  gridArea?: string;
}

/* please keep for reference:
alert data return obj: {
  id: string;
  internalId: string;
  description: string;
  alertType: AlertType ('operations' || 'maintenance');
  createdAt: string;
  status: AlertStatus ('completed' || 'no_completed);
}*/

export const MachineAlertsOnlyWidget = ({
  machineId,
  gridArea
}: MachineAlertsOnlyWidgetProps): JSX.Element => {
  const { t } = useTranslation(['mh']);

  /* const { data, isLoading, error } = useGetMachineDataScienceAlertsQuery(
    {
      machineId,
      status: AlertStatus.NotComplete
    },
    { pollingInterval: 30000 }
  ) */

  const {
    data: machineAlerts,
    error: machineAlertsError,
    isLoading: machineAlertsLoading
  } = useGetAlertsByMachineIdQuery({
    machineId,
    active: true
  });

  const widgetSettings = {
    gridArea,
    title: t('alerts') as string,
    isLoading: machineAlertsLoading ? true : false,
    hasError: machineAlertsError && (t('failed_to_alerts_data') as string),
    hasMessage:
      !machineAlertsLoading && !machineAlertsError && !machineAlerts?.length
        ? `No ${t('alerts')} to Report`
        : undefined,
    Main: (
      <StyledIssuesTable className="widget-ui-main no-padding">
        <IssuesTable
          alarmsData={[]} //!alarmsDataIn ? (isLoading || !alarmsData ? [] : alarmsData) : alarmsDataIn}
          alertsData={[]} //!alertsDataIn ? (isLoading || !alertsData ? [] : alertsData) : alertsDataIn}
          machineAlerts={machineAlerts}
          //timeZone={timeZone}
          scrollHeight={125}
          compact={true}
        />
      </StyledIssuesTable>
    )
  };

  return <WidgetUi {...widgetSettings} />;
};

const MachineActiveIssues = ({ linksToPath, machineType, ga, ...rest }: Props): JSX.Element => {
  if (ga === 'demo') console.log(ga, 'not used');

  const { timeZone } = useTimeZone();
  const history = useHistory();
  const { machineId } = useParams<ProteinMachineRouteQueryParams>();
  const { t } = useTranslation(['mh']);

  const { dateRange } = useDateRange();

  const {
    data: alarmsData,
    isLoading: alarmsLoading,
    error: alarmsError
  } = useGetMachineAlarmsQuery({
    machineId,
    startDatetime: `${toISO8601(dateRange.startTime)}`,
    endDatetime: `${toISO8601(dateRange.endTime)}`
  });

  const filteredAlarmsData = alarmsData?.filter((alarmData) => !alarmData.endTimestamp);

  const {
    data: machineAlerts,
    error: machineAlertsError,
    isLoading: machineAlertsLoading
  } = useGetAlertsByMachineIdQuery({
    machineId,
    active: true
  });

  //hiding for now from inside active widgets
  // const {
  //   data: machineThresholdAlerts,
  //   error: machineThresholdAlertsError,
  //   isLoading: machineThresholdAlertsLoading
  // } = useGetThresholdAlertsQuery({
  //   machineId,
  //   limit: 50
  // });

  const {
    data: alertsData,
    isLoading: alertsLoading,
    error: alertsError
  } = useGetMachineDataScienceAlertsQuery({
    machineId,
    startDatetime: `${toISO8601(dateRange.startTime)}`,
    endDatetime: `${toISO8601(dateRange.endTime)}`
  });

  const isLoading = alarmsLoading || alertsLoading || machineAlertsLoading;

  // On a first render we show load icon, but hide when page stays open and keeps receiving new data
  // const isFirstRender = useRef(true);

  // TODO: Remove the check for DSI once DSI has alarms, just removing the error msg for DSI as requested temporarily*/
  const isDSI = machineType === MachineType.DSI;

  const hasError =
    (alarmsError || machineAlertsError) && !isDSI
      ? (t('failed_to_alarms_data') as string)
      : alertsError && (t('failed_to_alerts_data') as string);

  let hasMessage: string | undefined = undefined;

  if (!isLoading) {
    if (
      filteredAlarmsData?.length === 0 &&
      alertsData?.length === 0 &&
      machineAlerts?.length === 0
    ) {
      hasMessage = `No ${t('active_issues')} to Report`;
    }
  }

  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false;
  //   }
  // }, [isLoading, machineAlerts, alertsData, filteredAlarmsData, hasError]);

  const widgetSettings = {
    title: t('active_issues') as string,
    isLoading,
    //isLoading: isFirstRender.current ? (isLoading ? true : false) : false,
    hasError,
    IconRight: !hasMessage
      ? {
          Icon: <RightArrowIcon />,
          handleClick: () => linksToPath && history.push(linksToPath),
          tooltip: `view ${t('active_issues')}`,
          label: `view ${t('active_issues')}`
        }
      : undefined,
    hasMessage,
    Main: (
      <StyledIssuesTable className="widget-ui-main no-padding">
        <IssuesTable
          scrollHeight={MIN_SCROLL_HEIGHT}
          alarmsData={isLoading ? [] : filteredAlarmsData}
          alertsData={isLoading ? [] : alertsData}
          machineAlerts={isLoading ? [] : machineAlerts}
          alertsTriggers={[]}
          isLoading={isLoading}
          timeZone={timeZone}
        />
      </StyledIssuesTable>
    ),
    ...rest
  };
  return <WidgetUi {...widgetSettings} />;
};

// New Active Issues Widget Component
export const NewMachineActiveIssues = ({ machineType, ga, location }: Props): JSX.Element => {
  if (ga === 'demo') console.log(ga, 'not used');

  const { timeZone } = useTimeZone();
  const { machineId } = useParams<ProteinMachineRouteQueryParams>();
  const { t } = useTranslation(['mh']);
  const { scopes } = usePermissions();

  const userHaveAlertsPermission = useMemo(() => {
    const alertScope = scopes.find((scope) => {
      return scope.name === 'Alert Management';
    });
    return alertScope?.read || false;
  }, [scopes]);

  const [filterList, setFilterList] = useState({
    filterAlert: true,
    filterAlarm: true,
    filterMachineAlert: true
  });

  const { dateRange } = useDateRange();

  const {
    data: alarmsData,
    isLoading: alarmsLoading,
    error: alarmsError
  } = useGetMachineAlarmsQuery({
    machineId,
    startDatetime: `${toISO8601(dateRange.startTime)}`,
    endDatetime: `${toISO8601(dateRange.endTime)}`
  });

  const filteredAlarmsData = alarmsData?.filter((alarmData) => !alarmData.endTimestamp);

  const {
    data: machineAlerts,
    error: machineAlertsError,
    isLoading: machineAlertsLoading
  } = useGetAlertsByMachineIdQuery({
    machineId,
    active: true
  });

  const ApiEndPoints = useMemo(() => {
    return {
      apiStatus: AlertStatus.ACTIVE,
      secondApiStatus: AlertStatus.INACTIVE,
      acknowledgeStatus: undefined,
      secondAcknowledgeStatus: AlertAcknowledgeStatus.NOT_ACKNOWLEDGED,
      needsAcknowledge: undefined,
      secondNeedsAcknowledge: 'true'
    };
  }, []);

  //hiding for now from inside active widgets
  const {
    data: machineThresholdAlerts,
    error: machineThresholdAlertsError,
    isLoading: machineThresholdAlertsLoading
  } = useGetThresholdAlertsQuery(
    userHaveAlertsPermission && location && process.env.REACT_APP_ENABLE_ALERTS_TAB === 'true'
      ? {
          machineId,
          widgetId: location,
          status: ApiEndPoints.apiStatus,
          acknowledgmentStatus: ApiEndPoints.acknowledgeStatus,
          needsAcknowledgment: ApiEndPoints.needsAcknowledge
        }
      : skipToken
  );

  const {
    data: secondaryApi,
    error: secondaryApiError,
    isLoading: secondaryApiLoading
  } = useGetThresholdAlertsQuery(
    userHaveAlertsPermission && location && process.env.REACT_APP_ENABLE_ALERTS_TAB === 'true'
      ? {
          machineId: machineId,
          widgetId: location,
          status: ApiEndPoints.secondApiStatus,
          acknowledgmentStatus: ApiEndPoints.secondAcknowledgeStatus,
          needsAcknowledgment: ApiEndPoints.secondNeedsAcknowledge
        }
      : skipToken
  );

  const sortedByActiveAndStartTime = useMemo(() => {
    if (machineThresholdAlerts && secondaryApi) {
      const mergedData = [...machineThresholdAlerts, ...secondaryApi];
      return mergedData;
    }
  }, [machineThresholdAlerts, secondaryApi]);

  const {
    data: alertsData,
    isLoading: alertsLoading,
    error: alertsError
  } = useGetMachineDataScienceAlertsQuery({
    machineId,
    startDatetime: `${toISO8601(dateRange.startTime)}`,
    endDatetime: `${toISO8601(dateRange.endTime)}`
  });

  const thresholAlertLoading =
    secondaryApiLoading || machineThresholdAlertsLoading || location?.length === 0;

  const isLoading = alarmsLoading || alertsLoading || machineAlertsLoading || thresholAlertLoading;

  // On a first render we show load icon, but hide when page stays open and keeps receiving new data
  const isFirstRender = useRef(true);

  // TODO: Remove the check for DSI once DSI has alarms, just removing the error msg for DSI as requested temporarily*/
  const isDSI = machineType === MachineType.DSI;

  const hasError =
    (alarmsError || machineAlertsError) && !isDSI
      ? (t('failed_to_alarms_data') as string)
      : alertsError ||
        secondaryApiError ||
        (machineThresholdAlertsError && (t('failed_to_alerts_data') as string));

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [isLoading, machineAlerts, alertsData, filteredAlarmsData, hasError]);

  const activeIssuesWidgetSettings = {
    title: t('active_issues'),
    isLoading: isLoading,
    hasError:
      alarmsError &&
      machineAlertsError &&
      alertsError &&
      secondaryApiError &&
      machineThresholdAlertsError &&
      t('Failed to load Active Issues'),
    hasCustomMenu: true,
    noPad: true,
    customDropdownItems: [
      {
        title: t('view_alarms'),
        onClick: () =>
          setFilterList({
            filterAlert: false,
            filterAlarm: true,
            filterMachineAlert: false
          }),
        icon: <FrameIcon />
      },
      {
        title: t('view_alerts'),
        onClick: () =>
          setFilterList({
            filterAlert: true,
            filterAlarm: false,
            filterMachineAlert: false
          }),
        icon: <FrameIcon />
      },
      {
        title: t('see_all'),
        onClick: () =>
          setFilterList({
            filterAlert: true,
            filterAlarm: true,
            filterMachineAlert: true
          }),
        icon: <FrameIcon />
      }
    ],
    itemCount:
      (filterList.filterAlert && sortedByActiveAndStartTime?.length
        ? sortedByActiveAndStartTime?.length
        : 0) +
      (filterList.filterAlarm && filteredAlarmsData?.length ? filteredAlarmsData?.length : 0) +
      (filterList.filterAlert && alertsData?.length ? alertsData?.length : 0) +
      (filterList.filterMachineAlert && machineAlerts?.length ? machineAlerts?.length : 0)
  };

  return (
    <DashboardWidget {...activeIssuesWidgetSettings}>
      <StyledIssuesTable className="widget-ui-main no-padding">
        {false && (
          <IssuesTable
            scrollHeight={MIN_SCROLL_HEIGHT}
            alarmsData={isLoading ? [] : filteredAlarmsData}
            alertsData={isLoading ? [] : alertsData}
            machineAlerts={isLoading ? [] : machineAlerts}
            alertsTriggers={[]}
            isLoading={isLoading}
            timeZone={timeZone}
          />
        )}
        {activeIssuesWidgetSettings?.itemCount === 0 ? (
          <NoDataText>No Active Issues</NoDataText>
        ) : (
          <ActiveIssues
            scrollHeight={MIN_SCROLL_HEIGHT}
            alarmsData={isLoading || !filterList.filterAlarm ? [] : filteredAlarmsData}
            alertsData={isLoading || !filterList.filterAlert ? [] : alertsData}
            machineAlerts={isLoading || !filterList.filterMachineAlert ? [] : machineAlerts}
            alertsTriggers={isLoading || !filterList.filterAlert ? [] : sortedByActiveAndStartTime}
            isLoading={isLoading}
            timeZone={timeZone}
          />
        )}
      </StyledIssuesTable>
    </DashboardWidget>
  );
};

export default MachineActiveIssues;
