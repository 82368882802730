import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoUploadFile = ({
  width = '24',
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
  padding?: string;
}): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5778_167009)">
        <path
          d="M4.66664 12C3.83482 12 3.03708 11.684 2.44889 11.1214C1.86071 10.5588 1.53027 9.7957 1.53027 9.00005C1.53027 8.2044 1.86071 7.44134 2.44889 6.87873C3.03708 6.31612 3.83482 6.00005 4.66664 6.00005C4.86309 5.12483 5.43781 4.3557 6.26436 3.86186C6.67363 3.61733 7.1324 3.44775 7.61449 3.36279C8.09657 3.27783 8.59253 3.27917 9.07404 3.36672C9.55556 3.45426 10.0132 3.62631 10.4208 3.87304C10.8285 4.11976 11.1781 4.43633 11.4498 4.80467C11.7215 5.17301 11.9099 5.5859 12.0043 6.01978C12.0987 6.45366 12.0972 6.90002 12 7.33338H12.6666C13.2855 7.33338 13.879 7.57922 14.3166 8.0168C14.7541 8.45438 15 9.04788 15 9.66672C15 10.2856 14.7541 10.879 14.3166 11.3166C13.879 11.7542 13.2855 12 12.6666 12H12"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 10L8 8L10 10"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 8V14"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5778_167009">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
