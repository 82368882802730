import { differenceInSeconds, parseISO, format } from 'date-fns';
import {
  //addTimeZoneToUTCTimeStamp,
  getDurationISOTimeStamps,
  //formatTimeStampWithTz,
  convertUTCTimestamptoZonedTimestamp
} from './dateAndTimeHelpersV2';

export type ProcessApiDataReturnProps = Record<string, unknown>[] | undefined;

export const calculateDateObjectsDuration = (start: Date, end: Date): string | undefined => {
  const duration = differenceInSeconds(end, start);
  const formattedDuration = format(new Date(0, 0, 0, 0, 0, duration), 'H:mm:ss');
  return formattedDuration;
};

const showLogs = false;

export const calculateDuration = (start: string, end: string): string | undefined => {
  const newStart = parseISO(start);
  const newEnd = parseISO(end);

  if (!newStart || !newEnd) return undefined;

  const duration = differenceInSeconds(newEnd, newStart);
  const formattedDuration = format(new Date(0, 0, 0, 0, 0, duration), 'H:mm:ss');

  return formattedDuration;
};

export type ProcessApiSettingsPropsConvertKeys = Record<string, Record<string, string>>;

export interface ProcessApiSettingsProps {
  /* do you want to add a new key `date` that will have only the date, based on start time to this object?
  the date will be iso formatted the same way the start time string is formatted, but will only have the 
  year month and day */
  addDateAsKey?: string;
  addStartOfDayAsKey?: string;
  /* gets the difference between start and end time and adds it to a specified key to the object in pre-formatted string */
  addDurationAsKey?: string;
  /* show halrms tagged 'hidden' in 'location' key */
  showHidden?: boolean;
  /* calculates duration between startTimeKey and endTimeKey and adds as "duration" key, value in seconds*/
  addDuration?: boolean;
  /* current timeZone to use for processing */
  timeZone?: string;
  /* keep the origional timestamp values, but add a new key with the converted time as '[key]Zoned' */
  keepOrigionalTimestamp?: boolean;
  /* the object key for the item start time */
  startTimeKey?: string;
  /* the object key for the item end time */
  endTimeKey?: string;
  /* convert date objects to iso */
  convertDatesToISO?: boolean;
  /* do you want to covert key values to a new value, or change the name of a key? if so, follow the format below */
  convertValues?: Record<string, unknown>;
  /** for troubleshooting console.logs, this adds a string to the message so we know what function is calling this */
  caller?: string;
  /** include origional object data */
  includeData?: boolean;
  /** don't inclide keys with values */
  exclude?: Record<string, unknown[]>;
  /** don't inclide keys with values */
  excludeKeys?: string[];
  /** adds key with given value if undefined */
  addUndefinedKeys?: Record<string, unknown>;
  /** formats the timestamp based on the timezone based on provided date-fns formatting */
  formatTimestamps?: string;
  formatTimestampsAs?: string;
  /** this is needed to add colors based on id key object.  this function will get the value of colorKey and check if it's listed in colors[label] */
  colorKey?: string;
  colors?: Record<string, string>;
  /* EXAMPLE
  keyToConvert: this value will correspond the object key you want to change.
  [keyToConvert]: {  
    // currentValue: the current old value, newValue: the value you want to use
    [currentValue]: newValue,
    [currentValue]: newValue,
    [currentValue]: newValue,
    // addAsNewKey: do you want to replace the current value, or add the new value to a new key
    // newKey: the key you want to add the new values to, leave the old values in place.
    addAsNewKey: newKey
  } 
}
example: i want the id: 'CODE001' to be 'Alarms' and id: 'CODE002' to be 'Alerts' and id: 'CODE003' to be 'Recipes'
i will use currentValues: {
  CODE001: 'Alarms',
  CODE002: 'Alerts',
  CODE003: 'Recipes'
}

if i want to add a new key based on old values (i want to keep the old `id` key the same, but take that text, convert it and add
the new text to a new key i would add the option AddAsNewKey with the name of the key to add, like below...
i will use currentValues: {
  CODE001: 'Alarms',
  CODE002: 'Alerts',
  CODE003: 'Recipes',
  addAsNewKey: `id`
}
*/
  renameKeys?: Record<string, string>;
  renameValues?: Record<string, Record<string, string>>;
  /* do you want to rename a key something else?  this will remove the old key from the object */
  convertKeys?: ProcessApiSettingsPropsConvertKeys;
  addValuesAsKeys?: Record<string, string>;
}

export const processApiData = (
  data: ProcessApiDataReturnProps,
  props?: ProcessApiSettingsProps
): ProcessApiDataReturnProps => {
  if (!data) return undefined;

  // start a new array to return that is processed
  // based on the props passed in
  const x = (data || []).reduce((acc: Record<string, unknown>[], item) => {
    // if showHidden is false, and the item is hidden, skip it
    // i forget why this is needed
    if (!props?.showHidden && item?.location === 'hidden') return acc;

    // make a copy of the item to not affect the origional array
    const obj = { ...item };

    // this takes an object who's keys are the keys that you want to check for, the values you want to appear
    // if that key is undefined
    if (props?.addUndefinedKeys) {
      Object.keys(props.addUndefinedKeys).forEach((key) => {
        if (!obj?.[key]) obj[key] = props.addUndefinedKeys?.[key];
      });
    }

    // break down props to make it easier to read and to pass TS checks
    let startTimeKey = props?.startTimeKey;
    let endTimeKey = props?.endTimeKey;
    const timeZone = props?.timeZone as string;

    // check if you're excluding keys with values
    if (props?.exclude) {
      let excluded = false;
      Object.entries(props.exclude).map(([key, vals]) => {
        if (obj[key] && vals.includes(obj[key])) {
          excluded = true;
        }
      });
      if (excluded) return acc;
    }
    // check if your removing certain keys from every object
    if (props?.excludeKeys) {
      props.excludeKeys.map((key) => {
        delete obj[key];
      });
    }

    if (props?.convertKeys) {
      Object.entries(props.convertKeys).map(([convertKey, convertVals]) => {
        if (obj?.[convertKey]) {
          const oldValue = obj?.[convertKey] as string;
          const newValue = convertVals?.[oldValue] as string;
          obj[convertKey] = newValue || oldValue;
        }
      });
    }

    if (props?.renameValues) {
      Object.entries(props.renameValues).map(([convertKey, values]) => {
        if (obj?.[convertKey] && values?.[obj?.[convertKey] as string])
          obj[convertKey] = values?.[obj?.[convertKey] as string] as string;
      });
    }

    if (props?.addValuesAsKeys) {
      Object.entries(props.addValuesAsKeys).map(([key, value]) => {
        if (obj?.[key]) {
          obj[value] = obj?.[key];
        }
      });
    }

    if (props?.colors && props?.colorKey && obj?.[props.colorKey]) {
      const colorKey = props.colorKey as string;
      const colorId = obj?.[colorKey] as string;
      const color = colorId && props.colors?.[colorId];
      obj.color = color || 'red';
    }

    if (showLogs) {
      // check if there's a startTime
      if (startTimeKey && !obj?.[startTimeKey]) {
        console.log(
          `Error processing data:  startTimeKey: ${startTimeKey} does exist as a key in object.`
        );
      }
      // check for endTime
      if (endTimeKey && !obj?.[endTimeKey]) {
        console.log(
          `Error processing data: endTimeKey: ${endTimeKey} does exist as a key in object.`
        );
      }
    }
    // check if either start or end time is present before even bother with conversions
    if ((startTimeKey && obj?.[startTimeKey]) || (endTimeKey && obj?.[endTimeKey])) {
      startTimeKey = startTimeKey as string;
      endTimeKey = endTimeKey as string;

      const startTimeUTC = obj?.[startTimeKey] as string;
      const endTimeUTC = obj?.[endTimeKey] as string;

      // convert to provided timezone if needed
      if (timeZone) {
        // check if there's a start time stamp
        if (props?.startTimeKey && startTimeUTC) {
          // check to see if you want to foramt the time stamp be a new zoned time stamp,
          // or add the zoned timestamp to a new key [startTimeKey]Zoned
          if (props?.keepOrigionalTimestamp) {
            obj[`${startTimeKey}Zoned`] = convertUTCTimestamptoZonedTimestamp(
              startTimeUTC,
              timeZone
            );
          } else {
            obj[startTimeKey] = convertUTCTimestamptoZonedTimestamp(startTimeUTC, timeZone);
            obj[`${startTimeKey}UTC`] = startTimeUTC;
          }

          // adds the formatted zoned time as a new key [startTimeKey]Formatted
          if (props?.formatTimestamps)
            obj[`${startTimeKey}Formatted`] = convertUTCTimestamptoZonedTimestamp(
              startTimeUTC,
              timeZone,
              props?.formatTimestamps
            );

          // check to see if adding the start of date is req
          // adds it as an ISO object
          if (props?.addDateAsKey) {
            const zonedTime = convertUTCTimestamptoZonedTimestamp(startTimeUTC, timeZone) as string;
            if (props?.keepOrigionalTimestamp) {
              obj[`${props.addDateAsKey}Zoned`] = zonedTime.split('T')[0];
            } else {
              obj[props.addDateAsKey] = startTimeUTC.split('T')[0];
              obj[`${props.addDateAsKey}Zoned`] = zonedTime.split('T')[0];
            }
          }
        }

        // check if key exists in object, then convert
        if (props?.endTimeKey && endTimeUTC) {
          // check to see if you want to foramt the time stamp be a new zoned time stamp,
          // or add the zoned timestamp to a new key [endTimeKey]Zoned
          if (props?.keepOrigionalTimestamp) {
            obj[`${endTimeKey}Zoned`] = convertUTCTimestamptoZonedTimestamp(endTimeUTC, timeZone);
          } else {
            obj[endTimeKey] = convertUTCTimestamptoZonedTimestamp(endTimeUTC, timeZone);
            obj[`${endTimeKey}UTC`] = endTimeUTC;
          }

          // adds the formatted zoned time as a new key [startTimeKey]Formatted
          if (props?.formatTimestamps)
            obj[`${endTimeKey}Formatted`] = convertUTCTimestamptoZonedTimestamp(
              endTimeUTC,
              timeZone,
              props?.formatTimestamps
            );
        }
      }

      // this returns the start of day as a zoned timestamp and utc timestamp0
      if (props?.addStartOfDayAsKey && obj[startTimeKey] && startTimeUTC) {
        const newStart = String(obj[startTimeKey]).split('T')[0];
        const [newStartUTC] = String(startTimeUTC).split('T');
        const offset = String(obj[startTimeKey]).slice(-6);
        obj[props.addStartOfDayAsKey] = `${newStart}T00:00${offset}`;
        obj[`${props.addStartOfDayAsKey}UTC`] = `${newStartUTC}T00:00:00Z`;
      }

      // returns the number of seconds between start and end time
      if (startTimeKey && endTimeKey && (props?.addDurationAsKey || props?.addDuration)) {
        const durationKey = props?.addDurationAsKey || 'duration';
        obj[durationKey] = getDurationISOTimeStamps(
          obj[endTimeKey] as string,
          obj[startTimeKey] as string
        );
      }
    }

    return (acc = [...acc, obj]), acc;
  }, []);

  if (props?.caller) console.log(`processApiData called by: ${props.caller}`);

  return x;
};
