import React from 'react';

export const FrameIcon = ({ color }: { color?: string }): JSX.Element => {
  color = color || '#0076CC';
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_933_19355)">
        <path
          d="M7.3335 10.0002C7.3335 10.7074 7.61445 11.3857 8.11454 11.8858C8.61464 12.3859 9.29292 12.6668 10.0002 12.6668C10.7074 12.6668 11.3857 12.3859 11.8858 11.8858C12.3859 11.3857 12.6668 10.7074 12.6668 10.0002C12.6668 9.29292 12.3859 8.61464 11.8858 8.11454C11.3857 7.61445 10.7074 7.3335 10.0002 7.3335C9.29292 7.3335 8.61464 7.61445 8.11454 8.11454C7.61445 8.61464 7.3335 9.29292 7.3335 10.0002Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3335 12.3335L14.0002 14.0002"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2.6665 4H13.3332" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.6665 8H5.33317" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.6665 12H5.33317" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_933_19355">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
