// 3rd party libs
import React, { ReactElement } from 'react';
import theme from 'themes';
import styled from 'styled-components';

// Components
import { DataRenderer } from 'components/machine-health';
import { Checkbox, Column, KPICard, Typography } from 'components';

// Types
import { GroupPermission } from 'types/user-management';

import { useGetUserManagementPermissionsV2Query } from 'api';

interface Props {
  onPermissionChange: (permissions: GroupPermission[]) => void;
  groupPermissions: GroupPermission[];
}

const StyledRow = styled.div`
  display: flex;
  grid-gap: 1.25rem;
  flex-wrap: wrap;
  padding: 0.625rem;
  border-bottom: 0.0625rem solid ${theme.colors.lightMediumGrey};
`;
const PermissionContainer = styled.div`
  margin-top: 0.375rem;
`;
const Permission = ({ onPermissionChange, groupPermissions }: Props): ReactElement => {
  // Get all permissions and render permissions name.
  const {
    data: permissions,
    error: error,
    isFetching: isLoadingPermissions
  } = useGetUserManagementPermissionsV2Query();

  const updatePermission = (newPermission: GroupPermission, foundPermissionId: number) => {
    const newPermissions = [...groupPermissions];

    if (foundPermissionId !== -1) {
      // permission exist before
      if (permissions) newPermissions[foundPermissionId] = newPermission;
    } else {
      // permission does not exist before
      newPermissions.push(newPermission);
    }

    onPermissionChange(newPermissions);
  };

  const getIndexOfPermission = (permissionId: string) => {
    return groupPermissions.findIndex((permission) => permission.permissionId === permissionId);
  };

  const renderPermissions = () => {
    return permissions?.map((permission, index) => {
      const permissionId = permission.id;
      // Find the permission for this row in Group Permission
      const groupPermissionItem = groupPermissions.find(
        (groupPermissions) => groupPermissions.permissionId === permissionId
      );
      return (
        <StyledRow key={index}>
          <Column size={10}>
            <Typography
              size="0.75rem"
              color={theme.colors.text.lightBlack}
              mb={0}
              style={{ marginTop: '1.2rem', fontWeight: 500 }}
            >
              {permission.name}
            </Typography>
          </Column>
          <Column size={1}>
            {permission.name !== 'Machine Management' &&
              permission.name !== 'Groups and User Management' &&
              permission.name !== 'Documentation Center' && (
                <Checkbox
                  checked={groupPermissionItem && groupPermissionItem.read}
                  onChange={(e: { target: { checked: boolean } }) => {
                    updatePermission(
                      {
                        read: e.target.checked,
                        write: groupPermissionItem?.write ?? false,
                        permissionId
                      },
                      getIndexOfPermission(permissionId)
                    );
                  }}
                />
              )}
          </Column>
          <Column size={1}>
            <Checkbox
              checked={groupPermissionItem && groupPermissionItem.write}
              onChange={(e: { target: { checked: boolean } }) => {
                updatePermission(
                  {
                    read: groupPermissionItem?.read ?? false,
                    write: e.target.checked,
                    permissionId
                  },
                  getIndexOfPermission(permissionId)
                );
              }}
            />
          </Column>
        </StyledRow>
      );
    });
  };
  return (
    <PermissionContainer>
      <KPICard
        hideHeaderBackgroundColor={true}
        showHeaderBottom={true}
        component={
          <>
            <Typography
              size="0.813rem"
              weight="bold"
              color={theme.colors.darkGrey}
              style={{ marginLeft: '-0.7rem' }}
            >
              Modules
            </Typography>
            <Typography
              size="0.813rem"
              weight="bold"
              color={theme.colors.darkGrey}
              style={{ marginLeft: '12.5rem' }}
            >
              Read
            </Typography>
            <Typography
              size="0.813rem"
              weight="bold"
              color={theme.colors.darkGrey}
              style={{ marginRight: '0rem' }}
            >
              Write
            </Typography>
          </>
        }
      >
        <DataRenderer isLoading={isLoadingPermissions} error={error && 'Failed to load data'}>
          {renderPermissions()}
        </DataRenderer>
      </KPICard>
    </PermissionContainer>
  );
};

export default Permission;
