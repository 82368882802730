// 3rd party libraries
import React, { useEffect, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { useTheme } from 'styled-components';
import { SelectMethods, SelectProps, SelectState } from 'react-dropdown-select';
import { List, AutoSizer } from 'react-virtualized';
import { useTranslation } from 'react-i18next';

// Types
import { WidgetTableDropdownItem } from 'types/machine-health';
import { WidgetType } from 'types/protein';

// Components
import { StyledTableInput } from './Form';

// Constants
import { TagIdPrefix } from 'constants/machineConfig';
import { TagsDropdownItems } from 'types/machine-health/widget-table';
import BaseSelect from 'components/BaseSelect/BaseSelect';
import TabNavToggle from 'components/TabNavToggle';
import { Button, TooltipWrapper } from 'components';
import { TDropdownValue } from 'components/AlertQueryBuilder/customComponents/CustomValueEditor';
import { formatDecimalValue } from 'helpers';
import { IconSearch } from 'icons/IconSearch';
import { IcoRequiredInfo } from 'icons/IcoRequiredInfo';
import { colors, themeColors } from 'themes';
import Tooltip from 'rc-tooltip';
import { useParams } from 'react-router-dom';

export const StyledSelect = styled.select<{ color?: string }>`
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  width: 100%;
`;

const StyledSelectWrapper = styled.div`
  align-items: center;
  border-radius: 0.5rem;
  border: ${({ theme }) => theme.colors.borders.border02.border};
  color: ${({ color, theme }) => color || theme.colors.table.primary};
  display: flex;
  flex-grow: 1;
  height: 2.25rem;
  outline: 0.125rem solid transparent;
  position: relative;
  transition: all 0.2s ease-in-out;

  select {
    display: none;
  }

  svg {
    font-size: 1rem;
  }

  &:hover,
  :active,
  :focus {
    border: 0.0625rem solid ${({ theme }) => theme.colors.mediumBlue};
    outline: 0.125rem solid ${({ theme }) => theme.colors.mediumBlue3};
  }
`;

const StyledSelectLabelButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ color, theme }) => color || theme.colors.mediumBlue};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.typography.family};
  font-size: ${({ theme }) => theme.typography.components.tableHeader.size};
  height: 100%;
  justify-content: space-between;
  padding: 0 0.75rem;
  text-align: left;
  width: 100%;
`;

const StyledOption = styled.div<{
  active?: boolean;
  disabled?: boolean;
  onClick?: null | (() => void);
  style?: string;
}>`
  align-items: center;
  background-color: ${({ active, disabled, theme }) =>
    disabled ? theme.colors.lightGrey1 : active ? theme.colors.background.background10 : ''};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.darkGrey};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;

  span {
    color: ${({ disabled, theme }) => disabled && theme.colors.mediumGrey2};
  }

  &:hover,
  :focus,
  :focus:hover {
    background-color: ${({ active, theme }) => !active && theme.colors.background.background2};
  }
`;

const StyledOptionWrapper = styled.div<{ isVisible: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.background1};
  border-radius: 0.5rem;
  border: ${({ theme, isVisible }) => isVisible && theme.colors.borders.border02.border};
  box-shadow: ${({ theme, isVisible }) => isVisible && theme.colors.field.white.shadow};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: ${({ isVisible }) => (isVisible ? '18rem' : '0')};
  overflow-y: auto;
  position: absolute;
  top: 2.5rem;
  width: 100%;
  z-index: 1;
`;

const StyledSpanWrapper = styled.span<{ color?: string }>`
  align-items: center;
  color: ${({ color, theme }) => color || theme.colors.darkGrey};
  display: flex;
  gap: 0.25rem;
  backgroud: red;
`;

const StyledSpan = styled.span<{
  color?: string;
  isDisabledLabel?: boolean;
  padding?: string;
  isRequired?: boolean;
}>`
  border: ${({ isDisabledLabel, theme }) =>
    isDisabledLabel && '0.0625rem solid ' + theme.colors.lightGrey5};
  border-radius: ${({ isDisabledLabel }) => isDisabledLabel && '0.5rem'};
  color: ${({ color, theme }) => color || theme.colors.darkGrey};
  padding: ${({ padding }) => padding || 0};
  word-break: break-word;
  ${({ isRequired }) =>
    isRequired &&
    `
`}
`;

const StyledCircleSpan = styled.span`
  font-size: 2rem;
  padding-bottom: 0.35rem;
`;

const StyledSearch = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin: 0.5rem 0.5rem 0.125rem;
  }
`;

const ThresholdFieldContainer = styled.form`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  padding: 0.5rem;
  justify-content: space-between;
  input {
    cursor: text;
  }
  input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
    -webkit-appearance: inner-spin-button !important;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: ${colors.darkGrey};
  }

  button {
    width: 15rem;
  }
  cursor: text;
`;

const StyledSearchAlertLogic = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  input {
    margin: 0.5rem 0.5rem 0.125rem;
  }
`;

const StyledOptions = styled(List)`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 0.5rem;
  max-height: 12rem;
`;

const StyledInputField = styled.input`
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border: 0.1rem solid #ccc;
  border-radius: 0.4rem;
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
`;

const WarningIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1.4rem;
  top: 0.675rem;
`;

const DropdownOptions = styled.div<{
  active?: boolean;
  disabled?: boolean;
  onClick?: null | (() => void);
  style?: string;
}>`
  align-items: center;
  background-color: ${({ active, disabled, theme }) =>
    disabled ? theme.colors.lightGrey1 : active ? theme.colors.background.background10 : ''};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.darkGrey};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  gap: 0.5rem;
  padding: 1rem 1.5rem 1rem 2rem;
  justify-content: space-between;
  span {
    color: ${({ disabled, theme }) => disabled && theme.colors.mediumGrey2};
  }

  &:hover,
  :focus,
  :focus:hover {
    background-color: ${({ active, theme }) => !active && theme.colors.background.background2};
  }
`;

export const OptionHeaders = styled.div`
  border-bottom: 1px solid ${themeColors.lightGrey3};
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 0rem 1rem;
  span {
    font-weight: 600;
    color: ${themeColors.mediumGrey3};
    font-size: 14px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: auto;
`;

const SearchBarWrapper = styled.div`
  position: relative;
`;
interface DropdownProps {
  selectedItem?: WidgetTableDropdownItem;
  onChangeCallback?: (value: WidgetTableDropdownItem) => void;
  options: WidgetTableDropdownItem[];
}

export const Dropdown = ({
  selectedItem,
  onChangeCallback,
  options
}: DropdownProps): JSX.Element => {
  const theme = useTheme();

  const [selectedTag, setSelectedTag] = useState<WidgetTableDropdownItem>({
    name: 'Select an item',
    id: '',
    type: WidgetType.None
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    selectedItem && setSelectedTag(selectedItem);
  }, [selectedItem]);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleValueChange = (option: WidgetTableDropdownItem) => {
    setSelectedTag(option);
  };

  const handleChange = (option: WidgetTableDropdownItem) => {
    handleValueChange(option);
    onChangeCallback?.(option);
    handleClose();
  };

  return (
    <StyledSelectWrapper>
      <StyledSelectLabelButton onClick={(e) => handleOpen(e)}>
        <StyledSpan
          color={
            selectedTag?.values?.[0]?.value ? theme.colors.onTrackGreen : theme.colors.mediumGrey3
          }
        >
          <StyledCircleSpan>•</StyledCircleSpan>
          {selectedTag.id.startsWith(TagIdPrefix) ? (
            <StyledSpan color={theme.colors.mediumGrey3}>Select a tag...</StyledSpan>
          ) : (
            <StyledSpan color={theme.colors.mediumBlue}>{selectedTag.id}</StyledSpan>
          )}
        </StyledSpan>
        <FontAwesomeIcon icon={faChevronDown} color={theme.colors.darkGrey} />
      </StyledSelectLabelButton>
      <StyledOptionWrapper isVisible={open}>
        {options.map((tag, index) => (
          <StyledOption
            active={tag.id === selectedTag.id}
            key={index}
            onClick={() => handleChange(tag)}
          >
            <StyledSpan>{tag.name || tag.id}</StyledSpan>
            {tag.values?.[0]?.value && (
              <StyledSpan color={theme.colors.mediumGrey2}>{tag.values[0].value}</StyledSpan>
            )}
          </StyledOption>
        ))}
      </StyledOptionWrapper>
    </StyledSelectWrapper>
  );
};

interface CustomDropdownProps {
  placeHolder?: string;
  props: SelectProps<string | Record<string, unknown>>;
  state: SelectState<string | Record<string, unknown>>;
  methods: SelectMethods<string | Record<string, unknown>>;
  secondDropdown?: boolean;
  header?: boolean;
  borderVariant?: 'black';
}

interface CustomDropdownAlertLogicProps {
  props: SelectProps<string | Record<string, unknown>>;
  state: SelectState<string | Record<string, unknown>>;
  methods: SelectMethods<string | Record<string, unknown>>;
  thresholdType?: string;
  isContainsDropdown?: boolean;
}

type DropdownSingleTagValue = {
  id: string;
  values: {
    value: string;
  }[];
  isCustomThresholdValue: boolean;
};

type DropdownTagValue = {
  id: DropdownSingleTagValue;
  name: DropdownSingleTagValue;
  label: DropdownSingleTagValue;
};

// This component renders the content in the react-dropdown-select
export const CustomDropdownRenderer = ({
  props: selectProps,
  state,
  methods
}: CustomDropdownProps): JSX.Element | null => {
  const theme = useTheme();
  const regexp = new RegExp(state.search, 'i');
  const { t } = useTranslation(['mh']);
  const items = selectProps.options
    .filter((item) => {
      const typedItem = item as Record<string, unknown>;
      const searchBy = selectProps.searchBy as string;
      const labelField = selectProps.labelField as string;
      return (
        regexp.test(typedItem[searchBy] as string) || regexp.test(typedItem[labelField] as string)
      );
    })
    .filter((option) => selectProps.keepSelectedInList || !methods.isSelected(option));

  // Note about types in this component:
  // We are trusting that the react-dropdown-select library sends itself the correct types.
  // https://github.com/sanusart/react-dropdown-select/blob/master/docs/src/examples/CustomContentAndDropdown.js

  return (
    <StyledSearch>
      <StyledTableInput
        type="text"
        value={state.search}
        onChange={methods.setSearch}
        placeholder={t('search_for_tag') as string}
      />
      <AutoSizer style={{ height: '12rem' }}>
        {({ width, height }: { width: number; height: number }) => (
          <StyledOptions
            height={height}
            rowCount={items.length}
            rowHeight={38} // 2.5rem
            width={width - 2}
            rowRenderer={({ index, style, key }: { index: number; style: string; key: string }) => {
              const typedOption = items[index] as Record<string, unknown>;
              const itemValue = (typedOption?.values as { value: string }[])?.[0]?.value;
              const hasValue = itemValue !== undefined && itemValue !== null;
              return (
                <StyledOption
                  disabled={typedOption.disabled as boolean}
                  key={key}
                  style={style}
                  onClick={typedOption.disabled ? undefined : () => methods.addItem(typedOption)}
                >
                  <StyledSpan>{typedOption[selectProps.searchBy as string] as string}</StyledSpan>
                  <StyledSpan color={theme.colors.mediumGrey2}>
                    {typedOption[selectProps.labelField as string] !==
                    typedOption[selectProps.searchBy as string]
                      ? `(${typedOption[selectProps.labelField as string] as string})`
                      : ''}
                  </StyledSpan>
                  {hasValue && !typedOption.disabled && (
                    <StyledSpan color={theme.colors.mediumGrey2}>{itemValue}</StyledSpan>
                  )}
                  {typedOption.disabled && (
                    <StyledSpan isDisabledLabel>{selectProps.disabledLabel}</StyledSpan>
                  )}
                </StyledOption>
              );
            }}
          />
        )}
      </AutoSizer>
    </StyledSearch>
  );
};

// This component renders the content in the react-dropdown-select
export const CustomDropdownRendererAlertFieldSelector = ({
  placeHolder,
  props: selectProps,
  state,
  methods,
  secondDropdown,
  header,
  borderVariant
}: CustomDropdownProps): JSX.Element | null => {
  const theme = useTheme();
  const escapedSearch = state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regexp = new RegExp(escapedSearch, 'i');
  const [selectedOperator, setSelectedOperator] = useState('');
  const { t } = useTranslation(['mh']);
  const operatorsList = [
    {
      value: 'active_tags',
      label: t('active_tags') as string
    }
  ];
  const items = selectProps.options
    .filter((item) => {
      const typedItem = item as Record<string, unknown>;
      const searchBy = selectProps.searchBy as string;
      const labelField = selectProps.labelField as string;
      return (
        regexp.test(typedItem[searchBy] as string) || regexp.test(typedItem[labelField] as string)
      );
    })
    .filter((option) => selectProps.keepSelectedInList || !methods.isSelected(option));

  // Note about types in this component:
  // We are trusting that the react-dropdown-select library sends itself the correct types.
  // https://github.com/sanusart/react-dropdown-select/blob/master/docs/src/examples/CustomContentAndDropdown.js

  return (
    <StyledSearch>
      <StyledSearchAlertLogic>
        <div
          style={{
            flex: '1 0 8rem'
          }}
        >
          <SearchBarWrapper>
            <IconWrapper>
              <IconSearch width="15" />
            </IconWrapper>
            <StyledTableInput
              type="text"
              value={state.search}
              onChange={methods.setSearch}
              placeholder={placeHolder ? placeHolder : (t('search') as string)}
              style={{
                width: '100%',
                height: '40px',
                paddingLeft: '30px',
                backgroundColor: '#F9FAFB'
              }}
              borderVariant={borderVariant}
            />
          </SearchBarWrapper>
        </div>
        {secondDropdown && (
          <div style={{ margin: '0.5rem 0.5rem 0.125rem 1rem', flex: '0 1 8rem' }}>
            <BaseSelect
              // disabledLabel={'tag_already_assigned'}
              handleChange={(e) => {
                // props.handleOnChange(e ? e.target.value : null);
                setSelectedOperator(e.target.value);
              }}
              labelField="id"
              options={operatorsList}
              searchable={false}
              searchBy="label" // label is assigned as friendlyName value
              value={selectedOperator}
              valueField="id"
            />
          </div>
        )}
      </StyledSearchAlertLogic>
      {header && (
        <OptionHeaders>
          <span>Name</span>
          <span>Value</span>
        </OptionHeaders>
      )}
      <AutoSizer style={{ height: '12rem' }}>
        {({ width, height }: { width: number; height: number }) => (
          <StyledOptions
            height={height}
            rowCount={items.length}
            rowHeight={38} // 2.5rem
            width={width - 2}
            rowRenderer={({ index, style, key }: { index: number; style: string; key: string }) => {
              const typedOption = items[index] as Record<string, unknown>;
              const itemValue = (typedOption?.values as { value: string }[])?.[0]?.value;
              const hasValue = itemValue !== undefined && itemValue !== null;
              return (
                <>
                  <DropdownOptions
                    disabled={typedOption.disabled as boolean}
                    key={key}
                    style={style}
                    onClick={typedOption.disabled ? undefined : () => methods.addItem(typedOption)}
                  >
                    <StyledSpan>
                      {typedOption[selectProps.labelField as string] as string}
                    </StyledSpan>

                    {hasValue && !typedOption.disabled && (
                      <StyledSpan color={theme.colors.darkGrey}>
                        {typeof itemValue === 'string'
                          ? itemValue
                          : formatDecimalValue({ value: Number(itemValue) })}
                      </StyledSpan>
                    )}
                    {typedOption.disabled && (
                      <StyledSpan isDisabledLabel>{selectProps.disabledLabel}</StyledSpan>
                    )}
                  </DropdownOptions>
                </>
              );
            }}
          />
        )}
      </AutoSizer>
    </StyledSearch>
  );
};

// This component renders the content in the react-dropdown-select
export const CustomDropdownRendererAlertValueEditor = ({
  props: selectProps,
  state,
  methods,
  thresholdType,
  isContainsDropdown
}: CustomDropdownAlertLogicProps): JSX.Element | null => {
  const theme = useTheme();
  const escapedSearch = state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regexp = new RegExp(escapedSearch, 'i');

  const params: {
    alertId: string;
    machineId: string;
  } = useParams();

  const { t } = useTranslation(['mh']);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stateValue: any = state.values[0] as TDropdownValue;

  const selectedTags: DropdownTagValue = selectProps.values[0] as DropdownTagValue;

  const [customThresholdValue, setCustomThresholdValue] = useState<string | number>(
    // eslint-disable-next-line no-prototype-builtins
    selectedTags.hasOwnProperty('id')
      ? stateValue?.id?.isCustomThresholdValue === false ||
        stateValue?.isCustomThresholdValue === false
        ? ''
        : (selectedTags?.id?.id as string)
      : ''
  );

  const items = selectProps.options
    .filter((item) => {
      const typedItem = item as Record<string, unknown>;
      const searchBy = selectProps.searchBy as string;
      const labelField = selectProps.labelField as string;
      return (
        regexp.test(typedItem[searchBy] as string) || regexp.test(typedItem[labelField] as string)
      );
    })
    .filter((option) => selectProps.keepSelectedInList || !methods.isSelected(option));

  // Note about types in this component:
  // We are trusting that the react-dropdown-select library sends itself the correct types.
  // https://github.com/sanusart/react-dropdown-select/blob/master/docs/src/examples/CustomContentAndDropdown.js

  const [currentTab, setCurrentTab] = useState<string>(
    // '1'
    // eslint-disable-next-line no-prototype-builtins
    stateValue && stateValue.hasOwnProperty('label')
      ? stateValue?.isCustomThresholdValue
        ? '1'
        : stateValue?.id?.isCustomThresholdValue
        ? '1'
        : params && params.alertId && stateValue?.id?.isCustomThresholdValue === false
        ? '0'
        : '1'
      : '1'
  );

  return (
    <>
      <TabNavToggle
        items={
          isContainsDropdown
            ? [
                {
                  label: t('threshold') as string,
                  onClick: () => {
                    setCurrentTab('1');
                  },
                  active: currentTab === '1',
                  isTabEnabled: true
                }
              ]
            : [
                {
                  label: t('tag') as string,
                  onClick: () => {
                    setCurrentTab('0');
                  },
                  active: currentTab === '0',
                  isTabEnabled: true
                },
                {
                  label: t('threshold') as string,
                  onClick: () => {
                    setCurrentTab('1');
                  },
                  active: currentTab === '1',
                  isTabEnabled: true
                }
              ]
        }
      />
      {currentTab === '0' ? (
        <StyledSearch>
          <SearchBarWrapper>
            <IconWrapper>
              <IconSearch width="15" />
            </IconWrapper>
            <StyledTableInput
              type="text"
              value={state.search}
              onChange={methods.setSearch}
              placeholder={t('search_for_tag') as string}
              style={{
                width: '96%',
                height: '40px',
                paddingLeft: '30px',
                backgroundColor: '#F9FAFB'
              }}
            />
          </SearchBarWrapper>
          <OptionHeaders>
            <span>Name</span>
            <span>Value</span>
          </OptionHeaders>
          <AutoSizer style={{ height: '12rem' }}>
            {({ width, height }: { width: number; height: number }) => (
              <StyledOptions
                height={height}
                rowCount={items.length}
                rowHeight={38} // 2.5rem
                width={width - 2}
                rowRenderer={({
                  index,
                  style,
                  key
                }: {
                  index: number;
                  style: string;
                  key: string;
                }) => {
                  const typedOption = items[index] as Record<string, unknown>;
                  const itemValue = (typedOption?.values as { value: string }[])?.[0]?.value;
                  const hasValue = itemValue !== undefined && itemValue !== null;
                  return (
                    <DropdownOptions
                      disabled={typedOption.disabled as boolean}
                      key={key}
                      style={style}
                      onClick={
                        typedOption.disabled
                          ? undefined
                          : () => methods.addItem({ ...typedOption, isCustomThresholdValue: false })
                      }
                    >
                      <StyledSpan>
                        {typedOption[selectProps.labelField as string] as string}
                      </StyledSpan>
                      {hasValue && !typedOption.disabled && (
                        <StyledSpan color={theme.colors.darkGrey}>
                          {typeof itemValue === 'string'
                            ? itemValue
                            : formatDecimalValue({ value: Number(itemValue) })}
                        </StyledSpan>
                      )}
                      {typedOption.disabled && (
                        <StyledSpan isDisabledLabel>{selectProps.disabledLabel}</StyledSpan>
                      )}
                    </DropdownOptions>
                  );
                }}
              />
            )}
          </AutoSizer>
        </StyledSearch>
      ) : (
        <ThresholdFieldContainer
          onSubmit={() =>
            methods.addItem({
              id: customThresholdValue,
              values: [{ value: customThresholdValue }],
              isCustomThresholdValue: true
            })
          }
        >
          <StyledInputField
            defaultValue={customThresholdValue}
            type={thresholdType}
            value={customThresholdValue}
            placeholder={t('value') as string}
            style={{ padding: '0.5rem' }}
            onChange={(e) => {
              if (thresholdType === 'number') {
                return setCustomThresholdValue(Number(e.target.value));
              } else {
                setCustomThresholdValue(e.target.value.replace(/\//g, ''));
              }
            }}
          />
          <Button
            borderRadius="3rem"
            variant="hover-blue"
            type="submit"
            bgColor={theme.colors.primaryBlue5}
            color={theme.colors.lightGrey3}
            onClick={() => {
              return methods.addItem({
                id: customThresholdValue,
                values: [{ value: customThresholdValue }],
                isCustomThresholdValue: true
              });
            }}
          >
            {t('submit', { ns: 'fpns' })}
          </Button>
        </ThresholdFieldContainer>
      )}
    </>
  );
};

interface CustomContentProps {
  item: WidgetTableDropdownItem | TagsDropdownItems;
  label?: string | undefined;
  toolTipText?: string;
  removeTooltipIcon?: boolean;
  removeDotIcon?: boolean;
  inputTextColor?: string;
  inputBgColor?: string;
  enableTooltip?: boolean;
}

// This component renders the selected item in the react-dropdown-select
export const CustomContentRenderer = ({
  item,
  label,
  toolTipText,
  removeTooltipIcon,
  removeDotIcon,
  inputTextColor,
  enableTooltip = false
}: CustomContentProps): JSX.Element | null => {
  const theme = useTheme();
  const { t } = useTranslation(['mh']);

  const itemValue = item?.values?.[0]?.value;
  const hasValue = itemValue !== undefined && itemValue !== null;

  return item ? (
    <StyledSpanWrapper color={hasValue ? theme.colors.onTrackGreen : theme.colors.negativeRed}>
      {!removeDotIcon && <StyledCircleSpan>•</StyledCircleSpan>}
      {enableTooltip ? (
        <ToolTipWrapper tooltipMessage={item && ` Tag ID : ${item.label || item.name}`}>
          <StyledSpan color={inputTextColor || theme.colors.mediumBlue} padding="0 1.5rem 0 0">
            {item.friendlyName || item.name || item}
          </StyledSpan>
        </ToolTipWrapper>
      ) : (
        <StyledSpan color={inputTextColor || theme.colors.mediumBlue} padding="0 1.5rem 0 0">
          {item.friendlyName || item.name || item}
        </StyledSpan>
      )}
    </StyledSpanWrapper>
  ) : (
    <StyledSpan color={theme.colors.mediumGrey3} isRequired>
      {t(label as string) as string}
      {removeTooltipIcon ? (
        ''
      ) : (
        <WarningIconContainer>
          <TooltipWrapper Tooltip={toolTipText}>
            <IcoRequiredInfo width="30" />
          </TooltipWrapper>
        </WarningIconContainer>
      )}
    </StyledSpan>
  );
};

// This component renders the selected item in the react-dropdown-select
export const CustomContentRendererValueEditor = ({
  item,
  label,
  toolTipText,
  enableTooltip = false
}: CustomContentProps): JSX.Element | null => {
  const theme = useTheme();
  const { t } = useTranslation(['mh']);

  const itemValue = item?.values?.[0]?.value;
  const hasValue = itemValue !== undefined && itemValue !== null;

  return item && item?.id?.length !== 0 ? (
    <StyledSpanWrapper color={hasValue ? theme.colors.onTrackGreen : theme.colors.negativeRed}>
      <StyledCircleSpan>•</StyledCircleSpan>
      {enableTooltip ? (
        item && item?.isCustomThresholdValue ? (
          <StyledSpan color={theme.colors.mediumBlue} padding="0 1.5rem 0 0">
            {item && `${item.id}`}
          </StyledSpan>
        ) : (
          <ToolTipWrapper tooltipMessage={item && `Tag ID : ${item.label}`}>
            <StyledSpan color={theme.colors.mediumBlue} padding="0 1.5rem 0 0">
              {item && `${item.id}`}
            </StyledSpan>
          </ToolTipWrapper>
        )
      ) : (
        <StyledSpan color={theme.colors.mediumBlue} padding="0 1.5rem 0 0">
          {item && `${item.id}`}
        </StyledSpan>
      )}
    </StyledSpanWrapper>
  ) : (
    <StyledSpan color={theme.colors.mediumGrey3} isRequired>
      {t(label as string) as string}
      {toolTipText ? (
        <WarningIconContainer>
          <TooltipWrapper Tooltip={toolTipText}>
            <IcoRequiredInfo width="30" />
          </TooltipWrapper>
        </WarningIconContainer>
      ) : null}
    </StyledSpan>
  );
};

const TooltipInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: left;
  word-wrap: break-word;
`;

export const ToolTipWrapper = ({
  tooltipMessage,
  children,
  offset
}: {
  tooltipMessage: string;
  children: JSX.Element;
  offset?: 'center';
}): JSX.Element => {
  return (
    <Tooltip
      overlayClassName="information-tooltip"
      placement={'top'}
      overlay={<TooltipInformationContainer>{tooltipMessage}</TooltipInformationContainer>}
      arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      overlayStyle={{
        width: 'fit-content',
        fontSize: '0.75rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '0.875rem',
        letterSpacing: '0em',
        textAlign: 'left'
      }}
      align={{
        offset: offset === 'center' ? [0, 0] : [-24, 0]
      }}
    >
      {children}
    </Tooltip>
  );
};
