import { useEffect, RefObject } from 'react';

type Event = MouseEvent | TouchEvent;

// triggers a provided function if mouse is clicked outside of a provided ref elemenmt
export const useClickOutside = <T extends HTMLElement = HTMLElement>(
  // the containing elements ref
  ref: RefObject<T>,
  // the function to call if click is outside of the ref element
  handler: (event: Event) => void
): void => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }
      handler(event); // Call the handler only if the click is outside of the element passed.
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]); // Reload only if ref or handler changes
};

export default useClickOutside;
