/* eslint-disable @typescript-eslint/no-unused-vars */

// Third Party
import React, { ReactElement } from 'react';
import styled from 'styled-components';

// Styling
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 6rem;
  padding-right: 6rem;
  width: 100%;
`;

interface Props {
  hasWidgets?: boolean;
  hideTabToggler?: boolean;
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const MachineConfigAlerts = ({
  hasWidgets,
  hideTabToggler,
  isDirty,
  setIsDirty
}: Props): ReactElement => {
  return (
    <Container>
      {/* TODO: first we have to update API for config alert tab for protein machines  */}
      <div></div>
    </Container>
  );
};

export default MachineConfigAlerts;
