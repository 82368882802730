import React, { useState } from 'react';
import { D3ChartsProps } from '../D3Charts.types';
import { BasePieChart } from './BasePieChart';
import { DimensionsContainer } from 'components';
import { DefaultTooltipContainer } from '../Tooltips';
import D3PieChart from './D3PieChart';

export interface BaseProgressChartProps {
  progress?: number | string;
}

export interface D3ProgressPieChartProps extends D3ChartsProps, BaseProgressChartProps {}

export const D3ProgressPieChart = ({
  width,
  height,
  format,
  TooltipComponent,
  progress,
  ...props
}: D3ProgressPieChartProps): JSX.Element => {
  height = Number(height);
  width = Number(width);

  // start at zero
  progress = progress || 0;

  // set defaults dims
  width = width || height || 180;
  height = width;

  const [tooltip, setTooltip] = useState<Record<string, unknown> | undefined>(undefined);

  const handleHover = (type: string, options?: Record<string, unknown>) => {
    if (type === 'exit') return setTooltip(undefined);

    const width = (options?.width || 0) as number;
    const height = (options?.height || width) as number;
    // get the center coords of the slice
    const [left, top] = options?.centerVal as [number, number];

    setTooltip({
      // since the center of the chart is the 0, 0 mark
      // we need to do math to figure out the position of the tooltip
      // base on the wrapping container and send the px values
      left: left + width / 2,
      top: top + height / 2,
      ...(options as Record<string, unknown>)
    });
  };

  return (
    <DimensionsContainer
      // container props
      {...{
        // add custom class name for higher level style targeting
        className: 'd3-pie-chart-wrapper',
        // manually set fixed width and height values since this is a pie chart
        dims: { width, height }
      }}
      // send the component to the container to get actual dims
      Component={(dims) => (
        <>
          {
            // add tooltip if there is formatting or a custom tooltip component
            (TooltipComponent || format?.tooltip) && (
              <DefaultTooltipContainer
                {...tooltip}
                {...{ TooltipComponent, closeTooltip: () => setTooltip(undefined) }}
              >
                {format?.tooltip && format.tooltip(tooltip?.data as Record<string, unknown>)}
              </DefaultTooltipContainer>
            )
          }
          <BasePieChart
            {...{
              width: dims.width,
              height: dims.height,
              progress,
              checkIfSelected: () => true,
              handleHover: (type, data) => handleHover(type, { ...data, width, height }),
              ...props
            }}
          />
        </>
      )}
    />
  );
};

export default D3PieChart;
