import React, { useMemo } from 'react';
import loadable from '@loadable/component';
import { MachineViewContainer } from './MachineView.elements';
import { useMachineInfo } from '../providers';
import { showCounters } from '../testing_settings';
// import { RenderViewComponent } from 'helpers/machine';

// const AsepticView = loadable(() => import('./aseptic/AsepticView'));
// const DSIView = loadable(() => import('./dsi/DsiView'));
// const ProsealView = loadable(() => import('./proseal/ProsealView'));
const ProteinView = loadable(() => import('./protein/ProteinView'));

let MachineView_counter = 0;
export const MachineView = (): JSX.Element => {
  ++MachineView_counter;

  if (showCounters) console.log({ MachineView_counter });

  return (
    <MachineViewContainer className="machine-view-container">
      <PageRoutes />
    </MachineViewContainer>
  );
};

let MachineView_PageRoutes_counter = 0;
const PageRoutes = () => {
  // get the business unit to build the redirect string based on routing, this can be wiped once all bus use the same routing
  let { businessUnit } = useMachineInfo() as { businessUnit: number | string };

  // cache the route
  const CachedRoute = useMemo(() => {
    ++MachineView_PageRoutes_counter;
    if (businessUnit === 'pemea') businessUnit = 'protein';
    // here is where we start to detect the BUs and load them accordingly
    if (businessUnit === 'protein') return <ProteinView />;
    if (businessUnit === 'dsi') return DSIViewComponent();
    if (businessUnit === 'aseptic') return AsepticViewComponent();
    if (businessUnit === 'proseal') return ProsealViewComponent();
    return <div>MachineView 404: invalid business unit in MachineView PageRoutes</div>;
  }, [businessUnit]);

  if (showCounters) console.log({ MachineView_PageRoutes_counter });

  return CachedRoute;
};
