import React from 'react';
import { Container, ProgressBar, StepItem, StepItemContent } from './MultiStep.elements';
import { useTranslation } from 'react-i18next';
import { Typography } from 'components';
import theme from 'themes';

interface StepType {
  name?: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  icon?: any;
}
interface MultiStepProps {
  steps?: StepType[];
  currentStep?: number;
  handlePress?: (index: number) => void;
  isEmpty?: boolean;
}
const MultiStepIndicator = ({
  steps,
  currentStep = 0,
  handlePress,
  isEmpty
}: MultiStepProps): JSX.Element => {
  const { t } = useTranslation(['fpns']);

  return (
    <Container margin={'0 2.375rem'}>
      {steps?.map((step, i) => {
        const { name = '', icon: Icon } = step;
        const isPrev = i < currentStep;
        const active = isEmpty ? false : i === currentStep;

        return (
          <>
            <StepItem
              onClick={() => handlePress && handlePress(i)}
              color={active || isPrev ? theme.colors.primaryBlue5 : theme.colors.mediumGrey4}
              key={name}
            >
              <StepItemContent>
                {Icon && (
                  <Icon
                    width={30}
                    color={active || isPrev ? theme.colors.primaryBlue5 : theme.colors.mediumGrey4}
                  />
                )}
                <Typography size={'1rem'} weight="medium" mb={0}>
                  {t(name)}
                </Typography>
              </StepItemContent>
            </StepItem>
            {i !== steps.length - 1 && (
              <ProgressBar className="animate" isPrev={isPrev} active={active}></ProgressBar>
            )}
          </>
        );
      })}
    </Container>
  );
};

export default MultiStepIndicator;
