import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import { Typography } from 'components';
import { BackBtn } from '..';
import { IcoLeftArrow } from 'icons/IcoLeftArrow';
import { IcoClose } from 'icons/IcoClose';

interface MaintenanceEventDetailHeaderProps {
  maintenanceEventFlyoutOpen: boolean;
  bgColor: string;
  title: string;
  onClose: () => void;
  onHeaderClick: () => void;
  children?: JSX.Element | boolean;
}

const Container = styled.div<{ bgColor?: string }>`
  background-color: ${({ bgColor, theme }) => (bgColor ? bgColor : theme.colors.lightGrey1)};
  > * + * {
    margin-top: 0.875rem;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

// TODO - confirm that this is correct. Currently just adding a 'X' close button here as there is no
// other obvious way to close the flyout otherwise
const CloseButton = styled.button`
  background: inherit;
  border: none;
  cursor: pointer;
  padding: 0 0.5rem;
`;
const HeaderButtonContainer = styled.div`
  display: flex;
  padding: 0 1rem;
`;
const TitleText = styled.span``;

const MaintenanceEventDetailHeader = ({
  maintenanceEventFlyoutOpen,
  bgColor,
  onClose,
  title,
  onHeaderClick,
  children
}: MaintenanceEventDetailHeaderProps): ReactElement => {
  const theme = useTheme();
  return (
    <Container bgColor={bgColor}>
      <Header
        style={{
          padding: !maintenanceEventFlyoutOpen ? '0rem' : '1rem',
          cursor: !maintenanceEventFlyoutOpen ? 'auto' : 'pointer'
        }}
      >
        <Typography onClick={onHeaderClick} as="h3" mb={0} size={'1.5rem'} weight="bold">
          {/* {maintenanceEventFlyoutOpen && (
            <FontAwesomeIcon icon={faChevronLeft} color={theme.colors.darkGrey} />
          )} */}
          <TitleText>{title}</TitleText>
        </Typography>
        <CloseButton onClick={onClose}>{IcoClose()}</CloseButton>
      </Header>
      <HeaderButtonContainer>
        {maintenanceEventFlyoutOpen && (
          <BackBtn onClick={onHeaderClick}>
            <IcoLeftArrow />
            <Typography mb={0} color={theme.colors.primaryBlue5}>
              Back
            </Typography>
          </BackBtn>
        )}
        {children}
        <br />
      </HeaderButtonContainer>
    </Container>
  );
};

export default MaintenanceEventDetailHeader;
