import React, { ReactNode } from 'react';
import { CellValueContainer } from './CellValues.elements';
import { useTranslation } from 'react-i18next';

export interface CellValuesProps {
  title?: ReactNode;
  value?: ReactNode;
  label?: ReactNode;
  color?: string;
  format?: {
    innerTitle?: (data: Record<string, unknown>) => JSX.Element;
    innerValue?: (data: Record<string, unknown>) => JSX.Element;
    innerLabel?: (data: Record<string, unknown>) => JSX.Element;
  };
}

export const CellValues = ({
  title,
  value,
  label,
  format,
  color
}: CellValuesProps): JSX.Element => {
  const { t } = useTranslation(['mh']);

  if (typeof title === 'string') title = t(title.toLowerCase() as string);
  if (typeof label === 'string') label = t(label.toLowerCase() as string);

  // format after translation
  if (format?.innerTitle) title = format?.innerTitle({ title, value, label });
  if (format?.innerValue) value = format?.innerValue({ title, value, label });
  if (format?.innerLabel) label = format?.innerLabel({ title, value, label });

  return (
    <CellValueContainer>
      {title && <div className="cell-value__title">{title}</div>}
      {value && <div className="cell-value__value">{value}</div>}
      {label && (
        <div className="cell-value__label_container">
          <div className="cell-value___label">
            <div className="cell-value__label_color_dot" style={{ backgroundColor: color }}></div>
            <span className="cell-value__label_span"> {label}</span>
          </div>
        </div>
      )}
    </CellValueContainer>
  );
};
