import React, { useCallback, useState } from 'react';
import { Modal } from 'components';
import {
  ClickOrDragText,
  DragPromptWrapper,
  FileImage,
  FileTypeAndSizeText,
  ImageDragInBox,
  UploaderWrapper
} from 'pages/MachineManagement/MachineManagementPopup/MachineImageUploaderStyledComponents';
import UploadIcon from '../../../../../img/Upload Icon.svg';
import { useDropzone } from 'react-dropzone';
import { ModalSize } from 'types';

interface ImportProps {
  onFileChange?: (file: File | null) => void;
  setShowImportUsersModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const ImportUsers = ({ onFileChange, setShowImportUsersModal }: ImportProps): JSX.Element => {
  const [showFileDropZone, setShowFileDropZone] = useState<boolean>(true);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileChange?.(acceptedFiles[0]);
  }, []);
  const closeModal = (): void => {
    setShowFileDropZone(false);
    setShowImportUsersModal?.(false);
  };
  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    onDrop: onDrop,
    noClick: true,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx', '.xlsm']
    },
    multiple: false,
    maxFiles: 1
  });
  return (
    <>
      <Modal
        title=""
        allowContentScroll
        onClose={closeModal}
        showCloseButton={false}
        size={ModalSize.XSMALL_AUTO_HEIGHT}
        visible={showFileDropZone}
      >
        <UploaderWrapper>
          <ImageDragInBox {...getRootProps()} isDragAccept={isDragAccept}>
            <input {...getInputProps()} />
            <DragPromptWrapper onClick={open}>
              <FileImage src={UploadIcon} />
              <ClickOrDragText>
                <span style={{ color: 'blue' }}>Click</span> or drag file here
              </ClickOrDragText>
              <FileTypeAndSizeText>.xls, .xlsx, .xlsm, File size (500 kB max)</FileTypeAndSizeText>
            </DragPromptWrapper>
          </ImageDragInBox>
        </UploaderWrapper>
      </Modal>
    </>
  );
};

export default ImportUsers;
