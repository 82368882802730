import React from 'react';

export const PencilIcon = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="
    http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2677 3.73223L20.692 3.30797L20.2677 3.73223ZM6.5 21.0355V21.6355C6.65913 21.6355 6.81174 21.5723 6.92426 21.4597L6.5 21.0355ZM3 21.0355H2.4C2.4 21.3668 2.66863 21.6355 3 21.6355V21.0355ZM3 17.4644L2.57574 17.0401C2.46321 17.1527 2.4 17.3053 2.4 17.4644H3ZM17.1564 4.1565C17.8984 3.4145 19.1015 3.4145 19.8434 4.1565L20.692 3.30797C19.4813 2.09734 17.5185 2.09734 16.3079 3.30797L17.1564 4.1565ZM19.8434 4.1565C20.5854 4.89849 20.5854 6.10151 19.8434 6.8435L20.692 7.69203C21.9026 6.48141 21.9026 4.51859 20.692 3.30797L19.8434 4.1565ZM19.8434 6.8435L6.07574 20.6112L6.92426 21.4597L20.692 7.69203L19.8434 6.8435ZM6.5 20.4355H3V21.6355H6.5V20.4355ZM16.3079 3.30797L2.57574 17.0401L3.42426 17.8887L17.1564 4.1565L16.3079 3.30797ZM2.4 17.4644V21.0355H3.6V17.4644H2.4ZM14.8079 5.6565L18.3434 9.19203L19.192 8.3435L15.6564 4.80797L14.8079 5.6565Z"
      fill="#0076CC"
    />
  </svg>
);
