import React from 'react';

export const IcoFilter = ({
  color,
  width,
  height
}: {
  color?: string;
  width?: string;
  height?: string;
}): JSX.Element => {
  color = color || '#0076CC';
  return (
    <svg
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 14 14"
      fill="none"
      className="icon icon--filter"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2C1 1.44772 1.44772 1 2 1H12C12.5523 1 13 1.44772 13 2V3.25245C13 3.51767 12.8946 3.77202 12.7071 3.95956L8.62623 8.04044C8.43869 8.22798 8.33333 8.48233 8.33333 8.74755V10.3333L5.66667 13V8.74755C5.66667 8.48233 5.56131 8.22798 5.37377 8.04044L1.29289 3.95956C1.10536 3.77202 1 3.51767 1 3.25245V2Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
