// 3rd party libs
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Components
import { DashboardWidget } from 'components';

// Types
import { AlarmLocation } from 'types/machine-health/alarms';
import {
  AlertAcknowledgeStatus,
  AlertConfigAlertLocation,
  AlertLocation,
  AlertStatus
} from 'types/machine-health/alerts';

// Api
import {
  useGetAlertsByMachineIdQuery,
  useGetMachineAlarmsQuery,
  useGetMachineDataScienceAlertsQuery,
  useGetThresholdAlertsQuery
} from 'api';

// Helpers
import { toISO8601 } from 'helpers';
import { useTimeZone } from 'providers';
import { ActiveIssues } from '../IssuesTable';
import { FrameIcon } from 'icons/FrameIcon';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { usePermissions } from 'hooks';

const MIN_SCROLL_HEIGHT = 175;

interface Props {
  machineId: string;
  alarmsLocation: AlarmLocation;
  alertsLocation: AlertLocation;
  machineAlertsLocation: AlertConfigAlertLocation;
  startTime: Date;
  endTime: Date;
  ThresholdAlertLocation: string;
}

const Container = styled.div<{ showBottomBorder: boolean }>`
  height: 100%;

  /* We override some BaseTable styles here, as in this use case we want the table to always take up the
  full height of the container, even when there are not enough Alarm rows to enable scrolling. (The default
  behaviour of BaseTable is to shrink vertically when there are not enough rows to fill up the scroll height,
  when vertical scrollling is enabled) */
  .rc-table-body {
    min-height: ${MIN_SCROLL_HEIGHT}px;
  }

  /* Enable border on the last row when there is not enough data to make it scroll.
  Do not show when loading. */
  .rc-table-container {
    div:last-of-type {
      table {
        border-bottom: ${({ theme, showBottomBorder }) =>
          showBottomBorder && theme.colors.borders.border02.border};
      }
    }
  }
`;

const StyledTableSpacer = styled.div`
  height: 15.625rem;
`;
const NoDataText = styled.div`
  font-weight: 500;
  color: rgb(102, 102, 102);
  font-size: 1rem;
  left: 47%;
  position: absolute;
  top: 50%;
`;

/**
 * Loads Alarms and Alerts data for a given time range, machine id, and location, and passes
 * data to an instance of IssuesTable to render.
 */
const IssuesCard = ({
  machineId,
  alarmsLocation,
  alertsLocation,
  machineAlertsLocation,
  startTime,
  endTime,
  ThresholdAlertLocation
}: Props): JSX.Element => {
  const { timeZone } = useTimeZone();
  const { scopes } = usePermissions();

  const userHaveAlertsPermission = useMemo(() => {
    const alertScope = scopes.find((scope) => {
      return scope.name === 'Alert Management';
    });
    return alertScope?.read || false;
  }, [scopes]);

  const {
    data: alarmsData,
    isLoading: alarmsLoading,
    error: alarmsError
  } = useGetMachineAlarmsQuery({
    machineId,
    locationId: alarmsLocation,
    startDatetime: toISO8601(startTime, timeZone),
    endDatetime: toISO8601(endTime, timeZone)
  });
  const [filterList, setFilterList] = useState({
    filterAlert: true,
    filterAlarm: true,
    filterMachineAlert: true
  });
  const filteredAlarmsData = alarmsData?.filter((alarmData) => !alarmData.endTimestamp);

  const {
    data: alertsData,
    isLoading: alertsLoading,
    error: alertsError
  } = useGetMachineDataScienceAlertsQuery({
    machineId,
    startDatetime: toISO8601(startTime, timeZone),
    endDatetime: toISO8601(endTime, timeZone),
    location: alertsLocation
  });

  const {
    data: machineAlerts,
    error: machineAlertsError,
    isLoading: machineAlertsLoading
  } = useGetAlertsByMachineIdQuery({
    machineId,
    active: true,
    startDatetime: toISO8601(startTime, timeZone),
    endDatetime: toISO8601(endTime, timeZone),
    locations: [machineAlertsLocation]
  });

  const ApiEndPoints = useMemo(() => {
    return {
      apiStatus: AlertStatus.ACTIVE,
      secondApiStatus: AlertStatus.INACTIVE,
      acknowledgeStatus: undefined,
      secondAcknowledgeStatus: AlertAcknowledgeStatus.NOT_ACKNOWLEDGED,
      needsAcknowledge: undefined,
      secondNeedsAcknowledge: 'true'
    };
  }, []);

  const {
    data: machineThresholdAlerts,
    error: machineThresholdAlertsError,
    isLoading: machineThresholdAlertsLoading
  } = useGetThresholdAlertsQuery(
    userHaveAlertsPermission &&
      ThresholdAlertLocation?.length !== 0 &&
      process.env.REACT_APP_ENABLE_ALERTS_TAB === 'true'
      ? {
          machineId,
          widgetId: ThresholdAlertLocation,
          startDate: toISO8601(startTime, timeZone),
          endDate: toISO8601(endTime, timeZone),
          status: ApiEndPoints.apiStatus,
          acknowledgmentStatus: ApiEndPoints.acknowledgeStatus,
          needsAcknowledgment: ApiEndPoints.needsAcknowledge
        }
      : skipToken
  );

  const {
    data: secondaryApi,
    error: secondaryApiError,
    isLoading: secondaryApiLoading
  } = useGetThresholdAlertsQuery(
    userHaveAlertsPermission &&
      ThresholdAlertLocation?.length !== 0 &&
      process.env.REACT_APP_ENABLE_ALERTS_TAB === 'true'
      ? {
          machineId: machineId,
          widgetId: ThresholdAlertLocation,
          startDate: toISO8601(startTime, timeZone),
          endDate: toISO8601(endTime, timeZone),
          status: ApiEndPoints.secondApiStatus,
          acknowledgmentStatus: ApiEndPoints.secondAcknowledgeStatus,
          needsAcknowledgment: ApiEndPoints.secondNeedsAcknowledge
        }
      : skipToken
  );

  const sortedByActiveAndStartTime = useMemo(() => {
    if (machineThresholdAlerts && secondaryApi) {
      const mergedData = [...machineThresholdAlerts, ...secondaryApi];
      return mergedData;
    }
  }, [machineThresholdAlerts, secondaryApi]);

  const thresholAlertLoading =
    secondaryApiLoading || machineThresholdAlertsLoading || ThresholdAlertLocation?.length === 0;

  const isLoading = alarmsLoading || alertsLoading || machineAlertsLoading || thresholAlertLoading;

  const hasError =
    alarmsError &&
    alertsError &&
    machineAlertsError &&
    machineThresholdAlertsError &&
    secondaryApiError;
  const { t } = useTranslation(['mh']);

  const totalItems =
    (filteredAlarmsData?.length || 0) + (alertsData?.length || 0) + (machineAlerts?.length || 0);
  const showBottomBorder = isLoading ? false : totalItems < 3;

  const widgetSettings = {
    title: t('active_issues'),
    isLoading: isLoading ? true : false,
    hasError: hasError && t('Failed to load Active Issues'),
    hasCustomMenu: true,
    noPad: true,
    customDropdownItems: [
      {
        title: t('See Alarms'),
        onClick: () =>
          setFilterList({
            filterAlert: false,
            filterAlarm: true,
            filterMachineAlert: false
          }),
        icon: <FrameIcon />
      },
      {
        title: t('See Alerts'),
        onClick: () =>
          setFilterList({
            filterAlert: true,
            filterAlarm: false,
            filterMachineAlert: false
          }),
        icon: <FrameIcon />
      },
      {
        title: t('see_all'),
        onClick: () =>
          setFilterList({
            filterAlert: true,
            filterAlarm: true,
            filterMachineAlert: true
          }),
        icon: <FrameIcon />
      }
    ],
    itemCount:
      (filterList.filterAlarm && filteredAlarmsData?.length ? filteredAlarmsData?.length : 0) +
      (filterList.filterAlert && alertsData?.length ? alertsData?.length : 0) +
      (filterList.filterAlert && sortedByActiveAndStartTime?.length
        ? sortedByActiveAndStartTime?.length
        : 0) +
      (filterList.filterMachineAlert && machineAlerts?.length ? machineAlerts?.length : 0)
  };

  return (
    <Container showBottomBorder={showBottomBorder}>
      <DashboardWidget {...widgetSettings}>
        <StyledTableSpacer>
          {widgetSettings?.itemCount === 0 ? (
            <NoDataText>No Active Issues</NoDataText>
          ) : (
            <>
              <ActiveIssues
                scrollHeight={MIN_SCROLL_HEIGHT}
                alarmsData={isLoading || !filterList.filterAlarm ? [] : filteredAlarmsData}
                alertsData={isLoading || !filterList.filterAlert ? [] : alertsData}
                machineAlerts={isLoading || !filterList.filterMachineAlert ? [] : machineAlerts}
                alertsTriggers={
                  isLoading || !filterList.filterAlert ? [] : sortedByActiveAndStartTime
                }
                isLoading={isLoading}
              />
            </>
          )}
        </StyledTableSpacer>
      </DashboardWidget>
    </Container>
  );
};

export default IssuesCard;
