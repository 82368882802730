// 3rd party
import React, { ReactElement, createElement, useEffect, useState } from 'react';
import styled, { useTheme, DefaultTheme } from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

// Components
import { Checkbox, NewBaseTable, PermissionWrapper, Typography } from 'components';

// Types
import { SortState, BaseType, UserScopes, TableColumnConfigs } from 'types';
import {
  MaintenanceCreator,
  MaintenanceEvent,
  MaintenanceEventStatus,
  MaintenanceEventTableRow,
  MaintenanceScheduleTableRow
} from 'types/maintenance';
import { PermissionScopeName } from 'types/user-management';

// Helpers
// TODO - use getNextStepCell when next steps are given with events
import {
  getNextStepCell,
  getStatusCellEvent,
  maintenanceEventGroupBy,
  maintenanceScheduleGroupBy
} from './helpers';
import { toISO8601 } from 'helpers';
// Custom hooks
import { useUser } from 'selectors';
import { getCreatorCopyString, getCreatorCopyStringPlanned } from 'helpers';
import {
  useCreateMaintenanceEventsMutation,
  useClearRecentlyCompletedMaintenanceSchedulesMutation
} from 'api';
import { CellContext } from '@tanstack/react-table';
import { ColumnConfig as NewColumnConfig } from 'components/NewBaseTable/NewBaseTable';
import CheckMark from '../../img/Check circle.svg';
import XCheckMark from '../../img/X circle.svg';
import Select from 'react-select';
import CreateServiceBtn from './CreateServiceBtn';

/* Interfaces used for styling and local objects */

// The date fields that we are able to group tasks by
type GroupableDateFields = 'dueDate' | 'completionDate';

type GroupableStringFields = 'machineDescription';

interface GroupRow extends BaseType {
  rowType: 'group';
  title: string;
  countStr?: string;
  key: string;
}

type TableRow = GroupRow | MaintenanceEventTableRow;
type TableRowPlanned = GroupRow | MaintenanceScheduleTableRow;

// default sort state for new base table
const ScheduelTableQueryDefaultSortState = {
  id: 'subcomponent',
  desc: true
};

const Root = styled.div`
  width: 100%;
  height: auto;
`;

const TwoStackedTextContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '100%'};
  margin: 0;
  p {
    margin-bottom: 0;
    word-break: break-word;
  }
  p:last-child {
    margin-top: 0.125rem;
  }
  text-align: left;
`;

const Description = styled.div<{ isEmpty?: boolean }>`
  ${({ isEmpty }) => {
    return isEmpty ? 'text-align: center; width: 7.5rem' : '';
  }}
`;
const GroupRowContainer = ({ data }: { data: GroupRow }): ReactElement => {
  const theme = useTheme();
  return (
    <Typography weight="medium" mb={0}>
      {data.title} <span style={{ color: theme.colors.mediumBlue }}>{data.countStr}</span>
    </Typography>
  );
};

/* End styling */

/* Helper functions */
/**
 * Generate the rows for the table.
 * These are grouped by date if groupByDateField is set.
 * Otherwise it just returns the rows unchanged.
 */
const generateRows = (
  inputRows: MaintenanceEventTableRow[],
  groupByDateField?: GroupableDateFields,
  groupByStringField?: GroupableStringFields
) => {
  let rows: TableRow[] = [];

  if (groupByDateField) {
    // Loop through the rows and find unique days. When we find a new day, add a group row.
    let lastRoundedDay = '';
    inputRows.forEach((inputRow) => {
      const roundedDay = moment(inputRow[groupByDateField] as Date)
        .startOf('day')
        .format();

      if (lastRoundedDay !== roundedDay) {
        rows.push({
          rowType: 'group',
          title: moment(roundedDay).format('D MMM YYYY'),
          key: roundedDay
        });
        lastRoundedDay = roundedDay;
      }
      rows.push(inputRow);
    });
  } else if (groupByStringField) {
    const result = maintenanceEventGroupBy(
      inputRows,
      (ir: MaintenanceEventTableRow) => ir.machineId as string
    );
    Array.from(result.entries()).map((entry) => {
      const items = entry[1];
      const groupByName = items[0].machineDescription;
      rows.push({
        rowType: 'group',
        title: `${groupByName}`,
        countStr: ` (${items.length})`,
        key: `${groupByName}`
      });
      rows.push(...items);
    });
  } else {
    rows = inputRows;
  }
  return rows;
};

const generateRowsPlanned = (
  inputRows: MaintenanceScheduleTableRow[],
  groupByDateField?: GroupableDateFields,
  groupByStringField?: GroupableStringFields
) => {
  let rows: TableRowPlanned[] = [];

  if (groupByDateField) {
    // Loop through the rows and find unique days. When we find a new day, add a group row.
    let lastRoundedDay = '';
    inputRows.forEach((inputRow) => {
      const roundedDay = moment(inputRow[groupByDateField] as Date)
        .startOf('day')
        .format();

      if (lastRoundedDay !== roundedDay) {
        rows.push({
          rowType: 'group',
          title: moment(roundedDay).format('D MMM YYYY'),
          key: roundedDay
        });
        lastRoundedDay = roundedDay;
      }
      rows.push(inputRow);
    });
  } else if (groupByStringField) {
    const result = maintenanceScheduleGroupBy(
      inputRows,
      (ir: MaintenanceScheduleTableRow) => ir.machineId as string
    );
    Array.from(result.entries()).forEach((entry) => {
      const items = entry[1];
      const groupByName = items[0].machineDescription;
      rows.push({
        rowType: 'group',
        title: `${groupByName}`,
        countStr: ` (${items.length})`,
        key: `${groupByName}`
      });
      rows.push(...items);
    });
  } else {
    rows = inputRows;
  }
  return rows;
};

/**
 * Returns a cell for a row. If the row is a group, returns an empty cell with colspan 0.
 * Otherwise returns a cell of colspan 1 with the given child and background color.
 */

const maybeEmptyCell = (
  data: TableRow,
  child: React.ReactNode,
  bg?: string,
  textColor?: string,
  padding?: string
) => {
  let colSpan = 0;
  let children = child;

  if ((data as TableRow)?.rowType === 'group') {
    colSpan = 0;
    children = null;
  }

  return createElement(
    'p',
    {
      colSpan,
      style: {
        color: textColor,
        background: bg ? bg : 'transparent',
        margin: '0px',
        padding: padding,
        width: '300px'
      }
    },
    children
  );
};

const maybeEmptyCellPlanned = (data: TableRowPlanned, child: React.ReactNode) => {
  let children = child;
  if ((data as TableRowPlanned)?.rowType === 'group') {
    children = null;
  }
  return children;
};

// Generate the configurations for each column of this table
const generateColumnConfigs = ({
  theme,
  setSelectedPm,
  showSecondDescription = true,
  onClickCreateService,
  t
}: {
  theme: DefaultTheme;
  // TODO - use again when tasks are added
  setSelectedPm?: (pm: MaintenanceEventTableRow) => void;
  showSecondDescription?: boolean;
  onClickCreateService?: (data: MaintenanceEventTableRow) => void;
  t: TFunction<'fpns'[], undefined>;
}) => {
  return [
    {
      header: t('mm_table_machine_model') as string,
      id: 'machineDescription',
      isEnableSorting: true,
      isSelected: true,
      renderer: (value: CellContext<TableColumnConfigs, string>) => {
        const data = value.row.original;
        // If the row is of type 'group', make a cell that spans the whole width of the table
        // Otherwise return a cell of colspan 1
        let colSpan = 1;
        let children = <>{value.getValue()}</>;

        if ((data as TableRow)?.rowType === 'group') {
          colSpan = 5;
          children = <GroupRowContainer data={data as GroupRow} />;
        }
        const id = data.machineId as string;
        return createElement('span', { colSpan, id, style: { margin: '0px' } }, children);
      }
    },
    {
      header: t('mm_table_sub_component') as string,
      id: 'subcomponent',
      isEnableSorting: true,
      isSelected: true,
      renderer: (value: CellContext<TableColumnConfigs, string>) => {
        const data = value.row.original;
        // TODO - provide subcomponent when available from backend
        return maybeEmptyCell(data as TableRow, value.getValue());
      }
    },
    {
      header: t('mm_table_description') as string,
      id: 'description',
      isEnableSorting: false,
      isSelected: true,
      renderer: (value: CellContext<TableColumnConfigs, string>) => {
        const data = value.row.original;
        return maybeEmptyCell(
          data as TableRow,
          <TwoStackedTextContainer>
            <Typography>{value.getValue()}</Typography>
            {showSecondDescription && (
              <Typography
                variant="body2"
                color={
                  data.creator === MaintenanceCreator.Predictive
                    ? theme.colors.darkRed
                    : theme.colors.black
                }
              >
                {getCreatorCopyString(data as MaintenanceEvent)}
              </Typography>
            )}
          </TwoStackedTextContainer>
        );
      }
    },
    {
      header: t('detailed_instructions') as string,
      id: 'detailedInstructions',
      isEnableSorting: false,
      isSelected: true,
      renderer: (value: CellContext<TableColumnConfigs, string>) => {
        const data = value.getValue();
        const isEmpty = data === 'None';
        return <Description isEmpty={isEmpty}>{isEmpty ? 'N/A' : data}</Description>;
      }
    },
    {
      header: t('mm_table_status') as string,
      id: 'suggestedDue',
      isEnableSorting: true,
      isSelected: true,
      renderer: (value: CellContext<TableColumnConfigs, string>) => {
        const data = value.row.original as MaintenanceEventTableRow;
        const { cell, cellColor } = getStatusCellEvent(data, theme, t);
        return maybeEmptyCell(
          data as TableRow,
          cell,
          cellColor,
          '',
          `1rem .5rem 1rem ${cellColor === 'transparent' ? '0rem' : '0.75rem'}`
        );
      }
    },
    {
      header: t('mm_table_next_step') as string,
      id: 'nextStep',
      isEnableSorting: false,
      isSelected: true,
      renderer: (value: CellContext<TableColumnConfigs, string>) => {
        const data = value.row.original;
        // TODO - provide subcomponent when available from backend
        const { cell } = getNextStepCell(
          data as MaintenanceEventTableRow,
          theme,
          t,
          setSelectedPm,
          onClickCreateService
        );
        return maybeEmptyCell(data as TableRow, cell, '', theme.colors.primaryBlue5);
      }
    }
  ];
};
const getColumnCheck = (
  markAllCompleted: boolean | undefined,
  setMarkAllCompleted: React.Dispatch<React.SetStateAction<boolean>>
): ReactElement => {
  return (
    <Checkbox
      width={20}
      height={20}
      label=""
      checked={markAllCompleted}
      onChange={() => {
        setMarkAllCompleted(!markAllCompleted);
      }}
    />
  );
};
const OptionData = [
  {
    value: 'completed',
    label: 'Completed',
    Icon: <img src={CheckMark} />
  },
  {
    value: 'not_completed',
    label: 'Not Completed',
    Icon: <img src={XCheckMark} />
  }
];
const getEventStatusOptions = (): { value: string; label: string; Icon: ReactElement }[] => {
  return OptionData;
};
const generateColumnConfigsPlanned = (
  theme: DefaultTheme,
  tempEventsState: MaintenanceScheduleTableRow[] | undefined,
  handleCheckedEvent: (
    eventId: string,
    machineId: string,
    recentlyCompleted: boolean | undefined
  ) => void,
  t: TFunction<'fpns'[], undefined>,
  markAllCompleted: boolean | undefined,
  setMarkAllCompleted: React.Dispatch<React.SetStateAction<boolean>>
): NewColumnConfig[] => {
  return [
    {
      id: 'isDummy',
      header: getColumnCheck(markAllCompleted, setMarkAllCompleted),
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const data = cellValue.row.original as MaintenanceScheduleTableRow;
        const thisEvent = tempEventsState?.find((event) => event.id === data.id);
        const recentlyCompleted = thisEvent !== undefined ? thisEvent.recentlyCompleted : false;
        return maybeEmptyCellPlanned(
          data as TableRowPlanned,
          <div>
            <PermissionWrapper
              page={PermissionScopeName.MAINTENANCE_MANAGER}
              scope={UserScopes.Write}
            >
              <Checkbox
                width={20}
                height={20}
                label=""
                checked={recentlyCompleted}
                onChange={() => {
                  handleCheckedEvent(data.id, data.machineId, recentlyCompleted);
                }}
              />
            </PermissionWrapper>
          </div>
        );
      }
    },

    {
      id: 'machineDescription',
      header: t('mm_table_machine_model') as string,
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const data = cellValue.row.original as TableRowPlanned;
        let children = cellValue.getValue() as unknown as ReactElement;
        if (data?.rowType === 'group') {
          children = <GroupRowContainer data={data as GroupRow} />;
        }
        return children;
      }
    },
    {
      id: `recentlyCompleted`,
      header: t('mm_table_status') as string,
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const data = cellValue.row.original as MaintenanceScheduleTableRow;
        const thisEvent = tempEventsState?.find((event) => event.id === data.id);
        const recentlyCompleted = thisEvent !== undefined ? thisEvent.recentlyCompleted : false;

        // const data = cellValue.row.original as TableRowPlanned;
        const val = recentlyCompleted ? OptionData[0] : OptionData[1];
        const children = (
          <Select
            onChange={() => {
              handleCheckedEvent(data.id, data.machineId, recentlyCompleted);
            }}
            options={getEventStatusOptions()}
            getOptionLabel={(e) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {e.Icon}
                <span style={{ marginLeft: 5 }}>{e.label}</span>
              </div>
            )}
            value={val}
            styles={{
              control: (base) => ({
                ...base,
                border: 'none',
                boxShadow: 'none',
                width: '11rem',
                marginLeft: '-0.8rem'
              })
            }}
            menuPlacement="auto"
            menuPosition="fixed"
            components={{
              IndicatorSeparator: () => null
            }}
            isSearchable={false}
          />
        );
        return children;
      }
    },
    {
      id: 'subcomponent',
      header: t('mm_table_sub_component') as string,
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const data = cellValue.row.original as TableRowPlanned;
        return maybeEmptyCellPlanned(data, cellValue.getValue());
      }
    },
    {
      id: 'description',
      header: t('mm_table_description') as string,
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const data = cellValue.row.original as MaintenanceScheduleTableRow;
        return maybeEmptyCellPlanned(
          data as TableRowPlanned,
          <TwoStackedTextContainer width={'20rem'}>
            <Typography variant="body1">{cellValue.getValue()}</Typography>
            <Typography
              variant="body2"
              color={
                data.creator === MaintenanceCreator.Predictive
                  ? theme.colors.darkRed
                  : theme.colors.black
              }
            >
              {getCreatorCopyStringPlanned(data)}
            </Typography>
          </TwoStackedTextContainer>
        );
      }
    },
    {
      header: t('detailed_instructions') as string,
      id: 'detailedInstructions',
      isEnableSorting: false,
      isSelected: true,
      renderer: (value: CellContext<TableColumnConfigs, string>) => {
        const data = value.getValue();
        const isEmpty = data === 'None';
        return <Description isEmpty={isEmpty}>{isEmpty ? 'N/A' : data}</Description>;
      }
    }
  ];
};

export type SortableColumn = 'subcomponent' | 'machineDescription' | 'suggestedDue';
interface MaintenanceTableProps {
  data: MaintenanceEventTableRow[];
  isDataLoading?: boolean;
  groupByDateField?: GroupableDateFields;
  groupByStringField?: GroupableStringFields;
  headerBgColor?: string;
  sortState: Record<string, SortState>;
  sortColumnClicked: (column: SortableColumn) => void;
  setSelectedPm?: (pm: MaintenanceEventTableRow) => void;
  showSecondDescription?: boolean;
  onClickCreateService?: (data: MaintenanceEventTableRow) => void;
  isRunTimeTab?: boolean;
  handleServiceModal?: (value: boolean) => void;
}

interface MaintenanceTablePropsPlanned {
  data: MaintenanceScheduleTableRow[];
  isDataLoading?: boolean;
  groupByDateField?: GroupableDateFields;
  groupByStringField?: GroupableStringFields;
  headerBgColor?: string;
  selectedSecondaryTab?: number | undefined;
  sortState: Record<string, SortState>;
  sortColumnClicked: (column: SortableColumn) => void;
  setSelectedPm?: (pm: MaintenanceScheduleTableRow) => void;
  setMarkAllCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  markAllCompleted?: boolean;
  pageNumber: number;
  scheduleFrequency: number | undefined;

  isMarkerReset: boolean;
  setIsMarkerReset: React.Dispatch<React.SetStateAction<boolean>>;
  setCompletedMaintenanceEvents?: (ce: MaintenanceScheduleTableRow[]) => void;
}

interface MarkedMaintenancePage {
  pageNumber: number;
  scheduleFrequency: number | undefined;
  isFullyCompletedPage: boolean;
  isUnmarkedPage: boolean;
  items: MaintenanceScheduleTableRow[] | undefined;
}

const MaintenanceTable = ({
  data,
  groupByDateField,
  groupByStringField,
  setSelectedPm,
  showSecondDescription,
  onClickCreateService,
  isRunTimeTab,
  handleServiceModal
}: MaintenanceTableProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation(['fpns']);

  const tableData = generateRows(data, groupByDateField, groupByStringField);

  return (
    <Root>
      <NewBaseTable
        columnConfigs={generateColumnConfigs({
          theme,
          setSelectedPm,
          showSecondDescription,
          onClickCreateService,
          t
        })}
        newTableData={tableData}
        sortState={{ id: 'id', desc: true }}
        removeColumnOption={isRunTimeTab}
        tdMarginLeft="0.625rem"
        textAlign="left"
        padding="0 0 0 .6rem "
        isShowColumnOptions={!isRunTimeTab}
        shadow
        tableHeadBtn={<CreateServiceBtn handleServiceModal={handleServiceModal} />}
      />
    </Root>
  );
};

export const MaintenanceScheduleTableQuery = ({
  data,
  isDataLoading,
  groupByDateField,
  groupByStringField,
  markAllCompleted,
  pageNumber,
  scheduleFrequency,
  setMarkAllCompleted,
  isMarkerReset,
  setIsMarkerReset,
  setCompletedMaintenanceEvents
}: MaintenanceTablePropsPlanned): ReactElement => {
  const [tempEventsState, setTempEventsState] = useState<MaintenanceScheduleTableRow[]>();
  const [markedAsCompletedEvents, setMarkedAsCompletedEvents] = useState<MarkedMaintenancePage[]>(
    []
  );
  const theme = useTheme();
  const user = useUser();
  const { t } = useTranslation(['fpns']);
  const [createEvent] = useCreateMaintenanceEventsMutation();
  const [clearMaintenanceEvents] = useClearRecentlyCompletedMaintenanceSchedulesMutation();

  const tableData = generateRowsPlanned(data, groupByDateField, groupByStringField);
  useEffect(() => {
    if (
      !markedAsCompletedEvents.some(
        (e) => e.pageNumber === pageNumber && e.scheduleFrequency === scheduleFrequency
      )
    ) {
      setTempEventsState(data);
    }
  }, [tableData]);

  useEffect(() => {
    //Populate the download array with selected events on tab load.
    if (markedAsCompletedEvents && markedAsCompletedEvents.length > 0) {
      const completed: MaintenanceScheduleTableRow[] = [];
      markedAsCompletedEvents.forEach((event) => {
        if (event.items && event.scheduleFrequency === scheduleFrequency) {
          event.items.forEach((item) => {
            if (item.recentlyCompleted) {
              completed.push(item);
            }
          });
        }
      });

      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      setCompletedMaintenanceEvents!(completed);
    }
  }, [markedAsCompletedEvents]);

  useEffect(() => {
    if (markedAsCompletedEvents && markedAsCompletedEvents.length > 0) {
      const currentPage = getCurrentPage();
      if (currentPage.length === 1) {
        if (currentPage[0].items?.every((item) => item.recentlyCompleted)) {
          setMarkAllCompleted(true);
        } else {
          setMarkAllCompleted(false);
        }
        setTempEventsState(currentPage[0].items);
      }
    }
  }, [pageNumber, scheduleFrequency, markedAsCompletedEvents]);

  useEffect(() => {
    const completedEvents: MaintenanceScheduleTableRow[] = [];
    const resetEvents: MaintenanceScheduleTableRow[] = [];
    const clearedMaintenanceEventIds: string[] = [];
    if (markAllCompleted && tempEventsState) {
      tempEventsState.forEach((event) => {
        createEvent([
          {
            machineId: event.machineId,
            maintenanceScheduleId: event.id,
            status: MaintenanceEventStatus.Completed,
            creator: MaintenanceCreator.User,
            owner: user?.id,
            priority: 0,
            completion: toISO8601(new Date())
          }
        ]);
        completedEvents.push({ ...event, recentlyCompleted: true });
      });
      if (completedEvents.length > 0) {
        // setTimeout(() => {
        setTempEventsState(completedEvents);
        // }, 2000);
        const markedPage: MarkedMaintenancePage = {
          pageNumber: pageNumber,
          scheduleFrequency: scheduleFrequency,
          isFullyCompletedPage: true,
          isUnmarkedPage: false,
          items: completedEvents
        };
        let isMarked = false; //markedAsCompletedEvents.some((page): number => page.pageNumber = pageNumber);
        markedAsCompletedEvents.forEach((page, index) => {
          if (page.pageNumber === pageNumber) {
            markedAsCompletedEvents[index] = markedPage;
            isMarked = true;
          }
        });
        if (!isMarked) {
          setMarkedAsCompletedEvents([...markedAsCompletedEvents, markedPage]);
        }
      }
    } else if (tempEventsState && !isMarkerReset) {
      let allEventsMarked = false;
      if (markedAsCompletedEvents && markedAsCompletedEvents.length > 0) {
        const currentPage = getCurrentPage();
        if (currentPage[0].items?.every((item) => item.recentlyCompleted)) {
          allEventsMarked = true;
        }
      }

      if (allEventsMarked) {
        tempEventsState?.forEach((event) => {
          clearedMaintenanceEventIds.push(event.id);
          resetEvents.push({ ...event, recentlyCompleted: false });
        });
      }

      const markedPage: MarkedMaintenancePage = {
        pageNumber: pageNumber,
        scheduleFrequency: scheduleFrequency,
        isFullyCompletedPage: false,
        isUnmarkedPage: false,
        items: resetEvents
      };
      if (resetEvents.length > 0) {
        setMarkedAsCompletedEvents([markedPage]);
        setTempEventsState(resetEvents);
        clearMaintenanceEvents(clearedMaintenanceEventIds).then(() => {
          if (markedAsCompletedEvents.length > 0) {
            setMarkedAsCompletedEvents((prev) => {
              return prev.filter(
                (f) => f.pageNumber !== pageNumber && f.scheduleFrequency !== scheduleFrequency
              );
            });
          }
        });
      }
    } else if (isMarkerReset) {
      setIsMarkerReset(false);
    }
  }, [markAllCompleted]);
  const getCurrentPage = (): MarkedMaintenancePage[] => {
    const page = markedAsCompletedEvents.filter(
      (m) => m.pageNumber === pageNumber && m.scheduleFrequency === scheduleFrequency
    );
    return page;
  };
  const handleCheckedEvent = (
    eventId: string,
    machineId: string,
    recentlyCompleted: boolean | undefined
  ) => {
    if (!recentlyCompleted) {
      createEvent([
        {
          machineId,
          maintenanceScheduleId: eventId,
          status: MaintenanceEventStatus.Completed,
          creator: MaintenanceCreator.Manufacturer,
          owner: user?.id,
          priority: 0,
          completion: toISO8601(new Date())
        }
      ]).then(() => {
        const newEvents = tempEventsState?.map((event) =>
          event.id === eventId ? { ...event, recentlyCompleted: true } : event
        );

        setTempEventsState(newEvents);
        if (markedAsCompletedEvents.length === 0) {
          const markedPage: MarkedMaintenancePage = {
            pageNumber: pageNumber,
            scheduleFrequency: scheduleFrequency,
            isFullyCompletedPage: false,
            isUnmarkedPage: false,
            items: newEvents
          };
          setMarkedAsCompletedEvents([...markedAsCompletedEvents, markedPage]);
        } else {
          setMarkedAsCompletedEvents([
            ...updateModifiedPage(markedAsCompletedEvents, eventId, true, newEvents)
          ]);
        }
      });
    } else {
      clearMaintenanceEvents([eventId]).then(() => {
        const newEvents = tempEventsState?.map((event) =>
          event.id === eventId ? { ...event, recentlyCompleted: false } : event
        );
        setTempEventsState(newEvents);
        setMarkedAsCompletedEvents([
          ...updateModifiedPage(markedAsCompletedEvents, eventId, false, newEvents)
        ]);

        for (let i = 0; i < markedAsCompletedEvents.length; i++) {
          const isMarked = markedAsCompletedEvents[i].items?.some((s) => s.recentlyCompleted);
          if (!isMarked) {
            markedAsCompletedEvents[i].isUnmarkedPage = true;
            break;
          }
        }
        setMarkedAsCompletedEvents(markedAsCompletedEvents.filter((f) => !f.isUnmarkedPage));
      });
    }
  };

  const updateModifiedPage = (
    pages: MarkedMaintenancePage[],
    eventId: string,
    toggleState: boolean,
    newEvents: MaintenanceScheduleTableRow[] | undefined
  ): MarkedMaintenancePage[] => {
    let foundEventPage = false;
    if (pages) {
      pages.forEach((m) => {
        if (m.pageNumber == pageNumber && m.scheduleFrequency === scheduleFrequency) {
          foundEventPage = true;
          m.items = m.items?.map((event) =>
            event.id === eventId ? { ...event, recentlyCompleted: toggleState } : event
          );
        }
      });
      if (!foundEventPage) {
        const markedPage: MarkedMaintenancePage = {
          pageNumber: pageNumber,
          scheduleFrequency: scheduleFrequency,
          isFullyCompletedPage: false,
          isUnmarkedPage: false,
          items: newEvents
        };
        pages = [...pages, markedPage];
      }
    }
    return pages;
  };
  return (
    <Root>
      <NewBaseTable
        newTableData={tableData}
        columnConfigs={generateColumnConfigsPlanned(
          theme,
          tempEventsState,
          handleCheckedEvent,
          t,
          markAllCompleted,
          setMarkAllCompleted
        )}
        tdMarginLeft="0.625rem"
        textAlign="left"
        padding="0 0 0 .6rem"
        removeColumnOption
        sortState={ScheduelTableQueryDefaultSortState}
        isShowColumnOptions={true}
        isDataLoading={isDataLoading}
      />
    </Root>
  );
};

export default MaintenanceTable;
