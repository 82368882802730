// EXAMPLE
// '/fleet /page    /id                                    /version /view           /tab        /subTab'
// /fleet  /machine /dce5e9f4-bf5c-4b85-b506-e6183f2cd225  /v2      /machine-health /changeover /single-session
const fleetPageVersion = 'v2';
export const fleetPageVersionRoute = fleetPageVersion ? 'fleet/page/version' : 'fleet/page';

// prepend this path to your links to navigate to the correct page using the automatic routing based on version (v2, demo, etc)
// example, if you wait to go to the overview/steps-over-time  url.... use the path `${fleetPageVersionPath}/${id}/overview/steps-over-time`
// just sendt he machine-id with the link
export const fleetPageVersionPath = fleetPageVersion
  ? `fleet/machine/${fleetPageVersion}`
  : 'fleet/machine';

export const fleetPageRoute = `${fleetPageVersionRoute}/id/view/tab/subTab/selection`;
