import React, { ReactNode, createContext, useContext } from 'react';
import { useGetMachineByIdQuery } from 'api';
import { buID } from '../settings/buDefaultSettings';
import { DataLoader } from 'common/components/DataLoader/DataLoader';
import { showCounters } from '../testing_settings';
import { useFleetRouter } from '../hooks/useFleetRouter';

export interface UseMachineInfoProps {
  isLoading?: boolean;
  hasMessage?: ReactNode;
  hasError?: ReactNode;
  timeZone?: string;
  businessUnit?: string;
  [key: string]: unknown;
}

const MachineInfoContext = createContext<UseMachineInfoProps>({
  isLoading: true
});

export const useMachineInfo = (): UseMachineInfoProps => useContext(MachineInfoContext);

interface Props extends UseMachineInfoProps {
  children?: ReactNode | ReactNode[];
  businessUnit?: string;
}

let useMachineInfo_GetMachineInfo_counter = 0;

const GetMachineInfo = () => {
  const [{ id }] = useFleetRouter();
  const { data, isLoading, error } = useGetMachineByIdQuery(id as string);
  ++useMachineInfo_GetMachineInfo_counter;
  if (showCounters) console.log({ useMachineInfo_GetMachineInfo_counter });

  if (error) return { error };

  return { data, isLoading, machineId: id };
};

let useMachineInfo_MachineInfoProvider_counter = 0;
export const MachineInfoProvider = ({ children, businessUnit }: Props): JSX.Element => {
  ++useMachineInfo_MachineInfoProvider_counter;

  const { data, error, machineId } = GetMachineInfo();

  const isLoading = !data && !error ? true : false;

  const buName = buID?.[data?.businessUnit as number] || businessUnit;
  const timeZone: string | undefined = data?.timezone;
  const hasError: ReactNode = error ? true : false;

  if (showCounters) console.log({ useMachineInfo_MachineInfoProvider_counter });

  return (
    <MachineInfoContext.Provider value={{ ...data, timeZone, businessUnit: buName, machineId }}>
      <DataLoader {...{ isLoading, hasError }} Component={children} />
    </MachineInfoContext.Provider>
  );
};

// demo aseptic return
export const demoData = {
  id: 'dce5e9f4-bf5c-4b85-b506-e6183f2cd225',
  parts: null,
  description: 'FNDS-Aseptic-Linear Filler-N.00312.01',
  assets: null,
  sku: 'N.00312.01',
  plantId: '00136000016vHABAA2',
  orgId: '00136000016vHABAA2',
  lineId: '341c9b3d-32d4-4d8e-8e9a-d858cab39435',
  status: 'In Production',
  purchased: null,
  installed: '2010-05-25',
  warrantyExpired: null,
  nickname: null,
  order: null,
  serialNumber: 'N.00312.01',
  businessUnit: 4,
  timezone: 'America/New_York',
  editMode: null
};
