import React from 'react';

export interface Props {
  color?: string;
}

export const UserStatusIcon = ({ color }: Props): JSX.Element => {
  color = color || '#BA4E00';

  return (
    <svg width="54" height="52" viewBox="0 0 54 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.85117 26.2697C1.85117 29.5239 2.50209 32.7463 3.76676 35.7528C5.03143 38.7593 6.88509 41.4911 9.22191 43.7922C11.5587 46.0933 14.3329 47.9186 17.3861 49.1639C20.4393 50.4093 23.7117 51.0502 27.0164 51.0502C30.3212 51.0502 33.5936 50.4093 36.6468 49.1639C39.7 47.9186 42.4742 46.0933 44.811 43.7922C47.1478 41.4911 49.0014 38.7593 50.2661 35.7528C51.5308 32.7463 52.1817 29.5239 52.1817 26.2697C52.1817 23.0155 51.5308 19.7931 50.2661 16.7866C49.0014 13.7801 47.1478 11.0483 44.811 8.74719C42.4742 6.4461 39.7 4.62078 36.6468 3.37544C33.5936 2.1301 30.3212 1.48914 27.0164 1.48914C23.7117 1.48914 20.4393 2.1301 17.3861 3.37544C14.3329 4.62078 11.5587 6.4461 9.22191 8.74719C6.88509 11.0483 5.03143 13.7801 3.76676 16.7866C2.50209 19.7931 1.85117 23.0155 1.85117 26.2697Z"
        stroke={`${color}`}
      />
      <path
        d="M18.6281 20.7636C18.6281 22.9543 19.5119 25.0553 21.085 26.6044C22.6582 28.1535 24.7918 29.0238 27.0165 29.0238C29.2413 29.0238 31.3749 28.1535 32.948 26.6044C34.5212 25.0553 35.405 22.9543 35.405 20.7636C35.405 18.5729 34.5212 16.4719 32.948 14.9228C31.3749 13.3737 29.2413 12.5034 27.0165 12.5034C24.7918 12.5034 22.6582 13.3737 21.085 14.9228C19.5119 16.4719 18.6281 18.5729 18.6281 20.7636Z"
        stroke={`${color}`}
      />
      <path
        d="M10.7091 45.1281C11.4011 42.8599 12.8173 40.8719 14.7474 39.4588C16.6775 38.0458 19.0188 37.283 21.4239 37.2837H32.6085C35.0167 37.2829 37.3608 38.0475 39.2923 39.4638C41.2238 40.8801 42.6395 42.8725 43.3289 45.1446"
        stroke={`${color}`}
      />
    </svg>
  );
};
