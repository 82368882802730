// 3rd party libs
import React, { ReactElement } from 'react';
import theme from 'themes';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Components
import { Input, InputLabel, RadioButton, Row } from 'components';

interface Props {
  groupInformation: { name: string; isInternal: boolean };
  onGroupNameChange: (name: string) => void;
  onGroupAccessChange: (isInternal: boolean) => void;
}

const MandatoryIndicator = styled.span`
  color: #b62c10;
`;
const StaticValidationText = styled.div`
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.lightGrey8};
  margin-top: -0.6rem;
  padding-bottom: 1rem;
`;
export const GroupHeaderPropt = styled.div`
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.text.lightBlack};
  margin-bottom: 2.25rem;
  padding-bottom: 0.4rem;
  border-bottom: 0.063rem solid ${({ theme }) => theme.colors.tableborder};
  padding-bottom: 0.313rem;
`;
export const SectionRule = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 0.063rem solid ${theme.colors.lightGrey4};
  margin-bottom: 1.063rem;
  margin-top: 1rem;
`;
const GroupInfo = ({
  onGroupNameChange,
  onGroupAccessChange,
  groupInformation
}: Props): ReactElement => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <GroupHeaderPropt>{t('by_adding_new_group')}</GroupHeaderPropt>
      <InputLabel mandatory={true} color={theme.colors.text.lightBlack}>
        {t('group_name')}
      </InputLabel>
      <Input
        maxLength={50}
        placeholder="Enter group name"
        value={(groupInformation && groupInformation.name) ?? ''}
        onChange={(e: { target: { value: string } }) => {
          !(e.target.value.at(-1) === ' ' && groupInformation.name.at(-1) === ' ') &&
            onGroupNameChange(e.target.value);
        }}
        style={{ marginBottom: '1rem' }}
        onBlur={() => {
          onGroupNameChange(groupInformation.name.trim());
        }}
      />
      <StaticValidationText>{t('character_limit')}: 50</StaticValidationText>
      <InputLabel color={theme.colors.text.lightBlack}>
        {t('access_group')} <MandatoryIndicator>*</MandatoryIndicator>
      </InputLabel>
      <SectionRule></SectionRule>
      <Row>
        <RadioButton
          label="External JBT"
          labelWeight={400}
          labelColor={theme.colors.text.lightBlack}
          checked={!groupInformation.isInternal}
          onChange={() => {
            onGroupAccessChange(!groupInformation.isInternal);
          }}
        />
        <RadioButton
          label="Internal JBT"
          checked={groupInformation.isInternal}
          onChange={() => {
            onGroupAccessChange(!groupInformation.isInternal);
          }}
        />
      </Row>
      <SectionRule></SectionRule>
    </>
  );
};

export default GroupInfo;
