import { generateZonedTimeframe } from 'common/helpers/dateAndTimeHelpersV2';
import { useEffect, useState } from 'react';
import { useWidgetDateRange } from 'common/ui';
export interface UseLiveTimeFrameOptionsProps {
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
  weeks?: number;
  months?: number;
  startOf?: 'day' | 'week' | 'month' | 'year';
}

export const useLiveTimeFrame = (
  refreshInterval: number,
  { minutes, hours, days, seconds, startOf }: UseLiveTimeFrameOptionsProps,
  timeZone?: string
): {
  startTime: string;
  endTime: string;
  timeZone: string;
} => {
  refreshInterval = refreshInterval || 30000;

  const { setDateRange, utcDateRange, isInit, ...rest } = useWidgetDateRange();

  timeZone = timeZone || rest.timeZone;

  const [localDateRange, setLocalDateRange] = useState<
    { startTime?: string; endTime?: string } | undefined
  >(() =>
    generateZonedTimeframe(timeZone || 'UTC', {
      subtractHours: hours,
      subtractMinutes: minutes,
      subtractDays: days,
      subtractSeconds: seconds,
      startOf
    })
  );

  const dateRange = (localDateRange || utcDateRange) as { startTime: string; endTime: string };

  useEffect(() => {
    const updater = setInterval(() => {
      const newRange = generateZonedTimeframe(timeZone || 'UTC', {
        subtractHours: hours,
        subtractMinutes: minutes,
        subtractDays: days,
        subtractSeconds: seconds,
        startOf
      });

      if (newRange?.endTime === dateRange?.endTime) return;

      if (isInit) setDateRange?.(newRange);
      else setLocalDateRange?.(newRange);
    }, refreshInterval);
    return () => updater && clearInterval(updater);
  }, [utcDateRange]);

  return { ...dateRange, timeZone };
};
