import React from 'react';
import theme from 'themes';
import styled from 'styled-components';

//icons imports
import IcoSuccess from 'icons/IcoSuccess';
import IcoWarningOutline from 'icons/IcoWarningOutline';
import { IcoClose } from 'icons/IcoClose';
import IcoCross2 from 'icons/IcoCross2';
import IcoInfo2 from 'icons/IcoInfo2';

interface IBanner {
  type: 'success' | 'info' | 'warning' | 'error';
  closable?: boolean;
  message: React.ReactNode;
  showIcon?: boolean;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const iconMapFilled = {
  success: <IcoSuccess height={20} width={20} aria-label="success" />,
  info: <IcoInfo2 height={20} width={20} aria-label="info" />,
  error: <IcoCross2 height={18} width={18} aria-label="error" />,
  warning: <IcoWarningOutline height={20} width={20} aria-label="warning" />
};

const BannerWrapper = styled.div<{ type?: IBanner['type'] }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 0.063rem solid
    ${({ type }) =>
      type === 'info'
        ? theme.colors.primaryBlue5
        : type === 'error'
        ? theme.colors.text.error
        : type === 'warning'
        ? theme.colors.warning
        : theme.colors.green3};
  padding: 0.75rem;
  border-radius: 0.5rem;
  gap: 0.75rem;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  background-color: ${({ type }) =>
    type === 'info'
      ? theme.colors.bannerInfo
      : type === 'error'
      ? theme.colors.bannerError
      : type === 'warning'
      ? theme.colors.bannerWarning
      : theme.colors.bannerSuccess};
  p {
    margin: 0;
    height: unset;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span:nth-child(3) {
    cursor: pointer;
    margin-left: auto;
  }
`;

const Banner: React.FC<IBanner> = (props) => {
  const { type, closable = true, message, showIcon, ...restProps } = props;

  const [closed, setClosed] = React.useState(false);

  // banner mode defaults to Icon
  const isShowIcon = showIcon === undefined ? true : showIcon;

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    setClosed(true);
    props.onClose?.(e);
  };

  return (
    <>
      {!closed ? (
        <BannerWrapper type={type} {...restProps}>
          {isShowIcon ? <span aria-label={`icon-${type}`}>{iconMapFilled[type]}</span> : null}
          <p aria-label={'banner-message'}>{message}</p>
          {closable ? (
            <span onClick={handleClose} aria-label={'banner-close-icon'}>
              {IcoClose()}
            </span>
          ) : null}
        </BannerWrapper>
      ) : null}
    </>
  );
};

export default Banner;
