import { css } from 'styled-components';
import iconCheckMark from 'img/IconCheckMark.svg';

// this is styling used for styling the containers for dropdowns and tooltips
export const BoxShadowContainerStyles = css`
  background-color: white;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
`;
// this applies to the container for the dropdown select
export const DropdownSelectContainerStyles = css`
  ${BoxShadowContainerStyles};
  margin: 0;
  padding: 0;
  list-style: none;
  min-width: max-content;
  flex-direction: column;
  z-index: 1;

  button {
    padding: 0.5em 1em;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1em;
    min-width: max-content;
    width: 100%;
    text-align: left;

    &:before {
      content: '';
      display: block;
      width: 1.2em;
      height: 1.2em;
      border-radius: 50%;
      margin-right: 0.5em;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.is-selected:before {
      background-image: url(${iconCheckMark});
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;
