import React from 'react';
import { PageHeaderContainer } from './PageHeader.elements';
import { MachineViewBreadCrumbs } from '../BreadCrumbs/MachineViewBreadCrumbs';
import { MachineViewInfoBar } from '../MachineInfoBar/MachineViewInfoBar';

export const PageHeader = (): JSX.Element => {
  return (
    <PageHeaderContainer>
      <MachineViewBreadCrumbs />
      <MachineViewInfoBar />
    </PageHeaderContainer>
  );
};
