import styled from 'styled-components';

export const ChartLegendContainer = styled.ul`
  display: flex;
  min-width: max-content;
  padding: 0;
  margin: 0.7em 0 0 0;
  list-style: none;
  font-size: 0.9em;
  gap: 1em;

  &.has-buttons {
    button {
      cursor: pointer;
    }
  }

  &.is-vertical {
    flex-direction: column;
    padding-top: 0;
  }

  &.widget-ui__sub-header {
    padding: 1em;
  }
`;

export const ItemContainer = styled.li<{ color?: string }>`
  min-width: max-content;
  text-transform: capitalize;
  border: none;
  background-color: transparent;
  margin: 0;

  &.is-not-selected {
    opacity: 0.5;
  }

  .label {
    &.has-value {
      display: flex;
      gap: 0.35em;

      .value {
        font-size: 0.9em;
        opacity: 0.8;
      }
    }
  }

  ${({ color }) => {
    if (color)
      return `

      display: grid;
      gap: .35em;
      grid-template-columns: auto 1fr;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        width: .8em;
        height: .8em;
        border-radius: 50%;

        background-color: ${color};
      }
    `;
  }}
`;
