import React from 'react';
import type { FC, SVGProps } from 'react';

export const IcoInfo2: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    className="icon icon--info"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.66667 10.6667H8V8H7.33333M8 5.33333H8.00667M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
      stroke={props.color ? props.color : '#0076CC'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcoInfo2;
