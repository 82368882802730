import React from 'react';

export const IcoTimer = ({
  width = '24',
  color
}: {
  width?: string;
  color?: string;
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1516_31935)">
        <path
          d="M14 8C14 6.81337 13.6481 5.65339 12.9889 4.66672C12.3297 3.68005 11.3927 2.91102 10.2964 2.45685C9.20013 2.00269 7.9938 1.88379 6.82995 2.11519C5.6661 2.3466 4.597 2.91791 3.75783 3.75689C2.91867 4.59587 2.34712 5.66485 2.11545 6.82864C1.88379 7.99244 2.00242 9.1988 2.45634 10.2952C2.91026 11.3916 3.67909 12.3287 4.66561 12.9882C5.65213 13.6476 6.81204 13.9997 7.99867 14"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1932 10.5193C12.3069 10.0509 12.9731 10.0509 13.0868 10.5193C13.1602 10.8218 13.5069 10.9654 13.7727 10.8034C14.1843 10.5526 14.6554 11.0236 14.4046 11.4352C14.2426 11.7011 14.3861 12.0477 14.6887 12.1212C15.1571 12.2349 15.1571 12.901 14.6887 13.0147C14.3861 13.0882 14.2426 13.4348 14.4046 13.7007C14.6554 14.1123 14.1843 14.5833 13.7727 14.3325C13.5069 14.1705 13.1602 14.3141 13.0868 14.6167C12.9731 15.0851 12.3069 15.0851 12.1932 14.6167C12.1198 14.3141 11.7731 14.1705 11.5072 14.3325C11.0956 14.5833 10.6246 14.1123 10.8754 13.7007C11.0374 13.4348 10.8938 13.0882 10.5913 13.0147C10.1229 12.901 10.1229 12.2349 10.5913 12.1212C10.8938 12.0477 11.0374 11.7011 10.8754 11.4352C10.6246 11.0236 11.0956 10.5526 11.5072 10.8034C11.7731 10.9654 12.1198 10.8218 12.1932 10.5193Z"
          stroke={color || '#0076CC'}
          strokeWidth="0.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.44 12.568C13.44 13.0098 13.0818 13.368 12.64 13.368C12.1982 13.368 11.84 13.0098 11.84 12.568C11.84 12.1261 12.1982 11.768 12.64 11.768C13.0818 11.768 13.44 12.1261 13.44 12.568Z"
          stroke={color || '#0076CC'}
          strokeWidth="0.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 4.6665V7.99984L9.33333 9.33317"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1516_31935">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
