import { parseISO, startOfWeek } from 'date-fns';
import { convertZonedTimestamptoUTCTimestamp } from './dateAndTimeHelpers';

export const groupByKey = ({
  data,
  key,
  trimSpaces
}: {
  data?: Record<string, unknown>[];
  key?: string;
  trimSpaces?: boolean;
}): Record<string, Record<string, unknown>[]> => {
  if (!data || !key) return {};
  return data?.reduce(
    (acc: Record<string, Record<string, unknown>[]>, item: Record<string, unknown>) => {
      let groupKey = item?.[key] as string;

      if (trimSpaces) groupKey = groupKey?.split(' ').join('');

      // make sure the groupkey exists
      if (groupKey) {
        if (!acc[groupKey]) acc = { ...acc, [groupKey]: [] };
        acc[groupKey] = [item, ...acc[groupKey]];
      }
      return acc;
    },
    {}
  );
};

export const groupByWeek = (
  dateKey: string,
  options: {
    data?: Record<string, unknown>[];
    timeZone?: string;
    groupKey?: string;
  }
): Record<string, unknown>[] | undefined => {
  const { data, timeZone } = options;
  const groupKey = options?.groupKey || dateKey;

  if (!data) return undefined;

  return data.reduce((acc: Record<string, unknown>[], item: Record<string, unknown>) => {
    const timestamp = item[dateKey] as string;

    if (timestamp) {
      const date = parseISO(timestamp);
      const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 });
      const formattedDate = convertZonedTimestamptoUTCTimestamp(
        startOfWeekDate.toISOString(),
        timeZone || 'UTC'
      );
      acc = [{ ...item, [groupKey]: formattedDate }, ...acc];
    }

    return acc;
  }, []);
};
