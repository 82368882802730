// 3rd party libraries
import React, { ReactElement, useMemo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import MaintenanceManagerIcon from 'icons/headerIcons/MaintenanceManagerIcon.svg';

// Components
import {
  Button,
  Flyout,
  Loader,
  MaintenanceEventDetail,
  PageHeader,
  PermissionWrapper,
  Typography
} from 'components';
import TopView, { TableFilterContainer } from './TopView';

// Types
import { Machine, Plant, UserScopes } from 'types';
import { CartListType } from 'types/parts/cart';
import {
  MaintenanceEvent,
  MaintenanceEventArgs,
  MaintenanceEventStatus,
  MaintenanceEventTableRow,
  MaintenanceFrequencyType,
  MaintenanceSchedule,
  MaintenanceScheduleArgs,
  MaintenanceScheduleTableRow,
  MaintenanceEventDownloadPayload,
  Tab,
  MaintenanceCreator
} from 'types/maintenance';

// API
import { useGetPlantsQuery, useDownloadSelectedPlannedMaintenanceEventsMutation } from 'api';
import { toast } from 'react-toastify';
import CreateServiceForm from './CreateServiceForm';
import { PermissionScopeName } from 'types/user-management';
import CreateServiceBtn from 'components/MaintenanceTable/CreateServiceBtn';
import DropdownTab from 'common/components/DropdownTab';
import DocumentFileDropdown from 'components/DocumentFileDropdown';

interface Props {
  isExpanded: boolean;
}

const download_selected = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_146_80946)">
      <path
        d="M9.33337 2V4.66667C9.33337 4.84348 9.40361 5.01305 9.52864 5.13807C9.65366 5.2631 9.82323 5.33333 10 5.33333H12.6667"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3334 14H4.66671C4.31309 14 3.97395 13.8595 3.7239 13.6095C3.47385 13.3594 3.33337 13.0203 3.33337 12.6667V3.33333C3.33337 2.97971 3.47385 2.64057 3.7239 2.39052C3.97395 2.14048 4.31309 2 4.66671 2H9.33337L12.6667 5.33333V12.6667C12.6667 13.0203 12.5262 13.3594 12.2762 13.6095C12.0261 13.8595 11.687 14 11.3334 14Z"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 11.3334V7.33337" stroke="#0076CC" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.33337 9.66663L8.00004 11.3333L9.66671 9.66663"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_146_80946">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Container = styled.div<Props>`
  padding: 0rem 1.5rem;
  width: ${(props) => (props.isExpanded ? '100%' : '70%')};
`;

const ColumnContainer = styled.div`
  display: flex;
`;

const DownloadTypeSelectWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-left: 1rem;
  & button {
    background-color: white;
    border: 0rem;
    margin-right: 1rem;
    box-shadow: none;
  }
  & span {
    margin-left: 0.6rem;
  }
`;
const MachineDocument = styled.div`
  position: absolute;
  bottom: -6.5rem;
  right: 0px;
  z-index: 999;
`;
const ButtonWrapper = styled.div`
  margin: 0rem 1.5rem 0.2rem;
`;
export enum TabViews {
  HighPriority,
  Planned,
  History
}
export enum SecondaryTabViews {
  RunTimeBased,
  Predictive,
  ServiceTech,
  PmPlanning,
  Reports,
  Resolved,
  Daily,
  Weekly,
  Monthly,
  Quarterly,
  HalfYearly,
  Yearly
}

const TabsContainer = styled.div`
  margin: 0 1.5rem;
  position: relative;
`;

const secondaryTabArrayHighPriority = [
  { value: SecondaryTabViews.RunTimeBased, selected: true, label: 'run_time_based' },
  { value: SecondaryTabViews.PmPlanning, selected: false, label: 'pm_planning' },
  { value: SecondaryTabViews.Reports, selected: false, label: 'reports' },
  { value: SecondaryTabViews.Predictive, selected: false, label: 'predictive' },
  { value: SecondaryTabViews.ServiceTech, selected: false, label: 'service_tech' }
];

const secondaryTabArrayHistory = [
  { value: SecondaryTabViews.Resolved, selected: true, label: 'resolved' }
];

const secondaryTabArrayPlanned = [
  { value: SecondaryTabViews.Daily, selected: true, label: 'daily' },
  { value: SecondaryTabViews.Weekly, selected: false, label: 'weekly' },
  { value: SecondaryTabViews.Monthly, selected: false, label: 'monthly' },
  { value: SecondaryTabViews.Quarterly, selected: false, label: 'quarterly' },
  { value: SecondaryTabViews.HalfYearly, selected: false, label: 'half_yearly' },
  { value: SecondaryTabViews.Yearly, selected: false, label: 'yearly' }
];

export const addMachineDescToEvents = (
  events: MaintenanceEvent[],
  givenMachines: Machine[]
): MaintenanceEvent[] => {
  return events.map((ev) => {
    return {
      ...ev,
      machineDescription:
        givenMachines.find((m) => m.id === ev.machineId)?.description || 'Machine Unavailable'
    };
  });
};

export const addMachineDescToSchedule = (
  events: MaintenanceSchedule[],
  givenMachines: Machine[]
): MaintenanceSchedule[] => {
  if (events === undefined) return [];
  return events.map((ev) => {
    return {
      ...ev,
      machineDescription:
        givenMachines.find((m) => m.id === ev.machineId)?.description || 'Machine Unavailable'
    };
  });
};

interface MaintenanceServiceDashboardContentsProps {
  machineId?: string;
}
export interface MaintenanceSchedulePageProps {
  events: MaintenanceSchedule[];
  filter: MaintenanceScheduleArgs;
}
export default function MaintenanceServiceDashboardContents({
  machineId
}: MaintenanceServiceDashboardContentsProps): ReactElement {
  // TODO - Handle errors from retrieving plants

  const [downloadSelectedMaintenanceEvents] = useDownloadSelectedPlannedMaintenanceEventsMutation();
  const [refetchEventOnUpdate, setRefetchEventOnUpdate] = useState<number>(0);
  // const [machines, setMachines] = useState<Machine[]>();

  const { t } = useTranslation(['fpns']);

  const [currentTab, setCurrentTab] = useState(TabViews.HighPriority);
  const [secondaryTab, setSecondaryTab] = useState(secondaryTabArrayHighPriority);
  const [isNewServiceEvent, setIsNewServiceEvent] = useState<boolean>(false);
  const [currentMaintenanceSchedulePage, setCurrentMaintenanceSchedulePage] =
    useState<MaintenanceSchedulePageProps>();
  // Handling the filters' visibility
  const [numResults, setNumResults] = useState<number | null>(null);

  // Define default filters for different tabs
  const activeSubtab = secondaryTab.find((sub) => sub.selected === true);
  const defaultFilter = useMemo(() => {
    const filter: MaintenanceEventArgs = {
      includeTasks: false,
      includeProductIds: false
    };
    filter.machineIds = machineId ? [machineId] : [];
    if (activeSubtab?.value === SecondaryTabViews.ServiceTech) {
      filter.creator = MaintenanceCreator.User;
    } else if (activeSubtab?.value === SecondaryTabViews.Predictive) {
      filter.creator = MaintenanceCreator.Predictive;
      filter.owner = 'Data Science';
    }
    return filter;
  }, [machineId, secondaryTab]);

  const defaultHistoryFilter = useMemo(() => {
    const filter: MaintenanceEventArgs = {
      includeTasks: false,
      includeProductIds: false,
      status: MaintenanceEventStatus.Completed
    };
    filter.machineIds = machineId ? [machineId] : [];
    return filter;
  }, [machineId]);

  const defaultPlannedFilter = useMemo(() => {
    const filter: MaintenanceScheduleArgs = {
      frequencyType: MaintenanceFrequencyType.Days,
      minimumFrequency: 1,
      maximumFrequency: 1
    };
    filter.machineIds = machineId ? [machineId] : [];
    return filter;
  }, [machineId]);

  // initialize filters to the default
  const [priorityFilter, setPriorityFilter] = useState<
    MaintenanceEventArgs | MaintenanceScheduleArgs
  >(defaultFilter);
  const [plannedFilter, setPlannedFilter] = useState<
    MaintenanceEventArgs | MaintenanceScheduleArgs
  >(defaultPlannedFilter);
  const [historyFilter, setHistoryFilter] = useState<
    MaintenanceEventArgs | MaintenanceScheduleArgs
  >(defaultHistoryFilter);
  // reset filters back to default on tab change
  const resetFilter = (thisCurrentTab: TabViews) => {
    if (thisCurrentTab === TabViews.HighPriority) {
      setPriorityFilter(defaultFilter);
      setSecondaryTab(secondaryTabArrayHighPriority);
    } else if (thisCurrentTab === TabViews.Planned) {
      setPlannedFilter(defaultPlannedFilter);
      setSecondaryTab(secondaryTabArrayPlanned);
    } else {
      setHistoryFilter(defaultHistoryFilter);
      setSecondaryTab(secondaryTabArrayHistory);
    }
  };
  const { data: plants, isLoading: plantsLoading } = useGetPlantsQuery();

  const machines = useMemo(() => {
    return plantsLoading
      ? undefined
      : plants?.reduce((acc: Machine[], plant: Plant) => acc.concat(plant?.machines ?? []), []);
  }, [plants, plantsLoading]);

  useEffect(() => {
    setPriorityFilter(defaultFilter);
    setPlannedFilter(defaultPlannedFilter);
    setHistoryFilter(defaultHistoryFilter);
  }, [defaultFilter, defaultHistoryFilter, defaultPlannedFilter, machines]);

  const [selectedPm, setSelectedPm] = useState<
    MaintenanceEventTableRow | null | MaintenanceScheduleTableRow
  >(null);
  const [completedMaintenanceEvents, setCompletedMaintenanceEvents] = useState<
    MaintenanceScheduleTableRow[]
  >([]);
  const [followUpPM, setFollowUpPM] = useState<MaintenanceEvent | null>(null);
  const [cartViewType, setCartViewType] = useState(CartListType.VIEW_TYPE_LESS);
  const [showFollowUpModal, setShowFollowUpModal] = useState<boolean>(false);
  const [showCreateServiceModal, setCreateServiceModal] = useState<boolean>(false);
  const flyoutWidth = cartViewType === CartListType.VIEW_TYPE_LESS ? '28.125rem' : '58.125rem';

  const flyoutCloseHandler = (maintenanceEvent?: MaintenanceEventTableRow) => {
    if (maintenanceEvent?.followUpNeeded) {
      setShowFollowUpModal(true);
      setFollowUpPM({ ...selectedPm } as MaintenanceEventTableRow);
    }
    setSelectedPm(null); //triggers closing of flyout
    setCartViewType(CartListType.VIEW_TYPE_LESS);
    changeTabAndRefreshEvents(currentTab, false);

    const update = refetchEventOnUpdate + 1;
    setRefetchEventOnUpdate(update);
  };

  const changeTabAndRefreshEvents = (tabView: TabViews, shouldResetFilter: boolean) => {
    setCurrentTab(tabView);
    if (shouldResetFilter) {
      resetFilter(tabView);
    }
  };

  const changeSecondaryTab = (secondaryTabView: SecondaryTabViews) => {
    const tempSecondaryTab = [
      ...secondaryTabArrayHighPriority,
      ...secondaryTabArrayPlanned,
      ...secondaryTabArrayHistory
    ].map((x) => {
      return { ...x, selected: x.value == secondaryTabView ? true : false };
    });
    setSecondaryTab(tempSecondaryTab);
  };
  const tabs = [
    {
      label:
        numResults === null || currentTab != TabViews.HighPriority
          ? t('high_priority')
          : t('high_priority_param'),
      value: 'high_priority',
      subTabs: secondaryTabArrayHighPriority
    },
    {
      label:
        numResults === null || currentTab != TabViews.Planned ? t('planned') : t('planned_param'),
      value: 'planned',
      subTabs: secondaryTabArrayPlanned
    },
    {
      label:
        numResults === null || currentTab != TabViews.History ? t('history') : t('history_param'),
      value: 'history',
      subTabs: secondaryTabArrayHistory
    }
  ];
  const handleTabClick = (subTab: Tab, mainTab: Tab) => {
    const tab =
      mainTab.value === 'history'
        ? TabViews.History
        : mainTab.value === 'planned'
          ? TabViews.Planned
          : TabViews.HighPriority;
    setCurrentTab(tab);
    resetFilter(tab);
    const actvieSubTab = [
      ...secondaryTabArrayHighPriority,
      ...secondaryTabArrayPlanned,
      ...secondaryTabArrayHistory
    ].find((secTab) => secTab.label === subTab.label);
    changeSecondaryTab(actvieSubTab?.value || SecondaryTabViews.RunTimeBased);
  };
  const onModalClose = () => {
    setShowFollowUpModal(false);
    setCreateServiceModal(false);
    setFollowUpPM(null);
  };

  const submitClickHandler = () => {
    changeTabAndRefreshEvents(currentTab, true);
    setShowFollowUpModal(false);
    setCurrentTab(TabViews.HighPriority);
    changeSecondaryTab(SecondaryTabViews.ServiceTech);
    setIsNewServiceEvent(true);
  };
  const getSelectedServiceData = (data: MaintenanceEventTableRow) => {
    setShowFollowUpModal(true);
    setCreateServiceModal(true);
    setFollowUpPM({ ...data });
  };

  const initiateDownload = (type: string): void => {
    const downloadPayload: MaintenanceEventDownloadPayload = { machineIds: [] };

    if (machineId) {
      downloadPayload.machineIds?.push(machineId as string);
    }
    // Download maintenance events
    if (type === 'downloadselected') {
      if (completedMaintenanceEvents && completedMaintenanceEvents.length > 0) {
        downloadPayload.maintenanceScheduleIds = completedMaintenanceEvents.map(
          (event) => event.id
        );
        downloadPayload.maximumFrequency = currentMaintenanceSchedulePage?.filter.minimumFrequency;
        downloadPayload.maximumFrequency = currentMaintenanceSchedulePage?.filter.maximumFrequency;

        downloadSelectedMaintenanceEvents(downloadPayload)
          .unwrap()
          .then((result) => {
            window.location.href = `${result.url}`;
          })
          .catch((error) => {
            toast.error('Error occurred while downloading events');
            console.error(error);
          });
      } else {
        toast.error('No events have been selected!');
      }
    } else {
      downloadPayload.minimumFrequency = currentMaintenanceSchedulePage?.filter.minimumFrequency;
      downloadPayload.maximumFrequency = currentMaintenanceSchedulePage?.filter.maximumFrequency;
      downloadSelectedMaintenanceEvents(downloadPayload)
        .unwrap()
        .then((result) => {
          window.location.href = `${result.url}`;
        })
        .catch((error) => {
          toast.error('Error occurred while downloading events');
          console.error(error);
        });
    }
  };
  const changeTab = useCallback((tab: SecondaryTabViews) => {
    changeSecondaryTab(tab);
  }, []);

  return (
    <>
      <PageHeader
        mb="2rem"
        heading={t('card_maintenance_manager', { ns: 'common' })}
        icon={MaintenanceManagerIcon}
      />
      {!machines ? (
        <Loader />
      ) : (
        <>
          <TabsContainer>
            <DropdownTab
              tabs={tabs}
              onTabClick={handleTabClick}
              selectedTab={secondaryTab.find((tab) => tab.selected)}
            ></DropdownTab>
            {machineId && (
              <MachineDocument>
                <DocumentFileDropdown
                  machineId={machineId}
                  categoryName="operation_and_maintenance_manual"
                />
              </MachineDocument>
            )}
          </TabsContainer>

          {currentTab === TabViews.Planned && machines && (
            <ButtonWrapper>
              <TableFilterContainer mb="0" padding="0">
                <PermissionWrapper
                  page={PermissionScopeName.MAINTENANCE_MANAGER}
                  scope={UserScopes.Write}
                >
                  <CreateServiceBtn handleServiceModal={() => setCreateServiceModal(true)} />
                </PermissionWrapper>
                <DownloadTypeSelectWrapper>
                  <Button
                    icon={download_selected}
                    variant={'text'}
                    onClick={() => initiateDownload('downloadselected')}
                  >
                    <span>{t('download')}</span>
                  </Button>
                </DownloadTypeSelectWrapper>
              </TableFilterContainer>
            </ButtonWrapper>
          )}

          <ColumnContainer>
            <Container isExpanded>
              {currentTab === TabViews.HighPriority && machines && (
                <TopView
                  filter={priorityFilter}
                  machines={machines}
                  setSelectedPm={setSelectedPm}
                  setNumResults={setNumResults}
                  secondaryTab={secondaryTab}
                  primaryTab={currentTab}
                  onClickCreateService={getSelectedServiceData}
                  handleCreateServiceModal={setCreateServiceModal}
                  handleTabChange={changeTab}
                  refetchEventOnUpdate={refetchEventOnUpdate}
                  isNewServiceEvent={isNewServiceEvent}
                  setIsNewServiceEvent={setIsNewServiceEvent}
                />
              )}
              {currentTab === TabViews.Planned && machines && (
                <TopView
                  filter={plannedFilter}
                  machines={machines}
                  setSelectedPm={setSelectedPm}
                  setNumResults={setNumResults}
                  secondaryTab={secondaryTab}
                  primaryTab={currentTab}
                  updateCompletedEvents={setCompletedMaintenanceEvents}
                  setCurrentMaintenanceSchedulePage={setCurrentMaintenanceSchedulePage}
                />
              )}
              {currentTab === TabViews.History && machines && (
                <TopView
                  filter={historyFilter}
                  machines={machines}
                  setSelectedPm={setSelectedPm}
                  setNumResults={setNumResults}
                  secondaryTab={secondaryTab}
                  primaryTab={currentTab}
                  onClickCreateService={getSelectedServiceData}
                  handleCreateServiceModal={setCreateServiceModal}
                />
              )}
            </Container>
          </ColumnContainer>

          {selectedPm &&
            (typeof selectedPm.machineDescription == 'string' ||
              typeof selectedPm.machineDescription == 'undefined') && (
              <Flyout width={flyoutWidth} visible={!!selectedPm} onClose={flyoutCloseHandler}>
                {selectedPm && (
                  <MaintenanceEventDetail
                    maintenanceEventId={selectedPm.id}
                    machineDescription={selectedPm.machineDescription}
                    cartViewType={cartViewType}
                    onCartViewType={setCartViewType}
                    onClose={flyoutCloseHandler}
                  />
                )}
              </Flyout>
            )}
          {showCreateServiceModal && (
            <Flyout width={'28rem'} visible={showCreateServiceModal} onClose={() => onModalClose()}>
              {showCreateServiceModal && (
                <CreateServiceForm
                  title={FollowUpModal(showFollowUpModal, t)}
                  followUpPM={followUpPM}
                  handleClose={onModalClose}
                  submitClickHandler={submitClickHandler}
                  machines={showCreateServiceModal ? machines : []}
                  machineId={machineId}
                  showCreateServiceModal={showCreateServiceModal ? true : false}
                />
              )}
            </Flyout>
          )}
        </>
      )}
    </>
  );
}
function FollowUpModal(
  showFollowUpModal: boolean,
  t: TFunction<'fpns'[], undefined>
): React.ReactNode {
  return showFollowUpModal ? (
    <Typography as="h4" mb={0} size={'1.5rem'} weight="bold">
      <span>{t('create_follow_up_service')}</span>{' '}
    </Typography>
  ) : (
    <Typography as="h4" mb={0} size={'1.5rem'} weight="bold">
      <span>{t('create_new_service')}</span>{' '}
    </Typography>
  );
}
