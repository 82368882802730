import React from 'react';

export const DownloadIcon = (): JSX.Element => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66602 11.5228L2.66602 12.1895C2.66602 13.294 3.56145 14.1895 4.66602 14.1895L11.3327 14.1895C12.4373 14.1895 13.3327 13.294 13.3327 12.1895L13.3327 11.5228M10.666 8.85612L7.99935 11.5228M7.99935 11.5228L5.33268 8.85612M7.99935 11.5228L7.99935 3.52279"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
