import React, { useState, ReactNode } from 'react';
import { WidgetUiProps } from './WidgetUi.types';
import { WidgetUiContainer, baseClass } from './WidgetUi.elements';
import { WidgetUiHeaderButtons } from './WidgetUiHeaderButtons';
import { TimeStampDisplay } from './TimeStampDisplay/TimeStampDisplay';
import { useTranslation } from 'react-i18next';
import { DataLoader } from 'common/components/DataLoader/DataLoader';
import { PopupWithButton } from 'common/components/PopupWithButton/PopupWithButton';
import { FullScreenViewContainer } from './FullScreenView/FullScreenView.elements';
import { DateRangesSelectDropdown } from './DateRangesSelectDropdown/DateRangesSelectDropdown';
import WidgetCustomDropdown from 'components/StyledUi/DashboardWidget/WidgetCustomDropdown';

interface Props extends WidgetUiProps {
  children?: ReactNode;
}

export const WidgetUi = ({
  width,
  children,
  className,
  Footer,
  hasError,
  SubHeader,
  hasMessage,
  Header,
  isLoading,
  Main,
  title,
  hasButtons,
  styleType,
  hasDateFooter,
  minHeight,
  enableFullScreen,
  hasDateRanges,
  defaultDateRange,
  dropdownOptions,
  customDropdownItems,
  rightIconOnClick
}: Props): JSX.Element => {
  // this is to let us change the view of the widget, but lets us be flexible with the views
  const [widgetView, setWidgetView] = useState<Record<string, boolean>>({});

  const diagnosticsMode = !localStorage.getItem('diagnostics')
    ? false
    : localStorage.getItem('diagnostics') === 'true'
      ? true
      : false;

  // define starting class
  className = className ? `${baseClass} ${className}` : `${baseClass}`;
  // start adding classes
  if (isLoading) className += ` is-loading`;
  if (hasMessage) className += ` has-message`;
  if (hasError) className += ` has-error`;
  if (styleType) className += ` style-type--${styleType}`;
  if (SubHeader) className += ` has-sub-header`;

  // add date footer if needed
  // hasDateFooter is a string, it will displayed instead of the date
  if (hasDateFooter) {
    if (typeof hasDateFooter === 'string')
      Footer = <TimeStampDisplay>{hasDateFooter}</TimeStampDisplay>;
    else Footer = <TimeStampDisplay />;
  }

  // establesh main grid row that is full height
  let gridRows = ``,
    // establesh grid areas
    gridAreas = ``,
    // check if there is a header
    headerProps: Record<string, unknown> | undefined = undefined;

  // check if everything is good to see if page is ready to load
  const isLoaded = !isLoading && !hasError && !hasMessage;

  const [showCustomMenuOptions, setShowCustomMenuOptions] = useState<boolean>(false);
  const handleToggleCustomMenu = () => setShowCustomMenuOptions(!showCustomMenuOptions);

  // compbine header props to pass to header component
  if (Header || title || hasButtons || hasDateRanges || dropdownOptions) {
    headerProps = {
      title,
      hasButtons,
      Header,
      hasDateRanges,
      defaultDateRange,
      isLoading,
      hasError,
      dropdownOptions,
      handleToggleCustomMenu,
      rightIconOnClick
    };

    // add header grid row
    gridRows = `auto `;
    gridAreas = `'header'`;
  } else {
    // assign class if no header is present
    className += ` no-header`;
  }

  if (isLoaded) {
    // add the grid rows now that it's loaded
    if (SubHeader) {
      gridRows += ` auto `;
      gridAreas += `'sub-header'`;
    }
  }

  gridRows += `1fr`;
  gridAreas += `'main'`;

  // add footer grid row
  if (Footer || hasDateFooter) {
    gridRows += ` auto`;
    gridAreas += `'footer'`;
    className = `${className} has-footer`;
  }

  // define base settings
  const settings = {
    width,
    className,
    gridRows,
    minHeight,
    gridAreas
  };

  // this is for future use, do not use
  if (enableFullScreen)
    Footer = (
      <>
        {Footer}
        <button type="button" onClick={() => setWidgetView({ 'full-screen': true })}>
          Full Screen
        </button>
      </>
    );

  // make a wrapper component that has the basics
  // to avoid rewriting the same code over and over
  const Widget = () => (
    <WidgetUiContainer {...settings}>
      {headerProps && <WidgetHeader {...headerProps} />}
      <DataLoader className={`${baseClass}__main`} {...{ isLoading, hasError, hasMessage }}>
        {isLoaded && (
          <>
            {SubHeader}
            {
              // Main would be used if you want to style your own containing div.
              // make sure this component has a className of widget-ui__main to be
              // position correctly, and to get border styles.
              // you can also use grid-area: main; to position it in the grid for no styling
              Main ||
                // children render in the main section with styling and auto-scrolling
                (children && <div className={`${baseClass}__main has-padding`}>{children}</div>)
            }
          </>
        )}
      </DataLoader>
      {Footer}
      {
        // this is a wip, do not use
        diagnosticsMode && (
          <div className="diagnostics-mode-container">Diagnostics Mode Enabled</div>
        )
      }
      <WidgetCustomDropdown
        customDropdownItems={customDropdownItems}
        showMenu={showCustomMenuOptions}
        setShowMenuOptions={setShowCustomMenuOptions}
      />
    </WidgetUiContainer>
  );

  // checks to see if widget has a special view
  if (widgetView?.['full-screen']) {
    return (
      <PopupWithButton
        Popup={() => (
          <FullScreenViewContainer>
            <Widget />
          </FullScreenViewContainer>
        )}
        isOpen
        type="full-screen"
        handle={() => setWidgetView({})}
      />
    );
  }

  return <Widget />;
};

const WidgetHeader = ({
  title,
  hasButtons,
  hasDateRanges,
  defaultDateRange,
  isLoading,
  hasError,
  handleToggleCustomMenu,
  rightIconOnClick
}: WidgetUiProps) => {
  const { t } = useTranslation(['mh']);

  let translatedTitle = undefined;

  if (typeof title === 'string') translatedTitle = t(title.toLowerCase() as string);

  return (
    <header className={`${baseClass}__header`}>
      {(translatedTitle || title) && <h2>{translatedTitle || title}</h2>}
      {hasDateRanges && !isLoading && !hasError ? (
        <DateRangesSelectDropdown ranges={hasDateRanges} {...{ defaultDateRange }} />
      ) : (
        hasButtons?.headerRight &&
        hasButtons.headerRight.map((type) => (
          <WidgetUiHeaderButtons
            key={`btnLeft${type}`}
            {...{ type, handleToggleCustomMenu, rightIconOnClick }}
          />
        ))
      )}
    </header>
  );
};
