import React from 'react';

export const InfoIcon = (color: string, size?: string): JSX.Element => {
  return (
    <svg
      width={size || '18'}
      height={size || '18'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66668 11.5C7.66668 10.719 7.66668 10 7.66668 10C7.66668 10 7.66668 8.5779 7.66668 7.66667M7.66668 5.00001H7.67335M14.3466 8.09068C14.3466 11.7726 11.3619 14.7573 7.67997 14.7573C3.99807 14.7573 1.01331 11.7726 1.01331 8.09068C1.01331 4.40878 3.99807 1.42401 7.67997 1.42401C11.3619 1.42401 14.3466 4.40878 14.3466 8.09068Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
