import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoBell = ({
  width = '24',
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.02865 10.3333H11.362L10.4254 9.39671C10.1713 9.1427 10.0286 8.79819 10.0286 8.43897V6.33334C10.0286 4.59171 8.91557 3.11006 7.36198 2.56094V2.33333C7.36198 1.59695 6.76503 1 6.02865 1C5.29227 1 4.69531 1.59695 4.69531 2.33333V2.56094C3.14172 3.11006 2.02865 4.59171 2.02865 6.33334V8.43897C2.02865 8.79819 1.88594 9.1427 1.63193 9.39671L0.695312 10.3333H4.02865M8.02865 10.3333V11C8.02865 12.1046 7.13321 13 6.02865 13C4.92408 13 4.02865 12.1046 4.02865 11V10.3333M8.02865 10.3333H4.02865"
        stroke={color || '#B62C10'}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
