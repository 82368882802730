// 3rd party
import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import styled, { useTheme } from 'styled-components';
import { FleetBreadCrumbsProps, FleetBreadCrumbs } from 'components/StyledUi/FleetBreadCrumbs';
import { Helmet } from 'react-helmet';
// Types
import { IconSpec } from 'types';
import { BreadCrumb } from 'components/Breadcrumbs';

// Components
import { Breadcrumbs, Indicator, Typography } from 'components';

// Constants
import breakpoint from 'constants/breakpoints';

interface Props {
  heading: string;
  message?: string;
  messageColor?: string;
  icon?: IconSpec | string;
  mb?: string;
  breadcrumbs?: BreadCrumb[];
  rightContent?: ReactNode;
  onlyBreadcrumbs?: boolean;
  breadCrumbSettings?: FleetBreadCrumbsProps;
}

const BreadcrumbsContainer = styled.div<{ mb?: string }>`
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  padding: 0rem 2rem;
  background-color: ${({ theme }) => theme.colors.lightBlueBg};
`;

const ContentContainer = styled.div`
  width: 50%;
`;

const IconContainer = styled.div`
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
`;

const StyledIndicator = styled(Indicator)`
  display: inline;
  margin: 0;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 9.5rem;
  & > h3 {
    font-size: 4rem;
    @media (max-width: ${breakpoint.size.lg}px) {
      font-size: 3.125rem;
    }
    @media (max-width: ${breakpoint.size.md}px) {
      font-size: 2.5rem;
    }
    @media (max-width: ${breakpoint.size.sm}px) {
      font-size: 2rem;
    }
  }
`;

const ImgContainer = styled.img`
  margin-top: 4rem;
  z-index: 99;
  @media (max-width: ${breakpoint.size.md}px) {
    margin-top: 0;
    width: 125px;
  }
  @media (max-width: ${breakpoint.size.sm}px) {
    width: 100px;
  }
`;

const PageHeader = ({
  heading,
  message,
  messageColor,
  breadcrumbs,
  icon,
  mb,
  rightContent,
  onlyBreadcrumbs,
  breadCrumbSettings
}: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>{heading}</title>
      </Helmet>
      {!breadCrumbSettings ? (
        <BreadcrumbsContainer mb={mb}>
          {(!breadcrumbs || breadcrumbs.length === 0) && (
            <HeaderContainer>
              <Box className="BoxSec">
                <Typography as="h3" mb={0} color={theme.colors.lightGrey8} weight="thin">
                  {heading}
                </Typography>
                {icon && (
                  <IconContainer>
                    {typeof icon === 'string' ? (
                      <ImgContainer src={icon} />
                    ) : icon.iconType === 'fa' ? (
                      <FontAwesomeIcon icon={icon.iconElement as IconDefinition} />
                    ) : (
                      (icon.iconElement as (color?: string) => JSX.Element)(theme.colors.darkGrey)
                    )}
                  </IconContainer>
                )}
              </Box>
            </HeaderContainer>
          )}
          {breadcrumbs?.length ? <Breadcrumbs items={breadcrumbs} /> : ''}
        </BreadcrumbsContainer>
      ) : (
        <FleetBreadCrumbs {...breadCrumbSettings} />
      )}

      {!onlyBreadcrumbs && message && rightContent && (
        <HeaderContainer>
          <ContentContainer>
            {message && (
              <MessageContainer>
                {messageColor && <StyledIndicator color={messageColor} />}
                <Typography mb={0} size="1.125rem" weight="bold" color={messageColor}>
                  {message}
                </Typography>
              </MessageContainer>
            )}
          </ContentContainer>
          <ContentContainer>{rightContent}</ContentContainer>
        </HeaderContainer>
      )}
    </>
  );
};

export default PageHeader;
