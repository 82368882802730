import { Modal, NewBaseTable } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnConfig } from 'components/NewBaseTable/NewBaseTable';
import { TFunction } from 'i18next';
import { CellContext } from '@tanstack/react-table';
import { ModalSize, TableColumnConfigs } from 'types';
import styled from 'styled-components';
import { useGetMachineReportsQuery } from 'api';
import { ServiceReport, ServiceReportParams } from 'types/parts';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { themeColors } from 'themes';
import { DownloadIcon } from 'icons/DownloadIcon';
import { PrintIcon } from 'icons/PrintIcon';
import { saveAs } from 'file-saver';

interface ReportProps {
  t: TFunction<'fpns'[]>;
  handleReportClick: (pdfUrl: string, fileName: string) => void;
}
const TableCell = styled.div`
  max-width: 12rem;
  padding: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-all;
`;
const PdfUrl = styled.div`
  color: ${themeColors.mediumBlue};
  cursor: pointer;
`;
const ModalRibbon = styled.div`
  display: flex;
  border-top: 0.063rem solid ${themeColors.lightGrey5};
  border-bottom: 0.063rem solid ${themeColors.lightGrey5};
  margin-bottom: 1rem;
  margin-right: 3rem;
  margin-left: 3rem;
  padding-top: 1.2rem;
`;
const RibbonItem = styled.div`
  display: flex;
  margin-right: 2rem;
  cursor: pointer;
`;
const RibbonIcon = styled.div`
  margin-right: 0.2rem;
`;
const RibbonText = styled.div`
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const IframeWrapper = styled.div`
  margin-left: 5%;
  margin-right: 5%;
  height: 100%;
`;
const generateMachineReportConfig = ({ t, handleReportClick }: ReportProps): ColumnConfig[] => {
  return [
    {
      header: t('machine_name') as string,
      id: 'machineName',
      isEnableSorting: true,
      isSelected: true,
      renderer: (data: CellContext<TableColumnConfigs, string>) => {
        return <TableCell>{data.getValue()}</TableCell>;
      }
    },
    {
      header: 'Service Report Details', //t('asset_name') as string,
      id: 'pdfUrl',
      isEnableSorting: true,
      isSelected: true,
      renderer: (data: CellContext<TableColumnConfigs, string>) => {
        const row = data.row.original as ServiceReport;
        return (
          <TableCell>
            <PdfUrl
              onClick={() => {
                handleReportClick(row.documentPath as string, row.machineName as string);
              }}
              title="PDF Url"
            >
              {data.getValue()}
            </PdfUrl>
          </TableCell>
        );
      }
    },
    {
      header: 'Created Date', //t('asset_name') as string,
      id: 'createdAt',
      isEnableSorting: true,
      isSelected: true,
      renderer: (data: CellContext<TableColumnConfigs, string>) => {
        return <TableCell>{data.getValue()}</TableCell>;
      }
    }
  ];
};
interface ServiceReportProps {
  filter: ServiceReportParams | null;
}
const MachineReportsTable = ({ filter }: ServiceReportProps): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const [showPdfUrlModal, setShowPdfUrlModal] = useState<boolean>(false);
  const [orderUrl, setOrderUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  // const { onPageChange, pageNumber } = usePaginatedQueryOffset();
  // const ITEMS_PER_PAGE = PAGE_LENGTH.SMALL;
  const { data: reports, isFetching } = useGetMachineReportsQuery(
    filter
      ? {
          ...filter
        }
      : skipToken
  );
  const handleReportClick = (pdfUrl: string, fileName: string): void => {
    setShowPdfUrlModal(true);
    setOrderUrl(pdfUrl);
    setFileName(fileName);
  };

  return (
    <>
      <Modal
        visible={showPdfUrlModal}
        size={ModalSize.MEDIUM}
        title={'Reports'}
        onClose={() => setShowPdfUrlModal(false)}
        allowContentScroll={true}
      >
        <ModalRibbon>
          <RibbonItem>
            <RibbonIcon>
              <PrintIcon />
            </RibbonIcon>
            <RibbonText>Print Report</RibbonText>
          </RibbonItem>
          <RibbonItem
            onClick={() => {
              saveAs(orderUrl as string, `Report-${fileName as string}.pdf`);
            }}
          >
            <RibbonIcon>
              <DownloadIcon />
            </RibbonIcon>
            <RibbonText>Download Report</RibbonText>
          </RibbonItem>
        </ModalRibbon>
        <IframeWrapper>
          <iframe src={`${orderUrl}#zoom=FitH`} width="100%" height="100%" />
        </IframeWrapper>
      </Modal>
      <NewBaseTable
        columnConfigs={generateMachineReportConfig({ t, handleReportClick })}
        newTableData={!isFetching && reports ? reports : []}
        sortState={{ id: 'machineId', desc: true }}
        tdMarginLeft="0.625rem"
        textAlign="left"
        padding="0 0 0 .6rem "
        isShowGlobalSearch={true}
        shadow
        isDataLoading={isFetching}
      />
      {/* <Pagination
        onPageChange={onPageChange}
        itemsPerPage={ITEMS_PER_PAGE}
        numItems={10}
        currentPage={pageNumber}
      /> */}
    </>
  );
};
export default MachineReportsTable;
