import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || '24',
  height: width || '24',
  fill: 'none'
}))``;

export const IcoPdf = ({ width, color }: { width?: string; color?: string }): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3338_17951)">
        <path
          d="M9.33325 2V4.66667C9.33325 4.84348 9.40349 5.01305 9.52851 5.13807C9.65354 5.2631 9.82311 5.33333 9.99992 5.33333H12.6666"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33325 8V3.33333C3.33325 2.97971 3.47373 2.64057 3.72378 2.39052C3.97382 2.14048 4.31296 2 4.66659 2H9.33325L12.6666 5.33333V8"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33325 12H4.33325C4.59847 12 4.85282 11.8946 5.04036 11.7071C5.2279 11.5196 5.33325 11.2652 5.33325 11C5.33325 10.7348 5.2279 10.4804 5.04036 10.2929C4.85282 10.1054 4.59847 10 4.33325 10H3.33325V14"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3333 12H12.6666"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 10H11.3333V14"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33325 10V14H7.99992C8.35354 14 8.69268 13.8595 8.94273 13.6095C9.19278 13.3594 9.33325 13.0203 9.33325 12.6667V11.3333C9.33325 10.9797 9.19278 10.6406 8.94273 10.3905C8.69268 10.1405 8.35354 10 7.99992 10H7.33325Z"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3338_17951">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
