import React from 'react';
import { DateRangesSelectDropdownContainer } from './DateRangesSelectDropdown.elements';
import { IcoChevron } from 'icons/IcoChevron';
import { useTranslation } from 'react-i18next';
import { useWidgetDateRange } from '../providers/useWidgetDateRange';

export interface DateRangesRangeProps {
  startTime?: string;
  endTime?: string;
  subtractDays?: number;
  subtractWeeks?: number;
  subtactMonths?: number;
}

export interface DateRangesSelectDropdownProps {
  ranges?: Record<string, DateRangesRangeProps>;
  defaultLabel?: string;
  defaultDateRange?: string;
}

export const DateRangesSelectDropdown = ({
  ranges
}: DateRangesSelectDropdownProps): JSX.Element => {
  if (!ranges) return <></>;

  const { t } = useTranslation(['mh']);
  const { setDateRange, label } = useWidgetDateRange();

  const handleClick = (label: string) => {
    return setDateRange({
      label,
      subtractDays: ranges?.[label as string]?.subtractDays,
      subtractWeeks: ranges?.[label as string]?.subtractWeeks
    });
  };

  return (
    <DateRangesSelectDropdownContainer className="date-ranges-select-dropdown">
      <div className="date-ranges-select-dropdown__view-box">
        <div className="view-box__label">{label}</div>
        <IcoChevron />
      </div>
      <div className="date-ranges-select-dropdown__dropdown-area">
        {Object.keys(ranges).map((curlabel) => {
          return (
            <button
              key={curlabel}
              className={label === curlabel ? `is-selected` : undefined}
              type="button"
              onClick={() => handleClick(curlabel)}
            >
              {t(curlabel)}
            </button>
          );
        })}
      </div>
    </DateRangesSelectDropdownContainer>
  );
};
