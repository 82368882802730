import React from 'react';
import { convertToCSV } from '../js/convertTableDataToCsv';
import saveAs from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useTimeZone } from 'providers';
import { formatDate } from 'helpers';
import { convertSecondsToTime } from 'common/helpers/dateAndTimeHelpersV2';
import { BtnText, IconTextBtn } from 'components/RibbonComponent';
import { Download2 } from 'icons/indexed-icons/Download2';
import theme from 'themes';

export interface DownloadCSVButtonProps {
  data?: Record<string, unknown>[];
  fileName: string;
  headers?: Record<string, string>;
}

export const DownloadCSVButton = ({
  data,
  fileName,
  headers
}: DownloadCSVButtonProps): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const { timeZone } = useTimeZone();

  const handleClick = () => {
    if (!data) return false;
    if (data) {
      // Modify the startTimestamp format for each item
      const modifiedData = data?.map((item) => ({
        ...item,
        startTimestamp: `${formatDate(
          String(item?.startTimestamp),
          'long',
          timeZone
        )} - ${formatDate(String(item?.startTimestamp), 'hours-minutes-seconds', timeZone)}`,
        endTimestamp: `${formatDate(String(item?.endTimestamp), 'long', timeZone)} - ${formatDate(
          String(item?.endTimestamp),
          'hours-minutes-seconds',
          timeZone
        )}`,
        duration: convertSecondsToTime(item.duration)
      }));

      const csvString = modifiedData && convertToCSV(modifiedData, headers);
      const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      saveAs(csvBlob, fileName);
    }
  };

  const disabled = !data ? true : false;

  return (
    <IconTextBtn
      gap=".5rem"
      disable={disabled}
      onClick={() => handleClick()}
      className="ribbon-button"
      disabled={disabled}
    >
      <Download2 color={disabled ? theme.colors.lightGrey9 : theme.colors.primaryBlue5} />
      <BtnText>{t('download')} CSV</BtnText>
    </IconTextBtn>
  );
};
