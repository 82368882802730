import { groupByKey } from './dataGroupers';

export interface DataCountersOptionsProps {
  data?: Record<string, unknown>[];
  // the key to calculate the totals and averages,
  // otherwise this function only returnst he occurrences of each group
  valueKey?: string | number;
  // map the tags to a different value and rename
  mapKeys?: Record<string, string>;
  // map key and map tags are the same
  mapTags?: Record<string, string>;
  colors?: Record<string, string>;
  // function to perform on the total value before returning
  processTotal?: (val?: number) => number;
  // if you want to save the processed value as a new key, provide the key name here
  processedTotalKey?: string | number;
  // if you want to add values from keys from the orignial data object, add them here
  includeKeys?: string[];
}

export interface CalculateGroupTotalsReturnItemProps {
  count?: number;
  total?: number;
  average?: number;
  percent?: number;
  groupKey?: string;
  valueKey?: string;
  group?: string;
  color?: string;
  [key: string]: unknown;
}

export type CalculateGroupTotalsReturnProps = Record<string, CalculateGroupTotalsReturnItemProps>;

// this script is used to calculate the number of occurrences of each group in a dataset, and if a value key is provided,
// it wil give you totals value, occurence, average value, and percent of the total value
export const calculateDataKeyTotals = (
  // data to convert to chart data
  data: Record<string, unknown>[],
  // this is needed to know how we're grouping the data
  // at the miminum, this script will calculate the number of occurrences of each group
  groupKey: string,
  options?: DataCountersOptionsProps
): Record<string, CalculateGroupTotalsReturnItemProps> | undefined =>
  calculateGroupTotals(groupKey, { ...options, data });

export const calculateGroupTotals = (
  // this is needed to know how we're grouping the data
  // at the miminum, this script will calculate the number of occurrences of each group
  groupKey: string | number,
  { valueKey, data, includeKeys, mapKeys, mapTags, colors }: DataCountersOptionsProps
): Record<string, CalculateGroupTotalsReturnItemProps> | undefined => {
  mapKeys = mapTags;
  // return early if no data or no groupKey
  if (!data || groupKey === '') return undefined;

  // Group the data by the specified key and count the number of occurrences of each group
  const groupedData: CalculateGroupTotalsReturnItemProps | undefined = data.reduce(
    (acc: Record<string, Record<string, unknown>>, obj: Record<string, unknown>) => {
      // get the group this item is part of
      const idKey = obj?.[groupKey];
      const group = mapKeys?.[idKey as string] || (idKey as string);

      if (!group) return acc;

      const val = obj?.[valueKey as string] as number;

      // start new counter object
      if (!acc[group]) {
        acc = {
          [group]: {
            // the number of occurrences of this group
            count: 0,
            total: valueKey ? 0 : undefined,
            average: valueKey ? 0 : undefined,
            // add the groupKey to the object for reference
            groupKey,
            [groupKey]: group,
            color: obj?.color || (colors?.[group] as string)
          },
          ...acc
        };

        if (valueKey) acc[group].valueKey = valueKey;

        includeKeys?.forEach((key) => {
          key = mapKeys?.[key] || key;
          acc[group] = { ...(acc[group] as Record<string, unknown>), [key]: obj?.[key] };
        });
      }

      // setup a new group object.  this was done to pass TS errors
      const newG = acc[group] as Record<string, unknown>;
      // add to the counter
      newG.count = Number(newG.count) + 1;
      // check if there is a value or we're just couting the occurrences of this group
      // add value key for reference if provided
      if (val) {
        // get value if we're given a valueKey to total up the amounts
        newG.total = Number(newG.total) + val;
        newG.average = Number(newG.total) / Number(newG.count);
      }
      // replace with new totals
      acc[group] = newG;

      // return the new data object array
      return acc;
    },
    {}
  );

  return groupedData as Record<string, Record<string, unknown>> | undefined;
};

export const roundToTenth = (num: number): number => Math.round(num * 10) / 10;

export const countDataKeyTotals = (
  // data to convert to chart data
  data: Record<string, unknown>[],
  // this is needed to know how we're grouping the data
  // at the miminum, this script will calculate the number of occurrences of each group
  groupKey?: string,
  options?: {
    // add a value key if you want to do some math on the values
    // will return the total and average of the values along
    valueKey?: string;
    mapTags?: Record<string, string>;
    colors?: Record<string, string>;
  }
): Record<string, unknown> => {
  // Group the data by the specified key and count the number of occurrences of each group
  groupKey = groupKey || 'group';

  const groupedData: Record<string, unknown> = data?.reduce((acc, obj) => {
    // get the group this item is part of
    const idKey = obj?.[groupKey];
    const group = options?.mapTags?.[idKey as string] || (idKey as string);

    // start new counter object
    if (!acc[group])
      acc = {
        [group]: {
          // the number of occurrences of this group
          count: 0,
          // the total amount of the values of valueKeys (if provided)
          total: 0,
          // the average amount of the values of valueKeys (if provided)
          average: 0,
          // add the groupKey to the object for reference
          groupKey,
          group,
          color: obj.color || (options?.colors?.[group] as string)
        },
        ...acc
      };

    // setup a new group object.  this was done to pass TS errors
    const newG = acc[group] as Record<string, unknown>;
    // add to the counter
    newG.count = Number(newG.count) + 1;
    // check if there is a value or we're just couting the occurrences of this group
    // add value key for reference if provided
    if (options?.valueKey as string) {
      // get value if we're given a valueKey to total up the amounts
      const valueKey = options?.valueKey as string;
      const val = obj?.[valueKey] as number;

      if (valueKey && typeof val === 'number') {
        newG.valueKey = valueKey;
        newG.total = Number(newG.total) + val;
        newG.average = Number(newG.total) / Number(newG.count);
      }
    } else {
      delete newG.average;
      delete newG.total;
    }
    // replace with new totals
    acc[group] = newG;

    // return the new data object array
    return acc;
  }, {});

  return groupedData;
};

export const calculateByCategoryByGroup = (
  categoryKey: string,
  groupKey: string,
  {
    data,
    valueKey,
    //processTotal,
    colors
  }: //mapKeys
  //includeKeys
  //processTotalKey
  DataCountersOptionsProps
): Record<string, Record<string, unknown>> => {
  // if there is no data then return undefined
  if (!data || !groupKey || !categoryKey) return {};

  const categorized = groupByKey({ data, key: categoryKey });
  let totals = {};
  Object.entries(categorized).forEach(([key, item]) => {
    const groupTotals = calculateGroupTotals(groupKey, { data: item, valueKey });
    totals = { ...totals, [key]: groupTotals };
  });

  // Group the data by the specified group key and item key, and count the number of occurrences of each combination of group and item
  const groupedData = data.reduce(
    (
      acc: Record<string, Record<string, Record<string, unknown>>>,
      obj: Record<string, unknown>
    ) => {
      // the id of this group based on the group key
      const groupId = obj?.[groupKey] as string;
      // the id of this category based on the category key
      const categoryId = obj?.[categoryKey] as string;

      const value = !valueKey ? undefined : (obj?.[valueKey] as number);

      // this item doesn't have the groupKey present
      if (!groupId || !categoryId) return acc;
      // start adding the groups to the counter
      if (!acc?.[categoryId]) acc[categoryId] = {};

      if (!acc?.[categoryId]?.[groupId]) {
        acc[categoryId][groupId] = {
          // the number of occurrences of this group
          count: 0,
          // the total amount of the values of valueKeys (if provided)
          total: 0,
          // the average amount of the values of valueKeys (if provided)
          average: 0,
          // add the groupKey to the object for reference
          groupKey,
          group: groupId,
          categoryKey,
          category: categoryId,
          color: obj.color || (colors?.[groupId] as string)
        };
      }

      // setup a new group object.  this was done to pass TS errors
      let newG = acc?.[categoryId]?.[groupId] as Record<string, unknown>;
      // add to the counter
      newG.count = Number(newG.count) + 1;

      // check if there is a value or we're just couting the occurrences of this group
      // add value key for reference if provided
      if (value) {
        newG = {
          ...newG,
          valueKey,
          total: Number(newG.total) + value,
          average: Number(newG.total) / Number(newG.count)
        };
      } else {
        delete newG.average;
        delete newG.total;
      }

      // replace with new totals
      acc[categoryId][groupId] = newG;

      return acc;
    },
    {}
  );

  return groupedData;
};

export const calculateDataGroupTotals = (
  // data to convert to chart data
  data: Record<string, unknown>[],
  keys: {
    // this is needed to know how we're grouping the data
    // at the miminum, this script will calculate the number of occurrences of each group
    groupKey: string;
    // add a value key if you want to do some math on the values
    // will return the total and average of the values along
    valueKey?: string;
  }
): Record<string, unknown> => {
  // Group the data by the specified key and count the number of occurrences of each group
  const groupedData: Record<string, unknown> = data.reduce((acc, obj) => {
    // get the group this item is part of
    const groupKey = keys?.groupKey;
    const groupId = obj?.[groupKey] as string;

    // start new counter object
    if (!acc[groupId])
      acc = {
        [groupId]: {
          // the number of occurrences of this group
          count: 0,
          // the total amount of the values of valueKeys (if provided)
          total: 0,
          // the average amount of the values of valueKeys (if provided)
          average: 0,
          // add the groupKey to the object for reference
          groupKey,
          group: groupId,
          color: obj?.color as string
        },
        ...acc
      };
    else {
      // setup a new group object.  this was done to pass TS errors
      const newG = acc[groupId] as Record<string, unknown>;
      // add to the counter
      newG.count = Number(newG.count) + 1;
      // check if there is a value or we're just couting the occurrences of this group
      // add value key for reference if provided
      if (keys.valueKey) {
        // get value if we're given a valueKey to total up the amounts
        const val = obj?.[keys?.valueKey as string] as number;
        newG.valueKey = keys.valueKey;
        newG.total = Number(newG.total) + val;
        newG.average = Number(newG.total) / Number(newG.count);
      } else {
        delete newG.average;
        delete newG.total;
      }
      // replace with new totals
      acc[groupId] = newG;
    }
    // return the new data object array
    return acc;
  }, {});

  return groupedData;
};

export const calculateCategoryAndGroupedRatios = (
  data: Record<string, unknown>[],
  {
    colors,
    labelKey,
    valueKey
  }: { colors: Record<string, string>; labelKey?: string; valueKey?: string }
): Record<string, Record<string, unknown>> => {
  labelKey = labelKey || 'label';
  valueKey = valueKey || 'value';

  const { totals, grandTotal } = data.reduce<{
    totals: { [key: string]: number };
    grandTotal: number;
  }>(
    (acc, item) => {
      const label = item?.[labelKey as string] as string;
      const value = item?.[valueKey as string] as number;

      if (!label || !value) return acc;

      acc.totals[label] = (acc.totals[label] || 0) + value;
      acc.grandTotal += value;

      return acc;
    },
    { totals: {}, grandTotal: 0 }
  );

  // Calculate the percentages and round them down
  let items = Object.keys(totals).map((group) => {
    const value = (totals?.[group] || 0) / grandTotal;
    const roundedValue = Math.floor(value * 100);
    return {
      label: group,
      value: roundedValue,
      color: colors?.[group],
      remainder: value * 100 - roundedValue
    };
  });

  // Calculate the total of the rounded percentages
  const totalPercentage = items?.reduce((total, item) => total + item.value, 0);

  // If the total is less than 100, distribute the remaining percentage points
  if (totalPercentage < 100 && items?.length > 1) {
    // Sort the items by the fractional part of the percentage, in descending order
    items?.sort((a, b) => b.remainder - a.remainder);

    // Distribute the remaining percentage points
    for (let i = 0; i < 100 - totalPercentage; i++) {
      items[i % items.length].value++;
    }
  }

  // Remove the remainder property from the items
  items = items
    .map(({ label, value, color }) => ({ label, value, color, percent: `${value}%` }))
    .sort((a, b) => Number(b?.value) - Number(a?.value));

  return items;
};
