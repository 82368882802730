import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';
import { styledTheme } from 'components';

export const baseClass = 'router-tabs-nav';

export const TabsNavContainer = styled.nav<StyledUiContainerProps>`
  display: flex;
  gap: 1em;

  .router-tabs-nav__nav-item {
    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    padding: 0.7em 1em;
    text-decoration: none;
    background-color: rgb(249, 250, 251);
    color: inherit;
    border: solid 1px transparent;
    text-transform: capitalize;

    border-radius: ${styledTheme.radius.xs};

    &.is-disabled {
      cursor: not-allowed !important;
      opacity: 0.7;
    }

    &:not(.is-current)&.not(.is-disabled) {
      &:hover {
        cursor: pointer;
      }
    }

    &:not(.is-disabled) {
      &.is-current {
        border-color: ${styledTheme.color.secondary};
      }

      &:hover,
      &.is-current {
        background-color: ${styledTheme.color.secondaryLight};
      }
    }
  }

  &.full-width {
    gap: 0;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    .router-tabs-nav__nav-item {
      flex-grow: 1;
      border-style: solid;
      border-width: 0 0 2px 0;
      border-color: transparent;
      font-weight: 500;
      border-radius: 0;

      padding: ${styledTheme.space.md};
      color: ${styledTheme.color.main};

      &:not(.is-disabled) {
        &.is-current {
          border-color: ${styledTheme.color.secondary};
        }

        &:hover,
        &.is-current {
          background-color: ${styledTheme.color.secondaryLight};
          color: ${styledTheme.color.secondary};
        }
      }
    }
  }
  &.sm {
    font-size: 0.8em;
  }
`;
