// 3rd party
import React, { ReactElement, useMemo } from 'react';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Loader, NewBaseTable } from 'components';

// Types
import { BaseType, WithId } from 'types';
import { MasterTagList } from 'types/machine-management';

// Helpers
import { BuiltInSortingFn, CellContext } from '@tanstack/react-table';
import { formatDate } from 'helpers';
import { ActionsButton, MasterTagListTableWrapper, Root } from './index.elements';

export type TableRow = SortableMasterTagList;

interface MasterTagListTableProps {
  data: MasterTagList[];
  isDataLoading?: boolean;
  headerBgColor?: string;
  onOpenActions?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, mtlId: string) => void;
}

interface SortableMasterTagList extends BaseType, WithId {
  name: string;
  description?: string;
  bu?: string;
  machine?: string;
  model?: string;
  createdAt?: Date;
  createdBy?: string;
  updatedAt?: Date;
  updatedBy?: string;
  versions?: number;
}

interface TableColumnConfigs extends BaseType {
  description?: string;
  bu?: string;
  machine?: string;
  model?: string;
  createdAt?: Date;
  createdBy?: string;
  updatedAt?: Date;
  updatedBy?: string;
  versions?: number;
}

/* End interfaces */

const MasterTagListTable = ({
  data,
  isDataLoading,
  onOpenActions
}: MasterTagListTableProps): ReactElement => {
  const TableDataColumns = [
    {
      header: 'Name',
      id: 'name',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      },
      isAlwaysVisible: true
    },
    {
      header: 'Description',
      id: 'description',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const length = cellValue.getValue()?.length ?? 0;
        return length > 25
          ? cellValue.getValue()?.substring(0, 25) + '...'
          : cellValue.getValue() ?? '';
      },
      isAlwaysVisible: true
    },
    {
      header: 'BU',
      id: 'bu',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      },
      isAlwaysVisible: true
    },
    {
      header: 'Machine',
      id: 'machine',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      },
      isAlwaysVisible: true
    },
    {
      header: 'Model',
      id: 'model',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      },
      customSort: 'text' as BuiltInSortingFn,
      isAlwaysVisible: true
    },
    {
      header: 'Created By',
      id: 'createdBy',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      },
      isAlwaysVisible: true
    },
    {
      header: 'Date Modified',
      id: 'updatedAt',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return formatDate(cellValue.getValue(), 'short-year4');
      },
      isAlwaysVisible: true
    },
    {
      header: 'Versions',
      id: 'versions',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      },
      isAlwaysVisible: true
    },
    {
      header: 'Action',
      id: 'action',
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return (
          <ActionsButton
            onClick={(event) => {
              if (onOpenActions)
                onOpenActions(event, cellValue.row && (cellValue.row.original.id as string));
            }}
          >
            <FontAwesomeIcon icon={faEllipsis} />
          </ActionsButton>
        );
      },
      isAlwaysVisible: true
    }
  ];

  const sortableData: SortableMasterTagList[] = useMemo(() => {
    return data.map((mtl) => {
      return {
        bu: mtl.businessUnit?.name,
        machine: mtl.machineType?.name,
        model: mtl.machineModel?.name,
        ...mtl
      } as SortableMasterTagList;
    });
  }, [data]);

  const defaultValueSortState = {
    id: 'name',
    desc: false
  };

  if (isDataLoading) {
    return <Loader />;
  }

  return (
    <Root>
      <MasterTagListTableWrapper>
        <NewBaseTable
          newTableData={sortableData}
          columnConfigs={TableDataColumns}
          sortState={defaultValueSortState}
          textAlign="left"
          customClassName="master-tag-list"
          tdMarginLeft="0.625rem"
          isShowGlobalSearch
          isShowColumnOptions
        />
      </MasterTagListTableWrapper>
    </Root>
  );
};

export default MasterTagListTable;
