import React from 'react';
//import { MachineDetailsBar } from 'components/StyledUi/MachineDetailsBar/MachineDetailsBarV2';
import { useMachineInfo } from '../../providers';

import { MachineViewStateIcon } from './MachineViewStateIcon';
import { MachineInfoBarContainer } from './MachineInfoBar.elements';
import moment from 'moment-timezone';

export const MachineViewInfoBar = (): JSX.Element => {
  // set defaults
  // show the machine state icon
  const { timeZone, serialNumber } = useMachineInfo();

  const offset = moment.tz(timeZone as string).format('Z');
  const formattedZone = `${timeZone} ( UTC ${offset} )`;

  return (
    <MachineInfoBarContainer>
      {serialNumber && <div className="machine-info-bar__item">Serial Number: {serialNumber}</div>}
      {formattedZone && <div className="machine-info-bar__item">Timezone: {formattedZone}</div>}
      <MachineViewStateIcon />
    </MachineInfoBarContainer>
  );
};
