// 3rd party libs
import React, { ReactNode } from 'react';
import moment from 'moment';

// Components
import { useFilterSelected, filterSelectedData, useDateRange } from 'components';

// Types
import { CleaningStepGroup } from 'types/protein';
import { ToolTipLine } from './ToolTipContent';

// Helpers
import { useTranslation } from 'react-i18next';
import { PieChartWidget, chartValueRGBA } from 'common/components';
import { AveragePressurizationTimeChartTooltip } from 'pages/FleetMachineDetail/MachineHealth/PressurizedPerformance/components/AveragePressurizationTimeWidget/AveragePressurizationTimeWidget.elements';
import { colors as stepOverColors } from 'common/pages/fleetV2/settings/protein';
import { timeDisplayFormatter } from 'components/StyledUi/DashboardWidget/timeDisplayFormatter';

// How many steps to display (the rest will be combined under "other")
export const NUM_TOP_STEPS = 4;

interface CleaningStepProps {
  data: CleaningStepGroup[];
  isLoading?: boolean;
  isFetching?: boolean;
  hasError?: ReactNode | ReactNode[];
}

// Intermediary data type, to potentially group steps into one, which can then be rendered as pie slices
export interface DataGroup {
  stepId: string;
  name: string;
  percent: number;
  duration: number; // in milliseconds
  tooltipLines: ToolTipLine[];
  // The steps that make up this group. Mainly useful for the 'other' group, which contains many steps
  constituentSteps: string[];
}

/**
 * Take the list of steps and group them into DataGroups:
 * We take the top NUM_TOP_STEPS steps ,and put them into their own individual DataGroup
 * The remaining steps are grouped together under one "other" DataGroup
 * Each DataGroup will be rendered as one slice of the pie chart.
 */
export const groupData = (data: CleaningStepGroup[]): DataGroup[] => {
  // Order descending by percent
  const sortedData = data.sort((a, b) => b.percent - a.percent);

  // Get the highest steps by percentage
  const topSteps = sortedData.slice(0, NUM_TOP_STEPS);
  // Get the rest of the steps
  const otherSteps = sortedData.slice(NUM_TOP_STEPS);

  const groupedData: DataGroup[] = [];

  topSteps.forEach((step) => {
    groupedData.push({
      stepId: step.id,
      name: step.name,
      percent: step.percent,
      duration: step.duration,
      tooltipLines: [
        {
          id: step.id,
          label: step.name,
          percent: step.percent,
          duration: step.duration
        }
      ],
      constituentSteps: [step.id]
    });
  });

  if (otherSteps.length) {
    const totalPercent = otherSteps.reduce((acc, step) => acc + step.percent, 0);
    const totalDuration = otherSteps.reduce((acc, step) => acc + step.duration, 0);
    groupedData.push({
      stepId: 'other',
      name: 'Other',
      percent: totalPercent,
      duration: totalDuration,
      tooltipLines: otherSteps.map((step) => ({
        id: step.id,
        label: step.name,
        percent: step.percent,
        duration: step.duration
      })),
      constituentSteps: otherSteps.map((step) => step.id)
    });
  }

  return groupedData;
};

export const getCenterCellValue = (data: CleaningStepGroup[], recipe: string): string => {
  const item = data.find((obj) => obj.name === recipe);
  if (item) {
    const { percent, duration } = item;
    return `${moment.duration(duration).asHours().toFixed(1)}(${percent.toFixed(0)}%)`;
  } else {
    return '';
  }
};

const NewCleaningStepCategoriesPieChart = ({
  data,
  isLoading,
  hasError,
  isFetching
}: CleaningStepProps): JSX.Element => {
  const groupedData = groupData(data);
  const pieChartData = groupedData.map((group) => ({
    label: group.name,
    group: group.name,
    value: group.percent,
    duration: group.duration,
    color: stepOverColors[group.name] || chartValueRGBA(group.name)
  }));

  const [selected, handle] = useFilterSelected();
  const { dateRange } = useDateRange();

  const handleClick = (slice: Record<string, unknown>) => {
    const isSelected = selected?.group?.includes(slice.group as string) ? true : false;
    return handle(isSelected ? 'toggle' : 'set', { group: slice.group as string });
  };

  const checkIfSelected = (slice: Record<string, unknown>) => {
    if (!selected) return true;
    const group = (slice.label as string) || (slice.group as string);
    const selectedVals = selected?.group || [];
    if (selectedVals.length && selectedVals.includes(group)) return true;
    if (
      !groupedData.map((item) => item.name).includes(selected?.group[0] as string) &&
      group === 'Other'
    )
      return true;
    return false;
  };

  let filteredData;
  filteredData = pieChartData && filterSelectedData({ data: pieChartData, selected });

  if (selected && !groupedData.map((item) => item.name).includes(selected?.group[0] as string)) {
    filteredData =
      pieChartData && filterSelectedData({ data: pieChartData, selected: { group: ['Other'] } });
  }

  const centerCellValues =
    selected &&
    filteredData &&
    !groupedData.map((item) => item.name).includes(selected?.group[0] as string)
      ? {
          value: getCenterCellValue(data, selected?.group?.[0]),
          label: filteredData?.[0]?.label.slice(0, 45) as string,
          color: filteredData?.[0]?.color as string
        }
      : !isLoading && !hasError && pieChartData
      ? {
          value: `${moment
            .duration(pieChartData?.[0]?.duration)
            .asHours()
            .toFixed(1)}(${pieChartData?.[0]?.value.toFixed(0)}%)` as string,
          label: pieChartData?.[0]?.label.slice(0, 45),
          color: pieChartData?.[0]?.color as string
        }
      : undefined;

  if (selected && centerCellValues) {
    const cur = selected?.group?.[0];
    pieChartData?.forEach((x) => {
      if (x.label === cur && centerCellValues) {
        centerCellValues.value = `${moment
          .duration(x.duration)
          .asHours()
          .toFixed(1)}(${x.value.toFixed(0)}%)` as string;
        centerCellValues.label = x.label.slice(0, 45) as string;
        centerCellValues.color = x.color as string;
      }
    });
  }

  const dateRangeText = `${timeDisplayFormatter({ ...dateRange, useForDisplay: true })}`;

  const widgetUiSettings = {
    isLoading: isFetching,
    hasMessage: groupedData.length ? undefined : 'no data',
    hasError: hasError ? 'Error fetching cleaning details' : '',
    className: 'cleaning-step-categories-widget',
    title: 'Cleaning Step categories (Hours)',
    hasDateFooter: dateRangeText,
    handleClick,
    legendItems: pieChartData
  };

  const TooltipComponent = ({ label, value, duration }: Record<string, unknown>): JSX.Element => {
    const { t } = useTranslation(['mh']);
    return (
      <AveragePressurizationTimeChartTooltip>
        <div className="pie-chart-tooltip__label">{t(String(label || ''))}</div>
        <div className="pie-chart-tooltip__value">
          {duration ? (
            <span className="small-text">
              {moment
                .duration(duration as string)
                .asHours()
                .toFixed(1)}
            </span>
          ) : null}
          {value ? (
            <span className="small-text">{` (${Math.round(value as number)}%)`}</span>
          ) : null}
        </div>
      </AveragePressurizationTimeChartTooltip>
    );
  };

  const pieChartWidgetSettings = {
    ...widgetUiSettings,
    pieChartSettings: {
      type: 'doughnut',
      // passing it the inner part of the tooltip.
      // the container stiles will already be applied
      TooltipComponent
    },
    handleClick,
    checkIfSelected,
    selected,
    data: filteredData,
    centerCellValues,
    format: {
      legendItem: (props: Record<string, unknown>) => {
        const label = props?.label as string;
        return <>{label}</>;
      }
    }
  };

  return <PieChartWidget {...pieChartWidgetSettings} />;
};

export default NewCleaningStepCategoriesPieChart;
