import React from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { IconReset } from 'icons/IconReset';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  downArrow?: boolean;
  hideArrow?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  marginTop?: string;
}

const StyledButton = styled.button`
  background: ${({ theme, disabled }) => {
    return disabled ? theme.colors.white : theme.colors.white;
  }};
  color: ${({ theme, disabled }) => {
    return disabled ? theme.colors.mediumGrey2 : theme.colors.text.lightBlack;
  }};
  border-radius: 0.5rem;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 18px;
  vertical-align: center;
  cursor: ${({ disabled }) => {
    return disabled ? 'not-allowed' : 'pointer';
  }};
  &:hover {
    background: ${({ theme, disabled }) => {
      return disabled ? undefined : theme.colors.mediumBlue4;
    }};
  }
`;
const Label = styled.div`
  text-align: left;
  margin-right: 0.625rem;
  .account {
    width: 8.125rem;
    display: flex;
  }
`;

const ActionTooltip = styled.div`
  &.tooltip-zoom {
    .tooltip-container {
      cursor: pointer;

      &:hover > .tooltip {
        display: block;
      }
     
      .tooltip {
        display: none;
        position: absolute;
        top: 60px;
        bottom: 20px;
        padding: 0.5rem;
        height: 32px;
        background: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
        transform: translateX(-46%);

        &::after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: calc(50% - 3px);
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: #ffffff transparent transparent transparent;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: -11px;
          left: calc(50% - 3px);
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: #d3d3d3 transparent transparent transparent;
        }
      }
    }
  }

  p.zoom {
    display: flex;
    align-items: center;
    padding: 0.4rem;
    margin: 0;
    font-size: 0.87rem;
    height: 30px;

  }
}
`;

const ActionButton = ({
  children,
  downArrow,
  hideArrow,
  disabled,
  marginTop = '0.1875rem',
  ...rest
}: Props): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const theme = useTheme();
  return (
    <StyledButton {...{ disabled: disabled, ...rest }}>
      <ActionTooltip className="tooltip-zoom">
        <p className="zoom">
          <Label>{children}</Label>
          <div className="tooltip-container">
            <IconReset />
            <span className="tooltip">{t('reset_tooltip_message')}</span>
          </div>
        </p>
      </ActionTooltip>
      {!hideArrow && (
        <FontAwesomeIcon
          style={{ marginLeft: '0.125rem', marginTop }}
          icon={downArrow ? faCaretDown : faCaretRight}
          color={disabled ? theme.colors.mediumGrey2 : theme.colors.darkGrey}
        />
      )}
    </StyledButton>
  );
};

export default ActionButton;
