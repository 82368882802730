import { ReactNode } from 'react';
import { useGetMachineCleaningSessionDetailsQuery } from 'api';
import { calculateByCategoryByGroup } from 'common/helpers/dataCounters';
import { groupByKey } from 'common/helpers/dataGroupers';
import { processApiData } from 'common/helpers/processApiData';
import { colors, mapKeys } from 'common/pages/fleetV2/settings/protein';

import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { useDateRange } from 'components';
import { convertObjectArrayToLegendItems } from 'common/components';
import {
  convertUTCTimestamptoZonedTimestamp,
  getDurationISOTimeStamps
} from 'common/helpers/dateAndTimeHelpersV2';
import { ProteinMachineRouteQueryParams } from 'types/protein';
import { useParams } from 'react-router-dom';

const orderSessionsByDate = (data?: Record<string, string | unknown>) =>
  !data
    ? undefined
    : Object.entries(data)
        .map(([category, label]) => ({ category, label }))
        .sort((a, b) => (String(a.label) < String(b.label) ? -1 : 1));

const generateSessionsCategories = (data?: { category?: string; label?: string }[]) =>
  data?.map((item) => item?.category);

export const useNewSessionsOverTimeData = (): {
  isLoading?: boolean;
  isFetching?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: Record<string, unknown>[];
  categories?: string[];
  groupedData?: Record<string, Record<string, unknown>[]>;
  colors?: Record<string, string>;
  [key: string]: unknown;
} => {
  const { machineId } = useParams<ProteinMachineRouteQueryParams>();
  const { isoDateRange } = useDateRange();
  const { timeZone } = useMachineInfo();

  /**
   * Query for cleaning steps once we have a date range
   */
  const { data, isLoading, isFetching, error } = useGetMachineCleaningSessionDetailsQuery({
    grouped: false,
    machineId,
    startDatetime: isoDateRange?.startTime,
    endDatetime: isoDateRange?.endTime
  });

  if (!data)
    return { isFetching, hasError: error ? 'Error fetching changeover details' : undefined };

  const newData = data.map((item) => ({
    ...item,
    duration: new Date(item.endTime).getTime() - new Date(item.startTime).getTime(),
    changeoverStart: item.startTime
  }));

  // we can speed this up by doing all this in one pass, right nowit's not a problem at all
  const processedData = processApiData(newData, {
    timeZone,
    startTimeKey: 'changeoverStart',
    endTimeKey: 'changeoverEnd',
    //addDuration: true,
    //addDateAsKey: 'date',
    formatTimestamps: 'MMM dd',
    renameValues: {
      name: mapKeys
    }
  });

  // this all has to be redone, this currently works but it is not ideal
  let tooltipData: Record<string, unknown> = {};
  let dataTotals:
    | Record<
        string,
        Record<
          string,
          {
            group?: string;
            totalDuration?: number;
            count?: number;
            label?: string;
            category?: string;
          }
        >
      >
    | undefined = undefined;

  if (data) {
    data.forEach((item: Record<string, unknown>) => {
      const {
        sessionId,
        changeoverStart: sessionStart,
        changeoverEnd: sessionEnd,
        startTime,
        endTime,
        name,
        duration,
        ...rest
      } = item as {
        sessionId?: string;
        changeoverStart?: string;
        changeoverEnd?: string;
        startTime?: string;
        endTime?: string;
        rest: Record<string, unknown>;
        name?: string;
        duration?: number;
      };

      if (sessionId) {
        if (!tooltipData?.[sessionId]) {
          tooltipData = {
            ...tooltipData,
            [sessionId]: {
              ...rest,
              sessionId,
              sessionStart:
                sessionStart &&
                convertUTCTimestamptoZonedTimestamp(sessionStart, timeZone as string),
              sessionEnd:
                sessionEnd && convertUTCTimestamptoZonedTimestamp(sessionEnd, timeZone as string),
              sessionDuration:
                sessionStart &&
                sessionEnd &&
                getDurationISOTimeStamps(sessionEnd as string, sessionStart as string),
              steps: [],
              totals: {}
            }
          };
        }

        const formattedName = mapKeys?.[name as string] || (name as string);

        tooltipData[sessionId as string].steps.push({
          startTime:
            startTime && convertUTCTimestamptoZonedTimestamp(startTime, timeZone as string),
          endTime: endTime && convertUTCTimestamptoZonedTimestamp(endTime, timeZone as string),
          label: formattedName,
          name: formattedName,
          color: colors?.[formattedName as string],
          duration,
          category: sessionId,
          ...rest
        });

        if (!dataTotals?.[sessionId]) dataTotals = { ...dataTotals, [sessionId]: {} };
        if (!dataTotals?.[sessionId]?.[formattedName])
          dataTotals[sessionId] = {
            ...dataTotals?.[sessionId],
            [formattedName]: {
              totalDuration: 0,
              count: 0,
              group: formattedName,
              category: sessionId
            }
          };

        dataTotals[sessionId][formattedName].totalDuration += duration as number;
        dataTotals[sessionId][formattedName].count += 1;

        tooltipData[sessionId].totals = dataTotals[sessionId];
      }
    });
  }

  const chartData2 =
    dataTotals &&
    Object.values(dataTotals)
      .map((items) => Object.values(items))
      .flat();

  // group the data for stacking the bars
  const groupedData = groupByKey({ data: chartData2, key: 'sessionId' });

  // we need the totals for this chart so we add up the duration and get a new object based on caetgory: { group: value }
  const totals = calculateByCategoryByGroup('sessionId', 'name', {
    data: processedData,
    valueKey: 'duration'
  });

  // get an object who's keys are the sessionIDs and the values are the start dates
  // this will be used to order the bars by data while also grouping them by id
  // it will be used to populate the tooltip and the bottom axis
  const sessionStartDates =
    groupedData &&
    Object.entries(groupedData).reduce((acc: Record<string, unknown>, [sessionId, items]) => {
      if (!acc?.[sessionId as string]) acc = { ...acc, [sessionId]: items[0]?.changeoverStart };
      return acc;
    }, {});
  // get an array of [{ category: sessionId, label: startDate }] which is ordered by label or date
  const orderSessions = orderSessionsByDate(sessionStartDates);
  // generate a string array of the sessionIds that have been ordered by date, this way we can group by session id
  // and still order by date
  const categories = generateSessionsCategories(orderSessions);

  const legendItems =
    chartData2 &&
    convertObjectArrayToLegendItems(chartData2 as Record<string, unknown>[], {
      colors,
      labelKey: 'group'
    }).sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

  return {
    totals,
    isLoading,
    isFetching,
    hasError: error ? 'Error fetching changeover details' : undefined,
    hasMessage: !isLoading && !data?.length ? 'No data within range' : undefined,
    data: processedData,
    categories,
    groupedData,
    chartData: chartData2,
    colors,
    sessionStartDates,
    legendItems
  };
};
