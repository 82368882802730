export const convertObjectArrayToLegendItems = (
  data: Record<string, unknown>[],
  {
    colors,
    labelKey,
    groupKey,
    valueKey
  }: { colors?: Record<string, string>; labelKey?: string; valueKey?: string; groupKey?: string }
): Record<string, unknown>[] | undefined => {
  groupKey = groupKey || 'group';

  if (typeof data === 'object') data = Object.values(data);

  if (!data) return [];

  if (!valueKey) {
    return data?.map((item) => {
      const label = item?.[labelKey || (groupKey as string)] as string;
      const color = colors?.[label] || item?.color;
      return { label, color, [groupKey]: label, groupKey, [labelKey || label]: label, ...item };
    });
  } else {
    const { totals, grandTotal } = data?.reduce<{
      totals: { [key: string]: number };
      grandTotal: number;
    }>(
      (acc, item) => {
        const label = item?.[(labelKey as string) || groupKey] as string;
        const value = (item?.[valueKey as string] as number) || 1;

        if (!label) return acc;

        acc.totals[label] = (acc.totals[label] || 0) + value;
        acc.grandTotal += value;

        return acc;
      },
      { totals: {}, grandTotal: 0 }
    );

    // Calculate the percentages and round them down
    const items =
      totals &&
      Object.keys(totals).map((group) => {
        const value = (totals?.[group] || 0) / grandTotal;
        const roundedValue = Math.floor(value * 100);
        return {
          [groupKey]: group,
          [labelKey || 'label']: group,
          value: roundedValue,
          color: colors?.[group],
          remainder: value * 100 - roundedValue
        };
      });

    // Calculate the total of the rounded percentages
    const totalPercentage = items?.reduce((total, item) => total + item.value, 0);

    // If the total is less than 100, distribute the remaining percentage points
    if (totalPercentage < 100 && items?.length > 1) {
      // Sort the items by the fractional part of the percentage, in descending order
      items?.sort((a, b) => b.remainder - a.remainder);

      // Distribute the remaining percentage points
      for (let i = 0; i < 100 - totalPercentage; i++) {
        items[i % items.length].value++;
      }
    }

    return items
      .sort((a, b) => b.value - a.value)
      .map((item) => ({ ...item, value: item?.value ? `${item?.value}%` : undefined }));
  }
};
