import React from 'react';
import { IcoClockWithPause, IcoClockWithShield, IcoClockWithSquare } from 'icons/IcoClock';
import { IcoWrench } from 'icons/IcoWrench';
import { IcoCleaning } from 'icons/IcoCleaning';

export const Icons: Record<string, JSX.Element> = {
  idle: <IcoClockWithPause />,
  maintenance: <IcoWrench />,
  cleaning: <IcoCleaning />,
  sterilzation: <IcoClockWithShield />,
  'wait time': <IcoClockWithSquare />
};
