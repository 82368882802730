import React, { useState } from 'react';
import { DimensionsContainer } from 'components';
import { BasePieChart } from './BasePieChart';
import { D3ChartsProps } from '../D3Charts.types';
import { DefaultTooltipWrapper } from '../Tooltips';

export interface D3PieChartProps extends D3ChartsProps {
  data?: Record<string, unknown>[];
  handleClick?: (data: Record<string, unknown>) => void;
  handleSliceClick?: (label: string) => void;
  // this is if you want a Progress Doughnut chart
}

export const D3PieChart = ({
  data,
  width,
  height,
  format,
  TooltipComponent,
  progress,
  ...props
}: D3PieChartProps): JSX.Element => {
  height = Number(height);
  width = Number(width);

  if (!data && !progress) {
    console.log('error: pie chart is missing values');
    return <></>;
  }

  // set defaults dims
  width = width || height || 180;
  height = width;

  const [tooltip, setTooltip] = useState<Record<string, unknown> | undefined>(undefined);

  const handleHover = (type: string, options?: Record<string, unknown>) => {
    if (type === 'exit') return setTooltip(undefined);

    const width = (options?.width || 0) as number;
    const height = (options?.height || width) as number;

    // get the center coords of the slice
    const [left, top] = options?.centerVal as [number, number];

    setTooltip({
      // since the center of the chart is the 0, 0 mark
      // we need to do math to figure out the position of the tooltip
      // base on the wrapping container and send the px values
      left: left + width / 2,
      top: top + height / 2,
      ...(options as Record<string, unknown>)
    });
  };

  return (
    <DimensionsContainer
      // container props
      {...{
        // add custom class name for higher level style targeting
        className: 'd3-pie-chart-wrapper',
        // manually set fixed width and height values since this is a pie chart
        dims: { width, height }
      }}
      // send the component to the container to get actual dims
      Component={(dims) => (
        <>
          {
            // add tooltip if there is formatting or a custom tooltip component
            (TooltipComponent || format?.tooltip) && (
              <DefaultTooltipWrapper
                {...tooltip}
                {...{ TooltipComponent, closeTooltip: () => setTooltip(undefined) }}
              >
                {format?.tooltip && format.tooltip(tooltip as Record<string, unknown>)}
              </DefaultTooltipWrapper>
            )
          }
          <BasePieChart
            {...{
              width: dims.width,
              height: dims.height,
              data,
              progress,
              handleHover: (type, data) => handleHover(type, { ...data, width, height }),
              ...props
            }}
          />
        </>
      )}
    />
  );
};

export default D3PieChart;
