import React, { useMemo } from 'react';
import { MachineView } from './machine/MachineView';
import { FleetPageContainer } from './FleetPage.elements';
import { PageHeader } from './components/PageHeader/PageHeader';
import { MachineInfoProvider, useMachineInfo } from './providers';
import { DataLoader } from 'common/components/DataLoader/DataLoader';
import { showCounters } from './testing_settings';
import { useFleetRouter } from './hooks/useFleetRouter';
import { AccountInfoProvider } from './providers/useAccountInfoProvider';

//-----------------------------------
// NOTES:
// Look into having a customer info provider that has the customer info and the account info
// and timezone
// businessUnit is not required as the page gets the BU from the account info provider
//-----------------------------------

const AcountInfoProvided = () => {
  return (
    <MachineInfoProvider>
      <PageHeader />
      <PageRoutes />
    </MachineInfoProvider>
  );
};

export const FleetPage = (): JSX.Element => {
  return (
    <FleetPageContainer>
      <AccountInfoProvider>
        <AcountInfoProvided />
      </AccountInfoProvider>
      {/*<DiagnosticsModeBar />*/}
    </FleetPageContainer>
  );
};

let FleetPage_PageRoutes_counter = 0;

const PageRoutes = (): JSX.Element => {
  const { isLoading, hasError, hasMessage } = useMachineInfo();

  if (showCounters) console.log({ FleetPage_PageRoutes_counter });
  // get the second path value in the url
  const [{ page, id }] = useFleetRouter();
  console.log({ page, id });
  const CachedRoutes = useMemo(() => {
    ++FleetPage_PageRoutes_counter;
    switch (page) {
      // machine page has the tabs
      case 'machine': {
        return <MachineView />;
      }

      case 'line': {
        return <div>line Page</div>;
      }

      case 'site': {
        return <div>site page</div>;
      }

      case 'dashboard': {
        return <div>dashboard</div>;
      }

      default: {
        return <div>Fleet Page 404</div>;
      }
    }
  }, [page, id]);

  return <DataLoader {...{ isLoading, hasError, hasMessage }} Component={CachedRoutes} />;
};
