// 3rd Party
import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';

// Components
import { BaseSelect, Button, Input, Typography } from 'components';
import { ButtonContainer } from '..';

// Types
import { ChangeEvent, Machine } from 'types';
import { MaintenanceCreator, MaintenanceEvent, MaintenanceEventStatus } from 'types/maintenance';

// Api
import { useCreateMaintenanceEventsMutation } from 'api';

// Helpers
import { toISO8601 } from 'helpers';
import RequiredMark from 'components/RequiredMarker';
import theme from 'themes';
import { ErrorMessage } from '../AssignAndScheduleEvent';
import { ToastMsg } from 'common/components/Toast/Toast';
import { useUser } from 'selectors';

interface FollowUpDetailProps {
  maintenanceEvent?: MaintenanceEvent;
  machines?: Machine[];
  machineId?: string;
  onClose: () => void;
  onSubmitClick: () => void;
  newService?: boolean;
}

// Styling
const Container = styled.div`
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
  position: relative;
`;
const DateField = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    width: 70%;
  }
`;
const DateWithError = styled.div`
  width: 100%;
`;

const InputGroup = styled.div`
  margin-top: 2rem;
  margin-bottom: 0.75rem;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.lightGrey6};
  box-shadow: ${({ theme }) => theme.colors.borders.border02.shadow};
  border-radius: 0.375rem;
`;
const FollowUpDetail = ({
  maintenanceEvent,
  onClose,
  onSubmitClick,
  machines,
  machineId,
  newService
}: FollowUpDetailProps): ReactElement => {
  const user = useUser();
  const [pmDetails, setPMDetails] = useState<Partial<MaintenanceEvent>>(
    maintenanceEvent ? maintenanceEvent : {}
  );
  const getMachineById = useCallback(
    (id: string) => {
      const match = machines?.find((machine) => machine.id === id);
      match &&
        setPMDetails((pmDetails) => ({
          ...pmDetails,
          machineId: id,
          machineDescription: match.description
        }));
    },
    [machines]
  );
  const today = new Date().setHours(0, 0, 0, 0);

  useEffect(() => {
    if (machineId || pmDetails.machineId) {
      getMachineById(machineId || (pmDetails?.machineId as string));
    } else {
      setPMDetails((pmDetails) => ({
        ...pmDetails,
        machineId: undefined,
        machineDescription: undefined
      }));
    }
  }, [getMachineById, machineId]);

  const { t } = useTranslation(['fpns', 'common']);
  const isValid = (): boolean => {
    const valid = Boolean(
      pmDetails?.priority &&
        pmDetails?.machineDescription &&
        pmDetails?.subcomponent &&
        pmDetails?.suggestedDue &&
        pmDetails?.machineId &&
        pmDetails?.description
    );
    return valid;
  };
  type propNames =
    | 'priority'
    | 'machineDescription'
    | 'subcomponent'
    | 'description'
    | 'suggestedDue'
    | 'machineId';
  const priorityOptions = ['1', '2', '3'];

  const handlePMDetailsUpdate = (pm: MaintenanceEvent) => {
    setPMDetails(pm);
  };

  const getMachineId = (id: string) => {
    machines?.find((machine) => {
      if (machine.description === id) {
        setPMDetails({ ...pmDetails, machineId: machine.id, machineDescription: id });
      }
    });
  };

  // Needed to manipulate Date into type String
  const copyPMInfo = (
    pm: Partial<MaintenanceEvent> | undefined,
    propName: propNames,
    value: string | number | Date | boolean
  ) => {
    const copyPMDetails = { ...pmDetails, [propName]: value } as MaintenanceEvent;
    handlePMDetailsUpdate(copyPMDetails);
  };

  const handleChange = (value: string | Date | number | boolean, propName: propNames) => {
    setPMDetails({ ...pmDetails, [propName]: value });
  };

  const handleDayChange = (selectedDay: Date) => {
    copyPMInfo(pmDetails, 'suggestedDue', toISO8601(selectedDay));
  };

  const [createEvent] = useCreateMaintenanceEventsMutation();
  const isValidDate = new Date(pmDetails.suggestedDue || '') >= new Date(today);
  return (
    <Container>
      <Typography as="h4" mb={'.2rem'} size="1rem" weight="semi-bold">
        {t('priority_level')} <RequiredMark color={theme.colors.text.error} size={'1.3rem'} />
      </Typography>
      <BaseSelect
        variant={'white'}
        value={pmDetails?.priority ? pmDetails.priority?.toString() : '0'}
        handleChange={(event): void => {
          handleChange(event.target.value, 'priority');
        }}
        options={priorityOptions}
        placeholder={t('select_dot', { ns: 'common' }) as string}
        id="priority"
      />{' '}
      <br />
      <Typography as="h4" mb={'.2rem'} size="1rem" weight="semi-bold">
        {t('machine')} <RequiredMark color={theme.colors.text.error} size={'1.3rem'} />
      </Typography>
      {!newService ? (
        <Input
          type="string"
          id="machineDescription"
          variant="white"
          value={pmDetails?.machineDescription}
          onChange={(event: ChangeEvent) => {
            handleChange(event.target.value, 'machineDescription');
          }}
        />
      ) : (
        <BaseSelect
          variant={'white'}
          value={pmDetails?.machineDescription ? pmDetails.machineDescription : 'Machine'}
          handleChange={(event) => {
            getMachineId(event.target.value);
          }}
          options={machines ? machines.map((m) => m.description) : []}
          placeholder={t('select_dot', { ns: 'common' }) as string}
          id="machine"
        />
      )}
      <br />
      <Typography as="h4" mb={'.2rem'} size="1rem" weight="semi-bold">
        {t('subcomponent')} <RequiredMark color={theme.colors.text.error} size={'1.3rem'} />
      </Typography>
      <Input
        type="string"
        id="subcomponent"
        variant="white"
        value={pmDetails?.subcomponent}
        onChange={(event: ChangeEvent) => {
          if (event.target.value[0] !== ' ') handleChange(event.target.value, 'subcomponent');
        }}
      />
      <br /> <br />
      <DateField>
        <Typography as="h4" mb={'.2rem'} size="1rem" weight="semi-bold">
          {t('suggested_date')}
        </Typography>
        <DateWithError>
          <Input
            type="date"
            max="9999-12-31"
            onChange={(e: ChangeEvent) => {
              handleDayChange(e.target.value as unknown as Date);
            }}
            min={new Date().toISOString().split('T')[0]}
          />
          {!isValidDate && <ErrorMessage>{`Date must be today's or later`}</ErrorMessage>}
        </DateWithError>
      </DateField>
      <InputGroup>
        <Typography as="h4" mb={'.2rem'} size="1rem" weight="semi-bold">
          {t('description', { ns: 'common' })}{' '}
          <RequiredMark color={theme.colors.text.error} size={'1.3rem'} />
        </Typography>
        <StyledTextarea
          rows={6}
          value={pmDetails?.description}
          onChange={(event) => {
            if (event.target.value.length <= 1000)
              handleChange(event.target.value as string, 'description');
          }}
        />
        <Typography>1000 characters max</Typography>
      </InputGroup>
      <ButtonContainer>
        <Button
          variant="link"
          onClick={onClose}
          borderRadius="3rem"
          borderColor={theme.colors.primaryBlue5}
        >
          {t('cancel', { ns: 'common' })}
        </Button>
        <Button
          variant="hover-blue"
          borderRadius="3rem"
          bgColor={theme.colors.primaryBlue5}
          color={theme.colors.lightGrey3}
          onClick={() => {
            createEvent([
              {
                description: pmDetails?.description,
                machineID: pmDetails?.machineId,
                subcomponent: pmDetails?.subcomponent,
                suggestedDue: pmDetails?.suggestedDue,
                status: MaintenanceEventStatus.NotComplete,
                creator: MaintenanceCreator.User,
                owner: user?.id,
                priority: pmDetails.priority
              }
            ]).then(() => {
              ToastMsg({
                message: t('maintenance_event_created'),
                heading: t('created') as string,
                theme: 'colored',
                type: 'success'
              });
              onSubmitClick();
              onClose();
            });
          }}
          disabled={!isValid()}
        >
          {t('confirm', { ns: 'common' })}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default FollowUpDetail;
