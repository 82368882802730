// 3rd party libs
import React, { useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import Tooltip from 'rc-tooltip';
import { Series } from 'types/graph';
import { SettingIcon } from 'icons/SettingIcon';

// Components
import { Card, Typography, PermissionWrapper } from 'components';
import { AdminCardEditButton } from 'components/machine-health';
import TagGroup from './TagGroup';
import ChartTagData from './ChartTagData';

// Types
import { BaseTag, BaseTagDataType, ConfiguredWidget } from 'types/protein';
import { PermissionScopeName } from 'types/user-management';
import { Role, UserScopes } from 'types';

// Providers
import { useDate, useTimeZone } from 'providers';

// Utils
import { formatLineSeriesTooltip, toLineSeries } from './utils';
import SingleGraph from './SingleGraph';
import { useTranslation } from 'react-i18next';

interface Props {
  data: ConfiguredWidget;
  machineId: string;
}
interface LineGraphData {
  chartTags?: string[];
  booleanTags: BaseTag[];
  numberTags: BaseTag[];
  stringTags: BaseTag[];
  booleanSeries: Series[];
  numberSeries: Series[];
  error: Record<string, FetchBaseQueryError | SerializedError | undefined>;
  statesData: BaseTag[];
  loading: Record<string, boolean>;
}
interface LineGrapghGroupData {
  chartId: string;
  data: LineGraphData;
}
const StyledCard = styled(Card)`
  background: ${({ theme }) => theme.colors.white};
  position: relative;
`;

const HeaderInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const BodyInner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const EditButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const IconButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

const MatrixWidget = ({ data, machineId }: Props): JSX.Element => {
  const { timeZone } = useTimeZone();
  const { startTime, endTime } = useDate();
  const theme = useTheme();

  const [chartForWidgetId, setChartForWidgetId] = useState('');
  const [chartForWidgetIds, setChartForWidgetIds] = useState<string[]>([]);
  const [chartTags, setChartTags] = useState<string[]>();
  const [tagData, setTagData] = useState<BaseTag[]>([]);
  const [lineGraphGroupData, setLineGraphGroupData] = useState<LineGrapghGroupData[]>([]);
  const [statesData, setStatesData] = useState<BaseTag[]>([]);
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [error, setError] = useState<
    Record<string, FetchBaseQueryError | SerializedError | undefined>
  >({});
  const [isEditAdminPopupOpen, setIsEditAdminPopupOpen] = useState<boolean>(false);
  const { t } = useTranslation(['mh']);
  const { numberTags, stringTags, booleanTags } = useMemo(() => {
    const booleanTags: BaseTag[] = [];
    const numberTags: BaseTag[] = [];
    const stringTags: BaseTag[] = [];

    tagData?.forEach((tag) => {
      if (tag.meta?.dataType === BaseTagDataType.Boolean) {
        booleanTags.push(tag);
      } else if (tag.values.every((value) => typeof value.value === 'number')) {
        numberTags.push(tag);
      } else if (tag.values.every((value) => typeof value.value === 'string')) {
        stringTags.push(tag);
      }
    });

    return { booleanTags, numberTags, stringTags };
  }, [tagData]);

  const { numberSeries, booleanSeries } = useMemo(() => {
    const numberSeries = formatLineSeriesTooltip(toLineSeries(numberTags), timeZone);
    const booleanSeries = formatLineSeriesTooltip(toLineSeries(booleanTags), timeZone);
    return { numberSeries, booleanSeries };
  }, [booleanTags, numberTags, timeZone]);

  const finalData = useMemo(() => {
    if (chartForWidgetId !== '') {
      if (chartForWidgetIds.length !== 0) {
        const newData = {
          chartId: chartForWidgetId,
          data: {
            chartTags: chartTags,
            booleanTags: booleanTags,
            numberTags: numberTags,
            stringTags: stringTags,
            booleanSeries: booleanSeries,
            numberSeries: numberSeries,
            error: error,
            statesData: statesData,
            loading: loading
          }
        };
        // Add the new data to the existing finalData array
        return [newData];
      }
    }
    return [];
  }, [chartForWidgetId, tagData, chartForWidgetIds]);

  const shouldUpdateDataArray = chartForWidgetId !== '' && chartForWidgetIds.length !== 0;

  useEffect(() => {
    if (shouldUpdateDataArray) {
      const existingObjectIndex = lineGraphGroupData.findIndex(
        (obj) => obj.chartId === chartForWidgetId
      );
      const newDataArray: LineGrapghGroupData[] = [...lineGraphGroupData];

      if (existingObjectIndex !== -1) {
        // Update the state
        newDataArray[existingObjectIndex] = finalData[0];
      } else {
        // Create a new object and push it to the array
        newDataArray.push(...finalData);
      }

      setLineGraphGroupData(newDataArray);
    }
  }, [finalData]);

  return (
    <StyledCard borderColor={theme.colors.mediumGrey1}>
      <EditButtonContainer>
        <Tooltip placement="top" overlay="Edit">
          <IconButton onClick={() => setIsEditAdminPopupOpen(true)}>
            <SettingIcon />
          </IconButton>
        </Tooltip>
      </EditButtonContainer>
      <Card.Header bgColor={theme.colors.white}>
        <HeaderInner>
          <Typography weight="bold" as="h3" size="1.125rem" mb={0}>
            {t(data.name as string)}
          </Typography>
          {data.id && (
            <PermissionWrapper
              page={PermissionScopeName.FLEET}
              scope={UserScopes.Write}
              role={Role.Admin}
            >
              <AdminCardEditButton
                isEditAdminPopupOpen={isEditAdminPopupOpen}
                machineId={machineId}
                setIsEditAdminPopupOpen={setIsEditAdminPopupOpen}
                widgetId={data.id}
              />
            </PermissionWrapper>
          )}
        </HeaderInner>
      </Card.Header>
      <Card.Body pt={0}>
        <BodyInner>
          {data.members?.map((tagGroup) => (
            <TagGroup
              key={tagGroup.id}
              data={tagGroup}
              dataLoading={Object.values(loading).includes(true) ? true : false}
              isChartVisible={lineGraphGroupData?.map((data) => data.chartId).includes(tagGroup.id)}
              onToggleChart={() => {
                if (lineGraphGroupData?.map((data) => data.chartId).includes(tagGroup.id)) {
                  setChartForWidgetId('');
                  setChartTags(undefined);
                  setLineGraphGroupData((data) =>
                    data?.filter((chartWidgetData) => chartWidgetData.chartId !== tagGroup.id)
                  );
                } else {
                  setChartForWidgetId(tagGroup.id);
                  setChartTags(tagGroup.tags?.map((tag) => tag.id));
                  setChartForWidgetIds([...chartForWidgetIds, tagGroup.id]);
                }
                setTagData([]);
              }}
            />
          ))}
        </BodyInner>
        {lineGraphGroupData &&
          lineGraphGroupData?.map((fData) => {
            return (
              <>
                {fData.data && data && (
                  <SingleGraph
                    chartForWidgetId={fData.chartId}
                    chartTags={fData.data.chartTags}
                    booleanTags={fData.data.booleanTags}
                    numberTags={fData.data.numberTags}
                    stringTags={fData.data.stringTags}
                    booleanSeries={fData.data.booleanSeries}
                    numberSeries={fData.data.numberSeries}
                    data={data}
                    error={fData.data.error}
                    statesData={fData.data.statesData}
                    loading={fData.data.loading}
                    key={fData.chartId}
                  />
                )}
              </>
            );
          })}
      </Card.Body>
      {chartTags?.map((tagId) => (
        <ChartTagData
          currentTagValue={tagData.find((tag) => tag.id === tagId)}
          endTime={endTime}
          key={tagId}
          setError={setError}
          setLoading={setLoading}
          setStatesData={setStatesData}
          setTagData={setTagData}
          startTime={startTime}
          tagId={tagId}
        />
      ))}
    </StyledCard>
  );
};

export default MatrixWidget;
