import React from 'react';
import styled from 'styled-components';
import { styledTheme } from 'components';

const Svg = styled.svg.attrs(({ width, color }) => ({
  width: width || `100%`,
  height: `auto`,
  viewBox: '0 0 384 512',
  fill: color || styledTheme.color.main
}))``;

export const IconX = ({
  width,
  color,
  srLabel
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg
      {...{ width, color }}
      className="icon--search icon"
      aria-hidden="true"
      focusable="false"
      aria-label={srLabel || `close`}
    >
      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
    </Svg>
  );
};
