import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { ZoomObjectTuples } from 'types';

/**
 * Handle zooming Behaviour for all the children graphs where the flag 'sync = true'
 */

type ContextType = {
  zoomedDomain?: ZoomObjectTuples;
  resetZoom: () => void;
  onBrushDomainChangeEnd: (domain: ZoomObjectTuples) => void;
  undoZoom: () => void;
};

const defaultValue = {
  resetZoom: () => undefined,
  onBrushDomainChangeEnd: () => undefined,
  undoZoom: () => undefined
};

const DateContext = createContext<ContextType>(defaultValue);

export const useSyncZoom = (): ContextType => {
  return useContext(DateContext);
};

type Props = {
  children: ReactNode;
};

export const SyncZoomProvider = (props: Props): JSX.Element => {
  const [zoomedDomain, setZoomedDomain] = useState<ZoomObjectTuples>({
    x: undefined,
    y: undefined
  });
  const [zoomHistory, setZoomHistory] = useState<ZoomObjectTuples[]>([]);

  const onBrushDomainChangeEnd = (domain: ZoomObjectTuples) => {
    setZoomedDomain(domain);
  };

  const resetZoom = () => {
    setZoomedDomain({ x: undefined, y: undefined });
    setZoomHistory([]);
  };

  const undoZoom = () => {
    if (zoomHistory.length > 0) {
      const previousZoom = zoomHistory.slice(0, -1);
      setZoomedDomain(previousZoom[previousZoom.length - 1]);
      setZoomHistory(previousZoom);
      console.log(previousZoom, 'previousZoom');
    }
  };

  const memoizedSetZoomHistory = useCallback((newZoomedDomain) => {
    setZoomHistory((prevZoomHistory) => [...prevZoomHistory, newZoomedDomain]);
  }, []);

  useEffect(() => {
    // Check if zoomedDomain is defined and not equal to the last zoomedDomain
    if (zoomedDomain && zoomedDomain !== zoomHistory[zoomHistory.length - 1]) {
      // Update zoom history using memoized function
      memoizedSetZoomHistory(zoomedDomain);
    }
  }, [zoomedDomain, memoizedSetZoomHistory, zoomHistory]);

  const value = {
    zoomedDomain,
    onBrushDomainChangeEnd,
    resetZoom,
    undoZoom
  };
  return <DateContext.Provider value={value}>{props.children}</DateContext.Provider>;
};
