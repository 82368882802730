import styled from 'styled-components';
import { themeColors } from 'themes';

// Styled components

export const NavItem = styled.div`
  display: block;
  text-align: left;
  border: 0;
  width: 100%;
  a {
    display: block;
    margin: 0 1rem;
    line-height: inherit;
    cursor: pointer;
    text-align: center;
  }
  ul {
    list-style: none;
    z-index: 999;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const NavMenu = styled.ul`
  line-height: 2.813rem;
  display: flex;
  align-items: start;
  font-size: 0.8125rem;
  .nav__menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0rem 0.2rem 0rem 0rem;
    cursor: pointer;
    border-radius: 0;
  }
  .icon {
    padding-left: 1rem;
  }
`;

export const Tab = styled.span<{ $isSelected?: boolean }>`
  color: ${(props) => (props.$isSelected ? themeColors.primaryBlue5 : themeColors.lightGrey8)};
  border: 0;
  font-size: 0.885rem;
  margin-left: 1rem;
  font-weight: ${(props) => (props.$isSelected ? '600' : '400')};
  border-bottom: 2px solid ${(props) => (props.$isSelected ? themeColors.primaryBlue5 : 'none')};
  margin-bottom: 10px;
  padding-bottom: 0;
  padding-right: 0.5rem;
  display: flex;
  span {
    padding-left: 10px;
  }
`;
