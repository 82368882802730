import styled from 'styled-components';

const CTAContainer = styled.div`
  display: flex;

  > * + * {
    margin-left: 0.5rem;
  }
`;

export const CTAsRight = styled(CTAContainer)`
  justify-content: flex-end;
`;

export const CTAsStretch = styled(CTAContainer)`
  justify-content: space-between;
`;

export const IndicatorContainer = styled.div`
  position: absolute;
  background: #fff;
  top: -0.9rem;

  > * {
    margin-left: 0;
  }
`;

export const Table = styled.table<{ mb?: string; col?: number }>`
  width: 100%;
  margin-bottom: ${({ mb }) => mb || '0'};

  td {
    padding: 0.5rem 0;

    &:first-child {
      width: ${({ col }) => {
        return col ? 100 / col + '%' : '33%';
      }};
    }
  }
`;
