// map keys is used to map a key to a value, to rename that key
// example, if your return is "prod_down" and you want to rename it to "production downtime", for a chart or legend item or color
// you would add the following to the mapKeys object { prod_down: 'production downtime' }
// this well be used when processing the api call.  the new values should match up with the keys in the colors object and icons as well
export const mapKeys: Record<string, string> = {
  Mainten: 'maintenance',
  Prod: 'production uptime',
  Steril: 'sterilization',
  Sterilization: 'sterilization',
  Cleaning: 'cleaning',
  mainten: 'maintenance',
  Maintenance: 'maintenance',
  Sterilisation: 'sterilization',
  Waittime: 'wait time',
  Idle: 'idle',
  prod: 'production uptime',
  steril: 'sterilization',
  production_downtime: 'production downtime',
  productionDowntime: 'production downtime',
  Filling: 'filling',
  'Screw Capping': 'screw capping',
  'Wait time': 'wait time',
  Availability_Rate: 'availability',
  Performance_Rate: 'performance',
  Quality_Rate: 'quality'
};
