import React, { ReactElement, forwardRef } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { Typography } from 'components';
import { getBackgroundColor, getBorderColor } from 'helpers';
import theme from 'themes';

interface Props {
  heading?: string;
  children?: React.ReactNode;
  status?: string;
  height?: string;
  mb?: string | number;
  icon?: ReactElement;
  removeHeaderMargin?: boolean;
  rightContent?: ReactElement;
  // Custom header
  component?: JSX.Element;
  // Custom styling
  style?: CSSProperties;
  className?: string;
  hideHeaderBackgroundColor?: boolean;
  hideBorder?: boolean;
  showHeaderBottom?: boolean;
}

interface ContainerProps {
  removeHeaderMargin?: boolean;
  status?: string;
  hideHeaderBackgroundColor?: boolean;
  hideBorder?: boolean;
  showHeaderBottom?: boolean;
}

type Ref =
  | ((instance: HTMLDivElement | null) => void)
  | React.RefObject<HTMLElement>
  | null
  | undefined;

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  border: ${(props) =>
    !props.hideBorder && `0.0625rem solid ${getBorderColor((props && props.status) ?? '')}`};
  border-radius: 0.625rem;
  overflow: auto;
  height: ${(props: Props) => (props.height ? props.height : 'auto')};
  margin-bottom: ${(props: Props) => (props.mb ? props.mb : '0')};
`;

const Header = styled.header<ContainerProps>`
  padding: 1.25rem;
  background-color: ${(props) =>
    !props.hideHeaderBackgroundColor && getBackgroundColor((props && props.status) ?? '')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: 0;
  }

  ${(props) =>
    !props.removeHeaderMargin &&
    `> :first-child {
    margin-right: 1rem;
  }`}
  border-bottom: ${(props) =>
    props.showHeaderBottom && ` 0.0625rem solid ${theme.colors.lightMediumGrey}`};
`;

const KPICard = (
  {
    heading,
    children,
    removeHeaderMargin,
    hideHeaderBackgroundColor,
    hideBorder,
    showHeaderBottom,
    rightContent,
    status,
    component,
    icon,
    className,
    ...rest
  }: Props,
  ref: Ref
): JSX.Element => (
  <Container
    hideBorder={hideBorder}
    status={status}
    {...rest}
    className={className ? `kpi-card ${className}` : `kpi-card`}
  >
    {(component || heading) && (
      <Header
        hideHeaderBackgroundColor={hideHeaderBackgroundColor}
        removeHeaderMargin={removeHeaderMargin}
        showHeaderBottom={showHeaderBottom}
        status={status}
        ref={ref}
      >
        {component ? (
          component
        ) : (
          <>
            <Typography variant="h3">
              {heading} {icon}
            </Typography>
            {rightContent}
          </>
        )}
      </Header>
    )}
    {children}
  </Container>
);

export default forwardRef(KPICard);
