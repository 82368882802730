import React from 'react';

export const IcoBlueBell = (): JSX.Element => {
  return (
    <svg
      className="icon icon--blue-bell"
      width="124"
      height="131"
      viewBox="0 0 124 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_10268_3979)">
        <g filter="url(#filter1_d_10268_3979)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.185 10.4171C62.0883 9.48713 58.8584 9 55.6111 9C36.8867 9 21.6536 24.5863 21.6536 43.7449V76.2901C21.6536 81.6151 19.3839 86.6546 15.4248 90.1127C13.2477 92.0136 12 94.7854 12 97.7152C12 97.9302 12.1709 98.1063 12.3823 98.1063H98.84C99.0501 98.1063 99.2222 97.9302 99.2222 97.714C99.2222 94.7842 97.9734 92.0136 95.7962 90.1115C91.8383 86.6522 89.5686 81.6138 89.5686 76.2888V73.6125C87.5575 73.9778 85.4855 74.1686 83.369 74.1686C64.3337 74.1686 48.9025 58.7374 48.9025 39.702C48.9025 27.3411 55.4095 16.4999 65.185 10.4171ZM55.612 114.674C48.0833 114.674 41.6907 109.614 39.5052 102.645H71.7177C69.5322 109.614 63.1395 114.674 55.612 114.674Z"
            fill="#0076CC"
          />
        </g>
        <g filter="url(#filter2_i_10268_3979)">
          <path
            d="M97.5026 14.2271C101.876 16.7523 105.514 20.3758 108.057 24.7391C110.599 29.1023 111.958 34.054 111.999 39.1038C112.04 44.1537 110.761 49.1266 108.289 53.5304C105.817 57.9341 102.238 61.616 97.9063 64.2114C93.5743 66.8069 88.6396 68.2259 83.5906 68.3281C78.5416 68.4303 73.5535 67.2121 69.12 64.794C64.6865 62.376 60.9614 58.8419 58.3135 54.5418C55.6656 50.2417 54.1866 45.3245 54.023 40.2772L54.0085 39.3377L54.023 38.3982C54.1854 33.3906 55.6426 28.5105 58.2525 24.2336C60.8624 19.9568 64.536 16.4292 68.9151 13.9948C73.2942 11.5604 78.2294 10.3022 83.2395 10.3429C88.2496 10.3837 93.1636 11.7219 97.5026 14.2271ZM93.7534 31.4885C93.2541 30.9892 92.5898 30.6893 91.8851 30.645C91.1805 30.6007 90.4838 30.815 89.9259 31.2478L89.6534 31.4885L80.105 41.034L76.3558 37.2877L76.0832 37.047C75.5253 36.6145 74.8288 36.4005 74.1243 36.4449C73.4198 36.4894 72.7557 36.7893 72.2565 37.2884C71.7574 37.7876 71.4574 38.4517 71.413 39.1562C71.3685 39.8607 71.5826 40.5572 72.0151 41.1152L72.2557 41.3877L78.055 47.1869L78.3275 47.4276C78.836 47.8221 79.4614 48.0363 80.105 48.0363C80.7486 48.0363 81.3739 47.8221 81.8824 47.4276L82.155 47.1869L93.7534 35.5885L93.9941 35.3159C94.4268 34.7581 94.6412 34.0614 94.5969 33.3567C94.5526 32.6521 94.2526 31.9878 93.7534 31.4885Z"
            fill="#29A429"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_10268_3979"
          x="0"
          y="0.77"
          width="124"
          height="129.674"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.77" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10268_3979" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10268_3979"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_10268_3979"
          x="6.78"
          y="5.16306"
          width="97.6622"
          height="116.114"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.38306" />
          <feGaussianBlur stdDeviation="2.61" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.47 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10268_3979" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10268_3979"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_i_10268_3979"
          x="54.0085"
          y="10.342"
          width="57.9915"
          height="59.9921"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3.575" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.00916667 0 0 0 0 0.55 0 0 0 0 0.00916667 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_10268_3979" />
        </filter>
      </defs>
    </svg>
  );
};
