import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(() => ({
  height: 16,
  viewBox: '0 0 10 16',
  fill: 'none'
}))``;

export const IcoChevRight = ({
  color,
  width = 10
}: {
  width?: number;
  color?: string;
}): JSX.Element => {
  return (
    <Svg width={width} className="icon__chev--right">
      <path
        d="M1.67187 1L8.67187 8L1.67188 15"
        stroke={color || '#303E47'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
