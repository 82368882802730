import React from 'react';

interface IconExpandProps {
  strokeColor?: string;
}

export const IconExpand: React.FC<IconExpandProps> = ({ strokeColor = '#0000000' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2394_1342)">
        <path
          d="M3 19L20 19"
          stroke={strokeColor}
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 12L13 12"
          stroke={strokeColor}
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 5L20 5"
          stroke={strokeColor}
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 15L16 12L20 9"
          stroke={strokeColor}
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2394_1342">
          <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};
