import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoExpand = ({
  width = '24',
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66602 5.33332V2.66666M2.66602 2.66666H5.33268M2.66602 2.66666L5.99935 5.99999M13.3327 5.33332V2.66666M13.3327 2.66666H10.666M13.3327 2.66666L9.99935 5.99999M2.66602 10.6667V13.3333M2.66602 13.3333H5.33268M2.66602 13.3333L5.99935 9.99999M13.3327 13.3333L9.99935 9.99999M13.3327 13.3333V10.6667M13.3327 13.3333H10.666"
        stroke={color || '#0076CC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
