import React from 'react';
import InternalError from 'img/InternalError.png';
import { IcoBlueBell } from 'icons/IcoBlueBell';
import BlueGears from 'img/BlueGears.png';

export const DataLoaderIcons: Record<string, JSX.Element> = {
  'no alerts': <IcoBlueBell />,
  'no data': <img src={BlueGears} alt="no data" className="icon icon--no-data" />,
  hasError: <img src={InternalError} alt="internal error" className="icon icon--internal-error" />
};
