// 3rd party libs
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { skipToken } from '@reduxjs/toolkit/query';

// Components
import Scope from 'pages/UserManagementTwo/components/Group/AddEditGroup/Scope';
import Permission from 'pages/UserManagementTwo/components/Group/AddEditGroup/Permission';

import GroupInfo, {
  SectionRule
} from 'pages/UserManagementTwo/components/Group/AddEditGroup/GroupInfo';
import { DataRenderer } from 'components/machine-health';

// Types
import { BUOrganization, BusinessUnit, Id } from 'types';
import { GroupItem, GroupOrganization, GroupScope, UserFormElement } from 'types/user-management';
import { User } from 'types/user-management';
import {
  useGetCountByBusinessUnitsQuery,
  useGetLinesQuery,
  useGetOrganizationsQuery,
  useGetPlantsQuery,
  useGetUserManagementGroupByIdV2Query
} from 'api';
import { GroupModalFooter } from 'pages/UserManagementTwo/components/index';
import { getScopeCounts } from 'pages/UserManagement/components/Group/AddEditGroup/Scope/ScopeUtils';
import { isAlphaNumeric } from 'helpers';
import Collapse from 'rc-collapse';
import {
  MenuPanel,
  expandIcon
} from 'pages/MaintenanceServiceDashboard/MaintenanceQueryTableByFrequency';
import GroupUsers from './GroupUsers/Index';

interface Props {
  id?: Id;
  setIsChanged: (isChanged: boolean) => void;
  onClose: () => void;
  onCloseVerifyData: () => void;
  initialGroupInfo: GroupItem;
  isActiveUserPromptMode: boolean;
  setGroupName: (groupName: string) => void;
  isChanged?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 0.5rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;
`;
export const Header = styled.div`
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 1rem;
`;
export const MandatoryIndicator = styled.div`
  color: #b62c10;
  padding-left: 5px;
`;
export const HeaderWrapper = styled.div`
  display: flex;
`;
const UserCount = styled.div`
color: color: ${({ theme }) => theme.colors.text.lightBlack};
font-family: Roboto;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 0.25rem;
`;
const getUserToFormElement = (users: User[]): UserFormElement[] => {
  return users.map((u) => {
    return { id: u.id, validationState: {}, user: u } as UserFormElement;
  });
};
const updateSelectedBUsInEditMode = (
  BUs: BusinessUnit[],
  selectedBus: BusinessUnit[]
): BusinessUnit[] => {
  selectedBus.forEach((bu) => {
    const unit = BUs.find((b) => b.id === bu.id);
    if (unit) {
      bu = {
        ...bu,
        organizationCount: unit.organizationCount,
        plantCount: unit.plantCount,
        machineCount: unit.machineCount
      };
    }
  });
  return selectedBus;
};
const getSelectedBUsCountInEditMode = (
  BUs: BusinessUnit[],
  selectedBus: BusinessUnit[]
): { locations: number; lines: number; machines: number } => {
  const counts = { locations: 0, lines: 0, machines: 0 };
  selectedBus.forEach((item) => {
    const unit = BUs?.find((bu) => bu.id === item.id);
    if (unit) {
      counts.locations += unit?.organizationCount as number;
      counts.lines += unit?.plantCount as number;
      counts.machines += unit?.machineCount as number;
    }
  });
  return counts;
};
const AddEditGroup = ({
  id,
  initialGroupInfo,
  setIsChanged,
  onClose,
  onCloseVerifyData,
  isActiveUserPromptMode,
  setGroupName,
  isChanged
}: Props): ReactElement => {
  const { data: allOrganizations, isFetching: isLoadingOrganizations } = useGetOrganizationsQuery();
  const { data: allPlants, isFetching: isLoadingPlants } = useGetPlantsQuery();
  const { data: lines, isFetching: isLoadingLines } = useGetLinesQuery({ plantIds: [] });
  const {
    data: groupData,
    error: groupError,
    isLoading: isLoadingGroup
  } = useGetUserManagementGroupByIdV2Query(id ?? skipToken);
  const { data: businessUnits, isLoading: bizUnitLoading } = useGetCountByBusinessUnitsQuery();
  const getPanels = [
    { title: 'Scope', isRequire: true },
    { title: 'Permissions', isRequire: true },
    { title: 'User', isRequire: false }
  ];
  const [isVisibleScopeDetail, setIsVisibleScopeDetail] = useState(false);
  const initialGroupData = groupData ?? initialGroupInfo;
  const [groupInfoState, setGroupInfoState] = useState<GroupItem>(initialGroupData);
  const [activePanelKeys, setActivePanelKeys] = useState<React.Key[]>([
    'Scope',
    'Permission',
    'User'
  ]);
  const [groupUsers, setGroupUsers] = useState<UserFormElement[]>([]);
  const [scopeState, setScopeState] = useState<GroupScope>({
    allOrganizations: false,
    allPlants: false,
    allMachines: false,
    organizations: [],
    plants: [],
    machines: []
  });
  const [businessUnitsCount, setBusinessUnitsCount] = useState({
    locations: 0,
    lines: 0,
    machines: 0
  });
  const [selectedBUs, setSelectedBUs] = useState<BusinessUnit[]>([]);
  const [buSelectionVisible, setBuSelectionVisible] = useState<boolean>(false);
  const groupDataScope = {
    organizations: groupData?.organizations ?? [],
    plants: groupData?.plants ?? [],
    machines: groupData?.machines ?? [],
    allOrganizations: groupData?.allOrganizations ?? false,
    allPlants: groupData?.allPlants ?? false,
    allMachines: groupData?.allMachines ?? false
  };

  const [counts, setCounts] = useState({ organizations: '0', plants: '0', machines: '0' });

  useEffect(() => {
    setGroupInfoState(groupData ?? initialGroupInfo);
    if (id) {
      setScopeState(
        groupData
          ? { ...scopeState, organizations: groupData.organizations as GroupOrganization[] }
          : { ...scopeState }
      );
      setGroupUsers(
        groupData?.users ? getUserToFormElement(groupData?.users as User[]) : groupUsers
      );
    }
    if (groupData) {
      if (groupData.isInternal && businessUnits) {
        setSelectedBUs(
          updateSelectedBUsInEditMode(businessUnits, groupData.businessUnits as BusinessUnit[])
        );
        setBuSelectionVisible(true);
        setBusinessUnitsCount(
          getSelectedBUsCountInEditMode(businessUnits, groupData.businessUnits as BusinessUnit[])
        );
      } else {
        setIsVisibleScopeDetail(true);
        setCounts(getScopeCounts(allOrganizations ?? [], allPlants ?? [], groupDataScope));
      }
      setGroupName(groupData.name);
    }
  }, [groupData, businessUnits]);

  const isLoadingAddEditGroup = id
    ? isLoadingGroup || isLoadingOrganizations || isLoadingPlants || isLoadingLines
    : isLoadingOrganizations || isLoadingPlants || isLoadingLines;

  const onChangeAccessGroup = (isInternal: boolean) => {
    // When access Group changes, set scope detail to initial
    const scope = {
      organizations: [],
      plants: [],
      machines: [],
      allOrganizations: false,
      allPlants: false,
      allMachines: false
    };
    setCounts({ organizations: '0', plants: '0', machines: '0' });
    setGroupInfoState({
      ...scope,
      id: id,
      isInternal: isInternal,
      name: groupInfoState.name,
      permissions: groupInfoState.permissions
    });
    setBuSelectionVisible(false);
    setSelectedBUs([]);
    setBusinessUnitsCount({ locations: 0, lines: 0, machines: 0 });
    setIsVisibleScopeDetail(false);
  };
  const updateGroupData = (userElements: UserFormElement[]): void => {
    setGroupUsers([...userElements]);
    setGroupInfoState({
      ...groupInfoState,
      users: userElements.map((e) => e.user)
    });
  };
  const setBUsAndUpdateInfoState = (BUs: BusinessUnit[]): void => {
    setSelectedBUs([...BUs]);
    setGroupInfoState({
      ...groupInfoState,
      businessUnits: getFilteredBUs(BUs),
      organizations: getFilteredOrgs(BUs)
    });
  };
  const getFilteredBUs = (BUs: BusinessUnit[]): { id: number }[] => {
    return BUs.map((b) => {
      return { id: b.id };
    });
  };
  const getFilteredOrgs = (BUs: BusinessUnit[]): GroupOrganization[] => {
    let BUOrgs: BUOrganization[] = [];
    BUs.forEach((b) => {
      const v = b.organizations as BUOrganization[];
      BUOrgs = [...BUOrgs, ...v];
    });
    return BUOrgs.map((b) => {
      const org: GroupOrganization = {
        organizationId: '',
        value: '',
        label: '',
        allPlants: true,
        allMachines: true,
        plants: [],
        machines: [],
        selectedPlants: []
      };
      return { ...org, organizationId: b.id, value: b.id, label: b.name };
    });
  };
  return (
    <Container>
      <FormContainer>
        <DataRenderer
          isLoading={isLoadingAddEditGroup}
          error={id && groupError ? 'Failed to Load' : ''}
        >
          <GroupInfo
            groupInformation={{
              name: groupInfoState.name,
              isInternal: groupInfoState.isInternal as boolean
            }}
            onGroupNameChange={(groupName: string) => {
              isAlphaNumeric(groupName) &&
                setGroupInfoState({
                  ...groupInfoState,
                  name: groupName.trimStart()
                });
              setIsChanged(true);
            }}
            onGroupAccessChange={(isInternal: boolean) => {
              onChangeAccessGroup(isInternal);
              setIsChanged(true);
            }}
          />
          <Collapse
            activeKey={activePanelKeys}
            onChange={(key: React.Key | React.Key[]) =>
              setActivePanelKeys(Array.isArray(key) ? key : [key])
            }
            destroyInactivePanel={true}
            expandIcon={expandIcon}
          >
            {getPanels.map((panel) => {
              const header = (
                <HeaderWrapper>
                  <Header>{panel.title}</Header>
                  {panel.isRequire && <MandatoryIndicator>*</MandatoryIndicator>}
                  {panel.title === 'User' ? <UserCount>({groupUsers.length})</UserCount> : <></>}
                </HeaderWrapper>
              );
              return (
                <MenuPanel
                  showArrow={true}
                  collapsible="header"
                  key={panel.title}
                  header={header}
                  mb={0}
                >
                  {panel.title === 'Scope' ? (
                    <Scope
                      organizations={allOrganizations}
                      plants={allPlants}
                      lines={lines}
                      counts={{
                        organizations: counts.organizations,
                        plants: counts.plants,
                        machines: counts.machines
                      }}
                      setCounts={setCounts}
                      setGroupInfoState={setGroupInfoState}
                      groupInfoState={groupInfoState}
                      setIsChanged={setIsChanged}
                      setIsVisibleScopeDetail={setIsVisibleScopeDetail}
                      isVisibleScopeDetail={isVisibleScopeDetail}
                      setScopeState={setScopeState}
                      scopeState={scopeState as GroupScope}
                      businessUnits={!bizUnitLoading ? businessUnits : []}
                      selectedBUs={selectedBUs}
                      setSelectedBUs={setBUsAndUpdateInfoState}
                      businessUnitsCount={businessUnitsCount}
                      setBusinessUnitsCount={setBusinessUnitsCount}
                      buSelectionVisible={buSelectionVisible}
                      setBuSelectionVisible={setBuSelectionVisible}
                    />
                  ) : panel.title === 'Permissions' ? (
                    <Permission
                      groupPermissions={groupInfoState.permissions ?? []}
                      onPermissionChange={(permissions) => {
                        setGroupInfoState({ ...groupInfoState, permissions: permissions });
                        setIsChanged(true);
                      }}
                    />
                  ) : (
                    <GroupUsers
                      groupId={id as string}
                      groupUsers={groupUsers}
                      setGroupUsers={updateGroupData}
                    />
                  )}
                  <SectionRule></SectionRule>
                </MenuPanel>
              );
            })}
          </Collapse>
        </DataRenderer>
      </FormContainer>

      <GroupModalFooter
        tableItemId={id}
        onClose={onClose}
        onCloseVerifyData={onCloseVerifyData}
        groupInfo={groupInfoState}
        isActiveUserPromptMode={isActiveUserPromptMode}
        isChanged={isChanged}
      />
    </Container>
  );
};

export default AddEditGroup;
