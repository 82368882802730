// 3rd party libs
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

// Components
import { Button, WarningPrompt } from 'components';

import { GroupItem } from 'types/user-management';
import { Id } from 'types';
import {
  useAddEditGroupUserManagementTwoMutation,
  useDeleteGroupUserManagementV2Mutation
} from 'api';
import { isValidGroup } from 'pages/UserManagement/components/Group/AddEditGroup/Scope/ScopeUtils';
import { ToastMsg } from 'common/components/Toast/Toast';
import theme from 'themes';
import { IcoTrash } from 'icons/IcoTrash';
import { RightUpDiagArrowIcon } from 'icons/RightUpDiagArrowIcon';
import { Link } from 'react-router-dom';
const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding: 0 1rem;
  padding-bottom: 1rem;
`;
const DeleteContextWrapper = styled.div`
  margin-bottom: 0.75rem;
  padding: 0 1rem;
`;
const DeleteHeader = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.75rem;
`;
const DeletePrompt = styled.div`
  color: ${theme.colors.text.lightBlack}
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const DeleteButton = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 0.75rem;
`;
const MoveToGroupContainer = styled.div`
  display: flex;
  gap: 0.35rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 10rem;
  cursor: pointer;
`;
interface Props {
  tableItemId?: Id;
  onClose: () => void;
  onCloseVerifyData: () => void;
  groupInfo?: GroupItem;
  isActiveUserPromptMode: boolean;
  isChanged?: boolean;
}

const GroupModalFooter = ({
  tableItemId,
  onClose,
  onCloseVerifyData,
  groupInfo,
  isActiveUserPromptMode,
  isChanged
}: Props): ReactElement => {
  const [isViewConfirmDelete, setIsViewConfirmDelete] = useState(false);
  const [deletedGroup, { isLoading: isLoadingDeleteGroup }] =
    useDeleteGroupUserManagementV2Mutation();
  const [saveGroup, { isLoading: isLoadingSaveGroup }] = useAddEditGroupUserManagementTwoMutation();

  const onGroupDelete = useCallback(async () => {
    if (tableItemId) {
      deletedGroup({ groupId: tableItemId })
        .unwrap()
        .then(() => {
          ToastMsg({
            message: `Group deleted successfully.`,
            heading: 'Group',
            type: 'success',
            position: 'top-center'
          });
          onClose();
        })
        .catch((err) => {
          console.error('Failed to delete', err);
          const { data } = err;
          ToastMsg({
            message: `⚠️ ${data && data.detail}` ?? '⚠️ There was a problem deleting the group.',
            heading: 'Group',
            type: 'info',
            position: 'top-center'
          });
        });
      setIsViewConfirmDelete(false);
    }
  }, [tableItemId, onClose]);

  const onGroupSave = useCallback(async () => {
    if (isEmpty(groupInfo) || (!isEmpty(groupInfo) && !isValidGroup(groupInfo))) {
      ToastMsg({
        message: `Organizations, Lines and machines can not be empty.`,
        heading: 'Group',
        type: 'warning',
        position: 'top-center'
      });
    } else if (groupInfo) {
      saveGroup(groupInfo)
        .unwrap()
        .then(() => {
          ToastMsg({
            message: `The group has been ${tableItemId ? 'updated.' : 'created.'}`,
            heading: 'Group',
            type: 'success',
            position: 'top-center'
          });
          onClose();
        })
        .catch((err) => {
          console.error('Failed to save', err);
          const { data } = err;
          ToastMsg({
            message: `⚠️ ${data && data.detail}` ?? '⚠️ There was a problem saving the group.',
            heading: 'Group',
            type: 'info',
            position: 'top-center'
          });
        });
    }
  }, [groupInfo, tableItemId, onClose]);

  const allPermissionsFalse = groupInfo?.permissions?.every(
    (permission) => !permission.read && !permission.write
  );

  // Name, permission and at least 1 scope are required.
  const isDisabledSave = useMemo(
    () =>
      isLoadingDeleteGroup ||
      isLoadingSaveGroup ||
      isEmpty(groupInfo) ||
      isEmpty(groupInfo.name) ||
      isEmpty(groupInfo.permissions) ||
      allPermissionsFalse ||
      (!isEmpty(groupInfo) && !isValidGroup(groupInfo)) ||
      (isEmpty(groupInfo.organizations) && !groupInfo.allOrganizations),
    [groupInfo]
  );
  const isDisabledBtn = useMemo(
    () =>
      isLoadingSaveGroup ||
      isLoadingDeleteGroup ||
      isActiveUserPromptMode ||
      (groupInfo?.users && groupInfo.users.length > 0)
        ? true
        : false,
    [groupInfo]
  );

  return (
    <>
      <WarningPrompt
        helperText={`Are you sure you want to delete this group? This action cannot be undone!`}
        isConfirmPrompt
        isVisible={isViewConfirmDelete}
        onCancelCallback={() => setIsViewConfirmDelete(false)}
        onConfirmCallback={() => onGroupDelete()}
        title={`Remove "${groupInfo?.name}"`}
      />
      {tableItemId && (
        <DeleteContextWrapper>
          <DeleteHeader>Delete Group</DeleteHeader>
          <DeletePrompt>
            By deleting the group, you will lose all the data and the member access will also be
            removed. The group will need to be empty in order to delete the group.
          </DeletePrompt>
          <DeleteButton>
            <Button
              disabled={isDisabledBtn}
              variant="text"
              onClick={() => setIsViewConfirmDelete(true)}
              icon={
                <IcoTrash width={'2rem'} color={isDisabledBtn ? theme.colors.negativeRed3 : ''} />
              }
              color={theme.colors.text.error}
            >
              Delete Group
            </Button>
          </DeleteButton>
        </DeleteContextWrapper>
      )}
      {tableItemId && (
        <MoveToGroupContainer>
          <Link to={`/user-management-v2/${tableItemId}`}>Move to group page</Link>
          <RightUpDiagArrowIcon />
        </MoveToGroupContainer>
      )}
      <ButtonContainer>
        <Button variant="secondary" onClick={onCloseVerifyData} disabled={isActiveUserPromptMode}>
          Cancel
        </Button>
        <Button
          disabled={isDisabledSave || isActiveUserPromptMode || !isChanged}
          variant="primary"
          onClick={onGroupSave}
        >
          {tableItemId ? 'Update' : 'Save'}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default GroupModalFooter;
