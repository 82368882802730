import loadable from '@loadable/component';

const AccessDenied = loadable(() => import('./Permission/AccessDenied'));
const Admin = loadable(() => import('./Admin'));
const AddNewMachine = loadable(() => import('./Admin/AddNewMachine'));
const AccessibilityPolicy = loadable(
  () => import('../common/pages/AccessibilityPolicy/AccessibilityPolicy')
);
const Aseptic = loadable(() => import('./Aseptic'));
const B2CLogin = loadable(() => import('./B2CLogin'));
const CheckHierarchy = loadable(() => import('./MachineManagement/NewMachine/CheckHierarchy'));
const Dashboard = loadable(() => import('./Dashboard'));
const DemoTable = loadable(() => import('./DemoTable'));
const DSI = loadable(() => import('./DSI'));
const FindMachine = loadable(() => import('./MachineManagement/NewMachine/FindMachine'));
const FleetMachineDetail = loadable(() => import('./FleetMachineDetail'));
const FleetDashboardPage = loadable(() => import('./FleetDashboard'));
const HelpPage = loadable(() => import('./HelpPage/HelpPage'));
const Line = loadable(() => import('./Line'));
const Login = loadable(() => import('./Login'));
const Machine = loadable(() => import('./Machine'));
const MachineManagement = loadable(() => import('./MachineManagement'));
const MachinePmPartsPage = loadable(() => import('./MachinePmPartsPage'));
const MaintenanceServiceDashboard = loadable(() => import('./MaintenanceServiceDashboard'));
const MaintenanceServiceMachineDashboard = loadable(
  () => import('./MaintenanceServiceMachineDashboard')
);
const MaintenanceSchedule = loadable(() => import('./MachineManagement/MaintenanceSchedule'));
const MasterTagListDashBoard = loadable(() => import('./MasterTagListDashBoard'));
const MTLConfigurationMapping = loadable(
  () => import('./MachineManagement/MTLConfigurationMapping')
);
const OnboardingPage = loadable(() => import('./MachineManagement/OnboardingPage'));
const PartPage = loadable(() => import('./PartPage'));
const PartsCatalogPage = loadable(() => import('./PartsCatalogPage/PartsCatalogPage'));
const PartsSuggestions = loadable(() => import('./PartsSuggestions'));
const ProductDetailPage = loadable(() => import('./ProductDetailPage'));
const ProteinMachine = loadable(() => import('./ProteinMachine'));
const Proseal = loadable(() => import('./Proseal'));
const ReportPage = loadable(() => import('./ReportPage/ReportPage'));
const ReviewAndPublishPage = loadable(() => import('./MachineManagement/ReviewAndPublishPage'));
const Settings = loadable(() => import('./Settings'));
const SFOrdersPage = loadable(() => import('./SFOrdersPage'));
const Site = loadable(() => import('./Site'));
const SwaggerPage = loadable(() => import('./SwaggerUI'));
const UserManagement = loadable(() => import('./UserManagement'));
const UserManagementTwo = loadable(() => import('./UserManagementTwo'));

export {
  AccessDenied,
  Admin,
  AddNewMachine,
  AccessibilityPolicy,
  Aseptic,
  B2CLogin,
  CheckHierarchy,
  DemoTable,
  Dashboard,
  DSI,
  FleetMachineDetail,
  FleetDashboardPage,
  FindMachine,
  HelpPage,
  Line,
  Login,
  Machine,
  MachineManagement,
  MachinePmPartsPage,
  MasterTagListDashBoard,
  MaintenanceSchedule,
  MaintenanceServiceDashboard,
  MaintenanceServiceMachineDashboard,
  MTLConfigurationMapping,
  OnboardingPage,
  PartPage,
  PartsCatalogPage,
  PartsSuggestions,
  ProductDetailPage,
  Proseal,
  ProteinMachine,
  ReviewAndPublishPage,
  Settings,
  SFOrdersPage,
  Site,
  SwaggerPage,
  UserManagement,
  UserManagementTwo,
  ReportPage
};
