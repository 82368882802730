import styled from 'styled-components';
import { BarChartBarProps } from '../';

export interface DivBarChartContainerProps {
  width?: number;
  height?: number;
  chartWidth?: number;
  chartHeight?: number;
  marginRight?: number;
  marginBottom?: number;
  marginTop?: number;
  marginLeft?: number;
}

const barWidth = 30;

export const BarDiv = styled.div<BarChartBarProps>`
  position: absolute;

  width: ${({ width }) => (width ? `${width}px` : undefined)};
  height: ${({ height }) => (height ? `${height}px` : undefined)};
  left: ${({ x }) => (x ? `${x}px` : `0`)};
  top: ${({ y }) => (y ? `${y}px` : `0`)};

  .bar-styles {
    position: absolute;
    width: ${barWidth}px;
    left: -${barWidth / 2}px;
    height: 100%;
    background-color: ${({ color }) => color};
    display: block;
    content: '';
  }

  &.bar-position--top {
    border-radius: 0 0 0 0;
  }

  &.bar-position--bottom {
    border-radius: 0 0 5px 5px;
  }

  &:hover {
    cursor: pointer;
    z-index: 1;
    .tooltip-wrapper {
      display: block;
    }
  }

  .tooltip-wrapper {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-3px);
    min-width: 200px;
    width: max-content;

    display: none;

    &:hover {
      display: none;
    }
  }
`;
