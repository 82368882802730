import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  margin-top: 8vh;
  max-height: 100vh;
`;
export const MainText = styled.div`
  color: #0076cc;
  font-weight: 300;
`;
export const SubWarnText = styled(MainText)`
  max-width: 60%;
  margin: auto;
  font-weight: 500;
`;
export const ImageContainer = styled.img`
  margin: 6rem auto auto;
  max-width: 100%;
`;
export const ItemCenter = styled.div`
  display: flex;
  justify-content: center;
`;
