//import * as d3  from "d3"
import * as d3 from 'd3';
import { generateChartKeys, BarChartBarProps } from '../';

export const generateStackedBarChart = (
  data: Record<string, unknown>[],
  {
    width,
    height,
    categoryKey,
    groupKey,
    valueKey,
    marginLeft,
    marginBottom,
    marginTop,
    marginRight,
    colors,
    domainX,
    //domainY,
    barSpacing,
    leftTickFormatType,
    categories
  }: {
    categories?: string[];
    width?: number;
    height?: number;
    categoryKey?: string;
    groupKey?: string;
    valueKey?: string;
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
    marginBottom?: number;
    colors?: Record<string, string>;
    domainY?: string[] | number[];
    domainX?: string[] | number[];
    barSpacing?: number;
    leftTickFormatType?: 'seconds-to-hours';
  }
): [
  Record<string, BarChartBarProps[]>,
  BarChartBarProps[],
  BarChartBarProps[],
  { x?: number[]; y?: number[] }
] => {
  width = width || 0;
  height = height || 0;

  if (width === 0 || height === 0) return [{}, [], [], {}];

  marginLeft = marginLeft || 0;
  marginBottom = marginBottom || 0;
  marginTop = marginTop || 0;
  marginRight = marginRight || 0;

  groupKey = groupKey || 'group';
  categoryKey = categoryKey || 'category';
  valueKey = valueKey || 'value';

  const chartHeight = height - (marginBottom + marginTop);
  const chartWidth = width - (marginLeft + marginRight);

  categories = categories || generateChartKeys(data, categoryKey);

  const series = d3
    .stack()
    .keys(d3.union(data.map((d) => d?.[groupKey as string] as string))) // distinct series keys, in input order
    .value(([, D], key) => D.get(key)?.[valueKey as string] || 0)(
    d3.index(
      data,
      (d) => d?.[categoryKey as string] as string,
      (d) => d?.[groupKey as string] as string
    )
  );

  const maxTotal = d3.max(series, (d) => d3.max(d, (d) => d[1]));
  //const maxTotalRound = Math.ceil((maxTotal as number) / 10000) * 10000;

  const generateLeftKeys = (maxTotal: number, count: number) => {
    // get the total hours based on the amount of seconds
    const totalHours = maxTotal / 3600;

    // first round to nearest 10 as default
    let totalHoursRound = Math.ceil(totalHours / 0.5) * 0.5;
    // if the total is greater than 10, round to nearest 100 and keep moving up
    //if (totalHours > 5) totalHoursRound = Math.ceil(totalHours / 5) * 10;
    if (totalHours > 10) totalHoursRound = Math.ceil(totalHours / 10) * 10;
    else if (totalHours > 100) totalHoursRound = Math.ceil(totalHours / 100) * 100;
    else if (totalHours > 1000) totalHoursRound = Math.ceil(totalHours / 1000) * 1000;
    else if (totalHours > 10000) totalHoursRound = Math.ceil(totalHours / 10000) * 10000;
    // no convert that back to seconds

    const maxTotalSeconds = totalHoursRound * 3600;
    // how many ticks you want to display
    const chunk = maxTotalSeconds / count;
    const keys = [];
    // generate an array of seconds based on a rounded hour value
    for (let i = 0; i - 1 < count; i++) {
      ``;
      keys.push(chunk * i);
    }

    return keys;
  };
  const leftTickMarks =
    leftTickFormatType === 'seconds-to-hours' ? generateLeftKeys(maxTotal as number, 5) : undefined;
  const x = d3
    .scaleBand()
    .domain((domainX as string[]) || categories)
    .rangeRound([0, chartWidth])
    .padding((barSpacing as number) || 0.3);

  const y = d3
    .scaleLinear()
    .domain([0, leftTickMarks?.[leftTickMarks.length - 1] || maxTotal] as number[])
    .rangeRound([chartHeight, 0]);
  /*
const color = d3
.scaleOrdinal()
.domain(series.map((d) => d.key))
.range(d3.schemeSpectral[series.length])
.unknown('#ccc');
*/
  const leftTicks = leftTickMarks || y.ticks(5);
  const bottomTicks = x.domain();

  const gridX: number[] = [];
  const gridY: number[] = [];

  let bars: Record<string, Record<string, unknown>[]> = {};

  const axisLeft: BarChartBarProps[] = leftTicks.map((val) => {
    gridY.push(y(val as number));
    return {
      x: marginLeft,
      y: y(val as number),
      label: `${val}`,
      width: marginLeft
    };
  });

  const axisBottom: BarChartBarProps[] = bottomTicks.map((val) => {
    gridX.push((x(val) as number) + x.bandwidth() / 2);
    return {
      x: (x(val as string) as number) + x.bandwidth() / 2,
      y: 0,
      label: `${val}`,
      width: x.bandwidth(),
      center: x
    };
  });

  series.forEach((D) => {
    const group = D.key;
    D.forEach((d) => {
      const category = d.data[0];
      const height = y(d[0]) - y(d[1]);
      // for now i'm searching the chart data for the item and adding it to the bar data
      // ideally, this should be done a different way.  i'm trying to figure out how to get more info
      // from the data set that D3 is using for the stacks
      const item = data?.find(
        (item: Record<string, unknown>) =>
          item[groupKey as string] === group && item[categoryKey as string] === category && item
      );
      if (!bars || !bars?.[category]) bars = { [category]: [], ...bars };

      const xpos = (x(`${d.data[0]}`) as number) + Number(marginLeft);
      const ypos = (y(d[1]) as number) + Number(marginTop);
      const width = x.bandwidth() as number;

      const newBar = {
        ...item,
        x: xpos,
        y: ypos,
        width,
        height,
        color: item?.color || colors?.[group] || 'red',
        group,
        category,
        center: xpos + width / 2
      };

      if (height > 0) bars[category] = [newBar, ...bars[category]];
    });
  });
  return [bars, axisLeft, axisBottom, { x: gridX, y: gridY }];
};
