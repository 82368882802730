import { skipToken } from '@reduxjs/toolkit/dist/query';
import { CellContext } from '@tanstack/react-table';
import { useGetMaintenanceEventsQuery } from 'api';
import { InformationTooltip, Loader, NewBaseTable, Pagination } from 'components';
import CreateServiceBtn from 'components/MaintenanceTable/CreateServiceBtn';
import { TFunction } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Machine, TableColumnConfigs } from 'types';
import { MaintenanceEvent, MaintenanceEventArgs } from 'types/maintenance';
import { addMachineDescToEvents } from './MaintenanceServiceDashboardContents';
import { usePaginatedQueryOffset } from 'hooks';
import { PAGE_LENGTH } from 'constants/search';
import styled from 'styled-components';
import { IcoTimer } from 'icons/IcoTimer';
import { formatDate } from 'helpers';
import { ColumnConfig } from 'components/NewBaseTable/NewBaseTable';
import { Link } from 'react-router-dom';

const TableData = styled.div`
  padding: 0.75rem 0rem;
`;
type FnProps = {
  t: TFunction<'fpns'[]>;
  handleActionClick: () => void;
};
export const generatePmPlanningConfig = ({ t, handleActionClick }: FnProps): ColumnConfig[] => {
  return [
    {
      header: t('asset_name') as string,
      id: 'nameOfTheAsset',
      isEnableSorting: true,
      isSelected: true,
      renderer: (data: CellContext<TableColumnConfigs, string>) => {
        return <TableData>{data.getValue()}</TableData>;
      }
    },
    {
      header: t('measurement_type') as string,
      id: 'currentMeasurementType',
      isEnableSorting: true,
      isSelected: true,
      renderer: (data: CellContext<TableColumnConfigs, string>) => {
        const measurementType = {
          run_hours: 'Run hours'
        }[data.getValue()];
        return <TableData>{measurementType || data.getValue()}</TableData>;
      }
    },
    {
      header: t('current_measurement') as string,
      id: 'currentMeasurement',
      isEnableSorting: true,
      isSelected: true,
      renderer: (data: CellContext<TableColumnConfigs, string>) => {
        return <TableData>{data.getValue()}</TableData>;
      }
    },
    {
      header: t('next_measurement') as string,
      id: 'nextMeasurement',
      isEnableSorting: true,
      isSelected: true,
      renderer: (data: CellContext<TableColumnConfigs, string>) => {
        return <TableData>{data.getValue()}</TableData>;
      }
    },
    {
      header: t('last_pm_planning') as string,
      id: 'lastPmPlanning',
      isEnableSorting: true,
      isSelected: true,
      renderer: (data: CellContext<TableColumnConfigs, string>) => {
        const last_pm_planning = data.getValue() as string;
        return <TableData>{formatDate(last_pm_planning, 'short-year4')}</TableData>;
      }
    },
    {
      header: t('next_pm_planning') as string,
      id: 'nextPmPlanning',
      isEnableSorting: true,
      isSelected: true,
      renderer: (data: CellContext<TableColumnConfigs, string>) => {
        const next_pm_planning = data.getValue() as string;
        return <TableData>{formatDate(next_pm_planning, 'short-year4')}</TableData>;
      }
    },
    {
      header: t('action') as string,
      id: 'action',
      isEnableSorting: false,
      isSelected: true,
      renderer: (data: CellContext<TableColumnConfigs, string>) => {
        const id = data.row.original.machineId as string;

        return (
          <TableData onClick={() => handleActionClick()} style={{ padding: '.5rem 1rem' }}>
            <InformationTooltip
              width="max-content"
              placement="left"
              tooltipText="Refer to maintenance event"
              icon={
                <Link to={`/maintenance/servicedashboard?${id}`}>
                  <IcoTimer width="20" />
                </Link>
              }
            ></InformationTooltip>
          </TableData>
        );
      }
    }
  ];
};
type PmPlanningProps = {
  handleServiceModal?: (value: boolean) => void;
  filter: MaintenanceEventArgs | null;
  machines?: Machine[];
  handleActionClick: () => void;
  setNumResults?: (num: number | null) => void;
};

const PmPlanningTable = ({
  handleServiceModal,
  filter,
  machines,
  handleActionClick,
  setNumResults
}: PmPlanningProps): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const { onPageChange, pageNumber } = usePaginatedQueryOffset();
  const ITEMS_PER_PAGE = PAGE_LENGTH.SMALL;

  const { data: events, isLoading } = useGetMaintenanceEventsQuery(
    filter
      ? {
          ...filter,
          limit: ITEMS_PER_PAGE,
          offset: pageNumber
        }
      : skipToken
  );
  useEffect(() => {
    setNumResults && setNumResults(events ? events.total : null);
  }, [events]);
  const rows = useMemo(() => {
    const evs = addMachineDescToEvents(events?.items || ([] as MaintenanceEvent[]), machines || []);
    const transformData = evs.map((item) => {
      return {
        ...item,
        pmPredictionDetails: '',
        nameOfTheAsset: item.machineDescription,
        currentMeasurementType: item.frequencyType,
        currentMeasurement: item.pmPredictionDetails?.currentMeasurement,
        nextMeasurement: item.pmPredictionDetails?.estimatedNextMeasurement,
        lastPmPlanning: item.pmPredictionDetails?.currentMeasurementDate,
        nextPmPlanning: item.pmPredictionDetails?.estimatedNextMeasurementDate
      };
    });
    return transformData;
  }, [events]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <NewBaseTable
        columnConfigs={generatePmPlanningConfig({ t, handleActionClick })}
        newTableData={rows}
        sortState={{ id: 'id', desc: true }}
        tdMarginLeft="0.625rem"
        textAlign="left"
        padding="0 0 0 .6rem "
        isShowColumnOptions={true}
        shadow
        tableHeadBtn={<CreateServiceBtn handleServiceModal={handleServiceModal} />}
      />
      <Pagination
        onPageChange={onPageChange}
        itemsPerPage={ITEMS_PER_PAGE}
        numItems={events?.total}
        currentPage={pageNumber}
      />
    </>
  );
};
export default PmPlanningTable;
