import React, { useState, useEffect, useRef } from 'react';
import theme from 'themes';
import {
  DropdownContainer,
  OptionItem,
  PopupContainer,
  SubmenuContainer
} from 'common/components/DropdownOption/DropdownOption.element';

import styled from 'styled-components';
import { Checkmark } from 'icons/checkmark';
import { IcoChevRight } from 'icons';

interface CustomDropdownProps {
  options?: OptionType[];
  icon?: JSX.Element;
  handleOptionSelect?: (option?: OptionType, parentOption?: OptionType) => void;
  adminStatus?: { isAdmin: boolean; isAllAdmin: boolean };
}

export interface OptionType {
  value: string;
  label?: string;
  isActive?: boolean;
  showSubMenu?: boolean;
  subMenu?: OptionType[];
}

const IconContainer = styled.button`
  all: unset;
  cursor: pointer;
  line-height: 0;
  padding: 0.3rem;
  width: 1.8rem;
  box-sizing: border-box;
  :focus {
    background: ${theme.colors.lightGrey10};
    border-radius: 0.3rem;
    border: 1px solid ${theme.colors.primaryBlue5 + '80'};
    box-sizing: border-box;
  }
  margin-left: 1.4rem;
`;
const OptionText = styled.div`
  text-align: left;
  white-space: nowrap;
  width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const OptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TextContent = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '0'};
  padding-top: 0.25rem;
  pointer-events: none;
  user-select: none;
`;

const EllypsisMenu: React.FC<CustomDropdownProps> = ({
  options,
  icon,
  handleOptionSelect,
  adminStatus
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuOptions: OptionType[] | undefined = options;

  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for the dropdown container

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOption = (option: OptionType, parentOption?: OptionType) => {
    handleOptionSelect?.(option, parentOption);
  };

  // Effect to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer className="elipseBTN" ref={dropdownRef} containerPosition="absolute">
      {!adminStatus?.isAllAdmin ? (
        <TextContent width="auto">Admin</TextContent>
      ) : (
        <TextContent width="auto">Member{adminStatus?.isAdmin ? ', Admin' : ''}</TextContent>
      )}

      <IconContainer onClick={toggleDropdown}>{icon}</IconContainer>
      {isOpen && (
        <PopupContainer overflow="initial" className="elipseMenuWrapper" top="2rem">
          {menuOptions?.map((option) => {
            const { subMenu } = option;
            return (
              <OptionItem
                width="15rem"
                key={option.value}
                onClick={() => {
                  !subMenu?.length && handleOption(option);
                }}
                padding=".8rem 1rem"
              >
                <OptionRow>
                  <OptionText>{option?.label}</OptionText>
                  <IcoChevRight color={theme.colors.primaryBlue5} />
                </OptionRow>

                <SubmenuContainer width="15rem">
                  {subMenu?.map((menu) => (
                    <OptionItem
                      padding={menu.isActive ? '.8rem 1rem' : '.8rem 2rem'}
                      width="12rem"
                      onClick={() => {
                        setIsOpen(false);
                        !menu.isActive && handleOption(menu, option);
                      }}
                    >
                      {menu.isActive ? Checkmark({ color: theme.colors.primaryBlue5 }) : ''}{' '}
                      {menu.label}
                    </OptionItem>
                  ))}
                </SubmenuContainer>
              </OptionItem>
            );
          })}
        </PopupContainer>
      )}
    </DropdownContainer>
  );
};

export default EllypsisMenu;
