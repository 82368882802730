// map keys is used to map a key to a value, to rename that key
// example, if your return is "prod_down" and you want to rename it to "production downtime", for a chart or legend item or color
// you would add the following to the mapKeys object { prod_down: 'production downtime' }
// this well be used when processing the api call.  the new values should match up with the keys in the colors object and icons as well
export const mapKeys: Record<string, string> = {
  product_output: 'product output',
  cut_weight_scan: 'cut weight scan',
  running_state_duration: 'running',
  not_running_state_duration: 'not running',
  no_product_state_duration: 'no product',
  offline_state_duration: 'offline',
  piece_count: 'count',
  scanned_wt: 'weight',
  Fillets: 'portions'
};
