import React, { ReactNode } from 'react';
import { ProgressBarContainer, ProgressBarContainerProps } from './ProgressBar.elements';
import { useTranslation } from 'react-i18next';

import { TooltipIcon } from '../TooltipIcon/TooltipIcon';

export interface ProgressBarBaseProps extends ProgressBarContainerProps {
  /** text that appears above the progress bar  */
  title?: ReactNode;
  /** inline text next to title */
  subTitle?: ReactNode;
  /** text that appears below the progress bar */
  label?: string;
  /** bar color */
  color?: string;

  /** bottom legend items appear above label below progress bar*/
  bottomTickCount?: number;
  /** an array of bars that will generate like a horizontal bar */
  bars?: {
    progress?: number;
    color?: string;
  };
  /** color object to match up with progress values */
  colors?: Record<string | number, string>;
  /** order of bars, default is smallest is left */
  orderBy?: 'asc' | 'desc' | 'preserve';
}

export interface ProgressBarProps extends ProgressBarBaseProps {
  infoTooltip?: ReactNode;
  /** set to true if you don't want to show % value */
  hideValue?: boolean;
  /** formatter for value (if you want to change the % or whatever, returns a string) */
  format?: {
    progress?: (x: number) => ReactNode;
    color?: (x?: ProgressBarBaseProps) => string;
    infoTooltip?: (x?: ProgressBarBaseProps) => ReactNode;
  };
  formatValue?: (x?: ProgressBarBaseProps) => ReactNode;
  /** if you want to process the value before setting the color */
  formatColor?: (x?: ProgressBarBaseProps) => string;
}
interface Props extends ProgressBarProps {
  children?: ReactNode;
}

export const ProgressBar = ({
  title,
  subTitle,
  progress,
  label,
  hideValue,
  color,
  formatColor,
  formatValue,
  format,
  children,
  bottomTickCount,
  bars,
  colors,
  orderBy
}: Props): JSX.Element => {
  const { t } = useTranslation(['mh']);

  // for ts
  if (colors || bars || orderBy) console.log('not used', { colors, bars, orderBy });

  let className = 'progress-bar-container';
  // convert to number if string
  progress = Number(progress);
  // make sure it's valid
  if (!progress) {
    className += ' has-error';
    // set to zero if not valid with error, this way we'll know if it's error of just 0
    progress = 0;
  }

  progress = Number(progress);

  if (typeof title === 'string') title = t(title);
  if (typeof subTitle === 'string') subTitle = t(subTitle);
  if (typeof label === 'string') label = t(label) as string;

  const values = { title, subTitle, label, progress, color };

  const showValue = hideValue
    ? undefined
    : formatValue?.(values) || format?.progress?.(progress) || `${progress}%`;

  let barColor = formatColor?.(values) || color || undefined;

  if (!barColor) {
    barColor = 'unknown';
    if (progress > 0) barColor = 'error';
    if (progress > 50) barColor = 'warning';
    if (progress > 75) barColor = 'success';
  }

  progress = progress > 100 ? 100 : progress;

  const InfoTooltip = () =>
    format?.infoTooltip ? (
      <TooltipIcon iconType="info">
        {format?.infoTooltip?.({ progress, color, title, subTitle, label })}
      </TooltipIcon>
    ) : (
      <></>
    );

  const BottomLegendItems =
    bottomTickCount &&
    Array.from({ length: bottomTickCount as number }).map((item, index) => (
      <div key={item as string} className="progress-bar-legend-label">
        {(100 / (bottomTickCount - 1)) * index}%
      </div>
    ));

  return (
    <ProgressBarContainer {...{ className, progress, barColor }}>
      {(title || subTitle) && (
        <div className="progress-bar-title-container">
          <span className="progress-bar-title">
            {title}
            {subTitle && <span className="progress-bar-sub-title">{subTitle}</span>}
          </span>
          <InfoTooltip />
        </div>
      )}
      <div className="display-area">
        <div className="progress-bar"></div>

        {showValue && <div className="progress-value">{showValue}</div>}

        {BottomLegendItems && <div className="progress-bar-legend">{BottomLegendItems}</div>}
      </div>

      {label && <div className="progress-bar-label">{label}</div>}

      {children}
    </ProgressBarContainer>
  );
};
