import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoContact = ({
  width = '24',
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1556_79197)">
        <path
          d="M13.3333 4.00002V12C13.3333 12.3536 13.1929 12.6928 12.9428 12.9428C12.6928 13.1929 12.3536 13.3334 12 13.3334H5.33333C4.97971 13.3334 4.64057 13.1929 4.39052 12.9428C4.14048 12.6928 4 12.3536 4 12V4.00002C4 3.6464 4.14048 3.30726 4.39052 3.05721C4.64057 2.80716 4.97971 2.66669 5.33333 2.66669H12C12.3536 2.66669 12.6928 2.80716 12.9428 3.05721C13.1929 3.30726 13.3333 3.6464 13.3333 4.00002Z"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66675 10.6667H10.6667"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33325 7.33333C7.33325 7.68696 7.47373 8.02609 7.72378 8.27614C7.97383 8.52619 8.31296 8.66667 8.66659 8.66667C9.02021 8.66667 9.35935 8.52619 9.60939 8.27614C9.85944 8.02609 9.99992 7.68696 9.99992 7.33333C9.99992 6.97971 9.85944 6.64057 9.60939 6.39052C9.35935 6.14048 9.02021 6 8.66659 6C8.31296 6 7.97383 6.14048 7.72378 6.39052C7.47373 6.64057 7.33325 6.97971 7.33325 7.33333Z"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66675 5.33331H4.66675"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66675 8H4.66675"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66675 10.6667H4.66675"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1556_79197">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
