import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoLeftArrow = ({
  width = '24',
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg width={width} height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.66667 6.66668L1 4.00001M1 4.00001L3.66667 1.33334M1 4.00001L13 4.00001"
        stroke={color || '#0076CC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
