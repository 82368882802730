// 3rd party libs
import React, { ReactElement, useMemo, useState } from 'react';
import { AddEditGroup } from 'pages/UserManagementTwo/components';
import { Button, Flyout, FlyoutHeader, Modal, Typography } from 'components';
import styled, { useTheme } from 'styled-components';
import { Id, ModalSize } from 'types';
import { GroupItem } from 'types/user-management';
import AlertIcon from 'components/WarningPrompt/AlertIcon';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
`;
const StyledModalHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1.25rem;
  padding: 1.25rem 1.5rem;
  width: 100%;
`;
const StyledButtonRow = styled.div`
  align-item: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  padding-top: 0.5rem;
  color: #b62c10;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;
const StyledTitle = styled.div`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0rem;
  line-height: 1.3125rem;
`;
const StyledContentContainer = styled.div`
  padding: 0 1.5rem 1.25rem;
  width: 100%;
`;

interface Props {
  tableItemId?: Id;
  open: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
}

const initialGroupInfo: GroupItem = {
  name: '',
  isInternal: false,
  allOrganizations: false,
  allMachines: false,
  allPlants: false,
  organizations: [],
  plants: [],
  machines: [],
  permissions: [],
  users: []
};

const GroupModal = ({ tableItemId, open, setIsOpenModal }: Props): ReactElement => {
  const theme = useTheme();
  const [isChanged, setIsChanged] = useState(false);
  const [isViewConfirmCancel, setIsViewConfirmCancel] = useState(false);
  const [groupName, setGroupName] = useState<string>('');
  const onClose = () => {
    setIsOpenModal(false);
  };

  const onCloseVerifyData = () => {
    if (isChanged) {
      setIsViewConfirmCancel(true);
    } else {
      setIsChanged(false);
      setIsOpenModal(false);
    }
  };
  const flyoutTitle = useMemo(() => {
    return tableItemId ? `Edit group ${groupName}` : `Add a new group`;
  }, [tableItemId, groupName]);

  const helperText = `Are you sure you want to go back to groups? You have unsaved changes.`;
  const title = 'Unsaved Changes';
  return (
    <>
      <Modal
        title={
          <StyledModalHeaderContainer>
            <AlertIcon />
            <StyledTitle>{title}</StyledTitle>
          </StyledModalHeaderContainer>
        }
        allowContentScroll
        onClose={() => {
          setIsViewConfirmCancel(false);
          setIsChanged(false);
        }}
        showCloseButton={true}
        size={ModalSize.XXSMALL_AUTO_HEIGHT}
        widthOverride={'22rem'}
        visible={isViewConfirmCancel}
        showVanityBorder={true}
        vanityBorderColor="#F00"
        hasBackgroundColor={true}
        backgroundColor="#FFF0F0"
        customCloseButtonColor="#B62C10"
      >
        <StyledContentContainer>
          <Typography color={theme.colors.mediumGrey3} variant="stepheading">
            {helperText}
          </Typography>
          <StyledButtonRow>
            <Button
              onClick={() => {
                setIsViewConfirmCancel(false);
              }}
              variant="text"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsOpenModal(false);
                setIsViewConfirmCancel(false);
                setIsChanged(false);
              }}
              variant="text"
            >
              Yes
            </Button>
          </StyledButtonRow>
        </StyledContentContainer>
      </Modal>
      <Flyout width="28.125rem" visible={open} onClose={onCloseVerifyData}>
        <FlyoutHeader heading={flyoutTitle} onClose={onCloseVerifyData} marginLeft="0.625rem" />
        <Container tabIndex={0}>
          <AddEditGroup
            id={tableItemId}
            setIsChanged={setIsChanged}
            initialGroupInfo={initialGroupInfo}
            onCloseVerifyData={onCloseVerifyData}
            onClose={onClose}
            isActiveUserPromptMode={isViewConfirmCancel}
            setGroupName={setGroupName}
            isChanged={isChanged}
          />
        </Container>
      </Flyout>
    </>
  );
};

export default GroupModal;
