import { ReactNode } from 'react';
import { groupByKey } from 'common/helpers/dataGroupers';
import { calculateByCategoryByGroup } from 'common/helpers/dataCounters';
import { colors } from '../../../settings/protein/colors';
import { filterSelectedData, useFilterSelected } from 'components';
import { useNewSessionsOverTimeData } from 'common/pages/fleetV2/machine/protein/hooks/useSessionsOverTimeData';

const orderSessionsByDate = (data?: Record<string, string>) =>
  !data
    ? undefined
    : Object.entries(data)
        .map(([category, label]) => ({ category, label }))
        .sort((a, b) => (a.label < b.label ? -1 : 1));

const generateSessionsCategories = (data?: Record<string, string>[]) =>
  data?.map((item) => item?.category);

export const useCleaningStepsDurationData = (
  otherLabelData?: string[]
): {
  data?: Record<string, unknown>[];
  isLoading?: boolean;
  isFetching?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  [key: string]: unknown;
} => {
  const { data, isLoading, isFetching, hasError } = useNewSessionsOverTimeData();

  const [selected] = useFilterSelected();
  // group the data for stacking the bars
  const groupedData = data && groupByKey({ data, key: 'sessionId' });
  // we need the totals for this chart so we add up the duration and get a new object based on caetgory: { group: value }
  const totals = calculateByCategoryByGroup('sessionId', 'name', {
    data,
    valueKey: 'duration'
  });

  // get an object who's keys are the sessionIDs and the values are the start dates
  // this will be used to order the bars by data while also grouping them by id
  // it will be used to populate the tooltip and the bottom axis
  const sessionStartDates =
    groupedData &&
    Object.entries(groupedData).reduce((acc, [sessionId, items]) => {
      if (!acc?.[sessionId as string]) acc = { ...acc, [sessionId]: items[0]?.changeoverStart };
      return acc;
    }, {});
  // get an array of [{ category: sessionId, label: startDate }] which is ordered by label or date
  const orderSessions = orderSessionsByDate(sessionStartDates);
  // generate a string array of the sessionIds that have been ordered by date, this way we can group by session id
  // and still order by date
  const categories = generateSessionsCategories(orderSessions);

  const chartData =
    totals &&
    Object.values(totals)
      .map((items) => {
        return Object.values(items);
      })
      .flat();

  let filteredChartData;
  if (selected && selected.group[0] === 'Other') {
    filteredChartData = chartData
      .filter((item) => otherLabelData?.includes(item?.group))
      .map((item) => ({
        average: item?.average,
        category: item?.category,
        categoryKey: item?.categoryKey,
        color: item?.color,
        count: item?.count,
        group: item?.group,
        groupKey: item?.groupKey,
        total: item?.total,
        valueKey: item?.valueKey
      }));
  } else {
    filteredChartData =
      chartData && filterSelectedData({ data: chartData as Record<string, unknown>[], selected });
  }

  const filteredTooltipData =
    filteredChartData && groupByKey({ data: filteredChartData, key: 'category' });

  return {
    totals,
    isLoading,
    isFetching,
    hasError,
    hasMessage: !isLoading && !data?.length ? 'No data within range' : undefined,
    data,
    categories,
    groupedData,
    chartData,
    filteredChartData,
    colors,
    sessionStartDates,
    filteredTooltipData
  };
};
