// 3rd party libs
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import SwitchComponent, { ReactSwitchProps } from 'react-switch';
import theme from 'themes';
interface SwitchProps extends ReactSwitchProps {
  defaultChecked?: boolean;
}

interface SwitchComponentWrapperProps {
  checked?: boolean;
  disabled?: boolean;
}

const SwitchComponentWrapper = styled.div<SwitchComponentWrapperProps>`
  ${(props) =>
    props.disabled &&
    props.checked &&
    css`
      .react-switch-handle {
        background-color: ${theme.colors.toggleSwitch.switchHandle.disabledSelected
          .fill} !important;
      }
      .react-switch-bg {
        background-color: ${theme.colors.toggleSwitch.switchBg.disabledSelected.fill} !important;
      }
    `}
`;

const Switch = (props: SwitchProps): JSX.Element => {
  const theme = useTheme();
  return (
    <SwitchComponentWrapper disabled={props.disabled} checked={props.checked}>
      <SwitchComponent
        offColor={props.offColor || theme.colors.lightGrey2}
        onColor={theme.colors.primaryBlue6}
        offHandleColor={props.offHandleColor || theme.colors.white}
        onHandleColor={theme.colors.primaryBlue5}
        boxShadow="0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2)"
        uncheckedIcon={false}
        checkedIcon={false}
        width={props.width || 32}
        height={props.height || 12}
        handleDiameter={props.handleDiameter || 20}
        {...props}
      />
    </SwitchComponentWrapper>
  );
};

export default Switch;
