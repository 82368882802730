import styled, { css } from 'styled-components';
import theme from 'common/theme';

const pointerSize = 10;

const svgMarkup = `<svg  class="arrow-icon" width="${pointerSize}" height="${pointerSize}" viewBox="0 0 ${pointerSize} ${pointerSize}" xmlns="http://www.w3.org/2000/svg">
  <polygon className="poly" points="${
    pointerSize / 2
  },0 ${pointerSize},${pointerSize} 0,${pointerSize}" fill="white" />
</svg>`;

// Encode the SVG string
const encodedSvg = encodeURIComponent(svgMarkup);

export const tooltipStyles = css`
  .text--capital {
    text-transform: capitalize;
  }

  .text--lrg {
    font-size: 1.1em;
  }

  .tooltip {
    position: absolute;
    bottom: 100%;
    transform: translate(-50%, -${pointerSize / 2}px);
    font-size: 0.8em;

    .icon-triangle {
      position: absolute;
      width: ${pointerSize}px;
      height: ${pointerSize}px;
      left: 50%;
      top: 100%;
      transform: rotate(180deg) translate(3px, 3px);
      path {
        filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.3));
      }
    }

    .tooltip__inner {
      width: max-content;
      max-width: 200px;
      background-color: white;
      padding: 0.5em;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      box-shadow: ${theme.effects.boxShadow.tooltip};

      .tooltip__title {
        font-weight: 500;
        margin-bottom: 0.3em;
        padding-bottom: 0.3em;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

/**
 *  demo usage
 *  <div className="tooltip">
      <div className="tooltip__inner">
        <div className="tooltip__title">
        {children}
      </div>
      <IcoTriangle fill="white" />
    </div>
 * 
 */

export const TooltipV2Container = styled.div<{
  x?: number;
  y?: number;
  left?: string;
  top?: string;
  bottom?: string;
  right?: string;
  className?: string;
}>`
  position: absolute;
  width: 0;
  height: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 900;

  left: ${({ x, left }) => ((x || left) && x && `${x}px`) || left};
  top: ${({ y, top }) => ((y || top) && y && `${y}px`) || top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};

  .tooltip__inner {
    width: max-content;
    max-width: 200px;
    position: absolute;
    flex-direction: column;
    bottom: 100%;
    transform: translateX(-50%);
    font-size: 0.8em;
    padding: 0.5em;
    background-color: white;
    border-radius: 5px;

    box-shadow: ${theme.effects.boxShadow.tooltip};
    display: ${({ left, top, x, y }) => (left || top || x || y ? 'flex' : 'none')};

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 50%;
      top: 100%;
      transform: translateX(-50%) rotate(180deg);
      background-image: url('data:image/svg+xml,${encodedSvg}');
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;

      filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.3));
    }
  }

  .tooltip__title {
    font-weight: 500;
    margin-bottom: 0.3em;
    padding-bottom: 0.3em;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }
`;

export const TooltipContainer = styled.div<{
  x?: number;
  y?: number;
  left?: number;
  top?: number;
}>`
  position: absolute;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  left: ${({ x, left }) => (x || left ? `${x || left}px` : '0')};
  top: ${({ y, top }) => (y || top ? `${y || top}px` : '0')};
`;
