import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width, fill }) => ({
  width: width || `100%`,
  height: width || `auto`,
  viewBox: '0 0 26 26',
  fill: fill || 'none',
  xmlns: 'http://www.w3.org/2000/svg'
}))``;

export const IcoUndo = ({
  width,
  fill
}: {
  width?: string;
  fill?: string;
  xmlns?: string;
}): JSX.Element => {
  return (
    <div>
      <Svg {...{ width, fill }} focusable="true">
        <path
          d="M12,12.5857864 L14.2928932,10.2928932 L15.7071068,11.7071068 L13.4142136,14 L15.7071068,16.2928932 L14.2928932,17.7071068 L12,15.4142136 L9.70710678,17.7071068 L8.29289322,16.2928932 L10.5857864,14 L8.29289322,11.7071068 L9.70710678,10.2928932 L12,12.5857864 Z M15,3.41421356 L15,7 L18.5857864,7 L15,3.41421356 Z M19,9 L15,9 C13.8954305,9 13,8.1045695 13,7 L13,3 L5,3 L5,21 L19,21 L19,9 Z M5,1 L15.4142136,1 L21,6.58578644 L21,21 C21,22.1045695 20.1045695,23 19,23 L5,23 C3.8954305,23 3,22.1045695 3,21 L3,3 C3,1.8954305 3.8954305,1 5,1 Z"
          fillRule="evenodd"
        />
      </Svg>
    </div>
  );
};
