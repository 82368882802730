import styledTheme from 'common/theme';

const baseColors = {
  idle: 'rgba(138, 136, 134, 1)',
  maintenance: 'rgba(230, 108, 55, 1)',
  cleaning: '#0076CC',
  'wait time': 'rgba(182, 44, 16, 1)',
  production: '#29A429',
  oee: 'rgba(0, 118, 204, 1)',
  sterilization: '#009EB3',
  performance: 'rgba(125, 150, 195, 1)',
  availability: 'rgba(131, 93, 208, 1)',
  quality: 'rgba(0, 158, 179, 1)',
  'production downtime': '#B62C10'
};

const laneAlarmsColors = {
  foil: 'rgba(0, 118, 204, 1)',
  filling: 'rgba(131, 93, 208, 1)',
  'screw capping': '#FFADC1',
  'infeed module': 'rgba(138, 136, 134, 1)',
  'outfeed module': 'rgba(0, 158, 179, 1)',
  sealing: 'rgba(230, 108, 55, 1)'
};

export const colors: Record<string, string> = {
  ...baseColors,
  ...laneAlarmsColors,
  Mainten: 'rgba(230, 108, 55, 1)',
  'Wait time': 'rgba(182, 44, 16, 1)',
  'production uptime': baseColors.production,
  Foil: laneAlarmsColors.foil,
  Filling: laneAlarmsColors.filling,
  'avd process': 'rgba(252, 214, 65, 1)',
  Sealing: laneAlarmsColors.sealing,
  Production: baseColors.production,
  Idle: baseColors.idle,
  actualDurationTitle: styledTheme.colors.black,
  actualDurationSubText: styledTheme.colors.gray800,
  actualDurationExtraText: '#b62c10'
};

export const changeoverTableColors = {
  positiveValue: styledTheme.colors.success200,
  negativeValue: styledTheme.colors.error200
};

export const actualDurationColors = {
  positiveValue: styledTheme.colors.error200,
  negativeValue: styledTheme.colors.success200
};
