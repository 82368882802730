import styled from 'styled-components';

export const MachineStateIconContainer = styled.div<{ color?: string }>`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 0.3em;
  min-width: max-content;

  .label {
    font-weight: 600;
    text-transform: capitalize;

    color: ${({ color }) => color || 'inherit'};
  }

  .icon {
    width: 1em;
    height: 1em;
  }

  .icon:not(.icon--check-mark-with-circle) path {
    stroke-width: 4;
  }
`;
