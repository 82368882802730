import { styledTheme } from 'components';
import React from 'react';

export interface Props {
  color?: string;
}

export const GoBackIcon = ({ color }: Props): JSX.Element => {
  color = color || styledTheme.color.secondary;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6923_1761)">
        <path
          d="M6.00016 9.33333L3.3335 6.66667L6.00016 4"
          stroke={`${color}`}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.3335 6.66663H10.6668C11.3741 6.66663 12.0524 6.94758 12.5524 7.44767C13.0525 7.94777 13.3335 8.62605 13.3335 9.33329C13.3335 10.0405 13.0525 10.7188 12.5524 11.2189C12.0524 11.719 11.3741 12 10.6668 12H10.0002"
          stroke={`${color}`}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6923_1761">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
