import React, { ReactNode, useState } from 'react';
import { WidgetUi, WidgetUiProps } from 'common/ui';
import { D3PieChart, D3PieChartProps, D3ProgressPieChart } from 'common/components';
import { PieChartWidgetContainer, PieChartWidgetMain } from './PieChartWidget.elements';
import { CellValues, CellValuesProps } from './CellValues/CellValues';
import { ResponsiveInlineChartLegend } from '../ChartLegends';
import { FilterSelectedPropsHandle } from 'components';
import { convertObjectArrayToLegendItems } from 'common/components';
import { useBuSettings } from 'common/pages/fleetV2/providers';

export interface PieChartWidgetBaseProps {
  // do you want to pass a custom component for the center of the chart?
  // this layer loads under the chart so you cant have ay events on it
  InnerComponent?: JSX.Element;
  centerCellValues?: CellValuesProps;
  bottomCellValues?: CellValuesProps[];
  // this hides the legend from the widget, the default is shown
  hideLegend?: boolean;
  // this turns off the built in legend click functionality.  You can have this disabled and still pass an
  // external click handler
  disableLegend?: boolean;
  // this turns off the click, if no handleSelect is provided
  // otherwise, whatever slice is cliciked will be shown as selected
  disableAutoSelect?: boolean;
  // array string of selected pie slice labels
  selected?: Record<string, string[]>;
  handleSelect?: FilterSelectedPropsHandle;
}

export interface PieChartWidgetProps
  extends PieChartWidgetBaseProps,
    D3PieChartProps,
    WidgetUiProps {
  widgetUiSettings?: WidgetUiProps;
  pieChartSettings?: D3PieChartProps;
  children?: ReactNode | ReactNode[];
  chartData?: Record<string, unknown>[];
  type?: 'progress' | 'pie' | 'doughnut' | string;
  legendItems?: Record<string, unknown>[];
  autoLegend?: boolean;
  autoTooltip?: boolean;
  format?: {
    innerTitle?: (data: Record<string, unknown>) => JSX.Element;
    innerValue?: (data: Record<string, unknown>) => JSX.Element;
    innerLabel?: (data: Record<string, unknown>) => JSX.Element;
  };
}

const SubCells = ({ bottomCellValues }: PieChartWidgetProps) =>
  !bottomCellValues ? (
    <></>
  ) : (
    <div className="pie-chart-widget__sub-cells">
      {bottomCellValues.map((cell, i) => (
        <CellValues key={`cell-${i}`} {...cell} />
      ))}
    </div>
  );

export const PieChartWidget = ({
  width,
  children,
  hideLegend,
  bottomCellValues,
  centerCellValues,
  widgetUiSettings,
  isLoading,
  hasError,
  hasMessage,
  InnerComponent,
  data,
  checkIfSelected,
  pieChartSettings,
  handleClick,
  format,
  className,
  hasDateFooter,
  hasButtons,
  progress,
  color,
  title,
  chartData,
  type,
  SubHeader,
  legendItems,
  TooltipComponent,
  autoLegend,
  disableAutoSelect,
  groupKey,
  valueKey,
  labelKey,
  ...rest
}: PieChartWidgetProps): JSX.Element => {
  data = data || chartData;

  const [selected, setSelected] = useState<Record<string, unknown> | undefined>(undefined);
  const { colors } = useBuSettings();

  labelKey = labelKey || groupKey || 'label';
  groupKey = (groupKey || 'group') as string;
  valueKey = valueKey || 'value';
  progress = progress || pieChartSettings?.progress;
  className = className ? `pie-chart-widget ${className}` : `pie-chart-widget`;

  let hasLegend = !hideLegend || autoLegend || legendItems ? true : false;

  /*
  const handleSelect = ({ group }: { group?: string }) =>
    disableAutoSelect
      ? undefined
      : !group
      ? console.log('error: missing groupKey in pie chart')
      : selected !== group && setSelected(group);
  */

  progress = progress || pieChartSettings?.progress;

  if ((data && !data.length) || (progress && progress === 0)) hasMessage = true;

  // this shows the progress in the center of the chart
  if (progress) {
    centerCellValues = { value: `${progress}%` };
    hasLegend = false;
    className = `${className} pie-chart-widget--progress-chart`;
    type === 'progress';
  }

  const handleSelect = (item?: Record<string, unknown>) => {
    if (disableAutoSelect || !item) return;
    const group = item?.[groupKey] as string;
    if (!group) return console.log('error: missing groupKey in pie chart');
    const selectedItem = selected?.[groupKey];
    // set selection if not selected or if selected is not the same as the group
    if (!selectedItem || selectedItem !== group) return setSelected({ [groupKey]: group });
    // clears selection by default
    return setSelected(undefined);
  };

  const checkSelected = (item?: Record<string, unknown>): boolean => {
    if (!item) return false;
    if (!selected || type === 'progress') return true;
    const group = item?.[groupKey] as string;
    if (!group) {
      console.log('error: missing groupKey in pie chart');
      return true;
    }
    const selectedItem = selected?.[groupKey];
    if (!selectedItem || selectedItem !== group) return true;
    return false;
  };

  handleClick = handleClick || handleSelect;

  if (selected?.[groupKey]) {
    const selectedSlice = selected[groupKey];
    const selectedDataItem = data?.find((x) => x[groupKey] === selectedSlice);

    const group = selectedDataItem?.[groupKey] as string;
    const label = (selectedDataItem?.[labelKey] as string) || group;
    const value = selectedDataItem?.[valueKey] as string;
    centerCellValues = { label, value };
  }

  handleClick = handleClick || handleSelect;
  checkIfSelected = checkIfSelected || checkSelected;

  /*data = (data || chartData || pieChartSettings?.data)?.sort(
    (a, b) => Number(b.value) - Number(a.value)
  );*/

  // add to the WidgetUi settings
  widgetUiSettings = {
    title,
    className,
    isLoading,
    hasError,
    hasMessage,
    hasDateFooter,
    hasButtons,
    width,
    ...widgetUiSettings,
    ...rest
  };

  // add data to the chart settings
  pieChartSettings = {
    type,
    TooltipComponent,
    groupKey,
    labelKey,
    valueKey,
    format,
    ...pieChartSettings,
    data,
    progress,
    color,
    checkIfSelected,
    handleClick
  };

  console.log({ legendItems, labelKey, groupKey });

  if (!isLoading && data && !hideLegend)
    legendItems =
      legendItems || convertObjectArrayToLegendItems(data, { colors, groupKey, valueKey }) || [];

  // start buildding settings for the main section container
  // this adds grid rows and positioning based on options
  const containerSettings: Record<string, unknown> = {
    className: 'pie-chart-widget-conatiner',
    gridRows: !hasLegend ? undefined : children || bottomCellValues ? '1fr auto' : '1fr'
  };

  const CenterCells = () =>
    isLoading ? (
      <></>
    ) : InnerComponent || centerCellValues ? (
      <div className="pie-chart-widget__inner-area">
        {centerCellValues ? <CellValues {...centerCellValues} {...{ format }} /> : InnerComponent}
      </div>
    ) : (
      <></>
    );

  const ChartToUse = type === 'progress' ? D3ProgressPieChart : D3PieChart;

  const LegendSubHeader = () =>
    isLoading ? (
      <></>
    ) : (
      <div className="widget-ui__sub-header">
        <ResponsiveInlineChartLegend
          {...{
            format,
            checkIfSelected,
            handleClick: (x) => handleClick?.({ group: x }),
            items: legendItems,
            isLoading
          }}
        />
      </div>
    );
  const mainClass = `widget-ui__main pie-chart-widget-main has-overflow centered`;
  if (isLoading) className += ' is-loading centered';
  if (type === 'progress' || progress) className += ' pie-chart-widget-main--progress-chart';

  const Main = () =>
    isLoading ? (
      <></>
    ) : (
      <PieChartWidgetMain className={mainClass}>
        <PieChartWidgetContainer {...containerSettings}>
          <CenterCells />
          {pieChartSettings && <ChartToUse {...pieChartSettings} />}
        </PieChartWidgetContainer>
        <SubCells {...{ bottomCellValues }} />
        {children}
      </PieChartWidgetMain>
    );

  return (
    <WidgetUi
      SubHeader={SubHeader || (hasLegend ? <LegendSubHeader /> : undefined)}
      Main={<Main />}
      {...widgetUiSettings}
    />
  );
};
