import { useGetUserManagementGroupByIdV2Query } from 'api';
import { BreadCrumbs, Typography } from 'components';
import { GroupModal, UserManagementModal } from 'pages/UserManagementTwo/components';
import { FlyoutAction, FlyoutState } from 'pages/UserManagementTwo';
import { UserManagementTable } from 'pages/UserManagementTwo/components';
import React, { useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Id } from 'types';
import { UserManagementTableType, UserTypes } from 'types/user-management';
import styled from 'styled-components';
import { PencilIcon } from 'icons/PencilIcon';
import CustomLoader from 'components/CustomLoader';

const LoaderContainer = styled.div`
  display: flex;
  margin: 0.8rem 0;
`;
const Container = styled.div`
  width: 100%;
  padding: 1.5rem 3.125rem 0 3.125rem;
  .userTable{
    tr th {
      &:first-child{
        max-width: 3rem;
      }
    };
    tr td {
      &:first-child {
        width: 1rem;
        max-width: 1rem;
      };
    };
  };
  }
`;
const GroupName = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const IconContainer = styled.div`
  margin-top: 0.5rem;
  cursor: pointer;
`;

const initialFlyoutState = {
  isOpen: false,
  flyoutType: UserManagementTableType.GROUP,
  flyoutItemId: null
};

const reducer = (state: FlyoutState, action: FlyoutAction) => {
  switch (action.type) {
    case 'close':
      return { ...state, isOpen: false };
    case 'open':
      return {
        ...state,
        isOpen: true,
        flyoutType: action.flyoutType,
        flyoutId: action.flyoutItemId
      };
    default:
      return state;
  }
};

const GroupDetails = (): JSX.Element => {
  const params: { groupId?: string } = useParams();
  const groupId = params.groupId;
  const [selectedItem, setSelectedItem] = useState<UserTypes>();
  const [groupModal, setGroupModal] = useState(false);

  const [flyoutState, dispatch] = useReducer(reducer, initialFlyoutState);

  const { data: groupDetail, isLoading } = useGetUserManagementGroupByIdV2Query(groupId || '');

  const breadCrumbItem = [
    { label: 'Group and user management', slug: '' },
    { label: 'Groups', slug: '/user-management-v2' },
    { label: groupDetail?.name, slug: groupId }
  ];

  const handleGroupEdit = () => {
    setGroupModal(true);
  };

  return (
    <Container>
      <BreadCrumbs items={breadCrumbItem} />
      {isLoading ? (
        <LoaderContainer>
          <CustomLoader size="2.5rem" thickness=".25rem" />
        </LoaderContainer>
      ) : (
        <GroupName>
          <Typography
            style={{ padding: '.5rem 0', display: 'flex' }}
            mb={0}
            size={'1.75rem'}
            weight="bold"
          >
            {groupDetail?.name}
          </Typography>
          <IconContainer onClick={handleGroupEdit}>
            {' '}
            <PencilIcon />
          </IconContainer>
        </GroupName>
      )}
      <UserManagementTable
        onClickButton={(
          flyoutType: UserManagementTableType,
          flyoutItemId?: Id,
          selectedUser?: UserTypes
        ) => {
          setSelectedItem(selectedUser);
          dispatch({ type: 'open', flyoutType, flyoutItemId });
        }}
        group_id={groupId}
        isGroupPage
      />

      {flyoutState.flyoutType === UserManagementTableType.USER && (
        <UserManagementModal
          tableType={flyoutState.flyoutType}
          open={flyoutState.isOpen}
          setIsOpenModal={() => dispatch({ type: 'close' })}
          tableItemId={flyoutState.flyoutId}
          selectedItem={{
            ...selectedItem,
            groups: [{ id: groupDetail?.id || '', name: groupDetail?.name }]
          }}
          setSelectedItem={setSelectedItem}
        />
      )}
      {groupModal && (
        <GroupModal
          open={groupModal}
          setIsOpenModal={() => {
            setGroupModal(false);
          }}
          tableItemId={groupId}
        />
      )}
    </Container>
  );
};

export default GroupDetails;
