import { useGetAccountInfoQuery } from 'api';
import { useRouter } from 'common/hooks/useRouter';
import { ReactNode } from 'react';
import { fleetPageRoute } from '../../settings/fleetPageRoutes';

export const useMachineViewBreadCrumbs = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  [key: string]: unknown;
} => {
  const { id } = useRouter(fleetPageRoute);

  const { data, isLoading, error } = useGetAccountInfoQuery({ machineId: id });
  const hasError = !error ? undefined : 'error getting data from useGetAcccountInfoQuery';
  const hasMessage = !data && !isLoading && !hasError ? 'no account info' : undefined;

  if (error) console.log({ error });

  return {
    ...data,
    isLoading,
    hasMessage,
    hasError
  };
};
