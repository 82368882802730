import React from 'react';
import {
  ActiveIssues,
  ActiveIssuesTableDataItemProps
} from 'common/ui/ActiveIssuesTable/ActiveIssuesTable';
import { WidgetUi, WidgetUiProps } from 'common/ui';
import { ActiveIssuesWidgetContainer } from './ActiveIssuesWidget.elements';
import { useTranslation } from 'react-i18next';
import { IcoBlueBell } from 'icons/IcoBlueBell';
export interface ActiveIssuesWidgetBaseProps {
  tableData?: ActiveIssuesTableDataItemProps[];
  // this tells the widget what keys to use for populating the UI with data,
  // this helps if youre data structure is different from the widget's
  mapKeys?: Record<keyof ActiveIssuesTableDataItemProps, string>;
}
export interface ActiveIssuesWidgetProps extends WidgetUiProps, ActiveIssuesWidgetBaseProps {
  data?: Record<string, unknown>[];
}

export const ActiveIssuesWidget = ({
  title,
  data,
  className,
  tableData,
  isLoading,
  hasMessage,
  hasError,
  ...rest
}: ActiveIssuesWidgetProps): JSX.Element => {
  const { t } = useTranslation(['mh']);

  const widgetSettings = {
    title,
    hasError,
    hasMessage,
    isLoading,
    className: className
      ? `active-issues-widget scrollable ${className}`
      : `active-issues-widget scrollable`,
    ...rest
    //hasDateFooter: true,
    //...status
  };

  if ((tableData && tableData?.length < 1) || (data && data?.length < 1)) {
    widgetSettings.hasMessage = widgetSettings.hasMessage || (
      <>
        <IcoBlueBell />
        <div className="loader-message">
          {t('no')} {t((title as string) || 'active issues')}
        </div>
      </>
    );
  }

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <ActiveIssuesWidgetContainer>
          <ActiveIssues {...{ data, tableData }} />
        </ActiveIssuesWidgetContainer>
      }
    />
  );
};
