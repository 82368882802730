import React, { CSSProperties, ReactElement } from 'react';
import Tooltip from 'rc-tooltip';
// Icons
import { InformationIcon } from 'icons';
// Theme
import { default as theme } from 'themes';
import 'rc-tooltip/assets/bootstrap.css';
import './InformationTooltip.css';
import { ToolTipIconWrapper } from './InformationTooltip.elements';

interface InformationTooltipProps {
  placement: string;
  tooltipText: string;
  color?: string;
  icon?: ReactElement | ((color?: string) => ReactElement);
  width?: string;
  labelStyle?: CSSProperties;
}

const InformationTooltip = ({
  placement,
  tooltipText,
  color,
  icon,
  width,
  labelStyle
}: InformationTooltipProps): ReactElement => (
  <Tooltip
    overlayClassName="information-tooltip"
    placement={placement}
    overlay={<span style={labelStyle}>{tooltipText}</span>}
    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
    overlayStyle={{
      width: 'fit-content',
      maxWidth: width || '10.3125rem',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '0.875rem',
      letterSpacing: '0em',
      textAlign: 'left'
    }}
  >
    <ToolTipIconWrapper>
      {icon ||
        (
          InformationIcon as (
            color?: string,
            iconWidht?: string,
            iconHeight?: string,
            iconStockWidth?: string
          ) => JSX.Element
        )(color || theme.colors.darkGrey)}
    </ToolTipIconWrapper>
  </Tooltip>
);

export default InformationTooltip;
