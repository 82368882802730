import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoShrink = ({
  width = '24',
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1556_91886)">
        <path
          d="M3.33398 6.00001H6.00065V3.33334"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 2L6 6"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33398 10H6.00065V12.6667"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 14L6 10"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6667 6.00001H10V3.33334"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 6L14 2"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6667 10H10V12.6667"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 10L14 14"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1556_91886">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
