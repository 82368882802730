// We can possibly use only one, but need to research regarding timframe that we use in params when calling api

// 3rd party
import React, { createContext, ReactNode, useContext } from 'react';

// Types
import useMachineStateProtein from 'hooks/useMachineStateProtein';
import { useDateRange } from 'components';

interface State {
  state?: string;
  timestamp?: string;
}

type ContextType = {
  machineState?: State;
  setMachineState?: (state: State) => void;
  isLoading: boolean;
};

const defaultValue = {
  machineState: undefined,
  setMachineState: (state: State) => console.log(state),
  isLoading: false
};

type Props = {
  machineId?: string;
  children?: ReactNode;
  timeZone?: string;
};

const ProteinmachineStatusContext = createContext<ContextType>(defaultValue);

export const useProteinmachineStatus = (): ContextType => {
  return useContext(ProteinmachineStatusContext);
};

// This provider is used on Production tab of machine health for Protein

export const ProteinmachineStatusProvider = (props: Props): JSX.Element => {
  const { machineId, children } = props;

  const { endTime } = useDateRange().utcTZConvertedISO;
  if (!machineId) return <></>;

  // takes current time, suntracts last 15 minutes (because we want to grab latest state),
  // converts it to machine timezone (or UTC if no timezone) and formats it for API call
  const localStartDatetimeString = endTime;

  const { states, isLoading } = useMachineStateProtein(machineId, localStartDatetimeString, 30000);

  // states come back sorted, so we just ned to grab latest value
  const lastState = states?.[states?.length - 1];
  const state = lastState?.name?.toString() || undefined;
  const time = lastState?.end_timestamp?.toString();

  const machineState = { state, timestamp: time };

  const value = {
    machineState,
    setMachineState: () => undefined,
    isLoading
  };

  return (
    <ProteinmachineStatusContext.Provider value={value}>
      {children}
    </ProteinmachineStatusContext.Provider>
  );
};
