import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoDownload = ({
  width = '24',
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Download">
        <path
          id="Icon"
          d="M2.66663 11.1667L2.66663 11.8334C2.66663 12.9379 3.56206 13.8334 4.66663 13.8334L11.3333 13.8334C12.4379 13.8334 13.3333 12.9379 13.3333 11.8334L13.3333 11.1667M10.6666 8.50004L7.99996 11.1667M7.99996 11.1667L5.33329 8.50004M7.99996 11.1667L7.99996 3.16671"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  );
};
