import React from 'react';

export const IcoCheck = ({
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  color = color || '#0076CC';

  return (
    <svg
      className="icon-check"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33301 8.66669L5.99967 11.3334L12.6663 4.66669"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IcoCheckWithCircle = ({
  color,
  size
}: {
  size?: number;
  color?: string;
}): JSX.Element => {
  color = color || '#0076CC';
  size = size || 2;
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--check-mark-with-circle"
    >
      <path
        d="M5 7L6.33333 8.33333L9 5.66667M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={size}
      />
    </svg>
  );
};
