import React, { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'constants/breakpoints';

interface Props {
  children: ReactNode;
}

const { size } = breakpoints;

export const DesktopOrLaptop = ({ children }: Props): JSX.Element | null => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: size.xl + 1 });
  return isDesktopOrLaptop ? <>{children}</> : null;
};

export const Tablet = ({ children }: Props): JSX.Element | null => {
  const isTablet = useMediaQuery({ minWidth: size.md + 1, maxWidth: size.xl });
  return isTablet ? <>{children}</> : null;
};

export const Mobile = ({ children }: Props): JSX.Element | null => {
  const isMobile = useMediaQuery({ maxWidth: size.md });
  return isMobile ? <>{children}</> : null;
};

export const Default = ({ children }: Props): JSX.Element | null => {
  const isNotMobile = useMediaQuery({ minWidth: size.md });
  return isNotMobile ? <>{children}</> : null;
};
