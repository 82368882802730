import React from 'react';
import DataRenderer from '../DataRenderer';
import styled from 'styled-components';
import KPIOverTimeGraph from 'components/KPIOverTimeGraph';
import { useSyncZoom, useTimeZone } from 'providers';
import { Interpolation, Series } from 'types/graph';
import { axisStyle, yAxis } from './utils';
import MachineStatesChart from '../MachineStatesChart';
import { BaseTag, ConfiguredWidget } from 'types/protein';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

const Graphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledLoadingWrapper = styled.div`
  height: 40px;
  padding-top: 1rem;
`;

type Props = {
  chartForWidgetId: string;
  chartTags?: string[];
  booleanTags: BaseTag[];
  numberTags: BaseTag[];
  stringTags: BaseTag[];
  booleanSeries: Series[];
  numberSeries: Series[];
  data: ConfiguredWidget;
  error: Record<string, FetchBaseQueryError | SerializedError | undefined>;
  statesData: BaseTag[];
  loading: Record<string, boolean>;
};
const SingleGraph = ({
  chartForWidgetId,
  chartTags,
  booleanTags,
  numberTags,
  stringTags,
  booleanSeries,
  numberSeries,
  data,
  error,
  statesData,
  loading
}: Props): JSX.Element => {
  const { timeZone } = useTimeZone();
  const { zoomedDomain, onBrushDomainChangeEnd, resetZoom, undoZoom } = useSyncZoom();
  return (
    <div>
      {chartForWidgetId && chartTags && (
        <>
          <DataRenderer
            isLoading={Object.values(loading).includes(true) ? true : false}
            error={
              Object.values(error).some((error) => !!error) ? 'Failed to load tags' : undefined
            }
          >
            <Graphs>
              {booleanTags.length !== 0 && (
                <KPIOverTimeGraph
                  key={`chart-${chartForWidgetId}`}
                  graphContainerHeight="18.3125rem"
                  series={booleanSeries}
                  title={data.name as string}
                  axisH={yAxis(timeZone)}
                  axisV={axisStyle.vertical}
                  sync={true}
                  brush={{
                    zoomedDomain,
                    onBrushDomainChangeEnd,
                    resetZoom,
                    undoZoom
                  }}
                  isBooleanChart={true}
                  interpolation={Interpolation.StepAfter}
                  displayZeroLine={true}
                />
              )}
              {numberTags.length !== 0 && (
                <KPIOverTimeGraph
                  key={`chart-${chartForWidgetId}`}
                  graphContainerHeight="18.3125rem"
                  series={numberSeries}
                  title={data.name as string}
                  axisH={yAxis(timeZone)}
                  axisV={axisStyle.vertical}
                  sync={true}
                  brush={{
                    zoomedDomain,
                    onBrushDomainChangeEnd,
                    resetZoom,
                    undoZoom
                  }}
                  isBooleanChart={false}
                  interpolation={Interpolation.Linear}
                  displayZeroLine={true}
                />
              )}
              {stringTags.length !== 0 && (
                <MachineStatesChart
                  hideSubStepIds
                  isStringTagChart
                  title={data.name as string}
                  states={stringTags}
                />
              )}
              {statesData?.length !== 0 && (
                <MachineStatesChart
                  hideSubStepIds
                  title={data.name as string}
                  states={statesData}
                />
              )}
            </Graphs>
          </DataRenderer>
          <StyledLoadingWrapper>
            <DataRenderer isLoading={false} />
          </StyledLoadingWrapper>
        </>
      )}
    </div>
  );
};

export default SingleGraph;
