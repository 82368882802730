import React, { useState, useEffect, useRef } from 'react';
import {
  CustomIconBtn,
  DropdownContainer,
  IconWrapper,
  PopUpContent,
  PopUpFooter,
  PopUpHeader,
  PopUpInputText,
  PopUpSavedFilters,
  PopUpSavedFiltersContainer,
  PopupContainer,
  SavedFilterText,
  SearchBarWrapper,
  StyledSearch
} from './index.element';
import { Checkbox } from 'components';
import Collapse from 'rc-collapse';
import {
  MenuPanel,
  expandIcon
} from 'pages/MaintenanceServiceDashboard/MaintenanceQueryTableByFrequency';
import { tanStackTableMultiFilterOption } from 'reducers/tanStackTable';
import { IconSearch } from 'icons/IconSearch';
import { StyledTableInput } from 'components/machine-health/configurator/WidgetTable/Form';
import { ChangeEvent } from 'types';
import { IcoFilter } from 'icons/IcoFilter';
import theme from 'themes';

interface AdvanceFilterPopUpProps {
  options?: tanStackTableMultiFilterOption[];
  handleOptionSelect?: (option: AdvanceFilterOptionType[]) => void;
  searchable?: boolean;
  searchInputValue?: (input?: string) => void;
  selectedOpt?: string | JSX.Element;
  label?: JSX.Element;
  iconColor?: string;
}

export interface AdvanceFilterOptionType {
  name: string;
  value: string;
  label?: string;
}

const AdvanceFilterPopUp: React.FC<AdvanceFilterPopUpProps> = ({
  options,
  handleOptionSelect,
  searchable
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [searchInput, setSearchInput] = useState({ category: '', searchString: '' });
  const [filterOption, setFilterOption] = useState(options);
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for the dropdown container

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchOption = (e: ChangeEvent, category: string) => {
    setSearchInput({ category: category, searchString: e.target.value });

    const filteredList = options
      ?.find((item) => item?.category === category)
      ?.filters?.filter(
        (option) =>
          option?.label?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
          option?.value?.toLowerCase()?.includes(e.target.value?.toLowerCase())
      );

    setFilterOption([
      {
        category,
        filters: filteredList || []
      }
    ]);
  };

  // Effect to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [activePanelKeys, setActivePanelKeys] = useState<React.Key[]>([]);

  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    handleOptionSelect && handleOptionSelect(selectedValues);
  }, [selectedValues]);

  // Handle checkbox changes
  const handleCheckboxChange = (event, data) => {
    const { checked } = event.target;
    setSelectedValues((prevSelectedValues) => {
      if (checked) {
        // Add value to the list if checked
        return [...prevSelectedValues, data];
      } else {
        // Remove value from the list if unchecked
        return prevSelectedValues.filter((item) => item?.value !== data?.value);
      }
    });
  };

  return (
    <>
      <DropdownContainer ref={dropdownRef}>
        <CustomIconBtn isActive={isOpen} onClick={toggleDropdown}>
          <IcoFilter width="16" height="16" />
        </CustomIconBtn>
        {isOpen && (
          <PopupContainer top="2rem">
            <PopUpSavedFiltersContainer>
              <SavedFilterText>Saved Filters</SavedFilterText>
              <PopUpSavedFilters>no filters</PopUpSavedFilters>
            </PopUpSavedFiltersContainer>
            <Collapse
              destroyInactivePanel={true}
              activeKey={activePanelKeys}
              expandIcon={({ ...props }) =>
                expandIcon({ color: theme.colors.primaryBlue5, ...props })
              }
              onChange={(key: React.Key | React.Key[]) =>
                setActivePanelKeys(Array.isArray(key) ? key : [key])
              }
            >
              {filterOption &&
                filterOption.map((panel) => {
                  return (
                    <MenuPanel
                      showArrow={true}
                      collapsible="header"
                      key={panel?.category}
                      header={<PopUpHeader>{panel?.category}</PopUpHeader>}
                      mb={0}
                    >
                      {searchable && (
                        <StyledSearch>
                          <SearchBarWrapper>
                            <IconWrapper>
                              <IconSearch width="15" />
                            </IconWrapper>
                            <StyledTableInput
                              type="text"
                              value={searchInput?.searchString}
                              onChange={(e) => {
                                handleSearchOption(e, panel?.category);
                              }}
                              placeholder={`Search ${panel?.category}`}
                              style={{
                                paddingLeft: '2rem',
                                width: '92%'
                              }}
                              borderVariant={'none'}
                              bgColor={theme.colors.white}
                            />
                          </SearchBarWrapper>
                        </StyledSearch>
                      )}
                      {panel?.filters.map((item) => {
                        return (
                          <PopUpContent htmlFor={`checkbox-${item?.value}`}>
                            <Checkbox
                              width={20}
                              height={20}
                              label=""
                              checked={
                                !!selectedValues?.find((user) => user?.value === item?.value)
                              }
                              onChange={(e) =>
                                handleCheckboxChange(e, {
                                  name: panel?.category,
                                  value: item?.value
                                })
                              }
                              id={item?.value}
                            />
                            <PopUpInputText>{item.label}</PopUpInputText>
                          </PopUpContent>
                        );
                      })}
                    </MenuPanel>
                  );
                })}
            </Collapse>
            <PopUpFooter>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <p style={{ color: theme.colors.lightGrey8 }} onClick={() => setSelectedValues([])}>
                  Clear
                </p>
                <p style={{ color: theme.colors.primaryBlue5 }} onClick={() => setIsOpen(!isOpen)}>
                  Apply
                </p>
              </div>
            </PopUpFooter>
          </PopupContainer>
        )}
      </DropdownContainer>
    </>
  );
};

export default AdvanceFilterPopUp;
