import styled from 'styled-components';

export const FullScreenViewContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  
  button { 
    display: none;
  }
  
  .widget-ui {
    height: 100%!important;
    width: 100%;
    font-size: clamp(15px, 3vw, 32px)

  }

  @keyframes fade-in-cover {
    0% {
      display: none
      opacity: 0;
    }
    2% {
      display: grid;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
