import React, { ReactNode } from 'react';
import { TooltipWrapperContainer } from './TooltipWrappers.elements';

export interface TooltipWrapperProps {
  left?: number;
  top?: number;
  x?: number;
  y?: number;
  TooltipComponent?: (props: Record<string, unknown>) => JSX.Element;
  closeTooltip?: () => void;
  [key: string]: unknown;
}

interface Props extends TooltipWrapperProps {
  children?: ReactNode | ReactNode[];
}

export const DefaultTooltipContainer = ({
  left,
  top,
  children,
  TooltipComponent,
  closeTooltip,
  ...data
}: Props): JSX.Element => {
  if (!left || !top) return <></>;

  const Content = TooltipComponent ? <TooltipComponent {...data} /> : children;

  return (
    <TooltipWrapperContainer {...{ left, top, onMouseLeave: () => closeTooltip?.() }}>
      <div className="content-wrapper">
        <div className="content">{Content}</div>
      </div>
    </TooltipWrapperContainer>
  );
};
export const DefaultTooltipWrapper = DefaultTooltipContainer;
