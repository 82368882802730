import { styledTheme } from 'components';
import styled from 'styled-components';

export const ResponsiveInlineLegendContainer = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns auto 1fr;
  align-items: center;
  gap: 1em;

  .legend-wrapper {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
  }
   
  ul {
    display: flex;
    gap: 1em;
    flex-wrap: nowrap;
    overflow: hidden;
    padding: 0;
    margin: 0;
    li {
      max-width: max-content;
      cursor: pointer;
    }
  }

  .dropdown-container {
    position: relative;
    max-width: max-content;
    min-height: 100%;
    
    &:hover {
      ul {
        display: flex;
        overflow-y:auto;
        height:auto;
        max-height:15.625rem;
        min-height:1.875rem;
      }
    }

    .dropdown-button {
      cursor: pointer;
      display: flex;
      gap: .5em;
      align-items: center;

      .icon-chevron {
        path {
          fill: ${styledTheme.color.main}!important;
        }
      }
    }

    ul {
      background-color: white;
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      margin: 0;  
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      flex-direction: column;
      z-index: 100;
      gap: 0;

      li {
        cursor: pointer;
        padding: .5em 1em;
        width: 100%;
        max-width: 100%;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
`;
