import React, { ReactNode } from 'react';
import { KPIWidgetCellContainer } from './KPIWidgetCell.elements';
import { useTranslation } from 'react-i18next';
import { IcoClock } from 'icons/IcoClock';

export interface KPIWidgetCellProps {
  title?: ReactNode;
  value?: ReactNode;
  label?: ReactNode;
  color?: string;
  className?: string;
  // this normally gets sent from the main widget, but you can set in the data array too
  type?: string;
  isCenterCell?: boolean;
}

export const KPIWidgetCell = ({
  title,
  value,
  label,
  color,
  className,
  type,
  isCenterCell
}: KPIWidgetCellProps): JSX.Element => {
  className += ' kpi-widget-cell-container';
  const { t } = useTranslation(['mh']);

  // add class modifiers
  if (type) {
    className += ' is-icon';
    if (type === 'time') title = <IcoClock />;
  }
  if (isCenterCell) className += ' is-center-cell';

  if (typeof title === 'string') title = t(title.toLowerCase() as string) as string;
  if (typeof label === 'string') label = t(label.toLowerCase() as string) as string;

  return (
    <KPIWidgetCellContainer {...{ colr: color, className }}>
      {title && <div className="kpi-widget-cell__title">{title}</div>}
      <div className="kpi-widget-cell__value">{value || 'no data'}</div>
      {label && <div className="kpi-widget-cell__label">{label}</div>}
    </KPIWidgetCellContainer>
  );
};
