// colors will be an object who's keys are the formatted string values of the group/category/state names as they appear in the interface.
/**
 *  example colors = {
 *    'production uptime': '#FF0000',
 *    idle: '#00FF00',
 *  }
 */

const stepOverColors: Record<string, string> = {
  Drying: 'rgba(230, 108, 55, 1)',
  'Drainage of nozzles': 'rgba(0, 118, 204, 1)',
  'Air purge of circuits before drying': 'rgba(138, 136, 134, 1)',
  'Defrost inspection': 'rgba(0, 158, 179, 1)',
  'Roller chain rinse': 'rgba(182, 44, 16, 1)',
  'Foaming of belt, evaporator and equipment': '#00cc99',
  'Belt rinse': '#29A429',
  'Hot gas': 'rgb(105, 193, 150)',
  'Evaporator defrost': 'rgb(234, 118, 0)',
  'Defrost inspection middle': '#e91e63',
  'Equipment defrost/rinse': 'rgba(138, 136, 134, 1)',
  'Mist rinse': '#c135d4',
  'Foaming inspection': 'rgba(131, 93, 208, 1)',
  'Equipment defrost': '#5e3b27',
  'Evaporator rinse': 'rgba(255, 173, 193, 1)',
  'Rinse inspection end': 'rgba(252, 214, 65, 1)',
  'Cleaning detergent nozzles': 'rgba(125, 150, 195, 1)',
  'Rinse inspection middle': '#d43552',
  Completed: '#8c1aff',
  Other: '#000000'
};

const recipeColors: Record<string, string> = {
  'chicken nuggets *': 'rgba(230, 108, 55, 1)',
  'full defrost and clean': 'rgba(0, 118, 204, 1)',
  drying: 'rgba(138, 136, 134, 1)',
  'belt rinse test': 'rgba(0, 158, 179, 1)',
  'no selection': 'rgba(182, 44, 16, 1)',
  'BK Tempura Test 05Oct': '#00cc99',
  rinse: 'rgb(105, 193, 150)',
  'BK Tempura Test 05Oct *': 'rgb(234, 118, 0)',
  'ww 2': '#e91e63',
  'ww 2 *': '#c135d4',
  'crispy fillet': 'rgba(131, 93, 208, 1)',
  'crispy fillet *': '#5e3b27',
  'bk wings': 'rgba(255, 173, 193, 1)',
  'bk long': 'rgba(252, 214, 65, 1)',
  'manual cleaning': 'rgba(125, 150, 195, 1)',
  'bk fc value burger': '#d43552',
  'crispy strips': '#8c1aff',
  '18.polbagietka *': '#29A429',
  '18.polbagietka': '#2A629A',
  'no Selection': '#29A429'
};

export const colors: Record<string, string> = {
  alarm: 'rgba(230, 108, 55, 1)',
  'critical alarm': 'rgba(0, 118, 204, 1)',
  'warning information': 'rgba(138, 136, 134, 1)',
  undefined: '#000000',
  ...stepOverColors,
  ...recipeColors
};
