import React from 'react';
import type { FC, SVGProps } from 'react';

const IcoCross2: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    {...props}
  >
    <circle cx="6.5" cy="6.5" r="6" stroke={props.color ? props.color : '#B62C10'} />
    <path
      d="M3.04443 9.49854L9.7501 3.90006"
      stroke={props.color ? props.color : '#B62C10'}
      strokeLinecap="round"
    />
    <path
      d="M3.25 3.8999L9.75 9.7499"
      stroke={props.color ? props.color : '#B62C10'}
      strokeLinecap="round"
    />
  </svg>
);

export default IcoCross2;
