import React, { useState, useMemo, useRef, useEffect, ReactElement } from 'react';
import theme from 'themes';
import styled from 'styled-components';
import { Ellipsys } from 'icons/Ellipsys';
import './overflow-menu.css';

interface OverflowMenuProps {
  children: ReactElement | ReactElement[];
  visibilityMap: Record<string, boolean>;
}

const OverflowStyle = styled.div`
  order: 99;
  position: relative;
  background-color: white;
`;

const DropdownContainer = styled.div`
  display: flex;
  gap: 0.313rem;
  align-items: center;
  order: 99;
  background-color: white;
`;

const IconContainer = styled.button`
  all: unset;
  cursor: pointer;
  line-height: 0;
  padding: 0.3rem;
  width: 1.8rem;
  box-sizing: border-box;
  :focus {
    background: ${theme.colors.lightGrey10};
    border-radius: 0.3rem;
    border: 1px solid ${theme.colors.primaryBlue5 + '80'};
    box-sizing: border-box;
  }
`;

const PopupContainer = styled.div<{ top?: string; overflow?: string; width?: string }>`
  position: absolute;
  top: ${({ top }) => top || '100%'};
  width: ${({ width }) => width || 'max-content'};
  z-index: 99;
  right: 0;
  background-color: white;
  border-radius: 0.5rem;
  overflow: ${({ overflow }) => overflow || 'hidden'};
  list-style: none;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const OptionItem = styled.li<{ width?: string; padding?: string }>`
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding || '1rem'};
  cursor: pointer;
  position: relative;
  overflow: visible;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey10};
  }
  &:hover ul {
    display: block;
  }
`;

const OptionText = styled.div`
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OverflowMenu = ({ children, visibilityMap }: OverflowMenuProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const shouldShowMenu = useMemo(
    () => Object.values(visibilityMap).some((v) => v === false),
    [visibilityMap]
  );

  if (!shouldShowMenu) {
    return null;
  }
  return (
    <OverflowStyle>
      <DropdownContainer ref={dropdownRef}>
        <IconContainer onClick={toggleDropdown}>
          <Ellipsys />
        </IconContainer>
        {isOpen && (
          <PopupContainer overflow="initial" className="overflow-menu" top="2rem">
            {React.Children.map(children, (child) => {
              if (
                child &&
                child.props['data-targetid'] &&
                !visibilityMap[child.props['data-targetid']]
              ) {
                return (
                  <OptionItem key={child.props['data-targetid']} padding=".8rem 1rem">
                    <OptionRow>
                      <OptionText>{React.cloneElement(child)}</OptionText>
                    </OptionRow>
                  </OptionItem>
                );
              }
              return null;
            })}
          </PopupContainer>
        )}
      </DropdownContainer>
    </OverflowStyle>
  );
};

export default OverflowMenu;
