import { round } from 'lodash';
interface DecimalFormatOptionsProps {
  minimumSignificantDigits?: number;
}

export function formatDecimal(
  value: number,
  { minimumSignificantDigits }: DecimalFormatOptionsProps = {}
): string {
  // const formatterLocale = userLocale ?? getCurrentLocal();
  const formater = Intl.NumberFormat(undefined, { style: 'decimal', minimumSignificantDigits });

  return formater.format(value);
}

export function formatPercentage(value: number): string {
  const formater = Intl.NumberFormat(undefined, { style: 'percent' });

  return formater.format(value);
}

export const validateNumber = (value: number): boolean => {
  return !isNaN(value);
};

export const formatDecimalValue = ({
  value,
  roundOffDigit = 2
}: {
  value: number;
  roundOffDigit?: number;
}): number => {
  const pattern = /e/;
  if (value && typeof value === 'number') {
    // check for exponential notation, e.g. 1.2e+23
    if (pattern.test(value as unknown as string)) {
      return value.toPrecision(roundOffDigit) as unknown as number;
    }
    return round(value, roundOffDigit).toFixed(roundOffDigit) as unknown as number;
  }
  return value;
};
