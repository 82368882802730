import styled from 'styled-components';
import { styledTheme } from 'components';
import { StyledUiContainerProps } from 'components';

export const MachineInfoBarContainer = styled.div<StyledUiContainerProps>`
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  gap: 0.5em;
  align-items: center;

  color: ${styledTheme.color.gray};
  grid-area: ${({ gridArea }) => gridArea};

  .machine-info-bar__item {
    padding-left: 0.5em;
    display: flex;
    flex-grow: 0;
    gap: 0.5em;
    border-left: solid 1px rgba(0, 0, 0, 0.3);
  }

  .machine-info-bar__item:first-child {
    border-left: none;
    padding-left: 0;
  }
`;

export const MachineStateIconContainer = styled.div<{ color?: string }>`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 0.3em;
  min-width: max-content;

  .label {
    font-weight: 600;
    text-transform: capitalize;

    color: ${({ color }) => color || 'inherit'};
  }

  .icon {
    width: 1em;
    height: 1em;
  }

  .icon:not(.icon--check-mark-with-circle) path {
    stroke-width: 4;
  }
`;
