import styled from 'styled-components';

interface containerProps {
  containerPosition?: string;
}
export const DropdownContainer = styled.div<containerProps>`
  position: ${({ containerPosition }) => containerPosition || 'relative'};
  display: flex;
  gap: 0.313rem;
  align-items: center;
`;

export const PopupContainer = styled.div<{ top?: string; overflow?: string; width?: string }>`
  position: absolute;
  top: ${({ top }) => top || '100%'};
  width: ${({ width }) => width || 'max-content'};
  z-index: 99;
  right: 0;
  background-color: white;
  border-radius: 0.5rem;
  overflow: ${({ overflow }) => overflow || 'hidden'};
  list-style: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;
export const List = styled.ul`
  list-style: none;
  max-height: 19rem;
  overflow-y: auto;
  padding: 0;
  margin: 0;
`;
export const OptionItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey10};
  }
`;

export const StyledSearch = styled.div`
  padding: 0.6rem 0;
  display: flex;
  flex-direction: column;
  input {
    margin: 0.5rem 0.5rem 0.125rem;
  }
`;

export const SearchBarWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  cursor: auto;
`;
export const OptionItem = styled.li<{ width?: string; padding?: string }>`
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding || '1rem'};
  cursor: pointer;
  position: relative;
  max-width: 11rem;
  overflow: visible;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey10};
  }
  &:hover ul {
    display: block;
  }
`;
export const SelectedOption = styled.div`
  width: 4rem;
`;
export const SubmenuContainer = styled.ul<{ width?: string }>`
  width: ${({ width }) => width};
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  left: 100%;
  top: 0;
  width: max-content;
  z-index: 9999;
  right: 0;
  background-color: white;
  border: 1px;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  display: none;
`;
