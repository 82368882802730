import React from 'react';
import { TabsNavContainer } from './TabsNav.elements';
import { useTranslation } from 'react-i18next';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';

export interface TabsNavTabItemProps {
  label: string;
  slug: string;
}

export interface RouterTabsNavProps {
  // the url path with variables as the folder in the url path
  // example: pageRoute: /path/page/machineId --- with url --.com/fleet/machine/1234 would give you variables path: fleet, page: machine, machineId: 1234
  pageRoute?: string;
  // path key is the variable to check from above (in the example, this link controls the machineId variable to check if this tab is active)
  pathKey?: string;
  tabs?: TabsNavTabItemProps[];
  // the path that the slug will be added to on click
  basePath?: string;
  style?: 'page-tab' | 'sub-tab';
  size?: 'sm' | 'lg';
  // manually set the path to use for the link
  path?: string;
  isDisabled?: boolean;
}

interface TabItemProps extends TabsNavTabItemProps, RouterTabsNavProps {}

const TabItem = ({ slug, label, pathKey, pageRoute, basePath, path, isDisabled }: TabItemProps) => {
  const { t } = useTranslation(['mh']);

  if (!pathKey && !pageRoute) {
    console.error('missing tabs, pathKey, or pageRoute in RouterTabsNav/NavItem');
    return <>error loading nav</>;
  }

  if (!pathKey) {
    console.log('missing pathKey in RouterTabsNav/NavItem');
    pathKey = 'path';
  }

  let className = 'router-tabs-nav__nav-item';

  //const history = useHistory();
  const [{ currentUrl, ...urlPaths }, handle] = useFleetRouter(); //useRouterWithHandler(pageRoute);

  let isCurrent = false;

  const curSlug = urlPaths?.[pathKey] as string;

  if (curSlug === slug) isCurrent = true;
  if (path && currentUrl && String(currentUrl)?.includes(path as string)) isCurrent = true;

  if (isCurrent) className += ' is-current';
  if (isDisabled) className += ' is-disabled';

  const handleClick = () => {
    if (isDisabled) return;
    if (path) return handle('setPath', { path });
    if (pathKey && slug) {
      console.log({ pathKey, slug });
      return handle('updatePath', { [pathKey]: slug });
    }
    //return set('setPath', { path });
  };

  const urlLink = basePath
    ? `${basePath}/${slug}`
    : (handle('getUpdatedPath', { [pathKey]: slug }) as string);

  return (
    <a
      {...{
        href: urlLink,
        className,
        onClick: (e) => {
          e.preventDefault();
          return handleClick();
        }
      }}
    >
      {t(label.toLocaleLowerCase())}
    </a>
  );
};

export const RouterTabsNav = ({ tabs, style, size, ...rest }: RouterTabsNavProps): JSX.Element => {
  let className = `router-tabs-nav`;
  // add the style of tab, for page main tab style, choose 'full-width'
  if (style) className += ` ${style}`;
  // for smaller or bigger style tabs, choose 'sm' or 'lg', this changes font-size but not style
  if (size) className += ` ${size}`;

  if (!tabs) {
    console.error('missing tabs, pathKey, or pageRoute in RouterTabsNav');
    return <></>;
  }

  return (
    <TabsNavContainer {...{ className }}>
      {tabs.map(({ label, slug, ...tab }) => (
        <TabItem key={slug} {...{ label, slug, ...rest, ...tab }} />
      ))}
    </TabsNavContainer>
  );
};
