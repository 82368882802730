import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

export const KPIWidgetContainer = styled.div<StyledUiContainerProps>`
  width: 100%;
  color: inherit;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;

  font-size: 1em;

  &.text-size--small {
    font-size: 0.7em;
  }

  &.text-size--medium {
    font-size: 0.9em;
  }

  &.text-size--large {
    font-size: 1.2;

    .kpi-widget__values-wrapper--sub-cells {
      min-height: 0;
    }
  }

  &.text-size--xl-sub-cells {
    .kpi-widget__values-wrapper--sub-cells {
      font-size: 1.5em;
    }
  }

  .kpi-widget-cell--sub-cell {
    font-size: 0.8em;
  }

  .kpi-widget__values-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
    min-height: 1em;

    .kpi-widget-cell {
      color: inherit;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .kpi-widget-cell--center-cells {
      flex-grow: 0;
      color: rgba(102, 102, 102, 1);
      svg path {
        stroke: rgba(102, 102, 102, 1);
      }
    }
  }
`;
