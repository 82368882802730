import styled from 'styled-components';
import breakpoint from 'constants/breakpoints';
import theme from 'themes';

export interface Props {
  toggleSize?: number;
}

export const StepContainer = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 0.75rem 0.75rem;
  width: 100%;
  flex-direction: column;
  background: #f9fafb;
  transition: all 1s linear;

  .label-fields {
    font-size: 0.875rem !important;
    margin: 0;
    font-weight: 500 !important;
  }

  .header {
    display: flex;
    flex-direction: row;

    button.chevron {
      border: 0;
      background: none;
      cursor: pointer;
      width: 26px;
    }
  }

  .wrapper {
    display: block;

    &.closed {
      display: none;
    }

    &.open {
      display: block;

      .date-range-picker {
        left: 0;
        right: auto;
        margin-bottom: 3rem;
      }
    }
  }

  h2 {
    margin: 1rem 0.5rem;
    color: #212121;
    font-size: 16px;
  }

  .radioInput-container--label > label,
  .label,
  .label label {
    color: #323130;
    font-weight: 700;
    font-size: 12px;

    small {
      color: red;
      font-weight: 700;
    }
  }

  .select--disabled {
    cursor: none;
    opacity: 0.5;
  }

  .column {
    &.fixed-width {
      width: 200;

      @media ${breakpoint.device.lg} {
        min-width: 240px;
      }

      @media ${breakpoint.device.xl} {
        min-width: 300px;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    position: relative;

    input {
      max-width: 6.5rem;
    }

    &.reminder {
      flex-direction: column;

      .input--stopAfter {
        margin-left: 1rem;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 2rem;
      }

      p {
        font-size: 14px;
        padding: 0 0.5rem;
      }
    }

    @media ${breakpoint.device.lg} {
      flex-wrap: nowrap;
    }
    input[type='number'].input--stopAfter {
      width: 6.8rem;
      min-width: 7rem;
    }

    input[type='number'] {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: 1px solid #e5e9ed;
      height: 2.4rem;
      min-width: 60px;
      padding: 0 1rem;

      @media ${breakpoint.device.lg} {
        min-width: 80px;
      }

      @media ${breakpoint.device.xl} {
        min-width: 100px;
      }

      @media ${breakpoint.device.xxl} {
        min-width: 2.5rem;
      }
    }

    input[type='number']::-webkit-inner-spin-button {
      opacity: 1;
      -webkit-appearance: inner-spin-button !important;
      width: 30px;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      background: #303e47;
    }

    .dropdown {
      width: 8rem;
      select {
        min-width: 60px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: #ffffff;

        @media ${breakpoint.device.lg} {
          min-width: 80px;
        }

        @media ${breakpoint.device.xl} {
          min-width: 100px;
        }

        @media ${breakpoint.device.xxl} {
          min-width: 2.5rem;
        }
      }

      select:focus {
        outline: none;
      }

      & > div {
        height: 2.24rem;
      }
    }

    .row {
      padding: 0.25rem;
    }

    .column {
      .row {
        padding: 0;
      }
    }

    &.column {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }

    .card {
      max-width: 420px;
      margin: 1rem auto 2rem;
      width: 100%;
      border-radius: 12px;
      box-shadow: 0px 0px 1px 1px #cccccc;
      margin-bottom: 3rem;

      .header {
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
        padding: 1.25rem 1.5rem;
        background: #f7f7f8;
        border-top-right-radius: inherit;
        border-top-left-radius: inherit;
      }

      .counter {
        text-align: center;
        font-size: 1.25rem;
        font-weight: 600;
        margin: 0.75rem 0;
      }

      .alertCount {
        padding: 2rem 0;
        background-size: cover;

        img {
          display: block;
          margin: 0 auto;
          max-width: 75px;
        }
      }
    }
  }

  .frequency_container {
    .slidingWindowRow {
      justify-content: start !important;
    }
  }

  .inputHoverSec input:hover {
    border-color: ${theme.colors.primaryBlue5} !important;
  }

  .customSelectHover {
    border: 0.063rem solid #d8dde3;
    select {
      border: none !important;
    }
  }

  .customSelectHover:hover {
    border: 0.063rem solid ${theme.colors.primaryBlue5} !important;
  }

  .cstmMatchInput {
    border: 0.063rem solid #d8dde3;

    input {
      border: none !important;
    }
  }
  .cstmMatchInput:hover {
    border-color: ${theme.colors.primaryBlue5} !important;
  }

  .radioInput-container {
    min-width: 300px;
  }

  .radioInput-container--wrapper {
    padding-bottom: 10px;
    &.range {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .matches,
      .duration {
        display: block;
        opacity: 0;
        min-width: 110px;

        &.active {
          opacity: 1;
          position: relative;
        }
      }

      .duration--input {
        min-width: 65px;
      }

      .dropdown {
        select {
          min-width: 100px;
        }
      }

      .matches {
        min-width: 60px;
        input {
          min-width: 8.485rem;
          margin: 0px;
        }
      }

      .duration {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        min-width: auto;

        input {
          margin: 0;
          min-width: 110px;
        }
      }
    }
  }

  .customMatchesBox {
    input {
      max-width: 100%;
      width: 100%;
    }
  }

  .radioInput-container--wrapper--input {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    min-width: 100%;
    .input--radio {
      display: flex;
      flex-direction: column;

      input {
        align-self: flex-start;
        order: 3;
      }

      div:nth-child(2) {
        top: 32px;
        left: 0;
      }

      div:nth-child(3) {
        order: 1;
        margin: 0;
      }

      label {
        font-size: 14px;
      }
    }
  }

  .dropdown_checkboxes {
    max-width: 300px;
  }

  .option {
    .option--checkbox {
    }
  }

  .dropdown-header-row {
    .header--label {
      color: ${theme.colors.black};
      font-size: 0.875rem;
      font-weight: 600;
      text-transform: none;
      padding: 1rem 0.25rem;
    }
  }
`;

export const ToggleInput = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .toggle-container {
    width: ${({ toggleSize }) => (toggleSize && `calc(${toggleSize}px * 2)`) || `30px`};
    background-color: #b6caf0;
    cursor: pointer;
    user-select: none;
    border-radius: 15px;
    padding: 2px;
    height: ${({ toggleSize }) => (toggleSize && `calc(${toggleSize}px * 0.6)`) || `25px`};
    position: relative;
    margin-left: 1rem;
  }

  .dialog-button {
    cursor: pointer;
    background-color: #0a70ff;
    color: white;
    padding: ${({ toggleSize }) => (toggleSize && `calc(${toggleSize}px / 2)`) || `12px`};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    //min-width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ toggleSize }) => toggleSize + 'px' || `25px`};
    height: ${({ toggleSize }) => toggleSize + 'px' || `25px`};
    min-width: unset;
    border-radius: ${({ toggleSize }) => toggleSize + 'px' || `25px`};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: ${({ toggleSize }) => (toggleSize && `calc(${toggleSize}px * 1.15)`) || `13px`};
    top: ${({ toggleSize }) => (toggleSize && `calc(-${toggleSize}px * 0.15)`) || `-3px`};
    transition: all 0.3s ease;
  }

  .disabled {
    background-color: #a3a3a3;
    left: ${({ toggleSize }) => (toggleSize && `calc(-${toggleSize}px * 0.15)`) || `-3px`};
  }

  .container-wrapper {
    &.reminder {
      margin-top: 1rem;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
export const InsertButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  cursor: pointer;
  color: ${theme.colors.primaryBlue5};
  align-items: center;
  font-size: 14px;
  border-top: 1px solid #ccc;
`;

export const MenuListOptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  > input {
    scale: 1.3 !important;
  }
`;
export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  label {
    font-size: 0.875rem;
    font-weight: 400;
  }
`;

export const InputWrapper = styled.div<{ error?: boolean }>`
  display: flex;
  position: relative;
  border-radius: 0.375rem;
  border: ${({ error }) => (error ? `0.063rem solid ${theme.colors.text.error} !important` : '')};
  > input {
    max-width: 6.8rem;
    :focus {
      outline: none;
    }
  }
`;

export const ErrorBorderWrapper = styled.div<{ error?: boolean; hideLeftBorder?: boolean }>`
  border: ${({ error }) =>
    error ? `0.063rem solid ${theme.colors.text.error} !important` : 'none'};
  border-radius: 0rem 0.375rem 0.375rem 0rem;
  ${({ hideLeftBorder }) => (hideLeftBorder ? 'border-left: none' : '')}
`;
export const RequiredMessageWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.2rem;
  font-size: 0.75rem;
  span {
    margin-top: 0.5rem;
    color: ${theme.colors.text.error};
  }
`;

export const SimulationWrapper = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  bottom: 29%;
  align-items: center;
  gap: 0.55em;
  border: 1px solid #ccc;
  height: 3.2rem;
  margin-top: 3rem;
  border-radius: 0.375rem;
  z-index: 90;
  background: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  .count {
    border-right: 1px solid #ccc;
    padding: 10px;
    font-size: 20px;
    color: #b62c10;
    font-weight: 600;
  }

  .mid-section {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    span {
      padding: 0.25rem;
      padding-left: 0rem;
    }
    .timestamp {
      font-size: 0.75rem;
      .end-timestamp {
        padding-left: 0.3rem;
      }
    }
    .title {
      font-weight: 600;
      font-size: 0.875rem;
    }
  }

  .date-wrapper {
    display: flex;
    align-items: center;
    border-left: 1px solid #ccc;
    width: 3.5rem;
    height: 70%;
    display: flex;
    padding-right: 1rem;
    justify-content: flex-end;
  }

  .alert-simulator {
    .ui-date-range-btn {
      & > svg {
        height: 1.125rem !important;
        width: 1.125rem !important;
      }
    }

    .date-range-picker {
      left: auto !important;
      right: -1rem !important;
      top: 2.2rem !important;
    }
  }
`;
export const AlertSetupHeading = styled.div`
  margin: 5px 0.24rem;
  margin-bottom: 6px;
  color: ${theme.colors.text.lightBlack};
  font-size: 16px;
  font-weight: 500;
`;
