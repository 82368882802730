import React, { ReactNode, useState } from 'react';
import { Icons } from './Icons';
import { Tooltip } from '../Tooltip/Tooltip';
import { TooltipIconContainer } from './TooltipIcon.elements';

export interface TooltipIconProps {
  iconType?: 'info' | string;
  IconComponent?: React.FC;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

interface Props extends TooltipIconProps {
  children?: ReactNode;
}

export const TooltipIcon = ({
  children,
  iconType,
  IconComponent,
  position
}: Props): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false);

  iconType = iconType || 'info';
  position = position || 'top';

  const className = `tooltip-icon tooltip-icon--${iconType} tooltip-icon--${position}`;
  const Icon = IconComponent || Icons?.[iconType];

  let tooltipSettings: Record<string, string> = {
    left: '50%',
    bottom: '100%'
  };

  if (position === 'bottom') {
    tooltipSettings = {
      left: '50%',
      bottom: '0'
    };
  }

  return (
    <TooltipIconContainer
      className={className}
      onMouseOver={() => setShowTooltip(true)}
      onMouseOut={() => setShowTooltip(false)}
    >
      <Icon />
      {children && showTooltip && <Tooltip {...tooltipSettings}>{children}</Tooltip>}
    </TooltipIconContainer>
  );
};
