import React from 'react';
import styled from 'styled-components';
import theme from 'themes';

const Svg = styled.svg.attrs(({ color, width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  viewBox: '0 0 16 16',
  fill: color || theme.colors.primaryBlue5,
  strokeWidth: 1.3
}))``;

const IcoTableColumnOption = ({ width }: { width?: string }): JSX.Element => {
  return (
    <Svg width={width} xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 2.5H6" stroke="#0076CC" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.5 6.5H6" stroke="#0076CC" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.5 10.5H6" stroke="#0076CC" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.5 14.5H6" stroke="#0076CC" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 2.5H15.5" stroke="#0076CC" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 6.5H15.5" stroke="#0076CC" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 10.5H15.5" stroke="#0076CC" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 14.5H15.5" stroke="#0076CC" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export default IcoTableColumnOption;
