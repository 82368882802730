import React from 'react';

export interface Props {
  color?: string;
}

export const BellRegularIcon = ({ color }: Props): JSX.Element => {
  color = color || '#B62C10';

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 12.1667H14.6667L13.4959 10.9959C13.1784 10.6784 13 10.2477 13 9.79871V7.16667C13 4.98964 11.6087 3.13757 9.66671 2.45118V2.16667C9.66671 1.24619 8.92052 0.5 8.00004 0.5C7.07957 0.5 6.33337 1.24619 6.33337 2.16667V2.45118C4.39139 3.13757 3.00004 4.98964 3.00004 7.16667V9.79871C3.00004 10.2477 2.82166 10.6784 2.50415 10.9959L1.33337 12.1667H5.50004M10.5 12.1667V13C10.5 14.3807 9.38075 15.5 8.00004 15.5C6.61933 15.5 5.50004 14.3807 5.50004 13V12.1667M10.5 12.1667H5.50004"
        stroke={`${color}`}
      />
    </svg>
  );
};
