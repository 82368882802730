// 3rd party libs
import React from 'react';
import { BreadCrumbs } from 'components/StyledUi/FleetBreadCrumbs';
import { MachineViewWatchdogStatusIcon } from './MachineViewWatchdogStatusIcon';
import { useRouter } from 'common/hooks/useRouter';
import { hasConfig, hasWatchdog } from '../../settings/buDefaultSettings';
import { fleetPageRoute } from '../../settings/fleetPageRoutes';
import { useMachineInfo } from '../../providers';
import { useMachineViewBreadCrumbs } from './useMachineViewBreadCrumbs';

export const MachineViewBreadCrumbs = (): JSX.Element => {
  const { companyName, siteName, lineName, plantId, ...apiRest } = useMachineViewBreadCrumbs();
  const { description, businessUnit, lineId, isLoading } = useMachineInfo();

  const { machineId } = useMachineInfo();
  const { page } = useRouter(fleetPageRoute);

  if (!businessUnit && !isLoading) return <div>invalid business unit</div>;

  let items: Record<string, unknown>[] = [
    {
      label: companyName as string,
      isLoading,
      slug: undefined
    }
  ];

  if (page === 'machine' || page === 'line' || page === 'site') {
    items = [
      ...items,
      {
        label: siteName as string,
        slug: !plantId ? undefined : `/fleet/site/${plantId}`,
        isLoading
      }
    ];

    if (page === 'line' || page === 'machine') {
      items = [
        ...items,
        {
          label: lineName as string,
          slug: !lineId ? undefined : `/fleet/line/${lineId}`,
          isLoading
        }
      ];
    }

    if (page === 'machine') {
      items = [
        ...items,
        {
          label: description as string,
          isLoading,
          slug: !machineId ? undefined : `/fleet/machine/${machineId}/machine-health/overview`
        }
      ];
    }
  }

  const showConfigButton = hasConfig?.[businessUnit as string];
  const showWatchdogIcon = hasWatchdog?.[businessUnit as string];

  return (
    <BreadCrumbs {...{ items, ...apiRest }}>
      {showWatchdogIcon && <MachineViewWatchdogStatusIcon />}
      {showConfigButton && (
        //<EditButton {...{ handle }} />
        <div>edit</div>
      )}
    </BreadCrumbs>
  );
};
