/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// 3rd party libs
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import theme, { themeColors } from 'themes';
import { isEmpty } from 'lodash';

// Components
import { Checkbox, TooltipWrapper } from 'components';

// User Management Components
import {
  BulkUser,
  Group,
  User,
  UserGroup,
  UserManagementTableType,
  UserTypes,
  ValidatedUsersResponse
} from 'types/user-management';
import { TFunction } from 'i18next';
// Types
import { ChangeEvent, TableColumnConfigs } from 'types';
import { ColumnConfig } from '../../../components/NewBaseTable/NewBaseTable';
import { CellContext } from '@tanstack/react-table';
import { PencilIcon } from 'icons/PencilIcon';
import { AddUserIcon } from 'icons/AddUserIcon';
import { GroupAddIcon } from 'icons/GroupAddIcon';
import { TableAction } from '..';
import UserRole from './User/UserRole';
import { DropDownItem } from 'pages/ProteinMachine/MachineConfig/Common/Alerts/FormElementsTypes';
import {
  ClearValues,
  DropdownIndicator,
  Option
} from 'pages/ProteinMachine/MachineConfig/Common/Alerts/DropdownWithCheckboxes';
import Select, { MultiValue } from 'react-select';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { IcoExclamationCircleHollow } from 'icons/IcoExclamationCircleHollow';

//props def
interface DynamicInputProps {
  variant?: string;
  borderVariant?: string;
}
// Styling
const TableCellWrapper = styled.div`
  display: flex;
`;
const TableCellItemWrapper = styled.div`
  width: 150px;
  height: 45px;
  padding-left: 10px;
  padding-top: 4px;
`;
const TableCell = styled.div<{ display?: string; overflow?: string }>`
  display: ${({ display }) => display};
  align-items: center;
  gap: 0.3rem;
  max-width: 12rem;
  text-overflow: ellipsis;
  overflow: ${({ overflow }) => overflow || 'hidden'};
  white-space: nowrap;
  word-wrap: break-all;
  display: flex;
  align-items: center;
`;
const TableCellSharedData = styled.div`
  width: 2rem;
`;
const TableCellButton = styled.div`
  align-items: right;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
const TableDataLink = styled.div`
  color: ${theme.colors.primaryBlue5};
  cursor: pointer;
  a {
    text-decoration: none;
    color: ${theme.colors.primaryBlue5};
  }
`;
const UserAndGroups = styled.div`
  z-index: 100;
`;
const CustomGroupSelect = styled.span`
  width: 15rem;
  z-index: 500;
  margin-bottom: 1rem;
`;
const CustomSelect = styled.span`
  width: 12rem;
  z-index: 500;
  margin-bottom: 1rem;
`;
const IcoWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 0.313rem;
  margin-top: 0.25rem;
`;
const ErrorMessageWrapper = styled.div`
  color: #b62c10;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorMessage = styled.div<{ hasMessage: string | undefined }>`
  position: relative;
  color: #ff0000;
  cursor: default;
  font-size: 0.8rem;
  display: ${(props) => {
    if (props.hasMessage && props.hasMessage === 'error') {
      return 'inline';
    } else {
      return 'none';
    }
  }};
`;

const RowItem = styled.div`
  width: 230px;
  margin-bottom: 1rem;
`;
const DynamicInput = styled.input<DynamicInputProps>`
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;

  font-family: ${(props) => props.theme.typography.family || 'sans-serif'};
  font-size: ${(props) => props.theme.typography.components.input.size || '0.875rem'};
  line-height: ${(props) => props.theme.typography.components.input.lineHeight || '1.125rem'};
  font-weight: ${(props) => props.theme.typography.components.input.weight || '500'};
  color: ${(props) => {
    if (props.variant && props.variant === 'disabled') {
      return props.theme.colors.disabled.light.fill;
    } else if (props.variant && props.variant === 'white-dark') {
      return 'black';
    }
    return props.theme.colors.field.select.enabled || '#5D6A86';
  }};
  background-color: ${(props) =>
    props.variant
      ? props.variant === 'disabled'
        ? props.theme.colors.field.disabled.fill
        : props.variant === 'white' || props.variant === 'white-dark'
        ? props.theme.colors.field.white.fill
        : props.theme.colors.field.grey.fill || 'rgb(244, 247, 249)'
      : 'transparent'};

  cursor: ${(props) => (props.variant && props.variant === 'disabled' ? 'not-allowed' : 'auto')};

  appearance: none;

  box-sizing: border-box;
  border-radius: 0.375rem;
  border: ${(props) => {
    if (props.borderVariant && props.borderVariant === 'none') {
      return 'none';
    } else if (props.borderVariant && props.borderVariant === 'error') {
      return props.theme.colors.borders.error.border;
    }
    return props.theme.colors.borders.border02.border || '0.0625rem solid #D8DDe3';
  }};
  box-shadow: ${(props) =>
    (props.borderVariant && props.borderVariant === 'none'
      ? 'none'
      : props.theme.colors.borders.border02.shadow) || 'none'};

  ::placeholder {
    color: ${(props) => props.theme.colors.mediumGrey3};
  }

  :focus {
    outline: none;
    border: 1px solid ${themeColors.primaryBlue5};
  }

  :hover {
    outline: none;
    border-color: ${theme.colors.primaryBlue5} !important;
  }
`;
export const CustomUserHeader = styled.thead(({ theme }) => ({
  tr: {
    background: theme.colors.lightGrey1,
    th: {
      fontSize: '0.8125rem',
      fontWeight: 'bold',
      lineHeight: theme.typography.components.tableHeader.lineHeight,
      borderBottom: theme.colors.borders.border02.border,
      padding: '0',
      height: '7.5rem',
      ':first-child': {
        borderTopLeftRadius: '0.5rem'
      },
      ':last-child': {
        borderTopRightRadius: '0.5rem'
      }
    }
  }
}));

export const CustomGroupHeader = styled.thead(({ theme }) => ({
  tr: {
    background: theme.colors.lightGrey1,
    th: {
      fontSize: '0.8125rem',
      fontWeight: 'bold',
      lineHeight: theme.typography.components.tableHeader.lineHeight,
      borderBottom: theme.colors.borders.border02.border,
      padding: '0.75rem',
      height: '4.625rem',
      ':first-child': {
        borderTopLeftRadius: '0.5rem'
      },
      ':last-child': {
        borderTopRightRadius: '0.5rem'
      }
    }
  }
}));

export const groupsColumnConfigs = (
  onClickButton: (tableType: UserManagementTableType, id?: string) => void,
  t: TFunction<'fpns'[], undefined>
): ColumnConfig[] => {
  return [
    {
      header: t('mm_group_table_name') as string,
      id: 'name',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const data = cellValue.row.original;
        return (
          <TableDataLink>
            <Link to={`/user-management-v2/${data.id}`}>{cellValue.getValue()}</Link>
          </TableDataLink>
        );
      }
    },
    {
      header: t('mm_group_table_customer_location') as string,
      id: 'customer',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const row = cellValue.row.original as Group;
        const count = (row && row.customer) ?? 0;
        return <TableCell>{row && row.allOrganizations ? 'All' : count}</TableCell>;
      }
    },
    {
      header: t('mm_group_table_number_users') as string,
      id: 'numberOfUsers',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return (
          <TableCellWrapper>
            <TableCellSharedData>{cellValue.getValue()}</TableCellSharedData>
            <TableCellButton
              onClick={() => {
                onClickButton(UserManagementTableType.USER);
              }}
            >
              <TooltipWrapper
                Tooltip={'Add new user to this group'}
                textColor="#323130"
                backGroundColor="#ffffff"
              >
                <AddUserIcon />
              </TooltipWrapper>
            </TableCellButton>
          </TableCellWrapper>
        );
      }
    },
    {
      header: t('mm_group_table_access_group') as string,
      id: 'accessGroup',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return <TableCell>{cellValue.getValue() ? 'Internal' : 'External'}</TableCell>;
      }
    },
    {
      header: t('mm_group_table_edit_group') as string,
      id: 'editGroup',
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const group = cellValue.row.original as Group;
        return (
          <TableCellButton
            onClick={() => {
              onClickButton(UserManagementTableType.GROUP, group.id);
            }}
          >
            <TooltipWrapper
              Tooltip={'Edit this group'}
              textColor="#323130"
              backGroundColor="#ffffff"
            >
              <PencilIcon />
            </TooltipWrapper>
          </TableCellButton>
        );
      }
    }
  ];
};
const getColumnCheck = (
  handleClick?: (action?: string) => void,
  isDisable?: boolean,
  forceReset?: boolean,
  setResetControlCheck?: (val: boolean) => void
): ReactElement => {
  const [isChecked, setIsChecked] = useState(false);
  if (forceReset && isChecked) {
    setIsChecked(false);
    setResetControlCheck?.(false);
  }
  return (
    <p>
      <Checkbox
        width={20}
        height={20}
        label="Name"
        checked={isChecked}
        onChange={() => {
          setIsChecked(!isChecked);
          handleClick?.(isChecked ? 'deselectAll' : 'selectAll');
        }}
        margin="0"
        labelWeight={600}
        disabled={isDisable}
      />
    </p>
  );
};
export const usersValidationColumnConfigs = (
  handleSelect: (field: string, value: DropDownItem | null, id: string) => void,
  handleMultiSelect: (field: string, items: MultiValue<DropDownItem>, id: string) => void,
  validateOnBlur: (field: string, value: string, id: string) => void,
  t: TFunction<'fpns'[], undefined>,
  updateFieldOnChange: (field: string, value: string, id: string) => void,
  handleCheckClickOnBulkMode?: (action?: string, data?: UserTypes) => void,
  selectedTableItems?: UserTypes[]
): ColumnConfig[] => {
  return [
    {
      id: 'select',
      header: getColumnCheck(handleCheckClickOnBulkMode, true),
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const row = cellValue.row.original as UserTypes;
        return (
          <div>
            <Checkbox
              width={20}
              height={20}
              margin="0"
              label=""
              checked={!!selectedTableItems?.find((user) => user?.id === row.id)}
              onChange={() =>
                handleCheckClickOnBulkMode && handleCheckClickOnBulkMode('selectUser', row)
              }
            />
          </div>
        );
      }
    },
    {
      header: t('first_name', { ns: 'common' }) as string,
      id: 'firstName',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const row = cellValue.row.original as BulkUser;
        const firstName = (row && row.firstName) ?? '';
        const ifocus = ((row && row.firstNameFocus) as boolean) ?? false;
        const id = (row && row.id) ?? '';
        const errors = (row && row.errorMessages) ?? '';
        const columnError = getColumnError(errors, 'first_name');
        return (
          <RowItem>
            <DynamicInput
              variant="white"
              type="text"
              placeholder={t('first_name', { ns: 'common' }) as string}
              width="auto"
              value={firstName}
              onChange={(event: ChangeEvent) => {
                updateFieldOnChange('firstName', event.target.value as string, id);
                validateOnBlur('first_name', event.target.value as string, id);
              }}
              autoFocus={ifocus}
            />
            {columnError && (
              <ErrorMessage hasMessage={'error'}>
                <IcoWrapper>
                  <IcoExclamationCircleHollow />
                  <ErrorMessageWrapper>{columnError}</ErrorMessageWrapper>
                </IcoWrapper>
              </ErrorMessage>
            )}
          </RowItem>
        );
      }
    },
    {
      header: t('last_name', { ns: 'common' }) as string,
      id: 'lastName',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const row = cellValue.row.original as BulkUser;
        const lastName = (row && row.lastName) ?? '';
        const id = (row && row.id) ?? '';
        const errors = (row && row.errorMessages) ?? '';
        const ifocus = ((row && row.lastNameFocus) as boolean) ?? false;
        const columnError = getColumnError(errors, 'last_name');
        return (
          <RowItem>
            <DynamicInput
              variant="white"
              placeholder={t('last_name', { ns: 'common' }) as string}
              width="auto"
              value={lastName}
              onChange={(event: ChangeEvent) => {
                updateFieldOnChange('lastName', event.target.value as string, id);
                validateOnBlur('last_name', event.target.value as string, id);
              }}
              borderVariant={columnError ? 'error' : undefined}
              autoFocus={ifocus}
            />
            {columnError && (
              <ErrorMessage hasMessage={'error'}>
                <IcoWrapper>
                  <IcoExclamationCircleHollow />
                  <ErrorMessageWrapper>{columnError}</ErrorMessageWrapper>
                </IcoWrapper>
              </ErrorMessage>
            )}
          </RowItem>
        );
      }
    },
    {
      header: t('mm_user_table_email') as string,
      id: 'email',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const row = cellValue.row.original as BulkUser;
        const id = (row && row.id) ?? '';
        const errors = (row && row.errorMessages) ?? '';
        const ifocus = ((row && row.emailFocus) as boolean) ?? false;
        const columnError = getColumnError(errors, 'email');
        return (
          <RowItem>
            <DynamicInput
              variant="white"
              placeholder={t('mm_user_table_email') as string}
              width="auto"
              value={cellValue.getValue()}
              onChange={(event: ChangeEvent) => {
                updateFieldOnChange('email', event.target.value as string, id);
                validateOnBlur('email', event.target.value as string, id);
              }}
              borderVariant={columnError ? 'error' : undefined}
              autoFocus={ifocus}
            />
            {columnError && (
              <ErrorMessage hasMessage={'error'}>
                <IcoWrapper>
                  <IcoExclamationCircleHollow />
                  <ErrorMessageWrapper>{columnError}</ErrorMessageWrapper>
                </IcoWrapper>
              </ErrorMessage>
            )}
          </RowItem>
        );
      }
    },
    {
      header: t('mm_user_table_phone') as string,
      id: 'phone',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const row = cellValue.row.original as BulkUser;
        const id = (row && row.id) ?? '';
        const errors = (row && row.errorMessages) ?? '';
        const ifocus = ((row && row.phoneFocus) as boolean) ?? false;
        const columnError = getColumnError(errors, 'phone');
        return (
          <RowItem>
            <DynamicInput
              variant="white"
              placeholder={t('mm_user_table_phone') as string}
              width="auto"
              value={cellValue.getValue()}
              onChange={(event: ChangeEvent) => {
                updateFieldOnChange('phone', event.target.value as string, id);
                validateOnBlur('phone', event.target.value as string, id);
              }}
              borderVariant={columnError ? 'error' : undefined}
              autoFocus={ifocus}
            />
            {columnError && (
              <ErrorMessage hasMessage={'error'}>
                <IcoWrapper>
                  <IcoExclamationCircleHollow />
                  <ErrorMessageWrapper>{columnError}</ErrorMessageWrapper>
                </IcoWrapper>
              </ErrorMessage>
            )}
          </RowItem>
        );
      }
    },
    {
      header: t('mm_user_table_group') as string,
      id: 'groups',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const id = cellValue.row.original.id as string;
        const groupList = cellValue.row.original.groups as DropDownItem[];
        const selectedGroups = cellValue.row.original.selectedGroups as DropDownItem[];
        const columnError = 'All fields are required';
        return (
          <CustomGroupSelect>
            <Select
              isMulti
              placeholder="Select"
              value={selectedGroups}
              options={groupList}
              onChange={(items) => {
                handleMultiSelect('selectedGroups', items, id);
              }}
              components={{
                Option,
                DropdownIndicator
              }}
              styles={{
                clearIndicator: ClearValues,
                multiValue: (styles) => {
                  return {
                    ...styles,
                    backgroundColor: 'white',
                    borderRadius: '1.25rem',
                    border: '0.063rem solid  #0076CC',
                    padding: '0.25rem 0.5rem 0.25rem 0.5rem;'
                  };
                },
                multiValueLabel: (styles) => ({
                  ...styles,
                  fontWeight: '400'
                }),
                multiValueRemove: (styles) => ({
                  ...styles,
                  ':hover': {
                    cursor: 'pointer'
                  }
                }),
                indicatorSeparator: () => ({
                  display: 'none'
                })
              }}
              menuPosition="fixed"
            />
            <ErrorMessage
              hasMessage={
                !selectedGroups || (selectedGroups && selectedGroups.length === 0)
                  ? 'error'
                  : undefined
              }
            >
              <IcoWrapper>
                <IcoExclamationCircleHollow />
                <ErrorMessageWrapper>{columnError}</ErrorMessageWrapper>
              </IcoWrapper>
            </ErrorMessage>
          </CustomGroupSelect>
        );
      }
    },
    {
      header: t('mm_user_table_role') as string,
      id: 'role',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const roles = cellValue.row.original.role as DropDownItem[];
        const selectedRole = cellValue.row.original.selectedRole as DropDownItem;
        const id = cellValue.row.original.id as string;
        const columnError = 'All fields are required';
        return (
          <CustomSelect>
            <Select
              options={roles}
              value={selectedRole}
              placeholder="Select"
              hideSelectedOptions={false}
              onChange={(option) => {
                handleSelect('selectedRole', option, id);
              }}
              styles={{
                singleValue: (styles) => ({
                  ...styles,
                  border: '0.063rem solid  #0076CC',
                  width: 'fit-content',
                  borderRadius: '1.25rem',
                  padding: '0.25rem 0.5rem 0.25rem 0.5rem'
                }),
                indicatorSeparator: () => ({
                  display: 'none'
                })
              }}
              menuPosition="fixed"
            />
            <ErrorMessage hasMessage={!selectedRole ? 'error' : undefined}>
              <IcoWrapper>
                <IcoExclamationCircleHollow />
                <ErrorMessageWrapper>{columnError}</ErrorMessageWrapper>
              </IcoWrapper>
            </ErrorMessage>
          </CustomSelect>
        );
      }
    },
    {
      header: t('mm_user_table_alert') as string,
      id: 'textAlert',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const notify = cellValue.row.original.alertNotifications as DropDownItem[];
        const selectedNotifications = cellValue.row.original
          .selectedNotifications as DropDownItem[];
        const id = cellValue.row.original.id as string;
        const columnError = 'All fields are required';
        return (
          <CustomSelect>
            <Select
              isMulti
              placeholder="Select"
              value={selectedNotifications}
              options={notify}
              onChange={(items) => {
                handleMultiSelect('selectedNotifications', items, id);
              }}
              components={{
                Option,
                DropdownIndicator
              }}
              styles={{
                clearIndicator: ClearValues,
                multiValue: (styles) => {
                  return {
                    ...styles,
                    backgroundColor: 'white',
                    borderRadius: '1.25rem',
                    border: '0.063rem solid  #0076CC',
                    padding: '0.25rem 0.5rem 0.25rem 0.5rem;'
                  };
                },
                multiValueLabel: (styles) => ({
                  ...styles,
                  fontWeight: '400'
                }),
                multiValueRemove: (styles) => ({
                  ...styles,
                  ':hover': {
                    cursor: 'pointer'
                  }
                }),
                indicatorSeparator: () => ({
                  display: 'none'
                })
              }}
              menuPosition="fixed"
            />
            <ErrorMessage
              hasMessage={
                !selectedNotifications ||
                (selectedNotifications && selectedNotifications.length === 0)
                  ? 'error'
                  : undefined
              }
            >
              <IcoWrapper>
                <IcoExclamationCircleHollow />
                <ErrorMessageWrapper>{columnError}</ErrorMessageWrapper>
              </IcoWrapper>
            </ErrorMessage>
          </CustomSelect>
        );
      }
    }
  ];
};

export const usersColumnConfigs = (
  handleTableAction: ({ flyoutType, flyoutItemId, action, selectedUser }: TableAction) => void,
  t: TFunction<'fpns'[], undefined>,
  handleClick?: (action?: string, data?: UserTypes) => void,
  selectedTableItems?: UserTypes[],
  isGroupPage?: boolean,
  resetControlCheck?: boolean,
  setResetControlCheck?: (val: boolean) => void
): ColumnConfig[] => {
  return [
    {
      id: 'action',
      header: getColumnCheck(handleClick, false, resetControlCheck, setResetControlCheck),
      isEnableSorting: false,
      isSelected: true,
      isAlwaysVisible: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const row = cellValue.row.original as UserTypes;

        return (
          <div>
            <Checkbox
              width={20}
              height={20}
              margin="0"
              label=""
              checked={!!selectedTableItems?.find((user) => user?.id === row.id)}
              onChange={() => handleClick && handleClick('selectUser', row)}
            />
          </div>
        );
      }
    },
    {
      header: t('mm_user_table_name') as string,
      id: 'name',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const row = cellValue.row.original as UserTypes;
        const firstName = (row && row.firstName) ?? '';
        const lastName = (row && row.lastName) ?? '';
        const name = `${firstName} ${lastName}`;
        return (
          <TableCell display={'flex'} title={firstName || lastName ? name : '-'}>
            {firstName || lastName ? name : '-'}
          </TableCell>
        );
      }
    },
    {
      header: t('mm_user_table_email') as string,
      id: 'email',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return (
          <TableCell title={typeof cellValue.getValue() === 'string' ? cellValue.getValue() : ''}>
            {cellValue.getValue() ?? '-'}
          </TableCell>
        );
      }
    },
    {
      header: t('mm_user_table_group') as string,
      id: 'group',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const groups = cellValue.row.original.groups as UserGroup[];
        const groupList: ReactElement = (
          <UserAndGroups>
            {groups.map((group) => (
              <div>{group.name}</div>
            ))}
          </UserAndGroups>
        );

        return (
          <>
            {groups?.length > 0 && (
              <TooltipWrapper Tooltip={groupList} textColor="#323130" backGroundColor="#ffffff">
                <TableCellWrapper>
                  <TableCellButton>
                    <GroupAddIcon />
                  </TableCellButton>
                  <TableDataLink>
                    {groups?.length} {groups?.length > 1 ? 'Groups' : 'Group'}
                  </TableDataLink>
                </TableCellWrapper>
              </TooltipWrapper>
            )}
          </>
        );
      }
    },
    {
      header: t('mm_user_table_role') as string,
      id: 'role',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const data = cellValue.row.original as UserTypes;
        const groups = data.groups as UserGroup[];
        const is_admin = groups?.find((group) => group.isAdmin);
        const admin_all = groups.some((group) => !group.isAdmin);
        return (
          <TableCell className="roll_DropdownWrapper" overflow="initial">
            <UserRole
              id={data.id}
              data={groups}
              role={data.isAdmin ? 'admin' : 'member'}
              isGroupPage={isGroupPage}
              adminStatus={{ isAdmin: is_admin?.isAdmin as boolean, isAllAdmin: admin_all }}
            />
          </TableCell>
        );
      }
    },
    {
      header: t('mm_user_table_status') as string,
      id: 'status',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const data = cellValue.row.original as UserTypes;
        let isActive = data.isActive;
        const handleOptionSelect = (option?: DropDownItem | null) => {
          isActive = option?.value === 'active';
          handleClick && handleClick('changeStatus', { id: data.id, isActive: isActive });
        };
        return (
          <TableCellItemWrapper>
            <Select
              isSearchable={false}
              options={[
                { label: 'Active', value: 'active' },
                { label: 'Inactive', value: 'inactive' }
              ]}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={(option) => handleOptionSelect(option)}
              value={
                isActive
                  ? { label: 'Active', value: 'active' }
                  : { label: 'Inactive', value: 'inactive' }
              }
              components={{
                IndicatorSeparator: () => null
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: 'none',
                  boxShadow: 'none',
                  width: '8rem',
                  marginLeft: '-0.8rem'
                })
              }}
            />
          </TableCellItemWrapper>
        );
      }
    },
    {
      header: t('mm_user_table_phone') as string,
      id: 'phone',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return <TableCell>{cellValue.getValue() ?? '-'}</TableCell>;
      }
    },
    {
      header: t('mm_user_table_alert') as string,
      id: 'alertNotification',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const row = cellValue.row.original as User;
        const textAlert = row && row.textAlert ? 'Text' : '';
        const emailAlert = row && row.emailAlert ? 'Email' : '';
        return (
          <TableCell>
            {textAlert}
            {!isEmpty(textAlert) && !isEmpty(emailAlert) && ', '}
            {emailAlert}
            {isEmpty(textAlert) && isEmpty(emailAlert) && '-'}
          </TableCell>
        );
      }
    },
    {
      header: t('mm_user_table_edit_user') as string,
      id: 'editUser',
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const user = cellValue.row.original as UserTypes;
        return (
          <TooltipWrapper Tooltip={'Edit this user'} textColor="#323130" backGroundColor="#ffffff">
            <TableCellButton
              onClick={() => {
                handleTableAction({
                  flyoutType: UserManagementTableType.USER,
                  action: 'editUser',
                  flyoutItemId: user.id,
                  selectedUser: user
                });
              }}
            >
              <PencilIcon />
            </TableCellButton>
          </TooltipWrapper>
        );
      }
    }
  ];
};

export function getFilteredData(data: (UserTypes | User)[], searchKey: string) {
  switch (searchKey) {
    case 'admin':
      return data.filter((user) => user.isAdmin);
    case 'member':
      return data.filter((user) => !user.isAdmin);
    case 'active':
      return data.filter((user) => user.isActive);
    case 'inactive':
      return data.filter((user) => !user.isActive);
    default:
      return data;
  }
}

export const addKeyProp = (
  data: (Group | User | BulkUser | ValidatedUsersResponse | UserTypes)[],
  type: UserManagementTableType
): (Group | User | BulkUser | ValidatedUsersResponse)[] =>
  data.map((d, i) => {
    return { ...d, key: `${type}-${i}` };
  });
export const addIdProp = (
  data: (Group | User | BulkUser | ValidatedUsersResponse)[]
): (Group | User | BulkUser | ValidatedUsersResponse)[] =>
  data.map((d) => {
    const v = uuidv4();
    return { ...d, id: v };
  });
export const transformKeysToTableColumn = (
  groups: Group[],
  type: string
): {
  key: string;
  name: string;
  accessGroup: boolean;
  customer: number;
  numberOfUsers: number;
}[] => {
  const tg: {
    key: string;
    id: string;
    name: string;
    accessGroup: boolean;
    customer: number;
    numberOfUsers: number;
  }[] = [];

  groups.forEach((g, i) => {
    const tfm = {
      key: `${type}-${i}`,
      id: g.id,
      name: g.name,
      accessGroup: g.isInternal as boolean,
      customer: g.organizationsCount,
      numberOfUsers: g.usersCount
    };
    tg.push(tfm);
  });
  return tg;
};
export const updateValidatedUser = (
  users: ValidatedUsersResponse[] | undefined,
  field: string,
  value: string,
  id: string
): ValidatedUsersResponse[] | undefined => {
  if (!users) return [];
  for (const user of users) {
    //turn off existing focus
    if (user['firstNameFocus']) user['firstNameFocus'] = false;
    if (user['lastNameFocus']) user['lastNameFocus'] = false;
    if (user['emailFocus']) user['emailFocus'] = false;
    if (user['phoneFocus']) user['phoneFocus'] = false;
  }
  for (const user of users) {
    //turn on focus on current item
    if (id === user.id) {
      user[field] = value;
      user[field + 'Focus'] = true;
      break;
    }
  }
  return users;
};
export const updateMultiSelect = (
  users: ValidatedUsersResponse[] | undefined,
  field: string,
  options: MultiValue<DropDownItem>,
  id: string
): ValidatedUsersResponse[] | undefined => {
  if (!users) return [];
  for (const user of users) {
    if (id === user.id) {
      user[field] = options;
      break;
    }
  }
  return users;
};
export const updateSelect = (
  users: ValidatedUsersResponse[] | undefined,
  field: string,
  option: DropDownItem,
  id: string
): ValidatedUsersResponse[] | undefined => {
  if (!users) return [];
  for (const user of users) {
    if (id === user.id) {
      user[field] = option;
      break;
    }
  }
  return users;
};

const getColumnError = (
  errors: { columnName: string; errors: string[] }[],
  col: string
): string | undefined => {
  if (errors.length === 0 || errors[0].errors.length === 0) return undefined;
  const error = errors.find((err) => err.columnName === col);
  return error?.errors[0] as string;
};
export const transformUserColumnOptions = (users: UserTypes[], type: string) => {
  return users?.map((user, i) => {
    const tfm = {
      ...user,
      key: `${type}-${i}`,
      id: user.id,
      name: `${user.firstName || ''} ${user.lastName || ''}`,
      email: user.email,
      group: user.groups,
      role: user.isAdmin,
      status: user.isActive,
      phone: user.phone,
      alertNotification: {
        emailAlert: user.emailAlert,
        textAlert: user.textAlert
      }
    };
    return tfm;
  });
};
