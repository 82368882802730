import React from 'react';

import { MachineStateIconContainer } from '../MachineStatesIcon.elements';
import { useGetAsepticMachineHealthKpiQuery } from 'api';
import { useBuSettings } from 'common/pages/fleetV2/providers';
import { AsepticMachineHealthInterval, WidgetType } from 'types/machine-health';
import { useFleetRouter } from 'common/pages/fleetV2/hooks/useFleetRouter';

const useMachineState = () => {
  const { colors, Icons, mapKeys } = useBuSettings();
  const [{ id: machineId }] = useFleetRouter();

  const { currentData, ...rest } = useGetAsepticMachineHealthKpiQuery({
    machineId: machineId as string,
    kpiDataInterval: AsepticMachineHealthInterval.Last24Hours,
    widgetType: WidgetType.MACHINE_INFO,
    limit: 100000,
    includeHistoricData: false
  });

  let state = (currentData?.find((x) => x.id === 'machinestate')?.value?.value ||
    'unknown') as string;

  state = state.toLowerCase();

  const label = mapKeys?.[state] || state;

  return {
    ...rest,
    state,
    color: colors?.[label],
    Icon: Icons?.[label] || undefined
  };
};

export const AsepticMachineStateIcon = (): JSX.Element => {
  const { color, state, Icon } = useMachineState();

  return (
    <div className="machine-info-bar__item machine-state">
      Machine State:
      <MachineStateIconContainer className="machine-state-icon" {...{ color }}>
        <span className="label">{state}</span> {Icon}
      </MachineStateIconContainer>
    </div>
  );
};
