import React from 'react';
import { IcoClockWithPause, IcoClockWithShield, IcoClockWithSquare } from 'icons/IcoClock';
import { IcoWrench } from 'icons/IcoWrench';
import { IcoCleaning } from 'icons/IcoCleaning';
import Idle from '../../../../../icons/Idle.svg';
import WaitTime from '../../../../../icons/Wait_time.svg';
import Production from '../../../../../icons/Production.svg';
import NoProduct from '../../../../../icons/no-product.svg';

export const Icons: Record<string, JSX.Element | string> = {
  idle: <IcoClockWithPause />,
  maintenance: <IcoWrench />,
  cleaning: <IcoCleaning />,
  sterilzation: <IcoClockWithShield />,
  'wait time': <IcoClockWithSquare />,
  running: Production,
  'no product': NoProduct,
  'not running': Idle,
  offline: WaitTime
};
