import React from 'react';

export const CheckmarkLong = (): JSX.Element => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33301 8.6665L5.99967 11.3332L12.6663 4.6665"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
