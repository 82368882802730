import React from 'react';

export interface Props {
  color?: string;
}

export const RightUpDiagArrowIcon = ({ color }: Props): JSX.Element => {
  color = color || '#0076CC';

  return (
    <svg
      className="icon icon-right-up-diag-arrow-icon"
      width="13"
      height="14"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1130_91674)">
        <path
          d="M7.55577 3.77734L3.11133 8.22179"
          stroke={color}
          strokeWidth="0.666667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5553 3.77734H7.5553V7.77734"
          stroke="#0076CC"
          strokeWidth="0.666667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1130_91674">
          <rect width="12.6667" height="12.6667" fill="white" transform="translate(0 0.666016)" />
        </clipPath>
      </defs>
    </svg>
  );
};
