import React, { createContext, useContext, useState, ReactNode } from 'react';
import { sub, startOfDay } from 'date-fns';
import {
  convertToZonedTimestamp,
  getCurrentZonedTime,
  generateZonedTimeframe
} from 'common/helpers/dateAndTimeHelpersV2';
import { useMachineInfo } from 'common/pages/fleetV2/providers';

export interface UseWidgetDateRangeCurrentRangeProps {
  [key: string]: {
    subtractDaysCount?: number;
    subtractWeeksCount?: number;
  };
}

export interface UseWidgetDateRangeReturnProps {
  dateRangeLabel?: string;
  startTime: string;
  endTime: string;
  setDateRange: (props: {
    startTime?: string;
    endTime?: string;
    label?: string;
    subtractMinutes?: number;
    subtractHours?: number;
    subtractDays?: number;
    subtractWeeks?: number;
    subtactMonths?: number;
  }) => void;
  timeZone: string;
  label?: string;
  utcDateRange: {
    startTime: string;
    endTime: string;
  };
  refreshInterval?: number;
  isInit: boolean;
}

/** this is to load defaults based on local settings, before timeZone is received  */
const defEnd = getCurrentZonedTime().date;
const defStart = startOfDay(defEnd);
sub(getCurrentZonedTime().date, {
  days: 6
});

export const WidgetDateRangeContext = createContext<UseWidgetDateRangeReturnProps>({
  startTime: defStart.toISOString(),
  endTime: defEnd.toISOString(),
  setDateRange: (props: { startTime?: string; endTime?: string }) =>
    console.log(
      'date range not set, make sure this component is wrapped in WidgetDateRangeProvider',
      { props }
    ),
  timeZone: 'UTC',
  utcDateRange: {
    startTime: convertToZonedTimestamp(defStart.toISOString(), 'UTC') as string,
    endTime: convertToZonedTimestamp(defEnd.toISOString(), 'UTC') as string
  },
  isInit: false
});

export const useWidgetDateRange = (): UseWidgetDateRangeReturnProps =>
  useContext<UseWidgetDateRangeReturnProps>(WidgetDateRangeContext);

export interface WidgetDateRangeProviderProps {
  subtractDays?: number;
  subtractWeeks?: number;
  subtractMonths?: number;
  subtractSeconds?: number;
  subtractMinutes?: number;
  subtractHours?: number;
  timeZone?: string;
  defaultLabel?: string;
  // there is no timer in this, this will only pass the value down to children
  refreshInterval?: number;
  debugLabel?: string;
  startOf?: 'day' | 'week' | 'month' | 'year';
}

interface Props extends WidgetDateRangeProviderProps {
  children?: ReactNode;
}

export const WidgetDateRangeProvider = ({
  children,
  subtractDays,
  //subtractWeeks,
  subtractHours,
  subtractSeconds,
  subtractMinutes,
  timeZone,
  defaultLabel,
  debugLabel,
  startOf
}: Props): JSX.Element => {
  // set default timeZone to UTC
  timeZone = timeZone || useMachineInfo()?.timeZone || 'UTC';

  const newDate = generateZonedTimeframe(
    timeZone,
    {
      subtractDays,
      subtractHours,
      subtractMinutes,
      subtractSeconds,
      startOf
    },
    true
  );

  const [utcDateRange, setUtcDateRange] = useState<{
    startTime: string;
    endTime: string;
    label?: string;
  }>({
    ...newDate,
    label: defaultLabel
  });

  // this will convert the timestamp to the machines time zone
  const dateRange = {
    startTime: convertToZonedTimestamp(utcDateRange.startTime, timeZone),
    endTime: convertToZonedTimestamp(utcDateRange.endTime, timeZone)
  };

  if (debugLabel) {
    console.log(debugLabel, {
      utcDateRange,
      subtractDays,
      subtractHours,
      subtractMinutes,
      subtractSeconds
    });
  }

  // click handler for the date range
  const setDateRange = ({
    startTime,
    endTime,
    subtractDays,
    subtractHours,
    subtractMinutes,
    subtractSeconds,
    label,
    startOf
  }: {
    startTime?: string;
    endTime?: string;
    subtractDays?: number;
    subtractMonths?: number;
    subtractWeeks?: number;
    subtractHours?: number;
    subtractMinutes?: number;
    subtractSeconds?: number;
    label?: string;
    startOf?: 'day' | 'week' | 'month' | 'year';
  }) => {
    const newRange = {
      ...utcDateRange,
      ...generateZonedTimeframe(timeZone, {
        subtractDays,
        subtractHours,
        subtractMinutes,
        subtractSeconds,
        startOf
      })
    };

    if (endTime) newRange.endTime = convertToZonedTimestamp(endTime, 'UTC') as string;
    if (startTime) newRange.startTime = convertToZonedTimestamp(startTime, 'UTC') as string;
    if (label) newRange.label = label;

    return setUtcDateRange(newRange);
  };

  return (
    <WidgetDateRangeContext.Provider
      value={{
        setDateRange,
        utcDateRange,
        // thanks TS
        timeZone: timeZone as string,
        startTime: dateRange.startTime as string,
        endTime: dateRange.endTime as string,
        label: utcDateRange?.label as string,
        isInit: true
      }}
    >
      {children}
    </WidgetDateRangeContext.Provider>
  );
};
