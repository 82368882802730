import React from 'react';
import { IcoClockWithPause, IcoClockWithShield, IcoClockWithSquare } from 'icons/IcoClock';
import { IcoWrench } from 'icons/IcoWrench';
import { IcoCleaning } from 'icons/IcoCleaning';
import { IcoCheckWithCircle } from 'icons/IcoCheck';
import { colors } from './colors';

export const Icons: Record<string, JSX.Element> = {
  idle: <IcoClockWithPause />,
  maintenance: <IcoWrench />,
  cleaning: <IcoCleaning />,
  sterilization: <IcoClockWithShield />,
  'wait time': <IcoClockWithSquare />,
  production: <IcoCheckWithCircle size={1.5} color={colors.production} />
};
