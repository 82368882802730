import React from 'react';
import { Typography } from 'components';
import Asterisk from 'pages/AlertsPage/Step3/Asterisk ';

interface Props {
  children?: React.ReactNode;
  id?: string;
  mandatory?: boolean;
  color?: string;
}

const InputLabel = ({ children, id, mandatory, color }: Props): JSX.Element => (
  <Typography color={color ? color : 'darkGrey'} htmlFor={id} variant="inputlabel">
    {children} {mandatory && <Asterisk />}
  </Typography>
);

export default InputLabel;
