import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoBilling = ({
  width,
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5700_196009)">
        <path
          d="M9.31159 7.51947H14.6882M9.31159 11.1039H14.6882M12.896 14.6883H14.6882M5.72717 20.0649V5.72727C5.72717 5.25194 5.91599 4.79609 6.2521 4.45998C6.5882 4.12388 7.04406 3.93506 7.51938 3.93506H16.4804C16.9557 3.93506 17.4116 4.12388 17.7477 4.45998C18.0838 4.79609 18.2726 5.25194 18.2726 5.72727V20.0649L15.5843 18.2727L13.7921 20.0649L11.9999 18.2727L10.2077 20.0649L8.41548 18.2727L5.72717 20.0649Z"
          stroke={color || '#828485'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5700_196009">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        ̥
      </defs>
    </Svg>
  );
};
