import NewBaseTable from 'components/NewBaseTable/NewBaseTable';
import { TFunction } from 'i18next';
import { DropDownItem } from 'pages/ProteinMachine/MachineConfig/Common/Alerts/FormElementsTypes';
import React from 'react';
import { BulkUser, UserTypes } from 'types/user-management';
import { usersValidationColumnConfigs } from '../utils';
import { MultiValue } from 'react-select';
import { RibbonItem } from 'components/RibbonComponent';
interface props {
  isLoading: boolean;
  tableValidationData: BulkUser[];
  handleSelect: (field: string, value: DropDownItem | null, id: string) => void;
  handleMultiSelect: (field: string, items: MultiValue<DropDownItem>, id: string) => void;
  validateOnBlur: (field: string, value: string, id: string) => void;
  t: TFunction<'fpns'[], undefined>;
  updateFieldOnChange: (field: string, value: string, id: string) => void;
  handleCheckClickOnBulkMode: (action?: string, data?: UserTypes) => void;
  selectedTableItems?: UserTypes[];
  defaultUserSortState: { id: string; desc: boolean };
  tableHeaderButtons?: RibbonItem[];
}
export const DisplayImportedUsers = ({
  isLoading,
  tableValidationData,
  handleSelect,
  handleMultiSelect,
  validateOnBlur,
  t,
  updateFieldOnChange,
  handleCheckClickOnBulkMode,
  selectedTableItems,
  defaultUserSortState,
  tableHeaderButtons
}: props): JSX.Element => {
  return (
    <>
      <NewBaseTable
        newTableData={tableValidationData || []}
        columnConfigs={usersValidationColumnConfigs(
          handleSelect,
          handleMultiSelect,
          validateOnBlur,
          t,
          updateFieldOnChange,
          handleCheckClickOnBulkMode,
          selectedTableItems
        )}
        tdMarginLeft="0.625rem"
        textAlign="left"
        sortState={defaultUserSortState}
        isDataLoading={isLoading}
        isShowGlobalSearch={false}
        showTableCoverRule={true}
        customClassName="userTable"
        disableVirtualize={true}
        tableHeaderButtons={tableHeaderButtons}
        shadow={true}
      />
    </>
  );
};
