import React, { useRef, useEffect, useState } from 'react';
import { ChartLegendProps } from '../ChartLegends.types';
import { InlineChartLegend } from '../InlineLegend/InlineChartLegend';
import { ResponsiveInlineLegendContainer } from './ResponsiveInlineLegend.elements';
import { DimensionsContainer } from 'components';
import { IcoChevron } from 'icons/IcoChevron';

export const ResponsiveInlineChartLegend = ({
  className,
  ...props
}: ChartLegendProps): JSX.Element => (
  <DimensionsContainer
    className={className}
    Component={(dims) => <Provided {...props} {...dims} />}
  />
);

export const Provided = ({ className, items, width, ...props }: ChartLegendProps): JSX.Element => {
  const wrapperRef: React.MutableRefObject<null> = useRef(null);
  let restOfItems: Record<string, unknown>[] | undefined = undefined;

  className = className
    ? `${className} chart-legend-overflow-wrapper`
    : 'chart-legend-overflow-wrapper';

  const [isOverflowing, setIsOverflowing] = useState(false);

  if (isOverflowing && items) {
    className += ' is-overflowing';
    restOfItems = items.slice(4);
    items = items.slice(0, 4);
  }

  useEffect(() => {
    if (wrapperRef) {
      const legendRef = wrapperRef?.current;
      const legendEl = legendRef && legendRef.querySelector('.chart-legend');

      if (legendEl && width && Number(width) > 0) {
        const legendWidth = legendEl ? legendEl?.offsetWidth : 0;

        if (legendWidth > width) setIsOverflowing(true);
      }
    }
  }, [wrapperRef, width]);

  return (
    <ResponsiveInlineLegendContainer className={className}>
      <div className="legend-wrapper" ref={wrapperRef}>
        <InlineChartLegend {...props} {...{ items }} />
      </div>
      {isOverflowing && restOfItems && (
        <div className="dropdown-container">
          <div className="dropdown-button">
            ... <IcoChevron />{' '}
          </div>
          <InlineChartLegend {...props} {...{ items: restOfItems }} />
        </div>
      )}
    </ResponsiveInlineLegendContainer>
  );
};
