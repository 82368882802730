import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetDocumentCategoryQuery,
  useGetMachineDocumentQuery,
  useLazyGetMachineDocFileQuery
} from 'api';
import DropdownOption, { OptionType } from 'common/components/DropdownOption';
import Typography from 'components/Typography/Typography';
import { IcoDownload } from 'icons/IcoDownload';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import theme from 'themes';
const MachineDoc = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0 0.5rem;
`;
type Props = { machineId?: string; categoryName?: string };
const DocumentFileDropdown = ({ machineId, categoryName }: Props): JSX.Element | null => {
  const [getDocumentDownloadLink] = useLazyGetMachineDocFileQuery();
  const { data: documentCategory } = useGetDocumentCategoryQuery();
  const category = documentCategory?.find((category) => {
    return category.name === categoryName;
  });

  const { data: machineDocuments } = useGetMachineDocumentQuery(
    category?.id
      ? {
          machine_id: machineId,
          category_id: category.id
        }
      : skipToken
  );

  const fileList = useMemo(() => {
    const publishedDoc: OptionType[] = [];
    machineDocuments?.forEach((file) => {
      if (file.isPublished) {
        publishedDoc.push({
          label: file.userFileName,
          value: file.id,
          icon: <IcoDownload width={'20'} />
        });
      }
    });
    return publishedDoc;
  }, [machineDocuments]);

  const handleFileClick = async (file?: OptionType) => {
    try {
      const response = await getDocumentDownloadLink({
        document_id: file?.value || ''
      });
      const signedUrl = response.data?.signedUrl || '';

      const resp = await fetch(signedUrl);
      const blob = await resp.blob();
      const blobUrl = URL.createObjectURL(blob);

      const anchorEl = document.createElement('a');
      anchorEl.href = blobUrl;
      anchorEl.target = 'blank';
      anchorEl.download = file?.label || 'machine-doc-file';
      document.body.appendChild(anchorEl);

      anchorEl.click();

      document.body.removeChild(anchorEl);
      URL.revokeObjectURL(blobUrl);
    } catch (err) {
      console.log(err);
    }
  };
  return fileList.length ? (
    <MachineDoc>
      <IcoDownload />
      <DropdownOption
        options={fileList}
        handleOptionSelect={handleFileClick}
        label={
          <Typography mb={0} weight="semi-bold" color={theme.colors.primaryBlue5}>
            Machine Document
          </Typography>
        }
        iconColor={theme.colors.primaryBlue5}
      />
    </MachineDoc>
  ) : null;
};

export default DocumentFileDropdown;
