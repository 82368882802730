import React, { useMemo } from 'react';
import {
  StateHistoryButtonsContainer,
  StateHistoryButtonContainerProps,
  stateHistoryButtonsBaseClass as baseClass
} from './StateHistoryButtons.elements';
import { useTranslation } from 'react-i18next';
import RibbonComponent from 'components/RibbonComponent';
import { IconUndo } from 'icons/IconUndo';
import { X } from 'icons/indexed-icons/X';
import theme from 'themes';

export interface StateHistoryButtonsPropsSetting {
  disabled?: boolean;
  handleClick: () => void;
  label?: string;
  translateLabel?: string;
}

export interface StateHistoryButtonsProps extends StateHistoryButtonContainerProps {
  undo?: StateHistoryButtonsPropsSetting;
  redo?: StateHistoryButtonsPropsSetting;
  clear?: StateHistoryButtonsPropsSetting;
}

export const StateHistoryButtons = ({
  undo,
  redo,
  clear,
  gridArea,
  className
}: StateHistoryButtonsProps): JSX.Element => {
  className = className ? `${baseClass} ${className}` : baseClass;
  const { t } = useTranslation(['mh']);

  const ribbonItems = useMemo(() => {
    return [
      {
        label: `${t('undo')}`,
        icon: IconUndo,
        disable: undo?.disabled || false,
        onClick: () => undo?.handleClick()
      },
      {
        label: `${t('clear')}`,
        icon: () => (
          <X width={12} height={12} color={clear?.disabled ? theme.colors.lightGrey9 : ''} />
        ),
        disable: clear?.disabled || false,
        onClick: () => clear?.handleClick()
      }
    ];
  }, [undo, redo, clear]);

  return (
    <StateHistoryButtonsContainer {...{ className, gridArea }}>
      <RibbonComponent ribbonItems={ribbonItems} gap="1rem" />
    </StateHistoryButtonsContainer>
  );
};
