import styled from 'styled-components';
import theme from 'themes';

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  gap: 0.938rem;
`;

export const PopupContainer = styled.div<{ top?: string; headerBgColor?: string }>`
  position: absolute;
  top: ${({ top }) => top || '100%'};
  width: max-content;
  z-index: 99;
  right: 0;
  background-color: white;
  border: 0.063rem solid ${theme.colors.lightGrey1};
  border-radius: 0.313rem;
  overflow: hidden;
  list-style: none;
  box-shadow: 0 0.5rem 0.813rem rgba(0, 0, 0, 0.1);

  .rc-collapse-item {
    margin-bottom: 0;
  }

  .rc-collapse-content {
    background: ${theme.colors.lightGrey1};
  }

  .rc-collapse {
    border-radius: 0.313rem, 0.313rem, 0, 0;
    width: 16.5rem;
  }

  .rc-collapse-header {
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem;
  }

  .rc-collapse-item-active {
    background: ${theme.colors.lightGrey10};
  }

  .rc-collapse-header-text {
    flex: 1;
  }

  .sc-wkolL {
    margin: 0;
  }
`;

export const PopUpHeader = styled.div`
  color: black;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const PopUpContent = styled.label`
  display: flex;
  gap: 0.5rem;
  padding: 0.531rem;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

export const PopUpFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem;
  gap: 1rem;
  cursor: default;

  p {
    font-family: Roboto;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
    margin: 0;
    cursor: pointer;
  }
`;

export const PopUpSavedFiltersContainer = styled.div`
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  gap: 0.75rem;
`;

export const PopUpSavedFilters = styled.div`
  display: flex;
  color: black;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const SavedFilterText = styled.p`
  color: ${theme.colors.lightGrey8};
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.026rem;
  text-align: left;
  margin: 0;
`;

export const PopUpInputText = styled.div`
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.05rem;
  text-align: left;
`;

export const StyledSearch = styled.div`
  padding: 0.6rem 0;
  display: flex;
  flex-direction: column;
  input {
    margin: 0.5rem 0.5rem 0.125rem;
  }
`;

export const SearchBarWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  cursor: auto;
`;

export const CustomIconBtn = styled.button<{ isActive?: boolean }>`
  border: ${(props) => (props.isActive ? `0.063rem solid ${theme.colors.primaryBlue5}` : 'none')};
  background: ${(props) => (props.isActive ? `${theme.colors.lightGrey10}` : 'none')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.3rem 0.3rem;
  &:hover {
    background: ${theme.colors.lightGrey10};
  }
`;
