// 3rd party libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import AdminCardEditButton from '../configurator/AdminCardEditButton';
import { DashboardWidget, Loader, PermissionWrapper } from 'components';

// API
import {
  useGetMachineAssetsQuery,
  useGetMachineConfiguratorDataQuery,
  useGetMachineTagsHistoryQuery
} from 'api';

// Hooks & Providers
import { useContainerSize } from 'hooks';
import { useLanguage } from 'providers';

// Utils
import { useMachineKpi } from './utils';
import { NewRowRenderer, generateTooltip } from '../RowRenderer';

//Types
import { ResourceType, Role, UserScopes } from 'types';
import { MachineHealthSubTabs } from 'types/protein';
import { PermissionScopeName } from 'types/user-management';
import { FrameIcon } from 'icons/FrameIcon';
import { ActiveRecipeIcon } from 'icons/ActiveRecipeIcon';
import { SettingIcon } from 'icons/SettingIcon';
import {
  ActiveRecipeContainer,
  Container,
  ImageSubContainer,
  NewKpiContainer,
  SubContainer,
  TagsContainer
} from './index.elements';
import styled from 'styled-components';

// Images placeholders for testing purpose
const images = {
  '8a537e04-0139-4740-9a69-dadbd95d5984': '/assets/placeholder/machines/gcm_10.gif',
  'f026d81a-1589-4755-8bfc-1197b7a786d7': '/assets/placeholder/machines/gc_70.gif',
  '85a68065-ce6f-4467-835c-2062b9bc0b84': '/assets/placeholder/machines/gc_70.gif',
  'c2c5d503-3742-4a4b-99fb-7cc17d05d6eb': '/assets/placeholder/machines/gc_40.gif'
} as Record<string, string>;

type Props = {
  onClick: () => void;
  startTime: string;
  endTime: string;
};

// Styling
export const KpiContainer = styled.div`
  height: 20.1875rem;
  overflow-y: auto;
  & > div {
    padding: 0rem 1rem;
  }
`;

const MachineKpi = ({ onClick, startTime, endTime }: Props): JSX.Element => {
  const { height, containerRef: kpisContainerRef, triggerResize } = useContainerSize();
  const { main, isLoading, error, machineId } = useMachineKpi(5000);
  const { t } = useTranslation(['common']);
  const { languageId } = useLanguage();

  const { data } = useGetMachineConfiguratorDataQuery({
    machineId,
    labels: [MachineHealthSubTabs.OverviewMain],
    languageId: languageId
  });

  const { data: activeRecipeData } = useGetMachineTagsHistoryQuery({
    machineId,
    startDatetime: startTime,
    endDatetime: endTime,
    tagCodes: ['ActReciepeString'],
    is_machine_state: true
  });

  const {
    data: machineAssets,
    isLoading: machineAssetsIsLoading
    //error: machineAssetsError
  } = useGetMachineAssetsQuery({
    machineId: machineId as string,
    assetType: ResourceType.MachineImage
  });

  // Re-evaluate the space available when loading is complete, as KpiContainer will return
  // a height of 0 until that moment
  useEffect(() => {
    triggerResize();
  }, [isLoading]);

  const [isEditAdminPopupOpen, setIsEditAdminPopupOpen] = useState<boolean>(false);
  const handleIsEditAdminPopupOpen = () => {
    setIsEditAdminPopupOpen(!isEditAdminPopupOpen);
  };

  const defaultImage = `/assets/placeholder/machines/dsi.jpg`;

  let imageToUse = defaultImage;

  if (machineAssets && machineAssets.length > 0) {
    imageToUse = machineAssets[machineAssets.length - 1]?.url || images?.[machineId] || imageToUse;
  }

  const Image = <img src={imageToUse} alt={machineId} />;

  const widgetSettings = {
    isLoading,
    hasCustomMenu: true,
    hasError: error && '',
    title: t('machine_overview', { ns: 'common' }) as string,
    customDropdownItems: [
      {
        title: 'See More',
        onClick: () => onClick(),
        icon: <FrameIcon />
      },
      {
        title: 'Configure',
        onClick: () => setIsEditAdminPopupOpen(true),
        icon: <SettingIcon />
      }
    ]
  };

  return (
    <DashboardWidget {...widgetSettings}>
      <Container>
        <ImageSubContainer>
          {machineAssetsIsLoading ? <Loader size={50} /> : Image}
        </ImageSubContainer>
        <div style={{ paddingRight: '0.4rem', width: '53%' }}>
          <ActiveRecipeContainer>
            <ActiveRecipeIcon />
          </ActiveRecipeContainer>
          <SubContainer>
            <TagsContainer>
              <div style={{ display: 'flex' }}>
                <p style={{ fontSize: '0.875rem', fontWeight: '600' }}>{t('Active Recipe')}</p>
              </div>
              <p>
                {generateTooltip(
                  activeRecipeData &&
                    activeRecipeData[activeRecipeData.length - 1]?.values.slice(-1)[0]
                      ?.machineState === 'production'
                    ? (activeRecipeData[activeRecipeData.length - 1]?.values.slice(-1)[0]
                        ?.value as string)
                    : (t('not_available', { ns: 'common' }) as string)
                )}
              </p>
            </TagsContainer>
            <NewKpiContainer ref={kpisContainerRef}>
              <NewRowRenderer rows={main} containerHeight={height} />
            </NewKpiContainer>
          </SubContainer>
        </div>

        {data?.[0]?.id && (
          <PermissionWrapper
            page={PermissionScopeName.FLEET}
            scope={UserScopes.Write}
            role={Role.Admin}
          >
            <AdminCardEditButton
              addImageRow
              hideInactive
              machineId={machineId}
              widgetId={data[0].id}
              isEditAdminPopupOpen={isEditAdminPopupOpen}
              setIsEditAdminPopupOpen={handleIsEditAdminPopupOpen}
            />
          </PermissionWrapper>
        )}
      </Container>
    </DashboardWidget>
  );
};

export default MachineKpi;
