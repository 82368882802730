import React from 'react';
import type { FC, SVGProps } from 'react';

const IcoWarningOutline: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_9621_404)">
      <path
        d="M8 6V9.33333"
        stroke={props.color ? props.color : '#BA4E00'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.90874 2.39404L1.50474 11.4167C1.39334 11.6096 1.33438 11.8284 1.33375 12.0511C1.33311 12.2739 1.39081 12.493 1.5011 12.6865C1.6114 12.8801 1.77045 13.0414 1.96243 13.1544C2.15442 13.2674 2.37264 13.3282 2.5954 13.3307H13.4047C13.6274 13.3281 13.8455 13.2673 14.0374 13.1544C14.2293 13.0414 14.3883 12.8802 14.4986 12.6867C14.6088 12.4932 14.6666 12.2743 14.666 12.0516C14.6655 11.8289 14.6066 11.6103 14.4954 11.4174L9.0914 2.39338C8.97771 2.20571 8.81754 2.05054 8.62637 1.94284C8.4352 1.83515 8.21949 1.77856 8.00007 1.77856C7.78065 1.77856 7.56494 1.83515 7.37377 1.94284C7.1826 2.05054 7.02243 2.20571 6.90874 2.39338V2.39404Z"
        stroke={props.color ? props.color : '#BA4E00'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11H8.00667"
        stroke={props.color ? props.color : '#BA4E00'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9621_404">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IcoWarningOutline;
