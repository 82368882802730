import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Dropdown } from 'pages/AlertsPage/AlertsTab/Dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themeColors } from 'themes';
import { Tab } from 'types/maintenance';

interface TabProps {
  tabs?: Tab[];
  selectedTab?: Tab;
  onTabClick: (subTab: Tab, mainTab: Tab) => void;
}
const NavSubMenu = styled.div`
  flex-direction: column;
  list-style: none;
  position: absolute;
  top: 1rem;
  left: -1rem;
  right: 0;
  background: #ffffff;
  padding: 0.5rem 0;
  border-radius: 0.313rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.438rem 0.563rem 0rem;
  min-width: 8rem;
  width: fit-content;
`;

const MainTab = styled.div<{ isSelected?: boolean }>`
  color: ${(props) => (props.isSelected ? themeColors.primaryBlue5 : themeColors.lightGrey8)};
  border: 0;
  font-size: 0.885rem;
  font-weight: ${(props) => (props.isSelected ? '600' : '400')};
  padding: 0 0rem 0.5rem;
  cursor: pointer;
  position: relative;
  border-bottom: ${(props) =>
    props.isSelected ? `4px solid ${props.theme.colors.primaryBlue5}` : ''};
  font-family: Roboto;
  font-style: normal;
`;
const SubText = styled.div<{ padding?: string }>`
  margin: 0.3rem auto 0;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  padding: ${({ padding }) => padding};
`;
const SubTab = styled.li<{ isSelected?: boolean }>`
  color: ${(props) => (props.isSelected ? themeColors.primaryBlue5 : themeColors.lightGrey8)};
  padding: 0.5rem 1.2rem 0.3rem 1rem;
  cursor: pointer;
  font-weight: ${(props) => (props.isSelected ? 500 : 400)};
  font-size: 0.9rem;
  min-width: fit-content;
  white-space: nowrap;
`;
const TabContainer = styled.div`
  display: flex;
  gap: 4rem;
  user-select: none;
  box-shadow: 0px 1px 1px 0px ${(props) => props.theme.colors.lightBlack};
`;
const DropdownTab = ({ tabs = [], onTabClick, selectedTab }: TabProps): JSX.Element => {
  const activeMainTab = tabs.find(
    (tab) => tab.subTabs?.some((subTab) => subTab.selected) || tab.selected
  );
  const activeSubTab = activeMainTab?.subTabs?.find((subTab) => subTab.selected);

  const [currentTab, setCurrentTab] = useState<Tab>();
  const [activeTab, setActiveTab] = useState<{ subTab?: Tab; mainTab?: Tab }>({
    mainTab: activeMainTab || tabs[0],
    subTab: activeSubTab
  });
  const [showSubTab, setShowSubTab] = useState<boolean>();
  const { t } = useTranslation(['fpns']);

  const { ref } = useComponentVisible(setShowSubTab);

  const handleTabClick = (tab: Tab) => {
    setCurrentTab(tab);
    setShowSubTab(!showSubTab);
    if (!tab?.subTabs?.length) {
      onTabClick(tab, tab);
      setActiveTab({ ...activeTab, mainTab: tab });
    }
  };

  const handleSubTabClick = (subTab: Tab, mainTab: Tab) => {
    setActiveTab({ subTab, mainTab });
    onTabClick(subTab, mainTab);
  };
  return (
    <div>
      <TabContainer ref={ref}>
        {tabs.map((mainTab) => {
          const isSelected = mainTab.value === activeTab?.mainTab?.value;
          const isCurrentTab = mainTab.label === currentTab?.label;
          return (
            <MainTab
              isSelected={isSelected}
              key={mainTab.value}
              onClick={() => handleTabClick(mainTab)}
            >
              {mainTab.label} &nbsp;
              {mainTab.subTabs?.length ? (
                <FontAwesomeIcon
                  className="icon"
                  color={isSelected ? themeColors.primaryBlue5 : themeColors.lightGrey8}
                  icon={showSubTab && isCurrentTab ? faChevronUp : faChevronDown}
                />
              ) : (
                ''
              )}
              <br />
              {isSelected && mainTab?.subTabs ? (
                <SubText>({t(selectedTab?.label || activeTab?.subTab?.label || '')})</SubText>
              ) : (
                <SubText padding=".2rem"></SubText>
              )}
              {isCurrentTab && showSubTab && mainTab.subTabs && (
                <NavSubMenu>
                  {mainTab.subTabs?.map((subTab) => {
                    return (
                      <SubTab
                        key={subTab.value}
                        onClick={() => {
                          handleSubTabClick(subTab, mainTab);
                        }}
                        isSelected={subTab.label === activeTab?.subTab?.label}
                      >
                        <a>{t(subTab.label) as string}</a>
                      </SubTab>
                    );
                  })}
                </NavSubMenu>
              )}
            </MainTab>
          );
        })}
      </TabContainer>
    </div>
  );
};

export default React.memo(DropdownTab);

export function useComponentVisible(
  setShowContent: React.Dispatch<React.SetStateAction<boolean | undefined>>
): {
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | React.MutableRefObject<HTMLDivElement>
    | null
    | undefined;
} {
  /*eslint-disable-next-line*/
  const ref = useRef<any>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      setShowContent(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref };
}
