import React from 'react';

export const ImportIcon = (): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.33333 10.6667L4.66667 8.00008M4.66667 8.00008L7.33333 5.33341M4.66667 8.00008L14 8.00008M10.6667 10.6667V11.3334C10.6667 12.438 9.77124 13.3334 8.66667 13.3334H4C2.89543 13.3334 2 12.438 2 11.3334V4.66675C2 3.56218 2.89543 2.66675 4 2.66675H8.66667C9.77124 2.66675 10.6667 3.56218 10.6667 4.66675V5.33341"
      stroke="#0076CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
