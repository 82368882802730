import { useUpdateUserRoleMutation, useUpdateUserStatusMutation } from 'api';
import { ToastMsg } from 'common/components/Toast/Toast';
import { Button, InformationTooltip, Modal, Typography } from 'components';
import { IcoClose } from 'icons/IcoClose';
import IcoInfo2 from 'icons/IcoInfo2';
import {
  StyledButtonRow,
  StyledModalHeaderContainer,
  StyledTitle
} from 'pages/MachineManagement/MachineManagementPopup/MachineImageUploaderStyledComponents';
import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import theme from 'themes';
import { ModalSize } from 'types';
import { GroupItem, UserTypes } from 'types/user-management';

const ModalContent = styled.div`
  padding: 0rem 1.5rem 1.5rem;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;
const Col = styled.div`
  width: 14rem;
`;
export const CloseIcon = styled.span`
  cursor: pointer;
`;
export const Footer = styled.div`
  width: 70%;
  margin-left: auto;
  margin-bottom: 1.5rem;
`;
const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const InfoText = styled.div`
  display: flex;
  color: ${theme.colors.primaryBlue5};
`;
const roleOptions = [
  { label: 'Member', value: 'member' },
  { label: 'Admin', value: 'admin' }
];
const statusOptons = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' }
];
interface Props {
  showEditModal: boolean;
  closeModal: () => void;
  data: UserTypes[];
  groupDetail?: GroupItem;
}
const UpdateUser = ({ showEditModal, closeModal, data, groupDetail }: Props): JSX.Element => {
  const [userRole, setUserRole] = useState(data[0].isAdmin ? roleOptions[1] : roleOptions[0]);
  const [status, setStatus] = useState(data[0].isActive ? statusOptons[0] : statusOptons[1]);
  const [isFormChange, setIsFormChange] = useState(false);

  const [updateUserStatus] = useUpdateUserStatusMutation();
  const [updateUserRole] = useUpdateUserRoleMutation();

  const handleUpdateUser = () => {
    const roleParams = data.map((user) => ({
      group_id: groupDetail?.id,
      user_id: user.id,
      is_admin: userRole.value === 'admin'
    }));

    const updateStatusParams = data.map((user) => ({
      id: user.id,
      isActive: status.value === 'active'
    }));
    Promise.all([updateUserStatus(updateStatusParams), updateUserRole(roleParams)]).then(() => {
      ToastMsg({
        heading: 'Success',
        message: 'User information has been successfully updated.',
        type: 'success',
        position: 'top-center'
      });
      closeModal();
    });
  };

  const getUsersName = () => {
    return data.reduce(
      (acc, user) =>
        acc + `${user.firstName || ''} ${user.lastName || ''} ${data.length > 1 ? ', ' : ''}`,
      ''
    );
  };
  const trimTrailingComa = (str: string): string => {
    const val = str.split(',').length;
    if (val > 1) return str.trim().substring(0, str.length - 2);
    return str;
  };
  return (
    <Modal
      title={
        <StyledModalHeaderContainer justifyContent="space-between">
          <StyledTitle>Edit User</StyledTitle>
          <CloseIcon onClick={closeModal}>{IcoClose()}</CloseIcon>
        </StyledModalHeaderContainer>
      }
      allowContentScroll
      onClose={closeModal}
      showCloseButton={false}
      size={ModalSize.XSMALL_AUTO_HEIGHT}
      widthOverride="30rem"
      visible={showEditModal}
    >
      <ModalContent>
        <Typography>
          You have selected {data.length} member active user(s) from {groupDetail?.name}, what would
          you like to do with it?
        </Typography>
        <Row>
          <Col>Number of selected users:</Col>
          <Col>
            <UserInfo>
              {data.length}
              <InfoText>
                <InformationTooltip
                  tooltipText={trimTrailingComa(getUsersName())}
                  placement="top"
                  icon={<IcoInfo2 color={theme.colors.primaryBlue5} height={16} width={16} />}
                  width="20rem"
                  labelStyle={{
                    fontSize: '14px'
                  }}
                />
                <div>See selected name(s)</div>
              </InfoText>
            </UserInfo>
          </Col>
        </Row>

        <Row>
          <Col>Selected Role:</Col>
          <Col>
            {/* <BaseSelect
              // placeholder={t('select_dot') as string}
              options={roleOptions}
              variant="white"
              value={userRole}
              handleChange={(event) => {
                setIsFormChange(true);
                setUserRole(event.target.value);
              }}
            /> */}
            <Select
              options={roleOptions}
              value={userRole}
              onChange={(selectedOption) => {
                setIsFormChange(true);
                selectedOption && setUserRole(selectedOption);
              }}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null
              }}
              styles={{
                control: (styles) => ({ ...styles, backgroundColor: theme.colors.white }),
                option: (styles, { isFocused }) => {
                  return {
                    ...styles,
                    backgroundColor: isFocused ? theme.colors.lightGrey10 : theme.colors.white,
                    color: theme.colors.black
                  };
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>Selected Status:</Col>
          <Col>
            <Select
              options={statusOptons}
              value={status}
              onChange={(selectedOption) => {
                setIsFormChange(true);
                selectedOption && setStatus(selectedOption);
              }}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null
              }}
              styles={{
                control: (styles) => ({ ...styles, backgroundColor: theme.colors.white }),
                option: (styles, { isFocused }) => {
                  return {
                    ...styles,
                    backgroundColor: isFocused ? theme.colors.lightGrey10 : theme.colors.white,
                    color: theme.colors.black
                  };
                }
              }}
            />
          </Col>
        </Row>

        <Footer>
          <StyledButtonRow>
            <Button
              onClick={closeModal}
              variant="link"
              borderRadius="3rem"
              borderColor={theme.colors.primaryBlue5}
              // color={theme.colors.text.error}
            >
              Cancel
            </Button>
            <Button
              disabled={!isFormChange}
              onClick={handleUpdateUser}
              // borderColor={theme.colors.text.error}
              variant="hover-blue"
              bgColor={theme.colors.primaryBlue5}
              borderRadius="3rem"
              color={theme.colors.white}
            >
              Update
            </Button>
          </StyledButtonRow>
        </Footer>
      </ModalContent>
    </Modal>
  );
};

export default UpdateUser;
