import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  viewBox: '0 0 16 16',
  fill: 'none'
}))``;

export const IconUndo = ({
  width = 20,
  color,
  srLabel
}: {
  width?: number;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <div>
      <Svg {...{ width, color }} aria-label={srLabel || `edit`} xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5398_28241)">
          <path
            d="M5.99998 9.33333L3.33331 6.66667L5.99998 4"
            stroke={color || '#0076CC'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.33331 6.66669H10.6666C11.3739 6.66669 12.0522 6.94764 12.5523 7.44774C13.0524 7.94783 13.3333 8.62611 13.3333 9.33335C13.3333 10.0406 13.0524 10.7189 12.5523 11.219C12.0522 11.7191 11.3739 12 10.6666 12H9.99998"
            stroke={color || '#0076CC'}
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5398_28241">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </Svg>
    </div>
  );
};
