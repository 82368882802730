import React from 'react';
// Components
import { NewBaseTable } from 'components';

// Types
import { TableColumnConfigs } from 'types';
import { Alarm, DSIAlarm } from 'types/machine-health/alarms';

// Hooks
import styled, { CSSProperties } from 'styled-components';
import { Row } from '@tanstack/react-table';
import { formatDate, getDurationBetweenTimestamps } from 'helpers';
import { ColumnConfig } from 'components/NewBaseTable/NewBaseTable';
import { useTimeZone } from 'providers';
import { AlarmStepsContainer } from './index.element';
import { useTranslation } from 'react-i18next';

export interface AlarmTableRow extends Alarm {
  key: string;
  duration: string;
  startDate: string;
  isActive?: boolean;
}
interface TableUiProps {
  isLoading?: boolean;
  data?: Array<Alarm | DSIAlarm>;
  getRowStyles?: (row: Row<TableColumnConfigs>) => CSSProperties;
}
const TableCell = styled.div<{ width?: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

const AlarmsTable = ({ getRowStyles, data, isLoading }: TableUiProps): JSX.Element => {
  const { timeZone } = useTimeZone();
  const { t } = useTranslation(['mh']);

  const generateColumnConfigs: ColumnConfig[] = [
    {
      header: `${t('start_date')}`,
      id: 'startTimestamp',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => {
        const startTimestamp = getValue();
        const formattedStartTimestamp = `${formatDate(
          String(startTimestamp),
          'long',
          timeZone
        )} - ${formatDate(String(startTimestamp), 'hours-minutes-seconds', timeZone)}`;
        return (
          <TableCell title={formattedStartTimestamp} width={180}>
            {formattedStartTimestamp}
          </TableCell>
        );
      }
    },
    {
      header: `${t('duration')}`,
      id: 'duration',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ row }) => {
        const { startTimestamp, endTimestamp } = row.original;
        // Format the duration
        const duration =
          endTimestamp && startTimestamp
            ? getDurationBetweenTimestamps(String(startTimestamp), String(endTimestamp))
            : '-';
        return (
          <TableCell title={duration} width={100}>
            {duration}
          </TableCell>
        );
      }
    },
    {
      header: `${t('description')}`,
      id: 'description',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={300}>
          {getValue()}
        </TableCell>
      )
    },
    {
      header: `${t('area')}`,
      id: 'location',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={200}>
          {getValue()}
        </TableCell>
      )
    },
    {
      header: `${t('id')}`,
      id: 'code',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={100}>
          {getValue()}
        </TableCell>
      )
    },
    {
      header: `${t('type')}`,
      id: 'type',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={200}>
          {getValue()}
        </TableCell>
      )
    }
  ];

  const newTableData = data && !isLoading ? data : [];
  const columnsConfig = generateColumnConfigs;

  return (
    <AlarmStepsContainer className="alarm-steps-table-container">
      <NewBaseTable
        newTableData={newTableData}
        columnConfigs={columnsConfig}
        tdMarginLeft="0.625rem"
        textAlign="left"
        sortState={{ id: 'id', desc: true }}
        isShowColumnOptions={true}
        isDataLoading={isLoading}
        isShowGlobalSearch={true}
        showTableCoverRule={true}
        showTableHeaderRule={true}
        customClassName="alarm-steps-table"
        disableVirtualize={false}
        hideTotalFetchCount={false}
        maxTableHeight={35.8}
        getRowStyles={getRowStyles ? getRowStyles : undefined}
      />
    </AlarmStepsContainer>
  );
};
export default AlarmsTable;
