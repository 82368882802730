import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

export const KPIWidgetCellContainer = styled.div<StyledUiContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.2em;

  &.is-icon {
    .kpi-widget-cell__title {
      svg {
        height: 2.2em;
        width: 2.2em;
      }
    }
  }

  .kpi-widget-cell__title {
    font-weight: 500;
    opacity: 0.85;
    text-transform: capitalize;
    text-align: center;
  }

  .kpi-widget-cell__value {
    font-size: 1.6em;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;

    color: ${({ colr }) => colr};
  }

  .kpi-widget-cell__label {
    text-transform: capitalize;
  }

  &.is-center-cell {
    .kpi-widget-cell__value {
      font-weight: 500;
    }
  }
`;
