// Mapping of machine statuses for different BUs
// Disconnected status will depend of lastConnectedDate

import { TFunction } from 'i18next';

export const mappedStatuses: { [key: string]: string } = {
  Disconnected: 'Offline', // protein, value base on last_connected_val
  'Production Stopped': 'Connected', //protein
  'Production Running': 'Connected', //protein
  running: 'Connected',
  cleaning: 'Connected',
  idle: 'Connected',
  paused: 'Connected',
  stop_alarm: 'Connected',
  stop: 'Connected',
  Offline: 'Offline', //dsi not connected
  Running: 'Connected', //dsi
  'Not Running': 'Connected', //dsi
  'No Product': 'Connected', //dsi
  'In Production': 'Connected', // Pascal
  'never-connected': 'Offline',
  good: 'Connected'
};

export interface StateProtein {
  running: string;
  cleaning: string;
  idle: string;
  paused: string;
  stop_alarm: string;
  Disconnected: string;
  stop: string;
  [key: string]: string;
}

export const mappedStateProtein = (t: TFunction<'mh'>): StateProtein => {
  return {
    running: t('running'),
    cleaning: t('cleaning'),
    idle: t('idle'),
    paused: t('paused'),
    stop_alarm: t('stop_by_alarm'),
    Disconnected: t('unknown'),
    stop: t('paused')
  };
};

export const mappedStateDSI: { [key: string]: string } = {
  Offline: 'Unknown',
  'No Product': 'No Product',
  Running: 'Running',
  'Not Running': 'Not Running',
  Disconnected: 'Unknown'
};
