import React from 'react';
import type { FC, SVGProps } from 'react';
const IcoWarningNew: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5005 8.625V10.5417M11.5005 14.375H11.51M4.86093 18.2083H18.14C19.6154 18.2083 20.5376 16.6111 19.7999 15.3333L13.1603 3.83333C12.4226 2.55556 10.5783 2.55556 9.84058 3.83333L3.20105 15.3333C2.46333 16.6111 3.38548 18.2083 4.86093 18.2083Z"
        stroke={props.stroke || '#B62C10'}
        strokeWidth="1.4375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcoWarningNew;
