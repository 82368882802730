import React from 'react';

export const Ellipsys = ({ color }: { color?: string }): JSX.Element => {
  color = color || '#0076CC';
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_984_17504)">
        <path
          d="M7.33594 7.9987C7.33594 8.17551 7.40618 8.34508 7.5312 8.4701C7.65622 8.59513 7.82579 8.66536 8.0026 8.66536C8.17942 8.66536 8.34898 8.59513 8.47401 8.4701C8.59903 8.34508 8.66927 8.17551 8.66927 7.9987C8.66927 7.82189 8.59903 7.65232 8.47401 7.52729C8.34898 7.40227 8.17942 7.33203 8.0026 7.33203C7.82579 7.33203 7.65622 7.40227 7.5312 7.52729C7.40618 7.65232 7.33594 7.82189 7.33594 7.9987Z"
          stroke={color}
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33594 12.6667C7.33594 12.8435 7.40618 13.013 7.5312 13.1381C7.65622 13.2631 7.82579 13.3333 8.0026 13.3333C8.17942 13.3333 8.34898 13.2631 8.47401 13.1381C8.59903 13.013 8.66927 12.8435 8.66927 12.6667C8.66927 12.4899 8.59903 12.3203 8.47401 12.1953C8.34898 12.0702 8.17942 12 8.0026 12C7.82579 12 7.65622 12.0702 7.5312 12.1953C7.40618 12.3203 7.33594 12.4899 7.33594 12.6667Z"
          stroke={color}
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33594 3.33464C7.33594 3.51145 7.40618 3.68102 7.5312 3.80604C7.65622 3.93106 7.82579 4.0013 8.0026 4.0013C8.17942 4.0013 8.34898 3.93106 8.47401 3.80604C8.59903 3.68102 8.66927 3.51145 8.66927 3.33464C8.66927 3.15782 8.59903 2.98826 8.47401 2.86323C8.34898 2.73821 8.17942 2.66797 8.0026 2.66797C7.82579 2.66797 7.65622 2.73821 7.5312 2.86323C7.40618 2.98826 7.33594 3.15782 7.33594 3.33464Z"
          stroke={color}
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_984_17504">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
