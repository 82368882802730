// 3rd party libs
import React, { ReactNode } from 'react';
import { groupBy } from 'lodash';

// Components
import { useFilterSelected, useDateRange } from 'components';

// Types
import { CleaningStepGroup, DecoratedCleaningStep } from 'types/protein';

// Providers
import { useTranslation } from 'react-i18next';
import { BarChartWidget, chartValueRGBA } from 'common/components';
import { formatZonedTimestamp } from 'common/helpers/dateAndTimeHelpersV2';
import { colors as stepOverColors } from 'common/pages/fleetV2/settings/protein';
import { convertMilliSecondsToMinutes } from 'common/helpers/dateAndTimeHelpers';
import { AveragePressurizationTimeChartTooltip } from 'pages/FleetMachineDetail/MachineHealth/PressurizedPerformance/components/AveragePressurizationTimeWidget/AveragePressurizationTimeWidget.elements';
import { formatDuration } from 'helpers';
import { useCleaningStepsDurationData } from 'common/pages/fleetV2/machine/protein/hooks/useCleaningStepsDurationData';
import { timeDisplayFormatter } from 'components/StyledUi/DashboardWidget/timeDisplayFormatter';
import { NUM_TOP_STEPS } from 'components/CleaningStepCategoriesPieChart';

interface CleaningStepProps {
  groupedData?: CleaningStepGroup[];
  hasError?: ReactNode | ReactNode[];
}

export interface BarDatum {
  id: string;
  x: string;
  y: number;
  y0: number;
  color: string;
  toolTipData?: unknown;
}

export const groupIntoBars = (
  data: DecoratedCleaningStep[],
  getColorById: (id: string) => string,
  selectedSteps: string[]
): {
  barData: BarDatum[];
  numSessions: number;
  sessionIdToDateMap: Record<string, Date>;
  orderedSessionIds: string[];
} => {
  // Only steps that have a session are valid
  const validSteps = data.filter((step) => step.sessionId);
  const groupedBySession = groupBy(validSteps, 'sessionId');
  const sessionsArr = Object.values(groupedBySession);

  const sessionIdToDateMap: Record<string, Date> = {};

  const barData = sessionsArr
    .map((session) => {
      // Use the date of the first step within this session to populate the y-axis tick labels
      // We create a map of session id to date here, so it can be provided to the chart later
      const firstStep = session.reduce((prev, curr) => {
        return prev.startDateTime.getTime() < curr.startDateTime.getTime() ? prev : curr;
      });

      if (firstStep && !sessionIdToDateMap[firstStep.sessionId]) {
        sessionIdToDateMap[firstStep.sessionId] = firstStep.startDateTime;
      }

      // Combine steps of the same type within this session
      const combinedSteps: Record<string, DecoratedCleaningStep> = {};

      session.forEach((step) => {
        const existing = combinedSteps[step.id];

        // If existing combined step, add to the cumulative duration totals
        if (existing) {
          existing.duration += step.duration;
        } else {
          // No existing combined step, create a new one
          combinedSteps[step.id] = { ...step };
        }
      });

      let cumulativeStepDuration = 0; // Keep a cumulative running duration for this session, so the bars stack vertically on top of each other

      const combinedStepsArr = Object.values(combinedSteps);

      // Only display selected step types
      const filteredSteps = combinedStepsArr.filter(
        (d) => selectedSteps.includes(d.id) || !selectedSteps.length
      );

      // Sort steps by duration ascending
      filteredSteps.sort((a, b) => b.duration - a.duration);

      const returnData = filteredSteps.map((combinedStep) => {
        const datum = {
          id: combinedStep.id,
          x: combinedStep.sessionId,
          y0: cumulativeStepDuration / 1000 / 60, // In minutes
          y: (cumulativeStepDuration + combinedStep.duration) / 1000 / 60, // In minutes
          color: getColorById(combinedStep.id),
          toolTipData: {
            label: combinedStep.name,
            date: firstStep.startDateTime,
            duration: combinedStep.duration
          }
        };
        cumulativeStepDuration += combinedStep.duration;
        return datum;
      });
      return returnData;
    })
    .flat();

  const numSessions = sessionsArr.length;

  // Chronologically ordered session IDs - This is used to ensure the bar chart always shows the full
  // list of sessions in the x axis, in the correct order, even if some bars do not display due to filtering.
  const orderedSessionIds = Object.keys(groupedBySession).sort(
    (a, b) => sessionIdToDateMap[a].getTime() - sessionIdToDateMap[b].getTime()
  );

  return { barData, numSessions, sessionIdToDateMap, orderedSessionIds };
};

export const getOtherLabelData = (data: CleaningStepGroup[]): string[] => {
  // Order descending by percent
  const sortedData = data.sort((a, b) => b.percent - a.percent);

  // Get the rest of the steps
  const otherSteps = sortedData.slice(NUM_TOP_STEPS);
  const groupedData: string[] = [];

  otherSteps.forEach((step) => {
    groupedData.push(step.name);
  });

  return groupedData;
};

const NewCleaningStepDurationsBarChart = ({
  groupedData,
  hasError
}: CleaningStepProps): JSX.Element => {
  const { dateRange } = useDateRange();
  const { filteredChartData, isFetching, hasMessage, categories, sessionStartDates } =
    useCleaningStepsDurationData(getOtherLabelData(groupedData || [])) as {
      chartData?: Record<string, unknown>[];
      [key: string]: unknown;
    };

  // const formattedfilteredChartData =
  //   filteredChartData &&
  //   filteredChartData?.map((item) => ({
  //     ...item,
  //     group: item.group.toLowerCase()
  //   }));

  const uniqueGroupedData = new Set(groupedData.map((item) => item.name));
  const legendItems = [...uniqueGroupedData].map((item) => ({
    label: item,
    color: stepOverColors[item] || chartValueRGBA(item)
  }));

  const [selected, handle] = useFilterSelected();

  // the function that helps determines if the slice is selected
  const checkIfSelected = (bar: Record<string, unknown>) => {
    if (!selected) return true;
    const group = (bar?.label as string) || (bar?.group as string);
    const selectedVals = selected?.group || [];
    if (selectedVals.length && group && selectedVals.includes(group)) return true;
    return false;
  };

  const handleClick = ({ group, label }: Record<string, unknown>) => {
    if (label) {
      const isSelected = selected?.group?.includes(label as string) ? true : false;
      handle(isSelected ? 'toggle' : 'set', { group: label as string });
    } else if (group) {
      const isSelected = selected?.group?.includes(group as string) ? true : false;
      handle(isSelected ? 'toggle' : 'set', { group: group as string });
    }
  };

  const widgetSettings = {
    className: 'cleaning-step-duration-widget',
    title: 'Cleaning Step Duration (Mins)',
    isLoading: isFetching,
    hasError: hasError ? 'Error fetching cleaning details' : '',
    hasMessage,
    legendItems: legendItems,
    selected,
    handleClick
  };

  const TooltipComponent = (props: Record<string, unknown>): JSX.Element => {
    const { t } = useTranslation(['mh']);

    return (
      <AveragePressurizationTimeChartTooltip>
        <div className="pie-chart-tooltip__label">{t(String(props.group))}</div>
        <div className="pie-chart-tooltip__value">
          <span className="small-text">Duration: </span>{' '}
          {props?.total && formatDuration(props?.total as number, 'hours:mins:secs')}
        </div>
      </AveragePressurizationTimeChartTooltip>
    );
  };

  const hasDateFooter = `${timeDisplayFormatter({ ...dateRange, useForDisplay: true })}`;

  const chartSettings = {
    colors: Object.fromEntries(legendItems.map((item) => [item.label, item.color])),
    chartData: filteredChartData,
    categories: categories,
    chartKeys: {
      value: 'total'
    },
    hasDateFooter,
    leftTickFormatType: 'seconds-to-hours',
    checkIfSelected,
    handleClick,
    margins: {
      left: 50
    },
    format: {
      bottomTicks: (sessionId?: string | number) => {
        const dates = sessionStartDates as Record<string, string>;
        const date = dates?.[sessionId as string] as string;
        if (date) {
          return formatZonedTimestamp(date, 'UTC', 'M/d');
        }
        return sessionId;
      },
      leftTicks: (label?: number | string) => {
        return <>{convertMilliSecondsToMinutes(Number(label)).toFixed(0)} </>;
      }
    },
    Tooltip: TooltipComponent
  };

  return <BarChartWidget {...widgetSettings} {...chartSettings} />;
};

export default NewCleaningStepDurationsBarChart;
