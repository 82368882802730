import React, { ReactNode, useMemo } from 'react';
import { BarChartProps, BarChartBarProps } from '../';
import { BarDiv } from './DivBarChart.elements';
import { ChartTooltipWrapper } from 'common/ui';

export interface DivBarChartBarsProps extends BarChartProps {
  bars?: Record<string, BarChartBarProps[]>;
}

interface DefaultBarProps extends BarChartBarProps {
  Tooltip?: BarChartProps;
}

const DefaultBar = ({
  //x,
  y,
  //width,
  height,
  color,
  className,
  group,
  i,
  category,
  value,
  Tooltip,
  center,
  handleClick,
  ...rest
}: DefaultBarProps): JSX.Element => {
  return (
    <BarDiv
      key={`${category}${i}`}
      onClick={() => handleClick?.({ category, group, value, color, ...rest })}
      {...{
        className,
        x: center, //x as number,
        y: y as number,
        width: 0, //width as number,
        height: height as number,
        color: color as string
      }}
    >
      <div className="bar-styles">
        <ChartTooltipWrapper>
          {Tooltip ? (
            <Tooltip {...{ category, group, value, color, ...rest }} />
          ) : (
            <>
              <div>{category}</div>
              <div>{group}</div>
              <div>{value}</div>
            </>
          )}
        </ChartTooltipWrapper>
      </div>
    </BarDiv>
  );
};
export const DivBarChartBars = ({
  bars,
  width,
  height,
  chartKeys,
  Bar,
  Tooltip,
  handleClick
}: DivBarChartBarsProps): JSX.Element => {
  const groupKey = chartKeys?.group || 'group';

  const BarToUse: ReactNode = Bar || DefaultBar;

  const CachedBars = useMemo(() => {
    if (bars) {
      const stackCount = Object.keys(bars).length;

      return (
        <>
          {Object.entries(bars).map(([category, stacks], i) => {
            const barCount = i + 1;
            let catClass = `stack stack--${category} stack-position--${barCount}`;

            if (barCount === 1) catClass += ' stack-position--last';
            if (barCount === stackCount) catClass += ' stack-position--first';

            return (
              <span className={catClass} key={category}>
                {stacks.map((bar: Record<string, unknown>, i: number) => {
                  const count = i + 1;
                  const group = bar?.[groupKey as string];
                  let groupClass = `bar group--${group} bar-position--${count}`;
                  if (count === 1) groupClass += ' bar-position--top';
                  if (count === stacks.length) groupClass += ' bar-position--bottom';
                  return (
                    <BarToUse
                      key={i}
                      {...{ ...bar, className: groupClass, category, i, Tooltip, handleClick }}
                    />
                  );
                })}
              </span>
            );
          })}
        </>
      );
    }

    return <></>;
  }, [bars, width, height]);

  return CachedBars;
};
