import React from 'react';

export const IcoTriangle = ({ color, fill }: { color?: string; fill?: string }): JSX.Element => {
  color = color || '#008200';
  return (
    <svg
      className="icon icon-triangle"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.87417 1.35C8.37454 0.483334 9.62546 0.483334 10.1258 1.35L17.054 13.35C17.5544 14.2167 16.9289 15.3 15.9282 15.3H2.0718C1.07106 15.3 0.445594 14.2167 0.945964 13.35L7.87417 1.35Z"
        stroke={fill ? 'none' : color}
        fill={fill || 'none'}
        strokeWidth="1.4"
      />
    </svg>
  );
};

// this icon is a outlined triangle with a question mark in the middle
export const IcoTriangleWithQuestionMark = ({
  color
}: {
  color?: string;
  fill?: string;
}): JSX.Element => {
  color = color || '#750985';
  return (
    <svg
      className="icon icon--triangle-with-question-mark"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.44321 1.99188L12.2165 10.4776C12.417 10.8339 12.1639 11.2714 11.7733 11.2714H2.22667C1.83612 11.2714 1.58304 10.8339 1.78345 10.4776L6.55679 1.99188C6.75428 1.6408 7.24572 1.6408 7.44321 1.99188ZM8.1479 1.57954C7.63823 0.673485 6.36177 0.673485 5.8521 1.57954L1.07877 10.0653C0.572015 10.9661 1.20289 12.1 2.22667 12.1H11.7733C12.7971 12.1 13.428 10.9661 12.9212 10.0653L8.1479 1.57954Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M7.35794 8.1636H6.50888C6.5111 7.96199 6.52664 7.78838 6.55549 7.64277C6.58657 7.49492 6.63873 7.36163 6.71199 7.2429C6.78746 7.12417 6.88735 7.00657 7.01165 6.89008C7.11598 6.79599 7.20699 6.70639 7.28468 6.62126C7.36238 6.53614 7.42342 6.44877 7.46781 6.35917C7.51221 6.26732 7.53441 6.16539 7.53441 6.05339C7.53441 5.92346 7.51443 5.81593 7.47447 5.7308C7.43674 5.64344 7.37902 5.57735 7.30133 5.53255C7.22586 5.48775 7.13041 5.46535 7.01498 5.46535C6.91953 5.46535 6.83074 5.48663 6.74861 5.52919C6.66648 5.56951 6.59878 5.63224 6.54551 5.71736C6.49445 5.80249 6.46781 5.9145 6.46559 6.05339H5.5C5.50666 5.74649 5.57658 5.49335 5.70977 5.29398C5.84517 5.09236 6.02608 4.94339 6.2525 4.84707C6.47891 4.7485 6.73307 4.69922 7.01498 4.69922C7.32575 4.69922 7.59212 4.75074 7.8141 4.85379C8.03607 4.9546 8.20588 5.10356 8.32353 5.3007C8.44118 5.49559 8.5 5.73304 8.5 6.01306C8.5 6.20796 8.46226 6.38157 8.38679 6.5339C8.31132 6.68399 8.21254 6.82399 8.09046 6.95392C7.96837 7.08385 7.83407 7.21826 7.68757 7.35715C7.56104 7.4714 7.47447 7.59124 7.42786 7.71669C7.38346 7.84214 7.36016 7.99111 7.35794 8.1636ZM6.40899 9.20191C6.40899 9.05854 6.45782 8.93981 6.55549 8.84572C6.65316 8.7494 6.78413 8.70124 6.94839 8.70124C7.11043 8.70124 7.24029 8.7494 7.33796 8.84572C7.43785 8.93981 7.48779 9.05854 7.48779 9.20191C7.48779 9.3408 7.43785 9.4584 7.33796 9.55473C7.24029 9.65106 7.11043 9.69922 6.94839 9.69922C6.78413 9.69922 6.65316 9.65106 6.55549 9.55473C6.45782 9.4584 6.40899 9.3408 6.40899 9.20191Z"
        fill={color}
      />
    </svg>
  );
};
