// 3rd party
import React, { ReactElement } from 'react';
import styled from 'styled-components';

// Types
import { InputChangeHandler } from 'types';

// Components
import { InputLabel } from 'components';

// Component properties
interface Props {
  id?: string;
  width?: number; // give as pixel value
  height?: number; // give as pixel value
  checked?: boolean;
  onChange?: InputChangeHandler;
  label?: string;
  labelWeight?: number;
  className?: string;
  labelColor?: string;
  disabled?: boolean;
}

// Styling
const Root = styled.div`
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  align-self: center;
`;

const Input = styled.input<Props>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width / 16}rem` : '1.2rem')};
  height: ${(props) => (props.height ? `${props.height / 16}rem` : '1.2rem')};
  appearance: none;
  background-color: white;
  border-radius: 50%;
  border: ${(props) => `1px solid ${props.theme.colors.mediumGrey4}`};
  margin: 0;

  &[type='radio']:hover {
    background-color: ${(props) => props.theme.colors.primaryBlue5};
    border-color: ${(props) => props.theme.colors.primaryBlue5};
    box-shadow: inset 0 0 0 2.5px white, 0 0 0 1px rgba(0, 118, 204, 0.5);
    -webkit-box-shadow: inset 0 0 0 2.8px white, 0 0 0 1px rgba(0, 118, 204, 0.5); /* Adjusted for Safari */
  }

  &[type='radio']:focus-selected {
    background-color: ${(props) => props.theme.colors.primaryBlue5};
    border-color: ${(props) => props.theme.colors.primaryBlue5};
    box-shadow: inset 0 0 0 2.5px white, 0 0 0 1px rgba(0, 118, 204, 0.5);
    -webkit-box-shadow: inset 0 0 0 2.8px white, 0 0 0 1px rgba(0, 118, 204, 0.5); /* Adjusted for Safari */
    outline: 3px solid #cfe5f6;
  }

  &[type='radio']:checked {
    background-color: ${(props) => props.theme.colors.primaryBlue5};
    border-color: ${(props) => props.theme.colors.primaryBlue5};
    box-shadow: inset 0 0 0 4.2px white, 0 0 0 1px rgba(0, 118, 204, 0.5);
    -webkit-box-shadow: inset 0 0 0 4.4px white, 0 0 0 1px rgba(0, 118, 204, 0.5); /* Adjusted for Safari */
    outline: none;
  }

  &[type='radio']:disabled {
    background-color: ${(props) => props.theme.colors.background.background2};
    border-color: ${(props) => props.theme.colors.tableborder};
    outline: none;
  }

  &[type='radio']:hover:disabled {
    outline: none;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  &[type='radio']:checked:disabled {
    background-color: ${(props) => props.theme.colors.tableborder};
    border-color: ${(props) => props.theme.colors.tableborder};
    box-shadow: inset 0 0 0 0.26rem #f9fafb;
    -webkit-box-shadow: inset 0 0 0 0.26rem #f9fafb; /* Adjusted for Safari */
    outline: none;
  }
`;

const LabelContainer = styled.div<Props>`
  margin-left: 0.625rem;
  & > label {
    margin-bottom: 0;
    font-weight: ${(props) => (props.labelWeight ? props.labelWeight : 400)};
  }
  .radio-label {
    font-size: 14px;
    line-height: 16.8px;
    color  ${(props) => props.theme.colors.text.lightBlack};
  }
`;

const RadioButton = ({
  id,
  checked,
  onChange,
  width,
  height,
  label,
  labelWeight,
  labelColor,
  className,
  disabled
}: Props): ReactElement => (
  <Root className={className}>
    <Input
      id={`radio-${id}`}
      type="radio"
      disabled={disabled}
      className="radio-button"
      checked={checked}
      onChange={onChange}
      width={width}
      height={height}
    ></Input>
    {label && (
      <LabelContainer labelWeight={labelWeight}>
        <InputLabel color={labelColor}>
          <label htmlFor={`radio-${id}`} className="radio-label">
            {label}
          </label>
        </InputLabel>
      </LabelContainer>
    )}
  </Root>
);

export default RadioButton;
