// returns a list in unique values of a key from an array of objects
// and sorts them alphabetically
export const generateChartKeys = (data: Record<string, unknown>[], key: string): string[] =>
  data
    ?.reduce((acc: string[], d) => {
      const group = d?.[key] as string;
      if (group && !acc.includes(group)) acc.push(group);
      return acc;
    }, [])
    .sort();
