// 3rd party libs
import React, { ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useValidateUserEmailAvailableV2Mutation } from 'api';
import { useTranslation } from 'react-i18next';
import validator from 'validate.js';
import { v4 as uuidv4 } from 'uuid';

import { Checkbox, Typography, Input, InputLabel, Button, TooltipWrapper } from 'components';

import { ChangeEvent } from 'types';
import {
  UserValidationState,
  UserTypes,
  UserFormTypes,
  UserFormElement
} from 'types/user-management';

import userConstraints from './UserValidatorConfig';
import { CountryIso2, PhoneInput } from 'react-international-phone';
import { nameValidation } from 'helpers';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { InfoCircleNew } from 'icons/InfoCircleNew';
import { CheckmarkLong } from 'icons/CheckMarkLong';

interface Props {
  userData: UserFormTypes;
  onUserSave?: (userFormElement: UserFormTypes | UserFormElement) => void;
  isEditMode?: boolean;
  onCancelUserAdd?: (isEditMode: boolean, userId?: string) => void;
  handleFormChange?: (data: UserTypes, isValidForm: boolean, id: string | undefined) => void;
  isUserPage?: boolean;
  isAddGroupUser?: boolean;
  groupUsers?: UserFormElement[];
}

interface InputContainerProps {
  isRow?: boolean;
  paddingHorizontal?: string | number;
}

const PhoneContainer = styled.div`
  display: flex;
  .react-international-phone-input-container {
    display: flex;
    width: 100%;
    .react-international-phone-country-selector {
      position: relative;
      .react-international-phone-country-selector-button {
        border-radius: 4px;
        margin-right: -1px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        width: 40px;
        background: transparent;
        border: 1px solid #d8dde3;
      }
    }
    input {
      width: 100%;
      border: 1px solid #d8dde3;
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;
    }
    .react-international-phone-country-selector-dropdown {
      position: absolute;
      z-index: 1;
      top: var(--react-international-phone-dropdown-left, 44px);
      left: var(--react-international-phone-dropdown-left, 0);
      display: flex;
      width: 300px;
      max-height: 200px;
      flex-direction: column;
      padding: 4px 0;
      margin: 0;
      background-color: white;
      box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.25);
      color: #222;
      list-style: none;
      overflow-y: scroll;
      .react-international-phone-country-selector-dropdown__list-item {
        display: flex;
        min-height: 28px;
        box-sizing: border-box;
        align-items: center;
        padding: 2px 8px;
        img {
          margin-right: 8px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.63rem;
`;
export const FormSection = styled.section<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};

  div[class*='placeholder'],
  select option:first-child {
    color: #5d6a86;
  }

  &.occurrences {
    .ui-widget__main div > div {
      &:first-child {
        width: 24%;
      }
      &:nth-child(2) {
        width: 20%;
        margin: 0 1rem;
      }
      &:last-child {
        width: 56%;
      }
    }
  }

  .ui-widget {
    width: 100%;
  }

  .ui-widget__main > p {
    font-size: 0.875rem;
    padding-left: 1rem;
  }

  .ui-widget__main {
    overflow: visible;
  }

  .ui-date-range-picker {
    max-width: 435px;
  }

  .dropdown_checkboxes {
    font-family: ${({ theme }) => `${theme.typography.family}`};
    font-size: 0.875rem;
  }

  select {
    background: none;
  }
`;
export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.isRow ? 'row' : 'column')};
  gap: 0.25rem;
  justify-content: space-between;
  width: 100%;

  ${(props) =>
    props.paddingHorizontal
      ? `
    padding-left: ${props.paddingHorizontal};
    padding-right: ${props.paddingHorizontal};
  `
      : ''}

  & > div {
    padding-left: 0;
    padding-right: 0;
  }
`;
export const StyledInputLabel = styled(InputLabel)`
  align-items: center;
  color: ${({ theme }) => theme.colors.text.mutedgray};
  font-weight: ${({ theme }) => theme.typography.text.helper.weight};
  font-size: 0.875rem;
  display: flex;
`;
const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkRed};
  min-height: 1rem;
  padding-left: 0.5rem;
  margin: 0;
`;
const NotificationCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`;
const NotificationOption = styled.div`
  display: flex;
  margin-bottom: -1.125rem;
`;
const NotificationCardHeader = styled.div`
  display: flex;
`;
const TextLimit = styled.div`
  color: ${({ theme }) => theme.colors.lightGrey8};
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.938rem;
`;
const SaveUser = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-left: -10px;
`;
const IconWrapper = styled.div`
  margin-left: 2px;
`;
const CustomText = styled.div`
  width: 6rem;
`;
const AddEditUser = ({
  userData,
  onUserSave,
  handleFormChange,
  isUserPage
}: Props): ReactElement => {
  const { t } = useTranslation(['common']);
  const [user, setUser] = useState<UserTypes>(userData?.user || {});
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [validationState, setValidationState] = useState<UserValidationState>(
    userData.validationState as UserValidationState
  );

  const [validateUserEmail, { isLoading: isValidatingUserEmail }] =
    useValidateUserEmailAvailableV2Mutation();

  let isValidUserForm = false;
  let disabledForm = true;
  if (
    (validationState.email === undefined || validationState.email === '') &&
    (validationState.phone === undefined || validationState.phone === '') &&
    (validationState.firstName === undefined || validationState.firstName === '') &&
    (validationState.lastName === undefined || validationState.lastName === '')
  ) {
    isValidUserForm = true;
  }

  if (isDirty && !isValidUserForm) {
    disabledForm = true;
  } else if (!isDirty && !isValidUserForm) {
    disabledForm = true;
  } else if (!isDirty && isValidUserForm) {
    disabledForm = true;
  } else if (isDirty && isValidUserForm) {
    disabledForm = false;
  }

  const onUserFieldUpdate = (field: keyof UserTypes, value: string | number | boolean) => {
    const _user = {
      ...user,
      [field]: value
    };
    setUser(_user);
    if (
      validationState[field] ||
      field === 'textAlert' ||
      field === 'emailAlert' ||
      field === 'email' ||
      field === 'phone'
    ) {
      setValidationState({ ...validationState, [field]: undefined });
    }
    fieldValidation(field, value);
  };

  const onEmailBlur = useCallback(async () => {
    const updatedValidationState: UserValidationState = { ...validationState, email: undefined };

    // Check if valid email pattern
    const emailRegex =
      /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9-])+(\.))+([a-zA-Z]{2,4})+$/i;
    const emailRegexEmojiRes = /^(?!.*[\uD800-\uDFFF]).*$/;
    const personalDomain = /@(?!gmail\.com$|yahoo\.com$|outlook\.com$|hotmail\.com$)/;
    if (user.email === '') {
      updatedValidationState.email = `${t('email_not_empty')}`;
    } else if (
      !emailRegex.test(user.email as string) ||
      !emailRegexEmojiRes.test(user.email as string)
    ) {
      updatedValidationState.email = `${t('email_not_valid')}`;
    } else if (!personalDomain.test(user.email as string)) {
      updatedValidationState.email = 'Personal domain is not allowed';
    } else {
      // Check if email is available/taken by another user
      try {
        await validateUserEmail({ email: user.email as string, userId: user.id }).unwrap();
      } catch (error) {
        updatedValidationState.email = `${t('email_already_use')}`;
      }
    }
    setValidationState({ ...updatedValidationState });
  }, [user.email, user.id, validationState]);

  const validatePhone = (phone: string): boolean => {
    const updatedValidationState: UserValidationState = { ...validationState, phone: undefined };
    let isValid = true;
    if (phone === '') {
      updatedValidationState.phone = `${t('phone_number_empty')}`;
      isValid = false;
    } else if (!isValidPhoneNumber(phone)) {
      updatedValidationState.phone = `${t('phone_number_invalid')}`;
      isValid = false;
    }
    setValidationState({ ...updatedValidationState });
    return isValid;
  };
  const fieldValidation = (field: keyof UserTypes, data: string | number | boolean) => {
    const updatedValidationState: UserValidationState = {
      ...validationState,
      [field]: undefined
    };
    const userFormData = { ...user, [field]: data };
    const validations = validator.validate(userFormData, userConstraints);
    const validatesPhone = validatePhone(userFormData.phone || '');
    handleFormChange?.({ ...user, [field]: data }, !validations && validatesPhone, userData.id);
    if (validations?.[field]) {
      setValidationState({ ...updatedValidationState, [field]: validations?.[field]?.[0] });
    } else {
      setValidationState({ ...updatedValidationState });
    }
  };
  return (
    <FormContainer>
      <FormSection>
        <InputContainer>
          <StyledInputLabel mandatory={true}>{t('first_name')}</StyledInputLabel>
          <Input
            value={user.firstName}
            onChange={(event: ChangeEvent) => {
              setIsDirty(true);
              event.target.value.length <= 35 &&
                nameValidation(event.target.value) &&
                onUserFieldUpdate('firstName', event.target.value.trim() as string);
            }}
            placeholder="First Name"
          />
          {validationState.firstName ? (
            <ErrorText variant="helper">{validationState.firstName}</ErrorText>
          ) : (
            <TextLimit>Character limit: 35</TextLimit>
          )}
        </InputContainer>
        <InputContainer>
          <StyledInputLabel mandatory={true}>{t('last_name')}</StyledInputLabel>
          <Input
            value={user.lastName}
            onChange={(event: ChangeEvent) => {
              setIsDirty(true);
              event.target.value.length <= 35 &&
                nameValidation(event.target.value) &&
                onUserFieldUpdate('lastName', event.target.value.trim() as string);
            }}
            placeholder="Last Name"
          />
          {validationState.lastName ? (
            <ErrorText variant="helper">{validationState.lastName}</ErrorText>
          ) : (
            <TextLimit>Character limit: 35</TextLimit>
          )}
        </InputContainer>
      </FormSection>
      <FormSection>
        <InputContainer>
          <StyledInputLabel mandatory={true}>{t('email_address')}</StyledInputLabel>
          <Input
            value={user.email}
            disabled={isValidatingUserEmail}
            onChange={(event: ChangeEvent) => {
              setIsDirty(true);
              onUserFieldUpdate('email', event.target.value.trim() as string);
            }}
            onBlur={onEmailBlur}
            placeholder="xyz@example.com"
          />
          {validationState.email ? (
            <ErrorText mb={0} variant="helper">
              {validationState.email}
            </ErrorText>
          ) : (
            ''
          )}
        </InputContainer>
      </FormSection>
      <FormSection margin="1rem 0">
        <InputContainer>
          <StyledInputLabel mandatory={true}>{t('cell_phone')}</StyledInputLabel>
          <PhoneContainer>
            <PhoneInput
              defaultCountry="us"
              value={user.phone || ''}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onChange={(phone: string, country: CountryIso2) => {
                setIsDirty(true);
                onUserFieldUpdate('phone', phone as string);
                // if (validatePhone(phone)) {
                // }
                // else {
                //   toast.warning(`⚠️ Invalid Phone Number!`);
                //  }
                // onUserFieldUpdate('country', country as string);   // IF COUNTRY CODE IS REQUIRED THEN IMPLEMENT
              }}
              // onBlur={onPhoneBlur}
            />
          </PhoneContainer>
          {validationState.phone && <ErrorText variant="helper">{validationState.phone}</ErrorText>}
        </InputContainer>
      </FormSection>
      <NotificationCard>
        <NotificationCardHeader>
          <Typography color="darkGrey" weight="medium" size="1rem" as="span" mb={0}>
            {t('notifications')}
          </Typography>
        </NotificationCardHeader>

        <NotificationOption>
          <Checkbox
            id="email-notification-permission-check"
            width={20}
            height={20}
            checked={user.emailAlert}
            onChange={(event: ChangeEvent) => {
              setIsDirty(true);
              onUserFieldUpdate('emailAlert', event.target.checked as boolean);
            }}
            label={t('email') as string}
            margin="0.5rem 0 1rem"
          />
        </NotificationOption>

        <NotificationOption>
          <Checkbox
            id="text-notification-permission-check"
            width={20}
            height={20}
            checked={user.textAlert}
            onChange={(event: ChangeEvent) => {
              setIsDirty(true);
              onUserFieldUpdate('textAlert', event.target.checked as boolean);
            }}
            label={t('text') as string}
          />
        </NotificationOption>
      </NotificationCard>
      {!isUserPage && (
        <FormSection>
          <SaveUser>
            <Button
              variant="text"
              disabled={disabledForm}
              onClick={() => {
                onUserSave?.({
                  ...userData,
                  user: user,
                  validationState: validationState,
                  id: uuidv4()
                });
              }}
            >
              <CheckmarkLong /> &nbsp; Save{' '}
            </Button>
            <TooltipWrapper
              Tooltip={
                <CustomText>You will need to click on “Save” in order to save the user.</CustomText>
              }
              textColor="#323130"
              backGroundColor="#ffffca"
            >
              <IconWrapper>
                <InfoCircleNew />
              </IconWrapper>
            </TooltipWrapper>
          </SaveUser>
        </FormSection>
      )}
    </FormContainer>
  );
};

export default AddEditUser;
