import styled from 'styled-components';
import { DropdownSelectContainerStyles } from 'common/ui/Containers';
import theme from 'common/theme';

export const FilterButtonContainer = styled.button.attrs(() => ({ type: 'button' }))`
  border-color: transparent;
  background-color: transparent;
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-color: transparent;
  border-width: 0 0 2px 0;
  border-style: solid;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    border-color: ${theme.colors.secondary};
  }
`;

export const FilterButtonWithDropdownContainer = styled.div`
  position: relative;

  .dropdown {
    ${DropdownSelectContainerStyles};

    display: none;
    position: absolute;
    top: 100%;
    right: 0;

    &:focus {
      display: none;
    }

    button {
      background-color: transparent;
      border: none;
      font-family: inherit;
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  &:hover {
    .filter-button {
      border-color: ${theme.colors.secondary};
    }
    .dropdown {
      display: block;
    }
  }
`;
