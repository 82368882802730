import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoNotes = ({
  width,
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5700_196000)">
        <path
          d="M9.61488 20H6.99988C6.46944 20 5.96074 19.7893 5.58566 19.4142C5.21059 19.0391 4.99988 18.5304 4.99988 18V6C4.99988 5.46957 5.21059 4.96086 5.58566 4.58579C5.96074 4.21071 6.46944 4 6.99988 4H14.9999C15.5303 4 16.039 4.21071 16.4141 4.58579C16.7892 4.96086 16.9999 5.46957 16.9999 6V14"
          stroke={color || '#0076CC'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9999 19L15.9999 21L19.9999 17"
          stroke={color || '#0076CC'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 8H13"
          stroke={color || '#0076CC'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 12H11"
          stroke={color || '#0076CC'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5700_196000">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
