import React, { FC, SVGProps } from 'react';
import styled from 'styled-components';
import theme from 'themes';
import breakpoints from 'constants/breakpoints';

export type IconProps = {
  width?: number | undefined;
  height?: number | undefined;
  color?: string | undefined;
  srLabel?: string | undefined;
};

export type RibbonItem = {
  label: string;
  icon: FC<SVGProps<SVGSVGElement>> | (({ width, color, srLabel }: IconProps) => JSX.Element);
  onClick?: () => void;
  disable?: boolean;
  textColor?: string;
};
type Props = {
  ribbonItems: RibbonItem[];
  padding?: string;
  border?: boolean;
  gap?: string;
};

const { size } = breakpoints;
const Container = styled.div<{ padding?: string; border?: boolean; gap?: string }>`
  display: flex;
  gap: ${({ gap }) => (gap ? gap : `2rem`)};
  font-size: 1.1rem;
  padding: ${({ padding }) => padding};
  border-bottom: ${({ border, theme }) => (border ? `1px solid ${theme.colors.mediumGrey1}` : '')};
`;
export const BtnText = styled.span<{ color?: string }>`
  color: ${({ color }) => color || 'inherit'};
  font-size: 14px;
  text-align: center;
`;

export const IconTextBtn = styled.button<{ gap?: string; disable?: boolean }>`
  all: unset;
  display: flex;
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
  opacity: ${({ disable }) => (disable ? 0.5 : 1)};
  gap: ${({ gap }) => gap};
  align-items: center;
  @media (max-width: ${size.md}px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const RibbonComponent = ({ ribbonItems, padding, border, gap }: Props): JSX.Element => {
  return (
    <Container padding={padding} border={border} gap={gap}>
      {ribbonItems?.map((ribbon) => {
        const { icon: Icon, disable, textColor } = ribbon || {};
        return (
          <IconTextBtn
            gap=".5rem"
            disable={disable}
            onClick={ribbon?.onClick}
            className="ribbon-button"
          >
            {Icon && <Icon width={24} color={disable ? theme.colors.lightGrey9 : ''} />}
            <BtnText color={textColor ? textColor : ''}>{ribbon?.label}</BtnText>
          </IconTextBtn>
        );
      })}
    </Container>
  );
};

export default RibbonComponent;
