import React from 'react';
import { BarChartWidgetContainer } from './BarChartWidget.elements';
import { BarChartWidgetProps } from './BarChartWidget.types';
import { WidgetUi } from 'common/ui';
import { DivBarChart } from 'common/components';
import { ResponsiveInlineChartLegend } from '../ChartLegends';

interface Props extends BarChartWidgetProps {
  children?: React.ReactNode;
}

export const BarChartWidget = ({
  title,
  className,
  Header,
  SubHeader,
  Footer,
  AfterMain,
  hasMessage,
  hasError,
  isLoading,
  children,
  hasButtons,
  hasDateFooter,
  /* chart data must have group, category, and at least value */
  chartData,
  data,
  colors,
  hideLegend,
  hasDateRanges,
  defaultDateRange,
  chartKeys,
  legendItems,
  checkIfSelected,
  selected,
  groupKey,
  valueKey,
  labelKey,
  categoryKey,
  handleClick,
  ...rest
}: Props): JSX.Element => {
  groupKey = groupKey || chartKeys?.group || 'group';
  valueKey = valueKey || chartKeys?.value || 'value';
  categoryKey = categoryKey || chartKeys?.category || 'category';

  const sorted = chartData || data;

  const widgetSettings = {
    title,
    Header,
    Footer,
    AfterMain,
    hasMessage,
    hasError,
    isLoading,
    children,
    hasButtons,
    hasDateFooter,
    className,
    hasDateRanges,
    defaultDateRange
  };

  const chartSettings = {
    chartData: sorted,
    colors,
    chartKeys,
    groupKey,
    valueKey,
    categoryKey,
    checkIfSelected,
    handleClick,
    height: 200,
    ...rest
  };

  const legendItemsObj =
    legendItems ||
    (!hideLegend &&
      chartData?.reduce((acc, item) => {
        const group = item?.group as string;
        if (!acc?.[group]) acc[group] = { label: group, color: item?.color || colors?.[group] };
        return acc;
      }, {}));

  const legendSettings = legendItemsObj && {
    className: 'widget-ui__sub-header',
    items: legendItems || Object.values(legendItemsObj),
    selected: selected?.[groupKey],
    groupKey,
    labelKey,
    checkIfSelected,
    handleClick: (x?: string) => handleClick?.({ [groupKey]: x })
  };

  return (
    <WidgetUi
      SubHeader={
        SubHeader || (legendSettings && <ResponsiveInlineChartLegend {...legendSettings} />)
      }
      Main={
        <BarChartWidgetContainer className="widget-ui__main has-overflow">
          <DivBarChart {...chartSettings} />
        </BarChartWidgetContainer>
      }
      {...widgetSettings}
    />
  );
};
