import { IcoClose } from 'icons/IcoClose';
import React from 'react';
import styled from 'styled-components';
import theme from 'themes';

type ChipProps = {
  files?: File[];
  handleRemove?: (file?: File) => void;
};

const Container = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
export const ChipList = styled.div<{ width?: string }>`
  border: 1px solid ${theme.colors.primaryBlue5};
  border-radius: 1rem;
  width: ${({ width }) => width || '12rem'};
  white-space: nowrap;
  display: flex;
  align-items: center;
  & svg {
    cursor: pointer;
  }
`;
export const ChipItem = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '10rem'};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem 1rem;
`;
const DeleteIcon = styled.div``;

const ChipContainer = ({ files, handleRemove }: ChipProps): JSX.Element => {
  return (
    <Container>
      {files?.map((file) => {
        return (
          <ChipList>
            <ChipItem>{file.name}</ChipItem>
            <DeleteIcon onClick={() => handleRemove && handleRemove(file)}>{IcoClose()}</DeleteIcon>
          </ChipList>
        );
      })}
    </Container>
  );
};

export default ChipContainer;
