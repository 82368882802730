import React from 'react';
import styled from 'styled-components';

export const IcoWrench = ({
  color,
  size
}: {
  width?: string;
  color?: string;
  srLabel?: string;
  size?: number;
}): JSX.Element => {
  color = color || '#E66C37';
  size = size || 2;
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-wrench"
    >
      <path
        d="M8.7708 14.3334H14.4279V8.83339L7.82794 2.41672C9.93925 1.43639 12.3112 1.11654 14.6173 1.5012C16.9233 1.88586 19.05 2.95612 20.7044 4.56461C22.3589 6.17309 23.4597 8.24068 23.8553 10.4827C24.251 12.7246 23.922 15.0307 22.9137 17.0834L34.228 28.0834C34.9781 28.8127 35.3996 29.8019 35.3996 30.8334C35.3996 31.8648 34.9781 32.854 34.228 33.5834C33.4778 34.3127 32.4603 34.7225 31.3994 34.7225C30.3385 34.7225 29.321 34.3127 28.5708 33.5834L17.2565 22.5834C15.1452 23.5637 12.7732 23.8836 10.4672 23.4989C8.16116 23.1143 6.03449 22.044 4.38005 20.4355C2.72561 18.827 1.62477 16.7594 1.22911 14.5175C0.83346 12.2755 1.16246 9.96938 2.1708 7.91672L8.7708 14.3334Z"
        stroke={color}
        strokeWidth={size}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || '18px',
  height: width ? 'auto' : '20px',
  fill: 'none'
}))``;

export const IcoWrench2 = ({ width, color }: { width?: string; color?: string }): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3025_115185)">
        <path
          d="M7.0001 9.99985H10.0001V6.99985L6.5001 3.49985C7.61973 2.96513 8.8776 2.79066 10.1005 3.00048C11.3234 3.21029 12.4512 3.79407 13.3285 4.67143C14.2059 5.54878 14.7897 6.67656 14.9995 7.89946C15.2093 9.12235 15.0348 10.3802 14.5001 11.4999L20.5001 17.4999C20.8979 17.8977 21.1214 18.4372 21.1214 18.9999C21.1214 19.5625 20.8979 20.102 20.5001 20.4999C20.1023 20.8977 19.5627 21.1212 19.0001 21.1212C18.4375 21.1212 17.8979 20.8977 17.5001 20.4999L11.5001 14.4999C10.3805 15.0346 9.1226 15.209 7.8997 14.9992C6.6768 14.7894 5.54903 14.2056 4.67167 13.3283C3.79432 12.4509 3.21054 11.3232 3.00072 10.1003C2.7909 8.87735 2.96537 7.61948 3.5001 6.49985L7.0001 9.99985Z"
          stroke={color || '#0076CC'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3025_115185">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
