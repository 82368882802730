// 3rd party libs
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import circleExc from 'icons/CircleExclamation.svg';

// Components
import { UiWidget as Ui, Icon } from '../';
import { Types } from '.';
import { Modal, Typography } from 'components';
import { ModalSize } from 'types';

// Utilities
import { DateRangeProps } from '../DateRange/DateRangePicker/DateRangePicker.types';
import { IcoCalendar } from 'icons/IcoCalendar';
import { RightArrowIcon } from 'icons/RightArrowIcon';
// Constants
import { LineGuide } from 'constants/lineView';

// Theme
import theme from 'themes';
import { Ellipsys } from 'icons/Ellipsys';
import { SettingIcon } from 'icons/SettingIcon';

const StyledModalContentContainer = styled.div`
  padding: 0rem 3rem 1.5rem;
`;

interface Props extends Types.Props, Types.RefProps {
  handleToggleCalendar: () => void;
  onAdminButtonClickCallback?: () => void;
  linksToPathTooltipContent?: string;
  titleIndicator?: ReactNode | ReactNode[];
  showIconHelper?: boolean;
  dateRange?: DateRangeProps;
  hasMenu?: boolean;
  handleToggleMenu: () => void;
  hasCustomizer?: boolean;
  hasCustomMenu?: boolean;
  handleToggleCustomMenu: () => void;
  itemCount?: number;
  isMenuActive?: boolean;
}
interface IconProps extends Types.IconLinkProps {
  left?: boolean;
  hasError?: string;
  tooltip?: string;
  tooltipProperties?: {
    offsetX?: number;
    offsetY?: number;
    placement?: string;
  };
}

const WidgetIcon = ({
  Icon,
  label,
  handleClick,
  left,
  hasError,
  tooltip,
  tooltipProperties
}: IconProps): JSX.Element => {
  const { offsetX = 0, offsetY = 0, placement = 'top' } = tooltipProperties ?? {};

  const widgetHeaderIcon = tooltip ? (
    <Tooltip
      align={{
        offset: [offsetX, offsetY]
      }}
      overlay={tooltip}
      overlayStyle={{ maxWidth: 250 }}
      placement={placement}
    >
      <Ui.Icon ga={left ? 'icon-left' : 'icon-right'}>{Icon}</Ui.Icon>
    </Tooltip>
  ) : (
    <Ui.Icon ga={left ? 'icon-left' : 'icon-right'}>{Icon}</Ui.Icon>
  );

  const widgetHeaderIconButton = tooltip ? (
    <Tooltip placement="top" overlay={tooltip}>
      <Ui.IconButton
        onClick={() => handleClick?.()}
        ariaLabel={label}
        ga={left ? 'icon-left' : 'icon-right'}
        hasError={hasError}
      >
        {Icon}
      </Ui.IconButton>
    </Tooltip>
  ) : (
    <Ui.IconButton
      onClick={() => handleClick?.()}
      ariaLabel={label}
      ga={left ? 'icon-left' : 'icon-right'}
      hasError={hasError}
    >
      {Icon}
    </Ui.IconButton>
  );

  return !handleClick ? widgetHeaderIcon : widgetHeaderIconButton;
};

export const WidgetHeader = ({
  hasError,
  isLoading,
  isAdminWidget,
  title,
  titleIndicator,
  hasDatePicker,
  linksToPath,
  linksToPathTooltipContent,
  handleToggleCalendar,
  handleToggleMenu,
  onAdminButtonClickCallback,
  hasFlyOut,
  showIconHelper,
  tooltipProperties,
  hasMenu,
  hasCustomizer,
  hasCustomMenu,
  handleToggleCustomMenu,
  itemCount,
  isMenuActive
}: Props): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation(['mh']);
  const [showMachineStateInfo, setShowMachineStateInfo] = useState<boolean>(false);

  const headingFontSize = '1rem';
  const textSpacing = '1rem';

  const triggerShowMachineStateInfo = (): void => {
    setShowMachineStateInfo(true);
  };

  const onCloseStateInfoModal = () => {
    setShowMachineStateInfo(false);
  };
  let iconRight;
  // setup header icons and links
  // date picker overrides all
  if (linksToPath && linksToPathTooltipContent) {
    iconRight = !hasError && {
      Icon: <RightArrowIcon />,
      label: `go to ${linksToPath}`,
      handleClick: () => history.push(linksToPath),
      tooltip: `${linksToPathTooltipContent}`
    };
  } else if (hasCustomizer && onAdminButtonClickCallback) {
    iconRight = !hasError && {
      Icon: <SettingIcon />,
      label: 'view calendar',
      handleClick: onAdminButtonClickCallback
    };
  } else if (isAdminWidget && !!onAdminButtonClickCallback) {
    iconRight = {
      Icon: <SettingIcon />,
      label: 'edit properties',
      handleClick: onAdminButtonClickCallback
    };
  } else if (hasFlyOut) {
    iconRight = !hasError && {
      Icon: <Icon.ChevRight />,
      label: 'options',
      handleClick: () => hasFlyOut()
    };
  } else if (hasDatePicker) {
    iconRight = !hasError && {
      Icon: <IcoCalendar />,
      label: 'view calendar',
      handleClick: handleToggleCalendar
    };
  } else if (hasMenu) {
    iconRight = !hasError && {
      Icon: (
        <Ui.customIconBtn isActive={isMenuActive}>
          <Ellipsys />
        </Ui.customIconBtn>
      ),
      label: 'view menu',
      handleClick: handleToggleMenu
    };
  } else if (hasCustomMenu) {
    iconRight = !hasError && {
      Icon: (
        <Ui.customIconBtn isActive={isMenuActive}>
          <Ellipsys />
        </Ui.customIconBtn>
      ),
      label: 'view menu',
      handleClick: handleToggleCustomMenu
    };
  } else if (showIconHelper && linksToPathTooltipContent) {
    iconRight = {
      Icon: <Icon.InfoIcon />,
      label: 'info',
      tooltip: `${linksToPathTooltipContent}`,
      tooltipProperties
    };
  }
  // status icon, not clickable
  /* will add when icon needed
  if (hasStatusIcon)
    iconLeft = {
      Icon: <FontAwesomeIcon icon={faChartArea} />, // TODO: replace this with status component
      label: 'status'
    };
  */

  return (
    <>
      {title && (
        <Ui.Title hasHeader hasTitleIndicator={titleIndicator ? true : false}>
          {/* {titleIndicator} */}
          {t(title)}{' '}
          <span style={{ color: theme.colors.lightGrey8 }}>
            {itemCount ? `(${itemCount} in total)` : ``}
          </span>
          {title === t('machine_utilization') ? (
            <span onClick={triggerShowMachineStateInfo}>
              <img src={circleExc} style={{ cursor: 'pointer' }} />
            </span>
          ) : (
            ''
          )}
        </Ui.Title>
      )}
      <Modal
        visible={showMachineStateInfo as boolean}
        onClose={onCloseStateInfoModal}
        title={t(LineGuide.subTitle) as string}
        size={ModalSize.SMALL_AUTO_HEIGHT}
        maxWidth="30rem"
        widthOverride="25rem"
      >
        <StyledModalContentContainer>
          <Typography color={theme.colors.darkGrey} size={headingFontSize} weight="bold">
            {t(LineGuide.pemeaPna.details.running.title)}
          </Typography>
          <Typography
            color={theme.colors.darkGrey}
            mb={textSpacing}
            size={theme.typography.text.base.size}
          >
            {t(LineGuide.pemeaPna.details.running.text)}
          </Typography>

          <Typography color={theme.colors.darkGrey} size={headingFontSize} weight="bold">
            {t(LineGuide.pemeaPna.details.stopByAlarm.title)}
          </Typography>
          <Typography
            color={theme.colors.darkGrey}
            mb={textSpacing}
            size={theme.typography.text.base.size}
          >
            {t(LineGuide.pemeaPna.details.stopByAlarm.text)}
          </Typography>

          <Typography color={theme.colors.darkGrey} size={headingFontSize} weight="bold">
            {t(LineGuide.pemeaPna.details.paused.title)}
          </Typography>
          <Typography
            color={theme.colors.darkGrey}
            mb={textSpacing}
            size={theme.typography.text.base.size}
          >
            {t(LineGuide.pemeaPna.details.paused.text)}
          </Typography>

          <Typography color={theme.colors.darkGrey} size={headingFontSize} weight="bold">
            {t(LineGuide.pemeaPna.details.cleaning.title)}
          </Typography>
          <Typography
            color={theme.colors.darkGrey}
            mb={textSpacing}
            size={theme.typography.text.base.size}
          >
            {t(LineGuide.pemeaPna.details.cleaning.text)}
          </Typography>

          <Typography color={theme.colors.darkGrey} size={headingFontSize} weight="bold">
            {t(LineGuide.pemeaPna.details.idle.title)}
          </Typography>
          <Typography
            color={theme.colors.darkGrey}
            mb={textSpacing}
            size={theme.typography.text.base.size}
          >
            {t(LineGuide.pemeaPna.details.idle.text)}
          </Typography>
        </StyledModalContentContainer>
      </Modal>

      {iconRight && !isLoading && <WidgetIcon {...iconRight} />}
    </>
  );
};
