import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  differenceInSeconds,
  parseISO,
  format,
  getHours,
  getMinutes,
  getSeconds,
  parse
} from 'date-fns';
export { convertSecondsToTime } from './dateAndTimeHelpersV2';
// This makes a ISO time stamp out of StartDate and StartTime
// example startDate: 2023-11-15, startTime: 01:50:42 will become 2023-11-15T01:50:42+00:00
export const combineDateAndTimeToISOTimeStamp = (
  dateStamp: string,
  timeStamp: string,
  timeZone?: string
): string => {
  const newStamp = `${dateStamp}T${timeStamp}+00:00`;
  if (!timeZone) return newStamp;
  return convertTimestampUTCtoZoned(timeStamp, timeZone) as string;
};

// takes a UTC timestamp and a timezone, applies the offset to the time and adds the offset to the timestamp then returns a new ISO timestamp with zoned time
// EXAMPLE --------------
// UTC timestamp: 2023-11-15T01:50:42+00:00
// Timezone: Pacific/Honolulu (which is -10 hours from utc)
// will return the string 2023-11-14T15:50:42-10:00
export const convertUTCTimestamptoZonedTimestamp = (
  timestamp: string,
  timeZone: string,
  format?: string
): string | undefined => {
  format = format || `yyyy-MM-dd'T'HH:mm:ssxxx`;
  // can't convert because there's no timeZone yet
  if (!timeZone) return undefined;
  // Parse the ISO timestamp with offset
  const parsedDate = parseISO(timestamp);
  // Format the converted date with the new time zone offset
  const formattedDate = formatInTimeZone(parsedDate, timeZone, format); //format(convertedDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
  // return the new formated timestamp string
  return formattedDate;
};

// takes a zoned timestamp converts to utc time and adds the utc offset to the timestamp
export const convertZonedTimestamptoUTCTimestamp = (
  timestamp: string,
  timeZone?: string,
  format?: string
): string | undefined => {
  format = format || `yyyy-MM-dd'T'HH:mm:ssxxx`;
  // can't convert because there's no timeZone yet
  if (!timeZone) return undefined;
  // Parse the ISO timestamp with offset
  const parsedDate = parseISO(timestamp);
  // Format the converted date with the new time zone offset
  const formattedDate = formatInTimeZone(parsedDate, timeZone, format); //format(convertedDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
  // return the new formated timestamp string
  return formattedDate;
};

export const convertTimestampUTCtoZoned = convertUTCTimestamptoZonedTimestamp;

// converts seconds to 'hours as a number'
export const convertSecondsToHours = (seconds: number, round?: boolean): number => {
  const val = seconds / 3600;
  return round ? Math.round(val * 2) / 2 : val;
};

// converts milliseconds to 'minutes as a number'
export const convertMilliSecondsToMinutes = (seconds: number, round?: boolean): number => {
  const val = seconds / 60000;
  return round ? Math.round(val * 2) / 2 : val;
};

// converts 'hh:mm:ss' to seconds
export const convertTimeToSeconds = (time?: string): number => {
  if (!time) return -1;
  const parsedTime = parse(time, 'HH:mm:ss', new Date());
  return getHours(parsedTime) * 3600 + getMinutes(parsedTime) * 60 + getSeconds(parsedTime);
};
/*
export const convertSecondsToTime = (seconds: number, formatter?: 'hrs' | 'hrs mins'): string => {
  seconds = seconds || 0;
  // this is an older version, need to test which one works better
  // new Date(seconds * 1000).toISOString().substring(11, 19);
  if (typeof seconds === 'string') return '';

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  //const remainingSeconds = seconds % 60;



  if (formatter === 'hrs mins') return `${Number(hours)} hr ${Number(minutes)} min`;

  return format(new Date(0, 0, 0, 0, 0, seconds), 'H:mm:ss');
};

/*
// converts an iso timestamp with the timezone offset
export const formatTimeStampWithTz = (timestamp: string, tz: string, format?: string): string => {
  const convertedDate = utcToZonedTime(parseISO(timestamp), tz);
  // Format the converted date with the new time zone offset
  return formatInTimeZone(convertedDate, tz, format || `yyyy-MM-dd'T'HH:mm:ssxxx`);
};

// takes a UTC timestamp and a timeZone, converts the time stamp to the zoned time and formats back to ISO string format with the offset added to the end of the
// timestamp
*/
/* this might not work, use onvertTimestampUTCtoZoned

export const addTimeZoneToUTCTimeStamp = (timestamp: string, tz: string): string => {
  const utcDate = utcToZonedTime(parseISO(timestamp), tz);
  const utcStamp = convertToUTCTimeStamp(utcDate);
  return formatInTimeZone(utcStamp, tz, `yyyy-MM-dd'T'HH:mm:ssxxx`);
};


export const convertToUTCTimeStamp = (timestamp: string | Date): string => {
  if (typeof timestamp === 'string')
    return formatInTimeZone(parseISO(timestamp), 'UTC', `yyyy-MM-dd'T'HH:mm:ssxxx`);
  return formatInTimeZone(timestamp, 'UTC', `yyyy-MM-dd'T'HH:mm:ssxxx`);
};
*/

// get the number of seconds between a start and end time, provided in ISO string format
// and return a number value of the amount of seconds between the 2
export const getDurationISOTimeStamps = (start: string, end: string): number =>
  differenceInSeconds(parseISO(start), parseISO(end));

export const getUserLocale = (): string =>
  navigator.languages.length ? navigator.languages[0] : navigator.language;

export type FormatLocaleDatePropsFormatTypes =
  | 'full' // "Monday, November 1, 2021 at 12:00:00 AM Brasilia Standard Time"
  | 'long' // "November 1, 2021 at 12:00:00 AM GMT-3"
  | 'medium' // "Nov 1, 2021, 12:00:00 AM"
  | 'short' // "11/1/21, 12:00 AM"
  | undefined; // uses "short" as default

export const formatLocaleDate = (
  timestamp: string,
  formatType?: FormatLocaleDatePropsFormatTypes
): string => {
  formatType = formatType || 'short';

  const locale = getUserLocale();
  const date = new Date(timestamp);

  return new Intl.DateTimeFormat(locale, {
    dateStyle: formatType,
    timeStyle: formatType
  }).format(date);
};

export interface GetCUrrentZonedTimeReturnProps {
  string: string;
  date: Date;
}
export const getCurrentZonedTime = (timeZone: string): GetCUrrentZonedTimeReturnProps => {
  const currentDate = new Date();
  const currentUtcTime = zonedTimeToUtc(currentDate, timeZone);
  const currentZonedTime = utcToZonedTime(currentUtcTime, timeZone);

  let formatter = 'yyyy-MM-ddTHH:mm:ss';

  if (timeZone === 'UTC') formatter += 'Z';
  else formatter += 'xxx';

  return {
    string: format(currentZonedTime, formatter),
    date: currentZonedTime
  };
};

export const getDateTime = (dateString: string): string[] => {
  const [date, times] = dateString.split('T');
  const [time] = times.split('+');
  return [date, time];
};

export const convertDateToString = (
  timeStamp: Date,
  formatting?: string,
  options?: {
    // takes a date object with the local timezone attached to it,
    // converts the timestamp to UTC time
    convertToUTC?: boolean;
    timeZone?: string;
  }
): string => {
  formatting = formatting || 'api-call';

  const formats: Record<string, string> = {
    'chart-axis': 'MMM/d',
    'api-call': `yyyy-MM-dd'T'HH:mm:ssxxx`
  };

  if (options?.convertToUTC) {
    const utcDate = utcToZonedTime(timeStamp, 'UTC');
    return formatInTimeZone(utcDate, 'UTC', formats[formatting || 'api-call']);
  }

  // Format the UTC date as needed
  return format(timeStamp, formats[formatting || 'api-call']);
};
