import styled from 'styled-components';
import theme from 'themes';

export const Container = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: 'wrap';

  margin: ${({ margin }) => margin};
  border-bottom: 1px solid ${theme.colors.lightGrey10};
  padding: 1.5rem 8%;
`;
export const StepItem = styled.div<{ color?: string }>`
  color: ${({ color }) => color || theme.colors.mediumGrey4};
`;
export const StepItemContent = styled.div`
  margin: 0 2%;
  // white-space: nowrap;
  position: relative;
  text-align: center;
`;

export const ProgressBar = styled.div<{ active?: boolean; isPrev?: boolean }>`
  flex: 1;
  height: 0.3rem;
  background: ${({ isPrev, theme, active }) =>
    active
      ? 'linear-gradient(90deg, rgba(0,118,204,1) 0%, rgba(0,118,204,1) 50%, rgba(229,233,237,1) 50%, rgba(229,233,237,1) 100%)'
      : isPrev
        ? theme.colors.primaryBlue5
        : theme.colors.lightGrey3};
  border-radius: 0.17rem;
`;
