import styled from 'styled-components';

export const CellValueContainer = styled.div`
  text-align: center;
  max-width: 50%;
  font-size: 1.1em;
  min-width: max-content;

  .cell-value__value {
    font-size: 1.25em;
    line-height: 0.95;
    font-weight: 500;
    color: inherit;
    opacity: 0.85;
  }

  .cell-value__title {
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0.8em;
    text-transform: capitalize;
    opacity: 0.85;
  }

  .cell-value__label_container {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .cell-value___label {
    display: inline-block;
    margin-top: 0.85em;
    font-size: 0.85em;
    font-weight: 500;
    text-transform: capitalize;
    max-width: 9rem;
  }

  .cell-value__label_color_dot {
    display: inline-block;
    width: 0.625em;
    height: 0.625em;
    border-radius: 50%;
    opacity: 1;
  }

  .cell-value__label_span {
    opacity: 0.7225;
  }
`;
