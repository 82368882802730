import { StyledLoader } from 'components/StyledUi/elements/StyledLoader';
import { StyledUiContainerProps } from 'components/StyledUi';
import React, { ReactNode, useMemo } from 'react';
import { DataLoaderContainer } from './DataLoader.elements';
import { DataLoaderIcons } from './DataLoaderIcons';

export interface DataLoaderProps extends StyledUiContainerProps {
  isLoading?: boolean;
  error?: Record<string, unknown>;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  children?: ReactNode | ReactNode[];
  isFetching?: boolean;
  Component?: JSX.Element | ReactNode;
  type?: 'dots' | 'dashes' | 'squares' | 'no alerms' | 'no alerts' | string;
  noData?: boolean;
  Skeleton?: JSX.Element;
}

export const DataLoader = ({
  type,
  isLoading,
  error,
  hasError,
  hasMessage,
  className,
  Component,
  children,
  Skeleton,
  noData,
  ...rest
}: DataLoaderProps): JSX.Element => {
  className = className ? `${className} data-loader` : `data-loader`;
  // add modifiers

  if (children) return <>{children}</>;
  let icon = DataLoaderIcons[type || 'no data'];
  let message: string | undefined = noData ? 'nothing to show yet' : undefined;

  if (type) className += ` type--${type}`;

  const Load = useMemo(() => {
    if (hasMessage || noData) {
      if (noData) className += ' no-data';
      className += ' has-message';
      type = type || 'no data';
      if (typeof hasMessage === 'string') {
        message = hasMessage;
      } else if (typeof hasMessage === 'boolean') {
        message = 'nothing to show yet';
      } else {
        // return early if hasMessage is a component
        return <>{hasMessage}</>;
      }

      icon = DataLoaderIcons[type];
      return (
        <>
          {icon}
          <div className="loader-message">{message}</div>
        </>
      );
    } else if (hasError || error) {
      className += ' has-error';
      type = type || 'hasError';

      if (typeof hasError === 'string') {
        message = hasError;
      } else if (typeof hasError === 'boolean') {
        message = 'Internal error, please try again';
      } else {
        // return early because we're given a component
        return hasError;
      }

      icon = DataLoaderIcons[type];
      return (
        <>
          {icon}
          <div className="loader-message">{message}</div>
        </>
      );
    }

    if (isLoading) {
      className += ' is-loading';
      let Loader = <StyledLoader />;
      if (type === 'dots') Loader = <>. . .</>;
      if (type === 'dashes') Loader = <>- - -</>;
      if (type === 'squares')
        Loader = (
          <div className="squares-loader">
            <div className="square blue grid-1"></div>
            <div className="square purple grid-2"></div>
            <div className="square purple grid-3"></div>
            <div className="square blue grid-4"></div>
          </div>
        );
      return Skeleton || Loader;
    }

    // it's preffered you use Component as it won't trigger the child until the loading is done.
    // a child will start loading right away
    return undefined;
  }, [isLoading, error, hasError, hasMessage, noData]);

  return Load ? (
    <DataLoaderContainer className={className} {...rest}>
      {Load}
    </DataLoaderContainer>
  ) : (
    Component || <>{children}</>
  );
};
