import styled from 'styled-components';

export const PieChartWidgetMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  flex-wrap: wrap;
  overflow: visible;

  .pie-chart-widget__sub-cells {
    display: flex;
    gap: 0.5em;
    padding: 0.5em;
    width: 100%;

    .cell-value {
      flex-grow: 1;
    }
  }

  &: .is-loading {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PieChartWidgetContainer = styled.div`
  position: relative;
  padding: 0.5em;
  display: grid !important;
  grid-gap: 0.5em;

  &:not(.pie-chart-widget-main--progress-chart) {
    .d3-pie-chart-wrapper svg.base-pie-chart {
      path.is-not-selected {
        opacity: 0.5;
        transition: all 0.5s;
      }
    }
  }
  .d3-pie-chart-wrapper {
    position: relative;
    grid-column: 1;
    grid-row: 1;
  }

  .pie-chart-widget__inner-area {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    grid-column: 1;
    grid-row: 1;

    .cell-value {
      opacity: 0.85;
      min-width: max-content;
    }

    .cell-value__value {
      font-weight: 800;
      font-size: 1.7em;
    }
  }
`;
