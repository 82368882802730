// 3rd party libs
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { AdminCardEditButton, DataRenderer } from '..';
import { KPIOverTimeGraph, MachineModesGraphWidget, PermissionWrapper } from 'components';
import Tooltip from 'rc-tooltip';

// Types
import {
  ProteinMachineRouteQueryParams,
  KeyIndicatorHistory,
  MachineHealthSubTabs
} from 'types/protein';
import { Series } from 'types/graph';
import { DateTuple, Role, UserScopes } from 'types';

// Helpers
import { getYaxisOffset } from 'helpers/graph';
import { toISO8601 } from 'helpers';

// Utils
import { axisH, axisV, formatLineSeriesTooltip, toLineSeries, toScatterSeries } from './utils';
import {
  StateProtein,
  mappedStateProtein
} from '../../MachineStatus/utils/BUsMachineStatusMapping';

// Api
import { useGetConfiguredWidgetQuery, useGetMachineStatesCategoriesQuery } from 'api';

// Providers
import { useLanguage, useSyncZoom, useTimeZone } from 'providers';
import { SettingIcon } from 'icons/SettingIcon';
import { PermissionScopeName } from 'types/user-management';
import {
  Container,
  EditButtonContainer,
  IconButton,
  MachineStatesContainer
} from './index.elements';

const MachineIndicatorsGraph = (): JSX.Element => {
  const { timeZone } = useTimeZone();
  const { machineId } = useParams<ProteinMachineRouteQueryParams>();
  const { t } = useTranslation(['mh']);
  const { zoomedDomain, onBrushDomainChangeEnd, resetZoom, undoZoom } = useSyncZoom();
  const [isEditAdminPopupOpen, setIsEditAdminPopupOpen] = useState<boolean>(false);
  const startDatetime = useMemo(() => moment().subtract(13, 'days').endOf('day').toDate(), []);
  const endDatetime = useMemo(() => moment().toDate(), []);

  const localStartDatetimeString = toISO8601(startDatetime, timeZone);
  const localEndDatetimeString = toISO8601(endDatetime, timeZone);
  const { languageId } = useLanguage();

  const { data, isLoading, error } = useGetConfiguredWidgetQuery({
    machineId,
    labels: [MachineHealthSubTabs.MHKeyIndicators],
    includeTagValues: true,
    startDatetime: toISO8601(startDatetime, timeZone),
    endDatetime: toISO8601(endDatetime, timeZone),
    languageId: languageId
  });

  const widgetData = data && data[0];

  const { data: states, isLoading: statesLoading } = useGetMachineStatesCategoriesQuery({
    machineId,
    startDatetime: localStartDatetimeString,
    endDatetime: localEndDatetimeString
  });

  const mappedProteinState: StateProtein = mappedStateProtein(t);
  const newStates = states?.map((state) => ({
    endTimestamp: state.endTimestamp,
    startTimestamp: state.timestamp,
    stateCode:
      state.value === 10
        ? '0'
        : state.value === 40
        ? '1'
        : state.value === 41
        ? '2'
        : state.value === 50
        ? '4'
        : '5',
    stateName: mappedProteinState[state.name]
  }));

  const linesSeries: Series[] = useMemo(() => {
    /**
     * Transform data into graph input format
     * First, convert BaseTag type into KeyIndicatorHistory type, so data can be processed by existing helpers.
     */
    const indicators: KeyIndicatorHistory[] = (widgetData?.tags ?? []).map(
      (baseTag) =>
        ({
          unit: baseTag.unit as string,
          tagId: baseTag.id,
          values: baseTag.values,
          name: baseTag.name
        } as KeyIndicatorHistory)
    );

    return toLineSeries(indicators);
  }, [widgetData]);

  /**
   * Process data
   */
  const scatterSeries: Series = useMemo(() => {
    const { min } = getYaxisOffset(linesSeries);
    return toScatterSeries(states || [], Math.round(min), timeZone);
  }, [linesSeries, states, timeZone]);

  const series: Series[] = useMemo(
    () => [...formatLineSeriesTooltip(linesSeries, timeZone)],
    [linesSeries, scatterSeries, states, timeZone]
  );

  const xDomain: DateTuple = useMemo(
    () => [startDatetime, endDatetime],
    [startDatetime, endDatetime]
  );

  return (
    <>
      <DataRenderer
        isLoading={isLoading}
        error={error && (t('failed_to_load_key_indicators_history') as string)}
      >
        <Container>
          <KPIOverTimeGraph
            title={t('key_indicators_history') as string}
            graphContainerHeight="18.3125rem"
            series={series}
            axisH={axisH(timeZone)}
            axisV={axisV()}
            displayZeroLine={true}
            xDomain={xDomain}
            sync={true}
            brush={{
              zoomedDomain,
              onBrushDomainChangeEnd,
              resetZoom,
              undoZoom
            }}
          />

          <EditButtonContainer>
            <Tooltip placement="top" overlay={'Edit'}>
              <IconButton onClick={() => setIsEditAdminPopupOpen(true)}>
                <SettingIcon />
              </IconButton>
            </Tooltip>
          </EditButtonContainer>
          {widgetData?.id && (
            <PermissionWrapper
              page={PermissionScopeName.FLEET}
              scope={UserScopes.Write}
              role={Role.Admin}
            >
              <EditButtonContainer>
                <AdminCardEditButton
                  widgetId={widgetData.id}
                  machineId={machineId}
                  isEditAdminPopupOpen={isEditAdminPopupOpen}
                  setIsEditAdminPopupOpen={setIsEditAdminPopupOpen}
                />
              </EditButtonContainer>
            </PermissionWrapper>
          )}
        </Container>
      </DataRenderer>
      <MachineStatesContainer>
        <MachineModesGraphWidget
          isLoading={statesLoading}
          intervalSpacing={false}
          title={t('machine_state') as string}
          data={[
            {
              id: 1,
              label: t('state_timeline') as string,
              parentProperty: '1',
              data: newStates ?? []
            }
          ]}
        />
      </MachineStatesContainer>
    </>
  );
};

export default MachineIndicatorsGraph;
