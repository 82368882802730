import React from 'react';

export interface Props {
  color?: string;
}

export const ActiveRecipeIcon = ({ color }: Props): JSX.Element => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28.7026" height="28.7026" rx="14.3513" fill={color || '#0076CC'} />
      <g clipPath="url(#clip0_2394_30449)">
        <path
          d="M7.11157 9C7.11157 9.66304 7.37496 10.2989 7.84381 10.7678C8.31265 11.2366 8.94853 11.5 9.61157 11.5C10.2746 11.5 10.9105 11.2366 11.3793 10.7678C11.8482 10.2989 12.1116 9.66304 12.1116 9C12.1116 8.33696 11.8482 7.70107 11.3793 7.23223C10.9105 6.76339 10.2746 6.5 9.61157 6.5C8.94853 6.5 8.31265 6.76339 7.84381 7.23223C7.37496 7.70107 7.11157 8.33696 7.11157 9Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.1116 13.1667V10.6667C22.1116 10.2246 21.936 9.80072 21.6235 9.48816C21.3109 9.17559 20.887 9 20.4449 9H15.4449M15.4449 9L17.9449 11.5M15.4449 9L17.9449 6.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.11157 14.8333V17.3333C7.11157 17.7753 7.28717 18.1993 7.59973 18.5118C7.91229 18.8244 8.33621 19 8.77824 19H13.7782M13.7782 19L11.2782 16.5M13.7782 19L11.2782 21.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.1116 19C17.1116 19.663 17.375 20.2989 17.8438 20.7678C18.3126 21.2366 18.9485 21.5 19.6116 21.5C20.2746 21.5 20.9105 21.2366 21.3793 20.7678C21.8482 20.2989 22.1116 19.663 22.1116 19C22.1116 18.337 21.8482 17.7011 21.3793 17.2322C20.9105 16.7634 20.2746 16.5 19.6116 16.5C18.9485 16.5 18.3126 16.7634 17.8438 17.2322C17.375 17.7011 17.1116 18.337 17.1116 19Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2394_30449">
          <rect width="20" height="20" fill="white" transform="translate(4.61157 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};
