import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface ObjProps {
  [n: string]: string;
}

const formats: ObjProps = {
  short: 'll',
  numbers: 'LL'
};

interface Props {
  startTime?: Date;
  endTime?: Date;
  from?: Date;
  to?: Date;
  format?: string;
  useForDisplay?: boolean;
}

export const timeDisplayFormatter = ({
  startTime,
  endTime,
  to,
  from,
  format,
  useForDisplay
}: Props): string => {
  const today = moment();
  const end = moment(endTime || to);
  const { t } = useTranslation(['mh']);
  const duration = moment.duration(moment(endTime || to).diff(moment(startTime || from)));
  const endsToday = moment(end).isSame(today, 'day');
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const formatStart = moment(startTime || from).format(formats[format || 'short']);
  const formatEnd = moment(endTime || to).format(formats[format || 'short']);

  let ret = `${formatStart}${days > 1 ? ` - ${formatEnd}` : ``}`;

  if (useForDisplay && endsToday) {
    switch (true) {
      case days < 1 && duration.hours() > 0:
        ret =
          duration.minutes() > 30
            ? `${t('last')} ${hours + 1} ${t('hours')}`
            : `${t('last')} ${hours} ${t('hours')}`;
        break;
      case days < 1 && duration.hours() === 0:
        ret = `${t('last')} ${minutes} ${t('minutes')}`;
        break;
      case days < 2:
        ret = t('last_24_hours');
        break;
      case days < 8:
        ret = `${t('last')} ${days} ${t('days')}`;
        break;
      default:
        break;
    }
  }

  return ret;
};
