const colors: Record<string, string> = {
  main: '#323130', //same as grey800, use grey800
  mainLight: '#323130', //same as grey800, use grey800
  neg: 'white',
  primary: '#303E47',
  primaryAlt: '#303E47',
  secondaryLight: `#F1F7FF`,
  secondary: '#0A70FF',
  secondaryAlt: '#0A70FF',
  darken: 'rgba(0,0,0,.25)',
  dark: 'rgba(0,0,0,.5)',
  darker: 'rgba(0,0,0,.65)',
  darkest: 'rgba(0,0,0,.8)',
  grayDark: `#323130`, //same as grey800, grey800
  gray: `#828285`,
  grayLightest: `#F1F3F4`,
  grayLight: `#B2B2B2`,
  gray200: '#F2F3F4', //in UI color palette
  gray300: '#E5E9ED', //in UI color palette
  gray400: '#C8C8C8', //in UI color palette
  gray500: '#A3A3A3', //in UI color palette
  gray600: '#828485', //in UI color palette
  gray700: '#666666', //in UI color palette
  gray800: '#323130', //in UI color palette
  caution100: '#DA7029',
  black: `#323130`,
  success200: `#008200`, //in UI color palette
  error200: `#B62C10`, //in UI color palette
  green: `#008200`,
  red: `#B62C10`,
  orange: '#DA7029'
};

const chartUiColors: Record<string, string> = {
  gridLine: colors.gray300,
  gridLineHover: colors.error200,
  tickLine: colors.gray300,
  tickLabel: colors.main
};

const textColors: Record<string, string> = {
  label: colors.gray700,
  success: colors.success200,
  error: colors.error200,
  warning: colors.caution100,
  title: 'black',
  muted: colors.gray400,
  unknown: colors.gray500,
  /** dark gray, this is used under or next to a title color or as a title inside a widget (aside from widget title) */
  subTitle: colors.mainLight
};

//You can find these colors in colorsPallete.png #6
const JBTColors: Record<string, string> = {
  mainColor: '#0076CC',
  mainHover: '#005FAA',
  mainSelected: '#004577',
  mainDisabled: '#C8C8C8'
};

const telemetryColors: Record<string, string> = {
  Lila: '#835DD0',
  jbtBlue: '#0076CC',
  telemetry2100: '#8A8886',
  orange1: '#E66C37',
  fushia: '#C83D95'
};

//You can find these colors in colorsPallete.png #1
export const chartColors: Record<string, string> = {
  darkBlue: '#005FAA',
  Telemetry100: '#0E00B3',
  Telemetry200: '#3A4BC6',
  Telemetry300: '#0076CC',
  Telemetry400: '#126CFF',
  Telemetry500: '#2E8FFF',
  Telemetry600: '#708BBD',
  Telemetry700: '#750985',
  Telemetry800: telemetryColors.Lila,
  Telemetry900: '#8886F4',
  Telemetry1000: '#18A03C',
  Telemetry1100: '#779622',
  Telemetry1200: '#49986C',
  Telemetry1300: '#FF1A1A',
  Telemetry1400: '#DC3240',
  Telemetry1500: '#A05B32',
  Telemetry1600: '#BD8608',
  Telemetry1700: telemetryColors.orange1,
  Telemetry1800: '#009EB3',
  Telemetry1900: '#B915D5',
  Telemetry2000: telemetryColors.fushia,
  Telemetry2100: telemetryColors.telemetry2100,
  Telemetry2200: '#B3B3B3',
  Telemetry2300: '#FCD641'
};

const statusColors = {
  running: colors.success200,
  notRunning: telemetryColors.telemetry2100,
  offline: colors.error200
};

const border: Record<string, string> = {
  main: '#c2c2c6',
  light: colors.gray400,
  lightest: colors.gray300,
  blue: '#0076CC'
};

const bg: Record<string, string> = {
  lightest: colors.gray300,
  lighter: colors.gray400,
  negLightest: colors.gray200,
  negLighter: '#F1F3F4'
};

const headline: Record<string, string> = {
  primary: colors.primary
};

const status = {
  error: {
    lighter: '#FFEFEC',
    light: '#FFBCB2',
    base: '#EF4444'
  },
  warning: {
    lighter: '#FFEFEC',
    light: '#FFC9A2',
    base: '#DA7029'
  },
  success: {
    lighter: '#EDF8F2',
    light: '#B5E4CB',
    base: '#28B981'
  }
};

export default {
  border,
  bg,
  headline,
  status,
  ...statusColors,
  ...telemetryColors,
  ...colors,
  ...textColors,
  ...chartColors,
  ...chartUiColors,
  ...JBTColors
};
