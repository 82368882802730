// 3rd party libs
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { DataRenderer } from 'components/machine-health';

// Types
import { MachineHealthTabs, TopLevelTabs } from 'types/protein';

// Hooks
import { useConfiguredSubNav, usePermissions } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { themeColors } from 'themes';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NavItem, NavMenu, Tab } from './tab.elements';

interface Props {
  parentSection?: MachineHealthTabs;
  baseUrl: string;
  labels?: TopLevelTabs[];
  slugMap?: Record<string, string>;
  tabMember?: boolean;
  showMe?: boolean;
}

/**
 * Renders configured navigation tabs.
 */
const ConfiguredSubNav = ({
  baseUrl,
  labels,
  slugMap,
  parentSection,
  tabMember
}: Props): JSX.Element => {
  const history = useHistory();
  const { scopes } = usePermissions();
  const { t } = useTranslation(['mh']);
  const { isLoading, error, tabs } = useConfiguredSubNav(parentSection, labels, slugMap);
  const location = useLocation();

  const userHaveAlertsPermission = useMemo(() => {
    const alertScope = scopes.find((scope) => {
      return scope.name === 'Alert Management';
    });
    return alertScope?.read || false;
  }, [scopes]);
  const isActive = (subRoute: string) => location.pathname.includes(`${baseUrl}/${subRoute}`);
  return (
    <>
      <DataRenderer width="auto" isLoading={isLoading} error={error && 'Failed to load sub pages'}>
        <NavItem className="nav-item">
          <NavMenu className="nav-menu">
            {tabs
              ?.filter((tab) => {
                if (!userHaveAlertsPermission) {
                  return tab.name !== 'Alerts';
                }
                return tab;
              })
              ?.map((tab, index) => {
                const showName = (tab.label?.split('_').length as number) >= 2 ? true : false;
                return (
                  <>
                    <li className="nav__menu-item">
                      <Tab
                        className="nav-menu__tab"
                        onClick={() => {
                          return history.push(`${baseUrl}/${tab.slug}`);
                        }}
                        key={`${tab.id}-${index}`}
                        $isSelected={isActive(tab.slug)}
                      >
                        <span>{showName ? t(tab.name as string) : t(tab.label as string)}</span>
                        {tab?.members && !tabMember ? (
                          <span>
                            <FontAwesomeIcon
                              className="icon_arrow"
                              color={
                                isActive(tab.slug)
                                  ? themeColors.primaryBlue5
                                  : themeColors.lightGrey8
                              }
                              icon={isActive(tab.slug) ? faChevronUp : faChevronDown}
                            />
                          </span>
                        ) : null}
                      </Tab>
                    </li>
                  </>
                );
              })}
          </NavMenu>
        </NavItem>
      </DataRenderer>
    </>
  );
};
export default ConfiguredSubNav;
