import React from 'react';
import type { FC, SVGProps } from 'react';

const IcoSuccess: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.76489 12L11.7649 14L15.7649 10M21.7649 12C21.7649 16.9706 17.7355 21 12.7649 21C7.79433 21 3.76489 16.9706 3.76489 12C3.76489 7.02944 7.79433 3 12.7649 3C17.7355 3 21.7649 7.02944 21.7649 12Z"
      stroke={props.color ? props.color : '#008200'}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcoSuccess;
