import React from 'react';
import { UiWidget as Ui } from '../';
import { FrameIcon } from 'icons/FrameIcon';
import { CalendarIcon } from 'icons/CalendarIcon';
import { SettingIcon } from 'icons/SettingIcon';
import { useHistory } from 'react-router-dom';
import { useGetMachineConfiguratorDataQuery } from 'api';
import { MachinePerformanceTabs } from 'types/protein';
import { useLanguage } from 'providers';

interface dropdownProps {
  showMenu?: boolean;
  setShowMenuOptions?: (data: boolean) => void;
  setIsEditAdminPopupOpen?: (data: boolean) => void;
  handleToggleCalendar?: () => void;
  dateRange?: string;
  machineId?: string;
}

const DropdownMenu = ({
  showMenu,
  setShowMenuOptions,
  setIsEditAdminPopupOpen,
  handleToggleCalendar,
  dateRange,
  machineId
}: dropdownProps): JSX.Element => {
  const history = useHistory();
  const { languageId } = useLanguage();
  const { data, isFetching: fetching } = useGetMachineConfiguratorDataQuery({
    machineId: machineId || '',
    labels: [MachinePerformanceTabs.Consumables],
    languageId: languageId,
    showInactive: false
  });

  return (
    <>
      {showMenu && (
        <div>
          <Ui.MenuContainer>
            <Ui.MenuItem
              onClick={() => {
                setShowMenuOptions && setShowMenuOptions(!showMenu), handleToggleCalendar?.();
              }}
            >
              <Ui.MenuItemIcon>
                <CalendarIcon />
              </Ui.MenuItemIcon>
              <Ui.MenuItemText>{dateRange || 'Date Picker'}</Ui.MenuItemText>
            </Ui.MenuItem>
            <Ui.MenuItem
              isDisabled={data?.length && !fetching ? false : true}
              onClick={() => {
                setShowMenuOptions && setShowMenuOptions(!showMenu),
                  data?.length &&
                    !fetching &&
                    history.push(`/fleet/machine/${machineId}/machine-performance/consumables`);
              }}
            >
              <Ui.MenuItemIcon>
                <FrameIcon />
              </Ui.MenuItemIcon>
              <Ui.MenuItemText>{'Detailed View'}</Ui.MenuItemText>
            </Ui.MenuItem>
            <Ui.MenuItem
              onClick={() => {
                setShowMenuOptions && setShowMenuOptions(!showMenu),
                  setIsEditAdminPopupOpen && setIsEditAdminPopupOpen(true);
              }}
            >
              <Ui.MenuItemIcon>
                <SettingIcon />
              </Ui.MenuItemIcon>
              <Ui.MenuItemText>{'Customize'}</Ui.MenuItemText>
            </Ui.MenuItem>
          </Ui.MenuContainer>
        </div>
      )}
    </>
  );
};

export default DropdownMenu;
