import React, { useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { IconDefinition, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { Typography } from 'components';
import { IcoPdf } from 'icons/IcoPdf';
import { IcoTrash } from 'icons/IcoTrash';
import { IcoCsv } from 'icons/IcoCsv';

interface DropAreaProps {
  isDragAccept: boolean;
  theme: DefaultTheme;
}

const DropArea = styled.div<DropAreaProps>`
  width: 100%;
  border: dashed;
  border-width: 0.0625rem;
  border-radius: 0.5rem;
  padding: 0.5rem;

  background-color: ${(props) => (props.isDragAccept ? 'rgba(200, 120, 204, 0.1)' : 'transparent')};
  border-color: ${(props) =>
    props.isDragAccept ? 'rgba(200, 120, 204, 0.5)' : props.theme.colors.mediumGrey2};
`;

const VContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const CloudIcon = styled.div`
  margin-bottom: 0.625rem;
  margin-top: 0.5rem;
`;

const InlineLink = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.mediumBlue};
  cursor: pointer;
`;
export const FileContainer = styled.div`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.primaryBlue5};
  border-radius: 0.8rem;
  padding: 1.3rem 0.75rem 0rem;
  max-height: 18rem;
  margin-bottom: 1rem;
  overflow: auto;
`;
export const FileCount = styled.div`
  position: absolute;
  top: -1rem;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlue5};
  padding: 0.3rem;
  border-radius: 0.7rem;
  left: 1rem;
  z-index: 2;
`;
export const FileList = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  margin: 0.3rem 0;
`;
const FileArea = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.625rem;
  background-color: ${({ theme }) => theme.colors.lightGrey2};
  padding: 0.5rem;
  text-align: left;
  flex-direction: row;
  column-gap: 0.625rem;
`;
const Text = styled.p`
    font-size: .8rem;
    font-weight: 600;
    margin: 0;
}
`;
const Spacer = styled.div`
  flex-grow: 1;
  height: 100%;
`;

interface OnboardingDropAreaProps {
  acceptedTypes: Accept;
  files: File[] | null | undefined;
  onFileChange: (file: File[] | null) => void;
  icon?: IconDefinition;
  onboarding?: boolean;
  maxFiles?: number;
}

export default function OnboardingMultipleFilesDropArea({
  acceptedTypes,
  files,
  onFileChange,
  icon,
  onboarding,
  maxFiles
}: OnboardingDropAreaProps): JSX.Element {
  const theme = useTheme();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileChange(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    onDrop: onDrop,
    noClick: true,
    accept: acceptedTypes,
    multiple: true,
    maxFiles
  });

  const pdfExtenstion = ['application/pdf'];

  return (
    <VContainer>
      {files && !onboarding ? (
        files?.map((file, fileIndex) => (
          <FileArea key={fileIndex}>
            <FontAwesomeIcon color={theme.colors.darkGrey} icon={faFile} />
            <Typography as="span" variant="stepheading">
              {file.name}
            </Typography>
            <Spacer />
            <Typography variant="stepheading">
              <InlineLink onClick={() => onFileChange(null)}>Remove</InlineLink>
            </Typography>
          </FileArea>
        ))
      ) : onboarding && files?.length ? (
        <FileContainer>
          <FileCount>
            <Typography color={theme.colors.black} as="span" weight="semi-bold">
              {files?.length} {files?.length > 1 ? 'files' : 'file'}
            </Typography>
          </FileCount>
          {files.map((file) => (
            <FileList key={file.name}>
              {pdfExtenstion.includes(file.type) ? <IcoPdf width="35" /> : <IcoCsv width="40" />}
              <Text color={theme.colors.black}>{file.name}</Text>
              <Spacer />
              <Typography variant="stepheading">
                <InlineLink
                  onClick={() => {
                    const remaining = files.filter((files) => {
                      return files.name !== file.name && files.size !== file.size;
                    });
                    onFileChange(remaining);
                  }}
                >
                  <IcoTrash width="24" />
                </InlineLink>
              </Typography>
            </FileList>
          ))}
        </FileContainer>
      ) : (
        <DropArea {...getRootProps()} isDragAccept={isDragAccept} theme={theme}>
          <input {...getInputProps()} />
          <VContainer>
            <CloudIcon>
              {icon && <FontAwesomeIcon color={theme.colors.darkGrey} icon={icon} size="lg" />}
            </CloudIcon>
            <Typography variant="body1">
              <InlineLink onClick={open}>Click to upload</InlineLink> or drag and drop
            </Typography>
          </VContainer>
        </DropArea>
      )}
    </VContainer>
  );
}
