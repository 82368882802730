// Third Party
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

// Components
import { Loader, Typography } from 'components';
import { WidgetTable } from 'components/machine-health';

// API
import { useGetMachineConfiguratorDataQuery } from 'api';

// Types
import { WidgetType, MachinePerformanceSubTabs } from 'types/protein';
import { WidgetTableDataItem } from 'types/machine-health';

// Constants
import { WidgetTableColumnConfigs } from 'constants/machineConfig';

// Providers
import { useLanguage } from 'providers';

// Styling
const Container = styled.div<{ removePadding?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ removePadding }) => (removePadding ? '0' : '0 6rem')};
  width: 100%;
`;

const WidgetTableContainer = styled.div`
  display: flex;
  gap: 1.375rem;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 1.375rem;
  width: 100%;
`;

interface Props {
  copyWidgetType?: WidgetType;
  handleCopyCallback?: (item: WidgetTableDataItem) => void;
  hasWidgets?: boolean;
  hideButtonRow?: boolean;
  isCopyTab?: boolean;
  isDirty: boolean;
  overrideMachineId?: string;
  removePadding?: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const MachinePerformance = ({
  copyWidgetType,
  handleCopyCallback,
  isCopyTab,
  overrideMachineId,
  removePadding,
  setIsDirty
}: Props): ReactElement => {
  const { machineId } = useParams<{ machineId: string }>();
  const { languageId } = useLanguage();
  const {
    data,
    isFetching: fetching,
    error
  } = useGetMachineConfiguratorDataQuery({
    machineId: overrideMachineId || machineId,
    labels: [
      MachinePerformanceSubTabs.Current,
      MachinePerformanceSubTabs.HistoricRecipes,
      MachinePerformanceSubTabs.ConsumablesMain
    ],
    languageId: languageId,
    showInactive: true
  });
  const [tabData, setTabData] = useState<WidgetTableDataItem[] | undefined>(data);

  useEffect(() => {
    setTabData(data);
  }, [data]);

  useEffect(() => {
    setIsDirty(false);
  }, []);

  const isFetching = fetching;
  const isError = error;

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    console.error(isError);
    <Typography color="negativeRed">Failed to load data</Typography>;
  }

  return (
    <Container removePadding={removePadding}>
      <WidgetTableContainer>
        {tabData?.length ? (
          tabData.map((widgetTable) => (
            <WidgetTable
              columnConfigs={WidgetTableColumnConfigs}
              copyTable={{
                copyWidgetType,
                handleCopyCallback,
                isCopyTable: isCopyTab
              }}
              data={widgetTable.members}
              isDataLoading={isFetching}
              key={widgetTable.id}
              parent={{
                ...widgetTable,
                // Do not allow the table to be editable here, as this is a duplication of the logic elsewhere in the page
                editable: false,
                toggleActive: false
              }}
              setIsDirty={setIsDirty}
              shouldRenderTags={false}
            />
          ))
        ) : (
          <Typography color="greyfont">No data found</Typography>
        )}
      </WidgetTableContainer>
    </Container>
  );
};

export default MachinePerformance;
