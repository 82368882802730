// 3rd party
import React, { ReactElement } from 'react';

// Components
import CartListComponent from 'components/CartListComponent';
interface Props {
  handleClose?: () => void;
  isCartEmpty?: (status?: boolean) => void;
}

const CartStepOne = ({ handleClose, isCartEmpty }: Props): ReactElement => {
  return (
    <CartListComponent
      isCartEmpty={isCartEmpty}
      handleClose={handleClose}
      viewType="default"
      selectionType="remove"
    />
  );
};

export default CartStepOne;
