import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'themes';
import { ActionItem } from '../Scope';
import { AddPlusIcon } from 'icons/AddPlusIcon';
import {
  IdType,
  User,
  UserFormElement,
  UserFormParams,
  UserFormTypes
} from 'types/user-management';
import { v4 as uuidv4 } from 'uuid';
import UserForm from 'pages/UserManagementTwo/components/User/AddEditUser/UserForm';
import Collapse from 'rc-collapse';
import { HeaderWrapper } from '..';
import {
  MenuPanel,
  expandIcon
} from 'pages/MaintenanceServiceDashboard/MaintenanceQueryTableByFrequency';
import { useDeleteUserV2Mutation, useUpdateGroupUserMutation } from 'api';
import { ToastMsg } from 'common/components/Toast/Toast';
import { IcoTrash } from 'icons/IcoTrash';

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 0.063rem solid ${theme.colors.lightGrey4};
  margin-bottom: 1.063rem;
  margin-top: 1rem;
`;
const NoUser = styled.div`
  text-align center;
  align-self: center;
  width: 4rem;
  font-family: Roboto;
  color: ${theme.colors.mediumGrey4};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1.688rem;
  margin-top: 1.688rem;
`;
export const ChildHeader = styled.div`
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 1rem;
`;
const FormHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`;
const HeaderText = styled.div`
  color: ${({ theme }) => theme.colors.text.lightBlack};
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const HeaderIcon = styled.button`
  margin-left: auto;
  border: none;
  background: transparent;
  cursor: pointer;
`;
const UserFormWrapper = styled.div``;
const newUser: User = {
  firstName: '',
  lastName: '',
  email: '',
  emailAlert: false,
  textAlert: false,
  phone: ''
};
interface props {
  groupId?: IdType | string;
  groupUsers: UserFormElement[];
  setGroupUsers: (UserFormElements: UserFormElement[]) => void;
}
const GroupUsers = ({ groupId, groupUsers, setGroupUsers }: props): JSX.Element => {
  const defaultUserFormElement: UserFormElement = {
    user: newUser,
    validationState: {},
    id: uuidv4()
  };
  const [deleteUser] = useDeleteUserV2Mutation();
  const [updateEditedUser] = useUpdateGroupUserMutation();
  const updateGroupUserList = (
    user: UserFormElement | UserFormTypes,
    isEditMode?: boolean
  ): void => {
    if (isEditMode) {
      const updatedUsers = groupUsers.map((u) => {
        if (u.id === user.id) {
          u = { ...user } as UserFormElement;
        }
        return u;
      });
      handleUserEdit(user as UserFormElement, updatedUsers);
    } else {
      setGroupUsers([...groupUsers, user] as UserFormElement[]);
      setShowUserForm(false);
    }
  };
  const handleUserEdit = (form: UserFormElement, updatedUsers: UserFormElement[]): void => {
    const userParam: UserFormParams = {
      email: form.user.email,
      emailAlert: form.user.emailAlert,
      firstName: form.user.firstName,
      lastName: form.user.lastName,
      id: form.user.id,
      phone: form.user.phone,
      textAlert: form.user.textAlert
    };

    updateEditedUser({ ...userParam })
      .unwrap()
      .then(() => {
        ToastMsg({
          heading: 'Success',
          message: 'User updated successfully!',
          type: 'success',
          position: 'top-center'
        });
        setGroupUsers([...updatedUsers] as UserFormElement[]);
      })
      .catch((err) => {
        console.log('user update error: ', err);
        ToastMsg({
          heading: 'Error',
          message: 'Failed to update user.',
          type: 'error',
          position: 'top-center'
        });
      });
  };
  const getUserPanelKeys = (): string[] => {
    const keys: string[] = [];
    groupUsers.forEach((panel) => {
      keys.push(panel.id as string);
    });
    return keys;
  };
  const onCancelUserAdd = (isEditMode: boolean, userId?: string): void => {
    if (!isEditMode) {
      setShowUserForm(false);
    } else {
      const validUsers = groupUsers.filter((e) => e.user.id !== userId);
      deleteUser([{ id: userId, groups: [{ id: groupId }] as IdType[] }])
        .unwrap()
        .then(() => {
          ToastMsg({
            heading: 'Success',
            message: 'User have been successfully deleted.',
            type: 'success',
            position: 'top-center'
          });
          setGroupUsers([...validUsers]);
        });
    }
  };
  const [showUserForm, setShowUserForm] = useState<boolean>(false);
  const [activePanelKeys, setActivePanelKeys] = useState<React.Key[]>(getUserPanelKeys());
  return (
    <>
      <UserContainer>
        {groupUsers && groupUsers.length > 0 ? (
          <>
            <Collapse
              activeKey={activePanelKeys}
              onChange={(key: React.Key | React.Key[]) => {
                setActivePanelKeys(Array.isArray(key) ? key : [key]);
              }}
              destroyInactivePanel={true}
              expandIcon={expandIcon}
              accordion={true}
            >
              {groupUsers.map((panel) => {
                const header = (
                  <HeaderWrapper>
                    <ChildHeader>
                      {panel.user.firstName}&nbsp;{panel.user.lastName}
                    </ChildHeader>
                    <FormHeader>
                      <HeaderIcon
                        onClick={() => {
                          onCancelUserAdd(true, panel.user?.id as string);
                        }}
                        title="Delete User"
                      >
                        <IcoTrash width={'2rem'} />
                      </HeaderIcon>
                    </FormHeader>
                  </HeaderWrapper>
                );
                return (
                  <MenuPanel
                    showArrow={true}
                    collapsible="header"
                    key={panel.id}
                    header={header}
                    mb={0}
                  >
                    {
                      <UserForm
                        userData={panel}
                        onUserSave={updateGroupUserList}
                        isEditMode={true}
                      />
                    }
                  </MenuPanel>
                );
              })}
            </Collapse>
          </>
        ) : (
          <NoUser>No User</NoUser>
        )}
      </UserContainer>
      {!showUserForm ? (
        <ActionItem
          onClick={() => {
            setShowUserForm(true);
          }}
        >
          <>
            <AddPlusIcon /> &nbsp;{' '}
            {groupUsers && groupUsers.length > 0 ? 'Add another user' : 'Add User'}
          </>
        </ActionItem>
      ) : (
        <UserFormWrapper>
          <FormHeader>
            <HeaderText>User &nbsp; {groupUsers && groupUsers.length + 1}</HeaderText>
            <HeaderIcon
              onClick={() => {
                onCancelUserAdd(false);
              }}
              title="Delete User"
            >
              <IcoTrash width={'2rem'} />
            </HeaderIcon>
          </FormHeader>
          <UserForm
            userData={defaultUserFormElement}
            onUserSave={updateGroupUserList}
            isAddGroupUser={true}
            groupUsers={groupUsers}
          />
        </UserFormWrapper>
      )}
    </>
  );
};
export default GroupUsers;
