// colors will be an object who's keys are the formatted string values of the group/category/state names as they appear in the interface.
/**
 *  example colors = {
 *    'production uptime': '#FF0000',
 *    idle: '#00FF00',
 *  }
 */
export const colors: Record<string, string> = {
  overfed: 'rgba(17, 141, 255, 1)',
  'well fed': 'rgba(0, 158, 179, 1)',
  underfed: 'rgba(200, 61, 149, 1)',
  'very underfed': 'rgba(200, 61, 149, 1)'
};
