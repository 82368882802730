// 3rd party
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useWizard } from 'react-use-wizard';
import { useTranslation } from 'react-i18next';

// Types
import { UserScopes, WizardFooterProps } from 'types';
import { CartState, SFCartItem } from 'types/parts/cart';
import { PermissionScopeName } from 'types/user-management';

// Providers
import { useLanguage } from 'providers';

// Constants
import { sfEnabled } from 'constants/featureFlags';
import breakpoint from 'constants/breakpoints';

// Components
import { Button, Typography, PermissionWrapper } from 'components';
import { useCart } from 'selectors';
import { useDispatch } from 'react-redux';
import { cartActions } from 'actions';
import { currency } from 'helpers';
import { generateAnalyticsEvent } from 'helpers/analytics';
import { AnalyticsCategories, ECommerceAnalyticEventActions } from 'constants/analytics';
import { useDeleteCartProductsMutation } from 'api';
import { IconTextBtn } from 'components/RibbonComponent';
import { IcoDownload } from 'icons/IcoDownload';
import theme from 'themes';

// Styling
const Footer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
  padding: 1rem;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-top: 2px solid ${theme.colors.lightGrey3};

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${breakpoint.size.md}px) {
    // padding: 1rem;
    flex-direction: column;
    align-items: center;
  }

  .icon-width {
    width: 30px;
  }
`;

const DLContainer = styled.div`
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  @media (max-width: ${breakpoint.size.md}px) {
    width: 100%;
    margin-bottom: 1rem;
    align-items: right;
  }
`;
const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  @media (max-width: ${breakpoint.size.md}px) {
    width: 100%;
    justify-content: right;
  }
`;

const Spacer = styled.div`
  // flex: 0 0 1rem;
`;

const NextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BackContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TotalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  line-height: 0.5rem;
  margin-left: 1rem;
`;

const GrandTotalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DownloadCSVContainer = styled.div`
  display: flex;
  // align-items: center;
  flex: 1;
`;

const DraftPOContainer = styled.div`
  display: flex;
  // align-items: center;
  flex: 1;
`;

const GrandTotalPriceContainer = styled.div`
  display: flex;
  line-height: 1.2rem;
`;
interface Props {
  stepFooters: WizardFooterProps[];
  onDownloadDraftPO?: () => void;
}

const CartFooter = ({ stepFooters, onDownloadDraftPO }: Props): ReactElement => {
  const cart = useCart() as CartState;
  const dispatch = useDispatch();
  const { t } = useTranslation(['fpns']);
  const { languageId } = useLanguage();
  const { activeStep, isFirstStep, isLastStep, nextStep, previousStep } = useWizard();
  const currentFooter = stepFooters[activeStep];
  const [deleteCartProducts] = useDeleteCartProductsMutation();
  const handleNext = () => {
    if (currentFooter.nextAction) currentFooter.nextAction();
    nextStep();
  };

  const emptyCart = () => {
    if (sfEnabled()) {
      cart.sfCart.cartItems?.map((sfItem: SFCartItem) => {
        deleteCartProducts({ cartItemId: sfItem.cartItem.cartItemId })
          .unwrap()
          .then(() => {
            dispatch({ type: cartActions.EMPTY_CART });
          });
      });
    } else {
      dispatch({ type: cartActions.EMPTY_CART });
    }
  };
  // Adjust to show by multiple currencies.
  // Customers should not see multiple currencies but, putting this
  // logic in so things do not appear broken for cross-region users (i.e. admins)
  const currencyGroups: Record<string, number> = {};
  cart?.items.forEach((itemGroup) => {
    // For now, assume undefined priceUnit/currency defaults to USD
    // for items that do not have a currency from the
    const priceUnit = itemGroup.item.priceUnit || 'USD';
    const price = !itemGroup.item?.isPurchasable
      ? 0
      : (itemGroup.item?.custPrice ? itemGroup.item.custPrice : itemGroup.item.price || 0) *
        itemGroup.quantity;
    if (price) {
      if (Object.prototype.hasOwnProperty.call(currencyGroups, priceUnit))
        currencyGroups[priceUnit] += price;
      else currencyGroups[priceUnit] = price;
    }
  });
  const grandTotal = Object.keys(currencyGroups)
    .map((priceUnit) => {
      return currency(currencyGroups[priceUnit], priceUnit);
    })
    .join(', ');
  return (
    <Footer>
      <DLContainer>
        {activeStep === 0 && (
          <>
            <DownloadCSVContainer>
              <IconTextBtn
                disabled={cart?.items.length === 0}
                onClick={() => {
                  dispatch({
                    type: cartActions.DOWNLOAD_CART_CSV,
                    t: t,
                    languageId: languageId
                  });
                }}
                gap=".2rem"
              >
                <IcoDownload />
                {t('download_cart_csv')}
              </IconTextBtn>
            </DownloadCSVContainer>
            <DraftPOContainer>
              <IconTextBtn
                disable={cart?.items.length === 0}
                onClick={() => {
                  if (onDownloadDraftPO) {
                    onDownloadDraftPO();
                  }
                }}
              >
                <span className="icon-width">
                  <IcoDownload />
                </span>
                {t('download_draft_order_quote')}
              </IconTextBtn>
            </DraftPOContainer>
          </>
        )}

        {isFirstStep && (
          <TotalContainer>
            <GrandTotalTextContainer>
              <Typography weight="medium" color={theme.colors.lightGrey8} size="1rem">
                {t('grand_total')}
              </Typography>
              <GrandTotalPriceContainer>
                <Typography weight="semi-bold" size="1.2rem">
                  {grandTotal !== '' ? String(grandTotal) : 'N/A'}
                </Typography>
              </GrandTotalPriceContainer>
            </GrandTotalTextContainer>
          </TotalContainer>
        )}
      </DLContainer>

      <Spacer />
      <ButtonContainer>
        {isFirstStep && (
          <Button
            disabled={cart?.items.length === 0}
            variant="outline-blue"
            width="8rem"
            onClick={emptyCart}
          >
            {t('clear_quote')}
          </Button>
        )}
        {!isFirstStep && !isLastStep && (
          <BackContainer>
            <Button
              variant="outline-blue"
              borderColor={theme.colors.primaryBlue5}
              onClick={previousStep}
            >
              {t(currentFooter.prevText as string) || null}
            </Button>
          </BackContainer>
        )}
        <NextContainer>
          <PermissionWrapper scope={UserScopes.Write} page={PermissionScopeName.SHOP}>
            <Button
              disabled={
                currentFooter.continueCondition ? !currentFooter.continueCondition() : false
              }
              variant="filled-primary"
              // width="8rem"
              onClick={() => {
                if (isFirstStep) {
                  generateAnalyticsEvent({
                    category: AnalyticsCategories.ECOMMERCE,
                    action: ECommerceAnalyticEventActions.CHECKOUT_STARTED
                  });
                }
                handleNext();
              }}
            >
              {t(currentFooter.nextText, { ns: ['fpns', 'common'] })}
            </Button>
          </PermissionWrapper>
        </NextContainer>
      </ButtonContainer>
    </Footer>
  );
};

export default CartFooter;
