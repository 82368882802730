import styled from 'styled-components';
const PageContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  height: 100%;
  padding: 0 3.5rem;
`;
const CardContainer = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  margin: 4rem auto;
  flex-wrap: wrap;
  width: 60rem;
`;
const CardWrapper = styled.div<{ bg?: string }>`
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 8px;
  padding: 25px 25px 60px;
  position: relative;
  max-width: 20rem;
  width: 38%;
  :after {
    content: '';
    background: url(${({ bg }) => bg});
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 78px;
  }
`;
const Icon = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;
const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: center;
  margin-top: 30px;
`;
const ButtonContainer = styled.div<{ position?: string; padding?: string }>`
  position: ${({ position }) => position || 'static'};
  bottom: 5rem;
  right: 4rem;
  display: flex;
  padding: ${({ padding }) => padding};
  justify-content: flex-end;
  gap: 1rem;
  & button {
    padding: 0.75rem 1rem;
  }
`;
const ModalTitle = styled.div`
  padding: 1.25rem;
`;
const ModalContent = styled.div<{ flexDirection?: string }>`
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  padding: 0 1.25rem;
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
`;
const PublishOption = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
export {
  PageContainer,
  CardContainer,
  ButtonContainer,
  CardWrapper,
  Icon,
  Status,
  ModalTitle,
  ModalContent,
  PublishOption
};
