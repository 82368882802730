import { StyledUiContainerProps } from 'components';
import styled from 'styled-components';

/*
top:0;
  left: 100%;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 200ms;
  
  &.is-open {
    left: 0;
    opacity: 1;

    &.is-flyout { 
      .popup-wrapper {
        transform: translateX(0);
      }
    }
  }
  */

export const FlyoutAndPopupsContainer = styled.div<StyledUiContainerProps>`
  top:0;
  left: 100%;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 200ms;
  
  &.is-flyout .popup-wrapper {
    transform: translateY(100%);
  }

  &.is-open {
    opacity: 1;
    left: 0;

    &.is-flyout { 
      .popup-wrapper {
        transform: translateX(0);
        box-shadow: 0 40px 50px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @keyframes cover-in {
    0% {
      left: 100%;
      opacity: 0;
    }
    1% {
      left: 0;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }

  @keyframes cover-out {
    0% {
      display: none
      opacity: 0;
    }
    2% {
      display: grid;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .popup-wrapper {
    max-width: 80vw;
    width: max-content;
    background: white;
    height: 100%;
    min-height: 100%;
    display: grid;
    

    grid-template-rows: ${({ gridRows }) => gridRows || 'auto 1fr'};

    .popup-content {
      padding: 1em;
    }    
  }

  .popup-main {
    padding-right: .5em;
    margin-right: .5em;
    padding-left: 1em;
  }

  .popup-header {
    display: flex;
    justify-content: flex-end;
  }

 

  .popup-title-bar {
    padding: 1em;
    grid-gap: 1em;
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
    align-items: center;
   
    button.btn-close { 
      border: 0;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      padding: .5em;
    }
  }

  .popup-title-bar__header {
    display: flex;
    width: 100%;
    align-items: center;
    h2 { 
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 1.2em;
      flex-grow: 1;
      display: flex;
    }
  }

  .popup-main {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.is-full-screen {
    .popup-wrapper {
      width: 100%;
    }
  }

  &.is-flyout {    
   
    .popup-wrapper {
      height: 100%;
      grid-column: 2;
      max-width: 40em;
    }
  }

  &.is-centered,
  &.is-modal,
  &.is-full-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5em;
  }

  @keyframes flyout-in {
    0% {
      display: none
      transform: translateX(100%);
    }
    1% {
      display: grid;
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

`;
