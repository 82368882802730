import { CleaningStepRow } from '..';

export function reshapeCleaningStepData(initialArray: CleaningStepRow[]): CleaningStepRow[] {
  const resultArray: CleaningStepRow[] = [];

  // Create a map to store subSteps by their parentRowId
  const subStepsMap = new Map();

  // Iterate over the initial array and populate the resultArray and subStepsMap
  initialArray.forEach((row) => {
    // If the row has a parentRowId, it's a sub step
    if (row.parentRowId) {
      if (!subStepsMap.has(row.parentRowId)) {
        subStepsMap.set(row.parentRowId, []);
      }
      subStepsMap.get(row.parentRowId).push(row);
    } else {
      // If the row doesn't have a parentRowId, it's a top-level step
      resultArray.push(row);
    }
  });

  // Add subSteps to their respective parent rows
  resultArray.forEach((row) => {
    if (subStepsMap.has(row.rowId)) {
      row.subRows = subStepsMap.get(row.rowId);
    }
  });

  return resultArray;
}
