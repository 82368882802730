import styled from 'styled-components';
import theme from 'themes';

interface DropAreaProps {
  isDragAccept: boolean;
}
export const Container = styled.div`
  background: ${theme.colors.white};
  height: 100%;
`;
export const UploadPromptMessage = styled.div`
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 1.125rem;
  color: ${theme.colors.darkGray3};
  margin: 1.25rem;
  border-bottom: 0.063rem solid #c2c2c6;
  padding-bottom: 0.313rem;
`;
export const UploaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ImageDragInBox = styled.div<DropAreaProps>`
  width: 100%;
  height: 9.563rem;
  margin: 1.875rem 1.25rem 0.625rem;
  border: 0.063rem dashed #C2C2C6;
  border-radius: 0.625rem;
  padding: 0.625rem; 1.25rem 0rem 1.25rem;
  cursor: pointer;
  background-color: ${(props) => (props.isDragAccept ? 'rgba(200, 120, 204, 0.1)' : 'transparent')};
  border-color: ${(props) =>
    props.isDragAccept ? 'rgba(200, 120, 204, 0.5)' : theme.colors.mediumGrey2};
`;
export const ImageInStorage = styled.div`
  width: 100%;
  height: 9.563rem;
  margin: 1.875rem 1.25rem 0.625rem;
  border: 0.063rem solid #C2C2C6;
  border-radius: 0.625rem;
  padding: 0.625rem; 1.25rem 0rem 1.25rem;
`;
export const ImageStorageMessage = styled.div`
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 1.125rem;
  align-text: center;
  margin-left: 1.25rem;
  color: ${theme.colors.lightGrey8};
`;
export const DragPromptWrapper = styled.div`
  position: relative;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const FileImage = styled.img`
  width: 1.875rem;
  height: 2.344rem;
`;
export const ClickOrDragText = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  align-text: center;
`;
export const FileTypeAndSizeText = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
`;
export const ButtonBox = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  justify-content: flex-end;
`;
export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 2.5rem;
`;
export const ImageButton = styled.div`
  position: absolute;
  top: 5%;
  left: 96%;
`;
export const MachineImage = styled.img`
  width: 15rem;
  height: 8rem;
  object-fit: contain;
`;
export const MachineImageBox = styled.div`
  position: relative;
  left: 20%;
  width: 15rem;
  height: 8rem;
`;
export const StyledTitle = styled.div`
  color: ${theme.colors.darkGrey}
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const AlertBody = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const StyledModalHeaderContainer = styled.div<{ justifyContent?: string }>`
  align-items: center;
  display: flex;
  gap: 1.25rem;
  padding: 1.25rem 1.5rem;
  width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};
`;
export const StyledContentContainer = styled.div`
  padding: 0 1.5rem 1.25rem;
  width: 100%;
`;
export const StyledButtonRow = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
`;
