/** load default settings from BU... these values will not change so there should not be any state changes associated with this */
import * as aseptic from '../settings/aseptic';
import * as proseal from '../settings/proseal';
import * as pna from '../settings/pna';
import * as avure from '../settings/avure';
import * as pemea from '../settings/pemea';
import * as dsi from '../settings/dsi';
import { useMachineInfo } from './useMachineInfoProvider';

export interface UseBuSettingsProps {
  colors?: Record<string, string>;
  Icons?: Record<string, JSX.Element>;
  mapKeys?: Record<string, string>;
  [key: string]: unknown;
}

const buSettings: Record<string, unknown> = { aseptic, proseal, pna, avure, pemea, dsi };

export const useBuSettings = (bu?: string): UseBuSettingsProps => {
  const { businessUnit } = useMachineInfo();
  bu = bu || businessUnit;

  return !bu ? {} : (buSettings?.[bu] as UseBuSettingsProps);
};
