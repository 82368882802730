import React from 'react';

export const IcoClock = ({ color }: { color?: string }): JSX.Element => {
  color = color || '#18A03C';
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className="icon icon-clock"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9.33333V16L21 21M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z"
        stroke={color}
        strokeWidth="1.53846"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IcoClockWithShield = ({ color }: { color?: string }): JSX.Element => {
  color = color || '#009EB3';
  return (
    <svg
      className="icon icon-clock icon-clock-with-shield"
      width="46"
      height="44"
      viewBox="0 0 46 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1709 22C40.1709 18.735 39.1746 15.5435 37.308 12.8293C35.4415 10.1151 32.7887 8.00024 29.6854 6.75248C26.5821 5.50472 23.1678 5.18011 19.8746 5.81974C16.5814 6.45938 13.5575 8.0345 11.1855 10.3457C8.81352 12.657 7.20013 15.6004 6.54955 18.8035C5.89897 22.0066 6.24045 25.3254 7.53076 28.3397C8.82107 31.354 11.0022 33.9284 13.7981 35.7371C16.594 37.5457 19.8789 38.5072 23.2372 38.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1995 12.833V21.9997L25.0852 23.833"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.0565 29.333C42.0565 36.6663 37.3422 40.333 35.4564 40.333C33.5707 40.333 28.8564 36.6663 28.8564 29.333C30.7422 29.333 33.5707 28.4163 35.4564 26.583C37.3422 28.4163 40.1707 29.333 42.0565 29.333Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IcoClockWithSquare = ({ color }: { color?: string }): JSX.Element => {
  color = color || '#B62C10';
  return (
    <svg
      className="icon icon-clock icon-clock-with-square"
      width="46"
      height="44"
      viewBox="0 0 46 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1709 22C40.1709 18.7366 39.1755 15.5465 37.3107 12.8331C35.4458 10.1197 32.7953 8.00484 29.6941 6.756C26.593 5.50715 23.1806 5.18039 19.8885 5.81705C16.5964 6.45371 13.5723 8.02518 11.1989 10.3327C8.82535 12.6403 7.20898 15.5803 6.55414 18.781C5.89929 21.9817 6.23538 25.2993 7.51991 28.3143C8.80443 31.3293 10.9797 33.9062 13.7706 35.7193C16.5616 37.5323 19.8428 38.5 23.1995 38.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1995 12.8333V21.9999L25.0852 23.8333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7422 29.3333H42.0565V40.3333H30.7422V29.3333Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IcoClockWithPause = ({ color }: { color?: string }): JSX.Element => {
  color = color || '#8A8886';
  return (
    <svg
      width="46"
      height="44"
      viewBox="0 0 46 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-clock icon-clock-with-pause"
    >
      <path
        d="M40.0609 23.8663C40.4595 20.4642 39.7595 17.0258 38.0581 14.0278C36.3566 11.0298 33.738 8.6207 30.5651 7.13444C27.3923 5.64819 23.8224 5.15842 20.3504 5.73303C16.8785 6.30765 13.6764 7.91818 11.188 10.3414C8.69971 12.7645 7.04842 15.8803 6.46309 19.2568C5.87776 22.6333 6.38739 26.1031 7.92132 29.1854C9.45525 32.2677 11.9375 34.8096 15.0239 36.4589C18.1104 38.1082 21.6482 38.783 25.1468 38.39"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1992 12.8333V21.9999L26.9707 25.6666"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6279 31.1663V40.3329"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.1714 31.1663V40.3329"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
