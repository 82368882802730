import React from 'react';

export const PrintIcon = (): JSX.Element => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2376_13907)">
        <path
          d="M11.3333 11.3333H12.6667C13.0203 11.3333 13.3594 11.1929 13.6095 10.9428C13.8595 10.6928 14 10.3536 14 10V7.33333C14 6.97971 13.8595 6.64057 13.6095 6.39052C13.3594 6.14048 13.0203 6 12.6667 6H3.33333C2.97971 6 2.64057 6.14048 2.39052 6.39052C2.14048 6.64057 2 6.97971 2 7.33333V10C2 10.3536 2.14048 10.6928 2.39052 10.9428C2.64057 11.1929 2.97971 11.3333 3.33333 11.3333H4.66667"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3327 6V3.33333C11.3327 2.97971 11.1922 2.64057 10.9422 2.39052C10.6921 2.14048 10.353 2 9.99935 2H5.99935C5.64573 2 5.30659 2.14048 5.05654 2.39052C4.80649 2.64057 4.66602 2.97971 4.66602 3.33333V6"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66602 10.0003C4.66602 9.6467 4.80649 9.30757 5.05654 9.05752C5.30659 8.80747 5.64573 8.66699 5.99935 8.66699H9.99935C10.353 8.66699 10.6921 8.80747 10.9422 9.05752C11.1922 9.30757 11.3327 9.6467 11.3327 10.0003V12.667C11.3327 13.0206 11.1922 13.3598 10.9422 13.6098C10.6921 13.8598 10.353 14.0003 9.99935 14.0003H5.99935C5.64573 14.0003 5.30659 13.8598 5.05654 13.6098C4.80649 13.3598 4.66602 13.0206 4.66602 12.667V10.0003Z"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2376_13907">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
