// 3rd party libs
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Components
import { ActionButton, Card, GraphLegend, StateOverTimeChart, Typography } from 'components';
import {
  BarPeriod,
  Padding,
  Row,
  ScatterChartData,
  TrackingLine
} from 'components/StateOverTimeChart';

// Types
import { StatePeriod, ProteinMachineState, ProteinMachineCategoryStates } from 'types/protein';
import { ZoomObjectTuples } from 'types';
import { BrushProps } from 'types/graph';
import { GoBackIcon } from 'icons/IconGoBack';
import { IcoChevron } from 'icons/IcoChevron';

export interface NestedRow {
  id: number;
  label: string;
  parentProperty: string;
  data: StatePeriod[];
  isLabel?: boolean;
  children?: NestedRow[];
}

type Props = {
  nestedData: NestedRow[];
  stateColors: Record<string, string>;
  title: string | React.ReactNode;
  subHeadingComponent?: React.ReactNode;
  // Flag to sync zooming with other graphs
  sync?: boolean;
  brush?: BrushProps;
  trackingLines?: TrackingLine[];
  scatterChartData?: { title?: string; data: ScatterChartData[] };
  barSpacing?: number;
  barCornerRadius?: number;
  intervalSpacing?: number;
  tickLabelPadding?: string | number;
  chartPadding?: Padding;
  hideStateCodes?: boolean;
  hideSubStepIds?: boolean;
  borderColor?: string;
  isDefaultExpanded?: boolean;
  width?: number;
  height?: number;
  isLine?: boolean;
  isMachineMode?: boolean;
  isStateDSI?: boolean;
};

// Map state codes to state names
interface CodeNameMap {
  [code: string]: {
    stateName: string;
    stateCode: string | ProteinMachineState | ProteinMachineCategoryStates;
    uniqueStateCode: string;
  };
}

const graphPadding = {
  top: 10,
  right: 30,
  bottom: 65,
  left: 150
};

const Container = styled(Card)`
  display: block;
`;

const Legends = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid ${({ theme }) => theme.colors.lightGrey2};
`;

const LegendRow = styled.div`
  margin-bottom: 0.5rem;
  margin-right: 0.938rem;
`;

const KeyInline = styled.div`
display: flex;
}`;

const ResetZoom = styled.div`
  margin-bottom: -1rem;
  margin-top: 0.5rem;
`;

const DataZoom = styled.div`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text.lightBlack};
  border-radius: 0.5rem;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.6rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 18px;
  vertical-align: center;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.mediumBlue4};
  }
`;

export const ZoomContainer = styled.div`
  display: flex;
`;

export const IconStyle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

const MoreOptions = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  svg > path {
    fill: #303e47;
  }
`;
const Dropdown = styled.div`
  display: none;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 8.375rem;
  min-width: max-content;
  overflow-y: scroll;
  ${MoreOptions}:hover & {
    display: block;
  }
`;

const DropdownItem = styled.div`
  color: ${({ theme }) => theme.colors.text.lightBlack};
  padding: 0.75rem 1rem;
  text-decoration: none;
  display: block;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey2};
  }
`;

/**
 * Create an array of BarPeriods to populate a row in the chart, filtering out
 * any bars that are not active (i.e. the legend has been unselected)
 */
const createBars = (
  data: StatePeriod[],
  colors: Record<string, string>,
  activeState: Record<string, boolean>,
  overrideCode?: string
) => {
  return data.reduce((prev, current) => {
    const { stateCode, recipeName, stateName, startTimestamp, endTimestamp } = current;
    if (activeState[stateCode]) {
      const startTime = new Date(startTimestamp);
      const endTime = new Date(endTimestamp);
      prev.push({
        state: overrideCode ? overrideCode : (stateCode as string),
        color: colors[stateCode],
        startTime,
        endTime,
        toolTipData: {
          title: recipeName ? recipeName : stateName,
          startTime,
          endTime
        }
      });
    }
    return prev;
  }, [] as BarPeriod[]);
};

// Initial state for expanded rows (all collapsed by default)
const initExpandedState = (nestedData: NestedRow[], isDefaultExpanded = false) => {
  const state: Record<string, boolean> = {};
  nestedData.forEach((parentRow) => {
    state[parentRow.parentProperty] = isDefaultExpanded;
  });
  return state;
};

// Initial state for active states (i.e. legends that are currently selected)
// All states (rows) are shown by default
const initActiveState = (nestedData: NestedRow[]): Record<string, boolean> => {
  const active: Record<string, boolean> = {};
  nestedData.forEach((parentRow) => {
    parentRow.data.forEach(({ stateCode }) => {
      active[stateCode] = true;
    });

    parentRow.children?.forEach((childRow) => {
      childRow.data.forEach(({ stateCode }) => {
        active[stateCode] = true;
      });
    });
  });
  return active;
};

// Check if all state are active states
const areAllActiveState = (data: Record<string, boolean>): boolean => {
  const key = (Object.keys(data) as Array<string>).find((key) => data[key] === false);
  if (key) {
    return false;
  }
  return true;
};

/**
 * Card component that accepts a nested data structure (parent rows containing child rows) to display
 * StatePeriod data on a StateOverTimeChart.
 *
 * Includes legends, and logic for enabling zoom on the StateOverTimeChart.
 *
 * This gives us a fully featured component to  display state over time data,
 * with legends, that can be reused easily.
 */
const StateOverTimeCard = ({
  borderColor,
  nestedData,
  title,
  stateColors,
  sync,
  brush,
  trackingLines,
  scatterChartData,
  barSpacing,
  barCornerRadius,
  intervalSpacing,
  tickLabelPadding,
  chartPadding,
  subHeadingComponent,
  hideStateCodes,
  hideSubStepIds,
  isLine,
  isStateDSI,
  isDefaultExpanded
}: Props): JSX.Element => {
  const [expandedRows, setExpandedRows] = useState(
    initExpandedState(nestedData, isDefaultExpanded)
  );

  const [activeState, setActiveState] = useState(initActiveState(nestedData));

  const { t } = useTranslation(['mh']);

  // Not zoomed by default
  const [zoomedDomain, setZoomedDomain] = useState<ZoomObjectTuples | undefined>(undefined);
  // True - when an area is being selecting
  const [zooming, setZooming] = useState(false);

  const legends = useMemo(() => {
    const map: Record<string, { stateName: string; stateColor: string }> = {};
    nestedData.forEach((parentRow) => {
      parentRow.data.forEach(({ stateCode, stateName }) => {
        map[stateCode] = {
          stateName: hideStateCodes ? stateName : `${t(stateName)} (${stateCode})`,
          stateColor: stateColors[stateCode]
        };
      });

      parentRow.children?.forEach((childRow) => {
        childRow.data.forEach(({ stateCode, stateName }) => {
          map[stateCode] = {
            stateName: hideStateCodes ? stateName : `${t(stateName)} (${stateCode})`,
            stateColor: stateColors[stateCode]
          };
        });
      });
    });
    return map;
  }, [stateColors, nestedData]);

  // Define rows for the StateOverTimeChart
  const rows: Row[] = useMemo(() => {
    const rows: Row[] = [];

    // Add the top level button rows
    nestedData.forEach((parentRow) => {
      const topLevelRow = {
        parentRowProperty: parentRow.parentProperty,
        label: t(parentRow.label),
        isButton: true,
        isLabel: parentRow.isLabel,
        isExpanded: expandedRows[parentRow.parentProperty],
        state: parentRow.parentProperty,
        bars: createBars(parentRow.data, stateColors, activeState, parentRow.parentProperty)
      } as Row;

      rows.push(topLevelRow);

      // Add the child rows, if this row is expanded
      if (expandedRows[parentRow.parentProperty]) {
        const nameMap: CodeNameMap = {};

        // Add mid level rows as non-expandable rows, similar to top level rows
        if ((parentRow?.children?.length || 0) > 0) {
          parentRow?.children?.forEach((child) => {
            const midLevelRow = {
              parentRowProperty: child.parentProperty,
              label: t(child.label),
              isButton: false,
              isLabel: child.isLabel,
              isExpanded: expandedRows[child.parentProperty],
              state: child.parentProperty,
              bars: createBars(child.data, stateColors, activeState, child.parentProperty)
            } as Row;

            rows.push(midLevelRow);

            child.data.forEach(({ stateCode, stateName }) => {
              // Combine the parent property with the child state code to make it a unique state
              const uniqueStateCode = `${child.parentProperty}-${stateCode}`;
              nameMap[uniqueStateCode] = {
                stateName,
                stateCode,
                uniqueStateCode
              };
            });
          });
          // If there are no mid level rows, add the child rows
        } else {
          parentRow.data.forEach(({ stateCode, stateName }) => {
            // Combine the parent property with the child state code to make it a unique state
            const uniqueStateCode = `${parentRow.parentProperty}-${stateCode}`;
            nameMap[uniqueStateCode] = {
              stateName,
              stateCode,
              uniqueStateCode
            };
          });

          // Then dynamically add a child row for each state
          Object.values(nameMap).forEach((val) => {
            rows.push({
              bars: createBars(
                parentRow.data.filter((bar) => bar.stateCode === val.stateCode),
                stateColors,
                activeState,
                val.uniqueStateCode
              ),
              label: hideStateCodes
                ? val.stateName
                : `${t(nameMap[val.uniqueStateCode].stateName)} (${
                    nameMap[val.uniqueStateCode].stateCode
                  })`,
              state: val.uniqueStateCode,
              stateNameLabel: val.stateName
            } as Row);
          });
        }
      }
    });
    return rows;
  }, [nestedData, expandedRows, activeState]);

  // Sync zoom if Applicable
  useEffect(() => {
    if (sync) {
      setZoomedDomain(brush?.zoomedDomain);
      setZooming(false);
    }
  }, [brush?.zoomedDomain]);
  return (
    <Container borderColor={borderColor}>
      <Legends>
        <div>
          {typeof title === 'string' ? (
            <Typography weight="semi-bold" size="1rem">
              {t(title)}
            </Typography>
          ) : (
            t(title as string)
          )}
          {subHeadingComponent}
        </div>
        <KeyInline>
          {Object.keys(legends)
            .slice(0, 5)
            .map((key) => (
              <LegendRow key={key}>
                <GraphLegend
                  color={legends[key].stateColor}
                  active={activeState[key]}
                  id={key}
                  label={legends[key].stateName}
                  onClick={() => {
                    if (areAllActiveState(activeState)) {
                      // set [key] to true, all others to false
                      setActiveState({
                        ...Object.keys(activeState).reduce((acc, curr) => {
                          return {
                            ...acc,
                            [curr]: false
                          };
                        }, {}),
                        [key]: true
                      });
                    } else {
                      if (activeState[key]) {
                        // set [key] to true, all others to false
                        setActiveState({
                          ...Object.keys(activeState).reduce((acc, curr) => {
                            return {
                              ...acc,
                              [curr]: true
                            };
                          }, {}),
                          [key]: true
                        });
                      } else {
                        // set [key] to true, keep all others current values
                        setActiveState({
                          ...activeState,
                          [key]: true
                        });
                      }
                    }
                  }}
                >
                  {legends[key].stateName}
                </GraphLegend>
              </LegendRow>
            ))}
          {Object.keys(legends).length > 5 && (
            <MoreOptions>
              ...
              <IcoChevron />
              <Dropdown>
                {Object.keys(legends)
                  .slice(5)
                  .map((key) => (
                    <DropdownItem key={key}>
                      <GraphLegend
                        color={legends[key].stateColor}
                        active={activeState[key]}
                        id={key}
                        label={legends[key].stateName}
                        onClick={() => {
                          if (areAllActiveState(activeState)) {
                            // Set [key] to true, all others to false
                            setActiveState({
                              ...Object.keys(activeState).reduce((acc, curr) => {
                                return {
                                  ...acc,
                                  [curr]: false
                                };
                              }, {}),
                              [key]: true
                            });
                          } else {
                            if (activeState[key]) {
                              // Set [key] to true, all others to false
                              setActiveState({
                                ...Object.keys(activeState).reduce((acc, curr) => {
                                  return {
                                    ...acc,
                                    [curr]: true
                                  };
                                }, {}),
                                [key]: true
                              });
                            } else {
                              // Set [key] to true, keep all others' current values
                              setActiveState({
                                ...activeState,
                                [key]: true
                              });
                            }
                          }
                        }}
                      >
                        {legends[key].stateName}
                      </GraphLegend>
                    </DropdownItem>
                  ))}
              </Dropdown>
            </MoreOptions>
          )}
        </KeyInline>
        {isLine === true || isStateDSI === true ? (
          <>
            {zoomedDomain?.x && (
              <ResetZoom>
                <ActionButton onClick={() => setZoomedDomain(undefined)} hideArrow>
                  {t('reset_zoom')}
                </ActionButton>
              </ResetZoom>
            )}
          </>
        ) : (
          <>
            <ZoomContainer>
              {zoomedDomain?.x && (
                <>
                  <DataZoom
                    onClick={() => {
                      if (sync) brush?.undoZoom();
                      else setZoomedDomain(undefined);
                    }}
                  >
                    {t('undo')}
                    <IconStyle>
                      <GoBackIcon />
                    </IconStyle>
                  </DataZoom>
                  <ActionButton
                    onClick={() => {
                      if (sync) brush?.resetZoom();
                      else setZoomedDomain(undefined);
                    }}
                    hideArrow
                  >
                    {t('reset_zoom')}
                  </ActionButton>
                </>
              )}
            </ZoomContainer>
          </>
        )}
      </Legends>
      <StateOverTimeChart
        padding={chartPadding ? chartPadding : graphPadding}
        tickLabelPadding={tickLabelPadding}
        rows={rows}
        onLabelClick={({ parentRowProperty }) => {
          parentRowProperty &&
            setExpandedRows({
              ...expandedRows,
              [parentRowProperty]: !expandedRows[parentRowProperty]
            });
        }}
        trackingLines={trackingLines}
        scatterChartData={scatterChartData}
        barSpacing={barSpacing}
        barCornerRadius={barCornerRadius}
        intervalSpacing={intervalSpacing}
        hasZoom={true}
        zooming={zooming}
        brush={{
          onBrushDomainChange: () => !zooming && setZooming(true),
          onBrushDomainChangeEnd: (d) => {
            if (sync) {
              brush?.onBrushDomainChangeEnd(d);
            } else {
              setZoomedDomain(d);
              setZooming(false);
            }
          },
          resetZoom: () => {
            if (sync) brush?.resetZoom();
            else setZoomedDomain(undefined);
          },
          undoZoom: () => {
            if (sync) brush?.undoZoom();
            else setZoomedDomain(undefined);
          },
          zoomedDomain
        }}
        syncAxis={sync}
        hideSubStepIds={hideSubStepIds}
      />
    </Container>
  );
};

export default StateOverTimeCard;
