import React, { useMemo } from 'react';
import { useMachineInfo } from '../../providers';
import { AsepticMachineStateIcon } from './BUMachineStateIcons/AsepticMachineState/AsepticMachineStateIcon';

export const MachineViewStateIcon = (): JSX.Element => {
  const { businessUnit } = useMachineInfo();

  const CachedIcon = useMemo(() => {
    if (businessUnit === 'aseptic') return <AsepticMachineStateIcon />;
    return <></>;
  }, [businessUnit]);

  return CachedIcon;
};
