import React from 'react';
import { KPIWidgetContainer } from './KPIWidget.elements';
import { WidgetUi, WidgetUiProps } from 'common/components';
import { KPIWidgetCellProps, KPIWidgetCell } from './KPIWidgetCell';
import { IcoHourGlass } from 'icons/IcoHourGlass';

export interface KPIWidgetProps extends WidgetUiProps {
  // the type will also be used for className modifier, please make sure when you add a new type
  // that it doesn't contain spaces or special characters
  type?: 'target-vs-current' | 'vs' | 'time' | 'wait-time';
  leftCell?: KPIWidgetCellProps;
  rightCell?: KPIWidgetCellProps;
  centerCell?: KPIWidgetCellProps;
  subCells?: KPIWidgetCellProps[];
  // default is medium
  textSize?: 'small' | 'medium' | 'large' | 'xl-sub-cells' | string;
}

// add new center col icons / layouts here
const CenterCells = ({ type }: KPIWidgetProps) => {
  switch (type) {
    case 'target-vs-current':
      return (
        <>
          <IcoHourGlass />
          <span>vs</span>
        </>
      );

    case 'time':
      return <IcoHourGlass />;

    case 'vs':
      return <span>vs</span>;

    default: {
      console.log('error: missing KPIWidget type');
      return <></>;
    }
  }
};

export const KPIWidget = ({
  title,
  type,
  leftCell,
  rightCell,
  centerCell,
  subCells,
  className,
  textSize,
  ...rest
}: KPIWidgetProps): JSX.Element => {
  // set default type, this determines the center icons/col
  type = type || 'target-vs-current';
  textSize = textSize || 'medium';

  const widgetSettings = {
    title,
    // add custom className to default className
    className: className ? `${className} kpi-widget` : `kpi-widget`,
    ...rest
  };

  let containerClass = 'widget-ui__main has-padding kpi-widget-container';

  if (textSize) containerClass += ` text-size--${textSize}`;

  return (
    <WidgetUi
      {...widgetSettings}
      // load the Main KPI values
      Main={
        <KPIWidgetContainer className={containerClass}>
          {centerCell && (
            <div className="kpi-widget__values-wrapper">
              <KPIWidgetCell
                {...{
                  className: 'kpi-widget-cell kpi-widget-cell--center-cell',
                  type,
                  isCenterCell: true,
                  ...centerCell
                }}
              />
            </div>
          )}
          {leftCell && rightCell && (
            <div className="kpi-widget__values-wrapper">
              <KPIWidgetCell
                {...{ className: 'kpi-widget-cell kpi-widget-cell--left-cell', ...leftCell }}
              />
              <div
                className={`kpi-widget-cell kpi-widget-cell--center-cells kpi-widget-cell--${type}`}
              >
                <CenterCells {...{ type }} />
              </div>
              <KPIWidgetCell
                {...{ className: 'kpi-widget-cell kpi-widget-cell--right-cell', ...rightCell }}
              />
            </div>
          )}

          {
            // check for sub cells and render using matching styles from above
            subCells && (
              <div className="kpi-widget__values-wrapper kpi-widget__values-wrapper--sub-cells">
                {subCells.map((cell, index) => (
                  <KPIWidgetCell
                    key={`footer-cell-${index}`}
                    {...{ className: 'kpi-widget-cell kpi-widget-cell--sub-cell', ...cell }}
                  />
                ))}
              </div>
            )
          }
        </KPIWidgetContainer>
      }
    />
  );
};
