import React from 'react';

interface IconProps {
  color?: string;
}

export const IcoCleaning = ({ color }: IconProps): JSX.Element => {
  color = color || '#0076CC';
  return (
    <svg
      width="46"
      height="44"
      viewBox="0 0 46 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-cleaning icon"
    >
      <path
        d="M7.14429 14.6025C7.46109 14.6392 7.78543 14.663 8.11354 14.6667C9.60326 14.6832 11.0157 14.3404 11.885 13.75C12.7543 13.1634 14.1667 12.8205 15.6564 12.8334C17.1461 12.8205 18.5585 13.1634 19.4278 13.75C20.2971 14.3404 21.7095 14.6832 23.1993 14.6667C24.689 14.6832 26.1014 14.3404 26.9707 13.75C27.84 13.1634 29.2524 12.8205 30.7421 12.8334C32.2318 12.8205 33.6442 13.1634 34.5135 13.75C35.3829 14.3404 36.7953 14.6832 38.285 14.6667C38.6055 14.6667 38.9242 14.641 39.2335 14.6044"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.22803 9.16675L9.47334 28.1032C9.6202 28.9594 10.0747 29.7371 10.756 30.2981C11.4372 30.859 12.3011 31.1669 13.1939 31.1667H33.2051C34.0978 31.1669 34.9617 30.859 35.6429 30.2981C36.3242 29.7371 36.7787 28.9594 36.9256 28.1032L40.1709 9.16675"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99927 36.6667H36.3993"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
