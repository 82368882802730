import React, { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { Button, Card, Typography } from 'components';
import { MaintenanceEvent } from 'types/maintenance';
import { CTAsStretch } from '.';
import theme from 'themes';
import { useGetProductsQuery } from 'api';
import { LONG_LEAD_TIME_THRESHOLD } from 'constants/parts';
import { CardFooter } from '../MaintenanceEventDetailCard';
import { IcoCart } from 'icons/IcoCart';
import { IcoBell } from 'icons/IcoBell';
import styled from 'styled-components';
import { IcoDetail } from 'icons/IcoDetail';
import { BtnText } from './CloseOutSurveyCard';
import { RightBtnContainer } from '..';

export const WarningContainer = styled.div<{ position?: string; padding?: string }>`
  position: ${({ position }) => position};
  padding: ${({ padding }) => padding};
  background: ${(props) => props.theme.colors.white};
  top: -0.7rem;
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;
interface OrderPartsKitCardProps {
  maintenanceEvent: MaintenanceEvent;
  onClick: CallableFunction;
}

function drawLeadTime(maxLeadTime: number | undefined, t: TFunction<'fpns'[], undefined>) {
  if (
    maxLeadTime === undefined ||
    maxLeadTime === null ||
    maxLeadTime === 0 ||
    maxLeadTime >= LONG_LEAD_TIME_THRESHOLD
  ) {
    return <Typography variant="body2">{t('lead_time_2_weeks', { ns: 'fpns' })}</Typography>;
  } else if (maxLeadTime === 1) {
    return <Typography variant="body2">{t('lead_time_1-2_days', { ns: 'fpns' })}</Typography>;
  } else if (maxLeadTime >= 2) {
    return (
      <Typography variant="body2">
        {t('lead_time_num_days', { ns: 'fpns', item: maxLeadTime })}
      </Typography>
    );
  }
}

const OrderPartsKitCard = ({ maintenanceEvent, onClick }: OrderPartsKitCardProps): JSX.Element => {
  const [maxLeadTime, setMaxLeadTime] = useState<number>();
  const { t } = useTranslation(['fpns']);
  const { data: products, isFetching: productsAreLoading } = useGetProductsQuery(
    maintenanceEvent?.purchasables && maintenanceEvent?.purchasables.length > 0
      ? {
          productIds: maintenanceEvent?.purchasables.map(
            (purchasable) => purchasable.purchasableId
          ),
          includeAssets: false
        }
      : skipToken
  );

  useEffect(() => {
    if (!productsAreLoading) {
      const leadTimes = products?.items.map((product) => {
        return product.leadTime;
      });

      leadTimes && setMaxLeadTime(Math.max(...leadTimes));
    }
  }, [productsAreLoading]);

  return (
    <>
      <Card borderColor={theme.colors.negativeRed}>
        <Card.Header
          padding="1rem 1rem 0"
          bgColor={theme.colors.white}
          icon={<IcoCart width="24" />}
        >
          <WarningContainer padding=".2rem .4rem" position="absolute">
            <IcoBell width="18" />
            <Typography mb={0} size={'1rem'} color={theme.colors.text.error} weight="semi-bold">
              {t('require_immediate_action', { ns: 'common' })}
            </Typography>
          </WarningContainer>
          <Typography variant="h3" mb={0} size="0.8125rem" weight="medium">
            {t('order_pm_kit')}
          </Typography>
        </Card.Header>
        <Card.Body pl={'3rem'} pt={0}>
          {maintenanceEvent.purchasables && (
            <Typography>{t('order_parts_for', { item: maintenanceEvent.description })}</Typography>
          )}
          <CTAsStretch>
            <>{!productsAreLoading && drawLeadTime(maxLeadTime, t)}</>
          </CTAsStretch>
        </Card.Body>
        <CardFooter textAlign="end">
          <RightBtnContainer>
            <Button
              variant="text"
              size="small"
              width="auto"
              onClick={() => {
                onClick();
              }}
            >
              <BtnText>
                <IcoDetail />
                <Typography
                  size={'1rem'}
                  mb={0}
                  weight="semi-bold"
                  color={theme.colors.primaryBlue5}
                >
                  {t('view_kit_details')}
                </Typography>
              </BtnText>
            </Button>
          </RightBtnContainer>
        </CardFooter>
      </Card>
    </>
  );
};

export default OrderPartsKitCard;
