import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  viewBox: '0 0 10 10',
  fill: 'none'
}))``;

export const IcoErrorTooltipIcon = ({
  width,
  color,
  srLabel
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <div
      style={{
        marginLeft: '12px',
        cursor: 'pointer'
      }}
    >
      <Svg
        {...{ width, color }}
        className="icon--pencil icon"
        focusable="true"
        aria-label={srLabel || `edit`}
      >
        <path
          d="M5.5 7H5V5H4.5M5 3H5.005M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5Z"
          stroke={color ? color : '#B62C10'}
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </div>
  );
};
