import React from 'react';
import { styledTheme } from 'components';

// this icon is 2 squares with 2 arounds goung in a circle motion
export const IcoProcessError = ({ color }: { color?: string }): JSX.Element => {
  color = color || styledTheme.color.main;
  return (
    <svg
      className="icon icon--process-error"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.13968 3.03679L8.80901 2.70679C8.55898 2.45683 8.2199 2.31641 7.86635 2.31641C7.51279 2.31641 7.17372 2.45683 6.92368 2.70679L2.20968 7.42012C2.08571 7.54395 1.98737 7.691 1.92027 7.85287C1.85317 8.01473 1.81863 8.18823 1.81863 8.36346C1.81863 8.53868 1.85317 8.71218 1.92027 8.87404C1.98737 9.03591 2.08571 9.18296 2.20968 9.30679L6.92301 14.0201C7.04684 14.1441 7.1939 14.2424 7.35576 14.3095C7.51762 14.3766 7.69113 14.4112 7.86635 14.4112C8.04157 14.4112 8.21507 14.3766 8.37693 14.3095C8.5388 14.2424 8.68585 14.1441 8.80968 14.0201L13.523 9.30679C13.647 9.18296 13.7453 9.03591 13.8124 8.87404C13.8795 8.71218 13.9141 8.53868 13.9141 8.36346C13.9141 8.18823 13.8795 8.01473 13.8124 7.85287C13.7453 7.691 13.647 7.54395 13.523 7.42012L12.8298 6.72692"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.86523 6.18359V8.18359"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.86523 10.1836V10.1903"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9551 4.96875L14.4707 1.45312"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.502 1.45312H14.4717V4.4233"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
