import React, { ReactNode, useMemo } from 'react';
import { D3ChartsProps } from '../D3Charts.types';
import * as d3 from 'd3';

interface DataProps {
  [key: string]: unknown;
}

const Path = ({
  color,
  d,
  handleHovered,
  handleClick,
  className
}: {
  color?: string;
  d: string;
  handleHovered?: (x: boolean) => void;
  handleExit?: (x?: boolean) => void;
  handleClick?: () => void;
  centerVal: [number, number];
  className?: string;
}) => {
  color = color || 'red';
  return (
    <path
      cursor={handleClick ? 'pointer' : 'default'}
      className={`arc${className ? ` ${className}` : ``}`}
      // for now we can control the fill via className
      // fillOpacity={className?.includes('is-not-selected') ? 0.7 : 1}
      d={d}
      fill={color}
      onMouseEnter={() => (handleHovered ? handleHovered(true) : false)}
      onMouseLeave={() => (handleHovered ? handleHovered(false) : false)}
      onClick={() => handleClick?.()}
    />
  );
};

const TextLabel = ({
  text,
  data,
  format,
  centerVal
}: {
  data?: Record<string, unknown>;
  text?: string;
  format?: (x?: Record<string, unknown>) => ReactNode | ReactNode[];
  centerVal: [number, number];
}) => {
  text = text || 'missing text';

  if (format && !data) text = 'missing data';
  if (format && data) text = format(data) as string;

  return (
    <text transform={`translate(${centerVal})`} textAnchor="middle" fill="white" fontSize="10">
      {text}
    </text>
  );
};

const SVG = ({
  children,
  width,
  height,
  outerRadius
}: {
  width?: number;
  height?: number;
  outerRadius?: number;
  innerRadius?: number;
  children: ReactNode;
}) => {
  return (
    <svg width={width} height={height} className="base-pie-chart">
      <g transform={`translate(${outerRadius} ${outerRadius})`}>{children}</g>
    </svg>
  );
};

export interface BasePieChartProps extends D3ChartsProps {
  handleHover?: (type: string, x?: Record<string, unknown>) => void;
  handleClick?: (data: Record<string, unknown>) => void;
  data?: DataProps[]; // Replace with the actual type of your data
  progress?: number | string;
}

export const BasePieChart = ({
  data,
  progress,
  color,
  innerRadius,
  outerRadius,
  width,
  height,
  format,
  type,
  handleHover,
  handleClick,
  checkIfSelected,
  groupKey,
  valueKey,
  labelKey
}: BasePieChartProps): JSX.Element => {
  labelKey = groupKey || labelKey || 'label';
  groupKey = groupKey || 'group';
  valueKey = valueKey || 'value';

  // this is a progress chart, setup the data for a doughnut chart with one slice
  if (progress) {
    data = [
      {
        value: progress,
        color: color || 'green',
        label: 'ok'
      },
      {
        value: 100 - Number(progress),
        color: 'rgba(229, 233, 237, 1)',
        label: 'not ok'
      }
    ];

    type = 'doughnut';
  }

  // we should not be seeing this message.
  // there should be a check in the parent component to make sure data is not undefined
  if (!data && !progress) return <>no data</>;

  data = data?.sort((a, b) => Number(b?.[valueKey] || 0) - Number(a?.[valueKey]));

  width = Number(width);
  height = Number(height);

  // set defaults dims
  width = width || height || 180;
  height = width;

  outerRadius = outerRadius || width / 2;

  // this gets set for ring style charts
  innerRadius = innerRadius || 0;
  innerRadius = outerRadius / innerRadius;
  // the higher the number, the thinner the ring
  const doughnutSize = 0.8;
  if (type === 'doughnut') innerRadius = outerRadius * doughnutSize;

  const createPie = d3
    .pie<DataProps>()
    .value((d) => d?.[valueKey] as number)
    .sort(null);

  const createArc = d3
    .arc<d3.PieArcDatum<DataProps>>()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius);

  const ChartCache = useMemo(() => {
    // this is where we create the pie chart in d3
    // it returns an array of items to draw as slices
    const pieData = createPie(data as DataProps[]);

    return pieData.map((d, i) => {
      const centerVal = createArc.centroid(d);

      return (
        <g key={i} className="arc">
          <Path
            {...{
              className: progress
                ? 'is-selected'
                : checkIfSelected?.(d.data)
                ? 'is-selected'
                : 'is-not-selected',
              centerVal,
              d: createArc(d) as string,
              color: (d?.data?.color as string) || 'red',
              handleClick: () => handleClick?.(d.data),
              handleHovered: !handleHover
                ? undefined
                : (x: boolean) => handleHover(x ? 'enter' : 'exit', { centerVal, ...d.data })
            }}
          />
          {format?.label && (
            <TextLabel {...{ text: d.data?.[labelKey] as string, centerVal, data: d.data }} />
          )}
        </g>
      );
    });
  }, [data]);

  return <SVG {...{ width, height, innerRadius, outerRadius }}>{ChartCache}</SVG>;
};
