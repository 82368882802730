// 3rd party
import React, { ReactElement, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Constants
import breakpoint from 'constants/breakpoints';

// Components
import { SidenavItem, PermissionWrapper } from 'components';
import NavFlyout from './NavFlyout';

// Types
import { Role, SidenavItemProps, UserScopes } from 'types';

// Theme
import theme from 'themes';

import { includes, isEqual } from 'lodash';
import { PermissionScopeName } from 'types/user-management';
import { useFleetNavigation } from 'providers';
import { useReport } from 'selectors';

// Icons
import expandIcon from 'icons/expand.svg';
import expandBlue from 'icons/expand-blue.svg';
import collapseBlue from 'icons/collapse-blue.svg';
import { IconExpand } from 'icons/IconExpand';
import { IconCollapse } from 'icons/IconCollapse';

//Apis
import { useGetAccountInfoQuery, useGetUserQuery } from 'api';

interface SidebarNavProps {
  items: SidenavItemProps[];
  role?: Role.Admin | Role.User;
  toggleContent?: boolean;
  setToggleContent?: React.Dispatch<React.SetStateAction<boolean>>;
  mobileView?: boolean;
}

const Root = styled.div`
  position: fixed;
  overflow: hidden;
  z-index: 900;
  height: -webkit-fill-available;
  width: 12vw;
  background-color: ${theme.colors.lightGrey3};
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.5s;
  transition: overflow 0.5s;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media ${breakpoint.device.lg} {
    width: 4.675rem;
    overflow: visible;
  }

  &:hover {
    width: 4.675rem;
    overflow: visible;
  }

  &:last-child {
    border-bottom: 0.0625rem solid gray;
  }
`;

const Icon = styled.div`
  cursor: pointer;
  width: 100%;
  height: 56px;
  padding: 21px 1.5rem 0 1.25rem;
`;

const IconMobile = styled.div<{ isSideNavOpen: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  background-color: ${({ isSideNavOpen }) => (isSideNavOpen ? '#FFFFFF' : 'transparent')};
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  height: 90vh;
  background-color: white;
  max-width: 7rem;
  padding-left: 0;
  border-radius: 0 0 7px 7px;
  list-style: none;
  margin: 0px;
  border-radius: 0px 0px 8px 8px;
  background: #FFF;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
}
`;

const SidebarNav = ({
  items,
  role,
  toggleContent,
  setToggleContent,
  mobileView
}: SidebarNavProps): ReactElement => {
  const history = useHistory();
  const navContext = useFleetNavigation();
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [open, setOpen] = useState(0);
  const [isHover, setIsHover] = useState(false);

  const navBaseRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const reportInfo = useReport();

  const { data: user } = useGetUserQuery();

  const getModifiedItems = (items: SidenavItemProps[], role?: string) => {
    const machineId = user?.machines[0] || '';
    const { data: accountData } = useGetAccountInfoQuery({ machineId });

    switch (role) {
      case 'user':
        if (user) {
          console.log(user);
          const machineId = user?.machines[0];
          const reportObjIdx = items.findIndex((item: SidenavItemProps) => item?.id === 19);
          if (accountData && accountData?.powerBiList?.length) {
            items[reportObjIdx].visible = true;
          } else {
            items[reportObjIdx].visible = false;
          }
          const newLink = items[reportObjIdx].link?.replace('fleet/machine/:machineId', machineId);
          items[reportObjIdx].link = newLink;
        }
        return items;
      case 'admin':
        if (reportInfo?.isReportValid) {
          const reportObjIdx = items.findIndex((item: SidenavItemProps) => item?.id === 19);
          items[reportObjIdx].visible = true;
          let newLink = items[reportObjIdx].link?.replace(':machineId', reportInfo.machineId);
          const regex = /machine.*reports/;
          if (!newLink?.includes(reportInfo.machineId)) {
            newLink = newLink?.replace(regex, `machine/${reportInfo.machineId}/reports`);
          }
          items[reportObjIdx].link = newLink;
        } else {
          items = items.filter((item: SidenavItemProps) => item?.id !== 19);
        }
        return items;
      default:
        return items;
    }
  };

  const itemsList = getModifiedItems(items, role);

  const toggle = (id: number) => setOpen(id);

  const handleBarClick = () => {
    if (setToggleContent) {
      setToggleContent(!toggleContent);
    }
  };

  const handleHover = () => {
    setIsHover(true);
  };
  const removeHover = () => {
    setIsHover(false);
  };

  const handleToggle = () => {
    navContext.setMobileSidebarNavOpen(!navContext.mobileSidebarNavOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        navContext.setMobileSidebarNavOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderItems = (items: SidenavItemProps[]) =>
    items.map((item, i: number) => {
      const permissionWrapperCommonProps = {
        page: item.name,
        key: `nav-${item.label}`,
        scope: UserScopes.Read
      };
      const sidenavItemCommonProps = {
        id: item.id,
        icon: item.icon,
        label: item.label,
        link: item.link,
        isLast: i === items.length - 1,
        isExpanded: isExpanded,
        setExpanded: setExpanded,
        toggleContent: toggleContent,
        toggleSubMenu: toggle,
        currentOpenSubMenu: open,
        mobileView: mobileView
      };
      if (item.authorisedGroups && !includes(item.authorisedGroups, role)) return;
      if (item.visible !== undefined && !item.visible) return;
      // manually override the behavior of the fleet route
      if (item.useFleetMenu) {
        return (
          <PermissionWrapper {...permissionWrapperCommonProps}>
            <SidenavItem
              {...sidenavItemCommonProps}
              clickOverride={() => {
                item.fleetLinks &&
                  !isEqual(item.fleetLinks, navContext.entityLinks) &&
                  navContext.setEntityLinks(item.fleetLinks);
                if (
                  parseFloat(process.env.REACT_APP_NAV_VERSION as string) >= 2 &&
                  navContext.navId !== item.id
                ) {
                  navContext.setNavId(item.id);
                  navContext.setNavFlyoutType(item.label);
                  if (navContext.entity) {
                    const etype = navContext.entity.type;
                    const link = etype && item.fleetLinks?.[etype];
                    const idvar = `:${etype}Id`;
                    history.push(link?.replace(idvar, navContext.entity.id) ?? '');
                  }
                  navContext.setShowFlyout(true);
                } else {
                  navContext.setShowFlyout(!navContext.showFlyout);
                }
              }}
            />
          </PermissionWrapper>
        );
      } else {
        return (
          <PermissionWrapper
            {...permissionWrapperCommonProps}
            role={
              item.name === PermissionScopeName.USER_MANAGEMENT ||
              item.name === PermissionScopeName.MACHINE_MANAGEMENT
                ? Role.Admin
                : Role.User
            }
          >
            <SidenavItem {...sidenavItemCommonProps} subItems={item.subItems} />
          </PermissionWrapper>
        );
      }
    });

  const renderMobileView = () => (
    <div ref={dropdownRef}>
      <IconMobile
        onClick={handleToggle}
        onMouseEnter={handleHover}
        onMouseLeave={removeHover}
        isSideNavOpen={navContext.mobileSidebarNavOpen}
      >
        {navContext.mobileSidebarNavOpen ? (
          <IconExpand strokeColor="#0076CC" />
        ) : (
          <IconCollapse strokeColor="#FFFFFF" />
        )}
      </IconMobile>
      {navContext.mobileSidebarNavOpen && (
        <PopupContainer
          onMouseEnter={() => {
            setExpanded(true);
          }}
          onMouseLeave={() => {
            setExpanded(false);
          }}
          ref={navBaseRef}
        >
          <div className="nav-wrapper">{renderItems(itemsList)}</div>
          <div>
            {navContext.showFlyout && (
              <NavFlyout
                parentRef={navBaseRef}
                showing={navContext.showFlyout}
                setShow={navContext.setShowFlyout}
              />
            )}
          </div>
        </PopupContainer>
      )}
    </div>
  );

  const renderLargeScreenView = () => (
    <Root
      style={{
        width: !toggleContent ? '16.325rem' : '4.325rem'
      }}
      onMouseEnter={() => {
        setExpanded(true);
      }}
      onMouseLeave={() => {
        setExpanded(false);
      }}
      ref={navBaseRef}
    >
      <div className="nav-wrapper">
        {!toggleContent ? (
          <div onClick={handleBarClick}>
            <Icon onMouseEnter={handleHover} onMouseLeave={removeHover}>
              <img src={isHover ? expandBlue : expandIcon} />

              {/* (icon.iconElement as (color?: string) => JSX.Element)(getIconColor()) */}
            </Icon>
          </div>
        ) : (
          <Icon onMouseEnter={handleHover} onMouseLeave={removeHover}>
            {isHover ? (
              <div onClick={handleBarClick}>
                <img src={collapseBlue} />
              </div>
            ) : (
              <div onClick={handleBarClick}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2292_415)">
                    <path
                      d="M21 5L3 5"
                      stroke="#323130"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21 12H11"
                      stroke="#323130"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21 19L3 19"
                      stroke="#323130"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4 9L7 12L4 15"
                      stroke="#323130"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2292_415">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )}
          </Icon>
        )}
        {renderItems(itemsList)}
      </div>
      <div>
        {navContext.showFlyout && (
          <NavFlyout
            parentRef={navBaseRef}
            showing={navContext.showFlyout}
            setShow={navContext.setShowFlyout}
          />
        )}
        {/* Disabling the Link to settings as logout would be in profile section in header */}
        {/* <SidenavItem
          id={100}
          icon={{ iconElement: faCog, iconType: 'fa' }}
          label={NavFlyoutType.Settings}
          link={JBTRoutes.settings}
          setExpanded={setExpanded}
          currentOpenSubMenu={open}
          toggleSubMenu={toggle}
          toggleContent={toggleContent}
        /> */}
      </div>
    </Root>
  );

  return <>{mobileView ? renderMobileView() : renderLargeScreenView()}</>;
};

export default SidebarNav;
