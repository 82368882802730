/* eslint-disable react/prop-types */
import React from 'react';
import ErrorPage from './ErrorPage';
type State = {
  error: string;
};
type ErrorType = {
  name: string;
  message: string;
};
class ErrorBoundary extends React.Component {
  constructor(props: { children: JSX.Element | React.ReactNode }) {
    super(props);
    this.state = { error: '' };
  }

  componentDidCatch(error: ErrorType): void {
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  render(): React.ReactElement {
    const { error } = this.state as State;
    if (error) {
      return <ErrorPage />;
    } else {
      return <>{this.props.children}</>;
    }
  }
}

export default ErrorBoundary;
