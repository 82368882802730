import React from 'react';

import { useDateRange } from 'components';
import { DateButtonWithDropdown } from 'components';
import { TooltipWrapper } from 'components';
import { IcoGear } from 'icons/IcoGear';
import { HeaderButtonContainer } from './WidgetUiHeaderButtons.elements';
import { Ellipsys } from 'icons/Ellipsys';

export interface WidgetUiHeaderButtonTypesProps {
  type?: 'calendar' | 'settings' | string;
  handleToggleCustomMenu?: () => void;
  rightIconOnClick?: () => void;
}

export const WidgetUiHeaderButtons = ({
  type,
  handleToggleCustomMenu,
  rightIconOnClick
}: WidgetUiHeaderButtonTypesProps): JSX.Element => {
  switch (type) {
    case 'calendar': {
      const { dateRange, setDateRange } = useDateRange();
      return (
        <TooltipWrapper Tooltip="Dates">
          <DateButtonWithDropdown {...{ dateRange, setDateRange }} iconOnly />
        </TooltipWrapper>
      );
    }

    case 'settings': {
      return (
        <TooltipWrapper Tooltip="Widget Settings">
          <HeaderButtonContainer type="button" onClick={rightIconOnClick}>
            <IcoGear />
          </HeaderButtonContainer>
        </TooltipWrapper>
      );
    }

    case 'menu': {
      return (
        <TooltipWrapper Tooltip="Widget Settings">
          <HeaderButtonContainer type="button" onClick={handleToggleCustomMenu}>
            <Ellipsys />
          </HeaderButtonContainer>
        </TooltipWrapper>
      );
    }

    default: {
      return <div>error: type {type} not found</div>;
    }
  }
};
