// 3rd party libraries
import React, { ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// State management
import { cartActions } from 'actions';

// Constants
import { sfEnabled } from 'constants/featureFlags';
import breakpoint from 'constants/breakpoints';

// Components
import { Checkbox, CustomerPrice, Typography, InformationTooltip } from 'components';
import {
  CartItemRow,
  RemoveFromCartIcon,
  CartItemText,
  CartItemSku,
  QuantityContainer,
  PriceText,
  SelectControl,
  CartItemStock,
  CheckboxContainer,
  CartUrgentText
} from '.';
import CartRowCommentComponent from './CartRowCommentContainer';

// Types
import { User } from 'types';
import {
  CartListViewType,
  CartPurchasable,
  CartListType,
  CartState,
  CartListSelectionType
} from 'types/parts/cart';

// Providers
import { useLanguage } from 'providers';

// Helpers
import { currency, getProductDescription, getStockText } from 'helpers';

import { useCart, useUser } from 'selectors';

// hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

// Api
import { useUpdateCartProductMutation } from 'api';
import { IcoTrash } from 'icons/IcoTrash';
import NumberInput from 'components/NumberInput';
import styled from 'styled-components';

type CartRowListArguments = {
  handleClose?: () => void;
  viewType: CartListViewType;
  selectionType: CartListSelectionType;
  purchasable: CartPurchasable;
  selectionHandler?: (id: string) => void;
  removeHandler?: (purchasable: CartPurchasable) => void;
  qtyChangeHandler?: (purchasable: CartPurchasable, cartUpdateResult: boolean) => void;
  urgentChangeHandler?: (purchasable: CartPurchasable) => void;
};

const Container = styled.div`
  // padding-bottom: 1rem;

  .grid-container {
    @media (max-width: ${breakpoint.size.md}px) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;
      width: 100%;
    }
  }

  .cartItemRow {
    @media (max-width: ${breakpoint.size.md}px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 1rem 0;
    }
  }

  .flex-align {
    display: flex;
    align-items: center;
  }

  .left {
    display: none;
  }

  @media (max-width: ${breakpoint.size.md}px) {
    .left {
      display: inline-block;
      vertical-align: top;
      margin: 10px 0 0 0;
      padding: 0;
    }

    .right {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      padding: 0;
    }
  }
`;

const CartListRowComponent = ({
  viewType,
  handleClose,
  purchasable,
  selectionType,
  selectionHandler,
  removeHandler,
  qtyChangeHandler,
  urgentChangeHandler
}: CartRowListArguments): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['fpns']);
  const [cartItem, setCartItem] = useState<CartPurchasable>(purchasable);
  const { width: screenWidth } = useWindowDimensions();

  useEffect(() => {
    setCartItem(purchasable);
  }, [purchasable]);

  const user = useUser() as User;
  const cart = useCart() as CartState;
  const { languageId } = useLanguage();
  const [updateCartProduct, updateCartResult] = useUpdateCartProductMutation();
  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const rawQuantity = evt.target.value.length < 1 ? String(0) : evt.target.value;
    const newQuantity =
      Math.max(parseInt(rawQuantity), 0) == 0
        ? cartItem.quantity
        : Math.max(parseInt(rawQuantity), 0);
    const copyCartItem = { ...cartItem, ['quantity']: newQuantity };
    setCartItem(copyCartItem);
    qtyChangeHandler && qtyChangeHandler(copyCartItem, updateCartResult.isLoading);
    if (viewType === CartListType.VIEW_TYPE_DEFAULT) {
      const sfCartItem = cart.sfCart.cartItems?.find(
        (sfItem) => sfItem.cartItem.productId === copyCartItem.item.sfProductId
      );
      try {
        if (sfEnabled()) {
          updateCartProduct({
            accountId: user.organizations[0],
            cart: cart,
            product: copyCartItem.item,
            productId: copyCartItem.item.sfProductId as string,
            quantity: newQuantity,
            machineId: copyCartItem.groupId as string,
            cartItemId: sfCartItem?.cartItem.cartItemId
          });
        } else {
          dispatch({
            type: cartActions.SET_ITEM_QUANTITY,
            item: copyCartItem.item,
            groupId: copyCartItem.groupId as string,
            quantity: newQuantity
          });
        }
      } catch (error) {
        console.warn('Update to cart failed! Error:', error);
        toast(`⚠️ There was a problem updating the quantity`);
      }
    }
  };

  return (
    <>
      {viewType === CartListType.VIEW_TYPE_DEFAULT && screenWidth && screenWidth <= 768 ? (
        <Container>
          <CartItemRow className="cartItemRow" key={cartItem.item.id}>
            {viewType === CartListType.VIEW_TYPE_DEFAULT ? (
              <></>
            ) : viewType === CartListType.VIEW_TYPE_MORE &&
              selectionType === CartListType.SELECTION_MULTI_SELECT ? (
              <div className="grid-container">
                <p className="left">Is Urgent</p>
                <SelectControl className="right">
                  <Checkbox
                    key={`checkbox-${cartItem.item.sku}`}
                    checked={cartItem.selected as boolean}
                    onChange={() => {
                      selectionHandler && selectionHandler(cartItem.item.id);
                    }}
                  />
                </SelectControl>
              </div>
            ) : (
              viewType === CartListType.VIEW_TYPE_MORE &&
              selectionType === CartListType.SELECTION_REMOVE && <></>
            )}
            <div className="grid-container">
              <p className="left">
                <CartItemText defaultValue={viewType}>
                  <Typography weight="semi-bold">{t('parts_table_part')}</Typography>
                </CartItemText>
              </p>
              <CartItemText defaultValue={viewType} className="right">
                {/* where do we get this text from? */}

                <Typography variant="body2">
                  {getProductDescription(cartItem.item, languageId)}
                </Typography>
                {viewType === CartListType.VIEW_TYPE_DEFAULT && (
                  <Typography variant="body1">{t('manufacturer_not_available')}</Typography>
                )}
              </CartItemText>
            </div>
            <div className="grid-container">
              <p className="left">
                {' '}
                {(viewType === CartListType.VIEW_TYPE_DEFAULT ||
                  viewType === CartListType.VIEW_TYPE_MORE) && (
                  <CartItemSku>
                    <Typography weight="semi-bold">{t('parts_table_sku')}</Typography>
                  </CartItemSku>
                )}
              </p>
              <div className="flex-align">
                {(viewType === CartListType.VIEW_TYPE_DEFAULT ||
                  viewType === CartListType.VIEW_TYPE_MORE) && (
                  <CartItemSku>
                    <Typography variant="body1">
                      {cartItem.item.stockCode || cartItem.item.sku}
                    </Typography>
                  </CartItemSku>
                )}
                {viewType === CartListType.VIEW_TYPE_MORE && (
                  <CartItemStock>
                    <Typography variant="body1">{t(getStockText(purchasable.item))}</Typography>
                  </CartItemStock>
                )}

                <p className="flex-align">
                  {viewType === CartListType.VIEW_TYPE_DEFAULT && (
                    <CartUrgentText defaultValue={viewType}>
                      <Typography weight="semi-bold">
                        {t('parts_table_urgent')}
                        <InformationTooltip
                          placement="top"
                          tooltipText={t('parts_urgent_message')}
                        />
                      </Typography>
                    </CartUrgentText>
                  )}
                </p>
                <CheckboxContainer className="right">
                  <Checkbox
                    key={`checkbox-${cartItem.item.sku}`}
                    checked={cartItem.urgent as boolean}
                    onChange={() => {
                      urgentChangeHandler && urgentChangeHandler(cartItem);
                    }}
                  />
                </CheckboxContainer>
              </div>
            </div>
            <div className="grid-container">
              <p className="left">
                <QuantityContainer defaultValue={viewType}>
                  <Typography weight="semi-bold">{t('parts_table_quantity')}</Typography>
                </QuantityContainer>
              </p>
              <div className="flex-align">
                <QuantityContainer defaultValue={viewType}>
                  <NumberInput
                    type="number"
                    id="qty"
                    min={1}
                    value={cartItem.quantity}
                    onChange={changeHandler}
                  />
                </QuantityContainer>

                <RemoveFromCartIcon onClick={() => removeHandler && removeHandler(cartItem)}>
                  <IcoTrash width="30" />
                </RemoveFromCartIcon>
              </div>
            </div>
            {/* <div className="grid-container">
              <p className="flex-align">
                {viewType === CartListType.VIEW_TYPE_DEFAULT && (
                  <CartUrgentText defaultValue={viewType}>
                    <Typography weight="semi-bold">
                      {t('parts_table_urgent')}
                      <InformationTooltip placement="top" tooltipText={t('parts_urgent_message')} />
                    </Typography>
                  </CartUrgentText>
                )}
              </p>
              <CheckboxContainer className="right">
                <Checkbox
                  key={`checkbox-${cartItem.item.sku}`}
                  checked={cartItem.urgent as boolean}
                  onChange={() => {
                    urgentChangeHandler && urgentChangeHandler(cartItem);
                  }}
                />
              </CheckboxContainer>
            </div> */}
            <div className="grid-container">
              <p className="left">
                <PriceText defaultValue={viewType} style={{ padding: 0 }}>
                  <Typography weight="semi-bold">{t('parts_table_price')}</Typography>
                </PriceText>
              </p>
              <PriceText defaultValue={viewType} className="right">
                <Typography>
                  <CustomerPrice
                    product={cartItem.item}
                    fontSizeInREM={0.95}
                    inline={true}
                    handleClose={handleClose}
                  />
                </Typography>
                <Typography size={'1rem'} weight="semi-bold">
                  {typeof cartItem.item.price !== 'undefined' &&
                    cartItem.item.price > 0 &&
                    cartItem.item.isPurchasable &&
                    String(
                      currency(
                        cartItem.item.custPrice
                          ? cartItem.item.custPrice * cartItem.quantity
                          : cartItem.item.price * cartItem.quantity,
                        cartItem.item.priceUnit
                      )
                    ) +
                      ' ' +
                      t('total')}
                  {typeof cartItem.item.price !== 'undefined' &&
                    cartItem.item.price <= 0 &&
                    t('contact_jbt_for_price')}
                </Typography>
              </PriceText>
            </div>
            {viewType === CartListType.VIEW_TYPE_DEFAULT && <div className="grid-container"> </div>}
            {viewType === CartListType.VIEW_TYPE_MORE && (
              <CartRowCommentComponent
                viewType={viewType}
                purchasable={cartItem.item}
                verificationComments={cartItem.verificationComments}
              />
            )}
          </CartItemRow>
        </Container>
      ) : (
        <CartItemRow key={cartItem.item.id}>
          {viewType === CartListType.VIEW_TYPE_DEFAULT ? (
            <></>
          ) : viewType === CartListType.VIEW_TYPE_MORE &&
            selectionType === CartListType.SELECTION_MULTI_SELECT ? (
            <SelectControl>
              <Checkbox
                key={`checkbox-${cartItem.item.sku}`}
                checked={cartItem.selected as boolean}
                onChange={() => {
                  selectionHandler && selectionHandler(cartItem.item.id);
                }}
              />
            </SelectControl>
          ) : (
            viewType === CartListType.VIEW_TYPE_MORE &&
            selectionType === CartListType.SELECTION_REMOVE && <></>
          )}
          <CartItemText defaultValue={viewType}>
            {/* where do we get this text from? */}
            <>
              <Typography variant="body2">
                {getProductDescription(cartItem.item, languageId)}
              </Typography>
              {viewType === CartListType.VIEW_TYPE_DEFAULT && (
                <Typography variant="body1">{t('manufacturer_not_available')}</Typography>
              )}
            </>
          </CartItemText>
          {(viewType === CartListType.VIEW_TYPE_DEFAULT ||
            viewType === CartListType.VIEW_TYPE_MORE) && (
            <CartItemSku>
              <Typography variant="body1">
                {cartItem.item.stockCode || cartItem.item.sku}
              </Typography>
            </CartItemSku>
          )}
          {viewType === CartListType.VIEW_TYPE_MORE && (
            <CartItemStock>
              <Typography variant="body1">{t(getStockText(purchasable.item))}</Typography>
            </CartItemStock>
          )}
          <QuantityContainer defaultValue={viewType}>
            <NumberInput
              type="number"
              id="qty"
              min={1}
              value={cartItem.quantity}
              onChange={changeHandler}
            />
          </QuantityContainer>
          <PriceText defaultValue={viewType}>
            <Typography>
              <CustomerPrice
                product={cartItem.item}
                fontSizeInREM={0.95}
                inline={true}
                handleClose={handleClose}
              />
            </Typography>
            <Typography size={'1rem'} weight="semi-bold">
              {typeof cartItem.item.price !== 'undefined' &&
                cartItem.item.price > 0 &&
                cartItem.item.isPurchasable &&
                String(
                  currency(
                    cartItem.item.custPrice
                      ? cartItem.item.custPrice * cartItem.quantity
                      : cartItem.item.price * cartItem.quantity,
                    cartItem.item.priceUnit
                  )
                ) +
                  ' ' +
                  t('total')}
              {typeof cartItem.item.price !== 'undefined' &&
                cartItem.item.price <= 0 &&
                t('contact_jbt_for_price')}
            </Typography>
          </PriceText>
          {viewType === CartListType.VIEW_TYPE_DEFAULT && (
            <>
              <CheckboxContainer>
                <Checkbox
                  key={`checkbox-${cartItem.item.sku}`}
                  checked={cartItem.urgent as boolean}
                  onChange={() => {
                    urgentChangeHandler && urgentChangeHandler(cartItem);
                  }}
                />
              </CheckboxContainer>

              <RemoveFromCartIcon onClick={() => removeHandler && removeHandler(cartItem)}>
                <IcoTrash width="30" />
              </RemoveFromCartIcon>
            </>
          )}
          {viewType === CartListType.VIEW_TYPE_MORE && (
            <CartRowCommentComponent
              viewType={viewType}
              purchasable={cartItem.item}
              verificationComments={cartItem.verificationComments}
            />
          )}
        </CartItemRow>
      )}
    </>
  );
};

export default CartListRowComponent;
