import React from 'react';
import { ChartTooltipContainer } from './ChartHTML.elements';
import { IcoTriangle } from 'icons/IcoTriangle';

export const ChartTooltipWrapper = ({
  children,
  x,
  y,
  left,
  top
}: {
  children: React.ReactNode;
  x?: number;
  y?: number;
  left?: number;
  top?: number;
}): JSX.Element => {
  if (!children) return <></>;

  return (
    <ChartTooltipContainer className="tooltip-wrapper" {...{ x, y, left, top }}>
      <div className="tooltip">
        <div className="tooltip__inner">{children}</div>
        <IcoTriangle fill="white" />
      </div>
    </ChartTooltipContainer>
  );
};
