import styled, { css } from 'styled-components';

export interface ChartHTMLElementProps {
  width?: number;
  height?: number;
  chartWidth?: number;
  chartHeight?: number;
  marginRight?: number;
  marginBottom?: number;
  marginTop?: number;
  marginLeft?: number;
  x?: number;
  y?: number;
}

const pointerWidth = 13;

export const ChartContainer = styled.div<ChartHTMLElementProps>`
  position: relative;

  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};

  .chart-area,
  .grid-area {
    position: absolute;

    width: ${({ chartWidth }) => (chartWidth ? `${chartWidth}px` : '100%')};
    height: ${({ chartHeight }) => (chartHeight ? `${chartHeight}px` : '100%')};
    left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0')};
    top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0')};
  }

  .grid-line {
    border-color: rgba(0, 0, 0, 0.15);
    border-style: solid;

    &.grid-line--x {
      border-width: 0 0 0 1px;
    }

    &.grid-line--y {
      border-width: 1px 0 0 0;
    }
  }

  .axis {
    position: absolute;
    font-size: 0.9em;

    &.axis--left {
      left: 0;
      top: ${({ marginTop }) => `${marginTop || 0}px`};
      height: ${({ chartHeight }) => `${chartHeight || 100}px`};
      width: ${({ marginLeft }) => `${marginLeft || 0}px`};
    }

    &.axis--bottom {
      top: ${({ chartHeight, marginTop }) =>
        chartHeight ? `${chartHeight + (marginTop || 0)}px` : '100%'};
      left: ${({ marginLeft }) => `${marginLeft || 0}px`};
      height: ${({ marginBottom }) => `${marginBottom || 0}px`};
      width: ${({ chartWidth }) => `${chartWidth || 0}px`};
      transform: translateY(7px);
    }
  }

  .tooltip-wrapper {
    display: none;
  }
`;

export const GridLine = styled.div<ChartHTMLElementProps>`
  position: absolute;

  top: ${({ y }) => (y ? `${y}px` : '0')};
  left: ${({ x }) => (x ? `${x}px` : '0')};
  width: ${({ x }) => (!x ? '100%' : 0)};
  height: ${({ y }) => (!y ? '100%' : 0)};
`;

export const TickMark = styled.div<ChartHTMLElementProps>`
  position: absolute;
  width: 0;
  height: 0;

  top: ${({ y }) => (y ? `${y}px` : undefined)};

  .tick__inner {
    font-size: 0.9em;
    position: absolute;
    min-width: max-content;
    .text--xs {
      font-size: 0.8em;
      text-transform: capitalize;
    }
  }

  &.tick--left {
    right: 1em;
    .tick__inner {
      font-size: 0.9em;
      position: absolute;
      transform: translate(-100%, -50%);
    }
  }

  &.tick--bottom {
    left: ${({ x }) => (x ? `${x}px` : undefined)};
    .tick__inner {
      left: 50%;
      transform: translate(-50%, 0.5em);
      text-align: center;

      min-width: ${({ width }) => (width ? `${width}px` : 'max-content')};
    }
  }
`;

export const PointsChartPoint = styled.div<{ x?: number; y?: number; color?: string }>`
  position: absolute;
  width: 0;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    z-index: 1;
  }

  top: ${({ y }) => `${y || 0}px`};
  left: ${({ x }) => `${x || 0}px`};

  &:before {
    position: absolute;
    display: block;
    content: '';
    left: 50%;
    top: 50%;
    width: 6px;
    height: 6px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-color: ${({ color }) => color || 'black'};
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
  }
`;

export const tooltipStyles = css`
  .text--capital {
    text-transform: capitalize;
  }

  .text--lrg {
    font-size: 1.1em;
  }

  .tooltip {
    position: absolute;
    bottom: 100%;
    transform: translate(-50%, -${pointerWidth / 2}px);
    font-size: 0.8em;
    .icon-triangle {
      position: absolute;
      width: ${pointerWidth}px;
      height: ${pointerWidth}px;
      left: 50%;
      top: 100%;
      transform: rotate(180deg) translate(3px, 3px);
      path {
        filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.3));
      }
    }

    .tooltip__inner {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
      background-color: white;
      padding: 0.5em;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      .tooltip__title {
        font-weight: 500;
        margin-bottom: 0.3em;
        padding-bottom: 0.3em;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

export const ChartTooltipContainer = styled.div<{
  x?: number;
  y?: number;
  left?: number;
  top?: number;
}>`
  position: absolute;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  left: ${({ x, left }) => (x || left ? `${x || left}px` : '0')};
  top: ${({ y, top }) => (y || top ? `${y || top}px` : '0')};

  ${tooltipStyles};
`;

export const BarTooltipContainer = styled.div<{ x?: number; y?: number }>`
  position: absolute;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  left: ${({ x }) => (x ? `${x}px` : '0')};
  top: ${({ y }) => (y ? `${y}px` : '0')};

  ${tooltipStyles};
`;

export const ChartTooltipColorItem = styled.div<{ color?: string }>`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5em;
  min-width: max-content;
  align-items: center;
  text-transform: capitalize;

  &:before {
    display: block;
    content: '';
    width: 0.9em;
    height: 0.9em;
    border-radius: 50%;
    background-color: ${({ color }) => color || 'black'};
  }
`;
