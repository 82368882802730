import { styledTheme } from 'common/theme';
import styled from 'styled-components';

export const ActiveIssuesRow = styled.div`
  font-size: 0.9em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.35em;

  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: inherit;

  &:last-child {
    border-bottom: none;
  }

  .active-issues__row--1 {
    display: flex;
    align-items: center;
    font-weight: normal;
    padding-bottom: 0.4em;

    color: ${styledTheme.colors.black};

    img {
      width: 1em;
    }
  }

  .ico-alert-bell {
    height: 1.5em;
    width: auto;
    margin-right: 5px;
  }

  .active-issues__row--2 {
    color: ${styledTheme.colors.black};
  }

  .active-issues__row--strong {
    font-weight: 700;
    padding-right: 0.3em;
    color: ${styledTheme.colors.black};
  }
`;
