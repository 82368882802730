import { StyledUiContainerProps } from 'components';
import styled from 'styled-components';

export const PopupWithButtonContainer = styled.div<StyledUiContainerProps>`
  top:0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  animation: fade-in-cover 100ms forwards;
  opacity: 0;

  .popup-wrapper {
    max-width: 80vw;
    width: max-content;
    background: white;
    height: 100%;
    min-height: 100%;
    display: grid;

    grid-template-rows: ${({ gridRows }) => gridRows || 'auto 1fr'};

    .popup-content {
      padding: 1em;
    }    
  }

  .popup-main {
    padding-right: .5em;
    margin-right: .5em;
    padding-left: 1em;
  }

  .popup-header {
    display: flex;
    justify-content: flex-end;

  }

  .popup-title-bar {
    padding: 1em;
    display: flex;
    
    h2 {
      flex-grow: 1;
      width: 100%;
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 1.2em;
    }

    button { 
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .popup-main {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.is-full-screen {
    .popup-wrapper {
      width: 100%;
    }
  }

  &.is-flyout {    
    .popup-wrapper {
      height: 100%;
      grid-column: 2;
      transform: translateX(100%);
      animation: slide-in 100ms 25ms forwards;
      max-width: 40em;
    }
  }

  &.is-centered,
  &.is-modal,
  &.is-full-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5em;
  }

  @keyframes slide-in {
    0% {
      display: none
      transform: translateX(100%);
    }
    1% {
      display: grid;
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes fade-in-cover {
    0% {
      display: none
      opacity: 0;
    }
    2% {
      display: grid;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
