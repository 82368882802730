import React from 'react';

export const GroupAddIcon = (): JSX.Element => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="
http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6126_33168)">
        <path
          d="M7.16675 8.66659C7.16675 9.02021 7.30722 9.35935 7.55727 9.60939C7.80732 9.85944 8.14646 9.99992 8.50008 9.99992C8.8537 9.99992 9.19284 9.85944 9.44289 9.60939C9.69294 9.35935 9.83341 9.02021 9.83341 8.66659C9.83341 8.31296 9.69294 7.97383 9.44289 7.72378C9.19284 7.47373 8.8537 7.33325 8.50008 7.33325C8.14646 7.33325 7.80732 7.47373 7.55727 7.72378C7.30722 7.97383 7.16675 8.31296 7.16675 8.66659Z"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83325 14V13.3333C5.83325 12.9797 5.97373 12.6406 6.22378 12.3905C6.47383 12.1405 6.81296 12 7.16659 12H9.83325C10.1869 12 10.526 12.1405 10.7761 12.3905C11.0261 12.6406 11.1666 12.9797 11.1666 13.3333V14"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 3.33333C10.5 3.68696 10.6405 4.02609 10.8905 4.27614C11.1406 4.52619 11.4797 4.66667 11.8333 4.66667C12.187 4.66667 12.5261 4.52619 12.7761 4.27614C13.0262 4.02609 13.1667 3.68696 13.1667 3.33333C13.1667 2.97971 13.0262 2.64057 12.7761 2.39052C12.5261 2.14048 12.187 2 11.8333 2C11.4797 2 11.1406 2.14048 10.8905 2.39052C10.6405 2.64057 10.5 2.97971 10.5 3.33333Z"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8333 6.66675H13.1666C13.5202 6.66675 13.8593 6.80722 14.1094 7.05727C14.3594 7.30732 14.4999 7.64646 14.4999 8.00008V8.66675"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.83325 3.33333C3.83325 3.68696 3.97373 4.02609 4.22378 4.27614C4.47382 4.52619 4.81296 4.66667 5.16659 4.66667C5.52021 4.66667 5.85935 4.52619 6.10939 4.27614C6.35944 4.02609 6.49992 3.68696 6.49992 3.33333C6.49992 2.97971 6.35944 2.64057 6.10939 2.39052C5.85935 2.14048 5.52021 2 5.16659 2C4.81296 2 4.47382 2.14048 4.22378 2.39052C3.97373 2.64057 3.83325 2.97971 3.83325 3.33333Z"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 8.66675V8.00008C2.5 7.64646 2.64048 7.30732 2.89052 7.05727C3.14057 6.80722 3.47971 6.66675 3.83333 6.66675H5.16667"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6126_33168">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
