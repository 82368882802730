// colors will be an object who's keys are the formatted string values of the group/category/state names as they appear in the interface.
/**
 *  example colors = {
 *    'production uptime': '#FF0000',
 *    idle: '#00FF00',
 *  }
 */

import { styledTheme } from 'common/theme';

const baseColors = {
  undefined: styledTheme.colors.Telemetry700,
  'stopped by operator': styledTheme.colors.JBTColors,
  'machine running': styledTheme.colors.Telemetry1000,
  'machine error': styledTheme.colors.Telemetry1700,
  'external error': styledTheme.colors.error200,
  'process error': styledTheme.colors.Telemetry1600,
  offline: styledTheme.colors.Telemetry2100,
  'new undefined error': styledTheme.colors.Telemetry700,
  overfed: styledTheme.colors.Telemetry700,
  'well fed': styledTheme.colors.success200,
  underfed: styledTheme.colors.caution100,
  'very underfed': styledTheme.colors.error200
};
export const colors: Record<string, string> = {
  'Machine Running': styledTheme.colors.Telemetry1000,
  ...baseColors
};

export const chartColors = {
  red: '#B62C10'
};
