import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconButton, HeaderSection, Title } from './FlyoutSidebar.elements';
import { SettingIcon } from 'icons/SettingIcon';

interface FlyoutHeaderProps {
  onClose?: () => void;
  onBack?: () => void;
  header?: string;
  isAdmin?: boolean;
  handleAdminCallback?: () => void;
}

export const Header = ({
  onBack,
  onClose,
  header,
  isAdmin,
  handleAdminCallback
}: FlyoutHeaderProps): JSX.Element => {
  return (
    <HeaderSection>
      <Title>
        {header}
        {isAdmin && (
          <IconButton onClick={() => handleAdminCallback?.()}>
            <SettingIcon />
          </IconButton>
        )}
      </Title>
      {onBack && (
        <IconButton>
          <FontAwesomeIcon onClick={() => onBack()} icon={faChevronLeft} />
        </IconButton>
      )}
      {onClose && (
        <IconButton>
          <FontAwesomeIcon onClick={() => onClose()} icon={faTimes} />
        </IconButton>
      )}
    </HeaderSection>
  );
};
