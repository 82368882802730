import { fleetPageRoute } from '../settings/fleetPageRoutes';
import { useRouterWithHandler, UseRouterWithHandlerReturnProps } from 'common/hooks/useRouter';

// here are the variables for the paths in the url /id/view/tab/subTab/selection
// id = machine id area`
// view = main view (ex: machine-health)
// tab = sub view (ex: overview)
// subTab = sub view of the sub view or tab (ex: sessions-over-time)
// selection = sub view of the sub view or selection (aka session id)
export const useFleetRouter = (extraPath?: string): UseRouterWithHandlerReturnProps => {
  return useRouterWithHandler(extraPath ? `${fleetPageRoute}/${extraPath}` : fleetPageRoute);
};
