// 3rd party libs
import React, { useEffect, useMemo, useState } from 'react';
// import { useTranslation } from 'react-i18next';

// Components
import { Loader, Pagination } from 'components';
import {
  SortableColumn,
  MaintenanceScheduleTableQuery
} from 'components/MaintenanceTable/MaintenanceTable';

// Types
import { Machine, SortState } from 'types';
import {
  MaintenanceSchedule,
  MaintenanceScheduleArgs,
  MaintenanceScheduleTableRow
} from 'types/maintenance';

// Helpers
import {
  MaintenanceSchedulePageProps,
  addMachineDescToSchedule
} from './MaintenanceServiceDashboardContents';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetMaintenanceScheduleQuery } from 'api';

// Hooks
import { usePaginatedQueryOffset, useSort } from 'hooks';

// Constants
import { PAGE_LENGTH } from 'constants/search';

const ITEMS_PER_PAGE = PAGE_LENGTH.SMALL;
const formatSchedulesIntoRows = (data: MaintenanceSchedule[]): MaintenanceScheduleTableRow[] =>
  data.map(
    (item) =>
      ({
        ...item
        // TODO - connect this to the next task
      } as MaintenanceScheduleTableRow)
  );

interface TProps {
  filter: MaintenanceScheduleArgs | null;
  /** Tables with incomplete events might want to sort by suggested_due, others by completion. */
  notice: (loading: boolean, total: number) => JSX.Element;
  headerBgColor: string;
  selectedSecondaryTab?: number | undefined;
  machines?: Machine[];
  setNumResults?: (num: number | null) => void;
  setSelectedPm: (pm: MaintenanceScheduleTableRow) => void;
  setCompletedEvents?: (ce: MaintenanceScheduleTableRow[]) => void;
  setCurrentMaintenanceSchedulePage?: React.Dispatch<
    React.SetStateAction<MaintenanceSchedulePageProps | undefined>
  >;
}
export function MaintenanceScheduleTable({
  filter,
  headerBgColor,
  machines,
  setNumResults,
  setSelectedPm,
  setCompletedEvents,
  selectedSecondaryTab,
  setCurrentMaintenanceSchedulePage
}: TProps): JSX.Element {
  const [markAllCompleted, setMarkAllCompleted] = useState(false);
  const [isMarkerReset, setIsMarkerReset] = useState(false);
  const [sortState, setSortState] = useState<Record<string, SortState>>({
    machineDescription: SortState.unsorted,
    subcomponent: SortState.unsorted,
    suggestedDue: SortState.unsorted
  });
  const { onPageChange, pageNumber } = usePaginatedQueryOffset();

  useEffect(() => {
    onPageChange(0);
  }, [selectedSecondaryTab]);
  const { data: events, isFetching } = useGetMaintenanceScheduleQuery(
    filter
      ? {
          ...filter,
          limit: ITEMS_PER_PAGE,
          offset: pageNumber
        }
      : skipToken
  );
  const scheduleFrequency = filter?.minimumFrequency;
  const rows = useMemo(() => {
    if (events === undefined || machines === undefined) {
      return [];
    }
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    setCurrentMaintenanceSchedulePage!({
      events: events.items,
      filter: filter as MaintenanceScheduleArgs
    });
    const evs = addMachineDescToSchedule(events.items as MaintenanceSchedule[], machines);
    return formatSchedulesIntoRows(evs);
  }, [events]);
  const sortedData = useSort<MaintenanceScheduleTableRow>(sortState, rows);
  useEffect(() => {
    if (setNumResults === undefined) {
      return;
    }
    if (markAllCompleted) {
      setMarkAllCompleted(!markAllCompleted);
      setIsMarkerReset(true);
    }
    setNumResults(events ? events.total : null);
  }, [events]);

  const sortColumnClicked = function (column: SortableColumn) {
    let columntState = sortState[column];
    Object.keys(sortState).forEach((field) => {
      if (field !== column) {
        setSortState((currSortState) => {
          return { ...currSortState, [field]: SortState.unsorted };
        });
      }
    });
    setSortState((currSortState) => {
      if (columntState == SortState.ascending) {
        columntState = SortState.descending;
      } else if (columntState === SortState.descending) {
        columntState = SortState.unsorted;
      } else {
        columntState = SortState.ascending;
      }

      return { ...currSortState, [column]: columntState };
    });
  };

  if (!events || !machines) {
    return <Loader />;
  }
  return (
    <>
      <MaintenanceScheduleTableQuery
        data={sortedData}
        isDataLoading={isFetching}
        headerBgColor={headerBgColor}
        setSelectedPm={setSelectedPm}
        sortState={sortState}
        sortColumnClicked={sortColumnClicked}
        setMarkAllCompleted={setMarkAllCompleted}
        markAllCompleted={markAllCompleted}
        pageNumber={pageNumber}
        scheduleFrequency={scheduleFrequency}
        isMarkerReset={isMarkerReset}
        setIsMarkerReset={setIsMarkerReset}
        selectedSecondaryTab={selectedSecondaryTab}
        setCompletedMaintenanceEvents={setCompletedEvents}
      />

      <Pagination
        onPageChange={onPageChange}
        itemsPerPage={ITEMS_PER_PAGE}
        numItems={events.total}
        forcePage={pageNumber}
        currentPage={pageNumber}
      />
    </>
  );
}
