import React from 'react';
import BlueArrow from '../../../../../../../../../../icons/blueArrow.svg';
import Chevron from '../../../../../../../../../../icons/chevron.svg';
import GreenCheckmark from '../../../../../../../../../../icons/GreenCheckCircle.svg';
import Bug from '../../../../../../../../../../icons/IssuesIcon.svg';
import ChevronDown from '../../../../../../../../../../icons/ChevronDown.svg';
import { AddHHMMSS, formatDuration } from 'helpers';
import { ActualChangeOverDetail, AsepticChangeoverType } from 'types/machine-health/aseptic';
import { Alarm } from 'types/machine-health/alarms';
import { CleaningStepStatus, CleaningStepWithKPI } from 'types/protein';
import _ from 'lodash';

export const generateIcon = (type: string): HTMLImageElement => {
  let icon;

  switch (type) {
    case 'green checkmark':
      icon = GreenCheckmark;
      break;
    case 'chevron right':
      icon = Chevron;
      break;
    case 'chevron down':
      icon = ChevronDown;
      break;
    case 'bug':
      icon = Bug;
      break;
    case 'blue arrow':
      icon = BlueArrow;
      break;
    default:
      icon = '';
  }

  return <img src={icon} alt={`${type}`} />;
};

const getTargetDuration = (values, key) => {
  return (
    values.reduce((acc, item) => {
      if (item.modeDescr !== key) return acc;
      return acc + item.duration;
    }, 0) * 1000
  ); //we need to return it in milliseconds
};

const differenceBetweenActualAndTarget = (values, key, targetDetails) => {
  const actualDuration = getDurationMilliseconds(values);
  const targetDuration = getTargetDuration(targetDetails, key);
  return actualDuration - targetDuration;
};

const mergeAllAlarmsData = (subSteps: ActualChangeOverDetail[]) => {
  const allAlarms: Alarm[] = [];
  subSteps?.forEach((value) => {
    const alarms = value.alarms.map((alarm) => ({
      group: value.modeDescr,
      ...alarm
    }));
    allAlarms.push(...alarms);
  });
  return allAlarms;
};

// Return formatted duration string from a step
const getStepDuration = (subSteps: ActualChangeOverDetail[]) => {
  if (subSteps) {
    let sumDuration = 0;
    subSteps.forEach((subStep) => {
      sumDuration += new Date(subStep.endTime).getTime() - new Date(subStep.startTime).getTime();
    });
    return formatDuration(sumDuration, 'hours:mins:secs');
  }
};

const getDurationMilliseconds = (subSteps?: ActualChangeOverDetail[]) => {
  let sumDuration = 0;
  if (subSteps) {
    subSteps.forEach((subStep) => {
      sumDuration += new Date(subStep.endTime).getTime() - new Date(subStep.startTime).getTime();
    });
  }
  return sumDuration;
};

export const formatDurationString = (milliseconds: number): string => {
  return formatDuration(milliseconds, 'hours:mins:secs');
};

const getSumTargetDuration = (key: string, targetDetails: ActualChangeOverDetail[] | undefined) => {
  return targetDetails?.reduce((acc, Titem) => {
    if (Titem.modeDescr === key) {
      return acc + Titem.duration;
    }
    return acc;
  }, 0);
};

const formatAveDurationMinutes = (aveDur: number): string => {
  const hours = Math.floor(aveDur / 60);
  const minutes = aveDur % 60;

  const hoursDisplay = hours.toString().length === 1 ? `0${hours}` : hours;
  const minutesDisplay = minutes.toString().length === 1 ? `0${minutes}` : minutes;

  return `${hoursDisplay}:${minutesDisplay}:00`;
};

const getSubStepSumTargetDuration = (
  subStep: ActualChangeOverDetail,
  targetDetails: ActualChangeOverDetail[] | undefined
) => {
  let sumTargetDuration = '00:00:00';

  if (targetDetails) {
    const subSteps = targetDetails.filter(
      (targetDetail) => targetDetail.recipeDescr === subStep.recipeDescr
    );
    subSteps.forEach((subStep) => {
      sumTargetDuration =
        sumTargetDuration && subStep?.duration
          ? AddHHMMSS(sumTargetDuration, subStep?.duration)
          : '-';
    });
  }

  return sumTargetDuration;
};

const checkSubStepsStatus = (subSteps: ActualChangeOverDetail[]) => {
  const status = subSteps.every((subStep) => {
    return subStep?.alarms.length > 0;
  });
  return !status ? CleaningStepStatus.Completed : '';
};

export const toCleaningStepWithKPI = (
  selectedChangeover?: AsepticChangeoverType
): CleaningStepWithKPI[] => {
  // Group tags by their 'tagGroupId'

  const groups = _(selectedChangeover?.actualDetails)
    .groupBy((actualDetail) => actualDetail.modeDescr)
    .map((values, key) => ({
      id: values[0].modeNumber,
      group: values[0].modeDescr,
      name: key,
      startTime: values[0].startTime,
      endTime: values[values.length - 1].endTime,
      averageDuration: formatAveDurationMinutes(values[0].avgDuration as unknown as number),
      alarms: mergeAllAlarmsData(values),

      duration: formatDurationString(getDurationMilliseconds(values)),
      targetDuration: formatDurationString(
        getSumTargetDuration(key, selectedChangeover?.targetDetails) * 1000
      ),
      //targetDuration: getSumTargetDuration(values, selectedChangeover?.targetDetails),
      actualVStargetDiff: differenceBetweenActualAndTarget(
        values,
        key,
        selectedChangeover?.targetDetails
      ),

      subRows: _(values)
        .groupBy((subStep) => subStep.recipeDescr)
        .map((subSteps) => ({
          id: subSteps[0].recipeDescr,
          name: subSteps[0].recipeDescr,
          parentName: subSteps[0].modeDescr,
          status: checkSubStepsStatus(subSteps),
          startTime: subSteps[0].startTime + '.275000+00:00',
          endTime: subSteps[subSteps.length - 1].endTime,
          alarms: mergeAllAlarmsData(subSteps),
          duration: getStepDuration(subSteps),
          averageDuration: formatAveDurationMinutes(subSteps[0].avgDuration as unknown as number),
          targetDuration: getSubStepSumTargetDuration(
            subSteps[0],
            selectedChangeover?.targetDetails
          )
        }))
        .value()
    }))
    .value();
  const returnData: CleaningStepWithKPI[] = [];

  for (const group of groups) {
    returnData.push(group as unknown as CleaningStepWithKPI);
  }
  return returnData;
};
